import withStyles from '@mui/styles/withStyles';
import layoutStyles from 'containers/Layout/styles';
import styles from 'components/DataTableX/styles';
import Translation from 'utils/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, Fragment } from 'react';
import GlobalModal from 'components/Global/Modal';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { TextField, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/system';
import { Done } from '@mui/icons-material';

const ModelContent = (props) => {

    const { apiRef, hideActionButton, onActionButtonPressed, selectedCheckboxRows, columns, disableActionButton, handleClose } = props;
    const [ticketsToClose, setTicketsToClose] = useState([]);
    const [quickCloseReason, setQuickCloseReason] = useState('');
    const user = useSelector((state) => state.user.user);
    useEffect(() => {
        if (quickCloseReason.length === 0) {
            disableActionButton(true);
            hideActionButton(false);
        }
        if (quickCloseReason.length > 5 && ticketsToClose.length > 0) {
            disableActionButton(false);
        }
    }, [quickCloseReason]);
    const gqlMutation = gql`
    mutation MyMutation($comment: [ticket_comment_insert_input!]!, $ticket: ticket_set_input = {}, $where: ticket_bool_exp = {}) {
      insert_ticket_comment(objects: $comment) {
        affected_rows
      }
      update_ticket(_set: $ticket, where: $where) {
        affected_rows
      }
    }
  `;
    useEffect(() => {
        // const newData = selectedCheckboxRows.filter((row) => row.status_id !== 'c7dc5b89-e3cd-41ec-baf1-d7d43d079725');
        console.log(selectedCheckboxRows)
        const newData = selectedCheckboxRows.filter((row) => row.status_id !== 'c7dc5b89-e3cd-41ec-baf1-d7d43d079725');
        const finalData = newData.filter((row, index, self) => self.findIndex((t) => t.id === row.id) === index);
        console.log({ finalData })
        setTicketsToClose([...finalData]);
    }, [selectedCheckboxRows]);


    const [mutation, { data: quickCloseData, loading: quickCloseLoading, error: quickCloseError }] = useMutation(gqlMutation);
    const handleActionButtonPress = () => {
        let ticketIdsArray = [];
        let newComments = [];
        selectedCheckboxRows.forEach((ticket) => {
            ticketIdsArray.push(ticket.id);
            let comment = {
                ticket_id: ticket.id,
                account_id: user?.account_id,
                agent: true,
                public: false,
                author_id: user?.id,
                created_at: 'now()',
                updated_at: 'now()',
                source: {
                    data: {
                        subject: 'Quick Resolve',
                        body_preview: quickCloseReason,
                        body: `<h3>This ticket has been resolved by the quick resolve feature</h3><h4>Quick Resolve Reason :- </h4><p>${quickCloseReason}</p>`,
                        type_id: '2d67d487-aaa7-4d3e-a610-95eac278687f',
                    },
                },
            };
            newComments.push(comment);
        });
        let variables = {
            comment: [...newComments],
            ticket: {
                status_id: 'c7dc5b89-e3cd-41ec-baf1-d7d43d079725',
                updated_at: 'now()',
            },
            where: {
                id: {
                    _in: ticketIdsArray,
                },
            },
        };
        console.log(variables);

        mutation({ variables }).then(() => {
            apiRef.current.setRowSelectionModel([]);
        });
    };

    // If the parent component has provided the onActionButtonPressed prop, use it
    useEffect(() => {
        if (onActionButtonPressed) {
            handleActionButtonPress();
        }
    }, [onActionButtonPressed]);
    useEffect(() => {
        if (quickCloseLoading) {
            hideActionButton(true);
        }
    }, [quickCloseLoading]);

    useEffect(() => {
        console.log(ticketsToClose)
    }, [ticketsToClose])


    return (
        <>
            {quickCloseError && !quickCloseLoading && (
                <>
                    <h4
                        style={{
                            fontWeight: 500,
                        }}
                    >
                        {Translation('ticketViewPage.toolbar.quickClose.model.error')}
                    </h4>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: `row`,
                            marginTop: `16px`,
                            gap: '20px',
                        }}
                    >
                        <Button
                            sx={{
                                width: `35%`,
                            }}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            {Translation('ticketViewPage.toolbar.quickClose.closeButton')}
                        </Button>
                    </Box>
                </>
            )}
            {!quickCloseError && (
                <>
                    <DataGridPro
                        disableSelectionOnClick={true}
                        rowSelectionModel={[]}
                        rows={ticketsToClose}
                        columns={columns.filter(
                            (column) => column.field === 'alias' || column.field === 'subject',
                        )}
                        density='compact'
                        // hideFooter={true}
                        sx={{
                            maxHeight: '250px',
                        }}
                    />
                    {!quickCloseLoading && !quickCloseError && quickCloseData && (
                        <>
                            <h4
                                style={{
                                    fontWeight: 500,
                                    textAlign: 'center',
                                }}
                            >
                                {Translation('ticketViewPage.toolbar.quickClose.model.finished')}
                            </h4>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexDirection: `row`,
                                    marginTop: `16px`,
                                    gap: '20px',
                                }}
                            >
                                <Button
                                    sx={{
                                        width: `35%`,
                                    }}
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    {Translation('ticketViewPage.toolbar.quickClose.closeButton')}
                                </Button>
                            </Box>
                        </>
                    )}
                    {quickCloseLoading && (
                        <h4
                            style={{
                                fontWeight: 500,
                                textAlign: 'center',
                            }}
                        >
                            {Translation('ticketViewPage.toolbar.quickClose.model.updating')}
                        </h4>
                    )}
                    {!quickCloseLoading && !quickCloseData && (
                        <>
                            <h4
                                style={{
                                    fontWeight: 500,
                                }}
                            >
                                {Translation('ticketViewPage.toolbar.quickClose.model.reason')}
                            </h4>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                variant='outlined'
                                onChange={(e) => {
                                    setQuickCloseReason(e.target.value);
                                }}
                                placeholder={Translation('ticketViewPage.toolbar.quickClose.model.reasonPlaceholder')}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};
const QuickCloseTicketToolBar = (props) => {
    const { selectedCheckboxRows, columns, apiRef } = props;
    const [quickClose, setQuickClose] = useState(false);

    console.log({ selectedCheckboxRows })

    return (
        <>
            <GlobalModal
                open={quickClose}
                handleClose={() => setQuickClose(false)}
                width={'70%'}
                title={Translation('ticketViewPage.toolbar.quickClose.model.title')}
                submitLabel={Translation('ticketViewPage.toolbar.quickClose.submitButton')}
                cancelLabel={Translation('ticketViewPage.toolbar.quickClose.cancelButton')}
                content={<ModelContent apiRef={apiRef} selectedCheckboxRows={selectedCheckboxRows} columns={columns} />}
            />

            <Button
                size='small'
                disabled={selectedCheckboxRows.filter((row) => row.status !== 'resolved').length === 0}
                onClick={() => setQuickClose(true)}
                startIcon={<Done />}
            >
                {Translation('ticketViewPage.toolbar.label.quickClose')}
            </Button>
        </>
    );
};

export default withStyles(
    (theme) => ({
        ...layoutStyles(theme),
        ...styles(theme),
    }),
    { withTheme: true },
)(QuickCloseTicketToolBar);
