import { put, takeLatest } from "redux-saga/effects";
import { push } from "redux-first-history";
import { scrollOpenViews, scrollOpenViewAndMenu } from "utils/menuHelper";
import {
  SELECT_MENU_ITEM,
  SELECTED_MENU_ITEM,
  OPEN_VIEW,
  OPENED_VIEW,
  OPENED_DYNAMIC_VIEW,
  CLOSE_VIEW,
  CLOSED_VIEW,
  OPEN_DYNAMIC_VIEW,
} from "containers/App/Redux/menu/constants";

export function* onSelectedMenuChanged() {
  yield takeLatest(SELECT_MENU_ITEM, navigateToUrl);
}

export function* closeView(action) {
  const { foundMenuItem, foundOpenViewItem, idsToBeRemoved } = action;
  yield put(push(foundMenuItem.url));
  yield put({ type: SELECTED_MENU_ITEM, foundMenuItem, foundOpenViewItem });
  yield put({ type: CLOSED_VIEW, idsToBeRemoved });
}

export function* onCloseView() {
  yield takeLatest(CLOSE_VIEW, closeView);
}

export function* openView(action) {
  let { menuItem, icon, openViews, activeSubMenuItem } =
    action.payload || action;
  const isViewOpened = openViews.find((item) => item.id === menuItem.id);
  if (menuItem.url !== window.location.pathname) {
    yield put(push(menuItem.url));
  }
  if (!isViewOpened) {
    yield put({ type: OPENED_VIEW, menuItem, icon });
  }
  yield put({
    type: SELECTED_MENU_ITEM,
    foundMenuItem: menuItem,
    foundOpenViewItem: menuItem,
    openViews: openViews,
    activeSubMenuItem: activeSubMenuItem,
  });
  if (!isViewOpened) {
    let tempArray = [];
    openViews.map((item) => tempArray.push(item));
    tempArray.push(menuItem);
    openViews = tempArray;
  }
  scrollOpenViews(openViews);
}

export function* onOpenView() {
  yield takeLatest(OPEN_VIEW, openView);
}

export function* openDynamicView(action) {
  const { dynamicItem, parentItem, openViews } = action;
  if (dynamicItem.url !== window.location.pathname) {
    yield put(push(dynamicItem.url));
  }
  yield put({
    type: OPENED_DYNAMIC_VIEW,
    dynamicItem,
    parentItem,
  });

  yield put({
    type: SELECTED_MENU_ITEM,
    foundMenuItem: dynamicItem,
    foundOpenViewItem: dynamicItem,
  });
  // console.log(openViews,dynamicItem)
  const newOpenView = [...openViews, dynamicItem];
  // openViews.push(dynamicItem);
  scrollOpenViews(newOpenView);
}

export function* onOpenDynamicView() {
  yield takeLatest(OPEN_DYNAMIC_VIEW, openDynamicView);
}

export function* navigateToUrl(action) {
  const {
    foundMenuItem,
    foundOpenViewItem,
    openViews,
    activeSubMenuItem,
    topLevel,
  } = action;
  let finalFoundItem = foundMenuItem;
  if (activeSubMenuItem && topLevel) {
    Object.keys(activeSubMenuItem).forEach((key) => {
      if (foundMenuItem?.parent?.parentId === key) {
        finalFoundItem = activeSubMenuItem[key];
      }
    });
  }
  if (finalFoundItem.url !== window.location.pathname) {
    yield put(push(finalFoundItem.url));
  }
  yield put({
    type: SELECTED_MENU_ITEM,
    foundMenuItem: finalFoundItem,
    foundOpenViewItem: finalFoundItem,
  });
  // yield put({ type: SELECTED_MENU_ITEM, foundMenuItem, foundOpenViewItem,openViews });
  scrollOpenViewAndMenu(openViews);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  onSelectedMenuChanged,
  onOpenView,
  onCloseView,
  onOpenDynamicView,
];
