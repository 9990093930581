const components = {
  "components.search": "Buscar",

  // Nuevas traducciones
  "components.leftSideBarMenuList.TitleA": "SIX STAR",
  "components.leftSideBarMenuList.TitleB": "ADMINISTRAR",
  
  // Verificación de contraseña
  "components.passwordVerifyComponent.mustContain": "La contraseña debe contener lo siguiente: ",
  "components.passwordVerifyComponent.upperCase": "Una letra mayúscula",
  "components.passwordVerifyComponent.lowerCase": "Una letra minúscula",
  "components.passwordVerifyComponent.number": "Un número",
  "components.passwordVerifyComponent.min": "Mínimo 8 caracteres",
  "components.passwordVerifyComponent.special": "Un carácter especial",
  "components.passwordVerifyComponent.match": "Las contraseñas deben coincidir",
  "components.passwordVerifyComponent.enterNew": "Ingrese su nueva contraseña",
  "components.passwordVerifyComponent.confirm": "Confirme su nueva contraseña",
  "components.passwordVerifyComponent.random": "Su contraseña aleatoria es:",
  "components.passwordVerifyComponent.copiedToClipboard": "Copiado al portapapeles",
  "components.passwordVerifyComponent.makeNote": "Por favor, tome nota de esto.",
  
  // Restablecimiento de contraseña
  "components.passwordReset.error": "Pedimos disculpas, pero no podemos restablecer su contraseña en este momento.",
  "components.passwordReset.submitRequest": "Por favor, envíe una solicitud de soporte para que podamos ayudarlo a restablecer su contraseña.",
  "components.passwordReset.thankYou": "Gracias por su comprensión.",
  "components.passwprdReset.success.pleaseWait": "Por favor espere, restableciendo la contraseña",

};
export default components;
