import React from "react";
import { useIntl } from "react-intl";

const TranslationComponent = ({ tag, variables = null }) => {
  const intl = useIntl();

  let message = "";
  let lastWord = tag.split(".").pop();

  if (
    variables !== null &&
    !variables.id.match(/\d+/g) &&
    variables.id.includes("-")
  ) {
    variables.id = variables.id.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });

    tag = tag + "." + variables.id;
    variables = null;
  }

  try {
    if (variables !== null) {
      message = intl.formatMessage(
        {
          id: tag,
          defaultMessage: tag + " Not Defined",
          description: "Translation for " + tag,
        },
        { ...variables }
      );
    } else {
      message = intl.formatMessage({
        id: tag,
        defaultMessage: tag + " Not Defined",
        description: "Translation for " + tag,
      });
    }
  } catch (error) {
    console.log(error);
  }

  return <span>{message}</span>;
};

export default TranslationComponent;
