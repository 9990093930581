let personJson = {
  table: "person",
  data: [
    {
      property: "id",
      table: "person",
      type: "text",
      translate: true,
      sortable: true,
      hide: true,
      style: {
        align: "center",
        headerAlign: "center",
      },
    },
    {
      property: "alias",
      table: "person",
      type: "text",
      translate: true,
      sortable: true,
      style: {
        align: "center",
        headerAlign: "center",
      },
    },
    // {
    //     property: 'avatar',
    //     table: 'person',
    //     type: 'avatar',
    //     translate: true,
    //     sortable: true,
    //     style: {
    //         align: "center",
    //         headerAlign: "center"
    //     }
    // },
    {
      property: "name",
      table: "person",
      type: "text",
      filterable: true,
      translate: true,
      sortable: true,
      style: {
        // flex: true,
        width: 200,
      },
    },
    {
      property: "organization",
      table: "organization",
      translate: true,
      sortable: true,
      sortBy: "name",
      type: "text",
      filterable: true,
      hide: false,
      style: {
        flex: true,
      },
      properties: [
        {
          property: "name",
          type: "text",
        },
      ],
    },
    {
      property: "email_address",
      table: "person",
      type: "text",
      filterable: true,
      translate: true,
      sortable: true,
      style: {
        flex: true,
      },
    },
    {
      property: "active",
      table: "person",
      type: "boolean",
      translate: true,
      sortable: true,
      style: {
        align: "center",
        headerAlign: "center",
      },
    },

    // {
    //     property: 'deleted',
    //     display: 'deleted',
    //     table: 'person',
    //     type: 'boolean',
    //     translate: true,
    //     sortable: true,
    //     style: {
    //         align: "center",
    //         headerAlign: "center"
    //     }
    // }
  ],
  order_by: {
    type: "asc",
    property: "alias",
  },
};

export default personJson;
