const styles = (theme) => ({
  container: {
    margin: "10px",
    cursor: "pointer",
  },
  text: {
    marginLeft: "10px",
  },
});

export default styles;
