const components = {
  "components.search": "Rechercher",

  // Nouvelles traductions
  "components.leftSideBarMenuList.TitleA": "SIX STAR",
  "components.leftSideBarMenuList.TitleB": "GÉRER",
  
  // Vérification du mot de passe
  "components.passwordVerifyComponent.mustContain": "Le mot de passe doit contenir les éléments suivants : ",
  "components.passwordVerifyComponent.upperCase": "Une lettre majuscule",
  "components.passwordVerifyComponent.lowerCase": "Une lettre minuscule",
  "components.passwordVerifyComponent.number": "Un chiffre",
  "components.passwordVerifyComponent.min": "Minimum 8 caractères",
  "components.passwordVerifyComponent.special": "Un caractère spécial",
  "components.passwordVerifyComponent.match": "Les mots de passe doivent correspondre",
  "components.passwordVerifyComponent.enterNew": "Entrez votre nouveau mot de passe",
  "components.passwordVerifyComponent.confirm": "Confirmez votre nouveau mot de passe",
  "components.passwordVerifyComponent.random": "Votre mot de passe aléatoire est :",
  "components.passwordVerifyComponent.copiedToClipboard": "Copié dans le presse-papiers",
  "components.passwordVerifyComponent.makeNote": "Veuillez en prendre note.",
  
  // Réinitialisation du mot de passe
  "components.passwordReset.error": "Nous nous excusons, mais nous ne pouvons pas réinitialiser votre mot de passe pour le moment.",
  "components.passwordReset.submitRequest": "Veuillez soumettre une demande de support afin que nous puissions vous aider à réinitialiser votre mot de passe.",
  "components.passwordReset.thankYou": "Merci pour votre compréhension.",
  "components.passwprdReset.success.pleaseWait": "Veuillez patienter, réinitialisation du mot de passe",

};
export default components;
