import React, { useEffect, useRef, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TextField, Typography } from '@mui/material';
import { useClickOutside } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import usePermissions from 'containers/App/Hooks/usePermissions';

function ToolBarSubjectPlugin(props) {
  const { classes, theme, setCommentValues, ticket, commentValues } = props;
  const priority = useSelector((state) => state.app.config.ticket_priority);
  const [priorityOptions, setPriorityOptions] = useState([]);
  // const user = useSelector((state) => state.app.user);

  const permission = usePermissions({
    access: {
      type: 'one',
      priority: 1,
      permissions: [
        {
          key: 'CAN_EDIT_TICKET',
          value: true,
        },
      ],
    },
  });

  const defaultTheme = {
    palette: {
      priority: {
        low: '#8BC34A',
        normal: '#FFC107',
        high: '#F44336',
      },
    },
  };

  useEffect(() => {
    priority.map((item) => {
      //if not in the list, add it
      if (!priorityOptions.find((option) => option.value === item.id)) {
        setPriorityOptions((prevState) => [
          ...prevState,
          {
            label: item.name,
            icon: ['fas', 'circle'],
            value: item.id,
            iconColor: defaultTheme.palette.priority[item.name],
          },
        ]);
      }
    });
  }, []);
  return (
    <div className={`${classes['toolbar']} ${classes['comment-toolbar']}`}>
      <Box display={'flex'} alignItems={'center'} gap={2} marginLeft={1} width={'100%'}>
        <Typography variant={'body'}>Subject :</Typography>
        <TextField
          variant={'standard'}
          size={'small'}
          onChange={(e) => {
            setCommentValues((prevState) => {
              return { ...prevState, subject: e.target.value };
            });
          }}
          value={commentValues?.subject ?? ''}
          sx={{
            flexGrow: 1,
            marginRight: 2,
            '& .MuiInputBase-input': {
              fontWeight: 500,
            },
          }}
        />
      </Box>
      <div className='divider'></div>
      {permission.access && (
        <>
          <Select
            classes={classes}
            onChange={(value) => {
              setCommentValues((prevState) => {
                return { ...prevState, public: value };
              });
            }}
            options={[
              {
                label: 'Public',
                icon: ['fas', 'lock-open'],
                value: true,
                iconColor: 'black',
              },
              {
                label: 'Private',
                icon: ['fas', 'lock'],
                value: false,
                iconColor: 'black',
              },
            ]}
          />

          <div className='divider'></div>
          <Select
            classes={classes}
            onChange={(value) => {
              setCommentValues((prevState) => {
                return { ...prevState, priority_id: value };
              });
            }}
            options={priorityOptions}
            default={ticket?.priority.id}
          />
        </>
      )}
    </div>
  );
}

function Select({ options, classes, onChange, default: defaultValue }) {
  const buttonRef = useRef(null);
  const dropDownRef = useRef(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(false);
  const [blockType, setBlockType] = useState(options[0]?.label ?? '');
  const [icon, setIcon] = useState(options[0]?.icon ?? ['fas', 'question']);
  const [iconColor, setIconColor] = useState(options[0]?.iconColor ?? null);
  const [defaultSet, setDefaultSet] = useState(false);
  const ref = useClickOutside(() => setShowBlockOptionsDropDown(false));
  useEffect(() => {
    if (defaultValue && !defaultSet) {
      const defaultOption = options.find((option) => option.value === defaultValue);
      if (defaultOption) {
        setDefaultSet(true);
        setBlockType(defaultOption.label);
        setIcon(defaultOption.icon);
        setIconColor(defaultOption.iconColor);
      }
    }
  }, [defaultValue, defaultSet, options]);
  useEffect(() => {
    const buttonRefCurrent = buttonRef.current;
    const dropDown = dropDownRef.current;

    if (buttonRefCurrent !== null && dropDown !== null) {
      const { height } = buttonRefCurrent.getBoundingClientRect();
      dropDown.style.top = `${height + 7}px`;
      dropDown.style.right = `0px`;
    }
  }, [dropDownRef, buttonRef, showBlockOptionsDropDown]);

  const handleSelect = (option) => {
    setShowBlockOptionsDropDown(false);
    setBlockType(option.label);
    setIcon(option.icon);
    setIconColor(option.iconColor);
    onChange(option.value);
  };
  return (
    <div>
      <button ref={buttonRef} className='toolbar-item block-controls' onClick={() => setShowBlockOptionsDropDown(!showBlockOptionsDropDown)} aria-label='Formatting Options'>
        <span className={'icon block-type'}>
          {' '}
          <FontAwesomeIcon color={iconColor} icon={icon} fixedWidth />{' '}
        </span>

        <span className='text'>{blockType}</span>
        <i className='chevron-down' />
      </button>
      {showBlockOptionsDropDown && (
        <div className={classes['dropdown']} ref={dropDownRef}>
          <div ref={ref}>
            {options.map((option, index) => (
              <button key={index} className='item' onClick={() => handleSelect(option)}>
                {icon && (
                  <span className={'icon '}>
                    {' '}
                    <FontAwesomeIcon color={option.iconColor} icon={option.icon} fixedWidth />{' '}
                  </span>
                )}
                <span className='text'>{option.label}</span>
                {blockType === option.label && <span className='active' />}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ToolBarSubjectPlugin;
