import themeManage from "./manage";
import ThemeMeet from "./meet";
import ThemeAurelia from "./theme-aurelia";
import ThemeQuiet from "./theme-quiet";
import ThemeSky from "./theme-sky";

const getCurrentTheme = (currentTheme) => {
  let muiTheme;
  switch (currentTheme) {
    case "manage":
      muiTheme = themeManage;
      break;
    case "meet":
      muiTheme = ThemeMeet;
      break;
    case "aurelia":
      muiTheme = ThemeAurelia;
      break;
    case "quiet":
      muiTheme = ThemeQuiet;
      break;
    case "sky":
      muiTheme = ThemeSky;
      break;
    default:
      muiTheme = themeManage;
      break;
  }

  return muiTheme;
};

export default getCurrentTheme;
