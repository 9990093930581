import React, { useEffect, useState } from 'react';
import { Button, Modal, Backdrop, Fade } from "@mui/material"
import styles from "./styles";
import withStyles from "@mui/styles/withStyles";
import { useSelector } from "react-redux";
import { useMutation, gql } from '@apollo/client';
import { updateFirstTimeSetup } from "slices/user";
import { useDispatch } from "react-redux";
import Translation from 'utils/translation';

const FirstTimeSetup = (props) => {
    const { classes } = props;
    const [open, setOpen] = useState(false);

    const user = useSelector(state => state.user.user);
    const dispatch = useDispatch();

    const FIRST_USER = gql`
        mutation updateFirstTimeSetupGQL($userID: uuid!) {
            update_person(_set: {first_time_setup: false}, where: {id: {_eq: $userID}}) {
                affected_rows
            }
        }
    `;

    const [firstTimeUser, { loading, error, data }] = useMutation(FIRST_USER, {
        variables: { userID: user.id },
    });

    useEffect(() => {
        if (error) {
          //  console.log(error)
        }
    }, [error]);

    useEffect(() => {
        if (user.first_time_setup) {
            setOpen(true);
        }
    }, [user]);

    const handleClose = () => {
        setOpen(false);
        firstTimeUser()
        dispatch(updateFirstTimeSetup())
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                onClick={() => handleClose()}
            >
                <div class={classes.modelContainer}>
                    <div class={classes.modelContent}>
                        <h1 class={classes.center}>{Translation("firstTimeSetupPage.firstLookAtManage")}</h1> 
                        <h3 class={classes.center}>{Translation("firstTimeSetupPage.elevatingYourITManagementExperience")}</h3>
                        <img class={classes.center} width={400} src="https://images.squarespace-cdn.com/content/5794a11a414fb5c7c36ce4aa/3a3ecf23-449a-4c99-bb5e-d3f5ea9a0434/iphone+laptop.png?content-type=image%2Fpng&format=750w" />
                        <h3 class={classes.center}>{Translation("firstTimeSetupPage.weAreThrilledToPresent")}</h3>
                        <p class={classes.center}>{Translation("firstTimeSetupPage.asAValuedMember")}</p>
                        <h3 class={classes.center}>{Translation("firstTimeSetupPage.aNewChapterInITManagement")}</h3>
                        <h4>{Translation("firstTimeSetupPage.technologicalTakeOver")}</h4>
                        <p>{Translation("firstTimeSetupPage.redesign")}</p>
                        <h4>{Translation("firstTimeSetupPage.effortlessLicenseOverview")}</h4>
                        <p>{Translation("firstTimeSetupPage.simplifyLicenceManagement")}</p>
                        <h4>{Translation("firstTimeSetupPage.smartReportGeneration")}</h4>
                        <p>{Translation("frstTimeSetupPage.harnessThePowerOfData")}</p>
                        <h4>{Translation("firstTimeSetupPage.tailoredDashboardAtYourFingertips")}</h4>
                        <p>{Translation("firstTimeSetupPage.craftDashboard")}</p>
                        <img class={classes.center} width={400} src="https://images.squarespace-cdn.com/content/5794a11a414fb5c7c36ce4aa/cb31c594-b416-454c-abed-763ea420d204/preview.png?content-type=image%2Fpng&format=750w" />
                        <h3 class={classes.center}>{Translation("firstTimeSetupPage.benefitsTitle")}</h3>
                        <h4>{Translation("firstTimeSetupPage.amplifiedEfficiency")}</h4>
                        <p>{Translation("firstTimeSetupPage.guestExperience")}</p>
                        <h4>{Translation("firstTimeSetupPage.realTimeIntelligence")}</h4>
                        <p>{Translation("firstTimeSetupPage.instantlyAccess" )}</p>
                        <h4>{Translation("firstTimeSetupPage.tailoredToYou")}</h4>
                        <p>{Translation("firstTimeSetupPage.customiseDashboard" )}</p>
                        <h4>{Translation("firstTimeSetupPage.futureProof")}</h4>
                        <p>{Translation("firstTimeSetupPage.atThisIminentLaunch")}</p>
                        <Button variant="contained" color="secondary" onClick={handleClose} fullWidth={true}>{Translation("firstTimeSetUpPage.button.getStarted")}</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default withStyles(
    (theme) => ({
        ...styles(theme),
    }),
    {
        withTheme: true,
    }
)(FirstTimeSetup);