// ********** Login **********
export const LOGIN_SSO = "websocket:LOGIN_SSO";
export const LOGIN_SSO_SUCCESS = "websocket:LOGIN_SSO/SUCCESS";
export const LOGIN_SSO_FAIL = "websocket:LOGIN_SSO/FAIL";

// ********** Login **********
export const REFRESH_SSO = "websocket:SSO_REFRESH_TOKEN";
export const REFRESH_SSO_SUCCESS = "websocket:SSO_REFRESH_TOKEN/SUCCESS";
export const REFRESH_SSO_FAIL = "websocket:SSO_REFRESH_TOKEN/FAIL";

// ********** Login **********
export const LOGIN = "websocket:LOGIN";
export const LOGIN_SUCCESS = "websocket:LOGIN/SUCCESS";
export const LOGIN_FAIL = "websocket:LOGIN/FAIL";

// ********** Login Code **********
export const LOGIN_CODE = "websocket:LOGIN_CODE";
export const LOGIN_CODE_SUCCESS = "websocket:LOGIN_CODE/SUCCESS";
export const LOGIN_CODE_FAIL = "websocket:LOGIN_CODE/FAIL";

export const LOGOUT_SOCKET_SERVER = "websocket:LOGOUT_SOCKET_SERVER";

// ********** Notifications **********
export const SEND_NOTIFICATION = "websocket:SEND_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "websocket:SEND_NOTIFICATION/SUCCESS";
export const SEND_NOTIFICATION_FAIL = "websocket:SEND_NOTIFICATION/FAIL";

// ********** Navigation **********
export const NAVIGATION_JOIN_PAGE = "websocket:NAVIGATION_JOIN_PAGE";
export const NAVIGATION_JOIN_PAGE_SUCCESS =
  "websocket:NAVIGATION_JOIN_PAGE/SUCCESS";
export const NAVIGATION_JOIN_PAGE_FAIL = "websocket:NAVIGATION_JOIN_PAGE/FAIL";

// ********** Tokens **********
export const REFRESH_TOKEN = "websocket:REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "websocket:REFRESH_TOKEN_SUCCESS/SUCCESS";
export const REFRESH_TOKEN_FAIL = "websocket:REFRESH_TOKEN_FAIL/FAIL";

// ********** Register Session **********
export const REGISTER_SESSION = "websocket:REGISTER_SESSION";
export const REGISTER_SESSION_SUCCESS = "websocket:REGISTER_SESSION/SUCCESS";
export const REGISTER_SESSION_FAIL = "websocket:REGISTER_SESSION/FAIL";

// ********** Elastic Search **********
export const ELASTIC_SEARCH = "websocket:ELASTIC_SEARCH";
export const ELASTIC_SEARCH_SUCCESS = "websocket:ELASTIC_SEARCH/SUCCESS";
export const ELASTIC_SEARCH_FAIL = "websocket:ELASTIC_SEARCH/FAIL";

// ********** Chat GPT **********
export const CHAT_GPT = "websocket:CHAT_GPT";
export const CHAT_GPT_SUCCESS = "websocket:CHAT_GPT/SUCCESS";
export const CHAT_GPT_FAIL = "websocket:CHAT_GPT/FAIL";

// websocket: REFRESH_TOKEN

// ********** Power BI Auth **********
export const POWER_BI_AUTH = "websocket:POWER_BI_AUTH";
export const POWER_BI_AUTH_SUCCESS = "websocket:POWER_BI_AUTH/SUCCESS";
export const POWER_BI_AUTH_FAIL = "websocket:POWER_BI_AUTH/FAIL";
