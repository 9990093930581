import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
};

export const onlineUsers = createSlice({
  name: "onlineUsers",
  initialState,
  reducers: {
    setOnlineUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const { setOnlineUsers } = onlineUsers.actions;

export default onlineUsers.reducer;
