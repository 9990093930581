import {
  OPEN_VIEW,
  OPEN_DYNAMIC_VIEW,
  CLOSE_VIEW,
  SELECT_MENU_ITEM,
  OPEN_SETTING_DRAWER,
  CLOSE_SETTING_DRAWER,
  SHOW_HIDE_OPEN_VIEWS,
  SHOW_HIDE_HEADER_TABS,
  SHOW_SEARCH,
  CHANGE_MENU,
  OPEN_DYNAMIC_PARENT_VIEW,
} from "./constants";
export function changeMenu(menu) {
  return {
    type: CHANGE_MENU,
    menu,
  };
}
export function openView(menuItem, icon, openViews) {
  return {
    type: OPEN_VIEW,
    menuItem,
    icon,
    openViews,
  };
}

export function openDynamicView(dynamicItem, parentItem, openViews) {
  return {
    type: OPEN_DYNAMIC_VIEW,
    dynamicItem,
    parentItem,
    openViews,
  };
}
export function openDynamicParentView(dynamicItem, parentItem, openViews) {
  return {
    type: OPEN_DYNAMIC_PARENT_VIEW,
    dynamicItem,
    parentItem,
    openViews,
  };
}
export function selectMenuItem(
  foundMenuItem,
  foundOpenViewItem,
  openViews,
  activeSubMenuItem,
  topLevel
) {
  return {
    type: SELECT_MENU_ITEM,
    foundMenuItem,
    foundOpenViewItem,
    openViews,
    activeSubMenuItem,
    topLevel,
  };
}

export function closeView(foundMenuItem, foundOpenViewItem, idsToBeRemoved) {
  return {
    type: CLOSE_VIEW,
    foundMenuItem,
    foundOpenViewItem,
    idsToBeRemoved,
  };
}

export function openSettingsDrawer() {
  return {
    type: OPEN_SETTING_DRAWER,
  };
}

export function closeSettingsDrawer() {
  return {
    type: CLOSE_SETTING_DRAWER,
  };
}

export function showHideOpenViews(showHide) {
  return {
    type: SHOW_HIDE_OPEN_VIEWS,
    showHide,
  };
}

export function showHideHeaderTabs(showHide) {
  return {
    type: SHOW_HIDE_HEADER_TABS,
    showHide,
  };
}

export function showHideSearch(value) {
  return {
    type: SHOW_SEARCH,
    value,
  };
}
