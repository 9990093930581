import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Box, Button, Stack, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import Translation from 'utils/translation';
import { SidePanelBoldTitle, SidePanelItemCard, SidePanelParagraph } from 'containers/Pages/TicketViewPage/components/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import useWarningNotification from 'utils/useWarningNotification';
import { useNavigate } from 'react-router-dom';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';

const Locations = (props) => {
  const { company } = props;
  return (
    <>
      {company?.organization?.[0]?.locations?.map((location, index) => (
        <SidePanelItemCard key={index}>
         
            <SidePanelParagraph>{location?.address_line_one}</SidePanelParagraph>
         
        
            
              <SidePanelParagraph>{location?.address_line_two}</SidePanelParagraph>
            
          
              <SidePanelParagraph>
                {location?.city}
              </SidePanelParagraph>

              <SidePanelParagraph>
                {location?.country?.name}
            </SidePanelParagraph>
        
            
            <SidePanelParagraph>
                {location?.zip_code}
            </SidePanelParagraph>
        
        </SidePanelItemCard>
      ))}
    </>
  );
};

export default Locations;
