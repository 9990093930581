import React from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import Button from "components/MuiOverride/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Translation from "utils/translation";
import { useNavigate } from 'react-router-dom';

function ContactUsTopBox(props) {
    const { classes, icon, text, handleClick } = props;
    const navigate = useNavigate(); 

    // const handleClick = () => {
    //     if (url) {
    //       navigate(url);
    //     }
    //   };

    return (
        <div className={classes.container} onClick={handleClick}>
            <FontAwesomeIcon icon={icon} size="2x" />

            <div className={classes.textContainer}>
                {text}
            </div>
            <Button 
            metadata="call_us_button"
            disabled={false} className={classes.callUsButton} variant="contained">
                {Translation("contactUsPage.number.button")}
            </Button>
        </div>
    );
}

export default withStyles(
    (theme) => ({
        ...layoutStyles(theme),
        ...styles(theme),
    }),
    { withTheme: true }
)(ContactUsTopBox);
