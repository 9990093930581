const components = {
  "components.search": "Cerca",

  // Nuove traduzioni
  "components.leftSideBarMenuList.TitleA": "SIX STAR",
  "components.leftSideBarMenuList.TitleB": "GESTISCI",
  
  // Verifica password
  "components.passwordVerifyComponent.mustContain": "La password deve contenere quanto segue: ",
  "components.passwordVerifyComponent.upperCase": "Una lettera maiuscola",
  "components.passwordVerifyComponent.lowerCase": "Una lettera minuscola",
  "components.passwordVerifyComponent.number": "Un numero",
  "components.passwordVerifyComponent.min": "Minimo 8 caratteri",
  "components.passwordVerifyComponent.special": "Un carattere speciale",
  "components.passwordVerifyComponent.match": "Le password devono corrispondere",
  "components.passwordVerifyComponent.enterNew": "Inserisci la tua nuova password",
  "components.passwordVerifyComponent.confirm": "Conferma la tua nuova password",
  "components.passwordVerifyComponent.random": "La tua password casuale è:",
  "components.passwordVerifyComponent.copiedToClipboard": "Copiato negli appunti",
  "components.passwordVerifyComponent.makeNote": "Si prega di prendere nota di questo.",
  
  // Reset password
  "components.passwordReset.error": "Ci scusiamo, ma al momento non siamo in grado di reimpostare la tua password.",
  "components.passwordReset.submitRequest": "Si prega di inviare una richiesta di supporto in modo che possiamo assisterti nella reimpostazione della password.",
  "components.passwordReset.thankYou": "Grazie per la comprensione.",
  "components.passwprdReset.success.pleaseWait": "Attendere prego, reimpostando la password",

};
export default components;
