import React from 'react';
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import {setQuickCreate} from 'slices/app'
import { useDispatch, useSelector } from 'react-redux';


const HeaderButton = ({ icon, link, onClick, quickCreate,  }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const roles = useSelector((state) => state?.user?.user?.permissions?.roles);
    const Icon = () => {
        return React.createElement(icon);
    }
    const handleButtonClick = () => {
        if (quickCreate && roles.includes('agent') || roles.includes("engineer")) {
            dispatch(setQuickCreate());
            return;
        }
        else{
            navigate('support/new-request');
        }
        if (onClick) {
            onClick();
        } else if (link) {
            navigate(link);
        }
    };

    return (
        <IconButton
            aria-label="iconButton"
            onClick={handleButtonClick}
            size="large"
        >
            <Icon />
        </IconButton>

        
        
    );
};

export default HeaderButton;
