import { useMutation } from "@apollo/client";
import {graphQLMutations} from "graphql/datatableview";

export const useDatatableviewUpdate = (props) => {
    const {
        person_id = "",
        viewUpdateData = {
            sort_model: {},
            state: {},
            filter_model: {},
            datatable: "",
            name: "",
            route: ""
        },
    } = props

    console.log(props)

    const { INSERT_DATATABLE_VIEW } = graphQLMutations();
    const [insertView, { error: datatableviewInsertError, data: datatableviewInsertData }] = useMutation(
        INSERT_DATATABLE_VIEW,
        {
            variables: { person_id: viewUpdateData.person_id, 
                sort_model: viewUpdateData.sort_model, 
                state: viewUpdateData.state, 
                filter_model: viewUpdateData.filter_model, 
                datatable: viewUpdateData.datatable, 
                name: viewUpdateData.name, 
                route: viewUpdateData.route },
        }
    );
    // const [deleteView, { error: datatableviewDeleteError, data: datatableviewDeleteData }] = useMutation(
    //     DELETE_DATATABLE_VIEW,
    //     {
    //         variables: { _eq: id },
    //     }
    // );
    // const [updateView, { error: datatableviewUpdateError, data: datatableviewUpdateData }] = useMutation(
    //     UPDATE_DATATABLE_VIEW,
    //     {
    //         variables: { _eq: id, sort_model: sort_model, state: state, filter_model: filter_model, name: name },
    //     }
    // );
    // switch (action) {
    //     case 'insert':
    //         if (datatableviewInsertError) {
    //             console.log(datatableviewInsertError);
    //             return false
    //         } else {
    //             return datatableviewInsertData
    //         }
    //     case 'delete':
    //         if (datatableviewDeleteError) {
    //             console.log(datatableviewDeleteError);
    //             return false
    //         } else {
    //             return datatableviewDeleteData
    //         }
    //     case 'update':
    //         if (datatableviewUpdateError) {
    //             console.log(datatableviewUpdateError);
    //             return false
    //         } else {
    //             return datatableviewUpdateData
    //         }
    //     default:
    //         return false
    // }
    return insertView
}

export default useDatatableviewUpdate;