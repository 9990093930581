import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import PageBase from "components/PageBase";
import styles from "./styles";
import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import useErrorNotification from "utils/useErrorNotification";
import theme from "slices/theme";
import LoadingIndicator from "components/LoadingIndicator";
import Button from "components/MuiOverride/Button";
import Box from "@mui/material/Box";

function Payslips() {
  const user = useSelector((state) => state.user.user);
  const { alias: userAlias, organization, email_address } = user;
  const { alias: organizationAlias } = organization;

  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [formSuccessMessage, setFormSuccessMessage] = useState("");
  const [startProcessing, setStartProcessing] = useState(false);

  const [sendErrorNotification] = useErrorNotification();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    setFormError(false);
    setFormSuccess(false);
    setFormErrorMessage("");
    setFormSuccessMessage("");
    setStartProcessing(true);

    console.log('starting')
    axios.post('https://hook.integromat.com/7u7gxnny1lcwlvjy6j9wlxcvvi9bc06i', formData)
      .then(response => {
        if (response?.data?.error) {
          setFormErrorMessage(response?.data?.message ?? "There was an error");
          setFormError(true);
          return
        } else {
          setFormSuccessMessage(response?.data?.message ?? "Your payslip has been uploaded successfully.")
          setFormSuccess(true);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
        setFormErrorMessage("There was an error uploading your payslip. Please try again. If the problem persists, please contact support.");
        setFormError(true);
      }).finally(() => {
        setStartProcessing(false);
      })
  }

  return (
    <PageBase title="Payslips">
      <Card>
        {startProcessing ? (
          <CardContent>
            <LoadingIndicator />
          </CardContent>
        ) : (
          <CardContent>
            {formError && (
              <Typography variant="h5" component="h2" sx={{ color: 'red' }}>
                {formErrorMessage}
              </Typography>
            )}
            {formSuccess ? (
              <Typography variant="h5" component="h2">
                <Box>{formSuccessMessage}</Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: "20px" }}
                  onClick={() => {
                    setFormSuccess(false);
                    setFormError(false);
                    setFormErrorMessage("");
                    setFormSuccessMessage("");
                    setStartProcessing(false);
                  }}
                >
                  Upload Another Payslip
                </Button>
              </Typography>
            ) : (
              <>
                <h1 className="text-center mb-4">Upload Payslips</h1>
                <form onSubmit={handleSubmit} enctype="multipart/form-data" className="">
                  <div className="card rounded p-4 mb-4">
                    <h3 className="text-center">Step 1</h3>
                    <p>Click on the "Choose File" button to select a single payslip PDF file or a valid ZIP file containing multiple
                      PDF
                      payslip files.</p>

                    <p>Please note, each payslip PDF file name must start with P_SLP and contain a valid employee number that
                      matches an
                      employee number saved in BambooHR:</p>


                    <div className="form-group">
                      <input type="file" accept="zip,application/zip,application/x-zip,application/x-zip-compressed" id="file" name="file" className="form-control-file" />
                      <input type="hidden" id="userID" name="userID" value={userAlias} />
                      <input type="hidden" id="companyID" name="companyID" value={organizationAlias} />
                      <input type="hidden" id="userEmail" name="userEmail" value={email_address} />
                    </div>
                  </div>
                  <div className="card rounded p-4">
                    <h3 className="text-center">Step 2</h3>
                    <p>Click on the "Submit" button. </p>
                    <input type="submit" className="btn btn-primary mb-2" />
                  </div>
                </form>
              </>
            )}
          </CardContent>
        )}
      </Card>
    </PageBase>
  );
}

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(Payslips);
