const misc = {
  'tooltip.disabled': 'Deshabilitado',
  'languageSelector.english': 'Inglés',
  'languageSelector.dutch': 'Holandés',
  'languageSelector.german': 'Alemán',
  'languageSelector.french': 'Francés',
  'languageSelector.spanish': 'Español',
  'languageSelector.polish': 'Polaco',
  'languageSelector.italian': 'Italiano',
  'languageSelector.thai': 'Tailandés',
  'datatable.toolbar.quickFilterPlaceholder': 'Buscar...',
  'datatable.toolbar.columns': 'Columnas',
  'datatable.toolbar.density': 'Densidad',
  'datatable.toolbar.densityComfortable': 'Cómoda',
  'datatable.toolbar.densityCompact': 'Compacta',
  'datatable.toolbar.densityStandard': 'Estándar',
  'datatable.toolbar.export': 'Exportar',
  'datatable.toolbar.filters': 'Filtros',
  'socket.connected': 'Conectado',
  'socket.disconnected': 'Desconectado',
  'profile.menu.viewProfile': 'Ver Perfil',
  'profile.menu.editProfile': 'Editar Perfil',
  'profile.menu.help': 'Ayuda',
  'profile.menu.signOut': 'Cerrar Sesión',
  'addon.add': 'Agregar',
  'addon.modal.save': 'Guardar',
  'addon.edit': 'Editar',
  'notFoundPage.pageDevelopment': 'Página en Desarrollo',
  'notFoundPage.pageDevelopmentMessage':
    'Gracias por explorar nuestro sitio web. Esta página en particular aún está en desarrollo ya que estamos en nuestra fase beta. Apreciamos su paciencia y comprensión.',
  'notFoundPage.pageDevelopmentMessage2':
    'Tenga en cuenta que nuestro sitio está en constante actualización y mejoras para brindarle la mejor experiencia posible. ¡Vuelva pronto para ver nuevos contenidos y características!',
  general: 'General',
  'ticket.role.first_responder': 'Primer Respondedor',
  'ticket.role.first_responder.tooltip':
    'Primer Respondedor: Punto de contacto inicial para problemas de TI de alta prioridad, responsable de una resolución rápida y tiempo de inactividad mínimo.',
  'ticket.role.lead_engineer': 'Ingeniero Líder',
  'ticket.role.lead_engineer.tooltip':
    'Ingeniero Líder: Responsable de la dirección técnica general del proyecto y de asegurar que el proyecto se complete a tiempo y dentro del presupuesto.',
  'ticket.status_id': 'Estado',
  'ticket.priority_id': 'Prioridad',
  'ticket.closed_at': 'Cerrado En',
  'ticket.subject': 'Asunto',
  'team.team': 'Equipo',
  'ticket.created_at': 'Creado En',
  'ticket.updated_at': 'Actualizado En',
  'ticket.requester_id': 'Solicitante',
  'ticket.organization_id': 'Organización',
  'ticket.assignee_id': 'Asignado',
  'ticket.author_id': 'Autor',
  'ticket.public': 'Público',
  'ticket.alias': 'ID',
  'ticket.status': 'Estado',
  'ticket.priority': 'Prioridad',
  'ticket.team_id': 'Equipo',
  'ticket.assignee': 'Asignado',
  'ticket.requester': 'Solicitante',
  'ticket.has_incidents': 'Tiene Incidentes',
  'ticket.updated_by_id': 'Actualizado Por',
  'ticket.author': 'Autor',
  'person.assignee': 'Asignado',
  'person.requester': 'Solicitante',
  'ticket_status.status': 'Estado',
  'ticket_priority.priority': 'Prioridad',
  'organization.organization': 'Empresa',
  'ticket_priority.priority.normal': 'Normal',
  'ticket_priority.priority.high': 'Alta',
  'ticket_priority.priority.low': 'Baja',
  'ticket_status.status.new': 'Nuevo',
  'ticket_status.status.pending': 'Pendiente',
  'ticket_status.status.hold': 'En Espera',
  'ticket_status.status.open': 'Abierto',
  'ticket_status.status.resolved': 'Resuelto',
  'person.name': 'Nombre',
  'person.avatar': 'Avatar',
  'person.email_address': 'Correo Electrónico',
  'person.active': 'Activo',
  'person.alias': 'ID',
  'organization.name': 'Nombre',
  'organization.active': 'Activo',
  'organization.alias': 'ID',
  'board.board': 'Tablero',

  // new translations
  'dateTime.utc': 'UTC',
  'ticket.devices.RMM.install': 'Instalar Six Star RMM',
  'ticket.devices.RMM.login': 'Iniciar sesión en Six Star RMM',
  'ticket.devices.RMM.title': 'Para ver la información más reciente',

  // Filtros de la tabla de datos
  'dataTable.filters.reset': 'Restablecer',
  'dataTable.filters.loading': 'Cargando...',
  'dataTable.filters.header': 'Filtros',

  // Vista previa de la tabla de datos
  'dataTable.preview.viewTicket': 'Ver ticket',
  'dataTable.preview.quickNotes': 'Notas rápidas',
  'dataTable.preview.contacts': 'Contactos',
  'dataTable.preview.followers': 'Seguidores',
  'dataTable.preview.relatedTickets': 'Tickets relacionados',
  'dataTable.preview.updateIn': 'Actualizar en',
  'dataTable.preview.devices': 'Dispositivos',
  'dataTable.preview.engineer': 'Ingeniero',
  'dataTable.preview.ticketCreated': 'Ticket creado:',

  // ChatGPT
  'chatgpt.somethingWentWrong': 'Algo salió mal, por favor, inténtelo de nuevo',
  'chatgpt.history': 'Historial',

  // Botón de grupo personalizado
  'customGroupButton.one.expandAll': 'Expandir todo',
  'customGroupButton.two.collapseAll': 'Colapsar todo',

  // Números de bandera
  'flagNumber.button.callUs': 'Llámenos',

  "HelpOptions.Title": "¿Cómo podemos ayudarte?",
  "FloatingBeta.ReportIssue": "Informar un problema",
  "FloatingBeta.Feedback": "Enviar comentarios",
  "dateTime.utc": "UTC",
  "ticket.devices.RMM.install": "Instalar Six Star RMM",
  "ticket.devices.RMM.login": "Iniciar sesión en Six Star RMM",
  "ticket.devices.RMM.title": "Para ver la información más reciente",
  "dataTable.filters.reset": "Restablecer",
  "dataTable.filters.loading": "Cargando...",
  "dataTable.filters.header": "Filtros",
  "dataTable.preview.viewTicket": "Ver ticket",
  "dataTable.preview.quickNotes": "Notas rápidas",
  "dataTable.preview.contacts": "Contactos",
  "dataTable.preview.followers": "Seguidores",
  "dataTable.preview.relatedTickets": "Tickets relacionados",
  "dataTable.preview.updateIn": "Actualizar en",
  "dataTable.preview.devices": "Dispositivos",
  "dataTable.preview.engineer": "Ingeniero",
  "dataTable.preview.ticketCreated": "Ticket creado: ",
  "chatgpt.somethingWentWrong": "Algo salió mal. Por favor, inténtelo de nuevo.",
  "chatgpt.history": "Historial",
  "customGroupButton.one.expandAll": "Expandir todo",
  "customGroupButton.two.collapseAll": "Colapsar todo",
  "flagNumber.button.callUs": "Llámanos",
  "profile.menu.viewCompany": "Ver empresa",
  "contactUsModals.addWidgetModal.title": "Cómo agregar un widget a tu panel de control",
  "contactUsModals.addWidgetModal.step1": "1. Navega a tu panel de control",
  "contactUsModals.addWidgetModal.step2": "2. Localiza la cinta morada en la parte superior de tu pantalla",
  "contactUsModals.addWidgetModal.step3": "3. Haz clic en el botón agregar widget",
  "contactUsModals.addWidgetModal.step4": "4. Selecciona un widget de la lista",
  "contactUsModals.addWidgetModal.step5": "5. Haz clic en el botón agregar",
  "contactUsModals.signOutModal.title": "Cómo cerrar sesión",
  "contactUsModals.signOutModal.step1": "1. Encuentra tu avatar en la parte superior derecha de la pantalla",
  "contactUsModals.signOutModal.step2": "2. Haz clic en tu avatar",
  "contactUsModals.signOutModal.step3": "3. Encuentra la opción de cerrar sesión en el menú desplegable",
  "contactUsModals.signOutModal.step4": "4. Haz clic en la opción de cerrar sesión",
  "TicketViewPage.toolbar.label": "Buscar tickets",
  "TicketViewPage.toolbar.view": "Ver",
  "TicketViewPage.toolbar.saveView": "Guardar vista",
  "TicketViewPage.toolbar.saveViewPrompt": "Por favor, ingrese el nombre con el que desea guardar esta vista.",
  "TicketViewPage.toolbar.label.export": "Exportar",
  "TicketViewPage.toolbar.label.filters": "Filtros",
  "formLoader.submissionSuccess": "Gracias por enviar el formulario",
  "formLoader.submissionError": "Hubo un error al enviar el formulario",
  "reportIssue.submit": "Enviar",
  "reportIssue.issue": "Problema...",
  "ticketViewPage.modal.button.bunle": "Paquete",
  "ticketViewPage.modal.button.back": "Atrás",
  "flagNumber.button.callUs": "Llámanos",
  "ticketDocuments.notFound": "No se encontraron documentos",
  "ticketViewPage.childTicketsNone": "No hay tickets secundarios",
  "ticketViewPage.parentNone": "No hay ticket principal",
  "ticketViewPage.restricted": "Restringido",
  "ticketViewPage.modal.button.close": "Cerrar",
  "tooltip.edit": "Editar",
  "tooltip.notifcations": "Notificaciones",
  "tooltip.chooseLanguage": "Elegir idioma",
  "tooltip.logTicket": "Registrar ticket",
  "tooltip.contactUs": "Contáctanos",
  "tooltip.hideTray": "Ocultar bandeja",
  "tooltip.notifications": "Notificaciones"

};
export default misc;
