import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextField, Tooltip } from '@mui/material';
import Avatar from "@mui/material/Avatar";
import { getInitials } from "utils/helperFunctions";
import classNames from "classnames";



function HeaderComponent(props) {
  const { user, company } = props;
  //console.log('USER', user);
 // console.log('headerc COMPANY', company);
  return (
    < Box
      sx={{
        top: 0,
        right: 0,
        backgroundColor: "#FFFFFF",
        padding: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: 60,
      }}
    >
      {user?.person[0].avatar  ? (
        <Avatar
        style={{ marginLeft: 10 }}
          className={classNames(
            // classes.avatar,
            // socketStatus
            //   ? classes.avatarConnected
            //   : classes.avatarDisconnected
          )}
          src={user?.person[0].avatar }
        ></Avatar>

      ) : (
        <Avatar
        style={{ marginLeft: 10 }}
          className={classNames(
            // classes.avatar,
            // socketStatus
            //   ? classes.avatarConnected
            //   : classes.avatarDisconnected
          )}
        >
          {getInitials(user?.person[0].name )}
        </Avatar>
      )}

      <Tooltip title={company?.organization?.[0]?.name}>
        <Typography variant='h6' style={{ marginLeft: 15, fontWeight: 'bold' }}>{company?.organization?.[0]?.name}</Typography>
      </Tooltip>
      <Box
        sx={{ ml: 'auto' }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          style={{ width: 250 }}
          disabled
        />
      </Box>
      </Box>

  );
}

export default HeaderComponent;
