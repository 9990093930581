import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import useErrorNotification from 'utils/useErrorNotification';
import { useDispatch } from 'react-redux';
import { cloneDeep, set } from 'lodash';
import { addTicket } from 'slices/tickets';
import { graphQLMutations } from 'graphql/ticket';
const useCommentPublicUpdate = (props) => {
  const { commentID = null, ticketID = null } = props ?? {};
  const [superLoading, setSuperLoading] = useState(false);
  const [sendErrorNotification] = useErrorNotification();
  const { followers } = useSelector((state) => state?.tickets[ticketID]?.current ?? []);
  const ticket = useSelector((state) => state?.tickets[ticketID] ?? null);
  const dispatch = useDispatch();
  const { UPDATE_TICKET_COMMENT_PUBLIC } = graphQLMutations();
  const [submitChangeCommentPublic, { data, loading, error }] = useMutation(UPDATE_TICKET_COMMENT_PUBLIC);

  const handleSubmitChangeCommentPublic = ({ value }) => {
    setSuperLoading(true);
    const variables = {
      commentID: commentID,
      public: value,
    };
    submitChangeCommentPublic({ variables });
  };
  //TODO: Fix this / Do we need it
  useEffect(() => {
    try {
      // if (data) {
      //   // let tempCuttentTicket = cloneDeep(ticket?.current);
      //   // let tempComments = tempCuttentTicket?.comments ?? [];
      //   // tempComments.map((item, index) => {
      //   //   if (item?.id === commentID) {
      //   //     set(tempComments, `comments[${index}]`, !item?.public);
      //   //   }
      //   // });
      //   // await dispatch(
      //   //   addTicket({ data: tempCuttentTicket, location: "current" })
      //   // );
      //   setSuperLoading(false);
      // } else {
      //   setSuperLoading(false);
      // }
      // setSuperLoading(false);
      if (loading) {
        setSuperLoading(true);
      } else {
        setSuperLoading(false);
      }
    } catch (error) {
      console.log(error);
      sendErrorNotification({
        error: error,
        location: 'Update Internal Flag Status',
      });
      setSuperLoading(false);
    }
  }, [data, loading]);

  useEffect(() => {
    if (error) {
      sendErrorNotification({
        error: error,
        location: 'Update Comment Status',
      });
    }
  }, [error]);

  return {
    handle: handleSubmitChangeCommentPublic,
    loading: superLoading,
  };
};

export default useCommentPublicUpdate;
