const widgets = {
    "widget.barChart.description": "นี่คือวิดเจ็ตทดสอบที่น่าสนใจมาก นำเข้าและสามารถใช้ได้ในประกอบกับส่วนเพิ่มเติมอื่น ๆ",
    "widget.organizationTickets.name": "วิดเจ็ตจัดระเบียบตั๋ว",
    "widget.organizationTickets.description": "นี่คือวิดเจ็ตที่แสดงจำนวนตั๋วที่เปิดที่มีลำดับความสำคัญ 1",
    "widget.outlookInbox.name": "วิดเจ็ตกล่องจดหมาย Outlook",
    "widget.outlookInbox.description": "นี่คือวิดเจ็ตที่แสดงจำนวนอีเมลในกล่องขาเข้าของคุณ",
    "widget.personTickets.name": "วิดเจ็ตตั๋วส่วนตัว",
    "widget.personTickets.description": "นี่คือวิดเจ็ตที่แสดงจำนวนตั๋วที่เปิดที่มีลำดับความสำคัญ 1",
    "widget.pieChart.name": "วิดเจ็ตแผนภูมิวงกลม",
    "widget.pieChart.description": "นี่คือวิดเจ็ตทดสอบที่น่าสนใจมาก นำเข้าและสามารถใช้ได้ในประกอบกับส่วนเพิ่มเติมอื่น ๆ",
    "widget.quickLinks.name": "วิดเจ็ตลิงก์ด่วน",
    "widget.quickLinks.description": "นี่คือวิดเจ็ตที่แสดงรายการลิงก์ไปยังหน้าอื่น ๆ ในเว็บไซต์",
    "widget.social.name": "วิดเจ็ตฟีดโซเชียล",
    "widget.social.description": "นี่คือวิดเจ็ตฟีดโซเชียลที่เชื่อมต่อกับบัญชีโซเชียลมีเดียของ Six Star",
    "widget.ticketTable.name": "วิดเจ็ตตารางตั๋ว",
    "widget.ticketTable.description": "นี่คือวิดเจ็ตทดสอบที่น่าสนใจมาก นำเข้าและสามารถใช้ได้ในประกอบกับส่วนเพิ่มเติมอื่น ๆ",
    "widget.social.feedType": "Facebook / Twitter",
    "widget.quickLinks.newRequest": "คำขอใหม่",
    "widget.quickLinks.licenceManagement": "การจัดการใบอนุญาต",
    "widget.quickLinks.assetManagement": "การจัดการทรัพย์สิน",
    "widget.quickLinks.sixStarDrive": "Six Star Drive", 
    "widget.quickLinks.sixStarGlobal": "Six Star Global",

    "widget.quickLinks.button.newRequest": "คำขอใหม่",
"widget.quickLinks.button.licenceManagement": "การจัดการใบอนุญาต",
"widget.quickLinks.button.assetManagement": "การจัดการทรัพย์สิน",
"widget.quickLinks.button.sixStarDrive": "Six Star Drive",

"widgets.org.tickets.p1": "ตั๋วองค์กร P1",
"widgets.org.tickets.p2": "ตั๋วองค์กร P2",
"widgets.org.tickets.open": "ตั๋วองค์กร เปิด",

"widgets.personal.tickets.p1": "ตั๋วส่วนบุคคล P1",
"widgets.personal.tickets.p2": "ตั๋วส่วนบุคคล P2",
"widgets.personal.tickets.open": "ตั๋วส่วนบุคคล เปิด",

"widgets.personal.tickets.p1": "P1 ส่วนตัว",
"widgets.personal.tickets.p2": "P2 ส่วนตัว",
"widgets.personal.tickets.open": "เปิดส่วนตัว",
"widget.Guage.name": "วิดเจ็ตแสดงสถานะของตั๋วที่ได้รับการแก้ไข",
"widget.Guage.description": "นี่คือวิดเจ็ตที่แสดงจำนวนตั๋วที่ได้รับการแก้ไขจากคิววันนี้",
"widget.LineGraph.name": "วิดเจ็ตแสดงกราฟเส้น",
"widget.LineGraph.description": "นี่คือวิดเจ็ตที่แสดงจำนวนตั๋วที่สร้างขึ้นในช่วง 30 วันที่ผ่านมา",
"widget.muiBarChart.name": "วิดเจ็ตแสดงแผนภูมิแท่ง",
"widget.muiBarChart.description": "นี่คือวิดเจ็ตที่แสดงจำนวนตั๋วตามลำดับความสำคัญที่สร้างขึ้นในช่วง 30 วันที่ผ่านมา",
"widget.ReleaseNotes.name": "วิดเจ็ตแสดงหมายเหตุการออกเวอร์ชัน",
"widget.ReleaseNotes.description": "นี่คือวิดเจ็ตที่แสดงหมายเหตุการออกเวอร์ชันล่าสุด",
"widget.DailyUpdates.name": "วิดเจ็ตแสดงการอัปเดตประจำวัน",
"widget.DailyUpdates.description": "นี่คือวิดเจ็ตที่แสดงการอัปเดตประจำวัน",
"widget.TicketPieChart.name": "วิดเจ็ตแสดงแผนภูมิวงกลมของตั๋ว",
"widget.TicketPieChart.description": "นี่คือวิดเจ็ตที่แสดงจำนวนตั๋วตามสถานะ",
"widget.barchart.title" : "ตั๋วตามลำดับความสำคัญ",
"widget.linechart.title" : "ตั๋วที่สร้างขึ้นในช่วง 30 วันที่ผ่านมา",
"widget.piechart.title" : "ตั๋วตามสถานะ",
"widget.guage.title" : "ตั๋วที่ได้รับการแก้ไขจากคิววันนี้"
  
};

export default widgets;