const styles = (theme) => ({
  avatar: {
    width: 32,
    height: 32,
    fontSize: 14,
    backgroundColor: theme.palette.text.primary,
    borderStyle: "solid",
    borderWidth: "2px",
  },
  avatarConnected: {
    borderColor: theme.palette.success.main,
  },
  avatarDisconnected: {
    borderColor: theme.palette.error.main,
  },
});

export default styles;
