import { updateToken } from "slices/user";

const tokens = async (socket, store) => {
  socket.on("websocket:SET_NEW_TOKEN", (payload) => {
    // console.log({ "websocket:SET_NEW_TOKEN": payload });
    store.dispatch(updateToken(payload));
  });
};

export default tokens;
