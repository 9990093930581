import { setUpdateTicketTableID } from "slices/app";

const tableSubscription = async (socket, dispatch) => {
    try {
        socket.on("websocket:TABLE_SUBSCRIPTION", (payload) => {
            dispatch(setUpdateTicketTableID(payload));
        });
    } catch (error) {
        // console.log(error)
    }

};

export default tableSubscription;
