const components = {
  "components.leftSideBarMenuList.TitleA": "SIX STAR",
  "components.leftSideBarMenuList.TitleB": "BEHEREN",
  
  // Wachtwoordverificatie
  "components.passwordVerifyComponent.mustContain": "Het wachtwoord moet het volgende bevatten: ",
  "components.passwordVerifyComponent.upperCase": "Een hoofdletter",
  "components.passwordVerifyComponent.lowerCase": "Een kleine letter",
  "components.passwordVerifyComponent.number": "Een nummer",
  "components.passwordVerifyComponent.min": "Minimaal 8 tekens",
  "components.passwordVerifyComponent.special": "Een speciaal teken",
  "components.passwordVerifyComponent.match": "Wachtwoorden moeten overeenkomen",
  "components.passwordVerifyComponent.enterNew": "Voer uw nieuwe wachtwoord in",
  "components.passwordVerifyComponent.confirm": "Bevestig uw nieuwe wachtwoord",
  "components.passwordVerifyComponent.random": "Uw willekeurige wachtwoord is:",
  "components.passwordVerifyComponent.copiedToClipboard": "Gekopieerd naar klembord",
  "components.passwordVerifyComponent.makeNote": "Gelieve hier een notitie van te maken.",
  
  // Wachtwoord resetten
  "components.passwordReset.error": "Onze excuses, maar we kunnen uw wachtwoord momenteel niet opnieuw instellen.",
  "components.passwordReset.submitRequest": "Stuur alstublieft een ondersteuningsverzoek in zodat we u kunnen helpen met het opnieuw instellen van uw wachtwoord.",
  "components.passwordReset.thankYou": "Bedankt voor uw begrip.",
  "components.passwprdReset.success.pleaseWait": "Even geduld, wachtwoord wordt gereset",

};
export default components;
