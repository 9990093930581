const components = {
  "components.search": "Search",

  // new translations
  "components.leftSideBarMenuList.TitleA" : "SIX STAR",
  "components.leftSideBarMenuList.TitleB" : "MANAGE",

  //password verificiatiion
  "components.passwordVerifyComponent.mustContain" : "Password must contain the following: ",
  "components.passwordVerifyComponent.upperCase" : "An uppercase letter",
  "components.passwordVerifyComponent.lowerCase" : "A lowercase letter",
  "components.passwordVerifyComponent.number" : "A number",
  "components.passwordVerifyComponent.min" : "Minimum 8 characters",
  "components.passwordVerifyComponent.special" : "A special character",
  "components.passwordVerifyComponent.match" : "Passwords must match",
  "components.passwordVerifyComponent.enterNew" : "Enter your new password",
  "components.passwordVerifyComponent.confirm" : "Confirm your new password",
  "components.passwordVerifyComponent.random" : "Your random password is:",
  "components.passwordVerifyComponent.copiedToClipboard" : "Copied to clipboard",
  "components.passwordVerifyComponent.makeNote" : "Please make a note of this.",

  //password reset
  "components.passwordReset.error" : "We apologize, but we are unable to reset your password at this time.",
  "components.passwordReset.submitRequest" : "Please submit a support request so that we can assist you with resetting your password.",
  "components.passwordReset.thankYou" : "Thank you for your understanding.",
  "components.passwprdReset.success.pleaseWait" : "Please Wait, Resetting Password",
};
export default components;
