import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFoundPage from "containers/Pages/ErrorPages/NotFoundPage/Loadable";
import NotAuthorizedPage from "containers/Pages/ErrorPages/NotAuthorizedPage/Loadable";
import manage from "./Menus/manage";
import meet from "./Menus/meet";

//function to loop through array and any nested arrays of menu items and remove component key
const getMenu = (menu = "manage") => {
  let selectedMenu;
  switch (menu) {
    case "manage":
      selectedMenu = manage;
      break;
    case "meet":
      selectedMenu = meet;
      break;
    default:
      selectedMenu = manage;
      break;
  }

  // Assign index and parent fields for each item, which is needed for header tabs navigation
  let index = 0;
  selectedMenu.map((item) => {
    const menuItem = item;
    if (menuItem.index === undefined) {
      menuItem.index = index;
      index += 1;
      if (menuItem.children) {
        menuItem.children.map((child) => {
          const childItem = child;
          childItem.index = index;
          childItem.parent = {
            id: childItem.id,
            parentId: menuItem.id,
            parentText: menuItem.text,
            // showParent: childItem.showParent,
          };
          index += 1;
          if (childItem.children) {
            childItem.children.map((grandChild) => {
              const grandChildItem = grandChild;
              grandChildItem.index = index;
              grandChildItem.parent = {
                id: grandChildItem.id,
                parentId: menuItem.id,
                parentText: menuItem.text,
                parentOfParentId: childItem.id,
                showParent: childItem.showParent,
              };
              index += 1;
              return grandChildItem;
            });
          }
          return child;
        });
      }
    }
    return item;
  });
  return selectedMenu;
};

// Routes
const getRoute = (item) => {
  // return <PrivateRoute  key={item.id}  path={item.url} element={item.component} roles={item.roles}/>
  return (
    <Route
      key={item.id}
      path={item.url}
      element={<PrivateRoute element={item.component} roles={item.roles} />}
    />
  );
};
const PrivateRoute = ({ element: Component, roles }) => {
  const user = useSelector((state) => state.user.user);
  let authorized = false;
  if (roles) {
    roles.forEach((role) => {
      // role = 'ROLE_' + role;
      if (user.roles.some((item) => item === role)) {
        authorized = true;
      }
    });
  }
  if (authorized) {
    return Component;
  } else {
    return <NotAuthorizedPage />;
  }
};
const CombinedRoutes = (location, selectedMenu, currentMenu) => {
  const menu = getMenu(currentMenu);
  // console.log('dynamicMenu', dynamicMenu)
  return (
    <Routes key={location.key} location={location}>
      {menu.map(
        (item) => {
          if (item.children) {
            return item.children.map((child) => {
              if (child.children) {
                return child.children.map((grandChild) => getRoute(grandChild));
              }
              return getRoute(child);
            });
          }
          return getRoute(item);
        }
        // !item.children
        //     ? getRoute(item)
        //     : item.children.map(child => getRoute(child)),
      )}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export { CombinedRoutes, getMenu };
