import { useIntl } from "react-intl";

export const useTranslation = () => {
  const intl = useIntl();
  const handleTranslate = (tag, variables = null) => {
    let message = "";

    let lastWord = tag.split(".").pop();

    if (
      variables !== null &&
      !variables.id.match(/\d+/g) &&
      variables.id.includes("-")
    ) {
      //remove the dash in variables.id and make it camelcase
      variables.id = variables.id.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });

      tag = tag + "." + variables.id;
      // console.log(tag)
      variables = null;
    }
    try {
      if (variables !== null) {
        message = intl.formatMessage(
          {
            id: tag,
            defaultMessage: tag + " Not Defined",
            description: "Translation for " + tag,
          },
          { ...variables }
        );
      } else {
        message = intl.formatMessage({
          id: tag,
          defaultMessage: tag + " Not Defined",
          description: "Translation for " + tag,
        });
      }
      return message;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    translate: handleTranslate,
  };
};

export default useTranslation;
