import { gql } from "@apollo/client";

export const graphQLQueries = () => {
    const GET_DATATABLE_VIEWS = gql`
    subscription GetDatatableViewStreamingSubscription($datatable: String = "", $route: String = "") {
        datatable_view(where: {datatable: {_eq: $datatable}, route: {_eq: $route}}) {
          datatable
          name
          route
          filter_model
          sort_model
          state
          id
          default_flag
          global
        }
      }
      
    `

    return {
        GET_DATATABLE_VIEWS
    };
}

export const graphQLMutations = () => {

    const INSERT_DATATABLE_VIEW = gql`
    mutation InsertDatatableView($person_id: uuid, $sort_model: json = "", $state: json = "", $filter_model: json = "", $datatable: String = "", $name: String = "", $route: String = "", $defaultFlag: Boolean = false) {
        insert_datatable_view(objects: {person_id: $person_id, sort_model: $sort_model, state: $state, filter_model: $filter_model, datatable: $datatable, name: $name, route: $route, default_flag: $defaultFlag}) {
          affected_rows
          returning {
            id
          }
        }
      }      
`;

    const DELETE_DATATABLE_VIEW = gql`
    mutation DeleteDatatableView($_eq: uuid = "") {
    delete_datatable_view(where: {id: {_eq: $_eq}}) {
        affected_rows
      }
    }
`;

    const UPDATE_DATATABLE_VIEW = gql`
    mutation UpdateDatatableView($_eq: uuid = "", $_set: datatable_view_set_input = {}) {
      update_datatable_view(where: {id: {_eq: $_eq}}, _set: $_set) {
        affected_rows
        returning {
          id
        }
      }
    }
    `;

    return {
        INSERT_DATATABLE_VIEW,
        DELETE_DATATABLE_VIEW,
        UPDATE_DATATABLE_VIEW
    };
};