const menu = {
  "menu.manage.dashboard.tabText": "Dashboard",
  "menu.manage.dashboard.menuText": "Dashboard",
  "menu.manage.supportSection.text": "Ondersteuning",
  "menu.manage.supportSection.menuText": "Ondersteuning",
  "menu.manage.supportSection.dashboard.tabText": "Dashboard",
  "menu.manage.supportSection.dashboard.menuText": "Dashboard",
  "menu.manage.supportSection.newRequest.tabText": "Nieuwe Aanvraag",
  "menu.manage.supportSection.newRequest.menuText": "Nieuwe Aanvraag",
  "menu.manage.supportSection.myTicketsOverview.tabText":
    "Mijn Tickets Overzicht",
  "menu.manage.supportSection.myTicketsOverview.menuText":
    "Mijn Tickets Overzicht",
  "menu.manage.supportSection.approvals.tabText": "Goedkeuringen",
  "menu.manage.supportSection.approvals.menuText": "Goedkeuringen",
  "menu.manage.sixstarMeet.tabText": "Six Star Meet",
  "menu.manage.sixstarMeet.menuText": "Six Star Meet",
  "menu.manage.manageSixStarConnect.tabText": "Six Star Connect",
  "menu.manage.manageSixStarConnect.menuText": "Six Star Connect",
  "menu.manage.manageManageSection.text": "Beheer",
  "menu.manage.manageManageSection.menuText": "Beheer",
  "menu.manage.manageManageSection.dashboard.tabText": "Dashboard",
  "menu.manage.manageManageSection.dashboard.menuText": "Dashboard",
  "menu.manage.manageSection.text": "Beheer",
  "menu.manage.manageSection.menuText": "Beheer",
  "menu.manage.manageSection.documents.tabText": "Documenten",
  "menu.manage.manageSection.documents.menuText": "Documenten",
  "menu.manage.manageSection.securityReports.tabText": "Beveiligingsrapporten",
  "menu.manage.manageSection.securityReports.menuText": "Beveiligingsrapporten",
  "menu.manage.manageSection.serviceReports.tabText": "Service Rapporten",
  "menu.manage.manageSection.serviceReports.menuText": "Service Rapporten",
  "menu.manage.manageSection.licenceManagement.tabText": "Licentiebeheer",
  "menu.manage.manageSection.licenceManagement.menuText": "Licentiebeheer",
  "menu.manage.manageSection.assetManagement.tabText": "Assetbeheer",
  "menu.manage.manageSection.assetManagement.menuText": "Assetbeheer",
  "menu.manage.manageSection.upcomingExpiries.tabText":
    "Aankomende Vervaldatums",
  "menu.manage.manageSection.upcomingExpiries.menuText":
    "Aankomende Vervaldatums",
  "menu.manage.manageSection.bookACallWithYourAccountManager.tabText":
    "Plan een Gesprek met uw Accountmanager",
  "menu.manage.manageSection.bookACallWithYourAccountManager.menuText":
    "Plan een Gesprek met uw Accountmanager",
  "menu.manage.financeSection.text": "Financiën",
  "menu.manage.financeSection.menuText": "Financiën",
  "menu.manage.financeSection.dashboard.tabText": "Dashboard",
  "menu.manage.financeSection.dashboard.menuText": "Dashboard",
  "menu.manage.financeSection.ordersQuotesInvoices.tabText":
    "Orders/Offertes/Facturen",
  "menu.manage.financeSection.ordersQuotesInvoices.menuText":
    "Orders/Offertes/Facturen",
  "menu.manage.financeSection.creditApplication.tabText": "Kredietaanvraag",
  "menu.manage.financeSection.creditApplication.menuText": "Kredietaanvraag",
  "menu.manage.helpSection.text": "Help",
  "menu.manage.helpSection.menuText": "Help",
  "menu.manage.helpSection.contactUs.tabText": "Contacteer Ons",
  "menu.manage.helpSection.contactUs.menuText": "Contacteer Ons",
  "menu.manage.helpSection.giveFeedback.tabText": "Geef Feedback",
  "menu.manage.helpSection.giveFeedback.menuText": "Geef Feedback",
  "menu.manage.helpSection.guides.tabText": "Gidsen",
  "menu.manage.helpSection.guides.menuText": "Gidsen",
  "menu.manage.helpSection.customerForms.tabText": "Klantformulieren",
  "menu.manage.helpSection.customerForms.menuText": "Klantformulieren",
  "menu.manage.engineerSection.text": "Ingenieur",
  "menu.manage.engineerSection.menuText": "Ingenieur",
  "menu.manage.engineerSection.myTasks.tabText": "Mijn Taken",
  "menu.manage.engineerSection.myTasks.menuText": "Mijn Taken",
  "menu.manage.engineerSection.ticketBoard.tabText": "Ticket Board",
  "menu.manage.engineerSection.ticketBoard.menuText": "Ticket Board",
  "menu.manage.engineerSection.triageTickets.tabText": "Triage Tickets",
  "menu.manage.engineerSection.triageTickets.menuText": "Triage Tickets",
  "menu.manage.engineerSection.priorityTickets.tabText": "Prioriteit Tickets",
  "menu.manage.engineerSection.priorityTickets.menuText": "Prioriteit Tickets",
  "menu.manage.engineerSection.escalations.tabText": "Escalaties",
  "menu.manage.engineerSection.escalations.menuText": "Escalaties",
  "menu.manage.internalSection.text": "Intern",
  "menu.manage.internalSection.menuText": "Intern",
  "menu.manage.internalSection.orderTracker.tabText": "Order Tracker",
  "menu.manage.internalSection.orderTracker.menuText": "Order Tracker",
  "menu.manage.internalSection.resourceTracker.tabText": "Resource Tracker",
  "menu.manage.internalSection.resourceTracker.menuText": "Resource Tracker",
  "menu.manage.internalSection.knowledgeBase.tabText": "Kennisbank",
  "menu.manage.internalSection.knowledgeBase.menuText": "Kennisbank",
  "menu.manage.internalSection.twoFactor.tabText": "Twee Factor",
  "menu.manage.internalSection.twoFactor.menuText": "Twee Factor",
  "menu.manage.internalSection.internal.tabText": "Intern",
  "menu.manage.internalSection.internal.menuText": "Intern",
  "menu.manage.internalSection.expenses.tabText": "Onkosten",
  "menu.manage.internalSection.expenses.menuText": "Onkosten",
  "menu.manage.userSection.text": "Gebruiker",
  "menu.manage.userSection.menuText": "Gebruiker",
  "menu.manage.userSection.createContact.tabText": "Contact Aanmaken",
  "menu.manage.userSection.createContact.menuText": "Contact Aanmaken",
  "menu.manage.userSection.contactSearch.tabText": "Contact Zoeken",
  "menu.manage.userSection.contactSearch.menuText": "Contact Zoeken",
  "menu.manage.userSection.zoomPresence.tabText": "Zoom Aanwezigheid",
  "menu.manage.userSection.zoomPresence.menuText": "Zoom Aanwezigheid",
  "menu.manage.userSection.zoomCallQueues.tabText": "Zoom Oproepwachtrijen",
  "menu.manage.userSection.zoomCallQueues.menuText": "Zoom Oproepwachtrijen",
  "menu.manage.userSection.mergeContacts.tabText": "Contacten Samenvoegen",
  "menu.manage.userSection.mergeContacts.menuText": "Contacten Samenvoegen",
  "menu.manage.adminSection.text": "Beheerder",
  "menu.manage.adminSection.menuText": "Beheerder",
  "menu.manage.adminSection.dashboard.tabText": "Dashboard",
  "menu.manage.adminSection.dashboard.menuText": "Dashboard",
  "menu.manage.adminSection.impersonate.tabText": "Vervalsen",
  "menu.manage.adminSection.impersonate.menuText": "Vervalsen",
  "menu.manage.supportSection.newRequestDashboard.tabText": "Nieuwe Aanvraag",
  "menu.manage.supportSection.newRequestDashboard.menuText": "Nieuwe Aanvraag",
  "menu.manage.supportSection.newRequestForm.tabText.supportRequest":
    "Ondersteuningsverzoek",
  "menu.manage.supportSection.newRequestForm.tabText.serviceRequest":
    "Serviceverzoek",
  "menu.manage.supportSection.newRequestForm.tabText.procurementRequest":
    "Inkoopverzoek",
  "menu.manage.supportSection.newRequestForm.tabText.internalRequest":
    "Intern Verzoek",
  "menu.manage.supportSection.newRequestForm.tabText.passwordReset":
    "Wachtwoord Reset",
  "menu.manage.supportSection.newRequestForm.tabText.newStarter":
    "Nieuwe Starter / Rolwijziging",
  "menu.manage.supportSection.newRequestForm.tabText.leaverRequest":
    "Vertrekker Verzoek",
  "menu.manage.supportSection.newRequestForm.tabText.engineerVisit":
    "Ingenieur Bezoek",
  "menu.manage.supportSection.newRequestForm.tabText.developmentRequest":
    "Ontwikkelingsverzoek",
  "menu.manage.engineerSection.ticketViewPage.tabText": "Ticket# {id}",
  "menu.manage.engineerSection.ticketViewPage.menuText": "Ticket {id}",
  "menu.manage.engineerSection.ticketsBoard.tabText": "Tickets Bord",
  "menu.manage.engineerSection.ticketsBoard.menuText": "Tickets Bord",
  "menu.manage.supportSection.newRequestForm.tabText": "{id}",
  "menu.manage.supportSection.newRequestForm.menuText": "{id}",
  "menu.manage.engineerSection.drive.tabText": "Six Star Drive",
  "menu.manage.engineerSection.drive.menuText": "Six Star Drive",
  "menu.manage.engineerSection.reports.tabText": "Rapporten",
  "menu.manage.engineerSection.reports.menuText": "Rapporten",
  "menu.manage.engineerSection.companyProfile.tabText": "Mijn Organisatie",
  "menu.manage.engineerSection.companyProfile.menuText": "Mijn Organisatie",
  "menu.manage.engineerSection.chatGPT.tabText": "Chat GPT",
  "menu.manage.engineerSection.chatGPT.menuText": "Chat GPT",
  "menu.manage.financeSection.Invoices.tabText": "Facturen",
  "menu.manage.financeSection.Invoices.menuText": "Facturen",
  "menu.manage.financeSection.quotesProposals.tabText": "Offertes",
  "menu.manage.financeSection.quotesProposals.menuText": "Offertes",
  "menu.manage.financeSection.creditTerms.tabText": "Kredietvoorwaarden",
  "menu.manage.financeSection.creditTerms.menuText": "Kredietvoorwaarden",
  "menu.manage.internalSection.impersonate.tabText": "Impersoneren",
  "menu.manage.internalSection.impersonate.menuText": "Impersoneren",
  "menu.manage.managedServicesSection.text": "Beheerde Diensten",
  "menu.manage.managedServicesSection.menuText": "Beheerde Diensten",
  "menu.manage.managedServicesSection.dashboard.tabText": "Dashboard",
  "menu.manage.managedServicesSection.dashboard.menuText": "Dashboard",
  "menu.manage.managedServicesSection.serviceOverview.tabText":
    "Serviceoverzicht",
  "menu.manage.managedServicesSection.serviceOverview.menuText":
    "Serviceoverzicht",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.tabText":
    "Endpoint Beveiliging",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.menuText":
    "Endpoint Beveiliging",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.tabText":
    "Backup",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.menuText":
    "Backup",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.tabText":
    "Veilige Formulieren",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.menuText":
    "Veilige Formulieren",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.tabText":
    "Geplande Sitebezoeken",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.menuText":
    "Geplande Sitebezoeken",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.tabText":
    "E-Afvalverwerking",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.menuText":
    "E-Afvalverwerking",
  "menu.manage.sixStarSecureSection.text": "Six Star Secure",
  "menu.manage.sixStarSecureSection.menuText": "Six Star Secure",
  "menu.manage.sixStarSecureSection.dashboard.tabText": "Dashboard",
  "menu.manage.sixStarSecureSection.dashboard.menuText": "Dashboard",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.tabText":
    "Endpoint Bescherming (Antivirus & Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.menuText":
    "Endpoint Bescherming (Antivirus & Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.tabText":
    "Endpoint Detectie & Respons",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.menuText":
    "Endpoint Detectie & Respons",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.tabText":
    "Firewall Beheer",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.menuText":
    "Firewall Beheer",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.tabText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.menuText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.tabText":
    "Apparaatversleuteling",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.menuText":
    "Apparaatversleuteling",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.tabText":
    "Office 365/Azure Beheer",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.menuText":
    "Office 365/Azure Beheer",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.tabText":
    "Backup & Rampenherstel",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.menuText":
    "Backup & Rampenherstel",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.tabText":
    "Beveiligingsrapporten",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.menuText":
    "Beveiligingsrapporten",
  "menu.manage.softwareLicenceManagementSection.text": "Softwarelicentiebeheer",
  "menu.manage.softwareLicenceManagementSection.menuText":
    "Softwarelicentiebeheer",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.tabText":
    "Geautoriseerde Softwarecatalogus",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.menuText":
    "Geautoriseerde Softwarecatalogus",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.tabText":
    "Licentiebeheer",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.menuText":
    "Licentiebeheer",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.tabText":
    "Softwarebeheer",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.menuText":
    "Softwarebeheer",
  "menu.manage.assetManagementSection.text": "Assetbeheer",
  "menu.manage.assetManagementSection.menuText": "Assetbeheer",
  "menu.manage.assetManagementSection.assetManagementDashboard.tabText":
    "Dashboard",
  "menu.manage.assetManagementSection.assetManagementDashboard.menuText":
    "Dashboard",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.tabText":
    "Geautoriseerde Hardwarecatalogus",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.menuText":
    "Geautoriseerde Hardwarecatalogus",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.tabText":
    "Apparaatbeheer",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.menuText":
    "Apparaatbeheer",
  "menu.manage.sixStarAdditionsSection.text": "Six Star Aanvullingen",
  "menu.manage.sixStarAdditionsSection.menuText": "Six Star Aanvullingen",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.tabText":
    "Gastapparaatondersteuning",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.menuText":
    "Gastapparaatondersteuning",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.tabText":
    "Six Star: Stem",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.menuText":
    "Six Star: Stem",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.tabText":
    "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.menuText":
    "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.tabText":
    "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.menuText":
    "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.tabText":
    "Six Star: Meet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.menuText":
    "Six Star: Meet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.tabText":
    "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.menuText":
    "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.tabText":
    "Six Star: Cast",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.menuText":
    "Six Star: Cast",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.tabText":
    "Six Star: Pay",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.menuText":
    "Six Star: Pay",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.tabText":
    "Six Star: Display",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.menuText":
    "Six Star: Display",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.tabText":
    "Six Star: Park",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.menuText":
    "Six Star: Park",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.tabText":
    "Six Star: Zwart",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.menuText":
    "Six Star: Zwart",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.tabText":
    "Six Star: Print",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.menuText":
    "Six Star: Print",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.tabText":
    "Six Star: Internet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.menuText":
    "Six Star: Internet",
    "menu.manage.userSection.editContact.tabText": "Contact bewerken",
"menu.manage.userSection.editContact.menuText": "Contact bewerken",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.tabText": "Loonstrookjes",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.menuText": "Loonstrookjes",
};
export default menu;
