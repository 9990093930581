import React, { Children, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "@mui/styles/withStyles";
import { Box, Grid, Tabs } from "@mui/material";
import { useElementSize, useHover } from "@mantine/hooks";
import Tab from "@mui/material/Tab";


const SidePanelTabScroller = (props) => {
  const { children, theme } = props;
  const [tabValue, setTabValue] = React.useState({ index: false });
  const [tabArray, setTabArray] = useState([]);
  const handleChange = (event, newValue) => {
    setTabValue({ index: newValue, title: tabArray[newValue].title });
  };
  const { ref: mainWrapperRef, width: mainWrapperWidth } = useElementSize();
  useEffect(() => {
    if (
      tabArray.length > 0 &&
      tabValue.title === undefined &&
      mainWrapperWidth > 0
    ) {
      setTabValue({ index: 0, title: tabArray[0].title });
    }
  }, [tabArray, mainWrapperWidth]);
  let childrenArray = [];
  let childrenChildArray = [];
  children.forEach((child, index) => {
    if (typeof child === "object") {
      childrenArray.push(child);
      childrenChildArray.push(Children.toArray(child));
    }
  });
  const handleTabs = (event) => {
    // console.log('handleTabs',event)
    //if event is not in tabArray then add it
    if (!tabArray.find((tab) => tab.title === event.title)) {
      setTabArray((prevState) => [...prevState, event]);
    }
  };

  //clone children and add props
  childrenArray = childrenArray.map((child, index) => {
    return React.cloneElement(child, {
      handleTabs: handleTabs,
      key: index,
      tabValue: tabValue,
    });
  });

  useEffect(() => {
    if (mainWrapperWidth === 0 || mainWrapperWidth > 1100) {
      setTabValue({ index: false });
    }
  }, [mainWrapperWidth]);

  return (
    <>
      <Grid
        item
        display={{ xs: "block", md: "block", lg: "none" }}
        width={"100%"}
        ref={mainWrapperRef}
      >
        <Tabs
          sx={{ width: "100%" }}
          value={tabValue.index}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabArray.map((tab, index) => {
            return (
              <Tab
                label={
                  <>
                    <FontAwesomeIcon
                      icon={tab.icon}
                      fixedWidth
                      style={{ marginBottom: theme.spacing(1) }}
                    />{" "}
                    {tab.title}
                  </>
                }
                key={index}
              />
            );
          })}
        </Tabs>

        {childrenArray[0]}
        {childrenArray[1]}
      </Grid>
    </>
  );
};

export default withStyles((theme) => ({}), { withTheme: true })(
  SidePanelTabScroller
);
