import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Translation from 'utils/translation';
import graphQLQueries from 'graphql/ticket';
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DataTablePro from "components/DataTablePro"
import { useElementSize } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import useTranslation from "containers/App/Hooks/useTranslation";
import UserAvatar from "components/Global/UserAvatar";
import { gql } from "@apollo/client";
import { startCase } from "lodash";
import Moment from 'moment';
import { useQuery } from '@apollo/client';
import PageBase from 'components/PageBase';
import { DataGridPro as DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";


const Contacts = () => {

    const navigate = useNavigate();
    const rowsPerPage = [10, 15, 20]; 

    const { id } = useParams();

    const GET_CONTCATS = gql`
    query MyQuery($orgID: uuid = "") {
        person(where: {active: {_eq: true}, deleted: {_eq: false}, organization: {id: {_eq: $orgID}}}, order_by: {name: desc}) {
          avatar
          first_name
          last_name
          name
          alias
          email_address
          account_id
          id
          organization {
            name
            child_organizations {
              name
            }
            parent_organization {
              name
            }
          }
          account {
            created_at
            active
          }
          phone_numbers {
            phone_number {
              number
              country {
                dialing_prefix
              }
            }
          }
        }
      }
      
      
      `
const { data, loading, error } = useQuery(GET_CONTCATS, {
    variables: { orgID: id },
    });
    

  //  console.log(data);
    //logging only the first record in the data
    // console.log(data.person[0]);

    const rows = data?.person?.map((person) => ({
     
        id: person?.id,
      //  avatar: person?.avatar,
     //   avatar: <UserAvatar user={person} />,
        first_name: person?.first_name,
        last_name: person?.last_name,
        email_address: person?.email_address,
        organization: person?.organization.name,
        organization_child: person?.organization.child_organizations,
        organization_parent: person?.organization.parent_organization,
        account_id: person?.account_id,
        created_at: person?.account.created_at.split('T')[0],
        active: person?.account.active,
        name: person?.name,
        number: person?.phone_numbers?.[0]?.phone_number?.number,
        dialing_prefix: person?.phone_numbers?.[0]?.phone_number?.country?.dialing_prefix,
    }));


    const columns = [
      {
        field: "avatar", headerName: "Avatar", minWidth: 100, filterable: false,
        renderCell: (person) => {
          return <UserAvatar user={person?.name} />
        }
      },
    { field: 'first_name', headerName: 'First Name', width: 200 },
    { field: 'last_name', headerName: 'Last Name', width: 200 },
    { field: 'email_address', headerName: 'Email Address', width: 300 },
    { field: 'organization', headerName: 'Organization', width: 200 },
    {field: 'active', headerName: 'Active', width: 100},
    ];

   // console.log(data);

    const handleRowClick = (row) => {
        console.log(row);
      //  const rowData = JSON.stringify(row);
      //equating row data to the actual row
        const id = row.row.id;
        
        navigate(`/admin/profile/${id}`);
    }
    if (!data) {
        return <div>Loading...</div>;
      }
    return (
      
        
        <Box
        sx = {{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            padding: '20px',
            marginTop: '20px',
        }}>
      
                    <DataGrid
                            rows={rows}
                            columns={columns}
                            onRowClick={handleRowClick}
                            pagination
                            initialState={{
                                    ...data.initialState,
                                    pagination: { paginationModel: { pageSize: 10 } },
                                }}
                              pageSizeOptions= {[10, 15, 20]}
                            //checkboxSelection
                         // disableSelectionOnClick
                         slots={{ toolbar: GridToolbar,}}
                         slotProps={{
                            toolbar: {
                              showQuickFilter: true,
                            },
                          }}
                        
                            
            sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '15px',
                },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '22px',
                },
                cursor: 'pointer',
            }}
                    />
                    </Box>

    )
}
    export default Contacts;