import { TextNode } from "lexical";
function convertMentionElement(domNode) {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createMentionNode(textContent);
    return {
      node,
    };
  }

  return null;
}

const mentionStyle =
  "background-color: rgba(24, 119, 232, 0.2); border-radius: 5px; padding: 0 5px;";
export class MentionNode extends TextNode {
  __mention;

  static getType() {
    return "mention";
  }

  static clone(node) {
    // console.log('clone',node)
    return new MentionNode(node.__mentionObject, node.__text, node.__key);
  }
  static importJSON(serializedNode) {
    const node = $createMentionNode(serializedNode.mentionName);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(mentionName, text, key) {
    super(text ?? mentionName.name, key);
    this.__mentionObject = mentionName;
    this.__mention = mentionName.name;
    this.__id = mentionName.id;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      mentionName: this.__mentionObject,
      type: "mention",
      version: 1,
    };
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    dom.style.cssText = mentionStyle;
    dom.className = "mention";
    return dom;
  }

  exportDOM() {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-mention", "true");
    element.setAttribute("data-lexical-mention-user-id", this.__id);
    element.textContent = this.__text;
    return { element };
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute("data-lexical-mention")) {
          return null;
        }

        return {
          conversion: convertMentionElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true;
  }
}

export function $createMentionNode(mentionName) {
  const mentionNode = new MentionNode(mentionName);
  mentionNode.setMode("segmented").toggleDirectionless();
  return mentionNode;
}

export function $isMentionNode(node) {
  return node instanceof MentionNode;
}
