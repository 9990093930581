import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import PropTypes from "prop-types";
import styles from "./styles";
import { Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "components/MuiOverride/Button";
import Translation from "utils/translation";

const FlagNumber = (props) => {
  const { classes, url, number } = props;
  return (
    <Card className={classes.flagNumberContainer}>
      {url && (
        <img
          src={url}
          width={95}
          height={71}
          className={classes.flagNumberImage}
        />
      )}
      <Typography
        variant="h6"
        component="h6"
        className={classes.flagNumberText}
      >
        {number}
      </Typography>
      <Button metadata="call_us_button" className={classes.callUsButton} variant="contained">{Translation("flagNumber.button.callUs")}</Button>

    </Card>
  );
};

FlagNumber.propTypes = {
  url: PropTypes.string,
  number: PropTypes.string.isRequired,
};

FlagNumber.defaultProps = {
  url: null,
  number: "",
};

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(FlagNumber);
