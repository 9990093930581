import React from 'react';
import withStyles from '@mui/styles/withStyles';
import layoutStyles from 'containers/Layout/styles';
import styles from './styles';
import Moment from 'react-moment';
import Translation from 'utils/translation';
import Zoom from '@mui/material/Zoom';
import { Tooltip, Box } from '@mui/material';

/*
 *   Creates a relative time string.
 *   @Params data (string)
 *           color (string)
 *
 *   @Return Html & Javascript component
 *   @Author Llew
 */

function DateTime(props) {
  let { theme, data, color, ago, prefix } = props;
  if (!color) color = theme.palette.generic.foreground;
  let format;
  if (!ago) {
    format = 'DD MMM YYYY HH:mm ';
  }
  return (
    <Tooltip title={`${data} ${Translation('dateTime.utc')}`} followCursor TransitionComponent={Zoom}>
      <Box>
        {prefix && <span>{prefix} </span>}
        <Moment
          fromNow={ago}
          // style={{
          //     color: color,
          //     fontWeight: 500
          // }}
          format={format}
        >
          {data}
        </Moment>
      </Box>
    </Tooltip>
  );
}

DateTime.defaultProps = {
  color: null,
};

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true },
)(DateTime);
