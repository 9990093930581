const admin = {
    "admin.adminPanel.loading": "Loading...",
    "admin.adminPanel.error": "Error Loading Admin panel, please try again or contact Six Star Support",
    "admin.adminPanel.redirected": "Admin Panel Opened In New Window",
    "menu.manage.userSection.adminPanel.tabText": "Admin Panel",
    "menu.manage.userSection.adminPanel.menuText": "Admin Panel",
    "menu.manage.userSection.adminPanel.tooltip": "Admin Panel",
    "admin.adminPanel.title": "Admin Panel",
    "admin.adminPanel.openAdminPanel": "Click to Open Admin Panel",
}

export default admin;