import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import { $generateHtmlFromNodes } from "@lexical/html";

function EditorToHtml({ htmlCallBack }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editor) {
      editor.registerUpdateListener(({ editorState }) => {
        editor.update(() => {
          const htmlString = $generateHtmlFromNodes(editor, null);
          htmlCallBack(htmlString);
        });
      });
    }
  }, [editor]);

  return <></>;
}

export default withStyles(
  (theme) => ({
    ...styles(theme),
  }),
  { withTheme: true }
)(EditorToHtml);
