import { createSlice } from "@reduxjs/toolkit";
import { differenceWith, isEqual, toPairs, set } from "lodash";
import { findObjectsByKey } from "utils/helperFunctions";

const initialState = {};

export const tickets = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    currentTicket: (state, action) => {
      //if state[action.payload.id] exists, update it, else add it
      state.currentTicket = action.payload;
    },
    addTicket: (state, action) => {
      const { data, location = "data" } = action.payload;

      if (state[data.id]) {
        //if location is array loop through and add each item
        if (Array.isArray(location)) {
          location.forEach((item) => {
            state[data.id][item] = data;
          });
        } else {
          state[data.id][location] = data;
        }
      } else {
        let defaultState = {
          "middle-tabs": { searchQuery: "" },
          remoteUpdated: false,
          editedValues: {},
          persons: [],
        };
        if (Array.isArray(location)) {
          location.forEach((item) => {
            defaultState = { [item]: data, ...defaultState };
            state[data.id] = defaultState;
          });
        } else {
          defaultState = { [location]: data, ...defaultState };
          state[data.id] = defaultState;
        }
      }

      if (location === "remote") {
        let remoteDiff = differenceWith(
          toPairs(state[data.id].current),
          toPairs(data),
          isEqual
        );
        if (remoteDiff.length > 0) {
          remoteDiff.forEach(([key, value]) => {
            if (state[data.id].editedValues !== null) {
              if (state[data.id].editedValues.hasOwnProperty(key)) {
                // console.log('THIS HAS BEEN EDITED LOCAL AND REMOTE******  ' + key)
                state[data.id].remoteUpdated = true;
              }
            }
          });
        } else {
          state[data.id].remoteUpdated = false;
        }
        state[data.id].current = { ...data, ...state[data.id].editedValues };
      }
      let personsInTicket = [];
      findObjectsByKey(state[data.id], ["person", "author"], personsInTicket);

      state[data.id].persons = personsInTicket.sort();
    },
    setTicketRemoteUpdated: (state, action) => {
      //if state[action.payload.id] exists, update it, else add it
      const { id, value } = action.payload;
      state[id].remoteUpdated = value;
    },
    setEditedValues: (state, action) => {
      //if state[action.payload.id] exists, update it, else add it
      const { id, value, target } = action.payload;
      if (!state[id].current) {
        state[id].current = state[id].local;
      }
      if (!state[id].previous) {
        state[id].previous = state[id].current;
      }
      set(state[id].current, target, value);
      set(state[id].editedValues, target, value);

      // state[id].current[target] = value
      // state[id].editedValues[target] = value
    },
    resetEditedValues: (state, action) => {
      //if state[action.payload.id] exists, update it, else add it
      const { id } = action.payload;
      state[id].editedValues = {};
      state[id].current = state[id].local;
    },
    removeEditedValues: (state, action) => {
      //if state[action.payload.id] exists, update it, else add it
      const { id, value } = action.payload;
      delete state[id].editedValues[value];
    },
    selectedTab: (state, action) => {
      //if state[action.payload.id] exists, update it, else add it
      const { id, value: index, name } = action.payload;
      const tabId = "tab-" + index;

      if (state[id]["middle-tabs"][tabId]) {
        state[id]["middle-tabs"][tabId].selected = true;
      } else {
        state[id]["middle-tabs"][tabId] = { selected: true, name };
      }
      Object.keys(state[id]["middle-tabs"]).forEach((key) => {
        //key contains tab-

        if (key !== tabId && key.includes("tab-")) {
          state[id]["middle-tabs"][key].selected = false;
        }
      });
    },
    searchQuery: (state, action) => {
      //if state[action.payload.id] exists, update it, else add it
      const { id, value, tabs } = action.payload;

      state[id][tabs].searchQuery = value.trim();
    },
  },
});

export const {
  addTicket,
  selectedTab,
  searchQuery,
  setEditedValues,
  resetEditedValues,
  removeEditedValues,
} = tickets.actions;

export default tickets.reducer;
