const pages = {
  "dashboard.pageTitle": "Ship's Log",
  "notAuthorizedPage.title": "No Permission, Arrr",
  "notAuthorizedPage.desc": "Ye be not allowed to enter this page, matey.",
  "dashboard.welcomeText": "Welcome To {ssg}, Yarrr!",
  "app.html":
    "Edit <code>{fileName}</code> js and save to reload. Now with {what}, Arrr!",
  "settingsPage.pageTitle": "Adjustments",
  "ticketViewPage.priority": "Importance",
  "ticketViewPage.assignee": "Assigned Matey",
  "ticketViewPage.team": "Crew",
  "ticketViewPage.sixStarTeam": "Six Star Crew",
  "ticketViewPage.resourcesOnTicket": "Resources On Letter",
  "ticketViewPage.followers": "Followers, Yarrr",
  "ticketViewPage.tags": "Marks",
  "ticketViewPage.takeIt": "take it, Arrr",
  "ticketViewPage.notes": "Notes, Yarrr",
  "ticketViewPage.activity": "Happenings",
  "ticketViewPage.timeline": "Timeline, Yarrr",
  "ticketViewPage.tasks": "Chores",
  "ticketViewPage.search": "Search, Yarrr",
  "ticketViewPage.noteAddedBy": "Note added by",
  "ticketViewPage.moreButton": "More, Arrr",
  "ticketViewPage.makeInternal": "Make Ship's",
  "ticketViewPage.makePublic": "Make Public, Yarrr",
  "ticketViewPage.deleteComment": "Throw Overboard",
  "ticketViewPage.reportComment": "Report, Arrr",
  "ticketViewPage.internalTicketComment": "This be a ship's comment",
  "ticketViewPage.publicTicketComment": "This be a public comment, Yarrr",
  "ticketViewPage.ticketSource.internal_web": "Ship's Web",
  "ticketViewPage.ticketSource.external_email": "Ship's Pigeon Post",
  "ticketViewPage.followTicket": "Follow Letter",
  "ticketViewPage.unfollowTicket": "Unfollow Letter, Arrr",
  "ticketViewPage.tasks.priority.completed": "Finished",
  "ticketViewPage.tasks.priority.in_progress": "In Progress, Yarrr",
  "ticketViewPage.tasks.priority.not_started": "Not Started, Arrr",
  "ticketViewPage.tasks.priority.low": "Low, Yarrr",
  "ticketViewPage.tasks.priority.medium": "Medium, Arrr",
  "ticketViewPage.tasks.priority.important": "Important, Yarrr",
  "ticketViewPage.tasks.priority.urgent": "Urgent, Arrr",
  "ticketViewPage.tasks.assignedTo": "Assigned To",
  "ticketViewPage.tasks.completedBy": "Completed By",
  "ticketViewPage.requester": "Requester, Yarrr",
  "ticketViewPage.organization": "Crew",
  "ticketViewPage.button.cancel": "Cancel, Arrr",
  "ticketViewPage.button.close": "Close, Yarrr",
  "ticketViewPage.downloadEml": "Download EML, Yarrr",
  "newRequestPage.header": "New Plea",
  "newRequestPage.button.internalRequest": "Ship's Plea",
  "newRequestPage.button.serviceRequest": "Service Plea",
  "newRequestPage.button.procurementRequest": "Procurement Plea, Arrr",
  "newRequestPage.card.urgentHelp": "Need Urgent Help, Yarrr?",
  "newRequestPage.button.callUs": "Call Us, Yarrr",
  "newRequestPage.button.passwordReset": "Secret Code Reset",
  "newRequestPage.button.newRequest": "Support Plea",
  "formPage.title.supportRequest": "Support Plea",
  "formPage.title.newStarterRequest": "New Starter Plea",
  "formPage.title.serviceRequest": "Service Plea",
  "formPage.title.newStarter": "New Starter / Role Change, Yarrr",
  "formPage.title.leaver": "Leaver, Arrr",
  "formPage.title.engineerVisit": "Engineer Visit, Yarrr",
  "formPage.title.procurementRequest": "Procurement Plea",
  "formPage.title.developmentRequest": "Development Plea, Arrr",
  "formPage.title.internalRequest": "Ship's Plea",
  "newRequestPage.button.passwordReset": "Arrr! Reset yer Password for the Domain/Computer, me heartie!",
  "ldapPasswordResetPage.button.generatePassword": "Create Secret Code",
  "ldapPasswordResetPage.title": "Arrr! Password Reset for the Domain/Computer",
  "ldapPasswordResetPage.paragraph.intro":
    "Reset yer computer domain secret code. Depending on yer ship's policy ye maybe required to change yer secret code every 90 days, Arrr.",
  "licencePage.title": "Licences, Yarrr",
  "licencePage.office365Licences": "Office 365 Licences, Arrr",
  "licencePage.license": "Licence, Yarrr",
  "licencePage.active": "Active, Yarrr",
  "licencePage.consumed": "Consumed, Arrr",
  "licencePage.unused": "Unused, Yarrr",
  "assetPage.title": "Assets, Yarrr",
  "resourceTracker.title": "Resource Tracker, Yarrr",
  "ticketViewPage.overview": "Overview, Yarrr",
  "ticketViewPage.roles": "Roles, Arrr",
  "ticketViewPage.addContact": "Add Matey",
  "organizationViewPage.overview": "Overview, Yarrr",
  "organizationViewPage.activity": "Happenings, Yarrr",
  "assetPage.name": "Name, Yarrr",
  "assetPage.type": "Type, Arrr",
  "assetPage.status": "Status, Yarrr",
  "assetPage.serial": "Serial, Arrr",
  "assetPage.manufacturer": "Maker, Yarrr",
  "assetPage.cpu": "CPU, Yarrr",
  "assetPage.memory": "Memory, Arrr",
  "assetPage.lastUser": "Last Seen, Yarrr",
  "assetPage.lastSeen": "Last Seen On, Yarrr",
  "assetPage.operatingSystem": "Operating System, Yarrr",
  "sixStarMeet.header": "The Technology Behind Every Great Meeting, Yarrr",
  "ticketViewPage.childTickets": "Child Letters, Arrr",
  "ticketViewPage.parent": "Parent, Yarrr",
};

export default pages;
