const styles = (theme) => ({
  actions: {
    position: "absolute",
    textAlign: "right",
    bottom: "0",
    padding: "10px",
    right: "0",
    "& i": {
      backgroundSize: "contain",
      display: "inline-block",
      height: "20px",
      width: "20px",
      verticalAlign: "-0.25em",
    },
  },
  "action-button": {
    backgroundColor: "#eee",
    border: 0,
    padding: "8px 12px",
    position: "relative",
    marginLeft: "5px",

    color: "#222",
    display: "inline-block",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ddd",
      color: "#000",
    },
    "&:disabled": {
      opacity: "0.5",
      backgroundColor: "#eee",
      cursor: "not-allowed",
    },
  },
  ltr: {
    textAlign: "left",
  },
  rtl: {
    textAlign: "right",
  },
  "editor-container": {
    // margin: ` ${theme.spacing(2)} ${theme.spacing(2)}  ${theme.spacing(2)} ${theme.spacing(1)} `,
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    color: "#000",
    position: "relative",
    lineHeight: "20px",
    fontWeight: "400",
    textAlign: "left",
  },
  "editor-container-shadow": {
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  },
  "editor-inner": {
    background: "#fff",
    position: "relative",
  },
  "editor-input-read-only": {
    height: "200px",
    maxHeight: "200px",
    overflowY: "scroll",
  },
  "editor-input": {
    minHeight: "50px",
    resize: "none",
    fontSize: "15px",
    caretColor: "rgb(5, 5, 5)",
    position: "relative",
    tabSize: "1",
    outline: 0,
    padding: "15px 10px",
  },

  "editor-placeholder": {
    color: "#999",
    overflow: "hidden",
    position: "absolute",
    textOverflow: "ellipsis",
    top: "15px",
    left: "10px",
    fontSize: "15px",
    userSelect: "none",
    display: "inline-block",
    pointerEvents: "none",
  },
  "editor-text-bold": {
    fontWeight: "bold",
  },
  "editor-text-italic": {
    fontStyle: "italic",
  },
  "editor-text-underline": {
    textDecoration: "underline",
  },
  "editor-text-strikethrough": {
    textDecoration: "line-through",
  },
  "editor-text-underlineStrikethrough": {
    textDecoration: "underline line-through",
  },
  "editor-text-code": {
    backgroundColor: "rgb(240, 242, 245)",
    padding: "1px 0.25rem",
    fontFamily: "Menlo, Consolas, Monaco, monospace",
    fontSize: "94%",
  },
  "editor-link": {
    color: "rgb(33, 111, 219)",
    textDecoration: "none",
  },
  "editor-code": {
    backgroundColor: "rgb(240, 242, 245)",
    fontFamily: "Menlo, Consolas, Monaco, monospace",
    display: "block",
    padding: "8px 8px 8px 52px",
    lineHeight: "1.53",
    fontSize: "13px",
    margin: 0,
    marginTop: "8px",
    marginBottom: "8px",
    tabSize: 2,
    whiteSpace: "pre",
    overflowX: "auto",
    position: "relative",
    "&:before": {
      content: "attr(data-gutter)",
      position: "absolute",
      backgroundColor: "#eee",
      left: 0,
      top: 0,
      borderRight: "1px solid #ccc",
      padding: "8px",
      color: "#777",
      whiteSpace: "pre-wrap",
      textAlign: "right",
      minWidth: "25px",
    },
    "&:after": {
      content: "attr(data-highlight-language)",
      top: "2%",
      right: "5px",
      padding: "3px",
      fontSize: "10px",
      textTransform: "uppercase",
      position: "absolute",
      color: "rgba(0, 0, 0, 0.5)",
    },
  },
  "editor-tokenComment": {
    color: "slategray",
  },
  "editor-tokenPunctuation": {
    color: "#999",
  },
  "editor-tokenProperty": {
    color: "#905",
  },
  "editor-tokenSelector": {
    color: "#690",
  },
  "editor-tokenOperator": {
    color: "#9a6e3a",
  },
  "editor-tokenAttr": {
    color: "#07a",
  },
  "editor-tokenVariable": {
    color: "#e90",
  },
  "editor-tokenFunction": {
    color: "#dd4a68",
  },
  "editor-paragraph": {
    margin: 0,
    marginBottom: "8px",
    position: "relative",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  "editor-heading-h1": {
    fontSize: "24px",
    color: "rgb(5, 5, 5)",
    fontWeight: 400,
    margin: 0,
    marginBottom: "12px",
    padding: 0,
  },
  "editor-heading-h2": {
    fontSize: "15px",
    color: "rgb(101, 103, 107)",
    fontWeight: 700,
    margin: 0,
    marginTop: "10px",
    padding: 0,
    textTransform: "uppercase",
  },
  "editor-quote": {
    margin: 0,
    marginLeft: "20px",
    fontSize: "15px",
    color: "rgb(101, 103, 107)",
    borderLeftColor: "rgb(206, 208, 212)",
    borderLeftWidth: "4px",
    borderLeftStyle: "solid",
    paddingLeft: "16px",
  },
  "editor-list-ol": {
    padding: 0,
    margin: 0,
    marginLeft: "16px",
  },
  "editor-list-ul": {
    padding: 0,
    margin: 0,
    marginLeft: "16px",
  },
  "editor-listitem": {
    margin: "8px 32px 8px 32px",
  },
  "editor-nested-listitem": {
    listStyleType: "none",
  },
  "comment-toolbar": {
    backgroundColor: "white",
    marginBottom: "1px",

    padding: "4px",
    position: "relative",
  },
  "comment-toolbar-bottom": {
    backgroundColor: "white",
    marginTop: "1px",

    padding: "4px",
    justifyContent: "space-between",
    position: "relative",
  },
  "contact-toolbar": {
    "& .fa-icon": {
      display: "flex",
      lineHeight: "16px",
      width: "20px",
      height: "20px",
      userSelect: "none",
      marginRight: "8px",
      backgroundSize: "contain",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
  },
  toolbar: {
    position: "relative",
    display: "flex",
    marginBottom: "1px",
    background: "#fff",
    padding: "4px",
    // borderTopLeftRadius: "10px",
    // borderTopRightRadius: "10px",
    verticalAlign: "middle",

    "& .chevron-down": {
      backgroundColor: "transparent",
      backgroundSize: "contain",
      display: "flex",
      marginTop: "3px",
      height: "16px",
      width: "16px",
      userSelect: "none",
      backgroundImage: "url(/images/icons/chevron-down.svg)",
    },
    "& .chevron-down.inside": {
      width: "16px",
      height: "16px",
      display: "flex",
      marginLeft: "-25px",
      marginTop: "11px",
      marginRight: "10px",
      pointerEvents: "none",
    },

    "& select.toolbar-item": {
      border: 0,
      display: "flex",
      background: "none",
      borderRadius: theme.borderRadius,
      padding: "8px",
      verticalAlign: "middle",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      width: "70px",
      color: "#777",
      textOverflow: "ellipsis",
    },
    "& select.code-language": {
      textTransform: "Capitalize",
      width: "130px",
    },
    "& .divider": {
      width: "1px",
      margin: "0 4px",
      backgroundColor: "#eee",
    },
    "& .toolbar-item": {
      "& .icon": {
        display: "flex",
        lineHeight: "16px",
        width: "20px",
        height: "20px",
        userSelect: "none",
        marginRight: "8px",
        backgroundSize: "contain",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      },
      "& .text": {
        textTransform: "Capitalize",
        display: "flex",
        lineHeight: "20px",
        width: "70px",
        verticalAlign: "middle",
        textOverflow: "ellipsis",
        overflow: "hidden",
        height: "20px",
        textAlign: "left",
        fontSize: "14px",
      },
      "&:hover:not([disabled])": {
        background: "#eee",
      },
    },
    "& button.toolbar-item": {
      border: 0,
      display: "flex",
      background: "none",
      borderRadius: theme.borderRadius,
      padding: "8px",
      cursor: "pointer",
      verticalAlign: "middle",

      "&:disabled": {
        cursor: "not-allowed",
        "& i.format": {
          opacity: 0.2,
        },
      },
      "&.spaced": {
        marginRight: "2px",
      },
      "& i.format": {
        backgroundSize: "contain",
        height: "18px",
        width: "18px",
        marginTop: "2px",
        verticalAlign: "-0.25em",
        display: "flex",
        opacity: 0.6,
        justifyContent: "center",
        alignItems: "center",
      },
      "&.active": {
        background: "rgba(223, 232, 250, 0.3)",
      },
      "&.active i": {
        opacity: 1,
      },
    },

    "& i": {
      "&.undo": {
        backgroundImage: "url(/images/icons/arrow-counterclockwise.svg)",
      },
      "&.redo": {
        backgroundImage: "url(/images/icons/arrow-clockwise.svg)",
      },
      "&.bold": {
        backgroundImage: "url(/images/icons/type-bold.svg)",
      },
      "&.italic": {
        backgroundImage: "url(/images/icons/type-italic.svg)",
      },
      "&.underline": {
        backgroundImage: "url(/images/icons/type-underline.svg)",
      },
      "&.strikethrough": {
        backgroundImage: "url(/images/icons/type-strikethrough.svg)",
      },
      "&.code": {
        backgroundImage: "url(/images/icons/code.svg)",
      },
      "&.link": {
        backgroundImage: "url(/images/icons/link.svg)",
      },
      "&.left-align": {
        backgroundImage: "url(/images/icons/text-left.svg)",
      },
      "&.center-align": {
        backgroundImage: "url(/images/icons/text-center.svg)",
      },
      "&.right-align": {
        backgroundImage: "url(/images/icons/text-right.svg)",
      },
      "&.justify-align": {
        backgroundImage: "url(/images/icons/text-justify.svg)",
      },
    },
    "& .icon": {
      "&.public": {
        backgroundImage: "url(/images/icons/text-paragraph.svg)",
      },
      "&.private": {
        backgroundImage: "url(/images/icons/text-paragraph.svg)",
      },
      "&.paragraph": {
        backgroundImage: "url(/images/icons/text-paragraph.svg)",
      },
      "&.large-heading, &.h1": {
        backgroundImage: "url(/images/icons/type-h1.svg)",
      },
      "&.small-heading, &.h2": {
        backgroundImage: "url(/images/icons/type-h2.svg)",
      },
      "&.bullet-list, &.ul": {
        backgroundImage: "url(/images/icons/list-ul.svg)",
      },
      "&.numbered-list, &.ol": {
        backgroundImage: "url(/images/icons/list-ol.svg)",
      },
      "&.quote": {
        backgroundImage: "url(/images/icons/chat-square-quote.svg)",
      },
      "&.code": {
        backgroundImage: "url(/images/icons/code.svg)",
      },
    },
  },
  markdown: {
    backgroundImage: "url(/images/icons/markdown.svg)",
  },

  dropdown: {
    zIndex: 5,
    display: "block",
    position: "absolute",
    boxShadow:
      "0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5)",
    borderRadius: theme.borderRadius,
    minWidth: "100px",
    minHeight: "40px",
    background: "#fff",
    "& .item": {
      margin: "0 8px 0 8px",
      padding: "8px",
      color: "#050505",
      cursor: "pointer",
      lineHeight: "16px",
      fontSize: "15px",
      display: "flex",
      alignContent: "center",
      flexDirection: "row",
      flexShrink: 0,
      justifyContent: "space-between",
      backgroundColor: "#fff",
      borderRadius: theme.borderRadius,
      border: 0,
      minWidth: "268px",
      "&:last-child": {
        marginBottom: "8px",
      },
      "&:first-child": {
        marginTop: "8px",
      },
      "&:hover": {
        backgroundColor: "#eee",
      },
      "& .text": {
        textTransform: "capitalize",
        display: "flex",
        lineHeight: "20px",
        flexGrow: 1,
        width: "200px",
      },
      "& .icon": {
        display: "flex",
        width: "20px",
        height: "20px",
        backgroundSize: "contain",
        userSelect: "none",
        marginRight: "12px",
        lineHeight: "16px",
      },
      "& .active": {
        display: "flex",
        width: "20px",
        height: "20px",
        backgroundSize: "contain",
      },
    },
    "& .icon": {
      "&.public": {
        backgroundImage: "url(/images/icons/text-paragraph.svg)",
      },
      "&.private": {
        backgroundImage: "url(/images/icons/text-paragraph.svg)",
      },
      "&.paragraph": {
        backgroundImage: "url(/images/icons/text-paragraph.svg)",
      },
      "&.large-heading, &.h1": {
        backgroundImage: "url(/images/icons/type-h1.svg)",
      },
      "&.small-heading, &.h2": {
        backgroundImage: "url(/images/icons/type-h2.svg)",
      },
      "&.bullet-list, &.ul": {
        backgroundImage: "url(/images/icons/list-ul.svg)",
      },
      "&.numbered-list, &.ol": {
        backgroundImage: "url(/images/icons/list-ol.svg)",
      },
      "&.quote": {
        backgroundImage: "url(/images/icons/chat-square-quote.svg)",
      },
      "&.code": {
        backgroundImage: "url(/images/icons/code.svg)",
      },
    },
  },
  "link-editor": {
    position: "absolute",
    zIndex: 100,
    top: "-10000px",
    left: "-10000px",
    marginTop: "-6px",
    maxWidth: "300px",
    width: "100%",
    opacity: 0,
    backgroundColor: "#fff",
    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)",
    borderRadius: theme.borderRadius,
    transition: "opacity 0.5s ease-in-out",
    "& .link-input": {
      display: "block",
      width: "calc(100% - 16px)",
      boxSizing: "border-box",
      margin: " 8px 8px",
      padding: "8px 12px",
      borderRadius: theme.borderRadius,
      backgroundColor: "#eee",
      fontSize: "15px",
      color: "#050505",
      border: 0,
      outline: 0,
      position: "relative",
      "& a": {
        color: "rgb(33, 111, 219)",
        textDecoration: "none",
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        marginRight: "30px",
        textOverflow: "ellipsis",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    "& div.link-edit": {
      backgroundImage: "url(/images/icons/pencil-fill.svg)",
      backgroundSize: "16px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "35px",
      verticalAlign: "-0.25em",
      position: "absolute",
      right: "0px",
      top: "0px",
      bottom: "0px",
      cursor: "pointer",
    },
    "& .button": {
      width: "20px",
      height: "20px",
      display: "inline-block",
      padding: "6px",
      borderRadius: theme.borderRadius,
      cursor: "pointer",
      margin: "0 2px",
      "&.hovered": {
        width: "20px",
        height: "20px",
        display: "inline-block",
        backgroundColor: "#eee",
      },
    },
  },
  "mentions-menu": {
    width: "250px",
    "&:focus": {
      boxShadow: "rgb(180 213 255) 0px 0px 0px 2px",
      outline: "none",
    },
  },
  "typeahead-popover": {
    backgroundColor: "#fff",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)",
    borderRadius: theme.borderRadius,
    // width: "auto",
    zIndex: 500,
    marginTop: "25px",

    "& ul": {
      padding: "0",
      listStyle: "none",
      margin: "0",
      borderRadius: theme.borderRadius,
      maxHeight: "200px",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "& li": {
        margin: "0",
        minWidth: "180px",
        fontSize: "14px",
        outline: "none",
        cursor: "pointer",
        borderRadius: theme.borderRadius,
        "&.selected": {
          backgroundColor: "#eee",
        },
      },
    },
    "& li": {
      margin: "0 8px 0 8px",
      padding: "8px",
      color: "#050505",
      cursor: "pointer",
      lineHeight: "16px",
      fontSize: "15px",
      display: "flex",
      alignContent: "center",
      flexDirection: "row",
      flexShrink: 0,
      backgroundColor: "#fff",
      borderRadius: theme.borderRadius,
      border: 0,
      "&:active": {
        display: "flex",
        width: "20px",
        // height: "20px",
        backgroundSize: "contain",
      },
      "&:first-child": {
        borderRadius: `${theme.borderRadius} ${theme.borderRadius} 0 0`,
      },
      "&:last-child": {
        borderRadius: `0 0 ${theme.borderRadius} ${theme.borderRadius}`,
      },
      "&:hover": {
        backgroundColor: "#eee",
      },
      "& .text": {
        display: "flex",
        lineHeight: "20px",
        flexGrow: 1,
        width: "150px",
      },
    },
  },
});

export default styles;
