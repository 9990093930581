import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';

import { Box, CircularProgress, Grid, Menu, MenuItem } from '@mui/material';
import {
  CommentAuthor,
  CommentCard,
  CommentCardContent,
  CommentMoreIconButton,
  CommentSubject,
  CommentTextWrapper,
  CommentTextWrapperHide,
  ToggleCommentIcon,
} from '../styled';
import Translation from 'utils/translation';
import DateTime from 'components/Global/DateTime';
import DownloadAWSFile from 'components/Global/DownloadAWSFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import useCommentDelete from 'containers/App/Hooks/useCommentDelete';
import useCommentPublicUpdate from 'containers/App/Hooks/useCommentPublicUpdate';
import TicketCommentTextEditor from '../TicketCommentTextEditor';
import UserAvatar from 'components/Global/UserAvatar';
import graphQLQueries from 'graphql/comment';
import { useLazyQuery } from '@apollo/client';
import StylesFilter from './StylesFilter';
import { Interweave } from 'interweave';
import usePermissions from 'containers/App/Hooks/usePermissions';
function TicketComment(props) {
  const { showWrapper, public: isPublic } = props;

  const permission = usePermissions({
    access: {
      type: 'one',
      priority: 1,
      permissions: [
        {
          key: 'CAN_EDIT_TICKET',
          value: true,
        },
      ],
    },
  });

  return (
    <>
      {showWrapper ? (
        <CommentCard
          elevation={3}
        // sx={{ backgroundColor: isPublic ? "white" : "#E0E0E0"}}
        >
          <CommentCardContent>
            <CommentBody {...props} permission={permission.access} />
          </CommentCardContent>
          <CommentFooter {...props} />
        </CommentCard>
      ) : (
        <>
          <CommentBody {...props} permission={permission.access} />
          <CommentFooter {...props} />
        </>
      )}
    </>
  );
}

const CommentBody = (props) => {
  const {
    id,
    index,
    subject,
    ticket_id,
    author,
    public: isPublic,
    lexical,
    theme,
    body_preview,
    reportComment,
    documents,
    permission = false,
  } = props;
  const [showText, setShowText] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [menuLoading, setMenuLoading] = useState(false);
  const [htmlBody, setHtmlBody] = useState(null);
  const [getBody, { loading: loadingMessageBody, error, data: messageBody }] = useLazyQuery(graphQLQueries().GET_COMMENT_BODY);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleInternalPublic = (e) => {
    handlePublicUpdate({ value: !isPublic });
    setAnchorEl(null);
  };
  const handleShowText = (comment) => {
    setShowText(!showText);
    getBody({ variables: { id: id } });
  };

  const handleShowAllText = (ticket) => {
    // setShowText(!showText);
    // ticket.comments[0].forEach(comments => {
    //   handleShowText(comments.id);
    // });
    //handleShowText(ticket.commentID);
    // console.log('*********',ticket);
  };
  useEffect(() => {
    if (loadingMessageBody === false || htmlBody === null) {
      // setHtmlBody(
      //   <Interweave
      //     noHtml={true}
      //     filters={[new StylesFilter()]}
      //     content={messageBody?.ticket_comment_by_pk?.source?.body ?? ""}
      //   />
      // );

      setHtmlBody(
        <div
          style={{ width: '100%' }}
          dangerouslySetInnerHTML={{
            __html: messageBody?.ticket_comment_by_pk?.source?.body ?? '',
          }}
        />,
      );
    }
  }, [loadingMessageBody]);
  const { handle: handleDelete, loading: deleteLoading } = useCommentDelete({
    commentID: id,
    ticketID: ticket_id,
  });
  const { handle: handlePublicUpdate, loading: publicUpdateLoading } = useCommentPublicUpdate({
    commentID: id,
    ticketID: ticket_id,
  });
  useEffect(() => {
    if (deleteLoading || publicUpdateLoading) {
      setMenuLoading(true);
    } else {
      setMenuLoading(false);
    }
  }, [deleteLoading, publicUpdateLoading]);
  const handleInternalDelete = (e) => {
    handleDelete();
    setAnchorEl(null);
  };
  const eml = documents?.filter((d) => d.mime_type === 'eml')[0];
  return (
    <Grid container spacing={0} rowSpacing={0} columnSpacing={2} sx={{}}>
      <Grid item xs={1}>
        <Grid container spacing={0} rowSpacing={0} columnSpacing={0} sx={{}}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                padding: 0,
                flexDirection: 'column',
              }}
            >
              <UserAvatar user={author} />
              <CommentAuthor>{author?.name}</CommentAuthor>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: 1,
            padding: 0,
            marginBottom: 2,
          }}
        >
          <ToggleCommentIcon onClick={handleShowText} baseClassName='fas' className={`${showText ? 'fa-chevron-down' : 'fa-chevron-right'}`} />
          <CommentSubject onClick={handleShowText}>{subject}</CommentSubject>
        </Box>
        {!showText && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 1,
              padding: 0,
              cursor: 'pointer',
            }}
            onClick={handleShowText}
          >
            <CommentTextWrapperHide>
              {!lexical ? (
                body_preview
              ) : (
                <>
                  <TicketCommentTextEditor id={id} readOnly={true} value={lexical} namespace={`ticketCommentTextEditor${index}`} />
                </>
              )}
            </CommentTextWrapperHide>
          </Box>
        )}
        {showText && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 1,
              padding: 0,
            }}
          >
            <CommentTextWrapper>
              {!lexical ? (
                // this is the section for when you expand a comment
                htmlBody
              ) : (
                <TicketCommentTextEditor readOnly={true} value={lexical} namespace={`ticketCommentTextEditor${index}`} />
              )}
            </CommentTextWrapper>
          </Box>
        )}
      </Grid>
      <Grid item xs={1}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 1,
            padding: 0,
          }}
        >
          <>
            {menuLoading ? (
              <CircularProgress size={'20px'} />
            ) : (
              <>
                <CommentMoreIconButton
                  size={'small'}
                  id='basic-button'
                  color={'secondary'}
                  // aria-controls={open ? 'basic-menu' : undefined}
                  // aria-haspopup="true"
                  // aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  variant={'outlined'}
                >
                  <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
                </CommentMoreIconButton>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{ borderRadius: 0 }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {permission &&
                    (isPublic ? (
                      <MenuItem onClick={handleInternalPublic}>{Translation('ticketViewPage.makeInternal')}</MenuItem>
                    ) : (
                      <MenuItem onClick={handleInternalPublic}>{Translation('ticketViewPage.makePublic')}</MenuItem>
                    ))}
                  {eml ? (
                    <MenuItem onClick={(e) => { }}>
                      <DownloadAWSFile document={eml} percentage={false}>
                        {Translation('ticketViewPage.downloadEml')}
                      </DownloadAWSFile>
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={(e) => { }} disabled={true}>
                      {Translation('ticketViewPage.downloadEml')}
                    </MenuItem>
                  )}

                  {/* TODO: button has been disabled as report is not currently working */}
                  {/* <MenuItem
                    onClick={() =>
                      reportComment({
                        commentID: id,
                        ticketID: ticket_id,
                      })
                    }
                  >
                    {Translation("ticketViewPage.reportComment")}
                  </MenuItem> */}
                  {/* <MenuItem
                 onClick={() =>
                  handleShowAllText({
                    commentID: id,
                    ticketID: ticket_id,
                  })
                }
                  >
                  Expand All
                  </MenuItem> */}
                  {permission == true && (
                    <MenuItem sx={{ color: theme.palette.error.main }} onClick={(e) => handleInternalDelete(e)}>
                      {Translation('ticketViewPage.deleteComment')}
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </>
        </Box>
      </Grid>
    </Grid>
  );
};
const CommentFooter = (props) => {
  const { source, createdAt, public: isPublic, theme } = props;

  return (
    <Grid container spacing={0} rowSpacing={0} columnSpacing={0} sx={{}}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            gap: 1,
            padding: theme.spacing(1, 2, 2, 2),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              gap: 1,
              flex: 1,
            }}
          >
            {source === 'external_email' && (
              <Tooltip title={Translation(`ticketViewPage.ticketSource.${source}`)} followCursor TransitionComponent={Zoom}>
                <FontAwesomeIcon
                  icon={['fas', 'envelope']}
                  fixedWidth
                  style={{
                    position: 'relative',
                    top: '2px',
                  }}
                />
              </Tooltip>
            )}
            {source === 'internal_web' && (
              <Tooltip title={Translation(`ticketViewPage.ticketSource.${source}`)} followCursor TransitionComponent={Zoom}>
                <FontAwesomeIcon icon={['fas', 'globe']} fixedWidth />
              </Tooltip>
            )}

            {isPublic ? (
              <Tooltip title={Translation('ticketViewPage.publicTicketComment')} followCursor TransitionComponent={Zoom}>
                <FontAwesomeIcon icon={['fas', 'lock-open']} fixedWidth />
              </Tooltip>
            ) : (
              <Tooltip title={Translation('ticketViewPage.internalTicketComment')} followCursor TransitionComponent={Zoom}>
                <FontAwesomeIcon icon={['fas', 'lock']} fixedWidth />
              </Tooltip>
            )}
          </Box>
          <Box
            sx={{
              color: theme.palette.grey[700],
              // flex: 1,
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              position: 'relative',
              top: '2px',
            }}
          >
            <DateTime data={createdAt} ago={false} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default withStyles((theme) => ({}), { withTheme: true })(TicketComment);
