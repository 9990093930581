export default function generatePermissions(user) {
  if (user.permissions) {
    let finalBoards = [];
    let finalAccess = [];
    let finalTeams = [];
    let finalOrganizations = [];
    let finalRoles = [];
    let groupBoardsArray = [];
    let groupTeamsArray = [];
    let groupAccessArray = [];
    let organizationsArray = [];
    let individualBoards = [];
    let individualAccess = [];
    let individualTeams = [];

    function generateBoards(boards, boardArray, allowAll = false) {
      if (boards.length > 0) {
        boards.forEach((board) => {
          const name = board.board.name;
          const id = board.board.id;
          const value = board.value;
          const organization = board.organization;

          //add to groupBoards if not already there if it is there then update the value if it is true

          let boardObject = { name, id, value };
          if (organization?.id) {
            boardObject.organization = organization;
          }
          if (allowAll) {
            boardArray.push(boardObject);
          } else {
            const index = boardArray.findIndex((board) => board.id === id);
            if (index > -1) {
              if (value === true) {
                boardArray[index].value = value;
              }
            } else {
              boardArray.push(boardObject);
            }
          }
        });
      }

      return boardArray;
    }
    function generateTeams(teams, teamArray, allowAll = false) {
      if (teams.length > 0) {
        teams.forEach((team) => {
          const name = team.team.name;
          const id = team.team.id;
          const value = team.value;
          const organization = team.organization;

          //add to groupBoards if not already there if it is there then update the value if it is true

          let teamObject = { name, id, value };
          if (organization?.id) {
            teamObject.organization = organization;
          }
          if (allowAll) {
            teamArray.push(teamObject);
          } else {
            const index = teamArray.findIndex((board) => board.id === id);
            if (index > -1) {
              if (value === true) {
                teamArray[index].value = value;
              }
            } else {
              teamArray.push(teamObject);
            }
          }
        });
      }

      return teamArray;
    }
    function generateAccess(access, accessArray, allowAll = false) {
      if (access.length > 0) {
        access.forEach((access) => {
          const accessKey = access.access_key.key;
          const accessValue = access.value;
          const organization = access.organization;
          //add to group access if not already there if it is there then update the value if it is true
          let boardObject = { key: accessKey, value: accessValue };
          if (organization?.id) {
            boardObject.organization = organization;
          }
          if (allowAll) {
            accessArray.push(boardObject);
          } else {
            const accessIndex = accessArray.findIndex((access) => access.key === accessKey);
            if (accessIndex > -1) {
              if (accessValue === true) {
                accessArray[accessIndex].value = true;
              }
            } else {
              accessArray.push({ key: accessKey, value: accessValue });
            }
          }
        });
      }
      return accessArray;
    }

    if (user.permissions.groups.length > 0) {
      const groups = user.permissions.groups;
      groups.forEach((group) => {
        const groupName = group.group_permission.key;
        const access = group.group_permission.access;
        const boards = group.group_permission.boards;
        const teams = group.group_permission.teams;
        finalRoles.push(groupName);
        groupBoardsArray = generateBoards(boards, groupBoardsArray);
        groupAccessArray = generateAccess(access, groupAccessArray);
        groupTeamsArray = generateTeams(teams, groupTeamsArray);
      });
    }
    if (user.permissions.organizations.length > 0) {
      const organizations = user.permissions.organizations;

      organizations.forEach((organizationPermission) => {
        const organization = organizationPermission.organization_permission.organization;
        const access = organizationPermission.organization_permission.access;
        const boards = organizationPermission.organization_permission.boards;
        const teams = organizationPermission.organization_permission.teams;
        const accessArray = [];
        const boardsArray = [];
        const teamsArray = [];
        generateBoards(boards, boardsArray);
        generateAccess(access, accessArray);
        generateTeams(teams, teamsArray);
        organizationsArray.push({
          organization,
          boards: boardsArray,
          access: accessArray,
          teams: teamsArray,
        });
      });
      finalOrganizations = organizationsArray;
    }

    if (user.permissions.individual !== null) {
      const individual = user.permissions.individual;
      generateBoards(individual.boards, individualBoards, true);
      generateAccess(individual.access, individualAccess, true);
      generateTeams(individual.teams, individualTeams, true);
    }

    // Merge Individual Boards and Access + Individual Organizations Boards and Access
    if (individualBoards.length > 0) {
      individualBoards.forEach((board) => {
        const organization = board.organization;
        if (organization?.id) {
          const index = organizationsArray.findIndex((org) => org.organization.id === organization.id);

          if (index > -1) {
            const boards = organizationsArray[index].boards;
            // console.log(boards);
            if (boards.length > 0) {
              boards.forEach((orgBoard) => {
                if (orgBoard.id === board.id) {
                  orgBoard.value = board.value;
                } else {
                  organizationsArray[index].boards.push({
                    name: board.name,
                    id: board.id,
                    value: board.value,
                  });
                }
              });
            } else {
              organizationsArray[index].boards.push({
                name: board.name,
                id: board.id,
                value: board.value,
              });
            }
          }
        } else {
          const index = groupBoardsArray.findIndex((groupBoard) => groupBoard.id === board.id);
          if (index > -1) {
            groupBoardsArray[index].value = board.value;
          } else {
            groupBoardsArray.push(board);
          }
        }
      });
    }
    finalBoards = groupBoardsArray;
    if (individualAccess.length > 0) {
      individualAccess.forEach((access) => {
        const organization = access.organization;
        if (organization?.id) {
          const index = organizationsArray.findIndex((org) => org.organization.id === organization.id);
          if (index > -1) {
            const accessArray = organizationsArray[index].access;
            if (accessArray.length > 0) {
              accessArray.forEach((orgAccess) => {
                if (orgAccess.key === access.key) {
                  orgAccess.value = access.value;
                } else {
                  accessArray.push({ key: access.key, value: access.value });
                }
              });
            } else {
              accessArray.push({ key: access.key, value: access.value });
            }
          }
        } else {
          const index = groupAccessArray.findIndex((groupAccess) => groupAccess.key === access.key);
          if (index > -1) {
            groupAccessArray[index].value = access.value;
          } else {
            groupAccessArray.push({ key: access.key, value: access.value });
          }
        }
      });
    }

    finalAccess = groupAccessArray;

    // Merge Individual Teams and Access + Individual Organizations Teams and Access
    if (individualTeams.length > 0) {
      individualTeams.forEach((team) => {
        const organization = team.organization;
        if (organization?.id) {
          const index = organizationsArray.findIndex((org) => org.organization.id === organization.id);

          if (index > -1) {
            const teams = organizationsArray[index].teams;
            // console.log(boards);
            if (teams.length > 0) {
              teams.forEach((orgBoard) => {
                if (orgBoard.id === team.id) {
                  orgBoard.value = team.value;
                } else {
                  organizationsArray[index].boards.push({
                    name: team.name,
                    id: team.id,
                    value: team.value,
                  });
                }
              });
            } else {
              organizationsArray[index].boards.push({
                name: team.name,
                id: team.id,
                value: team.value,
              });
            }
          }
        } else {
          const index = groupTeamsArray.findIndex((groupTeam) => groupTeam.id === team.id);
          if (index > -1) {
            groupTeamsArray[index].value = team.value;
          } else {
            groupTeamsArray.push(team);
          }
        }
      });
    }
    finalTeams = groupTeamsArray;
    const finalPermissions = {
      roles: finalRoles,
      organizations: finalOrganizations,
      access: finalAccess,
      boards: finalBoards,
      teams: finalTeams,
    };
   // console.log(finalPermissions);
    return finalPermissions;
  }
  return null;
}
