import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import { LinearProgress } from "@mui/material";

function DownloadAWSFile(props) {
  const { document: attachment, children, percentage = false } = props;
  // console.log(doc);
  const [progress, setProgress] = useState(0);


  const downloadFile = async () => {
    const response = await fetch(
      "https://ssg-uploads.s3.eu-west-2.amazonaws.com" + "/" + attachment.url
    );
    const contentLength = response.headers.get("content-length");
    const total = parseInt(contentLength, 10);
    let loaded = 0;
    const reader = response.body.getReader();
    const chunks = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      chunks.push(value);
      loaded += value.length;
      setProgress(Math.floor((loaded / total) * 100));
    }
    const blob = new Blob(chunks);
    const dataURL = window.URL.createObjectURL(blob);
    let tempLink = document.createElement("a");
    tempLink.href = dataURL;
    tempLink.setAttribute("download", attachment.name);
    tempLink.click();
  };

  let name = attachment.name;
  //replace _ with space
  name = name.replace(/_/g, " ");
  //remove the first block of text before the first space
  name = name.substring(name.indexOf(" ") + 1);
  return (
    <>
      <div onClick={() => downloadFile()}>
        {!children && <>{name}</>}
        {children && <>{children}</>}
        {progress > 0 && progress < 100 && (
          <>
            <LinearProgress variant="determinate" value={progress} />
            {percentage && <> {progress} %</>}
          </>
        )}
      </div>
    </>
  );
}

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(DownloadAWSFile);
