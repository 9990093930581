import { signInSSO } from "slices/user";
import { setSSOLoginURL, clearSSOCode } from "slices/socket";

const mssso = async (socket, store) => {
  socket.on("authCodeUrlResponse", (payload) => {
    store.dispatch(setSSOLoginURL(payload));
  });
  socket.on("mssso", async (payload) => {
    const { data, redirectTo } = payload;
    console.log("mssso", payload);
    payload = {
      ...data,
      socket: socket,
    };

    await store.dispatch(signInSSO({
      ...payload,
      redirectTo: redirectTo
    }));


    store.dispatch(clearSSOCode());
  });
};

export default mssso;
