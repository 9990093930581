import React from 'react';
import { Chip } from '@mui/material';
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";

const prioritySize = {
    standard: 4,
    comfortable: 6,
    compact: 3,
};

const PriorityLabel = ({ theme, value, density }) => {

    let color = theme.palette?.main;
    if (theme.palette?.priority[value]) {
        color = theme.palette?.priority[value];
    }
    return (
        <Chip
            label={value}
            sx={{
                backgroundColor: color,
                color: 'white!important',
                boxShadow: 'none',
                borderRadius: theme.borderRadius,
                padding: theme.spacing(0, 1),
                height: theme.spacing(prioritySize[density]),
                width: '100%',
                textTransform: 'capitalize',
            }}
        />
    )
}

export default withStyles(
    (theme) => ({
        ...layoutStyles(theme),
    }),
    { withTheme: true }
)(PriorityLabel);