import { useContext, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { SiteData } from 'context/siteData';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const INSERT_VIEW = gql`
mutation INSERT_VIEW($person_id: uuid = "", $name: String = "", $state: json = "", $datatable: String = "", $default_flag: Boolean = false, $global: Boolean = false) {
    insert_datatable_view(objects: {person_id: $person_id, name: $name, state: $state, datatable: $datatable, default_flag: $default_flag, global: $global, route: "/" }) {
      affected_rows
      returning {
        id
        state
        created_at
        datatable
        default_flag
        global
        name
        updated_at
      }
    }
  }`


const DELETE_VIEW = gql`
mutation DELETE_VIEW($id: uuid = "") {
    delete_datatable_view_by_pk(id: $id) {
        id
    }
}`

let FALLBACK_VIEW = {
    state: {
        pagination: {
            paginationModel: {
                pageSize: 25,
                page: 0
            }
        },
        filter: {
            filterModel: {
                "items": [
                    {
                        field: "status_id",
                        operator: "_in",
                        id: 71494,
                        value: [
                            "115dc3ec-c6ca-4377-8d6b-d24045c345c8",
                            "6b888b99-cd8d-4cf5-a255-1cca328effa2"
                        ]
                    }
                ],
                "logicOperator": "and",
                "quickFilterLogicOperator": "and",
                "quickFilterValues": []
            }
        },
    },
}


const useTableState = ({ tableName, dataTableIdentifier, apiRef }) => {

    const { data: siteData, functions } = useContext(SiteData);
    const { id: person_id } = useSelector(state => state.user.user);

    const [state, setState] = useState();
    const [avaliableViews, setAvaliableViews] = useState({});
    const [finishedInit, setFinishedInit] = useState(false);

    const [hasDefault, setHasDefault] = useState(false);
    const [recentlyUpdated, setRecentlyUpdated] = useState(null);
    const [numberOfViews, setNumberOfViews] = useState(0);

    // useEffect(() => {
    //     console.log({ avaliableViews })
    // }, [avaliableViews])

    const [insertView, { data: insertViewData }] = useMutation(INSERT_VIEW)
    const [deleteView, { data: deleteViewData }] = useMutation(DELETE_VIEW)

    useEffect(() => {
        if (siteData) {
            const views = siteData.views.filter(view => view.datatable === dataTableIdentifier);
            // console.log({ views })
            const USER_DEFAULT_VIEW = views.filter(view => view.datatable === dataTableIdentifier && view.default_flag === true)[0];
            const USER_RECENTLY_UPDATED = views?.reduce((latest, current) => {
                const latestUpdatedTime = new Date(latest.updated_at).getTime();
                const currentUpdatedTime = new Date(current.updated_at).getTime();
                return currentUpdatedTime > latestUpdatedTime ? current : latest;
            }, views[0]);
            const currentTime = new Date().getTime();
            const sixtyMinutesAgo = currentTime - (60 * 60 * 1000);
            const isRecentlyUpdated = USER_RECENTLY_UPDATED && new Date(USER_RECENTLY_UPDATED.updated_at).getTime() > sixtyMinutesAgo;

            setHasDefault(USER_DEFAULT_VIEW ? true : false);
            setRecentlyUpdated(isRecentlyUpdated ? true : false);
            setNumberOfViews(views.length);

            // if (isRecentlyUpdated) {
            //     console.log('LOADING RECENTLY UPDATED VIEW', USER_RECENTLY_UPDATED.state)
            // } else if (USER_DEFAULT_VIEW) {
            //     console.log('LOADING DEFAULT VIEW', USER_DEFAULT_VIEW.state)
            // } else {
            //     console.log('LOADING FALLBACK VIEW', FALLBACK_VIEW.state)
            // }

            const firstLoadView = USER_RECENTLY_UPDATED ?? USER_DEFAULT_VIEW ?? FALLBACK_VIEW;
            // console.log(USER_RECENTLY_UPDATED)
            // console.log(firstLoadView.state)
            setState(firstLoadView.state);

            if (USER_DEFAULT_VIEW || USER_RECENTLY_UPDATED) {
                setAvaliableViews({
                    views: views,
                    currentView: firstLoadView
                });
            }

            setFinishedInit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteData])

    useEffect(() => {
        if (insertViewData) {
            const updateInternal = async () => {
                functions.updateData('views', insertViewData?.insert_datatable_view?.returning[0])
                setHasDefault(true);
                setNumberOfViews(numberOfViews + 1);
                setRecentlyUpdated(insertViewData?.insert_datatable_view?.returning[0])

                let tempAV = { ...avaliableViews };

                if (!tempAV) {
                    tempAV = {
                        views: [],
                        currentView: null
                    };
                }

                if (tempAV?.views?.length === 0) {
                    setAvaliableViews({
                        views: [insertViewData?.insert_datatable_view?.returning[0]],
                        currentView: insertViewData?.insert_datatable_view?.returning[0]
                    });
                } else {
                    let newViews = tempAV?.views ?? [];
                    newViews.push(insertViewData?.insert_datatable_view?.returning[0]);
                    setAvaliableViews({
                        views: newViews,
                        currentView: insertViewData?.insert_datatable_view?.returning[0]
                    });
                }
            }
            updateInternal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insertViewData])

    useEffect(() => {
        if (deleteViewData) {
            const updateInternal = async () => {
                functions.deleteData('views', deleteViewData?.delete_datatable_view_by_pk?.id)
                setNumberOfViews(numberOfViews - 1)
                let currentView = avaliableViews?.currentView;
                if (currentView?.id === deleteViewData?.delete_datatable_view_by_pk?.id) {
                    const defaultView = avaliableViews?.views.filter(view => view.default_flag === true)[0];
                    currentView = defaultView ?? FALLBACK_VIEW;
                }
                setAvaliableViews({
                    views: avaliableViews?.views.filter(view => view.id !== deleteViewData?.delete_datatable_view_by_pk?.id),
                    currentView: avaliableViews?.currentView
                });
            }
            updateInternal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteViewData])


    useEffect(() => {
        // console.log({ state })
        // console.log({ hasDefault, numberOfViews })
        // console.log({ state, finishedInit, siteData })
        if (state && finishedInit && siteData) {
            if (!hasDefault && numberOfViews === 0) {
                insertView({
                    variables: {
                        person_id: person_id,
                        name: 'Default',
                        state: state,
                        datatable: dataTableIdentifier,
                        default_flag: true,
                    }
                })
            }
        }

        if (state) {
            apiRef.current.restoreState(state)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])


    const saveNewView = (params) => {
        const view = insertView({
            variables: {
                person_id: person_id,
                name: params,
                state: apiRef.current.exportState(),
                datatable: dataTableIdentifier,
                default_flag: false,
                global: false
            }
        })
        setCurrentView(view);
    }


    const setCurrentView = (params) => {
        setAvaliableViews((prev) => {
            return {
                ...prev,
                currentView: params
            }
        })
        setState(params.state);
    }

    const removeView = (params) => {
        deleteView({
            variables: {
                id: params
            }
        })
    }

    const updateView = async (identifier) => {
        let currentDate = new Date()
        let view = {
            ...avaliableViews?.currentView,
            updated_at: currentDate.toISOString(),
            state: apiRef.current.exportState()
        }

        switch (identifier) {
            case 'pagination':
                functions.updateData('views', view)
                break;
            case 'currentView':
                functions.updateData('views', view)
                break;
            default:
                break;
        }
    }

    return {
        saveNewView,
        setCurrentView,
        deleteView: removeView,
        updateView: updateView,
        initialState: state,
        state,
        currentView: avaliableViews?.currentView ?? '',
        avaliableViews: avaliableViews?.views ?? [],
    }
}

export default useTableState;