import React from "react";

import withStyles from "@mui/styles/withStyles";
import { Card, Modal } from "@mui/material";
function TicketModal(props) {
  const {
    theme,
    open,
    handleClose,
    handleCallback = () => { },
    content,
    widthOverride
  } = props;
  let childWithProps;
  if (content) {
    childWithProps = React.cloneElement(content, {
      handleClose,
      handleCallback,
      theme,
    });
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxWidth: "80%",
    maxHeight: "80%",
    // height: "80vh",
    transform: "translateX(-50%) translateY(calc(-50% - .5px))",
    overflow: "auto",
    // minWidth: widthOverride ? widthOverride : "65%",
  };
  return (
    <Modal open={open} onClose={handleClose} >
      <Card sx={style}>
        {childWithProps}
      </Card>
    </Modal>
  );
}

export default withStyles((theme) => ({}), { withTheme: true })(TicketModal);
