import { GridFilterPanel } from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import { Rule } from '@mui/icons-material';
import { useEffect } from 'react';


function CustomFilterPanel(props) {
    return (
        <>
            <GridFilterPanel
                {...props}
                sx={{
                    width: '590px',
                    '& .MuiDataGrid-filterFormValueInput': {
                        width: '250px',
                    },
                    '& .MuiDataGrid-filterFormOperatorInput': {
                        minWidth: '140px',
                    },
                }}
            />
            <Button
                style={{
                    bottom: 4,
                    position: 'absolute',
                    right: 130
                }}
                startIcon={<Rule />}
                onClick={() => props.apiRef.current.hideFilterPanel()}>
                Apply Filters
            </Button>
        </>
    )
}

export default CustomFilterPanel;