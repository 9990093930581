import React, { memo, useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import TicketComment from "../../components/TicketComment";
function TicketCommentContainer(props) {
  const { comment, index, reportComment, showWrapper = true } = props;
  const {
    alias,
    created_at,
    updated_at,
    ticket_id,
    public: isPublic,
    id,
    source,
    person,
    source: {
      lexical,
      body,
      subject,
      body_preview,
      type: { name: sourceName },
    },
    documents,
  } = comment;

  // return <TicketComment
  //     index={index}
  //     ticket_id={ticket_id}
  //     id={id}
  //     author={name ?? 'Unknown'}
  //     public={isPublic}
  //     subject={subject}
  //     lexical={lexical}
  //     htmlBody={htmlBody}
  //     createdAt={created_at}
  //     source={sourceName}
  // />;

  // return <div>{htmlBody}</div>

  return (
    <TicketComment
      reportComment={reportComment}
      alias={alias}
      index={index}
      ticket_id={ticket_id}
      id={id}
      author={person}
      public={isPublic}
      subject={subject}
      lexical={lexical}
      body_preview={body_preview}
      createdAt={created_at}
      source={sourceName}
      documents={documents}
      showWrapper={showWrapper}
    />
  );
}

export default withStyles((theme) => ({}), { withTheme: true })(
  TicketCommentContainer
);
