import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Translation from 'utils/translation';
import graphQLQueries from 'graphql/ticket';
import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DataTablePro from "components/DataTablePro"
import { useElementSize } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import useTranslation from "containers/App/Hooks/useTranslation";
import UserAvatar from "components/Global/UserAvatar";
import { gql } from "@apollo/client";
import { startCase } from "lodash";
import Moment from 'moment';
import TableGeneric from 'components/TableGeneric';
import { EnumType } from 'json-to-graphql-query';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';



const OpenTickets = ({ company }) => {
  //    const { setFormFields, data, id, onSave, user, Edituser, userID } = props;
  const { id } = useParams();
  //console.log("PERSONOPENTICKETS", user)
  // const { userID } = useParams();
  //console.log(userID)
  // mapping through the user?.person[0]?.permissions?.groups[] array to get geth the keys of all the the groups and adding rthem to an array and then console logging them out

  // console.log('GROUPS',groups)
  //console.log
  // checking if groups contains agent 

  const { ref, height } = useElementSize();
  const { ref: HeaderRef, height: headerHeight } = useElementSize();
  const navigate = useNavigate();
  const handleRowClick = (event) => {
    if (event?.alias) {
      navigate("/support/tickets/" + event.alias);
    } else if (event?.row?.alias) {
      navigate("/support/tickets/" + event.row.alias);
    }
  };

  const query = {
    query: {
      ticket: {
        __args: {
          order_by: {
            updated_at: new EnumType('desc')
          },
          where: {
            status: {
              name: {
                _neq: 'resolved'
              }
            },
            organization_id: {
              _eq: id
            }
          }
        },
        id: true,
        priority_id: true,
        status_id: true,
        alias: true,
        subject: true,
        status: {
          name: true
        },
        priority: {
          name: true
        },
        requester: {
          name: true
        },
        assignee: {
          name: true
        },
        team: {
          name: true
        },
        updated_at: true
      }
    }
  };

  const gqlQuery = jsonToGraphQLQuery(query);
  console.log(gqlQuery);

  const incomingColumns = [
    { field: 'alias', headerName: 'Alias', width: 150 },
    {
      field: 'status.name',
      headerName: 'Status',
      width: 150,
      valueGetter: (params) => startCase(params.row.status?.name) || 'N/A',

    },
    {
      field: 'priority.name',
      headerName: 'Priority',
      width: 150,
      valueGetter: (params) => startCase(params.row.priority?.name) || 'N/A',
    },
    { field: 'subject', headerName: 'Subject', width: 200 },
    {
      field: 'requester.name',
      headerName: 'Requester',
      width: 150,
      // value getter, but also make sure first letter is capitalized
      valueGetter: (params) => startCase(params.row.requester?.name) || 'N/A'
    },
    {
      field: 'assignee.name',
      headerName: 'Assignee',
      width: 150,
      valueGetter: (params) => startCase(params.row.assignee?.name) || 'N/A'
    },
    {
      field: 'team.name',
      headerName: 'Team',
      width: 150,
      valueGetter: (params) => startCase(params.row.team?.name) || 'N/A'
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      valueGetter: (params) => Moment(params.row.updated_at).fromNow()
    },
  ]

  const searchQuery = {
    default: [
      { key: 'subject', operator: '_ilike' },
      { key: 'status.name', operator: '_ilike' },
      { key: 'priority.name', operator: '_ilike' },
      { key: 'requester.name', operator: '_ilike' },
      { key: 'assignee.name', operator: '_ilike' },
      { key: 'team.name', operator: '_ilike' },
    ],
  }


  const { translate } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "white !important",
        paddingTop: 4,
        borderRadius: 2,
        boxShadow: 2,
        marginTop: 2,
        //  display: 'flex',
        alignItems: 'center',
        height: 'auto',
      }} >
      <Box
        ref={ref}
        sx={{
          height: "100%",
          display: "block",
        }}
      >
        <Typography
          ref={HeaderRef}
          variant="h6"
          textAlign={"center"}
          paddingBottom={1}
        >
          {/* {translate(props.config.name)} */}
          Open Tickets
        </Typography>
        <TableGeneric
          columns={incomingColumns}
          tableName='ticket'
          query={query}
          onRowClick={handleRowClick}
          searchQuery={searchQuery}
        />
      </Box>
    </Box >

  )
}

export default OpenTickets;
