import { styled } from '@mui/material/styles';
import { Box, Card, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const ModalContainer = styled(Card)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    margin: 0,
    width: '80%',
    backgroundColor: 'transparent',
}));

export const ModalHeaderContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    'svg': {
        color: theme.palette.text.light,
        fontSize: theme.spacing(3),
    }
}));

export const ModalHeaderTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.light,
    margin: 0,
    fontSize: theme.spacing(3),
    flexGrow: 1
}));

export const HeaderCloseIcon = styled(FontAwesomeIcon)(({ theme }) => ({
    cursor: 'pointer'
}));

export const ModalBodyContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.primary.paper,
}));

export const ModalTextInput = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    width: '100%',
}));

export const ModelLoadingIndicatorContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(2),
}));

export const ModelLoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    width: '100%',
    margin: theme.spacing(3),
}));

export const QuickCreateModelDisclaimer = styled(Typography)(({ theme }) => ({
    fontWeight: '400',
    color: theme.palette.text.grayedOut,
    flexGrow: 1,
    width: '100%',
}));

export const QuickCreateFooter = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    gap: theme.spacing(2),
}));

export const QuickCreateButton = styled(Button)(({ theme }) => ({
    // width: '150px'
}));

export const QuickCreateError = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontWeight: '400',
    flexGrow: 1,
    width: '100%',
    fontSize: theme.spacing(2.5),
}));

export const QuickCreateSuccessTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.main,
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(3),
    flexGrow: 1
}));

export const DropdownGroups = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
}));

export const LeftGroup = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    alignItems: 'flex-start',
}));

export const RightGroup = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    alignItems: 'flex-end',
}));

export const GroupHeader = styled(Typography)(({ theme, align = "left" }) => ({
    color: theme.palette.text.grayedOut,
    fontWeight: '400',
    flexGrow: 1,
    width: '100%',
    textAlign: align,
}));