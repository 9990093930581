import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import AppBar from '@mui/material/AppBar';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Toolbar from '@mui/material/Toolbar';

import layoutStyles from 'containers/Layout/styles';
import Profile from 'containers/Layout/Profile';
import Search from 'components/Layout/Header/Search';
import SettingsButton from 'containers/Layout/Settings/settingsButton';
import Tabs from './Tabs';
import NotificationButton from 'containers/Layout/NotificationDrawer/notificationButton';
import Tooltip from '@mui/material/Tooltip';
import CallIcon from '@mui/icons-material/Call';
import HeaderButton from './HeaderButton';
import PlusIcon from '@mui/icons-material/Add';
import TranslationComponent from 'components/Global/Translation';
import LanguageSelector from 'components/LanguageSelector';
import { ContactMail, MailOutlined, MailSharp } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import QuickCreateTicket from 'Modals/QuickCreateTicket';
import FormLoader from 'components/FormLoader';
import { Modal } from '@mui/base';
import Translation from 'utils/translation';
import { Box } from '@mui/material';

class HeaderTabs extends React.Component {
    //  showUser = () => {
    //   const user = useSelector((state) => state?.user?.user);
    //  console.log("user", user);
    //  }
    handleOnClickAway = () => {
        const { showSearch, onShowSearch, handleModal } = this.props;

        setTimeout(() => {
            if (showSearch) {
                onShowSearch(false);
            }
        }, 0);
    };

    handleHideSearch = (value) => {
        this.props.onShowSearch(value);
    };

    render() {
        const {
            classes,
            drawerIsOpen,
            selectedMenuItemHasChildren,
            openViews,
            selectedMenuItem,
            showSearch,
            handleDrawerToggle,
            handleCloseView,
            handleTabChange,
            user,
        } = this.props;

        return (
            <AppBar
                elevation={2}
                position='fixed'
                color='default'
                className={classNames(
                    'primary-appbar',
                    `${selectedMenuItemHasChildren && 'no-box-shadow'}`,
                    classes.appBar,
                    drawerIsOpen && classes.appBarShift,
                )}
            >
                <Toolbar disableGutters={!drawerIsOpen}>
                    <IconButton aria-label='open drawer' className={classes.burgerIcon} onClick={handleDrawerToggle} size='large'>
                        <MenuIcon />
                    </IconButton>
                    {openViews && (
                        <Tabs openViews={openViews} selectedMenuItem={selectedMenuItem} handleCloseView={handleCloseView} handleTabChange={handleTabChange} />
                    )}
                    {/* {showSearch && (
            <ClickAwayListener onClickAway={this.handleOnClickAway}>
              <Search />
            </ClickAwayListener>
          )} */}
                    <Tooltip title={<TranslationComponent tag='tooltip.search' />}>
                        <Search />
                    </Tooltip>

                    <Tooltip title={<TranslationComponent tag='tooltip.contactUs' />}>
                        <Box>
                            <HeaderButton icon={CallIcon} link={'/help/contact-us'} />
                        </Box>
                    </Tooltip>
                    <Tooltip title={<TranslationComponent tag='tooltip.logTicket' />}>
                        <Box>
                            <HeaderButton icon={PlusIcon} quickCreate={true} onClick={() => { }} />{' '}
                        </Box>
                    </Tooltip>
                    <IconButton aria-label='search' onClick={() => this.handleHideSearch(!showSearch)} size='large'>
                        <Tooltip title={<TranslationComponent tag='tooltip.disabled' />}>
                            <Box>
                                <MailSharp />
                            </Box>
                        </Tooltip>
                    </IconButton>
                    <Tooltip title={<TranslationComponent tag='tooltip.notifications' />}>
                        <Box>
                            <NotificationButton user={user} />
                        </Box>
                    </Tooltip>

                    <LanguageSelector />

                    <Profile drawerIsOpen={drawerIsOpen} />
                </Toolbar>
            </AppBar>
        );
    }
}

HeaderTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    drawerIsOpen: PropTypes.bool.isRequired,
    showSearch: PropTypes.bool.isRequired,
    selectedMenuItemHasChildren: PropTypes.bool.isRequired,
    onShowSearch: PropTypes.func.isRequired,
    openViews: PropTypes.array.isRequired,
    selectedMenuItem: PropTypes.object.isRequired,
    handleDrawerToggle: PropTypes.func.isRequired,
    handleTabChange: PropTypes.func.isRequired,
    handleCloseView: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default withStyles((theme) => layoutStyles(theme), {
    withTheme: true,
})(HeaderTabs);
