const login = {
  "login.title": "SIX STAR GLOBAL",
  "login.headline": "La principale società di servizi gestiti IT al mondo.",
  "login.signInText": "Sign Into Six Star: Manage",
  "login.topCallToActionTitle": "Non fai parte di Six Star?",
  "login.topCallToActionLink": "Parliamone",
  "loginPage.input.email": "E-mail",
  "loginPage.input.password": "Password",
  "loginPage.input.mfa": "Codice MFA",
  "loginPage.rememberMe": "Ricordami",
  "loginPage.button.login": "Accedi",
  "loginPage.button.forgotPassword": "Password dimenticata?",
  "loginPage.apiMfaInProgress": "Inserisci il tuo codice MFA",
  "login.forgotPasswordText": "Password dimenticata",
  "loginPage.apiMultipleUsers": "Errore dell'account utente, contatta Six Star",
  "loginPage.apiNoPassword": "Inserisci la password",
  "loginPage.apiNoUser": "Inserisci l'indirizzo e-mail",
  "loginPage.apiInvalidTOTP": "Codice MFA non valido",
  "loginPage.apiInvalidPassword": "Password non valida",
  "login.sso.starting": "Inizio del Single Sign-On",
  "loginPage.button.normalSignIn": "Accedi con nome utente/password",
  "loginPage.text.msssoLoading": "Caricamento...",
  "loginPage.label.msssoSignIn": "Accedi con Microsoft",
  "loginPage.label.msssoSignInEmail": "Indirizzo e-mail Microsoft",
  "loginPage.text.msssoError": "Errore nell'accesso",

  "loginPage.emailPrompt": "Inserisci il tuo indirizzo e-mail qui sotto per reimpostare la tua password.",
  "loginPage.ForgotPassword.GoBack": "Torna indietro",
  "loginPage.ForgotPassword.Submit": "Invia",
  "loginPage.label.email": "E-mail",
  "loginPage.helpIcon": "Aiuto",
  "loginPage.label.fullName": "Nome completo",
  "loginPage.label.password": "Password",
  "loginPage.label.confirmPassword": "Conferma password",
  "loginPage.register.button.goback": "Torna indietro",
  "loginPage.register.button.register": "Registrati",
  "loginPage.register.button.signUp": "Accedi"

};
export default login;
