import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import withStyles from "@mui/styles/withStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, ButtonGroup } from "@mui/material";
import { useSelector } from "react-redux";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useClickOutside } from "@mantine/hooks";
import usePermissions from "containers/App/Hooks/usePermissions";

function ToolBarSubmitPlugin(props) {
  const {
    classes,
    theme,
    handleComposerCollapse,
    setCommentValues,
    submitCommentHandler,
    ticket,
    setShowNoteTypes
  } = props;
  const [editor] = useLexicalComposerContext();
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const toolbarRef = useRef(null);
  const [blockType, setBlockType] = useState("Open");
  const [icon, setIcon] = useState(["fas", "square"]);
  const [statusButton, setStatusButton] = useState("statusGrey");
  const anchorRef = React.useRef(null);
  const status = useSelector((state) => state.app.config.ticket_status);
  const [statusOptions, setStatusOptions] = useState([]);

  const colors = {
    "6b888b99-cd8d-4cf5-a255-1cca328effa2": '#FF0000',
    "115dc3ec-c6ca-4377-8d6b-d24045c345c8": '#FFC107',
    "c7dc5b89-e3cd-41ec-baf1-d7d43d079725": '#B0B0B0',
    "63ffbdc8-b443-42c6-8bc2-6e58a4011f93": '#2196F3',
    "e4829c81-314e-4634-81bc-903976e0ea99": '#000000',
  };
  // console.log("ticket", ticket);

  const permission = usePermissions({
    access: {
      type: "one",
      priority: 1,
      permissions: [
        {
          key: "CAN_EDIT_TICKET",
          value: true,
        },
      ],
    },
    // organizations: {
    //   type: "one",
    //   priority: 1,
    //   permissions: [
    //     {
    //       key: "CAN_EDIT_TICKET",
    //       value: true,
    //       organization: props.ticket.organization.id,
    //     },
    //   ],
    // },
  });


  useEffect(() => {
    setBlockType(ticket?.status_id);
  }, [ticket?.status_id]);
  useEffect(() => {
    status.map((item) => {
      //if not in the list, add it
      let userAllowed = ['resolved', 'hold', 'open']
      if (!statusOptions.find((option) => option.value === item.id)) {
        if (!permission.access) {
          if (userAllowed.includes(item.name)) {
            setStatusOptions((prevState) => [
              ...prevState,
              {
                resolved: item.resolved,
                label: item.name,
                icon: ["fas", "square"],
                value: item.id,
                iconColor: colors[item.id],
              },
            ]);
          }
        } else {
          setStatusOptions((prevState) => [
            ...prevState,
            {
              resolved: item.resolved,
              label: item.name,
              icon: ["fas", "square"],
              value: item.id,
              iconColor: colors[item.id],
            },
          ]);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (blockType === "6b888b99-cd8d-4cf5-a255-1cca328effa2") {
      setStatusButton("statusRed");
    }
    if (blockType === "115dc3ec-c6ca-4377-8d6b-d24045c345c8") {
      setStatusButton("statusAmber");
    }
    if (blockType === "c7dc5b89-e3cd-41ec-baf1-d7d43d079725") {
      setStatusButton("statusGrey");
    }
    if (blockType === "63ffbdc8-b443-42c6-8bc2-6e58a4011f93") {
      setStatusButton("statusBlue");
    }
    if (blockType === "e4829c81-314e-4634-81bc-903976e0ea99") {
      setStatusButton("statusBlack");
    }
  }, [blockType, statusButton]);

  return (
    <div
      ref={toolbarRef}
      className={`${classes["toolbar"]} ${classes["comment-toolbar-bottom"]}`}
    >
      <Button
        variant={"secondary"}
        onClick={() => {
          handleComposerCollapse();
          setCommentValues({});
          setShowNoteTypes(false);
        }}
        sx={{
          borderRadius: 2,
          boxShadow: "none!important",
          "& .MuiButton-root": {
            boxShadow: "none!important",
            "&.MuiButtonGroup-grouped:not(:last-of-type)": {
              borderRight: "1px solid white!important",
            },
          },
        }}
      >
        Cancel
      </Button>
      <ButtonGroup
        variant={statusButton}
        color={"info"}
        ref={anchorRef}
        aria-label="split button"
        size={"small"}
        sx={{
          borderRadius: 2,
          boxShadow: "none!important",
          "& .MuiButton-root": {
            boxShadow: "none!important",
            "&.MuiButtonGroup-grouped:not(:last-of-type)": {
              borderRight: "1px solid white!important",
            },
          },
        }}
      >
        <Button
          // variant={"contained"}
          // className="toolbar-item block-controls"
          // onClick={() =>
          //     setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
          // }
          aria-label="Formatting Options"
          onClick={() => {
            submitCommentHandler(editor);
          }}
        >
          {/*<span className={"icon block-type"}> <FontAwesomeIcon icon={icon} fixedWidth/> </span>*/}
          Submit as
        </Button>
        <Select
          classes={classes}
          onChange={(value, resolved) => {
            setBlockType(value);
            setCommentValues((prevState) => {
              return { ...prevState, status_id: value, resolved: resolved };
            });
          }}
          options={statusOptions}
          default={ticket?.status?.id}
        />
      </ButtonGroup>
    </div>
  );
}

function Select({ options, classes, onChange, default: defaultValue }) {
  const buttonRef = useRef(null);
  const dropDownRef = useRef(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [blockType, setBlockType] = useState(options[0]?.label ?? "");
  const [icon, setIcon] = useState(options[0]?.icon ?? ["fas", "question"]);
  const [iconColor, setIconColor] = useState(options[0]?.iconColor ?? null);
  const [defaultSet, setDefaultSet] = useState(false);
  const ref = useClickOutside(() => setShowBlockOptionsDropDown(false));
  useEffect(() => {
    if (defaultValue && !defaultSet) {
      const defaultOption = options.find(
        (option) => option.value === defaultValue
      );
      if (defaultOption) {
        setDefaultSet(true);
        setBlockType(defaultOption.label);
        setIcon(defaultOption.icon);
        setIconColor(defaultOption.iconColor);
      }
    }
  }, [defaultValue, defaultSet, options]);
  useEffect(() => {
    const buttonRefCurrent = buttonRef.current;
    const dropDown = dropDownRef.current;

    if (buttonRefCurrent !== null && dropDown !== null) {
      const { height } = buttonRefCurrent.getBoundingClientRect();
      dropDown.style.bottom = `${height + 7}px`;
      dropDown.style.right = `0px`;
    }
  }, [dropDownRef, buttonRef, showBlockOptionsDropDown]);

  const handleSelect = (option) => {
    setShowBlockOptionsDropDown(false);
    setBlockType(option.label);
    setIcon(option.icon);
    setIconColor(option.iconColor);
    onChange(option.value, option.resolved);
  };
  return (
    <>
      <Button
        ref={buttonRef}
        sx={{
          display: "flex",
          alignItems: "center",

          gap: "5px",
        }}
        onClick={() => setShowBlockOptionsDropDown(!showBlockOptionsDropDown)}
        aria-label="Formatting Options"
      >
        <strong>
          <span className="text">
            {blockType}{" "}
            <FontAwesomeIcon icon={["fas", "chevron-down"]} fixedWidth />
          </span>
        </strong>
      </Button>
      {showBlockOptionsDropDown && (
        <div className={classes["dropdown"]} ref={dropDownRef}>
          <div ref={ref}>
            {options.map((option, index) => (
              <button
                key={index}
                className="item"
                onClick={() => handleSelect(option)}
              >
                {icon && (
                  <span className={"icon "}>
                    {" "}
                    <FontAwesomeIcon
                      color={option.iconColor}
                      icon={option.icon}
                      fixedWidth
                    />{" "}
                  </span>
                )}
                <span className="text">{option.label}</span>
                {blockType === option.label && <span className="active" />}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
export default ToolBarSubmitPlugin;
