const login = {
    "login.title": "SIX STAR GLOBAL",
    "login.headline": "บริษัทบริการ IT ชั้นนำของโลก",
    "login.signInText": "เข้าสู่ระบบ Six Star: Manage",
    "login.topCallToActionTitle": "ยังไม่ได้เป็นส่วนหนึ่งของ Six Star หรือ?",
    "login.topCallToActionLink": "พูดคุยกันเถอะ",
    "loginPage.input.email": "อีเมล",
    "loginPage.input.password": "รหัสผ่าน",
    "loginPage.input.mfa": "รหัส MFA",
    "loginPage.rememberMe": "จดจำฉัน",
    "loginPage.button.login": "เข้าสู่ระบบ",
    "loginPage.button.forgotPassword": "ลืมรหัสผ่าน?",
    "loginPage.apiMfaInProgress": "กรุณาใส่รหัส MFA ของคุณ",
    "login.forgotPasswordText": "ลืมรหัสผ่าน",
    "loginPage.apiMultipleUsers": "ข้อผิดพลาดในบัญชีผู้ใช้, ติดต่อ Six Star",
    "loginPage.apiNoPassword": "กรุณาใส่รหัสผ่าน",
    "loginPage.apiNoUser": "กรุณาใส่ที่อยู่อีเมล",
    "loginPage.apiInvalidTOTP": "รหัส MFA ไม่ถูกต้อง",
    "loginPage.apiInvalidPassword": "รหัสผ่านไม่ถูกต้อง",
    "login.sso.starting": "เริ่มต้น Single Sign On",
    "loginPage.button.normalSignIn": "เข้าสู่ระบบด้วยชื่อผู้ใช้/รหัสผ่าน",
    "loginPage.text.msssoLoading": "กำลังโหลด...",
    "loginPage.label.msssoSignIn": "เข้าสู่ระบบด้วย Microsoft",
    "loginPage.label.msssoSignInEmail": "ที่อยู่อีเมลของ Microsoft",
    "loginPage.text.msssoError": "เกิดข้อผิดพลาดในการเข้าสู่ระบบ",
    
    // new translations
    "loginPage.emailPrompt": "กรุณากรอกที่อยู่อีเมลของคุณด้านล่างเพื่อรีเซ็ตรหัสผ่านของคุณ",
    "loginPage.ForgotPassword.GoBack": "ย้อนกลับ",
    "loginPage.ForgotPassword.Submit": "ส่ง",
    "loginPage.label.email": "อีเมล",
    "loginPage.helpIcon": "ช่วยเหลือ",
    "loginPage.label.fullName": "ชื่อเต็ม",
    "loginPage.label.password": "รหัสผ่าน",
    "loginPage.label.confirmPassword": "ยืนยันรหัสผ่าน",
    "loginPage.register.button.goback": "ย้อนกลับ",
    "loginPage.register.button.register": "ลงทะเบียน",
    "loginPage.register.button.signUp": "ลงทะเบียน"
};

export default login;