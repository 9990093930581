import React from 'react';
import { Box } from '@mui/material';
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";

const statusSize = {
    standard: 2.5,
    comfortable: 3,
    compact: 1.5,
};

const StatusLabel = ({ theme, value, density }) => {
    let color;
    switch (value) {
        case 'Open':
            color = theme.palette?.status.red;
            break;
        case 'New':
            color = theme.palette?.status.amber;
            break;
        case 'Closed':
            color = theme.palette?.status.grey;
            break;
        case 'Hold':
            color = theme.palette?.status.black;
            break;
        case 'Pending':
            color = theme.palette?.status.blue;
            break;
        case 'Resolved':
            color = theme.palette?.status.black;
            break;
        default:
            color = theme.palette?.status.yellow;
            break;
    }
    return (
        <>
            <Box
                sx={{
                    backgroundColor: color,
                    width: theme.spacing(statusSize[density]),
                    height: theme.spacing(statusSize[density]),
                    borderRadius: 1,
                }}
            />
            <Box
                sx={{
                    marginLeft: theme.spacing(1),
                }}
            >
                {value}
            </Box>
        </>
    )
}

export default withStyles(
    (theme) => ({
        ...layoutStyles(theme),
    }),
    { withTheme: true }
)(StatusLabel);





