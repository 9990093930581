import React from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import { Avatar, Box } from "@mui/material";
import { useSelector } from "react-redux";

const avatarSize = {
    standard: 35,
    comfortable: 45,
    compact: 20,
};

function getInitials(name) {
    //get the first and last initial of any words in the name and capitalise them only use the first and last
    return name
        ?.split(" ")
        .reduce((acc, word) => acc + word[0], "")
        .toUpperCase()
        .slice(0, 2);
}

function UserAvatar({ theme, user, density }) {
    const { onlineUsers } = useSelector((state) => state.onlineUsers);
    const size = avatarSize[density] ?? avatarSize.compact
    const online = (user?.id && onlineUsers?.some(userToFind => userToFind?.id === user.id)) ?? false;

    return (
        <>
            <Avatar
                sx={{
                    width: size,
                    height: size,
                    backgroundColor: theme.palette.tabs.secondary,
                    borderColor: online ? theme.palette.success.main : theme.palette.tabs.main,
                    borderStyle: "solid",
                    borderWidth: size * 0.1,
                    fontSize: size * 0.4,
                }}
                src={user.avatar}
            >
                {getInitials(user?.name)}
            </Avatar>
            <Box
                sx={{
                    marginLeft: 1,
                }}
            >
                {user.name ?? '-'}
            </Box>
        </>
    )
}

export default withStyles(
    (theme) => ({
        ...layoutStyles(theme),
    }),
    { withTheme: true }
)(UserAvatar);
