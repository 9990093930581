import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import { LiveHelp } from "@mui/icons-material";
import FeedbackIcon from '@mui/icons-material/Feedback';
import ErrorIcon from '@mui/icons-material/Error';
import Modal from "@mui/material/Modal";
import styles from "./styles";
import withStyles from "@mui/styles/withStyles";
import helpJSON from "./help.json";
import { useSelector } from "react-redux";
import HelpOptions from "./HelpOptions";
import Tooltip from '@mui/material/Tooltip';
import { Typography } from "@mui/material";
import Translation from "utils/translation";
import { useNavigate, useSearchParams } from "react-router-dom";

function FloatingBeta(props) {
  const { classes } = props;
  const [show, setShow] = React.useState(false);
  const [showModal, setShowModal] = React.useState(true);
  const route = window.location.pathname;
  const [content, setContent] = React.useState(null);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const hideOnRoutes = [
    '/support/table-test'
  ]
  const [showOptions, setShowOptions] = useState(false);
  let ShowOptionsTimeout;

  const handleHover = (delay = 0) => {
    clearTimeout(ShowOptionsTimeout);
    setShowOptions(true); // show options
    if (delay > 0) { // if delay is set, hide options after delay
      ShowOptionsTimeout = setTimeout(() => {
        setShowOptions(false);
      }, delay);
    }
  };

  React.useEffect(() => {
    setShowModal(false);
    if (hideOnRoutes.includes(route)) {
      setShow(false);
      setContent(helpJSON.home);
    } else {
      setShow(true);
    }
    // console.log(route);
  }, [route]);
  // let url =
  //   "https://sixstar.formstack.com/forms/feedback_form_manage_3?name-first=test&name-last=dd&email=123@123.com";
  return (
    <>
      {show && (
        <>
          {/* Button to display ooptions (i.e if the user wants to report an issue) */}
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fabContainer}
            onMouseEnter={() => handleHover()}
            onMouseLeave={() => handleHover(2500)}

          >

            <LiveHelp />
          </Fab>
          {showOptions && (
            <>
              {/* Button to send feedback */}
              {/* <Typography>
                <Tooltip title={Translation("FloatingBeta.Feedback")} placement="left">
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={classes.fabContainerItem1}
                    onClick={() => navigate('help/give-feedback')}
                    onMouseEnter={() => handleHover(0)}
                    onMouseLeave={() => handleHover(1500)}

                  >
                    <FeedbackIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              </Typography> */}

              {/* Button to report an issue  */}
              <Typography>
                <Tooltip title={Translation("FloatingBeta.ReportIssue")} placement="left">
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={classes.fabContainerItem2}
                    onClick={() => navigate('help/give-feedback')}
                    onMouseEnter={() => handleHover(0)}
                    onMouseLeave={() => handleHover(1500)}

                  >
                    <ErrorIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              </Typography>
            </>
          )}

        </>
      )}
    </>
  );
}

export default withStyles(
  (theme) => ({
    ...styles(theme),
  }),
  {
    withTheme: true,
  }
)(FloatingBeta);
