const widgets = {
  "widget.barChart.name": "Widget de Gráfico de Barras",
  "widget.barChart.description":
    "Este es un widget de prueba muy genial, importado y puede ser utilizado en otros complementos.",
  "widget.organizationTickets.name": "Widget de Tickets de la Organización",
  "widget.organizationTickets.description":
    "Este es un widget que muestra la cantidad de prioridades 1 abiertas.",
  "widget.outlookInbox.name": "Widget de Buzón de Outlook",
  "widget.outlookInbox.description":
    "Este es un widget que muestra la cantidad de correos electrónicos en tu bandeja de entrada.",
  "widget.personTickets.name": "Widget de Tickets Personales",
  "widget.personTickets.description":
    "Este es un widget que muestra la cantidad de prioridades 1 abiertas.",
  "widget.pieChart.name": "Widget de Gráfico Circular",
  "widget.pieChart.description":
    "Este es un widget de prueba muy genial, importado y puede ser utilizado en otros complementos.",
  "widget.quickLinks.name": "Widget de Enlaces Rápidos",
  "widget.quickLinks.description":
    "Este es un widget que muestra una lista de enlaces a otras páginas en el sitio.",
  "widget.social.name": "Widget de Feed Social",
  "widget.social.description":
    "Este es un widget de feed social conectado a las cuentas de redes sociales de Six Star.",
  "widget.ticketTable.name": "Widget de Tabla de Tickets",
  "widget.ticketTable.description":
    "Este es un widget de prueba muy genial, importado y puede ser utilizado en otros complementos.",
  "widget.social.feedType": "Facebook / Twitter",
  "widget.quickLinks.newRequest": "Nueva Solicitud",
  "widget.quickLinks.licenceManagement": "Gestión de Licencias",
  "widget.quickLinks.assetManagement": "Gestión de Activos",
  "widget.quickLinks.sixStarDrive": "Six Star Drive",
  "widget.quickLinks.sixStarGlobal": "Six Star Global",

    "widget.quickLinks.button.newRequest": "Nueva solicitud",
"widget.quickLinks.button.licenceManagement": "Gestión de licencias",
"widget.quickLinks.button.assetManagement": "Gestión de activos",
"widget.quickLinks.button.sixStarDrive": "Six Star Drive",

"widgets.org.tickets.p1": "Entradas Org P1",
"widgets.org.tickets.p2": "Entradas Org P2",
"widgets.org.tickets.open": "Entradas Org Abiertas",

"widgets.personal.tickets.p1": "Personales P1",
"widgets.personal.tickets.p2": "Personales P2",
"widgets.personal.tickets.open": "Personales Abiertos",
"widgets.personal.tickets.p1": "P1s Personales",
"widgets.personal.tickets.p2": "P2s Personales",
"widgets.personal.tickets.open": "Abiertos Personales",
"widget.Guage.name": "Widget de Medidor Resuelto",
"widget.Guage.description": "Este es un widget que muestra el número de tickets resueltos en la cola de hoy.",
"widget.LineGraph.name": "Widget de Gráfico de Línea",
"widget.LineGraph.description": "Este es un widget que muestra el número de tickets creados en los últimos 30 días.",
"widget.muiBarChart.name": "Widget de Gráfico de Barras",
"widget.muiBarChart.description": "Este es un widget que muestra el número de tickets, por prioridad, creados en los últimos 30 días.",
"widget.ReleaseNotes.name": "Widget de Notas de Versión",
"widget.ReleaseNotes.description": "Este es un widget que muestra las últimas notas de versión.",
"widget.DailyUpdates.name": "Widget de Actualizaciones Diarias",
"widget.DailyUpdates.description": "Este es un widget que muestra las actualizaciones diarias.",
"widget.TicketPieChart.name": "Widget de Gráfico Circular de Tickets",
"widget.TicketPieChart.description": "Este es un widget que muestra el número de tickets por estado.",
"widget.barchart.title" : "Tickets por Prioridad",
"widget.linechart.title" : "Tickets Creados en los Últimos 30 Días",
"widget.piechart.title" : "Tickets por Estado",
"widget.guage.title" : "Tickets Resueltos de la Cola de Hoy",
};
export default widgets;
