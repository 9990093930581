import React from 'react'
import { Box, TextField } from '@mui/material'
import ViewSelectorIcon from './GridToolbarViewSelectorIcon'

const GridToolbarViewSelectorSaveItem = ({ saveNewView, setAnchorEl }) => {
    const newViewRef = React.useRef()
    return (
        <Box
            sx={{
                height: 52,
                wisth: '100%',
                padding: '6px 16px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                borderTop: '1px solid rgba(0, 0, 0, 0.5)',
            }}
        >
            <TextField
                inputRef={newViewRef}
                variant='standard'
                size='small'
                placeholder={'Save New View As...'}
                // autoFocus={true}
                sx={{
                    flexGrow: 1,
                    height: 40,
                    marginTop: 2,
                }}
            />

            <ViewSelectorIcon
                icon={['fas', 'save']}
                onClick={() => {
                    saveNewView(newViewRef.current.value)
                    newViewRef.current.value = ''
                    setAnchorEl(false)
                }}
            />
        </Box>
    )
}

export default GridToolbarViewSelectorSaveItem