import { put, takeLatest, select } from "redux-saga/effects";
import {
  AUTHENTICATED,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  SIGN_IN,
  SIGN_OUT,
  CHECK_LOGIN_STATE,
  SIGN_IN_SSO,
} from "containers/App/Redux/user/constants";

// import { START_WEBSOCKET } from 'containers/App/Redux/socket/constants'
// import { socket } from './websocketSaga';

// import Cookies from 'universal-cookie';

export function* fetchResetPassword(action) {
  try {
    // here you can call your API in order to reset the password, for this demo just authenticate an user
    yield put({
      type: AUTHENTICATED,
      user: {
        name: "Jason Smyth",
        email: action.payload.email,
      },
    });
  } catch (e) {
    yield put({ type: RESET_PASSWORD_FAILED, message: e.message });
  }
}

export function* resetPassword() {
  yield takeLatest(RESET_PASSWORD, fetchResetPassword);
}

export function* fetchSignIn(action) {
  // console.log(action);
}

export function clearSignOut() {
  localStorage.clear();
  window.localStorage.removeItem("persist:manage");
  window.localStorage.removeItem("persist:menu");
  //TODO:SOCKET
  // socket.disconnect()
  // const cookies = new Cookies();
  // try {
  //   cookies.set(process.env.REACT_APP_SSG_SECURITY_CODE, null, { path: '/', secure: true, sameSite: 'strict', domain: window.location.hostname, maxAge: 0 });
  //   let siteURl = window.location.href
  //   window.location.origin = siteURl;
  // } catch (e) {
  //   console.log(e)
  // }
}

export function* checkLoginStateSage() {
  const globalState = yield select();
  let userIsAuthenticated = globalState.user.userIsAuthenticated;
  try {
    let x = localStorage.getItem("persist:manage");
    x = JSON.parse(x);
    x = JSON.parse(x.user);

    if (x.userIsAuthenticated === false && userIsAuthenticated === true) {
      let currentLocation = window.location;
      window.location = currentLocation;
    } else if (
      x.userIsAuthenticated === true &&
      userIsAuthenticated === false
    ) {
      let currentLocation = window.location;
      window.location = currentLocation;
    }
  } catch {
    let currentLocation = window.location;
    window.location = currentLocation;
  }
}
export function* checkLoginState() {
  yield takeLatest(CHECK_LOGIN_STATE, checkLoginStateSage);
}

export function* signOut() {
  yield takeLatest(SIGN_OUT, clearSignOut);
}

export function* signIn() {
  yield takeLatest(AUTHENTICATED, fetchSignIn);
  // yield takeLatest(AUTHENTICATED, fetchSignIn);
}

const yields = [signOut, signIn, resetPassword, checkLoginState];

export default yields;
