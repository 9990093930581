import React, { useEffect, useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { Box, flexbox } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "components/MuiOverride/TextField";
import {
  Typography,
  // TextField,
  Button
} from "@mui/material";
import DropdownSelect from "components/Global/DropDownSelect";
import { IconButton } from "@mui/material";
import Virtualize from "components/MuiOverride/Autocomplete";
function DataTableFilterLoop(props) {
  const {
    handleChange,
    theme,
    filterOptions,
    currentValues,
    classes,
    setCurrentValues,
    setValue,
    defaultValue
  } = props;


  return (
    <>
      {filterOptions.map((filter, index) => {
        // console.log(currentValues[filter.field_parent]);
        return (
          <React.Fragment key={index}>
            {filter.type === "text" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  marginBottom={3}
                  width={"100%"}
                  className={classes.filterItems}
                >
                  <TextField
                    metadata="filter"
                    id={`${filter.property}-outlined-basic`}
                    label={filter.property}
                    size={"small"}
                    fullWidth
                    value={
                      currentValues[filter.field_parent]
                        ? currentValues[filter.field_parent]
                        : ""
                    }
                    onChange={(event) => {
                      setCurrentValues((prevState) => ({
                        ...prevState,
                        [filter.field_parent]: event.target.value,
                      }));

                      setValue({
                        table: filter?.table ?? null,
                        value:
                          event.target.value.trim().length > 0
                            ? event.target.value
                            : null,
                        field: filter?.field ?? null,
                        field_parent: filter?.field_parent ?? null,
                      });
                    }}
                  />
                </Box>
              </Box>
            )}

            {filter.type === "dropdown" && (
              <Box
                marginBottom={3}
                width={"100%"}
                className={classes.filterItems}
              >
                <DropdownSelect
                  options={filter.options}
                  label={filter.property}
                  value={currentValues[filter.field_parent]}
                  onChange={(event, value) =>
                    setValue({
                      table: filter?.table ?? null,
                      value: value,
                      field: filter?.field ?? null,
                      field_parent: filter?.field_parent ?? null,
                    })
                  }
                />
              </Box>
            )}
            {filter.type === "autocomplete" && (
              <Box
                marginBottom={3}
                width={"100%"}
                className={classes.filterItems}
              >
                <Virtualize
                  query={filter.query}
                  field={filter.field}
                  endpoint={filter.endpoint}
                  label={filter.label}
                  group={filter.group ?? ""}
                  capitalize={filter.capitalize ?? false}
                  defaultValue={
                    (() => {
                      let returnArray = []
                      // console.log(currentValues)
                      // console.log(filterOptions)
                      if (currentValues.length > 0) {
                        currentValues.map((value) => {
                          // console.log(value)
                          if (value.field === filter.queryField) {
                            // console.log('found match:',value)
                            if (value.value !== null && value.value !== undefined && value.value !== ''){
                              returnArray.push(value.value);
                            }

                          }

                        })
                      }
                      return returnArray;
                    })()
                  }
                  callback={(value) => {
                    setValue(value, filter.queryField);
                  }}
                />
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

export default DataTableFilterLoop;
