const widgets = {
  "title.editWidget.transparent": "Transparent",
  "title.editWidget.opaque": "Opaque",
  "title.editWidget.edit": "Edit",
  "title.editWidget.export": "Export",
  "title.editWidget.delete": "Delete",
  "form.widget.import.field": "Import String",
  "title.widget.options": "Options",
  "title.widget.import.widget": "Import Widget",
  "title.widget.import.layout": "Import Widget Layout",
  "button.widget.add": "add",
  "button.widget.save": "save",
  "button.widget.edit": "edit",
  "button.widget.cancel": "cancel",
  "button.widget.move": "move/resize",
  "button.widget.export": "export",
  "button.widget.import": "import",
  "blank.widget.name": "New Shiny Blank Widget",
  "blank.widget.description": "A blank widget what more do you want?",
  "coda.widget.name": "Coda Embed Document",
  "coda.widget.description": "Coda Embed Document",
  "notification.widget.export": "Export copied to clipboard",
  "notification.widget.exportFailed": "Export failed to copy to clipboard",
  "links.widget.name": "Links",
  "links.widget.description":
    "This widget can provide a list of links that a custom can click and be taken to",
  "links.button.delete": "Delete",
  "links.dropdown.newWindow": "New Window",
  "links.dropdown.sameWindow": "Same Window",
  "links.button.addNew": "Add New Link",

  "widget.quickLinks.button.newRequest": "Nouvelle Demande",
"widget.quickLinks.button.licenceManagement": "Gestion des Licences",
"widget.quickLinks.button.assetManagement": "Gestion des Actifs",
"widget.quickLinks.button.sixStarDrive": "Six Star Drive",

"widgets.org.tickets.p1": "Billets Org P1",
"widgets.org.tickets.p2": "Billets Org P2",
"widgets.org.tickets.open": "Billets Org Ouverts",

"widgets.personal.tickets.p1": "Personnel P1",
"widgets.personal.tickets.p2": "Personnel P2",
"widgets.personal.tickets.open": "Personnel Ouvert",
"widget.barChart.name": "Widget de graphique à barres",
"widget.barChart.description":
  "Widget de graphique à barres qui affiche le nombre de tickets prioritaires ouverts.",
"widget.organizationTickets.name": "Widget de tickets de l'organisation",
"widget.organizationTickets.description":
  "Il s'agit d'un widget qui affiche le nombre de priorités 1 ouvertes.",
"widget.outlookInbox.name": "Widget de boîte de réception Outlook",
"widget.outlookInbox.description":
  "Il s'agit d'un widget qui affiche le nombre d'e-mails dans votre boîte de réception.",
"widget.personTickets.name": "Widget de tickets personnels",
"widget.personTickets.description":
  "Il s'agit d'un widget qui affiche le nombre de priorités 1 ouvertes.",
"widget.pieChart.name": "Widget de graphique circulaire",
"widget.pieChart.description":
  "Widget de graphique circulaire qui affiche le nombre de tickets ouverts pour un contact.",
"widget.quickLinks.name": "Widget de liens rapides",
"widget.quickLinks.description":
  "Il s'agit d'un widget qui affiche une liste de liens vers d'autres pages sur le site.",
"widget.social.name": "Widget de flux social",
"widget.social.description":
  "Il s'agit d'un widget de flux social connecté aux comptes de médias sociaux de Six Star.",
"widget.ticketTable.name": "Widget de tableau de tickets",
"widget.ticketTable.description":
  "Widget qui affiche un tableau des tickets.",
"widget.social.feedType": "Facebook / Twitter",
"widget.quickLinks.newRequest": "Nouvelle demande",
"widget.quickLinks.licenceManagement": "Gestion des licences",
"widget.quickLinks.assetManagement": "Gestion des actifs",
"widget.quickLinks.sixStarDrive": "Six Star Drive",
"widget.quickLinks.sixStarGlobal": "Six Star Global",
"widgets.personal.tickets.p1": "P1 Personnels",
"widgets.personal.tickets.p2": "P2 Personnels",
"widgets.personal.tickets.open": "Ouverts Personnels",
"widget.Guage.name": "Widget de Jauge Résolue",
"widget.Guage.description": "Ceci est un widget qui affiche le nombre de tickets résolus de la file d'attente du jour.",
"widget.LineGraph.name": "Widget de Graphique Linéaire",
"widget.LineGraph.description": "Ceci est un widget qui affiche le nombre de tickets créés au cours des 30 derniers jours.",
"widget.muiBarChart.name": "Widget de Graphique à Barres",
"widget.muiBarChart.description": "Ceci est un widget qui affiche le nombre de tickets, par priorité, créés au cours des 30 derniers jours.",
"widget.ReleaseNotes.name": "Widget de Notes de Version",
"widget.ReleaseNotes.description": "Ceci est un widget qui affiche les dernières notes de version.",
"widget.DailyUpdates.name": "Widget de Mises à Jour Quotidiennes",
"widget.DailyUpdates.description": "Ceci est un widget qui affiche les mises à jour quotidiennes.",
"widget.TicketPieChart.name": "Widget de Graphique Circulaire de Tickets",
"widget.TicketPieChart.description": "Ceci est un widget qui affiche le nombre de tickets par statut.",
"widget.barchart.title" : "Tickets par Priorité",
"widget.linechart.title" : "Tickets Créés au Cours des 30 Derniers Jours",
"widget.piechart.title" : "Tickets par Statut",
"widget.guage.title" : "Tickets Résolus de la File d'Attente du Jour",
};
export default widgets;
