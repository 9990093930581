// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
// import 'file-loader?name=[name].[ext]./favicon.ico';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
// import { securityCode } from './securityCode.js';
import configureStore from './configureStore';
import SocketClient from 'middleware/websocket/SocketClient';
import MicrosoftClient from 'middleware/microsoft/MicrosoftClient';
// Import root app
import App from 'containers/App';
import LanguageProvider from 'utils/language';
import { translationMessages } from './i18n';
import 'sanitize.css/sanitize.css';
import './global-styles';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

// Import app saga
// Create redux store with history
const MOUNT_NODE = document.getElementById('app');
const socketClient = new SocketClient();
const microsoftClient = new MicrosoftClient();


// this stops console logs on the production build
if (process.env.REACT_APP_BUILD !== "DEV") {
  let sendConsoleLog = console.log
  console.log = function (d) {
    if (process.env.REACT_APP_ENABLE_CONSOLE_LOG === "true") {
      sendConsoleLog(d)
    }
  };
}

console.info(`Current Websocket server is ${process.env.REACT_APP_SOCKET_SERVER}`);
console.info(`Current Websocket API server is ${process.env.REACT_APP_INTERNAL_API}`);
console.info(`Current API server is ${process.env.REACT_APP_API_SERVER}`);

let { store, history, persistor } = configureStore(socketClient, microsoftClient);
// let apolloClient = apolloCreate(store)
socketClient.connect(store);
const render = (messages) => {
  ReactDOM.render(
    <GoogleOAuthProvider clientId="203024769027-2dp8gqdp9h31vit2j5uacpkb3e7ca6gr.apps.googleusercontent.com">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LanguageProvider messages={messages}>
            <Router history={history}>
              {/* <StrictMode> */}
              <App socketClient={socketClient} history={history} store={store} />
              {/* </StrictMode> */}
              {/*</ApolloProvider>*/}
            </Router>
          </LanguageProvider>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
    ,
    MOUNT_NODE,
  );
};

const consoleLogClear = (firstRun = false) => {
  if (!firstRun) console.clear();
  let url = window.location.origin + '/desktopNotificationIcon.png';
  let backgroundImage = ['background-image: url(' + url + ')', 'background-repeat: no-repeat'].join(' ;');
  console.info(
    '%c   Six Star Global',
    'font-weight: bold; font-size: 40px;color: purple; text-shadow: 1px 0px 1px #CCCCCC, 0px 1px 1px #EEEEEE, 2px 1px 1px #CCCCCC, 1px 2px 1px #EEEEEE, 3px 2px 1px #CCCCCC, 2px 3px 1px #EEEEEE, 4px 3px 1px #CCCCCC, 3px 4px 1px #EEEEEE, 5px 4px 1px #CCCCCC, 4px 5px 1px #EEEEEE, 6px 5px 1px #CCCCCC, 5px 6px 1px #EEEEEE, 7px 6px 1px #CCCCCC, 2px 2px 4px rgba(206,89,55,0);' +
    backgroundImage,
  );
};
if (module.hot) {
  // Hot re-loadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  // console.log("Hot reloading enabled")
  // consoleLogClear(true);
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
  module.hot.addStatusHandler((status) => {
    if (status === 'prepare') {
      // consoleLogClear();
    }
  });
}
// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}
// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
