import { useState, createContext, useEffect } from "react";
import useIndexedDB from "./useIndexedDB";
import { useLazyQuery, gql } from "@apollo/client";
import { useSelector } from "react-redux";

const LOGGED_IN = gql`
  query GET_SITE_DATA_LOGGED_IN($person_id: uuid!) {
    ticket_priority{
        id
        name
    }
    team{
        id
        name
    }
    ticket_status{
        id
        name
    }
    datatable_view(where: {person_id: {_eq: $person_id}}) {
        created_at
        updated_at
        datatable
        default_flag
        global
        name
        id
        state
        hidden
    }
  }
`;

const LOGGED_IN_AGENT = gql`
  query GET_SITE_DATA_LOGGED_IN_AGENT($person_id: uuid!) {
    ticket_priority{
        id
        name
    }
    team{
        id
        name
    }
    ticket_status{
        id
        name
    }
    datatable_view(where: {_or: [
         {global: {_eq: true}},
            {person_id: {_eq: $person_id}}
        ]},
        order_by: {name: asc}) {
        created_at
        updated_at
        datatable
        default_flag
        global
        name
        id
        state
        hidden
    }
  }
`;


const tableToMake = [
    {
        name: 'teams',
        tableName: 'team',
        formatting: [
            {
                field: "tableName",
                func: (item) => item.name?.split(':::').pop()
            },
        ]
    },
    {
        name: 'status',
        tableName: 'ticket_status',
        formatting: [
            {
                field: "tableName",
                func: (item) => item.name.charAt(0).toUpperCase() + item.name.slice(1)
            },
        ]
    },
    {
        name: 'priority',
        tableName: 'ticket_priority'
    },
    {
        name: 'views',
        tableName: 'datatable_view'
    }
]


const SiteData = createContext()

const SiteDataProvider = ({ children }) => {
    const { user, userIsLoggingIn } = useSelector(state => state.user)
    const { id: person_id, roles } = user
    const is_agent = roles?.includes('agent')
    const QUERY = is_agent ? LOGGED_IN_AGENT : LOGGED_IN

    const [DBReady, setDBReady] = useState(false)
    const [initDataLoaded, setInitDataLoaded] = useState(false)

    const tables = tableToMake.map(table => table.name)

    const [tablesState, setTablesState] = useState(tables.reduce((acc, table) => {
        acc[table] = []
        return acc
    }, {}))

    const { getAllData, dbIsInitialized, updateData, deleteData, deleteDatabase } = useIndexedDB('manage', tables, 1);

    const [getNewSiteData, { data: newSiteData, error: newSiteDataError }] = useLazyQuery(QUERY, {
        variables: {
            person_id
        }
    })

    useEffect(() => {
        if (Object.keys(tablesState).length > 0) {
            setInitDataLoaded(true)
        }
    }, [tablesState])

    useEffect(() => {

    }, [initDataLoaded])

    useEffect(() => {
    }, [newSiteData])

    useEffect(() => {
        if (newSiteDataError) {
            console.log(newSiteDataError)
            setInitDataLoaded(true)
        }
    }, [newSiteDataError])

    useEffect(() => {
        const dbStatus = dbIsInitialized()
        if (dbStatus !== DBReady) {
            setDBReady(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbIsInitialized]);

    const refreshData = async () => {
        try {

            const data = await getAllData();
            // console.log({ data })

            let needNewData = false

            Object.keys(data).forEach(async (key) => {
                if (data[key].length === 0) {
                    needNewData = true
                }
            })

            if (needNewData) {
                await getNewSiteData()
            } else {
                setTablesState(data)
                setInitDataLoaded(true)
            }


        } catch (error) {
            console.log(error)
            setInitDataLoaded(true)
        }
    }


    useEffect(() => {

        if (userIsLoggingIn) {
            setInitDataLoaded(false)
        }

        if (DBReady && userIsLoggingIn) {
            refreshData();
        }
        if (!userIsLoggingIn) {

            setInitDataLoaded(true)
        }


        // if (!userIsLoggingIn) {
        //     console.log('user is not logging in')
        //     if (initDataLoaded) {
        //         {
        //             setInitDataLoaded(false)
        //         }
        //     }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [DBReady, userIsLoggingIn])


    useEffect(() => {
        if (newSiteData) {

            const fetchData = async () => {
                const data = await getAllData();
                setTablesState(data)
            }


            const addNewData = async () => {
                await Promise.all(Object.keys(newSiteData).map(async (key) => {
                    const internalData = tableToMake.find(table => table.tableName === key);

                    if (internalData.formatting) {
                        await Promise.all(newSiteData[key].map(async (item) => {
                            let newItem = { ...item };

                            internalData.formatting.forEach(format => {
                                return newItem[format.field] = format.func(item);
                            });

                            await updateData(internalData.name, {
                                ...newItem
                            });
                        }));
                    } else {
                        await Promise.all(newSiteData[key].map(async (item) => {
                            await updateData(internalData.name, item);
                        }));
                    }
                })).then(() => {
                    fetchData();
                })
            }
            addNewData();


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newSiteData])



    return (
        <SiteData.Provider value={{
            data: tablesState,
            DBReady: initDataLoaded,
            functions: {
                updateData,
                deleteData,
                deleteDatabase,
                setTablesState,
                refreshData
            }
        }}>
            {children}
        </SiteData.Provider>
    )
}

export { SiteData, SiteDataProvider }