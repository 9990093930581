const styles = theme => ({
    iframeContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGlow: 1,
        width: '100%',
    },
    iframeStyle: {
        borderRadius: '20px',
        width: '100%',
        height: 'calc(100vh - 110px)',
    }
});

export default styles;
