import io from "socket.io-client";
import mssso from "./listeners/mssso";
import mail from "./listeners/mail";
import tokens from "./listeners/tokens";
import { socketStatus } from "slices/socket";
import updateSessions from "./listeners/updateSessions";
import tableSubscription from "./listeners/tableSubscription";

export default class socketAPI {
  socket;

  connect(store) {
    const socketServerURL = process.env.REACT_APP_SOCKET_SERVER;

    this.socket = io(socketServerURL, {
      autoConnect: true,
      reconnection: true,
    });

    return new Promise((resolve, reject) => {
      this.socket.on("connect", () => {
        if (process.env.REACT_APP_WEBSOCKET_DEV === "true") {
          // console.log("Socket Connected");
          // console.log(this.socket.id);
          // console.log(this.socket);
        }
        store.dispatch(socketStatus(true));
        mssso(this.socket, store);
        tokens(this.socket, store);
        mail(this.socket);
        updateSessions(this.socket, store);
        tableSubscription(this.socket, store.dispatch);

        resolve();
      });
      this.socket.on("disconnect", () => {
        if (process.env.REACT_APP_WEBSOCKET_DEV === "true") {
          // console.log("Socket Has Disconnected");
        }
        store.dispatch(socketStatus(false));
        this.socket.connect(store);
        resolve();
      });
      this.socket.io.on("reconnect", () => {
        if (process.env.REACT_APP_WEBSOCKET_DEV === "true") {
          // console.log("Socket Has reconnected");
        }
        store.dispatch(socketStatus(false));
        resolve();
      });
      this.socket.io.on("reconnect_attempt", () => {
        if (process.env.REACT_APP_WEBSOCKET_DEV === "true") {
          // console.log("Attempting to reconnect to socket server.");
        }
      });
      this.socket.on("connect_error", (error) => {
        store.dispatch(socketStatus(false));
        console.log(error);
        reject(error);
      });

      if (process.env.REACT_APP_WEBSOCKET_DEV === "true") {
        this.socket.onAny((event, ...args) => {
          // console.log("Listening event: ", event, args);
        });
      }
    });
  }

  disconnect() {
    return new Promise((resolve) => {
      this.socket.disconnect(() => {
        if (process.env.REACT_APP_WEBSOCKET_DEV === "true") {
          // console.log("Socket Has Disconnected");
        }
        this.socket = null;
        resolve();
      });
    });
  }

  emit(event, data) {
    if (process.env.REACT_APP_WEBSOCKET_DEV === "true") {
      // console.log("Emitting event: ", event, data);
    }
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");

      return this.socket.emit(event, data, (response) => {
        // Response is the optional callback that you can use with socket.io in every request. See 1 above.
        if (response?.error) {
          console.error(response.error);
          return reject(response?.message ?? 'error');
        }

        return resolve(response);
      });
    });
  }

  on(event, fun) {
    // No promise is needed here, but we're expecting one in the middleware.
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");
      this.socket.on(event, fun);
      resolve();
    });
  }

  getSocket() {
    return new Promise((resolve, reject) => {
      resolve(this.socket);
    });
  }

  newGetSocket() {
    return this.socket;
  }
}
