import React, { useState, useEffect, useRef } from "react";
import { useScrollIntoView } from "@mantine/hooks";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import PageBase from "components/PageBase";
import { Box } from "@mui/system";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import useTranslation from "containers/App/Hooks/useTranslation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles";
import { useTheme } from "@mui/system";
import CastHeader from "images/cast/CastHeader.png";
import plus from "images/tv/plus.png";
import { Button } from "@mui/material";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Cast } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import castStill1 from "images/cast/castStill1.png";

const SixStarCastPage = ({ classes }) => {
    const theme = useTheme();
    const { translate } = useTranslation();
    const [loading, setLoading] = useState(true);
    const isPhoneSize = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        let loadingTimer = setTimeout(() => setLoading(false), 300);
        return () => {
            clearTimeout(loadingTimer);
        };
    });

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <>
            <PageBase minHeight={500} loading={loading} noPadding={true}>
                <Box sx={{ background: '#3A8BCA' }}>
                    <img

                        src={CastHeader}
                        alt={"Six Star Voice"}
                        style={{
                            width: "67%",
                            margin: "auto",
                            display: "block",
                        }}
                    />

                    <Box sx={{ background: "white!important", height: 'auto', }}>
                        <Typography
                            variant={"h3"}
                            align={"center"}
                            sx={{
                                fontWeight: 500,
                                display: "block",
                                paddingTop: theme.spacing(6)

                            }}
                        >
                            {translate("sixStarCastPage.header")}
                        </Typography>
                        <Box
                            align={"center"}
                            sx={{
                                paddingTop: theme.spacing(4),
                                display: "block",
                                paddingX: 4
                            }}>
                            <Typography>{translate("sixStarCastPage.low-Cost")} </Typography>
                        </Box>

                        <Box  spacing={5} style={{ alignItems: 'center',justifyContent:'center', paddingTop: theme.spacing(4), height: 'auto', display: 'flex', flexDirection: isPhoneSize ? "column" : "row", }}>
                            <Box sx={{padding: 2, width: isPhoneSize ? 'auto': '30%'}}>

                                <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "link"]}
                                    color="white"
                                    style={{
                                        fontSize: "5rem",
                                        backgroundColor: "#4FCF83",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(-2),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" fontWeight={500} style={{marginTop: 20}}>{translate("sixStarCastPage.oneStepAuthentication")}</Typography>
                                <Typography align="center" style={{ }}>{translate("sixStarCastPage.guestCan")}</Typography>
                            </Box>
                            <Box sx={{padding: 2, width: isPhoneSize ? 'auto': '30%'}}>
                                <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "mobile"]}
                                    color="white"
                                    style={{
                                        fontSize: "5rem",
                                        backgroundColor: "#87CAEE",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                        top: 0
                                    }}
                                />

                                <Typography align="center" fontWeight={500}>{translate("sixStarCastPage.streamCastableApp")}</Typography>
                                <Typography align="center" style={{  }}>{translate("sixStarCastPage.netflix")}</Typography>
                            </Box>

                            <Box sx={{padding: 2, width: isPhoneSize ? 'auto': '30%'}}>
                                <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "cloud"]}
                                    color="white"
                                    style={{
                                        fontSize: "7rem",
                                        backgroundColor: "#DAD9EC",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" fontWeight={500}>{translate("sixStarCastPage.CloudManaged")}</Typography>

                                <Typography align="center" style={{ }}>{translate("sixStarCastPage.easilyIntegrated")}</Typography>
                            </Box>



                        </Box>

                        <Box
                            sx={{ background: "#3A8BCA", marginTop: theme.spacing(4), height: 'auto', paddingBottom: 2 }}
                        >
                            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                            <iframe
                            src="https://player.vimeo.com/video/446412089?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            title="Six Star Cast"
                        ></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>

                        </Box>

                        <Box
                            sx={{ background: "white", marginTop: theme.spacing(4), height: 'auto', paddingBottom: 8 }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={isPhoneSize ? 12 : 6}>



                                    <Box sx={{ width: '100%', align: 'center', paddingX: 8 }}>
                                        <Typography align={"center"} variant="h4" sx={{ marginTop: 16, fontWeight: 500, paddingBottom: 6  }}>{translate("sixStarTVPage.fullyCustomisable")}</Typography>
                                        <Typography align={"center"}  sx={{}}>{translate("sixStarCastPage.p1")}</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ marginTop: 8 }}>
                                        <img

                                            src={castStill1}
                                            alt={"Six Star TV"}
                                            style={{
                                                width: "90%",
                                                margin: "auto",
                                                display: "block",
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>



                        </Box>

                    </Box>

                    <Box sx={{ background: "#3A8BCA", height: 'auto', paddingBottom: 4 }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop:4 }}>
                                <Button
                                    variant="contained"
                                    color="white"
                                    sx={{ borderRadius: 8, width: isPhoneSize ? '60%' : '30%', margin: 1, }}
                                >
                                    {translate("sixStar.GetInTouch")}
                                </Button>
                            </Grid>
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start', paddingTop:4 }}>
                                <Button
                                    variant="contained"
                                    color="white"
                                    sx={{ borderRadius: 8, width: isPhoneSize ? '60%' : '30%', margin: 1 }}
                                    onClick={handleScrollToTop}
                                >
                                    
                                    {translate("sixStar.BackToTop")}
                                    <FontAwesomeIcon icon={faArrowUp} style={{ marginLeft: 8 }} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>


            </PageBase>

        </>
    );

}

export default SixStarCastPage;