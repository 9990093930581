import React, { useEffect, useRef, useState } from "react";
import styles from "./styles";
import withStyles from "@mui/styles/withStyles";
import TableGeneric from "components/TableGeneric";
import { EnumType } from 'json-to-graphql-query';
import { DateTime } from 'luxon';

function AuditLog({ theme, classes, personID = null }) {
    const [currentPersonID, setCurrentPersonID] = useState(personID);

    const query = {
        query: {
            ticket_audit_30: {
                __args: {
                    order_by: {
                        created_at: new EnumType("desc")
                    },
                    where: {
                        person_id: {
                            _eq: personID
                        },
                        diffs: {
                            _cast: {
                                String: {
                                    _niregex: new EnumType('"\\"old\\": {\\"updated_at\\""')
                                }
                            }
                        }
                    }
                },
                id: true,
                ticket: {
                    id: true,
                    alias: true,
                },
                diffs: true,
                created_at: true,
                person: {
                    id: true,
                    alias: true,
                    name: true,
                    email_address: true,
                }
            }
        },

    }

    const searchQuery = {
        default: [
            { key: 'person.name', operator: '_ilike' },
            { key: 'person.email_address', operator: '_ilike' },
        ],
        numerator: [
            { key: 'ticket.alias', operator: '_ilike' },
        ]
    }


    const columns = [
        {
            field: "ticket.alias",
            headerName: "Ticket Alias",
            minWidth: 100,
            valueGetter: (params) => params.row.ticket?.alias || "N/A"
        },
        {
            field: "diffs",
            headerName: "Changes",
            minWidth: 100,
            flex: true,
            valueFormatter: (params) => {
                // example of params.value           
                // "diffs": {
                //     "new": {
                //         "updated_at": "2024-07-02T10:38:58+00:00"
                //     },
                //     "old": {
                //         "updated_at": "2024-07-02T09:13:05+00:00"
                //     }
                // },
                // if old is an empty object then display new ticket created
                // otherwise just list what was changed, ignoring updated_at and ignoring the values
                let result = "New Ticket Created";
                if (params?.value?.old && Object.keys(params?.value?.old).length > 0) {
                    result = Object.keys(params?.value?.old).filter(key => key !== 'updated_at').join(", ");
                }
                return result;
            },
        },
        {
            field: "created_at",
            headerName: "Date",
            minWidth: 100,
            valueFormatter: (params) => DateTime.fromISO(params?.value).toRelative(),
        },
        {
            field: "person.name",
            headerName: "Person Name",
            minWidth: 100,
            valueGetter: (params) => params.row.person?.name || "N/A"
        },
        {
            field: "person.email_address",
            headerName: "Person Email",
            minWidth: 100,
            valueGetter: (params) => params.row.person?.email_address || "N/A"
        }
    ]

 
    return (
        <>
            {personID && (
                <TableGeneric
                    query={query}
                    searchQuery={searchQuery}
                    tableName={"ticket_audit_30"}
                    columns={columns}
                    style={{
                        height: "calc(100vh - 96px)",
                        width: "100%",
                    }}
                    personID={personID}
                />
            )}
        </>
    );
}

export default withStyles(
    (theme) => ({
        ...styles(theme),
    }),
    { withTheme: true }
)(AuditLog);
