import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Translation from 'utils/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ExpiringLicences from 'containers/Pages/CompanyContact/Tabs/OverviewTab/ExpiringLicences';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faTableCells } from '@fortawesome/free-solid-svg-icons';
import graphQLQueries from "graphql/person";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from 'react';
import Avatar from "@mui/material/Avatar";
import { getInitials } from "utils/helperFunctions";
import classNames from "classnames";
//import SidePanel from './SidePanel';
import { useNavigate } from 'react-router-dom';
import TicketCommentContainer from 'containers/Pages/TicketViewPage/containers/TicketCommentContainer';
import PersonOpenTickets from 'containers/Pages/PersonContact/Tabs/personOverview/Components/PersonOpenTickets';
import TicketMessage from 'containers/Pages/TicketViewPage/components/TicketMessage';



function PersonOverview(props) {
  const { user, Edituser, userID, comments } = props;

  // getting the last ticket and commemnt 
  const organization = user?.person[0].organization;
  const orgID = user?.person[0].organization?.id;
  const lastTicket = organization?.tickets[organization?.tickets.length - 1];
  const lastComment = lastTicket?.comments[lastTicket?.comments.length - 1];
  const lastCommentCreatedAt = lastComment?.created_at;

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/admin/company/${orgID}`);
  }

  useEffect(() => {
    console.log('comments', comments);
  }, [comments]);

  const urlEnd = lastTicket?.alias;
  const url = `/support/tickets/${urlEnd}`;
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const [htmlStr, setHtmlStr] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      setHtmlStr(removeHtmlTags(lastComment?.source.body));
    }, 1000);

    return () => clearTimeout(timer);
  }, [lastComment]);

  function removeHtmlTags(html) {
    return html ? html.replace(/<[^>]*>?/gm, '') : '';
  }

  return (
    <Box
      sx={{

        overflowY: 'scroll',
        marginTop: 2,

      }}>
      {/* Summary box below */}
      <Box
        sx={{
          backgroundColor: "white !important",
          padding: 2,
          borderRadius: 2,
          boxShadow: 2,
          height: 'auto',
        }}
      >

        <Grid container spacing={2}>
          <Grid item xs={3}>

            <Typography variant="h6" align="center">
              {Translation("companyContactPage.tabs.overview.lastActivity")}
            </Typography>
            <Typography padding={1} align="center">
              {comments?.ticket_comment?.length >= 1 ? new Date(comments?.ticket_comment?.[0]?.created_at).toLocaleString('en-GB') : "No activity"}
            </Typography>
          </Grid>
          <Grid item xs={3}>

            <Typography variant="h6" align="center">
              {/* {Translation("companyContactPage.tabs.overview.customerStatus")} */}
              Account Status
            </Typography>
            <Typography padding={1} align="center">
              {user?.person[0].account?.active ? "Active" : "Inactive"}
            </Typography>
          </Grid>
          <Grid item xs={3}>

            <Typography variant="h6" align="center">
              {Translation("companyContactPage.tabs.overview.userCreated")}
            </Typography>
            <Typography padding={1} align="center">
              {user?.person[0].account?.created_at.split('T')[0]}
            </Typography>
          </Grid>
          <Grid item xs={3}>

            <Typography variant="h6" align="center">
              {Translation("companyContactPage.tabs.overview.company")}
            </Typography>
            <Typography padding={1} align="center" sx={{ color: '#3F76B6', cursor: 'pointer' }} onClick={handleNavigate}>
              {user?.person[0].organization?.name}
            </Typography>
          </Grid>
        </Grid>




      </Box>
      {/* Activities box below */}
      {/* <Box
  sx={{
    backgroundColor: "white !important",
    padding: 4,
    borderRadius: 2,
    boxShadow: 2,
    marginTop: 2,
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
  }}
>
  <FontAwesomeIcon icon={faServer} style={{ marginRight: 10,  }} />
  <Typography variant="h6" >{Translation("companyContactPage.tabs.activities")}</Typography>
  <FontAwesomeIcon icon={faCircleArrowRight} style={{ marginLeft: 10, color: "#6196c7", cursor: "pointer" }} onClick={() => {}}/>

  <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
    <FontAwesomeIcon icon={faChevronUp} rotation={90} style={{ color: "#000000", cursor: "pointer" }} onClick={() => {}}/>
  </Box>
</Box>   */}

      {/* Expiring licences box below */}
      {/* <Box
  sx={{
    backgroundColor: "white !important",
    padding: 4,
    borderRadius: 2,
    boxShadow: 2,
    marginTop: 2,
    //  display: 'flex',
    alignItems: 'center',
    height: 'auto',
  }}>
  <Box
    sx={{ display: 'flex', alignItems: 'center' }}>
    <Typography variant="h6" >{Translation("companyContactPage.tabs.overview.expiringLicences")}</Typography>
    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: "#e2434d", marginLeft: 10 }} />
  </Box>
  <ExpiringLicences 
  user={user}/>
</Box> */}

      {/* open tickets box below */}
      <Box
        sx={{
          backgroundColor: "white !important",
          paddingTop: 4,
          borderRadius: 2,
          boxShadow: 2,
          marginTop: 2,
          //  display: 'flex',
          alignItems: 'center',
          height: '500px',
        }}>
        {/* <Typography variant="h6" >{Translation("companyContactPage.tabs.overview.opemTickets")}</Typography> */}
        {showComponent && <PersonOpenTickets user={user} Edituser={Edituser} userID={userID} />}

      </Box>
      {/* Activities box below */}

      {/* mapping through the comments array and rendering a ticketmessage */}
      {comments?.ticket_comment?.map((comment, index) => {
        return <TicketMessage
          message={comment}
          key={index}
          // title={'ticket note type will go here'} 
          commentID={comment.id}
          ticket={lastTicket}
        />;
      }
      )}

    </Box>
  )
}

export default PersonOverview;