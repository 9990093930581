import React from 'react';
import Box from '@mui/material/Box';
import SidePanelItem from "containers/Layout/ThreePanelView/components/SidePanelItem";
import Translation from 'utils/translation';
import ContactsModal from 'containers/Pages/CompanyContact/Modals/Contacts';
import usePermissions from 'containers/App/Hooks/usePermissions';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import EditContactInfo from './Components/EditContactInfo';

const SidePanel = ( props ) => {
  // const {modalContent} = props;
  const {
    handleCollapse,
    showRightPanel,
    Edituser,
    user,
    userID,
    company
    } = props;
    const userPerms = useSelector((state) => state.user.user.permissions.roles);
    const [engineerPerms, setEngineerPerms] = useState(false);

    const permission = usePermissions({
      access: {
        type: 'one',
        priority: 1,
        permissions: [
          {
            key: 'CAN_EDIT_TICKET',
            value: true,
          },
        ],
      },
      // organizations: {
      //   type: "one",
      //   priority: 1,
      //   permissions: [
      //     {
      //       key: "CAN_EDIT_TICKET",
      //       value: true,
      //       organization: props.ticket.organization.id,
      //     },
      //   ],
      // },
    });
  
  return (
    <>
      <SidePanelItem
        icon={["fa", "users"]}
        title={Translation("companyContactPage.tabs.sidePanel.contacts")}
        // handleCollapse={"right"}
        right
        panelCollapsed={showRightPanel}
        handleTabs={() => { }}
        tabValue={null}
        handleCollapse={handleCollapse}
          permission={permission.access}
          modalContent={
            permission.access ? (
              // <EngineersModal
              //   handleSelectionChange={handleOnClickEngineer}
              //   updateTicketLocal={updateTicketLocal}
              //   ticket={ticket}
              //   ticketUpdateData={ticketUpdateData}
              // />
             <EditContactInfo
             Edituser={Edituser}
             user={user}
             userID={userID}
             />
            ) : (
              false
            )
          }
          

      >
            <ContactsModal
            Edituser={Edituser}
            user={user}
            />
  
      </SidePanelItem>
      {/* <SidePanelItem
        icon={["fa", "file"]}
        title={Translation("companyContactPage.tabs.sidePanel.licenses")}
        handleCollapse={"right"}
        right
        panelCollapsed={showRightPanel}
        handleTabs={() => { }}
        tabValue={null}
      >
        <LicensesModal />
      </SidePanelItem>
      <SidePanelItem
        icon={["fa", "truck"]}
        title={Translation("companyContactPage.tabs.sidePanel.procurement")}
        handleCollapse={"right"}
        right
        panelCollapsed={showRightPanel}
        handleTabs={() => { }}
        tabValue={null}
      >
        <ProcurementModal />
      </SidePanelItem>
      <SidePanelItem
        icon={["fa", "server"]}
        title={Translation("companyContactPage.tabs.sidePanel.assets")}
        handleCollapse={"right"}
        right
        panelCollapsed={showRightPanel}
        handleTabs={() => { }}
        tabValue={null}
      >
        <AssetsModal />
      </SidePanelItem>
      <SidePanelItem
        icon={["fa", "file"]}
        title={Translation("companyContactPage.tabs.sidePanel.invoices")}
        handleCollapse={"right"}
        right
        panelCollapsed={showRightPanel}
        handleTabs={() => { }}
        tabValue={null}
      >
        <InvoicesModal />
      </SidePanelItem>
      <SidePanelItem
        icon={["fa", "circle-check"]}
        title={Translation("ticketViewPage.checklist")}
        handleCollapse={"right"}
        right
        panelCollapsed={showRightPanel}
        handleTabs={() => { }}
        tabValue={null}
      >
        <List sx={{ width: '100%', }}>

          <ListItem button>
            <Typography variant="h6" style={{ marginLeft: 7 }}>
              {Translation("ticketViewPage.checklist")}
            </Typography>

          </ListItem>
        </List>
      </SidePanelItem>

      <SidePanelItem
        icon={["fa", "paste"]}
        title={Translation("ticketViewPage.attachments")}
        handleCollapse={"right"}
        right
        panelCollapsed={showRightPanel}
        handleTabs={() => { }}
        tabValue={null}
      >
        <List sx={{ width: '100%', }}>
          <ListItem button>


            <Typography variant="h6" style={{ marginLeft: 7 }}>
              {Translation("ticketViewPage.attachments")}
            </Typography>

          </ListItem>
        </List>
      </SidePanelItem>

      <SidePanelItem
        icon={["fa", "folder-tree"]}
        title={Translation("ticketViewPage.relatedItems")}
        handleCollapse={"right"}
        right
        panelCollapsed={showRightPanel}
        handleTabs={() => { }}
        tabValue={null}
      >
        <List sx={{ width: '100%', }}>
          <ListItem button>
            <Typography variant="h6" style={{ marginLeft: 7 }}>
              {Translation("ticketViewPage.relatedItems")}
            </Typography>

          </ListItem>
        </List>
      </SidePanelItem> */}
    </>
    //   <Box
    //     sx={{
    //       width: '320px',
    //       flexShrink: 0,
    //       transition: 'width 0.3s ease',
    //       borderLeft: '1px solid #ccc',
    //       backgroundColor: '#f9f9f9',
    //       position: 'absolute',
    //       top: 116,
    //       right: 0,
    //       bottom: 0,
    //       height: 'auto',
    //       display: 'flex',
    //       flexDirection: 'column',
    //       alignItems: 'center',
    //       overflowY: 'auto',
    //     }}
    //   >
    //     {/* Side panel content goes here */}
    //     <SidePanelItem
    //     //handleTabs={handleTabs}
    //      // tabValue={tabValue}
    //       title={Translation("ticketViewPage.devices")}
    //      // panelCollapsed={showRightPanel}
    //       icon={["fa", "laptop"]}
    //     //  right
    //       modalContent={
    //         <ContactsModal/>
    //           }
    //     //  handleCollapse={handleCollapse}
    //       // modalContent={
    //       //   <ContactsModel
    //       //     updateTicketLocal={updateTicketLocal}
    //       //     ticket={ticket}
    //       //   />
    //       // }

    //     >

    //       <ContactsModal/>
    //     </SidePanelItem>


    //     <Divider style={{ marginTop: 2, borderBottom: '0.5px solid black', width: '100%', padding: 5 }} />


    //     <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 0, padding: 1 }}>
    //       <FontAwesomeIcon icon={faFile} />
    //       <Typography variant="h6" style={{ marginLeft: 10, color: '#3F76B6' }}>Licences</Typography>

    //       <Box sx={{ ml: 'auto' }}>
    //         <FontAwesomeIcon icon={faCircleArrowRight} style={{marginLeft: 170}}/>
    //       </Box>
    //     </Box>

    //     <Box sx={{ border: '0.5px solid', borderRadius: 2, padding: 2, display: 'flex', width: '90%', }}>
    //       <Typography  >MICROSOFT OFFICE 365</Typography>
    //       <FontAwesomeIcon icon={faCircleArrowRight} style={{ color: "#3f76b6", marginLeft: 40 }} />
    //       <FontAwesomeIcon icon={faEllipsis} style={{ color: "#050505", marginLeft: 10 }} />
    //     </Box>
    //     <Box sx={{ border: '0.5px solid', borderRadius: 2, width: '90%', padding: 2, display: 'flex', marginTop: 2 }}>
    //       <Typography  >NINJA RMM</Typography>

    //       <FontAwesomeIcon icon={faEllipsis} style={{ color: "#050505", marginLeft: 150 }} />
    //     </Box>




    //     <Divider style={{ marginTop: 2, borderBottom: '0.5px solid black', width: '100%', padding: 5 }} />

    //     <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1, padding: 1, paddingTop: 2 }}>
    //       <FontAwesomeIcon icon={faTruck} />
    //       <Typography variant="h6" style={{ marginLeft: 10, color: '#3F76B6' }}  >Procurement</Typography>
    //       <FontAwesomeIcon icon={faCircleArrowDown} style={{ marginLeft: 127 }} />
    //     </Box>
    //     <Box sx={{ border: '0.5px solid', borderRadius: 2, width: '90%', padding: 0.5, display: 'flex', marginTop: 1 }}>
    //     <Typography style={{ fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
    //         #122493
    //     </Typography>
    //     <Typography style={{ flex: '1', textAlign: 'center', marginLeft: 0 }}>12/12/2021</Typography>
    //     <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#4fcf83", marginRight: 5 }} />
    //     <FontAwesomeIcon icon={faCircleArrowRight} style={{ color: "#3f76b6" }} />
    //   </Box>
    //  <Box sx={{ border: '0.5px solid', borderRadius: 2, width: '90%', padding: 0.5, display: 'flex', marginTop: 2 }}>
    //     <Typography style={{ fontWeight: 600, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
    //         #122493
    //     </Typography>
    //     <Typography style={{ flex: '1', textAlign: 'center', marginLeft: 0 }}>12/12/2021</Typography>
    //     <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#4fcf83", marginRight: 5 }} />
    //     <FontAwesomeIcon icon={faCircleArrowRight} style={{ color: "#3f76b6" }} />
    //   </Box>



    //   <Divider style={{ marginTop: 2, borderBottom: '0.5px solid black', width: '100%', padding: 5 }} />


    //       <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 1, paddingTop: 2, }}>
    //         <FontAwesomeIcon icon={faServer} />
    //         <Typography variant="h6" style={{ marginLeft: 10, color: '#3F76B6' }}  > Assets</Typography>
    //         <FontAwesomeIcon icon={faCircleArrowDown} style={{ marginLeft: 183 }} />
    //       </Box>
    //       <Box sx={{paddingTop: 20, }}>

    //       </Box>


    //       <Divider style={{ marginTop: 2, borderBottom: '0.5px solid black', width: '100%', padding: 5 }} />


    //       <Box sx={{ display: 'flex', alignItems: 'center',  paddingTop: 2, }}>
    //         <FontAwesomeIcon icon={faFile}  />
    //         <Typography variant="h6" style={{ marginLeft: 10, color: '#3F76B6' }}  > Invoices</Typography>
    //         <FontAwesomeIcon icon={faCircleArrowDown} style={{ marginLeft: 172 }} />
    //       </Box>
    //       <Box sx={{paddingTop: 20, }}>

    //       </Box>




    //     <List sx={{ width: '100%', maxWidth: 360 }}>
    //     <Divider/>
    //       <ListItem button>

    //         <FontAwesomeIcon icon={faCircleCheck} />
    //         <Typography variant="h6" style={{ marginLeft: 7 }}>
    //           Checklist
    //         </Typography>

    //       </ListItem>
    //       <Divider />
    //       <ListItem button>

    //         <FontAwesomeIcon icon={faPaste} />
    //         <Typography variant="h6" style={{ marginLeft: 7 }}>
    //           Attachments
    //         </Typography>

    //       </ListItem>
    //       <Divider />
    //       <ListItem button>

    //         <FontAwesomeIcon icon={faFolderTree} />
    //         <Typography variant="h6" style={{ marginLeft: 7 }}>
    //           Related Items
    //         </Typography>

    //       </ListItem>
    //     </List>

    //     {/* <IconButton onClick={onClose}>
    //       <ChevronLeftIcon />
    //     </IconButton> */}

    //   </Box>

  );
};

export default SidePanel;
