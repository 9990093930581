import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  openSettingsDrawer,
  closeSettingsDrawer,
} from "containers/App/Redux/menu/actions";

class SettingsButton extends React.PureComponent {
  openCloseSettingsDrawer = () => {
    const { openSettingDrawer } = this.props;
    if (openSettingDrawer) {
      this.props.dispatch(closeSettingsDrawer());
    } else {
      this.props.dispatch(openSettingsDrawer());
    }
  };

  render() {
    return (
      <IconButton
        className="settings-button"
        onClick={this.openCloseSettingsDrawer}
        size="large"
      >
        <NotificationsIcon />
      </IconButton>
    );
  }
}

SettingsButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.any.isRequired,
  openSettingDrawer: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    openSettingDrawer: state.menu.openSettingDrawer,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SettingsButton);
