const misc = {
  'tooltip.disabled': 'Wyłączony',
  'languageSelector.english': 'Angielski',
  'languageSelector.dutch': 'Holenderski',
  'languageSelector.german': 'Niemiecki',
  'languageSelector.french': 'Francuski',
  'languageSelector.spanish': 'Hiszpański',
  'languageSelector.polish': 'Polski',
  'languageSelector.italian': 'Włoski',
  'languageSelector.thai': 'Tajski',
  'datatable.toolbar.quickFilterPlaceholder': 'Szukaj...',
  'datatable.toolbar.columns': 'Kolumny',
  'datatable.toolbar.density': 'Gęstość',
  'datatable.toolbar.densityComfortable': 'Komfortowy',
  'datatable.toolbar.densityCompact': 'Kompaktowy',
  'datatable.toolbar.densityStandard': 'Standardowy',
  'datatable.toolbar.export': 'Eksport',
  'datatable.toolbar.filters': 'Filtry',
  'socket.connected': 'Połączony',
  'socket.disconnected': 'Rozłączony',
  'profile.menu.viewProfile': 'Zobacz profil',
  'profile.menu.editProfile': 'Edytuj profil',
  'profile.menu.help': 'Pomoc',
  'profile.menu.signOut': 'Wyloguj się',
  'addon.add': 'Dodaj',
  'addon.modal.save': 'Zapisz',
  'addon.edit': 'Edytuj',
  'notFoundPage.pageDevelopment': 'Strona w budowie',
  'notFoundPage.pageDevelopmentMessage':
    'Dziękujemy za eksplorowanie naszej strony. Ta konkretna strona jest jeszcze w fazie tworzenia. Doceniamy Twoją cierpliwość i zrozumienie.',
  'notFoundPage.pageDevelopmentMessage2':
    'Zauważ, że nasza strona jest regularnie aktualizowana i ulepszana, aby zapewnić Ci jak najlepsze doświadczenia. Wróć tu wkrótce po nową zawartość i funkcje!',
  general: 'Ogólne',
  'ticket.role.first_responder': 'Pierwszy Reagujący',
  'ticket.role.first_responder.tooltip':
    'Pierwszy Reagujący: Pierwszy punkt kontaktu dla wysokopriorytetowych problemów IT, odpowiedzialny za szybkie rozwiązanie i minimalny czas przestoju.',
  'ticket.role.lead_engineer': 'Główny Inżynier',
  'ticket.role.lead_engineer.tooltip':
    'Główny Inżynier: Odpowiedzialny za ogólny kierunek techniczny projektu oraz za jego terminowe i budżetowe zakończenie.',
  'ticket.status_id': 'Status',
  'ticket.priority_id': 'Priorytet',
  'ticket.closed_at': 'Zamknięto o',
  'ticket.subject': 'Temat',
  'team.team': 'Zespół',
  'ticket.created_at': 'Utworzono o',
  'ticket.updated_at': 'Zaktualizowano o',
  'ticket.requester_id': 'Osoba zgłaszająca',
  'ticket.organization_id': 'Organizacja',
  'ticket.assignee_id': 'Osoba przypisana',
  'ticket.author_id': 'Autor',
  'ticket.public': 'Publiczne',
  'ticket.alias': 'ID',
  'ticket.status': 'Status',
  'ticket.priority': 'Priorytet',
  'ticket.team_id': 'Zespół',
  'ticket.assignee': 'Osoba przypisana',
  'ticket.requester': 'Osoba zgłaszająca',
  'ticket.has_incidents': 'Ma incydenty',
  'ticket.updated_by_id': 'Zaktualizowane przez',
  'ticket.author': 'Autor',
  'person.assignee': 'Osoba przypisana',
  'person.requester': 'Osoba zgłaszająca',
  'ticket_status.status': 'Status',
  'ticket_priority.priority': 'Priorytet',
  'organization.organization': 'Firma',
  'ticket_priority.priority.normal': 'Normalny',
  'ticket_priority.priority.high': 'Wysoki',
  'ticket_priority.priority.low': 'Niski',
  'ticket_status.status.new': 'Nowy',
  'ticket_status.status.pending': 'Oczekujący',
  'ticket_status.status.hold': 'Wstrzymany',
  'ticket_status.status.open': 'Otwarty',
  'ticket_status.status.resolved': 'Rozwiązany',
  'person.name': 'Imię',
  'person.avatar': 'Awatar',
  'person.email_address': 'E-mail',
  'person.active': 'Aktywny',
  'person.alias': 'ID',
  'organization.name': 'Nazwa',
  'organization.active': 'Aktywna',
  'organization.alias': 'ID',
  'board.board': 'Tablica',

  // new translations
  'dateTime.utc': 'UTC',
  'ticket.devices.RMM.install': 'Zainstaluj Six Star RMM',
  'ticket.devices.RMM.login': 'Zaloguj się do Six Star RMM',
  'ticket.devices.RMM.title': 'Aby zobaczyć najnowsze informacje',

  // Filtry tabeli danych
  'dataTable.filters.reset': 'Resetuj',
  'dataTable.filters.loading': 'ŝadowanie...',
  'dataTable.filters.header': 'Filtry',

  // Podgląd tabeli danych
  'dataTable.preview.viewTicket': 'Zobacz zgłoszenie',
  'dataTable.preview.quickNotes': 'Szybkie notatki',
  'dataTable.preview.contacts': 'Kontakty',
  'dataTable.preview.followers': 'Obserwujący',
  'dataTable.preview.relatedTickets': 'Powiązane zgłoszenia',
  'dataTable.preview.updateIn': 'Aktualizacja za',
  'dataTable.preview.devices': 'Urządzenia',
  'dataTable.preview.engineer': 'Inżynier',
  'dataTable.preview.ticketCreated': 'Zgłoszenie utworzone:',

  // ChatGPT
  'chatgpt.somethingWentWrong': 'Coś poszło nie tak, spróbuj ponownie',
  'chatgpt.history': 'Historia',

  // Przycisk grupy niestandardowej
  'customGroupButton.one.expandAll': 'Rozwiń wszystko',
  'customGroupButton.two.collapseAll': 'Zwiń wszystko',

  // Liczby flagowe
  'flagNumber.button.callUs': 'Zadzwoń do nas',
  'profile.menu.viewCompany': 'Zobacz firmę',
  'contactUsModals.addWidgetModal.title': 'Jak dodać widget do swojego pulpitu',
  'contactUsModals.addWidgetModal.step1': '1. Przejdź do swojego pulpitu',
  'contactUsModals.addWidgetModal.step2': '2. Znajdź fioletową wstążkę na górze ekranu',
  'contactUsModals.addWidgetModal.step3': '3. Kliknij przycisk Dodaj widget',
  'contactUsModals.addWidgetModal.step4': '4. Wybierz widget z listy',
  'contactUsModals.addWidgetModal.step5': '5. Kliknij przycisk Dodaj',
  'contactUsModals.signOutModal.title': 'Jak się wylogować',
  'contactUsModals.signOutModal.step1': '1. Znajdź swój avatar u góry po prawej stronie ekranu',
  'contactUsModals.signOutModal.step2': '2. Kliknij na swój avatar',
  'contactUsModals.signOutModal.step3': '3. Znajdź przycisk wylogowania w menu rozwijanym',
  'contactUsModals.signOutModal.step4': '4. Kliknij przycisk wylogowania',
  'ticketViewPage.toolbar.label': 'Szukaj biletów',
  'ticketViewPage.toolbar.view': 'Widok',
  'ticketViewPage.toolbar.saveView': 'Zapisz widok',
  'ticketViewPage.toolbar.saveViewPrompt': 'Proszę podać nazwę, pod jaką chcesz zapisać ten widok.',
  'ticketViewPage.toolbar.label.export': 'Eksportuj',
  'ticketViewPage.toolbar.label.filters': 'Filtry',
  'formLoader.submissionSuccess': 'Dziękujemy za przesłanie formularza',
  'formLoader.submissionError': 'Wystąpił błąd podczas przesyłania formularza',
  'reportIssue.submit': 'Wyślij',
  'reportIssue.issue': 'Problem...',
  'ticketViewPage.modal.button.bunle': 'Paczka',
  'ticketViewPage.modal.button.back': 'Wstecz',
  'ticketDocuments.notFound': 'Nie znaleziono dokumentów',
  'ticketViewPage.childTicketsNone': 'Brak biletów podrzędnych',
  'ticketViewPage.parentNone': 'Brak biletu nadrzędnego',
  'ticketViewPage.restricted': 'Ograniczony',
};
export default misc;
