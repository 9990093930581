import { applyMiddleware, compose, createStore } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import createReducer from "./reducers";
import { persistReducer, persistStore } from "redux-persist";
import { createLogger } from "redux-logger";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import menuSagas from "./containers/App/Sagas/menu";
import authSagas from "./containers/App/Sagas/auth";
import socketMiddleware from "middleware/websocket/socketMiddleware";
import microsoftMiddleware from "middleware/microsoft/microsoftMiddleware";
import userMiddleware from "middleware/user/userMiddleware";

const persistConfig = {
  key: "manage",
  storage,
  blacklist: [
    "router",
    "menu",
    "clipboard",
    "tickets",
    "app",
    "onlineUsers",
    "user",
  ],
};

function configureStore(socketClient, microsoftClient, initialState = {}) {
  const persistedReducer = persistReducer(persistConfig, createReducer());
  const { createReduxHistory, routerMiddleware } = createReduxHistoryContext({
    history: createBrowserHistory(),
  });
  const reduxSagaMonitorOptions = {};
  let composeEnhancers = compose;
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const middlewares = [
    sagaMiddleware,
    routerMiddleware,
    socketMiddleware(socketClient),
    microsoftMiddleware(microsoftClient, socketClient),
    userMiddleware.middleware,
  ];

  const logger = createLogger({
    collapsed: true,
    level: "info",
    diff: true,
  });

  let enhancers = [];
  if (
    process.env.REACT_APP_BUILD === "DEV" &&
    process.env.REACT_APP_LOGGER === "true"
  ) {
    enhancers = [
      applyMiddleware(...middlewares),
      applyMiddleware(routerMiddleware),
      applyMiddleware(logger),
    ];
  } else {
    enhancers = [
      applyMiddleware(...middlewares),
      applyMiddleware(routerMiddleware),
    ];
  }

  const isChrome = !!window.chrome;

  if (process.env.REACT_APP_BUILD === "DEV") {
    if (isChrome) {
      enhancers = [
        ...enhancers,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__(),
      ];
    }
  }

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers)
  );
  const persistor = persistStore(store);

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry
  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    // module.hot.accept('./reducers', () => {
    //   store.replaceReducer(createReducer(store.injectedReducers));
    // });

    module.hot.accept("./reducers", () => {
      // This fetch the new state of the above reducers.
      const nextRootReducer = require("./reducers").default;
      store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
    });
  }
  const history = createReduxHistory(store);

  // socketClient.connect(store);

  menuSagas.map(store.runSaga);
  // authSagas.map(store.runSaga)
  return { store, history, persistor };
}

export default configureStore;
