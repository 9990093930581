import React, { useEffect, useRef, useState, PureComponent, useMemo } from 'react';
import styles from './styles';
import withStyles from '@mui/styles/withStyles';
import { DateTime } from 'luxon';
import { useQuery, useLazyQuery } from '@apollo/client';
import { LineChart } from '@mui/x-charts/LineChart';
import { gql } from '@apollo/client';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

function AuditLogGraph({ theme, classes, agentData, selectedAgent, selectedAgentName }) {
  // const { agentData, selectedAgent, selectedAgentName  } = props;

  const AGENT_QUERY = gql`
    query GET_AGENTS {
      person(where: { active: { _eq: true }, organization_id: { _eq: "be4c7483-78e6-482e-ba4b-630e5bba3ff1" } }, order_by: { name: asc }) {
        name
        last_name
        id
      }
    }
  `;

  const AUDIT_QUERY = gql`
    query GET_AUDIT_LOG($person_id: uuid!) {
      ticket_audit_30(where: { person_id: { _eq: $person_id }, diffs: { _cast: { String: { _niregex: "\\"old\\": {\\"updated_at\\"" } } } }, order_by: { created_at: desc }) {
        id
        ticket {
          id
          alias
        }
        diffs
        created_at
        person {
          id
          alias
          name
          email_address
        }
      }
    }
  `;

  const [agentId, setAgentId] = useState(null);
  const [compareAgentId, setCompareAgentId] = useState(null);
  const [graphDataArray, setGraphDataArray] = useState([]);
  const [sortedGraphDataArray, setSortedGraphDataArray] = useState([]);
  const { loading, error, data } = useQuery(AGENT_QUERY);
  const [getAuditLog, { loading: auditLoading, error: auditError, data: auditData }] = useLazyQuery(AUDIT_QUERY);
  const [getCompareAuditLog, { loading: compareAuditLoading, error: compareAuditError, data: compareAuditData }] = useLazyQuery(AUDIT_QUERY);

  useEffect(() => {
    if (selectedAgent) {
      getAuditLog({
        variables: {
          person_id: selectedAgent,
        },
      }).then((response) => {
        // Step 3: Adjust the Data Processing
        const processedData = processAuditData(response.data);
        setGraphDataArray((currentData) => [
          ...currentData.filter((data) => data.type !== 'primary'), // Remove old primary data
          { type: 'primary', data: processedData },
        ]);
      });
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (compareAgentId) {
      getCompareAuditLog({
        variables: {
          person_id: compareAgentId,
        },
      }).then((response) => {
        const processedData = processAuditData(response.data);
        setGraphDataArray((currentData) => [
          ...currentData.filter((data) => data.type !== 'compare'), // Remove old compare data
          { type: 'compare', data: processedData },
        ]);
      });
    }
  }, [compareAgentId]);

  const processAuditData = (auditData) => {
    const ticketCountsByDate = {};
    auditData.ticket_audit_30.forEach((ticket) => {
      const date = DateTime.fromISO(ticket.created_at).toISODate();
      ticketCountsByDate[date] = (ticketCountsByDate[date] || 0) + 1;
    });
    return Object.entries(ticketCountsByDate).map(([date, count]) => ({ date, count }));
  };

  const xAxisData = sortedGraphDataArray.map((item) => ({
    data: item.data.map((dataPoint) => dataPoint.date), // Assuming each dataPoint has a 'date' property
    scaleType: 'band',
    valueFormatter: (value) => value || '',
  }));

  const unifiedXAxisData = [
    {
      data: xAxisData[0]?.data || [],
      scaleType: 'band',
      valueFormatter: (value) => value || '',
      label: 'Date',
    },
  ];

  const seriesData = sortedGraphDataArray.map((item) => ({
    data: item.data.map((dataPoint) => dataPoint.count), // Assuming each dataPoint has a 'count' property
    label: item.type === 'primary' ? selectedAgentName : compareAgentId ? data?.person.find((agent) => agent.id === compareAgentId)?.name : '',
  }));

  const resetSelections = () => {
    setAgentId(null); // Assuming null is the initial state
    setCompareAgentId(null);
  };

  useEffect(() => {
    // Sort graphDataArray whenever it changes
    const sortedData = [...graphDataArray].sort((a, b) => b.data.length - a.data.length);
    setSortedGraphDataArray(sortedData);
  }, [graphDataArray]);

  return (
    // <PageBase>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            //centering the box
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          {selectedAgentName ? <Typography variant='h6'> {selectedAgentName}'s Ticket Actions</Typography> : <Typography variant='h6'> Please Select an Agent</Typography>}
        </Box>
        <Autocomplete
          id='compare-agent-autocomplete'
          options={data?.person || []}
          getOptionLabel={(option) => option.name}
          value={data?.person.find((agent) => agent.id === compareAgentId) || null}
          onChange={(event, newValue) => {
            setCompareAgentId(newValue?.id || null);
          }}
          renderInput={(params) => <TextField {...params} label='Compare Agent' variant='outlined' />}
          sx={{ minWidth: 200, ml: 2 }}
        />
        <Button variant='outlined' sx={{ ml: 2 }} onClick={resetSelections}>
          Reset
        </Button>
      </Box>
      {selectedAgentName ? (
        <LineChart
          xAxis={unifiedXAxisData}
          series={seriesData}
          width={600}
          height={400}
          grid={{ vertical: true, horizontal: true }}
          yAxis={[
            {
              label: 'Ticket Actions',
            },
          ]}
        />
      ) : null}
    </Box>
    // </PageBase>
  );
}

export default withStyles(
  (theme) => ({
    ...styles(theme),
  }),
  { withTheme: true },
)(AuditLogGraph);
