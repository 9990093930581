import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Icon from "@mui/material/Icon";
import Tab from "@mui/material/Tab";
import { MenuTabs as Tabs } from "../Styled";
import layoutStyles from "containers/Layout/styles";
import { grey } from "@mui/material/colors";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";

const newTab = (props) => {
  return (
    <Tab
      key={`id-${props.menuItem.id}`}
      value={props.menuItem.index}
      label={props.label}
      className={classNames(
        `${props.menuItem.index > 0 ? "close-tab" : ""}`,
        props.classes.tab
      )}
      component="div"
      icon={
        props.menuItem.index ? (
          <Icon
            onClick={() => props.handleCloseView(props.menuItem)}
            className="material-icons"
          >
            cancel
          </Icon>
        ) : null
      }
    />
  );
};

const HeaderTabs = ({
  classes,
  openViews,
  selectedMenuItem,
  handleTabChange,
  handleCloseView,
}) => {
  const intl = useIntl();
  return (
    <Tabs
      className={classNames("tabs", classes.tabs)}
      value={(selectedMenuItem && selectedMenuItem.index) || 0}
      onChange={(event, value) => {
        handleTabChange(event, value, true);
      }}
      variant="scrollable"
      scrollButtons
      TabIndicatorProps={
        selectedMenuItem.children && {
          style: { backgroundColor: grey[100], height: 48, zIndex: -1 },
        }
      }
      allowScrollButtonsMobile
    >
      {openViews.map((menuItem) => {
        let text = intl.formatMessage({
          id: menuItem.text,
          defaultMessage: menuItem.text + "Not Defined In Translation File",
          description: "Translation for " + menuItem.text,
        });

        return newTab({
          label: text,
          menuItem: menuItem,
          classes: classes,
          handleCloseView: handleCloseView,
        });
      })}
    </Tabs>
  );
};

HeaderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCloseView: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  openViews: PropTypes.any.isRequired,
  selectedMenuItem: PropTypes.object.isRequired,
};

export default withStyles((theme) => layoutStyles(theme), {
  withTheme: true,
})(HeaderTabs);
