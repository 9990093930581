import { Client } from "@microsoft/microsoft-graph-client";

export default class microsoftAPI {
  client = null;

  connect(token = "notoken") {
    return new Promise((resolve, reject) => {
      this.client = Client.init({
        authProvider: (done) => {
          done(null, token);
          resolve();
        },
      });
    });
  }

  get(endpoint, token) {
    this.connect(token);
    return new Promise((resolve, reject) => {
      return this.client
        .api(endpoint)
        .get()
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }
}
