import { createSlice } from "@reduxjs/toolkit";
import base64 from "base-64";
import { useSelector} from "react-redux";
import { useDispatch } from 'react-redux';


const GMorAgent = {
  home: {
    layouts: {
      lg: [
        {
          h: 7,
          w: 12,
          x: 0,
          y: 19,
          i: 'ff6bb8df-f8e1-4224-b39b-f779c75d6e83',
          minW: 6,
          minH: 6,
          maxW: 12,
          maxH: 12,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 2,
          y: 5,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 5,
          i: '6b51eb9b-a587-4275-ac47-2bbddeb4e800',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 2,
          y: 8.5,
          i: 'd311b013-b0f2-4dd8-b5e6-3c8848a13e17',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 4,
          y: 5,
          i: '18a59164-2bad-4db6-a647-a6537e52f877',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 4,
          y: 8.5,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 8.5,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 6,
          y: 0,
          i: 'f80e4764-809b-49d8-b518-bf492f223ba6',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 0,
          i: '59a3d221-b286-40f0-8e40-cd4ad625caaa',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 0,
          y: 12,
          i: '492af327-0181-4363-8cb0-4765eb49ad13',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 6,
          y: 12,
          i: 'cbe10595-e73b-4a13-a657-6fe8100a534d',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 6,
          y: 5,
          i: '5a57e377-7e36-4f36-8228-50033e31cba9',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        }
      ],
      md: [
        {
          h: 8,
          w: 12,
          x: 0,
          y: 0,
          i: 'ff6bb8df-f8e1-4224-b39b-f779c75d6e83',
          minW: 6,
          minH: 6,
          maxW: 12,
          maxH: 12,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 8,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 3,
          x: 0,
          y: 11.5,
          i: '6b51eb9b-a587-4275-ac47-2bbddeb4e800',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 3,
          x: 0,
          y: 15,
          i: 'd311b013-b0f2-4dd8-b5e6-3c8848a13e17',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 3,
          x: 0,
          y: 18.5,
          i: '18a59164-2bad-4db6-a647-a6537e52f877',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 22,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 25.5,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 29,
          i: 'f80e4764-809b-49d8-b518-bf492f223ba6',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 34,
          i: '59a3d221-b286-40f0-8e40-cd4ad625caaa',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 0,
          y: 39,
          i: '492af327-0181-4363-8cb0-4765eb49ad13',
          minW: 1.5,
          minH: 1,
          maxW: 18,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 46,
          i: 'cbe10595-e73b-4a13-a657-6fe8100a534d',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 0,
          y: 51,
          i: '5a57e377-7e36-4f36-8228-50033e31cba9',
          minW: 1.5,
          minH: 1,
          maxW: 18,
          maxH: 7,
          'static': false,
          moved: false
        }
      ],
      sm: [
        {
          h: 8,
          w: 12,
          x: 0,
          y: 0,
          i: 'ff6bb8df-f8e1-4224-b39b-f779c75d6e83',
          minW: 6,
          minH: 6,
          maxW: 12,
          maxH: 12,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 8,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 4,
          x: 0,
          y: 11.5,
          i: '6b51eb9b-a587-4275-ac47-2bbddeb4e800',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 4,
          x: 0,
          y: 15,
          i: 'd311b013-b0f2-4dd8-b5e6-3c8848a13e17',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 4,
          x: 0,
          y: 18.5,
          i: '18a59164-2bad-4db6-a647-a6537e52f877',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 22,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 25.5,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 29,
          i: 'f80e4764-809b-49d8-b518-bf492f223ba6',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 34,
          i: '59a3d221-b286-40f0-8e40-cd4ad625caaa',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 0,
          y: 39,
          i: '492af327-0181-4363-8cb0-4765eb49ad13',
          minW: 2,
          minH: 1,
          maxW: 24,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 46,
          i: 'cbe10595-e73b-4a13-a657-6fe8100a534d',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 0,
          y: 51,
          i: '5a57e377-7e36-4f36-8228-50033e31cba9',
          minW: 2,
          minH: 1,
          maxW: 24,
          maxH: 7,
          'static': false,
          moved: false
        }
      ],
      xs: [
        {
          x: 0,
          y: 0,
          w: 12,
          h: 8,
          minW: 6,
          maxW: 12,
          minH: 6,
          maxH: 12,
          moved: false,
          'static': false,
          i: 'ff6bb8df-f8e1-4224-b39b-f779c75d6e83'
        },
        {
          x: 0,
          y: 0,
          w: 2,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: '6b51eb9b-a587-4275-ac47-2bbddeb4e800'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: 'd311b013-b0f2-4dd8-b5e6-3c8848a13e17'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: '18a59164-2bad-4db6-a647-a6537e52f877'
        },
        {
          x: 0,
          y: 0,
          w: 2,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e'
        },
        {
          x: 0,
          y: 0,
          w: 2,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 5,
          minW: 1,
          maxW: 12,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: 'f80e4764-809b-49d8-b518-bf492f223ba6'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 5,
          minW: 1,
          maxW: 12,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '59a3d221-b286-40f0-8e40-cd4ad625caaa'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 7,
          minW: 6,
          maxW: 12,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '492af327-0181-4363-8cb0-4765eb49ad13'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 5,
          minW: 1,
          maxW: 12,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: 'cbe10595-e73b-4a13-a657-6fe8100a534d'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 7,
          minW: 6,
          maxW: 12,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '5a57e377-7e36-4f36-8228-50033e31cba9'
        }
      ]
    },
    data: {
      'ff6bb8df-f8e1-4224-b39b-f779c75d6e83': {
        name: 'Widget',
        view: {
          filters: [],
          density: 'compact',
          columnVisibilityModel: {
            alias: true,
            assignee: true,
            requester: true,
            status: true,
            priority: true,
            organization: true,
            subject: true,
            updated_at: true,
            created_at: true
          },
          rowsPerPage: 10
        },
        hidden: false
      },
      'd311b013-b0f2-4dd8-b5e6-3c8848a13e17': {
        openTickets: false,
        p2Tickets: true
      },
      '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25': {
        openTickets: false,
        closedTickets: false,
        p2Tickets: true
      },
      'baf3dc3d-f8ef-4b86-a1e1-3712477450e9': {
        p1Tickets: true
      },
      'dbbae34f-2085-47f2-903b-90cae4af612e': {
        p3Tickets: false,
        openTickets: true
      },
      '6b51eb9b-a587-4275-ac47-2bbddeb4e800': {
        p1Tickets: true
      },
      '18a59164-2bad-4db6-a647-a6537e52f877': {
        openTickets: true
      }
    },
    widgets: [
      {
        id: 'ff6bb8df-f8e1-4224-b39b-f779c75d6e83',
        addon: 'TicketTable',
        version: 3
      },
      {
        id: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
        addon: 'personTickets',
        version: 9
      },
      {
        id: '6b51eb9b-a587-4275-ac47-2bbddeb4e800',
        addon: 'organizationTickets',
        version: 12
      },
      {
        id: 'd311b013-b0f2-4dd8-b5e6-3c8848a13e17',
        addon: 'organizationTickets',
        version: 12
      },
      {
        id: '18a59164-2bad-4db6-a647-a6537e52f877',
        addon: 'organizationTickets',
        version: 12
      },
      {
        id: 'dbbae34f-2085-47f2-903b-90cae4af612e',
        addon: 'personTickets',
        version: 9
      },
      {
        id: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
        addon: 'personTickets',
        version: 9
      },
      {
        id: 'f80e4764-809b-49d8-b518-bf492f223ba6',
        addon: 'Guage'
      },
      {
        id: '59a3d221-b286-40f0-8e40-cd4ad625caaa',
        addon: 'LineGraph'
      },
      {
        id: '492af327-0181-4363-8cb0-4765eb49ad13',
        addon: 'dailyUpdates',
        version: 12
      },
      {
        id: 'cbe10595-e73b-4a13-a657-6fe8100a534d',
        addon: 'MUIbarchart'
      },
      {
        id: '5a57e377-7e36-4f36-8228-50033e31cba9',
        addon: 'releaseNotes',
        version: 12
      }
    ],
    collapsed: {}
  }
}; 

const Users = {
  home: {
    layouts: {
      lg: [
        {
          h: 7,
          w: 12,
          x: 0,
          y: 14.5,
          i: 'ff6bb8df-f8e1-4224-b39b-f779c75d6e83',
          minW: 6,
          minH: 6,
          maxW: 12,
          maxH: 12,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 2,
          y: 5,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 4,
          y: 5,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 5,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 0,
          i: '59a3d221-b286-40f0-8e40-cd4ad625caaa',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 6,
          w: 6,
          x: 0,
          y: 8.5,
          i: '492af327-0181-4363-8cb0-4765eb49ad13',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 8,
          w: 6,
          x: 6,
          y: 0,
          i: 'cbe10595-e73b-4a13-a657-6fe8100a534d',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 12,
          'static': false,
          moved: false
        },
        {
          h: 6,
          w: 6,
          x: 6,
          y: 8,
          i: '5a57e377-7e36-4f36-8228-50033e31cba9',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        }
      ],
      md: [
        {
          h: 8,
          w: 12,
          x: 0,
          y: 0,
          i: 'ff6bb8df-f8e1-4224-b39b-f779c75d6e83',
          minW: 6,
          minH: 6,
          maxW: 12,
          maxH: 12,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 8,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 22,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 25.5,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 34,
          i: '59a3d221-b286-40f0-8e40-cd4ad625caaa',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 0,
          y: 39,
          i: '492af327-0181-4363-8cb0-4765eb49ad13',
          minW: 1.5,
          minH: 1,
          maxW: 18,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 46,
          i: 'cbe10595-e73b-4a13-a657-6fe8100a534d',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 12,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 0,
          y: 51,
          i: '5a57e377-7e36-4f36-8228-50033e31cba9',
          minW: 1.5,
          minH: 1,
          maxW: 18,
          maxH: 7,
          'static': false,
          moved: false
        }
      ],
      sm: [
        {
          h: 8,
          w: 12,
          x: 0,
          y: 0,
          i: 'ff6bb8df-f8e1-4224-b39b-f779c75d6e83',
          minW: 6,
          minH: 6,
          maxW: 12,
          maxH: 12,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 8,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 11.5,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 15,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 23.5,
          i: '59a3d221-b286-40f0-8e40-cd4ad625caaa',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 0,
          y: 28.5,
          i: '492af327-0181-4363-8cb0-4765eb49ad13',
          minW: 2,
          minH: 1,
          maxW: 24,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 5,
          w: 6,
          x: 0,
          y: 35.5,
          i: 'cbe10595-e73b-4a13-a657-6fe8100a534d',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 12,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 0,
          y: 40.5,
          i: '5a57e377-7e36-4f36-8228-50033e31cba9',
          minW: 2,
          minH: 1,
          maxW: 24,
          maxH: 7,
          'static': false,
          moved: false
        }
      ],
      xs: [
        {
          x: 0,
          y: 0,
          w: 12,
          h: 8,
          minW: 6,
          maxW: 12,
          minH: 6,
          maxH: 12,
          moved: false,
          'static': false,
          i: 'ff6bb8df-f8e1-4224-b39b-f779c75d6e83'
        },
        {
          x: 0,
          y: 0,
          w: 2,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25'
        },
        {
          x: 0,
          y: 0,
          w: 2,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e'
        },
        {
          x: 0,
          y: 0,
          w: 2,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 5,
          minW: 1,
          maxW: 12,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '59a3d221-b286-40f0-8e40-cd4ad625caaa'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 7,
          minW: 6,
          maxW: 12,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '492af327-0181-4363-8cb0-4765eb49ad13'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 5,
          minW: 1,
          maxW: 12,
          minH: 1,
          maxH: 12,
          moved: false,
          'static': false,
          i: 'cbe10595-e73b-4a13-a657-6fe8100a534d'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 7,
          minW: 6,
          maxW: 12,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '5a57e377-7e36-4f36-8228-50033e31cba9'
        }
      ]
    },
    data: {
      'ff6bb8df-f8e1-4224-b39b-f779c75d6e83': {
        name: 'Widget',
        view: {
          filters: [],
          density: 'compact',
          columnVisibilityModel: {
            alias: true,
            assignee: true,
            requester: true,
            status: true,
            priority: true,
            organization: true,
            subject: true,
            updated_at: true,
            created_at: true
          },
          rowsPerPage: 10
        },
        hidden: false
      },
      '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25': {
        openTickets: false,
        closedTickets: false,
        p2Tickets: true
      },
      'baf3dc3d-f8ef-4b86-a1e1-3712477450e9': {
        p1Tickets: true
      },
      'dbbae34f-2085-47f2-903b-90cae4af612e': {
        p3Tickets: false,
        openTickets: true
      }
    },
    widgets: [
      {
        id: 'ff6bb8df-f8e1-4224-b39b-f779c75d6e83',
        addon: 'TicketTable',
        version: 3
      },
      {
        id: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
        addon: 'personTickets',
        version: 9
      },
      {
        id: 'dbbae34f-2085-47f2-903b-90cae4af612e',
        addon: 'personTickets',
        version: 9
      },
      {
        id: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
        addon: 'personTickets',
        version: 9
      },
      {
        id: '59a3d221-b286-40f0-8e40-cd4ad625caaa',
        addon: 'LineGraph'
      },
      {
        id: '492af327-0181-4363-8cb0-4765eb49ad13',
        addon: 'dailyUpdates',
        version: 12
      },
      {
        id: 'cbe10595-e73b-4a13-a657-6fe8100a534d',
        addon: 'MUIbarchart'
      },
      {
        id: '5a57e377-7e36-4f36-8228-50033e31cba9',
        addon: 'releaseNotes',
        version: 12
      }
    ],
    collapsed: {}
  }
}

const UsersNoData = {
  home: {
    layouts: {
      lg: [
        {
          h: 3.5,
          w: 2,
          x: 8,
          y: 0,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 6,
          y: 0,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 10,
          y: 0,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
          minW: 2,
          minH: 3.5,
          maxW: 2,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 6,
          x: 0,
          y: 0,
          i: '4a88fe8d-414a-4ba8-b734-33b76fdf1137',
          minW: 1,
          minH: 1,
          maxW: 12,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 2,
          x: 4,
          y: 7,
          i: '56eeb26b-8490-4603-8b91-0ec4730219bf',
          minW: 1,
          minH: 1,
          maxW: 4,
          maxH: 7,
          'static': false,
          moved: false
        },
        {
          h: 10,
          w: 6,
          x: 6,
          y: 3.5,
          i: '8cb94609-fec5-4e86-9e1a-f3aa48076f02',
          minW: 6,
          minH: 6,
          maxW: 12,
          maxH: 12,
          'static': false,
          moved: false
        },
        {
          h: 7,
          w: 4,
          x: 0,
          y: 7,
          i: '1bc0c2fe-6a4b-4e2f-b058-1467bd0f0a88',
          minW: 4,
          minH: 7,
          maxW: 4,
          maxH: 7,
          'static': false,
          moved: false
        }
      ],
      md: [
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 8,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 11.5,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 15,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
          minW: 3,
          minH: 3.5,
          maxW: 3,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 7,
          minW: 1.5,
          maxW: 18,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '4a88fe8d-414a-4ba8-b734-33b76fdf1137'
        },
        {
          x: 0,
          y: 0,
          w: 4,
          h: 7,
          minW: 1,
          maxW: 4,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '56eeb26b-8490-4603-8b91-0ec4730219bf'
        },
        {
          x: 0,
          y: 0,
          w: 12,
          h: 8,
          minW: 6,
          maxW: 12,
          minH: 6,
          maxH: 12,
          moved: false,
          'static': false,
          i: '8cb94609-fec5-4e86-9e1a-f3aa48076f02'
        },
        {
          x: 0,
          y: 0,
          w: 4,
          h: 7,
          minW: 4,
          maxW: 4,
          minH: 7,
          maxH: 7,
          moved: false,
          'static': false,
          i: '1bc0c2fe-6a4b-4e2f-b058-1467bd0f0a88'
        }
      ],
      sm: [
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 8,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 11.5,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          h: 3.5,
          w: 2,
          x: 0,
          y: 15,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
          minW: 4,
          minH: 3.5,
          maxW: 4,
          maxH: 3.5,
          'static': false,
          moved: false
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 7,
          minW: 2,
          maxW: 24,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '4a88fe8d-414a-4ba8-b734-33b76fdf1137'
        },
        {
          x: 0,
          y: 0,
          w: 4,
          h: 7,
          minW: 1,
          maxW: 4,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '56eeb26b-8490-4603-8b91-0ec4730219bf'
        },
        {
          x: 0,
          y: 0,
          w: 12,
          h: 8,
          minW: 6,
          maxW: 12,
          minH: 6,
          maxH: 12,
          moved: false,
          'static': false,
          i: '8cb94609-fec5-4e86-9e1a-f3aa48076f02'
        },
        {
          x: 0,
          y: 0,
          w: 4,
          h: 7,
          minW: 4,
          maxW: 4,
          minH: 7,
          maxH: 7,
          moved: false,
          'static': false,
          i: '1bc0c2fe-6a4b-4e2f-b058-1467bd0f0a88'
        }
      ],
      xs: [
        {
          x: 0,
          y: 0,
          w: 2,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25'
        },
        {
          x: 0,
          y: 0,
          w: 2,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: 'dbbae34f-2085-47f2-903b-90cae4af612e'
        },
        {
          x: 0,
          y: 0,
          w: 2,
          h: 3.5,
          minW: 6,
          maxW: 12,
          minH: 3.5,
          maxH: 3.5,
          moved: false,
          'static': false,
          i: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9'
        },
        {
          x: 0,
          y: 0,
          w: 6,
          h: 7,
          minW: 6,
          maxW: 12,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '4a88fe8d-414a-4ba8-b734-33b76fdf1137'
        },
        {
          x: 0,
          y: 0,
          w: 4,
          h: 7,
          minW: 1,
          maxW: 4,
          minH: 1,
          maxH: 7,
          moved: false,
          'static': false,
          i: '56eeb26b-8490-4603-8b91-0ec4730219bf'
        },
        {
          x: 0,
          y: 0,
          w: 12,
          h: 8,
          minW: 6,
          maxW: 12,
          minH: 6,
          maxH: 12,
          moved: false,
          'static': false,
          i: '8cb94609-fec5-4e86-9e1a-f3aa48076f02'
        },
        {
          x: 0,
          y: 0,
          w: 4,
          h: 7,
          minW: 4,
          maxW: 4,
          minH: 7,
          maxH: 7,
          moved: false,
          'static': false,
          i: '1bc0c2fe-6a4b-4e2f-b058-1467bd0f0a88'
        }
      ]
    },
    data: {
      '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25': {
        openTickets: false,
        closedTickets: false,
        p2Tickets: true
      },
      'baf3dc3d-f8ef-4b86-a1e1-3712477450e9': {
        p1Tickets: true
      },
      'dbbae34f-2085-47f2-903b-90cae4af612e': {
        p3Tickets: false,
        openTickets: true
      }
    },
    widgets: [
      {
        id: '9c0bf7e3-63bc-495d-8b8c-3b23d104cb25',
        addon: 'personTickets',
        version: 9
      },
      {
        id: 'dbbae34f-2085-47f2-903b-90cae4af612e',
        addon: 'personTickets',
        version: 9
      },
      {
        id: 'baf3dc3d-f8ef-4b86-a1e1-3712477450e9',
        addon: 'personTickets',
        version: 9
      },
      {
        id: '4a88fe8d-414a-4ba8-b734-33b76fdf1137',
        addon: 'releaseNotes',
        version: 12
      },
      {
        id: '56eeb26b-8490-4603-8b91-0ec4730219bf',
        addon: 'quickLinks',
        version: 3
      },
      {
        id: '8cb94609-fec5-4e86-9e1a-f3aa48076f02',
        addon: 'TicketTable',
        version: 3
      },
      {
        id: '1bc0c2fe-6a4b-4e2f-b058-1467bd0f0a88',
        addon: 'social',
        version: 3
      }
    ],
    collapsed: {}
  }
}

export const widgets = createSlice({
  name: "widgets",
  initialState: Users,
  reducers: {
    setGmAgentWidgets: (state) => {
      return GMorAgent
    },
    setUsersWidgets: (state) => {
      return Users
    },
    setUsersNoDataWidgets: (state) => {
      return UsersNoData
    },
    exportWidgetLayout: (state, action) => {
      const page = action.payload;
      if (state[page]?.data?.length > 0) {
        console.log(state[page]);
        let json = JSON.stringify(state[page]);
        let b64 = base64.encode(json);
        navigator.clipboard.writeText(b64);
      }
    },
    exportWidget: (state, action) => {
      const widget = action.payload;
      let json = JSON.stringify(widget);
      let b64 = base64.encode(json);
      // console.log(b64);
      navigator.clipboard.writeText(b64);
    },
    collapseWidget: (state, action) => {
      const { page, widget, breakpoint } = action.payload;

      state[page].data.forEach((el, index) => {
        if (widget.id === el.id) {
          const collapse = !state[page].data[index].collapsed[breakpoint];
          state[page].data[index].collapsed[breakpoint] = collapse;
          Object.keys(state[page].layouts).forEach((keyName) => {
            state[page].layouts?.[keyName]?.forEach((el) => {
              if (el.i === widget.id) {
                if (collapse && keyName === breakpoint) {
                  state[page].data[index].layouts[keyName] = { ...el };
                  el.h = 1;
                  el.isResizable = false;
                  el.resizeHandles = [];
                }
                if (!collapse && keyName === breakpoint) {
                  el.h = state[page].data[index].layouts[keyName].h;
                  el.isResizable = true;
                  delete el.resizeHandles;
                }
              }
            });
          });
        }
      });
    },
    importWidgetLayout: (state, action) => {
      const { page, data } = action.payload;
      try {
        let b64 = base64.decode(data);
        let array = JSON.parse(b64);

        if (array.data && array.layouts) {
          state[page] = array;
        } else {
          throw new Error("not valid");
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    importWidget: (state, action) => {
      const { page, widget, count } = action.payload;
      try {
        let b64 = base64.decode(widget);
        let object = JSON.parse(b64);
        object.id = count;
        Object.keys(object.layouts).map((keyName) => {
          object.layouts[keyName].i = count;
          return null;
        });
        if (object.id) {
          if (!state[page]) {
            state[page] = { data: null, loading: null };
            state[page].data = [object];
            state[page].layouts = {};
          } else {
            state[page].data.push(object);
            if (!state[page].layouts) {
              state[page].layouts = {};
            }
          }
          Object.keys(object.layouts).map((keyName) => {
            if (!state[page].layouts?.[keyName]) {
              state[page].layouts[keyName] = [];
            }
            state[page].layouts[keyName].push(object.layouts[keyName]);
            return null;
          });
        } else {
          throw new Error("not valid");
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    updateWidgetPageLayout: (state, action) => {
      const { page, layouts } = action.payload;
      if (state[page]?.layouts) {
        state[page].layouts = layouts;
      }
    },
    changeWidgetTransparent: (state, action) => {
      const { page, transparent, index } = action.payload;
      state[page].data[parseInt(index - 1)].transparent = transparent;
    },
    resetWidget: (state, action) => {
      const { page, widget, layouts } = action.payload;
      state[page].data = widget;
      state[page].layouts = layouts;
    },
    removeWidget: (state, action) => {
      const { page, id } = action.payload;
      state[page].data = state[page].data.filter((el) => el.id !== id);
      Object.keys(state[page].layouts).forEach((keyName) => {
        state[page].layouts[keyName] = state[page].layouts?.[keyName]?.filter(
          (el) => el.i !== id.toString()
        );
      });
    },
    updateWidget: (state, action) => {
      const { page, widget, index } = action.payload;
      state[page].data[index].data = widget;
    },
    addWidget: (state, action) => {
      const { page, widget } = action.payload;
      if (!state[page]) {
        state[page] = { data: null, loading: null };
        state[page].data = [widget];
        state[page].layouts = {};
      } else {
        state[page].data.push(widget);
        if (!state[page].layouts) {
          state[page].layouts = {};
        }
      }
      Object.keys(widget.layouts).map((keyName) => {
        if (!state[page].layouts?.[keyName]) {
          state[page].layouts[keyName] = [];
        }
        state[page].layouts[keyName].push(widget.layouts[keyName]);
        return null;
      });
    },
    saveWidgetLayout: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});


export const {
  collapseWidget,
  exportWidgetLayout,
  importWidgetLayout,
  exportWidget,
  importWidget,
  updateWidgetPageLayout,
  changeWidgetTransparent,
  resetWidget,
  removeWidget,
  updateWidget,
  addWidget,
  saveWidgetLayout,
  setGmAgentWidgets,
  setUsersWidgets,
  setUsersNoDataWidgets
} = widgets.actions;

export default widgets.reducer;

