const pages = {
  'dashboard.pageTitle': 'Armaturenbrett',
  'dashboard.greeting.morning': 'Guten Morgen',
  'dashboard.greeting.afternoon': 'Guten Nachmittag',
  'dashboard.greeting.evening': 'Guten Abend',
  'dashboard.welcomeMessage': 'Sie haben {tickets} aktive Tickets und {messages} Nachrichten zu beantworten.',
  'notAuthorizedPage.title': 'Nicht autorisiert',
  'notAuthorizedPage.desc': 'Sie sind nicht berechtigt, auf diese Seite zuzugreifen.',
  'dashboard.welcomeText': 'Willkommen bei {ssg}!',
  'dashboard.button.addWidget': 'Widget hinzufügen',
  'dashboard.button.close': 'Schließen',
  'dashboard.button.edit': 'Bearbeiten',
  'dashboard.button.save': 'Speichern',
  'settingsPage.pageTitle': 'Einstellungen',
  'ticketViewPage.priority': 'Priorität',
  'ticketViewPage.assignee': 'Zuständig',
  'ticketViewPage.team': 'Team',
  'ticketViewPage.sixStarTeam': 'Zusätzliche Six Star Teammitglieder',
  'ticketViewPage.resourcesOnTicket': 'Ressourcen zum Ticket',
  'ticketViewPage.followers': 'Follower',
  'ticketViewPage.tags': 'Schlagwörter',
  'ticketViewPage.takeIt': 'übernehmen',
  'ticketViewPage.notes': 'Notizen',
  'ticketViewPage.activity': 'Aktivität',
  'ticketViewPage.timeline': 'Zeitleiste',
  'ticketViewPage.tasks': 'Aufgaben',
  'ticketViewPage.search': 'Suchen',
  'ticketViewPage.noteAddedBy': 'Notiz hinzugefügt von',
  'ticketViewPage.moreButton': 'Mehr',
  'ticketViewPage.makeInternal': 'Intern machen',
  'ticketViewPage.makePublic': 'Öffentlich machen',
  'ticketViewPage.deleteComment': 'Löschen',
  'ticketViewPage.reportComment': 'Melden',
  'ticketViewPage.internalTicketComment': 'Dies ist ein interner Kommentar',
  'ticketViewPage.publicTicketComment': 'Dies ist ein öffentlicher Kommentar',
  'ticketViewPage.ticketSource.internal_web': 'Internes Web',
  'ticketViewPage.ticketSource.external_email': 'Interne E-Mail',
  'ticketViewPage.followTicket': 'Ticket folgen',
  'ticketViewPage.unfollowTicket': 'Ticket nicht mehr folgen',
  'ticketViewPage.tasks.priority.completed': 'Abgeschlossen',
  'ticketViewPage.tasks.priority.in_progress': 'In Bearbeitung',
  'ticketViewPage.tasks.priority.not_started': 'Noch nicht begonnen',
  'ticketViewPage.tasks.priority.low': 'Niedrig',
  'ticketViewPage.tasks.priority.medium': 'Mittel',
  'ticketViewPage.tasks.priority.important': 'Wichtig',
  'ticketViewPage.tasks.priority.urgent': 'Dringend',
  'ticketViewPage.tasks.assignedTo': 'Zugewiesen an',
  'ticketViewPage.tasks.completedBy': 'Abgeschlossen von',
  'ticketViewPage.requester': 'Anforderer',
  'ticketViewPage.organization': 'Organisation',
  'ticketViewPage.button.cancel': 'Abbrechen',
  'ticketViewPage.button.close': 'Schließen',
  'ticketViewPage.downloadEml': 'EML herunterladen',
  'newRequestPage.header': 'Neue Anfrage',
  'newRequestPage.button.internalRequest': 'Interne Anfrage',
  'newRequestPage.button.serviceRequest': 'Serviceanfrage',
  'newRequestPage.button.procurementRequest': 'Beschaffungsanfrage',
  'newRequestPage.card.urgentHelp': 'Brauchen Sie dringend Hilfe?',
  'newRequestPage.button.callUs': 'Rufen Sie uns an',
  'newRequestPage.button.passwordReset': 'Passwort zurücksetzen für Domain/Computer',
  'newRequestPage.button.newRequest': 'Supportanfrage',
  'formPage.title.supportRequest': 'Supportanfrage',
  'formPage.title.newStarterRequest': 'Anfrage für Neuanfänger',
  'formPage.title.serviceRequest': 'Serviceanfrage',
  'formPage.title.newStarter': 'Neuanfänger / Rollenwechsel',
  'formPage.title.leaver': 'Ausscheider',
  'formPage.title.engineerVisit': 'Technikerbesuch',
  'formPage.title.procurementRequest': 'Beschaffungsanfrage',
  'formPage.title.developmentRequest': 'Entwicklungsanfrage',
  'formPage.title.internalRequest': 'Interne Anfrage',
  'ldapPasswordResetPage.button.submit': 'Passwort zurücksetzen',
  'ldapPasswordResetPage.button.generatePassword': 'Passwort generieren',
  'ldapPasswordResetPage.title': 'Domain/Computer Passwort zurücksetzen',
  'ldapPasswordResetPage.paragraph.intro':
    'Setzen Sie Ihr Computer-Domain-Passwort zurück. Abhängig von der Policy Ihres Hotels müssen Sie Ihr Passwort vielleicht alle 90 Tage ändern.',
  'licencePage.title': 'Lizenzen',
  'licencePage.office365Licences': 'Office 365 Lizenzen',
  'licencePage.license': 'Lizenz',
  'licencePage.active': 'Aktiv',
  'licencePage.consumed': 'Verbraucht',
  'licencePage.unused': 'Unbenutzt',
  'assetPage.title': 'Vermögenswerte',
  'resourceTracker.title': 'Ressourcenverfolgung',
  'ticketViewPage.overview': 'Übersicht',
  'ticketViewPage.roles': 'Rollen',
  'ticketViewPage.addContact': 'Kontakt hinzufügen',
  'ticketViewPage.status': 'Status',
  'ticketViewPage.createdAt': 'Erstellt am',
  'ticketViewPage.updatedAt': 'Aktualisiert am',
  'ticketViewPage.devices': 'Geräte',
  'ticketViewPage.contacts': 'Kontakte',
  'ticketViewPage.documents': 'Dokumente',
  'ticketViewPage.addNote': 'Notiz hinzufügen',
  'ticketViewPage.info': 'Info',
  'ticketViewPage.created': 'Erstellt',
  'ticketViewPage.lastActivity': 'Letzte Aktivität',
  'ticketViewPage.priorityLevel': 'Prioritätsstufe',
  'ticketViewPage.assigned': 'Zugewiesen',
  'ticketViewPage.ticketSLA': 'Ticket SLA',
  'ticketViewPage.firstResponse': 'Erste Antwort',
  'ticketViewPage.resolution': 'Auflösung',
  'ticketViewPage.nextUpdate': 'Nächstes Update',
  'ticketViewPage.mostRecentMessages': 'Letzte Nachricht',
  'organizationViewPage.overview': 'Übersicht',
  'organizationViewPage.activity': 'Aktivität',
  'assetPage.name': 'Name',
  'assetPage.type': 'Typ',
  'assetPage.status': 'Status',
  'assetPage.serial': 'Seriennummer',
  'assetPage.manufacturer': 'Hersteller',
  'assetPage.cpu': 'CPU',
  'assetPage.memory': 'Speicher',
  'assetPage.lastUser': 'Zuletzt gesehen',
  'assetPage.lastSeen': 'Zuletzt gesehen am',
  'assetPage.operatingSystem': 'Betriebssystem',
  'sixStarMeet.header': 'Die Technologie hinter jedem großartigen Meeting',

  'ticketViewPage.approvedAt': 'Genehmigt am',
  'ticketViewPage.rejectedAt': 'Abgelehnt am',
  'ticketViewPage.approvedBy': 'Genehmigt von',
  'ticketViewPage.rejectedBy': 'Abgelehnt von',
  'ticketViewPage.approvalStatus': 'Genehmigungsstatus',
  'ticketViewPage.approvalStatus.approved': 'Genehmigt',
  'ticketViewPage.approvalStatus.rejected': 'Abgelehnt',
  'ticketViewPage.approvalStatus.pending': 'Ausstehend',
  'ticketViewPage.requestedBy': 'Angefordert von',

  // Ersteinrichtungsseite
  'firstTimeSetupPage.firstLookAtManage': 'Erster Blick auf Manage 3.0',
  'firstTimeSetupPage.elevatingYourITManagementExperience': 'Erhebung Ihrer IT-Management-Erfahrung',
  'firstTimeSetupPage.weAreThrilledToPresent':
    'Wir freuen uns, Ihnen einen Einblick in das Beta-Kapitel Ihres IT-Management-Weges mit Six Star: Manage 3.0 zu präsentieren.',
  'firstTimeSetupPage.asAValuedMember':
    'Als geschätzte Mitglieder der Six Star Global-Familie, die bereits von unseren Lösungen profitieren, freuen wir uns, Ihnen eine modifizierte und verbesserte Erfahrung vorstellen zu können, die Ihr IT-Management dynamischer, effizienter und intuitiver gestaltet.',
  'firstTimeSetupPage.aNewChapterInITManagement': 'Ein neues Kapitel im IT-Management',
  'firstTimeSetupPage.technologicalTakeOver': 'Ein technologisches Makeover:',
  'firstTimeSetupPage.redesign':
    'Ein modernes Redesign, das Geschwindigkeit und Stabilität berücksichtigt. Die Leistung steht bei uns an erster Stelle, daher haben wir sicherstellen, dass Sie Ihren IT-Portfolio jederzeit und überall, ob mobil oder am Desktop, griffbereit haben.',
  'firstTimeSetupPage.effortlessLicenseOverview': 'Mühelose Lizenzübersicht:',
  'firstTimeSetupPage.simplifyLicenceManagement':
    'Vereinfachen Sie die Lizenzverwaltung mühelos. Manage 3.0 ermöglicht es Ihnen, Software- und Benutzerlizenzen nahtlos zu überwachen, um die Einhaltung sicherzustellen und die Ausgaben zu optimieren.',
  'firstTimeSetupPage.smartReportGeneration': 'Intelligente Berichterstellung: ',
  'frstTimeSetupPage.harnessThePowerOfData':
    'Nutzen Sie die Kraft der Daten für strategische Entscheidungsfindung. Generieren Sie maßgeschneiderte Berichte mit Ticketstatistiken, um den Betrieb zu feinabstimmen und die Servicequalität in Ihrem Unternehmen zu verbessern.',
  'firstTimeSetupPage.tailoredDashboardAtYourFingertips': 'Maßgeschneidertes Dashboard auf einen Blick: ',
  'firstTimeSetupPage.craftDashboard':
    'Gestalten Sie ein personalisiertes IT-Dashboard mit einer Vielzahl von Widgets, um schnelle Einblicke in Ticketstatus und Trends zu erhalten, die Ihren spezifischen Interessen entsprechen.',
  'firstTimeSetupPage.benefitsTitle': 'Vorteile von Manage 3.0 für Ihre Hotelbetriebe',
  'firstTimeSetupPage.amplifiedEfficiency': 'Verstärkte Effizienz: ',
  'firstTimeSetupPage.guestExperience':
    'Fokussieren Sie sich auf die Bereitstellung herausragender Gästeerlebnisse mit automatisierten Prozessen, die Probleme nahtlos angehen.',
  'firstTimeSetupPage.realTimeIntelligence': 'Echtzeit-Intelligenz: ',
  'firstTimeSetupPage.instantlyAccess':
    'Greifen Sie sofort auf aktuelle Daten und Ticketstatistiken zu, um schnelle, informierte Entscheidungen zu treffen.',
  'firstTimeSetupPage.tailoredToYou': 'Auf Sie zugeschnitten: ',
  'firstTimeSetupPage.customiseDashboard':
    'Erstellen Sie ein individuelles IT-Dashboard mit einer Vielzahl von Widgets, um schnelle Einblicke in Ticketstatus und Trends zu erhalten, die Ihren spezifischen Interessen entsprechen.',
  'firstTimeSetupPage.futureProof': 'Zukunftssicherheit:',
  'firstTimeSetupPage.atThisIminentLaunch':
    'Da diese bevorstehende Einführung nur die Beta-Version ist, erwarten Sie regelmäßige Updates und innovative Funktionen, die mit den sich ändernden Anforderungen Ihrer IT-Infrastruktur Schritt halten werden.',
  'firstTimeSetUpPage.button.getStarted': 'Loslegen',

  // Laden
  'loading.title': 'Laden...',

  // Ticketansicht
  'ticketView.activityModule.title': 'Aktivität',
  'ticketView.emailModule.title': 'E-Mails',
  'ticketView.infoModule.subheaderOne': 'Support',
  'ticketView.infoModule.subheaderTwo': 'Technisches Team',
  'ticketView.infoModule.value 1': 'Option 1',
  'ticketView.infoModule.value 2': 'Option 2',
  'ticketView.infoModule.value 3': 'Option 3',
  'ticketView.infoModule.value 4': 'Option 4',
  'ticketView.notesModule.title': 'Notizen',
  'ticketView.tasksModule.title': 'Aufgaben',

  'ticketViewPage.modal.bundle.title': 'Tickets bündeln',
  'ticketViewPage.modal.bundle.parentTicket': 'Elternticket',
  'ticketViewPage.modal.bundle.childTicket': 'Kinderticket',
  'ticketViewPage.modal.bundle.button': 'Bündeln',
  'ticketViewPage.modal.bundle.button.cancel': 'Abbrechen',
  'companyContactPage.tabs.overview': 'Übersicht',
  'companyContactPage.tabs.activities': 'Aktivitäten',
  'companyContactPage.tabs.tasks': 'Aufgaben',
  'companyContactPage.tabs.overview.lastActivity': 'Letzte Aktivität',
  'companyContactPage.tabs.overview.customerStatus': 'Kundenstatus',
  'companyContactPage.tabs.overview.userCreated': 'Benutzer erstellt',
  'companyContactPage.tabs.overview.company': 'Unternehmen',
  'companyContactPage.tabs.overview.expiringLicences': 'Ablaufende Lizenzen',
  'companyContactPage.tabs.overview.opemTickets': 'Offene Tickets',
  'companyContactPage.tabs.overview.mostRecentActivity': 'Aktuellste Aktivität',
  'companyContactPage.tabs.overview.Drive': 'Drive',
  'companyContactPage.tabs.overview.lastActivity: ': 'Letzte Aktivität: ',
  'companyContactPage.tabs.search': 'Suchen',
  'companyContactPage.tabs.overview.AddedNote': 'Notiz hinzugefügt',
  'companyContactPage.tabs.sidePanel.contacts': 'Kontakte',
  'companyContactPage.tabs.sidePanel.other': 'Andere',
  'companyContactPage.tabs.sidePanel.licenses': 'Lizenzen',
  'companyContactPage.tabs.sidePanel.procurement': 'Beschaffung',
  'companyContactPage.tabs.sidePanel.assets': 'Vermögenswerte',
  'companyContactPage.tabs.sidePanel.invoices': 'Rechnungen',
  'companyContactPage.tabs.sidePanel.company': 'Unternehmen',
  'ticketViewPage.checklist': 'Checkliste',
  'ticketViewPage.attachments': 'Anlagen',
  'ticketViewPage.relatedItems': 'Verwandte Artikel',
  'sixStarTVPage.header': 'Führer in Funktionalität, Stabilität und Benutzerfreundlichkeit',
  'sixStarTVPage.integration': 'Integration',
  'sixStarTVPage.webBasedManagement': 'Webbasiertes Management',
  'sixStarTVPage.gaming': 'Spielen',
  'sixStarTVPage.fullyCustomisable': 'Vollständig anpassbar für ein gebrandetes Erlebnis',
  'sixStarTVPage.extraFeatures': 'Eine Vielzahl von zusätzlichen Funktionen',
  'sixStarTVPage.plus': 'mit Six Star: TV Plus',
  'sixStarTVPage.offer':
    'Six Star: TV Plus bietet eine ganze Reihe von Extras, um den Aufenthalt Ihrer Gäste noch wertvoller zu machen und gleichzeitig wichtige Zusatzverkäufe zu sichern.',
  'sixStarTVPage.wakeUp': 'Aufwachen',
  'sixStarTVPage.internationalViewing': 'Internationales Ansehen',
  'sixStarTVPage.roomService': 'Zimmerservice',
  'sixStar.GetInTouch': 'Kontakt aufnehmen',
  'sixStar.BackToTop': 'Zurück nach oben',
  'quickCreateTicketModal.title': 'Schnelle Ticketerstellung',
  'quickCreateTicketModal.button.submit': 'Einreichen',
  'quickCreateTicketModal.button.submitAndView': 'Einreichen und Ticket anzeigen',
  'quickCreateTicketModal.disclaimer': 'Dieses Ticket wird automatisch als mittlere Priorität und der Status als NEU gepostet',
  'quickCreateTicketModal.success': 'Ticket erfolgreich erstellt',
  'quickCreateTicketModal.button.close': 'Schließen',

  'contactUsPage.number.button': 'Hier klicken',
  'contactUsPage.title.second': 'Rufen Sie uns an',
  'contactUsPage.title.first': 'Hallo, wie können wir Ihnen helfen?',
  'contactUsPage.header.boxs.one': 'Wie melde ich ein Ticket?',
  'contactUsPage.header.boxs.two': 'Wie melde ich mich ab?',
  'contactUsPage.header.boxs.three': 'Wo sehe ich alle meine Tickets?',
  'contactUsPage.header.boxs.four': 'Wie füge ich Widgets hinzu?',
  'contactUsPage.search.answers': 'Suchen Sie nach Antworten...',
  'contactUsPage.search': 'Suchen Sie nach Antworten...',

  //sixstar black page
  // "sixStarBlackPage.header": "Erheben Sie globale Reisen und Präsidentensuiten",
  // "sixStarBlackPage.traveler": "Reisender",
  // "sixStarBlackPage.hotel": "Hotel",

  //sixstar vip page
  'sixStarVIPPage.header': 'Führungskräfte wählen schnelle Lösungen mit GM-Prioritätszugang',
  'sixStarVIPPage.directToSeniorSupport': 'Direkt zu Senior Support',
  'sixStarVIPPage.contactCards': 'Kontaktkarten',
  'sixStarVIPPage.reducedSLA': 'Reduzierte SLA für Führungskräfte',
  'sixStarVIPPage.contactCardsForGM': 'Kontaktkarten für GMs',

  //sixstar cast page
  'sixStarCastPage.header': 'Unterhaltung für Gäste von zu Hause',
  'sixStarCastPage.oneStepAuthentication': 'Ein-Schritt-Authentifizierung',
  'sixStarCastPage.streamCastableApp': 'Gäste können jede streambare App nutzen',
  'sixStarCastPage.CloudManaged': 'Cloud-gesteuert',

  // sixstar internet page
  'sixStarInternetPage.header': 'Erhöhen Sie die Konnektivität, senken Sie die Kosten und entfesseln Sie unübertroffene Unterstützung',
  'sixStarInternetPage.lowCost': 'Niedrige Kosten',
  'sixStarInternetPage.mobileConnectivity': 'Mobile Konnektivität',
  'sixStarInternetPage.installations': 'Installationen',

  // sixstar display page
  'sixStarDisplayPage.header': 'Räume verwandeln & Erlebnisse aufwerten',
  'sixStarDisplayPage.workWithAnyScreen': 'Funktioniert mit jedem Bildschirm',
  'sixStarDisplayPage.wayFinding': 'Wegfindung & Schnäppchenfindung',
  'sixStarDisplayPage.mobileWebEditor': 'Mobiler Web-Editor',

  'sixStarPlus.getInTouch': 'Kontakt aufnehmen',
  'sixStarTVPage.externalTVInputs': 'Externe TV-Eingänge',
  'sixStarTVPage.p2':
    'Nutzen Sie HDMI-Anschlüsse für Konsolen und andere Systeme sowie Apps wie AirPlay und Six Star: Cast, um Gästen das Streamen von ihren eigenen Geräten zu ermöglichen.',
  'sixStarTVPage.p3': 'Investitionskosten können durch die Nutzung vorhandener Infrastruktur reduziert werden',
  'sixStarTVPage.p4': 'Monitoring und Content-Management direkt über den Browser im Haus',
  'sixStarTVPage.p5': 'Eine stark steigende Anforderung für Gastgeber, die SS:TV mit Power erfüllen kann.',
  'sixStarTVPage.fullyCustomizable1080p':
    'Ein vollständig anpassbares 1080p-Menü ohne starre Designgrenzen. Beginnen Sie mit maßgeschneiderten Vorlagen für einen reibungslosen Start oder lassen Sie sich von SSG beim Design unterstützen.',
  'sixStarVoicePage.header': 'Entdecken Sie die Kraft von Six Star: Voice',
  'sixStarVoicePage.p1': 'Unvergleichliche Kommunikation',
  'sixStarVoicePage.advancedIntegrations': 'Fortgeschrittene Integrationen',
  'sixStarVoicePage.lineRedundancy': 'Leitungsredundanz',
  'sixStarVoicePage.customisableSolutions': 'Anpassbare Lösungen',
  'sixStarVoicePage.seamlessConnect': 'Verbinden Sie sich nahtlos mit Apps von Drittanbietern für vielseitige Systemfunktionalität.',
  'sixStarVoicePage.enjoy': 'Genießen Sie unterbrechungsfreie Kommunikation mit standardmäßiger Leitungsredundanz.',
  'sixStarVoicePage.tailor': 'Passen Sie Six Star: Voice an, um Ihre einzigartigen Gastgewerbebetriebe zu optimieren.',
  'sixStarVoicePage.p2': 'Nahtlose Kommunikation',
  'sixStarVoicePage.sayGoodbye':
    'Sagen Sie Tschüss zu Ausfallzeiten. Unser System ist mit Leitungsredundanz als Standardfunktion ausgestattet, um eine unterbrechungsfreie Kommunikation auch bei unerwarteten Herausforderungen zu gewährleisten. Wir haben auch dedizierte Teammitglieder für Telefonsupport bereit, falls Sie es benötigen.',
  'sixStarCastPage.low-Cost':
    'Kostengünstige Lösung für das Casting von Gästegeräten, die zu jedem HDMI-kompatiblen Fernseher hinzugefügt werden kann.',
  'sixStarCastPage.guestCan': 'Gäste können sofort auf ihre Unterhaltung zugreifen, sobald sie ihr Zimmer betreten.',
  'sixStarCastPage.netflix': 'Netflix, Hulu, Disney+ und viele mehr verfügbar.',
  'sixStarCastPage.easilyIntegrated': 'Problemlose Integration in bestehende TV-Lösungen',
  'sixStarCastPage.p1':
    'Verwenden Sie das Markenmarketing-Portal für die direkte Kommunikation mit Hotelgästen. Über das webbasierte Content-Management-System (CMS) können Hotelmarketingmitarbeiter Gäste über Hotelveranstaltungen und Annehmlichkeiten, Sonderangebote, Öffnungszeiten, Treueprogramme und vieles mehr informieren.',
  'sixStarDisplayPage.subHeader':
    'API-zentrisches System für Besprechungsraumdisplays, Werbung, Wegweisung, Fluganzeigetafel und Menüanzeigesystem im Gastgewerbe. Integrationen mit vielen Drittanbieterdiensten.',
  'sixtarDisplayPage.noFancy': 'Keine ausgefallene Hardware, um loszulegen, die einzige Hardware-Anforderung ist ein HDMI-Anschluss.',
  'sixStarDisplayPage.mutlipleGoals': 'Six Star: Display kann mehrere Ziele erreichen',
  'sixStarDisplayPage.plan': 'Planen Sie überall und zeigen Sie überall an, da alles in der Cloud verwaltet wird.',
  'sixStarDisplayPage.powerful': 'Leistungsstarke API-Konnektivität zieht die Aufmerksamkeit auf sich',
  'sixStarDisplayPage.p1':
    'Nutzen Sie Nachrichten, Wetter, Verkehr und Flugdaten, um Ihre Bildschirme in Echtzeit-Informationskraftwerke zu verwandeln. Mischen Sie API-Daten mit Ihrem Markeninhalt oder verwenden Sie API-Trigger, um Ihre Nachrichten automatisch zu ändern.',
  'sixStarParkPage.header': 'Parken verwalten wie Zimmer und Betten (inklusive Einnahmen)',
  'sixStarParkPage.customerSat': 'Kundenzufriedenheit',
  'sixStarParkPage.improve': 'Verbessern Sie das gesamte Parkerlebnis und maximieren Sie die Rentabilität',
  'sixStarParkPage.realTimeInformation': 'Echtzeitinformationen',
  'sixStarParkPage.provide': 'Bieten Sie Echtzeitinformationen zu Auslastung und Einnahmen über ein einziges Portal',
  'sixStarParkPage.geoMarketing': 'Geo-Marketing',
  'sixStarParkPage.drive': 'Fördern Sie In-Store-Promotionen für App-Benutzer und generieren Sie Fußgängerverkehr in Ihre Einrichtungen',
  'sixStarParkPage.extraFeatures': 'Zusätzliche Funktionen',
  'sixStarParkPage.p1':
    'Six Star: Park bietet so viele weitere Funktionen in Bezug auf soziale, wirtschaftliche und organisatorische Faktoren Ihres Parkanteils. Mit der Möglichkeit, EV-Ladegeräte in die Mischung aufzunehmen, Expresszahlungen, Vorabbuchungen und mobile Zahlungen, erhöhen Sie den direkten Umsatz und maximieren gleichzeitig das Gesamterlebnis für Gäste.',
  'sixStarParkPage.preparing': 'Vorbereitung für die Zukunft',
  'sixStarParkPage.p2':
    'Unser Hotel-Parking-Produkt bietet zwei fortschrittliche EV-Ladelösungen: TRONIQ 50, ein 50 kW DC-Ladegerät mit universeller Kompatibilität und Fernwartung, sowie DUAL BUSINESSLINE, ein 7,4 kW AC-Ladegerät, das zwei Autos gleichzeitig mit effizienter Leistungsverteilung durch Lastausgleich auflädt. Diese innovativen Lösungen verbessern das Gesamterlebnis für Gäste, indem sie schnelle, zuverlässige und bequeme Optionen für das Laden von Elektrofahrzeugen bieten.',

  'sixStarBlackPage.header': 'Erheben Sie globale Reisen und Präsidentensuiten',
  'sixStarBlackPage.subHeader':
    "Die Marke 'Six Star: Black' bietet zwei verschiedene Produkte, die auf prominente Persönlichkeiten und Luxusunterkünfte zugeschnitten sind, die die Bedürfnisse ihrer Gäste an die erste Stelle setzen.",
  'sixStarBlackPage.traveler': 'Reisender',
  'sixStarBlackPage.hotel': 'Hotel',
  'sixStarBlackPage.aService':
    'Ein Service für die Bewohner der Suiten der höchsten Stufe in Luxushotels, mit dem Ziel, das Gesamterlebnis der Gäste in diesen exklusiven Unterkünften zu verbessern.',
  'sixStarBlackPage.provides':
    'Bietet erstklassigen globalen IT-Support vor Ort, um die technologischen Bedürfnisse prominenter Personen unterwegs zu erfüllen.',
  'sixStarBlackPage.p1':
    'Erleben Sie erstklassigen Reisesupport mit Six Star: Black (Traveller). Unser erstklassiges globales IT-Team richtet sich an prominente Personen und ihre Familien und bietet nahtlose, personalisierte technische Unterstützung, egal wohin Ihre Abenteuer Sie führen - von Ersatzgeräten mit geringer Spannung bis zur Vorabkonfiguration direkt an Sie versandter Geräte.',
  'sixStarBlackPage.p2':
    'Integriert mit den bestehenden Angeboten des Hotels, um eine nahtlose und erhöhte Erfahrung für die Gäste zu schaffen. Wir bieten branchenführende Dienstleistungen, um den einzigartigen und erhöhten Erwartungen von Gästen auf Präsidenten- und Botschafterebene gerecht zu werden und einen wirklich luxuriösen Aufenthalt zu gewährleisten.',

  'sixStarVIPPage.subHeader': 'Premium-Support direkt für Senior Engineers und reduzierte SLAs für Ihr Führungsteam.',
  'sixStarVIPPage.whenYouCall':
    'Wenn Sie anrufen oder ein Ticket erstellen, garantieren wir Ihnen einen unserer Senior Engineers, um Ihnen bei Ihrem Anliegen zu helfen.',
  'sixStarVIPPage.onTheGo': 'On-The-Go-Kontaktkarten mit einer Direktnummer für Support.',
  'sixStarVIPPage.bigOrSmall': 'Unabhängig davon, ob das Problem groß oder klein ist, können wir eine niedrigere SLA-Zeit garantieren.',
  'sixStarVIPPage.plus':
    'Six Star: VIP Plus, die ultimative Supportstufe, die entwickelt wurde, um Ihre Erfahrung auf beispiellose Ebenen der Exzellenz zu heben. Mit VIP Plus erhalten Sie Zugang zu einem Bereich von Privilegien, die den Kundensupport neu definieren.',
  'sixStarVIPPage.instant': 'Sofortiger direkter Support',
  'sixStarVIPPage.dedicated': 'Dedizierte Supportnummer',
  'sixStarVIPPage.priority': "Priorität-SLA's",
  'sixStarVIPPage.withPlus': 'mit Six Star: VIP Plus',

  'sixStarInternetPage.subHeader':
    'Six Star: Internet kombiniert kostengünstige Datenkreise, umfassende mobile Konnektivität und SIP-Trunks mit kostenlosem Projektmanagement und robustem Support.',
  'sixStarInternetPage.leverage':
    'Wir nutzen strategische Partnerschaften, effiziente Infrastruktur und Technologielösungen, um Kosteneinsparungen für unsere Kunden zu erzielen.',
  'sixStarInternetPage.mobileEnhance':
    'Die mobile Konnektivität verbessert die Flexibilität und Produktivität für Unternehmen in einer zunehmend auf Mobilgeräten ausgerichteten Welt.',
  'sixStarInternetPage.inclusion':
    'Die Einbeziehung von Projektmanagement-Services bedeutet eine reibungslose und problemlose Einführung für Kunden.',
  'sixStarInternetPage.p1':
    'Six Star legt Wert auf Kosteneffizienz und bietet Datenkreise zu den niedrigstmöglichen Preisen an. Diese Funktion wurde entwickelt, um Unternehmen durch Minimierung der mit der Internetverbindung verbundenen Ausgaben einen Wettbewerbsvorteil zu verschaffen. Durch das Angebot erschwinglicher Datenkreise ermöglicht Six Star Unternehmen, ihre Ressourcen effizienter zuzuweisen und Wachstum und Nachhaltigkeit zu fördern.',
  'sixStarInternetPage.p2':
    'Six Star bietet nicht nur SIP (Session Initiation Protocol) Trunks für effiziente Sprachkommunikation an, sondern unterscheidet sich auch durch die Bereitstellung kostenloser Projektmanagement-Services. Dazu gehören Expertenberatung und Unterstützung während des Implementierungsprozesses. Nach der Implementierung steht der Service Desk von Six Star rund um die Uhr für Fragen oder Probleme zur Verfügung.',
  'sixStarInternetPage.projectManagement': 'Projektmanagement und Support',
  'sixStarInternetPage.maximize': 'Maximierung der Einsparungen mit Datenkreisen',

  'sixStarPrintPage.header': 'Müheloses Managed Print für nahtlose Produktivität',
  'sixStarPrintPage.subHeader': 'Smart Print, ohne Kapitaleinsatz: Sofortlösungen mit wettbewerbsfähigen Verbrauchsmaterialien',
  'sixStarPrintPage.printing': 'Kapitalfreies Drucken',
  'sixStarPrintPage.rapidResponse': 'Schnelle Reaktion',
  'sixStarPrintPage.costEffectiveConsumables': 'Kostengünstige Verbrauchsmaterialien',
  'sixStarPrintPage.providing': 'Bereitstellung einer Managed Print-Lösung ohne Vorabkosten.',
  'sixStarPrintPage.enjoy': 'Genießen Sie eine Unterstützung vor Ort am selben Tag/am nächsten Tag für einen unterbrechungsfreien Druckbetrieb.',
  'sixStarPrintPage.save': 'Sparen Sie durch wettbewerbsfähige Preise für Druckverbrauchsmaterialien von Six Star Global.',
  'sixStarPrintPage.hassle': 'Drucken ohne Aufwand',
  'sixStarPrintPage.unrivaled': 'Unvergleichliche Betriebszeitversicherung',
  'sixStarPrintPage.p1':
    'Genießen Sie das Drucken ohne finanzielle Belastung. Die Managed Print-Lösung von Six Star Global befreit Sie von Vorabkosten und ermöglicht einen effizienten Druckzugang ohne Kapitalinvestition.',
  'sixStarPrintPage.p2':
    'Verbessern Sie Ihre Druckerfahrung mit Six Star Global. Profitieren Sie von schneller, am selben Tag/am nächsten Tag vor Ort Unterstützung und gewährleisten Sie einen reibungslosen Ablauf Ihrer Druckvorgänge ohne Unterbrechungen.',

  'sixStarGuestPage.header': 'Speziell für Lobby-Gast-PCs oder Business-Center konzipiert',
  'sixStarGuestPage.subHeader':
    'Verbessern Sie Ihr Gästeerlebnis und optimieren Sie Ihre Geschäftsvorgänge mit unserer hochmodernen für Gäste zugänglichen Technologie.',
  'sixStarGuestPage.secure': 'Sichere Umgebung',
  'sixStarGuestPage.fully': 'Voll lizenziert',
  'sixStarGuestPage.custom': 'Benutzerdefinierte Lösungen',
  'sixStarGuestPage.B2B': 'B2B- und Guest-Facing-Support',
  'sixStarGuestPage.p1':
    'Verbessern Sie Ihr Business-Center mit unseren robusten B2B-Supportdiensten und verbessern Sie das Gästeerlebnis mit zuverlässigen und benutzerfreundlichen Arbeitsstationen für den öffentlichen Gebrauch. Wir bieten maßgeschneiderte IT-Lösungen für Firmenkunden und schaffen eine problemlose Umgebung für Gäste, egal ob sie Internetzugang, Dokumentendruck oder andere Computeranforderungen benötigen.',
  'sixSTarGuestPage.customised': 'Maßgeschneiderte Lösungen für jede Umgebung',
  'sixStarGuestPage.p2':
    'Da jede Gastgewerbe-Einrichtung einzigartig ist, bieten wir anpassbare Lösungen, um Ihren spezifischen Anforderungen gerecht zu werden. Vom Branding bis zur Softwarekonfiguration passen wir unsere Dienstleistungen an Ihre Geschäftsziele an, während Sie mit unserem 24/7-Support die Sicherheit haben.',

  'sixStarPayPage.header': 'Heben Sie Ihre Gastfreundschaft hervor, vereinfachen Sie Ihre Transaktionen',
  'sixStarPayPage.subHeader': 'Optimierung von Gastfreundschaftsexzellenz, eine Transaktion nach der anderen',
  'sixStarPayPage.flexible': 'Flexible Zahlung',
  'sixStarPayPage.variousPayment': 'Wir unterstützen verschiedene Zahlungsoptionen. Gäste können so bezahlen, wie sie möchten.',
  'sixStarPayPage.roomCharging': 'Room Charging',
  'sixStarPayPage.orderRouting': 'Einfache Bestellweiterleitung, Gäste können Artikel/Dienstleistungen auf ihr Zimmer buchen.',
  'sixStarPayPage.payAtTable': 'Pay-At-Table',
  'sixStarPayPage.ourPay': 'Unsere Pay-At-Table-Lösung stellt sicher, dass Ihre Speisekarte in Echtzeit auf allen Plattformen synchronisiert ist.',
  'sixStarPayPage.increasedEfficiency': 'Erhöhte Effizienz und Gästezufriedenheit',
  'sixStarPayPage.p1':
    'Sparen Sie Zeit und steigern Sie die Effizienz mit unserer synchronisierten Menüverwaltung und Bestellweiterleitung. Konzentrieren Sie sich auf die Bereitstellung eines außergewöhnlichen kulinarischen Erlebnisses ohne operative Probleme. Machen Sie diesen zufriedenen Kunden zu einem wiederkehrenden Kunden.',
  'sixStarPayPage.integration': 'Integration und Einrichtung leicht gemacht',
  'sixStarPayPage.p2':
    'Nahtlose Integration mit den meisten wichtigen PMS- und POS-Systemen bedeutet, dass Sie Ihre bevorzugten Tools weiterhin verwenden können, während Sie die zusätzlichen Vorteile von Six Star: Pay genießen.',

  'formLoader.BackButton': 'Zurück',
  'formLoader.forwardButton': 'Vorwärts',
  'formLoader.submitButton': 'Senden',
  'formLoader.reSubmitButton': 'Erneut senden',
  'dataTablePro.error': 'Fehler',
  'sixStarTV.design': 'Sprechen wir über Design',
  'ticketViewPage.button.bundle': 'Bündel',
  'ticketViewPage.button.goBack': 'Zurück',
  'ticketViewPage.ticket.summary': 'Ticket-Zusammenfassung: ',
  'ticketViewPage.formSummary': 'Formularzusammenfassung',
  'ticketViewPage.masterDetail.createdAt': 'Erstellt am:',
  'ticketViewPage.masterDetail.assignedMember': 'Zugewiesene Mitglieder',
  'ticketViewPage.masterDetail.ticketContacts': 'Ticket-Kontakte',
  'ticketViewPage.masterDetail.ticketCreatedAt': 'Ticket erstellt am',
  'ticketViewPage.masterDetail.mostRecentActivity': 'Aktuellste Aktivität',
  'ticketViewPage.masterDetail.ticketUpdatedBy': 'Ticket aktualisiert von',
  // "ticketViewPage.masterDetail.viewTicket": "Ticket anzeigen",
  // "ticketViewPage.masterDetail.quickNote": "Schnelle Notiz",
  'ticketViewPage.masterDetail.viewTicket': 'Ticket anzeigen',
  'ticketViewPage.masterDetail.quickNote': 'Schnelle Notiz',
  'ticketViewPage.carbonCopy': 'Durchschlag',
  'ticketViewPage.masterDetail.noRecentActivity': 'Keine aktuellen Aktivitäten',
  // "ticketViewPage.carbonCopyNotAvailable": "Keine CC verfügbar für dieses Ticket",
  "ticketViewPage.carbonCopyNotAvailable": "Keine CC verfügbar für dieses Ticket",
  "TicketViewPage.toolbar.quickClose.model.subTitle": "Möchten Sie {count} Tickets wirklich schnell lösen?",
  "TicketViewPage.toolbar.quickClose.model.title": "Tickets schnell lösen",
  "TicketViewPage.toolbar.label.quickClose": "Schnell lösen",
  "TicketViewPage.toolbar.quickClose.submitButton": "Schnell lösen",
  "TicketViewPage.toolbar.quickClose.cancelButton": "Abbrechen",
  "TicketViewPage.toolbar.quickClose.model.reason": "Bitte geben Sie einen kurzen Grund für das Schließen des Tickets ein, z.B. Unsinn",
  "TicketViewPage.toolbar.quickClose.model.reasonPlaceholder": "Grund",
  "TicketViewPage.toolbar.quickClose.model.finished": "Tickets wurden schnell gelöst",
  "TicketViewPage.toolbar.quickClose.model.updating": "Aktualisiere Tickets",
  "TicketViewPage.toolbar.quickClose.model.error": "Fehler beim Aktualisieren der Tickets",
  "TicketViewPage.toolbar.quickClose.closeButton": "Schließen",
  "menu.manage.helpSection.releaseNotes.tabText": "Versionshinweise",
  "menu.manage.helpSection.releaseNotes.menuText": "Versionshinweise",
  "releaseNotesPage.title": "Versionshinweise",
  "notFoundPage.pageDevelopment": "In Entwicklung",
  "notFoundPage.pageDevelopmentMessage": "Entschuldigung, dieser Abschnitt befindet sich derzeit in Entwicklung. Bitte kommen Sie später zurück, um spannende Updates von unserem Entwicklungsteam zu erhalten",
  "notFoundPage.button.dashboard": "Dashboard",
  "notFoundPage.button.ticket": "Meine Tickets",
  "ticketViewPage.modal.bundle.childTicketField": "Nach Unter-Tickets suchen",
  "TicketViewPage.toolbar.saveView": "Ansicht speichern",
  "TicketViewPage.toolbar.saveViewAs": "Ansicht speichern als",
  "TicketViewPage.toolbar.saveViewAsDuplicate": "Ansicht als Duplikat speichern",
  "TicketViewPage.toolbar.modal.areYouSure": "Sind Sie sicher?",
  "TicketViewPage.toolbar.modal.yes": "Ja",
  "TicketViewPage.toolbar.modal.no": "Nein",
  "ticketViewPage.childTickets": "Untertickets",
  "ticketViewPage.parent": "Elternticket",
  "ticketViewPage.ticketSummary": "Ticket-Zusammenfassung",
  "ticketViewPage.ticketNumber": "Ticketnummer",
  "TicketViewPage.toolbar.label.filters": "Filter",
  "TicketViewPage.toolbar.label.addContact": "Kontakt hinzufügen",
  "ticketViewPage.addNote": "Notiz hinzufügen",
  "ticketViewPage.ticketUpdate": "Ticket-Aktualisierung",
  "ticketViewPage.infoRequest": "Informationsanfrage",
  "ticketViewPage.approval": "Genehmigung",
  "ticketViewPage.requestForAction": "Handlungsaufforderung",
  "ticketViewPage.resolution": "Lösung",
  "ticketviewPage.tab.overview": "Überblick",
  "ticketviewPage.tab.timeline": "Zeitstrahl",
  "ticketviewPage.tab.formSummary": "Formularzusammenfassung",
  "ticketviewPage.tab.notes": "Notizen",
  "ticketviewPage.tab.tasks": "Aufgaben",
  "ticketviewPage.tab.activity": "Aktivität",
  "ticketViewPage.createdAt": "Erstellt am",
  "ticketViewPage.lastUpdate": "Letztes Update",
  "ticketViewPage.priority": "Priorität",
  "ticketViewPage.ticketSLA": "Ticket-SLA",
  "ticketViewPage.requester": "Antragsteller",
  "ticketViewPage.ticketOwner": "Ticketinhaber",
  "ticketViewPage.ticketStatus": "Ticketstatus:",
  "ticketViewPage.ticketSummary": "Ticket-Zusammenfassung",
  "ticketViewPage.ticketNumber": "Ticketnummer: #",
  "ticketViewPage.podAccess": "Pod-Zugriff",
  "ticketViewPage.podAccessDesc": "Dieser Pod bietet Ihnen schnellen Zugriff auf verschiedene Bereiche des Tickets",
  "ticketViewPage.addContact": "Kontakt hinzufügen",
  "ticketViewPage.editType": "Typ bearbeiten",
  "ticketViewPage.assigneeOrRequester": "Bearbeiter/Antragsteller aktualisieren",
  "ticketViewPage.contacts": "Kontakte",
  "ticketViewPage.contactDecs": "Dieser Pod zeigt alle Kontakte zu diesem Ticket an",
  "menu.manage.internalSection.forms.tabText": "Formulare",
  "menu.manage.internalSection.forms.menuText": "Formulare",
  "ticketViewPage.toolbar.label.quickEdit": "Schnellbearbeitung",
  "ticketViewPage.toolbar.editTicket.submitButton": "Einreichen",
  "datatableViews.toolbar.currentView": "Aktuelle Ansicht",
  "datatableViews.toolbar.loading": "Laden...",
  "ticketviewPage.tab.overview": "Überblick",
  "ticketviewPage.tab.timeline": "Zeitstrahl",
  "ticketviewPage.tab.notes": "Notizen",
  "ticketviewPage.tab.tasks": "Aufgaben",
  "ticketviewPage.tab.formSummary": "Formularzusammenfassung",
  "ticketviewPage.tab.activity": "Aktivität",
  "ticketViewPage.timeline.ticketCreated": "Ticket erstellt",
  "ticketViewPage.timeline.ticketUpdated": "Ticket aktualisiert",
  "ticketViewPage.timeline.messageAdded": "Nachricht hinzugefügt",
  "ticketViewPage.timeline.titleChange": "Titeländerung",
  "ticketViewPage.organisations": "Organisationen",
  "ticketViewPage.ticketDevices": "Ticket-Geräte",
  "ticketViewPage.ticketCC": "Ticket-CC",
  "menu.manage.companyContact.tabText": "Unternehmen",
  "menu.manage.companyContact.menuText": "Unternehmen",
  "ticketViewPage.rateYourService": "Bewerten Sie Ihren Service",
  "ticketViewPage.relatedTickets.desc": "Dieser Pod zeigt alle verwandten Tickets an",
  "ticketViewPage.ticketCC.desc": "Dieser Pod zeigt alle verwandten Kopien an",
  "ticketViewPage.ticketDevices.desc": "Dieser Pod zeigt alle verwandten Geräte an",
  "ticketViewPage.ticketSLA.desc": "Dieser Pod zeigt das SLA dieses Tickets an",
  "ticketViewPage.documents.desc": "Dieser Pod zeigt alle Dokumente an, die mit diesem Ticket verbunden sind",
  "ticketViewPage.organisations.desc": "Dieser Pod zeigt alle Organisationen an, die mit diesem Ticket verbunden sind",
  "ticketViewPage.initialResponse": "Erste Antwort",
  "ticketViewPage.nextUpdate": "Nächstes Update",
  "ticketViewPage.estimatedResolution": "Geschätzte Lösung",
  "ticketViewPage.resolution": "Lösung",
  "ticketViewPage.relatedTickets": "Verwandte Tickets",
  "ticketViewPage.bundleTicket": "Ticket bündeln",
  "ticketViewPage.shareTicket": "Ticket teilen",
  "ticketViewPage.contact": "Kontakt",
  "ticketViewPage.email": "E-Mail",
  "ticketViewPage.rateYourServiceComment": "Sie können Ihren Service jederzeit während Ihrer Sitzung mit Six Star Global bewerten.",
  "ticketViewPage.responseHistory": "Antwortverlauf",
  "ticketViewPage.initialComment": "Erster Kommentar",
  "ticketViewPage.agentResponse": "Antwort des Agenten",
  "ticketVIewPage.notClosed": "Nicht geschlossen",
  "ticketViewPage.created": "Erstellt am",
  "ticketViewPage.firstResponse": "Erste Antwort",
  "ticketViewPage.estimatedResolution": "Geschätzte Lösung",
  "ticketViewPage.actualResolution": "Tatsächliche Lösung",
  "createOrg.title": "Organisation erstellen",
  "ticketViewPage.addManager": "Account Manager hinzufügen",
  "editManager.addNew": "Neu hinzufügen",
  "editManager.currentManagers": "Aktuelle Manager",
  "companyContactPage.tabs.overview.region": "Region",
  "ticketViewPage.ratings": "Servicebewertungen",
  "ticketViewPage.ratingsdesc": "Dieses Pod zeigt alle Bewertungen, die für dieses Ticket abgegeben wurden.",
  "menu.manage.userSection.createOrganization.tabText": "Organisation erstellen",
  "menu.manage.userSection.createOrganization.menuText": "Organisation erstellen",
  "companyPage.info": "Firmeninformationen",
  "companyPage.AccountManager": "Account Manager",
  "companyPage.location": "Standort",
  "companyPage.relatedOrganizations": "Verwandte Organisationen",
  "companyPage.parentOrganization": "Mutterorganisation",
  "companyPage.childOrganizations": "Tochterorganisationen",
  "companyPage.noParentOrganization": "Keine Mutterorganisation",
  "companyPage.noChildOrganizations": "Keine Tochterorganisationen",
  "ticketViewPage.infoDesc": "Dieses Pod zeigt alle Informationen zu diesem Ticket an",
  "ticketViewPage.ticketInfo": "Ticket-Info",
  "ticketViewPage.timeline.priorityChange": "Prioritätsänderung",
  "ticketViewPage.timeline.statusChange": "Statusänderung",
  "ticketViewPage.timeline.ticketResolved": "Ticket Gelöst",
  'ticketViewPage.infoDesc': 'Dieses Modul zeigt alle Informationen zu diesem Ticket an',
  'ticketViewPage.ticketInfo': 'Ticket-Info',
  'ticketViewPage.timeline.priorityChange': 'Prioritätsänderung',
  'ticketViewPage.timeline.statusChange': 'Statusänderung',
  'ticketViewPage.timeline.ticketResolved': 'Ticket gelöst',
  'ticketViewPage.addSummary': 'Zusammenfassung hinzufügen',
  'ticketViewPage.notResponded': 'Keine Antwort',
  'ticketViewPage.noInitialComment': 'Kein erster Kommentar',
  'ticketViewPage.noAgentResponse': 'Keine Agentenantwort',
  'ticketViewPage.makePrivate': 'Privat machen',
  'ticketViewPage.makePublic': 'Öffentlich machen',
  'ticketViewPage.pod.team': 'Team',
  'ticketViewPage.team.desc': 'Dieses Modul zeigt alle Teammitglieder, die mit diesem Ticket in Verbindung stehen',
};

export default pages;
