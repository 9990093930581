const pages = {
  'dashboard.pageTitle': 'Dashboard',
  'notAuthorizedPage.title': 'Not Authorized',
  'notAuthorizedPage.desc': 'You are not authorized to access this page.',
  'dashboard.welcomeText': 'Welcome To {ssg}!',
  'app.html': 'Edit <code>{fileName}</code> js and save to reload. Now with {what}!',
  // "widgetsPage.tabText": "Widget Dashboard",
  // "widgetsPage.menuText": "Widget Dashboard",
  // "widgetPage.tabText": "Widgets",
  // "widgetPage.menuText": "Widgets",
  // "settings.menuText": "Settings",
  // "settings.tabText": "Settings",
  'settingsPage.pageTitle': 'Settings',
  'ticketViewPage.priority': 'Priority',
  'ticketViewPage.assignee': 'Assignee',
  'ticketViewPage.team': 'Team',
  'ticketViewPage.sixStarTeam': 'Membres additionnels de l’équipe Six Star',
  'ticketViewPage.resourcesOnTicket': 'Resources On Ticket',
  'ticketViewPage.followers': 'Followers',
  'ticketViewPage.tags': 'Tags',
  'ticketViewPage.takeIt': 'take it',
  'ticketViewPage.notes': 'Notes',
  'ticketViewPage.activity': 'Activity',
  'ticketViewPage.timeline': 'Timeline',
  'ticketViewPage.tasks': 'Tasks',
  'ticketViewPage.search': 'Search',
  'ticketViewPage.noteAddedBy': 'Note added by',
  'ticketViewPage.moreButton': 'More',
  'ticketViewPage.makeInternal': 'Make Internal',
  'ticketViewPage.makePublic': 'Make Public',
  'ticketViewPage.deleteComment': 'Delete',
  'ticketViewPage.reportComment': 'Report',
  'ticketViewPage.internalTicketComment': 'This is an internal comment',
  'ticketViewPage.publicTicketComment': 'This is a public comment',
  'ticketViewPage.ticketSource.internal_web': 'Internal Web',
  'ticketViewPage.ticketSource.external_email': 'Internal Email',
  'ticketViewPage.followTicket': 'Follow Ticket',
  'ticketViewPage.unfollowTicket': 'Unfollow Ticket',
  'ticketViewPage.tasks.priority.completed': 'Completed',
  'ticketViewPage.tasks.priority.in_progress': 'In Progress',
  'ticketViewPage.tasks.priority.not_started': 'Not Started',
  'ticketViewPage.tasks.priority.low': 'Low',
  'ticketViewPage.tasks.priority.medium': 'Medium',
  'ticketViewPage.tasks.priority.important': 'important',
  'ticketViewPage.tasks.priority.urgent': 'Urgent',
  'ticketViewPage.tasks.assignedTo': 'Assigned To',
  'ticketViewPage.tasks.completedBy': 'Completed By',
  'ticketViewPage.requester': 'Requester',
  'ticketViewPage.organization': 'Organization',
  'ticketViewPage.button.cancel': 'Cancel',
  'ticketViewPage.button.close': 'Close',
  'ticketViewPage.downloadEml': 'Download EML',
  'newRequestPage.header': 'New Request',
  'newRequestPage.button.internalRequest': 'Internal Request',
  'newRequestPage.button.serviceRequest': 'Service Request',
  'newRequestPage.button.procurementRequest': 'Procurement Request',
  'newRequestPage.card.urgentHelp': 'Need Urgent Help?',
  'newRequestPage.button.callUs': 'Call Us',
  'newRequestPage.button.passwordReset': 'Réinitialisation du mot de passe du Domaine/Ordinateur',
  'newRequestPage.button.newRequest': 'Support Request',
  'formPage.title.supportRequest': 'Support Request',
  'formPage.title.newStarterRequest': 'New Starter Request',
  'formPage.title.serviceRequest': 'Service Request',
  'formPage.title.newStarter': 'New Starter / Role Change',
  'formPage.title.leaver': 'Leaver',
  'formPage.title.engineerVisit': 'Engineer Visit',
  'formPage.title.procurementRequest': 'Procurement Request',
  'formPage.title.developmentRequest': 'Development Request',
  'formPage.title.internalRequest': 'Internal Request',
  'ldapPasswordResetPage.button.submit': 'Reset Password',
  'ldapPasswordResetPage.button.generatePassword': 'Generate Password',
  'ldapPasswordResetPage.title': 'Réinitialisation du mot de passe du Domaine/Ordinateur',
  'ldapPasswordResetPage.paragraph.intro':
    'Reset your computer domain password. Depending on your hotels policy you maybe required to change your password every 90 days.',
  'licencePage.title': 'Licences',
  'licencePage.office365Licences': 'Office 365 Licences',
  'licencePage.license': 'License',
  'licencePage.active': 'Active',
  'licencePage.consumed': 'Consumed',
  'licencePage.unused': 'Unused',
  'assetPage.title': 'Assets',
  'resourceTracker.title': 'Resource Tracker',
  'ticketViewPage.overview': 'Overview',
  'ticketViewPage.roles': 'Roles',
  'ticketViewPage.addContact': 'Add Contact',
  'organizationViewPage.overview': 'Overview',
  'organizationViewPage.activity': 'Activity',
  'assetPage.name': 'Name',
  'assetPage.type': 'Type',
  'assetPage.status': 'Status',
  'assetPage.serial': 'Serial',
  'assetPage.manufacturer': 'Manufacturer',
  'assetPage.cpu': 'CPU',
  'assetPage.memory': 'Memory',
  'assetPage.lastUser': 'Last Seen',
  'assetPage.lastSeen': 'Last Seen On',
  'assetPage.operatingSystem': 'Operating System',
  'sixStarMeet.header': 'The Technology Behind Every Great Meeting',

  'ticketViewPage.approvedAt': 'Approuvé le',
  'ticketViewPage.rejectedAt': 'Rejeté le',
  'ticketViewPage.approvedBy': 'Approuvé par',
  'ticketViewPage.rejectedBy': 'Rejeté par',
  'ticketViewPage.approvalStatus': "État d'approbation",
  'ticketViewPage.approvalStatus.approved': 'Approuvé',
  'ticketViewPage.approvalStatus.rejected': 'Rejeté',
  'ticketViewPage.approvalStatus.pending': 'En attente',
  'ticketViewPage.requestedBy': 'Demandé par',

  // First time set up page
  'firstTimeSetupPage.firstLookAtManage': 'Premier regard sur Manage 3.0',
  'firstTimeSetupPage.elevatingYourITManagementExperience': 'Élever votre expérience de gestion IT',
  'firstTimeSetupPage.weAreThrilledToPresent':
    "Nous sommes ravis de présenter l'aperçu d'un nouveau chapitre dans votre parcours de gestion IT avec la version bêta de Six Star: Manage 3.0.",
  'firstTimeSetupPage.asAValuedMember':
    'En tant que membres appréciés de la famille Six Star Global, qui bénéficient déjà de nos solutions, nous sommes ravis de vous présenter ce qui sera une expérience modifiée et améliorée signifiant que votre gestion IT est plus dynamique, rationalisée et intuitive.',
  'firstTimeSetupPage.aNewChapterInITManagement': 'Un nouveau chapitre dans la gestion IT',
  'firstTimeSetupPage.technologicalTakeOver': 'Un relooking technologique :',
  'firstTimeSetupPage.redesign':
    'Un redesign moderne prenant en compte la vitesse et la stabilité. La performance est notre principale préoccupation, nous nous sommes assurés que peu importe où vous êtes, sur mobile ou sur bureau, vous avez votre portfolio IT à portée de main.',
  'firstTimeSetupPage.effortlessLicenseOverview': 'Aperçu sans effort des licences :',
  'firstTimeSetupPage.simplifyLicenceManagement':
    'Simplifiez la gestion des licences sans effort. Manage 3.0 vous permet de superviser de manière transparente les licences logicielles et utilisateur, garantissant la conformité et optimisant les dépenses.',
  'firstTimeSetupPage.smartReportGeneration': 'Génération intelligente de rapports :',
  'frstTimeSetupPage.harnessThePowerOfData':
    'Exploitez la puissance des données pour la prise de décision stratégique. Générez des rapports personnalisés en utilisant les statistiques des tickets, vous aidant à affiner les opérations et à améliorer la qualité du service au sein de votre établissement.',
  'firstTimeSetupPage.tailoredDashboardAtYourFingertips': 'Tableau de bord personnalisé à portée de main :',
  'firstTimeSetupPage.craftDashboard':
    'Créez un tableau de bord IT personnalisé avec une variété de widgets, vous donnant des informations rapides sur les statuts des tickets et les tendances qui correspondent à vos intérêts spécifiques.',
  'firstTimeSetupPage.benefitsTitle': 'Avantages de Manage 3.0 pour vos opérations hôtelières',
  'firstTimeSetupPage.amplifiedEfficiency': 'Efficacité amplifiée :',
  'firstTimeSetupPage.guestExperience':
    "Concentrez-vous sur la fourniture d'expériences exceptionnelles pour les clients, avec des processus automatisés qui traitent les problèmes de manière transparente.",
  'firstTimeSetupPage.realTimeIntelligence': 'Intelligence en temps réel :',
  'firstTimeSetupPage.instantlyAccess':
    'Accédez instantanément à des données et statistiques sur les tickets à jour pour des décisions rapides et éclairées.',
  'firstTimeSetupPage.tailoredToYou': 'Sur mesure pour vous :',
  'firstTimeSetupPage.customiseDashboard':
    'Un tableau de bord personnalisé affiche précisément les informations dont vous avez besoin, offrant une vue complète de vos opérations IT.',
  'firstTimeSetupPage.futureProof': "Assurance pour l'avenir :",
  'firstTimeSetupPage.atThisIminentLaunch':
    "Comme ce lancement imminent n'est que la version bêta, attendez-vous à des mises à jour régulières et à des fonctionnalités innovantes qui suivront les besoins changeants de votre infrastructure IT.",
  'firstTimeSetUpPage.button.getStarted': 'Commencer',

  // loading
  'loading.title': 'Chargement...',

  // ticket view
  'ticketView.activityModule.title': 'Activité',
  'ticketView.emailModule.title': 'Emails',
  'ticketView.infoModule.subheaderOne': 'Support',
  'ticketView.infoModule.subheaderTwo': 'Équipe technique',
  'ticketView.infoModule.value 1': 'Option 1',
  'ticketView.infoModule.value 2': 'Option 2',
  'ticketView.infoModule.value 3': 'Option 3',
  'ticketView.infoModule.value 4': 'Option 4',
  'ticketView.notesModule.title': 'Notes',
  'ticketView.tasksModule.title': 'Tâches',

  'ticketViewPage.modal.bundle.title': 'Regrouper les tickets',
  'ticketViewPage.modal.bundle.parentTicket': 'Ticket parent',
  'ticketViewPage.modal.bundle.childTicket': 'Ticket enfant',
  'ticketViewPage.modal.bundle.button': 'Regrouper',
  'ticketViewPage.modal.bundle.button.cancel': 'Annuler',
  'companyContactPage.tabs.overview': "Vue d'ensemble",
  'companyContactPage.tabs.activities': 'Activités',
  'companyContactPage.tabs.tasks': 'Tâches',
  'companyContactPage.tabs.overview.lastActivity': 'Dernière activité',
  'companyContactPage.tabs.overview.customerStatus': 'Statut du client',
  'companyContactPage.tabs.overview.userCreated': 'Utilisateur créé',
  'companyContactPage.tabs.overview.company': 'Entreprise',
  'companyContactPage.tabs.overview.expiringLicences': 'Licences expirant',
  'companyContactPage.tabs.overview.opemTickets': 'Tickets ouverts',
  'companyContactPage.tabs.overview.mostRecentActivity': 'Activité la plus récente',
  'companyContactPage.tabs.overview.Drive': 'Drive',
  'companyContactPage.tabs.overview.lastActivity: ': 'Dernière activité : ',
  'companyContactPage.tabs.search': 'Recherche',
  'companyContactPage.tabs.overview.AddedNote': 'Note ajoutée',
  'companyContactPage.tabs.sidePanel.contacts': 'Contacts',
  'companyContactPage.tabs.sidePanel.other': 'Autre',
  'companyContactPage.tabs.sidePanel.licenses': 'Licences',
  'companyContactPage.tabs.sidePanel.procurement': 'Approvisionnement',
  'companyContactPage.tabs.sidePanel.assets': 'Actifs',
  'companyContactPage.tabs.sidePanel.invoices': 'Factures',
  'companyContactPage.tabs.sidePanel.company': 'Entreprise',
  'ticketViewPage.checklist': 'Liste de contrôle',
  'ticketViewPage.attachments': 'Pièces jointes',
  'ticketViewPage.relatedItems': 'Éléments associés',
  'sixStarTVPage.header': "Leaders en fonctionnalité, stabilité et facilité d'utilisation",
  'sixStarTVPage.integration': 'Intégration',
  'sixStarTVPage.webBasedManagement': 'Gestion basée sur le Web',
  'sixStarTVPage.gaming': 'Jeux',
  'sixStarTVPage.fullyCustomisable': 'Entièrement personnalisable pour une expérience de marque',
  'sixStarTVPage.extraFeatures': 'Un ensemble de fonctionnalités supplémentaires',
  'sixStarTVPage.plus': 'avec Six Star: TV Plus',
  'sixStarTVPage.offer':
    "Six Star: TV Plus offre toute une gamme d'extras pour rendre le séjour de vos invités encore plus précieux tout en sécurisant ces ventes supplémentaires si importantes.",
  'sixStarTVPage.wakeUp': 'Réveil',
  'sixStarTVPage.internationalViewing': 'Visionnement international',
  'sixStarTVPage.roomService': 'Service en chambre',
  'sixStar.GetInTouch': 'Contactez-nous',
  'sixStar.BackToTop': 'Retour en haut',
  'quickCreateTicketModal.title': 'Création rapide de ticket',
  'quickCreateTicketModal.button.submit': 'Soumettre',
  'quickCreateTicketModal.button.submitAndView': 'Soumettre et voir le ticket',
  'quickCreateTicketModal.disclaimer': 'Ce ticket sera automatiquement publié en tant que priorité moyenne et le statut en tant que NOUVEAU',
  'quickCreateTicketModal.success': 'Ticket créé avec succès',
  'quickCreateTicketModal.button.close': 'Fermer',

  //sixstar black page
  // "sixStarBlackPage.header": "Élever les voyages mondiaux et les suites présidentielles",
  // "sixStarBlackPage.traveler": "Voyageur",
  // "sixStarBlackPage.hotel": "Hôtel",

  //sixstar vip page
  'sixStarVIPPage.header': 'Choix exécutif pour des solutions rapides avec accès prioritaire pour les GM',
  'sixStarVIPPage.directToSeniorSupport': 'Accès direct au support senior',
  'sixStarVIPPage.contactCards': 'Cartes de contact',
  'sixStarVIPPage.reducedSLA': 'SLA réduite pour les cadres',
  'sixStarVIPPage.contactCardsForGM': 'Cartes de contact pour les GM',

  //sixstar cast page
  'sixStarCastPage.header': 'Divertissement pour les invités depuis chez eux',
  'sixStarCastPage.oneStepAuthentication': 'Authentification en un seul geste',
  'sixStarCastPage.streamCastableApp': "Les invités peuvent diffuser n'importe quelle application compatible",
  'sixStarCastPage.CloudManaged': 'Géré dans le cloud',

  // sixstar internet page
  'sixStarInternetPage.header': 'Élever la connectivité, réduire les coûts et libérer un support inégalé',
  'sixStarInternetPage.lowCost': 'Coût bas',
  'sixStarInternetPage.mobileConnectivity': 'Connectivité mobile',
  'sixStarInternetPage.installations': 'Installations',

  // sixstar display page
  'sixStarDisplayPage.header': 'Transformer les espaces et élever les expériences',
  'sixStarDisplayPage.workWithAnyScreen': "Fonctionne avec n'importe quel écran",
  'sixStarDisplayPage.wayFinding': "Signalisation et recherche d'offres",
  'sixStarDisplayPage.mobileWebEditor': 'Éditeur web mobile',

  'sixStarPlus.getInTouch': 'Entrer en contact',
  'sixStarTVPage.externalTVInputs': 'Entrées TV externes',
  'sixStarTVPage.p2':
    'Utilisez les ports HDMI pour les consoles et autres systèmes, ainsi que des applications comme AirPlay et Six Star: Cast pour permettre aux clients de diffuser depuis leurs propres appareils personnels.',

  'sixStarTVPage.p3': "Les coûts d'investissement peuvent être réduits en utilisant une infrastructure existante",
  'sixStarTVPage.p4': 'Surveillance interne et gestion de contenu directement depuis un navigateur',
  'sixStarTVPage.p5': "Une demande en forte hausse pour les fournisseurs d'hospitalité que SS:TV peut satisfaire avec brio.",
  'sixStarTVPage.fullyCustomizable1080p':
    'Un menu entièrement personnalisable en 1080p sans limites rigides de conception. Commencez avec des modèles sur mesure pour un démarrage en douceur ou laissez SSG vous aider avec la conception.',

  'sixStarVoicePage.header': 'Découvrez la puissance de Six Star: Voice',
  'sixStarVoicePage.p1': 'Communication inégalée',
  'sixStarVoicePage.advancedIntegrations': 'Intégrations avancées',
  'sixStarVoicePage.lineRedundancy': 'Redondance de ligne',
  'sixStarVoicePage.customisableSolutions': 'Solutions personnalisables',
  'sixStarVoicePage.seamlessConnect': 'Connectez-vous facilement à des applications tierces pour une fonctionnalité système polyvalente.',
  'sixStarVoicePage.enjoy': "Profitez d'une communication ininterrompue avec une redondance de ligne standard.",
  'sixStarVoicePage.tailor': "Adaptez Six Star: Voice pour optimiser vos opérations d'hospitalité uniques.",
  'sixStarVoicePage.p2': 'Communication sans faille',
  'sixStarVoicePage.sayGoodbye':
    "Dites adieu aux temps d'arrêt. Notre système est doté de la redondance de ligne en tant que fonction standard, garantissant une communication ininterrompue même face à des défis inattendus. Nous avons également des membres dédiés de l'équipe téléphonique prêts à vous soutenir en cas de besoin.",

  'sixStarCastPage.low-Cost':
    "Solution de diffusion peu coûteuse pour les appareils des clients pouvant être ajoutée à n'importe quelle télévision compatible HDMI.",
  'sixStarCastPage.guestCan': 'Les clients peuvent accéder à leur divertissement dès leur entrée dans leur chambre.',
  'sixStarCastPage.netflix': "Netflix, Hulu, Disney+ et bien d'autres disponibles.",
  'sixStarCastPage.easilyIntegrated': 'Intégration facile aux solutions TV existantes',
  'sixStarCastPage.p1':
    "Utilisez le portail marketing de marque pour communiquer directement avec les clients en interne. Via le système de gestion de contenu basé sur le Web (CMS), le personnel marketing de l'hôtel peut tenir les clients informés des événements et commodités de l'hôtel, des offres spéciales, des horaires d'ouverture, des programmes de fidélité, et bien plus encore.",

  'sixStarDisplayPage.subHeader':
    "Système d'affichage API-centric pour les salles de réunion, la publicité, l'orientation, le tableau des vols et le menu. Intégrations avec de nombreux services tiers.",
  'sixtarDisplayPage.noFancy': 'Aucun matériel sophistiqué pour commencer, le seul requis est un port HDMI.',
  'sixStarDisplayPage.mutlipleGoals': 'Six Star: Display peut atteindre plusieurs objectifs',
  'sixStarDisplayPage.plan': "Planifiez n'importe où et affichez partout car tout est géré dans le Cloud.",
  'sixStarDisplayPage.powerful': 'Une connectivité API puissante attire le regard',
  'sixStarDisplayPage.p1':
    "Accédez aux actualités, à la météo, à la circulation et aux données de vol pour transformer vos écrans en centrales d'informations en temps réel. Mélangez les données API avec votre contenu de marque, ou utilisez les déclencheurs API pour changer automatiquement votre messagerie.",

  'sixStarParkPage.header': 'Gérez le stationnement comme les chambres et les lits (y compris les revenus)',
  'sixStarParkPage.customerSat': 'Satisfaction client',
  'sixStarParkPage.improve': "Améliorez l'expérience globale de stationnement et maximisez la rentabilité",
  'sixStarParkPage.realTimeInformation': 'Informations en temps réel',
  'sixStarParkPage.provide': "Fournissez des informations en temps réel sur l'occupation et les revenus via un seul portail",
  'sixStarParkPage.geoMarketing': 'Géo-marketing',
  'sixStarParkPage.drive':
    "Mettez en œuvre des promotions en magasin pour les utilisateurs de l'application et générez du trafic vers vos installations",
  'sixStarParkPage.extraFeatures': 'Fonctionnalités supplémentaires',
  'sixStarParkPage.p1':
    "Six Star: Park offre de nombreuses fonctionnalités supplémentaires en matière de facteurs sociaux, économiques et organisationnels liés à votre parc de stationnement. Avec la possibilité d'inclure des bornes de recharge pour véhicules électriques, des paiements express, la réservation préalable et le paiement par mobile, vous augmenterez les revenus directs tout en maximisant l'expérience globale pour les clients.",
  'sixStarParkPage.preparing': "Préparation pour l'avenir",
  'sixStarParkPage.p2':
    "Notre produit de parking d'hôtel propose deux solutions de charge pour véhicules électriques avancées : TRONIQ 50, un chargeur DC de 50 kW compatible universel avec maintenance à distance, et DUAL BUSINESSLINE, un chargeur AC de 7,4 kW qui charge simultanément deux voitures avec une distribution efficace de l'énergie grâce à l'équilibrage de charge. Ces solutions de pointe améliorent l'expérience globale des clients en fournissant des options de charge pour véhicules électriques rapides, fiables et pratiques.",
  'sixStarBlackPage.header': 'Élever les Voyages Mondiaux et les Suites Présidentielles',
  'sixStarBlackPage.subHeader':
    "La marque 'Six Star: Black' propose deux produits distincts adaptés aux personnes de haut niveau et aux hébergements de luxe qui placent les besoins de leurs clients au premier plan.",
  'sixStarBlackPage.traveler': 'Voyageur',
  'sixStarBlackPage.hotel': 'Hôtel',
  'sixStarBlackPage.aService':
    "Un service pour les occupants des suites de plus haut niveau au sein d'hôtels de luxe, visant à améliorer l'expérience globale des clients séjournant dans ces hébergements exclusifs.",
  'sixStarBlackPage.provides':
    'Fournit un support informatique mondial de premier ordre pour répondre aux besoins technologiques des personnes de haut niveau lors de leurs déplacements.',
  'sixStarBlackPage.p1':
    "Expérimentez le support de voyage d'élite avec Six Star: Black (Voyageur). Notre équipe mondiale d'informatique de premier ordre s'occupe des personnes de haut niveau et de leurs familles, offrant une assistance technique transparente et personnalisée partout où vous mènent vos aventures, du remplacement de l'équipement basse tension à l'expédition directe d'équipement préconfiguré.",
  'sixStarBlackPage.p2':
    "Intégré aux offres existantes de l'hôtel pour créer une expérience fluide et élevée pour les clients. Nous fournissons des services de premier plan pour répondre aux attentes uniques et élevées des clients occupant des suites de niveau présidentiel et ambassadeur, assurant un séjour vraiment luxueux.",

  'sixStarVIPPage.subHeader': "Support premium direct vers des ingénieurs seniors et délais d'exécution réduits pour votre équipe de direction.",
  'sixStarVIPPage.whenYouCall':
    "Lorsque vous appelez ou créez un ticket, vous êtes assuré d'avoir l'un de nos ingénieurs seniors pour vous aider avec votre problème.",
  'sixStarVIPPage.onTheGo': 'Cartes de contact On-The-Go avec un numéro direct pour le support.',
  'sixStarVIPPage.bigOrSmall': "Que le problème soit grand ou petit, nous pouvons garantir un temps d'exécution plus court.",
  'sixStarVIPPage.plus':
    "Six Star: VIP Plus, le niveau ultime de support conçu pour élever votre expérience à des niveaux d'excellence sans précédent. Avec VIP Plus, vous avez accès à un ensemble de privilèges qui redéfinissent le support client.",
  'sixStarVIPPage.instant': 'Support Direct Instantané',
  'sixStarVIPPage.dedicated': 'Numéro de Support Dédié',
  'sixStarVIPPage.priority': "Délais d'Exécution Prioritaires",
  'sixStarVIPPage.withPlus': 'avec Six Star: VIP Plus',

  'sixStarInternetPage.subHeader':
    'Six Star: Internet combine des circuits de données rentables, une connectivité mobile complète et des troncs SIP avec une gestion de projet gratuite et un support robuste.',
  'sixStarInternetPage.leverage':
    'Nous exploitons des partenariats stratégiques, une infrastructure efficace et des solutions technologiques pour offrir des économies de coûts à ses clients.',
  'sixStarInternetPage.mobileEnhance':
    'La connectivité mobile améliore la flexibilité et la productivité des entreprises dans un monde de plus en plus centré sur la mobilité.',
  'sixStarInternetPage.inclusion':
    "L'inclusion de services de gestion de projet signifie une expérience d'intégration fluide et sans tracas pour les clients.",
  'sixStarInternetPage.p1':
    "Six Star privilégie la rentabilité, offrant des circuits de données aux tarifs les plus bas possibles. Cette fonctionnalité est conçue pour fournir aux entreprises un avantage concurrentiel en minimisant les dépenses liées à la connectivité Internet. En offrant des circuits de données abordables, Six Star permet aux entreprises d'allouer leurs ressources de manière plus efficace, favorisant la croissance et la durabilité.",
  'sixStarInternetPage.p2':
    "Six Star propose non seulement des troncs SIP (Session Initiation Protocol) pour une communication vocale efficace, mais se distingue également en fournissant des services gratuits de gestion de projet. Cela comprend des conseils et un soutien d'experts tout au long du processus de mise en œuvre. Une fois mis en œuvre, le service d'assistance de Six Star sera disponible 24h/24, 7j/7 pour toute question ou problème que vous pourriez avoir.",
  'sixStarInternetPage.projectManagement': 'Gestion de Projets et Support',
  'sixStarInternetPage.maximize': 'Maximiser les Économies avec les Circuits de Données',

  'sixStarPrintPage.header': 'Impression Gérée sans Effort pour une Productivité Sans Faille',
  'sixStarPrintPage.subHeader': 'Impression Intelligente, Sans Capex : Solutions Instantanées avec des Consommables Compétitifs',
  'sixStarPrintPage.printing': 'Impression sans Capital',
  'sixStarPrintPage.rapidResponse': 'Réponse Rapide',
  'sixStarPrintPage.costEffectiveConsumables': 'Consommables Rentables',
  'sixStarPrintPage.providing': "Fournissant une solution d'impression gérée sans coûts initiaux.",
  'sixStarPrintPage.enjoy': "Profitez d'un support sur site le jour même/le lendemain pour une impression ininterrompue.",
  'sixStarPrintPage.save': "Économisez avec des tarifs compétitifs sur les consommables d'impression de Six Star Global.",
  'sixStarPrintPage.hassle': 'Impression sans Problèmes',
  'sixStarPrintPage.unrivaled': 'Assurance de Temps de Fonctionnement Inégalée',
  'sixStarPrintPage.p1':
    "Profitez de l'impression sans la charge financière. La solution d'impression gérée de Six Star Global vous libère des coûts initiaux, rendant l'impression efficace accessible sans investissement en capital.",
  'sixStarPrintPage.p2':
    "Élevez votre expérience d'impression avec Six Star Global. Bénéficiez d'un support sur site rapide, le jour même/le lendemain, assurant le bon fonctionnement de vos opérations d'impression sans interruptions.",

  'sixStarGuestPage.header': "Conçu Spécifiquement pour les PC des Invités dans le Hall ou les Centres d'Affaires",
  'sixStarGuestPage.subHeader':
    "Rehaussez l'expérience de vos clients et rationalisez vos opérations commerciales avec notre technologie accessible de pointe pour les invités.",
  'sixStarGuestPage.secure': 'Environnement Sécurisé',
  'sixStarGuestPage.fully': 'Entièrement Sous Licence',
  'sixStarGuestPage.custom': 'Solutions Personnalisées',
  'sixStarGuestPage.B2B': 'Support B2B et Orienté Client',
  'sixStarGuestPage.p1':
    "Rehaussez votre centre d'affaires avec nos robustes services de support B2B et améliorez l'expérience du client avec des postes de travail publics fiables et conviviaux. Nous proposons des solutions informatiques sur mesure pour les clients d'entreprise tout en créant un environnement sans tracas pour les invités, qu'ils aient besoin d'un accès Internet, d'une impression de documents ou d'autres besoins informatiques.",
  'sixSTarGuestPage.customised': "Solutions Personnalisées pour N'importe Quel Environnement",
  'sixStarGuestPage.p2':
    "Reconnaissant que chaque établissement d'hospitalité est unique, nous proposons des solutions personnalisables pour répondre à vos besoins spécifiques. Du branding aux configurations logicielles, nous adaptons nos services pour aligner sur vos objectifs commerciaux, avec l'assurance de notre support 24h/24, 7j/7.",

  'sixStarPayPage.header': 'Rehaussez Votre Hospitalité, Simplifiez Vos Transactions',
  'sixStarPayPage.subHeader': "Optimisation de l'Excellence en Hospitalité, une Transaction à la Fois",
  'sixStarPayPage.flexible': 'Paiement Flexible',
  'sixStarPayPage.variousPayment': 'Nous prenons en charge diverses options de paiement. Les clients peuvent payer comme ils le souhaitent.',
  'sixStarPayPage.roomCharging': 'Facturation à la Chambre',
  'sixStarPayPage.orderRouting': 'Routage des Commandes Simplifié, les clients peuvent facturer des articles/services à leur chambre.',
  'sixStarPayPage.payAtTable': 'Payer à la Table',
  'sixStarPayPage.ourPay': 'Notre solution Pay-At-Table garantit que votre menu est synchronisé en temps réel sur toutes les plates-formes.',
  'sixStarPayPage.increasedEfficiency': 'Efficacité Accrue et Bonheur du Client',
  'sixStarPayPage.p1':
    "Gagnez du temps et améliorez l'efficacité avec notre gestion synchronisée de menus et des fonctionnalités de routage des commandes. Concentrez-vous sur la fourniture d'une expérience culinaire exceptionnelle sans les tracas opérationnels. Faites de ce client satisfait un client fidèle.",
  'sixStarPayPage.integration': 'Intégration et Configuration Faciles',
  'sixStarPayPage.p2':
    'Intégration transparente avec la plupart des principaux systèmes PMS et POS signifie que vous pouvez continuer à utiliser vos outils préférés tout en bénéficiant des avantages supplémentaires de Six Star: Pay.',
  // "sixStarTV.design": "Parlons de Design",
  'formLoader.BackButton': 'Retour',
  'formLoader.forwardButton': 'Avancer',
  'formLoader.submitButton': 'Soumettre',
  'formLoader.reSubmitButton': 'Re-soumettre',
  'dataTablePro.error': 'Erreur',
  'sixStarTV.design': 'Parlons de Design',
  'ticketViewPage.button.bundle': 'Regrouper',
  'ticketViewPage.button.goBack': 'Retour',
  'ticketViewPage.ticket.summary': 'Résumé du ticket : ',
  'ticketViewPage.formSummary': 'Résumé du formulaire',
  'ticketViewPage.masterDetail.createdAt': 'Créé à :',
  'ticketViewPage.masterDetail.assignedMember': 'Membres assignés',
  'ticketViewPage.masterDetail.ticketContacts': 'Contacts du ticket',
  'ticketViewPage.masterDetail.ticketCreatedAt': 'Ticket créé à',
  'ticketViewPage.masterDetail.mostRecentActivity': 'Activité la plus récente',
  'ticketViewPage.masterDetail.ticketUpdatedBy': 'Ticket mis à jour par',
  'ticketViewPage.masterDetail.viewTicket': 'Voir le ticket',
  'ticketViewPage.masterDetail.quickNote': 'Note rapide',
  'ticketViewPage.carbonCopy': 'Copie Carbone',
  'ticketViewPage.masterDetail.noRecentActivity': 'Aucune activité récente',
  // "ticketViewPage.carbonCopyNotAvailable": "Aucune copie carbone disponible sur ce ticket",
  "ticketViewPage.carbonCopyNotAvailable": "Aucune CC disponible sur ce ticket",
  "TicketViewPage.toolbar.quickClose.model.subTitle": "Êtes-vous sûr de vouloir résoudre rapidement {count} tickets ?",
  "TicketViewPage.toolbar.quickClose.model.title": "Résoudre Rapidement les Tickets",
  "TicketViewPage.toolbar.label.quickClose": "Résoudre Rapidement",
  "TicketViewPage.toolbar.quickClose.submitButton": "Résoudre Rapidement",
  "TicketViewPage.toolbar.quickClose.cancelButton": "Annuler",
  "TicketViewPage.toolbar.quickClose.model.reason": "Veuillez entrer une courte raison pour la fermeture du ticket, par exemple, inutile",
  "TicketViewPage.toolbar.quickClose.model.reasonPlaceholder": "Raison",
  "TicketViewPage.toolbar.quickClose.model.finished": "Les tickets ont été résolus rapidement",
  "TicketViewPage.toolbar.quickClose.model.updating": "Mise à jour des tickets",
  "TicketViewPage.toolbar.quickClose.model.error": "Erreur lors de la mise à jour des tickets",
  "TicketViewPage.toolbar.quickClose.closeButton": "Fermer",
  "menu.manage.helpSection.releaseNotes.tabText": "Notes de version",
  "menu.manage.helpSection.releaseNotes.menuText": "Notes de version",
  "releaseNotesPage.title": "Notes de version",
  "notFoundPage.pageDevelopment": "En développement",
  "notFoundPage.pageDevelopmentMessage": "Désolé, cette section est actuellement en développement. Veuillez revenir plus tard pour des mises à jour passionnantes de notre équipe technique",
  "notFoundPage.button.dashboard": "Tableau de bord",
  "notFoundPage.button.ticket": "Mes Tickets",
  "ticketViewPage.modal.bundle.childTicketField": "Rechercher un Ticket Enfant",
  "TicketViewPage.toolbar.saveView": "Enregistrer la vue",
  "TicketViewPage.toolbar.saveViewAs": "Enregistrer la vue sous",
  "TicketViewPage.toolbar.saveViewAsDuplicate": "Enregistrer la vue comme duplicata",
  "TicketViewPage.toolbar.modal.areYouSure": "Êtes-vous sûr(e) ?",
  "TicketViewPage.toolbar.modal.yes": "Oui",
  "TicketViewPage.toolbar.modal.no": "Non",
  "ticketViewPage.childTickets": "Tickets enfants",
  "ticketViewPage.parent": "Parent",
  "ticketViewPage.ticketSummary": "Résumé du ticket",
  "ticketViewPage.ticketNumber": "Numéro de ticket",
  "TicketViewPage.toolbar.label.filters": "Filtres",
  "TicketViewPage.toolbar.label.addContact": "Ajouter un contact",
  "ticketViewPage.addNote": "Ajouter une note",
  "ticketViewPage.ticketUpdate": "Mise à jour du ticket",
  "ticketViewPage.infoRequest": "Demande d'information",
  "ticketViewPage.approval": "Approbation",
  "ticketViewPage.requestForAction": "Demande d'action",
  "ticketViewPage.resolution": "Résolution",
  "ticketviewPage.tab.overview": "Aperçu",
  "ticketviewPage.tab.timeline": "Chronologie",
  "ticketviewPage.tab.formSummary": "Résumé du formulaire",
  "ticketviewPage.tab.notes": "Notes",
  "ticketviewPage.tab.tasks": "Tâches",
  "ticketviewPage.tab.activity": "Activité",
  "ticketViewPage.createdAt": "Créé le",
  "ticketViewPage.lastUpdate": "Dernière mise à jour",
  "ticketViewPage.priority": "Priorité",
  "ticketViewPage.ticketSLA": "SLA du ticket",
  "ticketViewPage.requester": "Demandeur",
  "ticketViewPage.ticketOwner": "Propriétaire du ticket",
  "ticketViewPage.ticketStatus": "Statut du ticket :",
  "ticketViewPage.ticketSummary": "Résumé du ticket",
  "ticketViewPage.ticketNumber": "Numéro du ticket : #",
  "ticketViewPage.podAccess": "Accès rapide",
  "ticketViewPage.podAccessDesc": "Ce pod vous donnera un accès rapide à différentes sections du ticket",
  "ticketViewPage.addContact": "Ajouter un contact",
  "ticketViewPage.editType": "Modifier le type",
  "ticketViewPage.assigneeOrRequester": "Mettre à jour le responsable/demandeur",
  "ticketViewPage.contacts": "Contacts",
  "ticketViewPage.contactDecs": "Ce pod affichera tous les contacts liés à ce ticket",
  "menu.manage.internalSection.forms.tabText": "Formulaires",
  "menu.manage.internalSection.forms.menuText": "Formulaires",
  "ticketViewPage.toolbar.label.quickEdit": "Édition rapide",
  "ticketViewPage.toolbar.editTicket.submitButton": "Soumettre",
  "datatableViews.toolbar.currentView": "Vue actuelle",
  "datatableViews.toolbar.loading": "Chargement...",
  "ticketviewPage.tab.overview": "Aperçu",
  "ticketviewPage.tab.timeline": "Chronologie",
  "ticketviewPage.tab.notes": "Notes",
  "ticketviewPage.tab.tasks": "Tâches",
  "ticketviewPage.tab.formSummary": "Résumé du formulaire",
  "ticketviewPage.tab.activity": "Activité",
  "ticketViewPage.timeline.ticketCreated": "Ticket créé",
  "ticketViewPage.timeline.ticketUpdated": "Ticket mis à jour",
  "ticketViewPage.timeline.messageAdded": "Message ajouté",
  "ticketViewPage.timeline.titleChange": "Changement de titre",
  "ticketViewPage.organisations": "Organisations",
  "ticketViewPage.ticketDevices": "Appareils du ticket",
  "ticketViewPage.ticketCC": "Ticket CC",
  "menu.manage.companyContact.tabText": "Entreprise",
  "menu.manage.companyContact.menuText": "Entreprise",
  "ticketViewPage.rateYourService": "Évaluez votre service",

  "ticketViewPage.relatedTickets.desc": "Ce pod affichera tous les tickets liés",
  "ticketViewPage.ticketCC.desc": "Ce pod affichera toutes les copies carbone liées",
  "ticketViewPage.ticketDevices.desc": "Ce pod affichera tous les appareils liés",
  "ticketViewPage.ticketSLA.desc": "Ce pod affichera le SLA de ce ticket",
  "ticketViewPage.documents.desc": "Ce pod affichera tous les documents liés à ce ticket",
  "ticketViewPage.organisations.desc": "Ce pod affichera toutes les organisations liées à ce ticket",
  "ticketViewPage.initialResponse": "Réponse initiale",
  "ticketViewPage.nextUpdate": "Prochaine mise à jour",
  "ticketViewPage.estimatedResolution": "Résolution estimée",
  "ticketViewPage.resolution": "Résolution",
  "ticketViewPage.relatedTickets": "Tickets Liés",
  "ticketViewPage.bundleTicket": "Regrouper le Ticket",
  "ticketViewPage.shareTicket": "Partager le Ticket",
  "ticketViewPage.contact": "Contact",
  "ticketViewPage.email": "E-mail",
  "ticketViewPage.rateYourServiceComment": "Vous pouvez évaluer votre service à tout moment pendant votre session avec Six Star Global.",
  "ticketViewPage.responseHistory": "Historique des Réponses",
  "ticketViewPage.initialComment": "Commentaire Initial",
  "ticketViewPage.agentResponse": "Réponse de l'Agent",
  "ticketVIewPage.notClosed": "Non fermé",
  "ticketViewPage.created": "Créé le",
  "ticketViewPage.firstResponse": "Première Réponse",
  "ticketViewPage.estimatedResolution": "Résolution Estimée",
  "ticketViewPage.actualResolution": "Résolution Réelle",
  "createOrg.title": "Créer une Organisation",
  "ticketViewPage.addManager": "Ajouter un Gestionnaire de Compte",
  "editManager.addNew": "Ajouter Nouveau",
  "editManager.currentManagers": "Gestionnaires Actuels",
  "companyContactPage.tabs.overview.region": "Région",
  "ticketViewPage.ratings": "Évaluations du Service",
  "ticketViewPage.ratingsdesc": "Ce pod montre toutes les évaluations laissées pour ce ticket.",
  "menu.manage.userSection.createOrganization.tabText": "Créer une Organisation",
  "menu.manage.userSection.createOrganization.menuText": "Créer une Organisation",
  "companyPage.info": "Infos sur l'entreprise",
  "companyPage.AccountManager": "Gestionnaire de Compte",
  "companyPage.location": "Emplacement",
  "companyPage.relatedOrganizations": "Organisations Connexes",
  "companyPage.parentOrganization": "Organisation Parent",
  "companyPage.childOrganizations": "Organisations Enfants",
  "companyPage.noParentOrganization": "Aucune Organisation Parent",
  "companyPage.noChildOrganizations": "Aucune Organisation Enfant",
  "ticketViewPage.infoDesc": "Ce pod affichera toutes les informations relatives à ce ticket",
  "ticketViewPage.ticketInfo": "Infos Ticket",
  "ticketViewPage.timeline.priorityChange": "Changement de Priorité",
  "ticketViewPage.timeline.statusChange": "Changement de Statut",
  "ticketViewPage.timeline.ticketResolved": "Ticket Résolu",
  'ticketViewPage.addSummary': 'Ajouter un Résumé',
  'ticketViewPage.notResponded': 'Pas Répondu',
  'ticketViewPage.noInitialComment': 'Pas de Commentaire Initial',
  'ticketViewPage.noAgentResponse': 'Pas de Réponse de l\'Agent',
  'ticketViewPage.makePrivate': 'Rendre Privé',
  'ticketViewPage.pod.team': 'Équipe',
  'ticketViewPage.team.desc': 'Ce module affichera tous les membres de l\'équipe liés à ce ticket',
};

export default pages;
