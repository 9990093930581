import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sounds: {
    notification: true,
    connection: true,
  },
  ticketPage: {
    leftSideBar: true,
    rightSideBar: true,
  },
  toolbarLocked: true,
};

export const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeConnectionSounds: (state, action) => {
      state.sounds.connection = action.payload;
    },
    changeNotificationSounds: (state, action) => {
      state.sounds.notification = action.payload;
    },
    setShowLeftPanel: (state, action) => {
      state.ticketPage.leftSideBar = action.payload;
    },
    setShowRightPanel: (state, action) => {
      state.ticketPage.rightSideBar = action.payload;
    },
    setToolbarLocked: (state, action) => {
      state.toolbarLocked = action.payload;
    },
  },
});

export const {
  changeConnectionSounds,
  changeNotificationSounds,
  setShowLeftPanel,
  setShowRightPanel,
  setToolbarLocked,
} = settings.actions;

export default settings.reducer;
