import { createTheme } from '@mui/material/styles';

const radius = '10px';
const statusYellow = '#FF9800';
const statusRed = '#E2434D';
const statusAmber = '#FF9800';
const statusGreen = '#4FCF83';
const statusBlue = '#4292CD';
const statusBlack = '#2F3941';
const statusGrey = '#999';

const globalPalette = {
  icons: {
    primary: '#4292CD',
    primaryHover: '#2A203B',
  },
  ticketHeaderIcons: {
    primary: '#182C48',
    icon: '#F3EE58',
    hover: '#4292CD',
  },
  background: {
    main: '#EAE9F4',
    dark: '#dad9ec',
  },
  warning: {
    main: statusYellow,
    contrastText: 'white',
  },
  lightBlue: {
    main: '#95c9ea',
    contrastText: 'black',
  },
  status: {
    yellow: statusYellow,
    red: '#E2434D',
    amber: statusYellow,
    green: '#4FCF83',
    blue: '#4292CD',
    black: '#2F3941',
    grey: '#999',
  },
  pods: {
    header: {
      icon: {
        primary: '#6196C7',
        hover: '#182C48',
        textColor: '#FFF',
      },
    }
  },
  priority: {
    high: '#E2434D',
    normal: '#4FCF83',
    low: '#999',
    default: 'gray',
  },
  primary: {
    500: '#716aca',
    main: '#2A203B',
    paper: '#ffffff',
  },
  secondary: {
    main: '#3F51B5',
  },
  tabs: {
    main: '#103F65',
    secondary: '#182C48',
    indicator: '#87CAEE',

    background: {
      primary: '#EEEEEE',
      secondary: '#dad9ec',
      indicator: '#3F51B5',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.6)',
      secondary: '#182C48',
      selected: '#3F51B5',
    },
  },
  action: {
    active: '#525672',
  },
  text: {
    primary: '#2A203B',
    secondary: '#868aa8',
    divider: '#292b3a',
    light: '#ffffff',
    grayedOut: '#B3B3B3',
  },
  success: {
    main: '#4FCF83',
  },
  error: {
    main: '#E2434D',
  },
  warn: {
    main: '#F9B953',
  },
  links: {
    main: '#3F76B6',
    secondary: '#87CAEE',
    hover: '#2A203B',
  },
  generic: {
    foreground: '#000',
    background: '#FFF',
  },
  pieChart: {
    fill: '#8884d8',
    text: '#FFF',
    colors: [
      '#0088FE',
      '#00C49F',
      '#FFBB28',
      '#FF8042',
      '#FF0000',
      '#0000FF',
      '#00FF00',
      '#FF00FF',
      '#000000',
      '#FFFFFF',
      '#FFA500',
      '#800000',
      '#808000',
      '#008000',
      '#800080',
      '#000080',
      '#808080',
      '#C0C0C0',
      '#008080',
      '#000000',
    ],
  },
  ticket_priority: {
    normal: '#4FCF83',
    high: '#E2434D',
  },
  table: {
    odd: '#EAE9F4',
    even: '#FFFF',
    border: '#000',
  },
  white: {
    main: '#fff',
  },
};

const themeManage = createTheme({
  borderRadius: radius,

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: radius,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#4292CD',
          '&.Mui-checked': {
            color: '#4292CD',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#4292CD',
          cursor: 'pointer',
        },
      },
    },
    // sx={{
    //   border: '0px solid transparent',
    //   backgroundColor: 'transparent'
    // }}
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '2rem',
        },
      },
      variants: [
        {
          props: { variant: 'transparent' },
          style: {
            backgroundColor: 'transparent',
            border: '1px solid transparent',
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '16px',
          backgroundColor: '#103F65',
          // color: theme.palette.text.light,
          // boxShadow: theme.shadows[3],
          fontSize: 15,
          borderRadius: radius,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          // textTransform: 'capitalize',
        },
        root: {
          borderRadius: radius,
          boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: radius,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: radius,
          backgroundColor: 'white',
          '& .odd': {
            cursor: 'pointer',
            backgroundColor: globalPalette.table.odd,
          },
          '& .even': {
            cursor: 'pointer',
            backgroundColor: globalPalette.table.even,
          },
          '& .MuiDataGrid-columnSeparator': {
            visibility: 'visible !important',
            // backgroundColor: "#2A203B!important",
          },
          '& .MuiDataGrid-virtualScroller': {
            '& .MuiDataGrid-overlayWrapper': {
              // height: "50px !important",
            },
          },
          '& .MuiDataGrid-sortIcon': {
            color: '#ffffff',
          },
          '& .MuiSvgIcon-root[data-testid="TripleDotsVerticalIcon"]': {
            color: '#ffffff !important',
          },
        },
        toolbarContainer: {
          gap: '20px',
          justifyContent: 'end',
          padding: '10px',
          '& .MuiButtonBase-root': {
            padding: '12px',
          },
        },
        columnHeaders: {
          borderRadius: ' 0px',
          backgroundColor: '#2A203B !important',
        },
        columnHeader: {
          backgroundColor: '#2A203B',
          color: 'white',
          fontWeight: 400,
        },
        cell: {
          borderBottomColor: '#2A203B !important',
          '&:focus-within': {
            outline: 'none!important',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: radius,
          boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        },
        text: {
          boxShadow: 'none',
        },
      },
    },

    MuiButtonGroup: {
      variants: [
        {
          props: { variant: 'statusYellow' },
          style: {
            backgroundColor: statusYellow,
            color: 'white',
            '&:hover': {
              backgroundColor: LightenDarkenColor(statusYellow, -20),
            },
          },
        },
        {
          props: { variant: 'statusRed' },
          style: {
            backgroundColor: statusRed,
            color: 'white',
            '&:hover': {
              backgroundColor: LightenDarkenColor(statusRed, -20),
            },
          },
        },
        {
          props: { variant: 'statusAmber' },
          style: {
            backgroundColor: statusAmber,
            color: 'white',
            '&:hover': {
              backgroundColor: LightenDarkenColor(statusAmber, -20),
            },
          },
        },
        {
          props: { variant: 'statusGreen' },
          style: {
            backgroundColor: statusGreen,
            color: 'white',
            '&:hover': {
              backgroundColor: LightenDarkenColor(statusGreen, -20),
            },
          },
        },
        {
          props: { variant: 'statusBlue' },
          style: {
            backgroundColor: statusBlue,
            color: 'white',
            '&:hover': {
              backgroundColor: LightenDarkenColor(statusBlue, -20),
            },
          },
        },
        {
          props: { variant: 'statusBlack' },
          style: {
            backgroundColor: statusBlack,
            color: 'white',
            '&:hover': {
              backgroundColor: LightenDarkenColor(statusBlack, -20),
            },
          },
        },
        {
          props: { variant: 'statusGrey' },
          style: {
            backgroundColor: statusGrey,
            color: 'white',
            '&:hover': {
              backgroundColor: LightenDarkenColor(statusGrey, -20),
            },
          },
        },
      ],
      styleOverrides: {
        grouped: {
          '&:not(:last-of-type)': {
            borderColor: 'white',
          },
        },
        root: {
          borderRadius: radius,
          boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiLoadingButton: {
      variants: [
        {
          props: { variant: 'login' },
          style: {
            backgroundColor: '#4292CD',
            color: 'red',
            '&:hover': {
              backgroundColor: '#2A203B',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: radius,
        },
      },
    },

    MuiIcon: {
      styleOverrides: {
        root: {
          boxSizing: 'content-box',
          padding: 3,
          fontSize: '1.125rem',
        },
      },
    },
  },

  palette: globalPalette,
  typography: {
    fontFamily: ['Futura', 'Futura Condensed PT'].join(','),
    subtitle1: {
      fontSize: 14,
    },
  },
  listItemText: {
    color: 'green',
  },
  custom: {
    radius: {
      borderRadius: '15px',
    },
    link: {
      // Not Sure
      color: '#e10050',
    },
    appBar: {
      // Header Bar Background
      backgroundColor: '#182C48',
    },
    drawerPaper: {
      //Menu Drawer Links Background
      backgroundColor: '#182C48',
    },
    drawerHeader: {
      //Menu Drawer Header Background
      backgroundColor: '#182C48',
      //Not Sure
      color: '#fff',
    },
    drawerMenuList: {
      //Menu Top Level Dropdown Background
      backgroundColor: '#182C48',
    },
    selectedItem: {
      //Current Menu Item Background
      backgroundColor: 'transparent',
    },
    selectedItemText: {
      //Background of selected menu item
      color: '#87CAEE',
    },
    //Not Sure
    text: '#757575',
    title: {
      //Menu Header Text Color
      color: '#d6d7e1',
    },
  },
  status: {
    red: '#E2434D',
    amber: '#F9B953',
    green: '#4FCF83',
  },
});

function LightenDarkenColor(col, amt) {
  var usePound = false;
  if (col[0] == '#') {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export default themeManage;
