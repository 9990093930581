import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/material/SvgIcon/SvgIcon";
import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useErrorNotification = () => {
  const [conf, setConf] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Notification = (key) => {
    let errorMessage =
      "There has been a general error. Please Contact Sixstar Support Development Team.";
    let errorTitle = "Error";

    if (conf?.location) {
      errorTitle += ` (${conf.location})`;
    }

    if (typeof conf.error === "object") {
      if (conf.error?.message) {
        errorMessage = conf.error?.message;
      }
    }
    if (typeof conf.error === "string") {
      errorMessage = conf.error;
    }
    return (
      <Box sx={{ minWidth: "230px" }} className="notification">
        <Grid container spacing={2} rowSpacing={0}>
          <Grid item xs={"auto"}>
            <div>{errorTitle}</div>
            <div>{errorMessage}</div>
          </Grid>
        </Grid>
      </Box>
    );
  };
  useEffect(() => {
    if (conf?.error) {
      try {
        enqueueSnackbar(<Notification />, {
          variant: "error",
          persist: true,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          action: (key) => (
            <IconButton
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              <FontAwesomeIcon
                icon={["fas", "times"]}
                fixedWidth
                color={"white"}
              />
            </IconButton>
          ),
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [conf]);
  return [setConf];
};

export default useErrorNotification;
