import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Drawer from "@mui/material/Drawer";
import { closeSettingsDrawer } from "containers/App/Redux/menu/actions";
import layoutStyles from "../styles";
import { useSubscription } from "@apollo/client";
import graphQLQueries from "graphql/notification";
import { Box, Divider, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTime from "components/Global/DateTime";
import useTranslation from "containers/App/Hooks/useTranslation";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { updateNotificationUnreadCount } from "slices/user";
import graphQLMutations from "graphql/notification";
import { useMutation, gql } from "@apollo/client";

function NotificationDrawer(props) {
  const { classes, theme } = props;
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [canOpenSettingsDrawer, setCanOpenSettingsDrawer] = useState(true);
  const openSettingDrawer = useSelector(
    (state) => state.menu.openSettingDrawer
  );
  const [currentPath, setCurrentPath] = useState();
  const { GET_NOTIFICATIONS, GET_UNREAD_COUNT } = graphQLQueries();
  let organizations = [];
  if (user?.permissions?.organizations) {
    organizations = user.permissions.organizations.map(
      (organization) => organization?.organization?.id
    );
  }
  const variables = {
    user: user?.id,
    organizations: organizations,
    limit: 100,
  };
  const variablesRead = { user: user?.id, organizations: organizations };
  const { data, loading, error } = useSubscription(GET_NOTIFICATIONS, {
    variables: variables,
  });
  const {
    data: unreadCount,
    loading: unreadCountLoading,
    error: unreadCountError,
  } = useSubscription(GET_UNREAD_COUNT, { variables: variablesRead });
  const { DELETE_NOTIFICATION } = graphQLMutations();

  const [
    deleteNotificaction,
    {
      data: deleteData,
      loading: deleteLoading,
      error: deleteError,
    },
  ] = useMutation(DELETE_NOTIFICATION);


  useEffect(() => {
    setCurrentPath(props.location);
    window.addEventListener("resize", isWindowResizing);
    return () => {
      window.removeEventListener("resize", isWindowResizing);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (unreadCountLoading == false) {
      dispatch(
        updateNotificationUnreadCount(
          unreadCount?.notification_aggregate?.aggregate?.count
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadCount]);

  useEffect(() => {
    if (currentPath !== props.location) {
      closeSettingsDrawerAction();
      setCurrentPath(props.location);
    }
    // eslint-disable-next-line
  }, []);

  // Debugging logs
  useEffect(() => {
    // console.log("Subscription data:", data);
    // console.log("Subscription loading:", loading);
    // console.log("Subscription error:", error);
  }, [data, loading, error]);

  useEffect(() => {
    // console.log("user:", user?.id);
    // console.log("organizations:", organizations);
    // console.log("Unread count data:", unreadCount);
    // console.log("Unread count loading:", unreadCountLoading);
    // console.log("Unread count error:", unreadCountError);
  }, [unreadCount, unreadCountLoading, unreadCountError]);

  const isWindowResizing = () => {
    if (!props.openSettingDrawer) {
      setCanOpenSettingsDrawer(false);
      setTimeout(() => {
        setCanOpenSettingsDrawer(true); // Fix resizing bug. The bug shows the settings drawer while resizing the windows and is hidden, so we can show it after resizing and have waited 500ms
      }, 500);
    }
  };

  const closeSettingsDrawerAction = () => {
    dispatch(closeSettingsDrawer());
  };

  return (
    <div>
      {canOpenSettingsDrawer ? (
        <Drawer
          onClose={closeSettingsDrawerAction}
          open={openSettingDrawer}
          anchor="right"
          sx={{
            height: "100%",

            "& .MuiPaper-root": {
              width: "550px",
              borderLeft: "none",
              boxShadow: theme.shadows[5],
            },
          }}
        >
          <Box
            sx={{
              marginTop: "48px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: 2,
              backgroundColor: theme.palette.tabs.main,
              padding: theme.spacing(4, 3),
              height: "102px",
            }}
          >
            <FontAwesomeIcon
              icon={["fa", "bell"]}
              size={"2x"}
              fixedWidth
              color={theme.palette.common.white}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                color={theme.palette.common.white}
                variant={"h5"}
                fontWeight={400}
              >
                {translate("notifications.notificationDrawer.headerTitle")}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              height: "calc(100% - 100px)",
              overflowY: "scroll",
            }}
          >
            {data?.notification?.map((notification, index) => {
              // console.log(notification)

              return (
                <Notification
                  key={index}
                  notification={notification}
                  theme={theme}
                  closeSettingsDrawerAction={closeSettingsDrawerAction}
                  deleteNotificaction={deleteNotificaction}
                />
              );
            })}
          </Box>
        </Drawer>
      ) : null}
    </div>
  );
}

const Notification = (props) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { notification, theme, key, closeSettingsDrawerAction, deleteNotificaction } = props;
  let url = null;
  let icon = notification.type.icon.split(" ");
  const wordsToBold = ["replied", "mentioned", "resource"];
  const grey = theme.palette.grey[500];
  let title = translate(notification.type.name);
  // add each word to an array from the title, then check if the word is in the wordsToBold array, if it is, add a <b> tag around it
  title = title.split(" ").map((word) => {
    if (wordsToBold.includes(word)) {
      return <b>{word + " "}</b>;
    } else {
      return word + " ";
    }
  });

  // if (
  //   notification?.type?.name === "notification.ticket.reply" &&
  //   notification?.category?.name === "ticket" &&
  //   notification?.metadata?.id
  // ) {
  url = notification?.url;
  // }

  const handleClick = () => {
    if (url) {
      navigate(url);
      closeSettingsDrawerAction()
    }
  };

  const handleRead = () => {
    deleteNotificaction({
      variables: {
        id: notification.id
      }
    })
  }
  return (
    <React.Fragment key={key}>
      <Box
        sx={{
          cursor: "pointer",

          padding: theme.spacing(2, 2, 1, 3),
          "&:hover": {
            backgroundColor: theme.palette.grey[100],
          },
        }}
      // onClick={() => handleClick()}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: theme.spacing(1),
          }}
        >
          <Typography variant={"body2"} color={theme.palette.tabs.main}>
            <FontAwesomeIcon
              icon={icon}
              size={"1x"}
              fixedWidth
              style={{ marginRight: theme.spacing(2) }}
              color={grey}
            />
            {title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography variant={"subtitle1"} color={grey}>
              <DateTime data={notification?.created_at} />{" "}
            </Typography>
            <IconButton
              color={theme.palette.tabs.main}
              onClick={() => handleRead()}
            >
              <FontAwesomeIcon
                icon={["fal", "times"]}
                style={{ width: "18px", height: "18px" }}
              />
            </IconButton>
          </Box>
        </Box>
        <Typography variant={"body2"}> {notification?.body}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          {url && (
            <IconButton
              color={theme.palette.tabs.main}
              onClick={() => handleClick()}
            >
              <FontAwesomeIcon
                icon={["fal", "circle-arrow-right"]}
                size={"xs"}
                style={{ width: "18px", height: "18px" }}
              />
            </IconButton>
          )}
        </Box>
      </Box>
      <Divider />
    </React.Fragment>
  );
};

export default compose(
  withStyles((theme) => layoutStyles(theme), {
    withTheme: true,
  })
)(NotificationDrawer);
