import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import Typography from "@mui/material/Typography";
import Button from "components/MuiOverride/Button";
import ContactUsTopBox from "components/ContactUsTopBox";
import Translation from "utils/translation";
import { useNavigate } from 'react-router-dom';
import SignOutModal from "./ContactUsModals/SignOutModal";
import AddWidgetModal from './ContactUsModals/AddWidgetModal';

function ContactUsTop(props) {
    const { classes } = props;
    const navigate = useNavigate(); 

    const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);
    const [isAddWidgetModalOpen, setIsAddWidgetModalOpen] = useState(false);

const handleLogTicketClick = () => {
    navigate("/support/new-request");
  };

  const handleLogOutClick = () => {
    setIsSignOutModalOpen(true);
  };

  const handleTicketTableClick = () => {
    navigate("/support/tickets");
  };

  const handleAddWidgetsClick = () => {
    setIsAddWidgetModalOpen(true);
  };


    return (
        <div className={classes.container}>
            <Typography
                variant="h3"
                component="h3"
                className={classes.headingText}
            >
                {Translation("contactUsPage.title.first")}
            </Typography>
            <div className={classes.searchContainer}>
                <input
                    className={classes.searchInput}
                    placeholder={Translation("contactUsPage.search.answers")}
                />
                <Button 
                metadata="search_button" 
                 disabled={true} 
                className={classes.callUsButton} 
                variant="contained"
                sx={{width: "30%!important", height: "70%!important", marginLeft: "auto", borderRadius: '15px!important'}}
                >{Translation("contactUsPage.search")}</Button>
            </div>
            <div className={classes.boxContainer}>
                <ContactUsTopBox text={Translation("contactUsPage.header.boxs.one")} icon={"ticket"} handleClick={handleLogTicketClick} />
                <ContactUsTopBox text={Translation("contactUsPage.header.boxs.two")} icon={"power-off"} handleClick={handleLogOutClick} />
                <ContactUsTopBox text={Translation("contactUsPage.header.boxs.three")} icon={"envelope"} handleClick={handleTicketTableClick}  />
                <ContactUsTopBox text={Translation("contactUsPage.header.boxs.four")} icon={"window-restore"} handleClick={handleAddWidgetsClick} />
                {isSignOutModalOpen && <SignOutModal onClose={() => setIsSignOutModalOpen(false)} />}
                {isAddWidgetModalOpen && <AddWidgetModal onClose={() => setIsAddWidgetModalOpen(false)} />}
            </div>
        </div>
    );
}

export default withStyles(
    (theme) => ({
        ...layoutStyles(theme),
        ...styles(theme),
    }),
    { withTheme: true }
)(ContactUsTop);
