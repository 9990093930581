import { setOnlineUsers } from "slices/onlineUsers";

const updateSessions = async (socket, store) => {
  socket.on("websocket:UPDATE_SESSIONS", (payload) => {
    if (payload.length > 0) {
      store.dispatch(setOnlineUsers(payload));
    }
  });
};

export default updateSessions;
