const login = {
  "login.title": "SIX STAR GLOBAL",
  "login.headline": "The World's Foremost IT Managed Services Company, Yarrr!",
  "login.signInText": "Board Six Star: Command",
  "login.topCallToActionTitle": "Not a Matey of Six Star?",
  "login.topCallToActionLink": "Let's Parley",
  "loginPage.input.email": "Pigeon Post Address",
  "loginPage.input.password": "Secret Code",
  "loginPage.input.mfa": "MFA Code, Arrr",
  "loginPage.rememberMe": "Remember Me, Yarrr",
  "loginPage.button.login": "Board Ship",
  "loginPage.button.forgotPassword": "Forgot Yer Secret Code?",
  "loginPage.apiMfaInProgress": "Please enter yer MFA code, Yarrr",
  "login.forgotPasswordText": "Forgot Secret Code",
  "loginPage.apiMultipleUsers": "Matey Account Error, Contact Six Star",
  "loginPage.apiNoPassword": "Please enter yer Secret Code",
  "loginPage.apiNoUser": "Please enter Pigeon Post Address",
  "loginPage.apiInvalidTOTP": "Invalid MFA Code, Arrr",
  "loginPage.apiInvalidPassword": "Invalid Secret Code",
  "login.sso.starting": "Starting Single Sign On, Yarrr",
  "loginPage.button.normalSignIn": "Username/Secret Code Board Ship",
};
export default login;
