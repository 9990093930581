import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import layoutStyles from 'containers/Layout/styles';
import PageBase from 'components/PageBase';
import styles from './styles';
import { Card, CardContent, Typography, Box } from '@mui/material';


function ReleaseNotes(props) {
  const { classes } = props;

  // BLANK SECTION
  // {
  //   title: "",
  //     items: [
  //       {
  //         title: "",
  //         items: [
  //           {
  //             title: "",
  //           },
  //         ]
  //       },
  //     ]
  // }

  const releaseNotes = [
    {
      date: '13/09/2024',
      title: 'New Features and Bug Fixes',
      version: '3.0.13',
      descriptions: 'New Features and Bug Fixes',
      sections: [
        {
          title: 'New Features',
          items: [
            {
              title: 'Ticket View Page',
              items: [
                {
                  title: 'Added the ability to add or edit ticket summaries',
                },
                {
                  title: 'Added the ability to collapse the pod items',
                },
                {
                  title: 'Implemented a new UI to view ticket notes',
                },
                {
                  title: 'Added tooltips and a confirmation modal to the public/private eye',
                }
              ],
            },
          ],
        },
        {
          title: 'Bug Fixes',
          items: [
            {
              title: 'Ticket View Page',
              items: [
                {
                  title: 'Fixed a bug where users could not open certain tickets',
                },
                {
                  title: 'Fixed the bug with organizations on a ticket',
                },
                {
                  title: 'FIxed the team pod name in the pod access buttons container',
                },
                {
                  title: 'Fixed a bug with the ticket SLA pod not showing the correct information',
                }
              ],
            },
            {
              title: 'Contact Profile Page',
              items: [
                {
                  title: 'Fixed a bug not allowing users to edit contact information',
                }
              ]
            },
          ],
        },
      ]
    },
    {
      date: '06/09/2024',
      title: 'New Features and Bug Fixes',
      version: '3.0.12',
      descriptions: 'New Features and Bug Fixes',
      sections: [
        {
          title: 'New Features',
          items: [
            {
              title: 'Ticket View Page',
              items: [
                {
                  title: 'Added email addresses to valued customer notes',
                },
                {
                  title: 'Added public/private eye to the ticket view page',
                },
                {
                  title: 'Added the ability to copy an email address on click',
                },
                {
                  title: 'Added redo and undo buttons to the comment editor, when adding a note',
                },
              ],
            },
          ],
        },
        {
          title: 'Bug Fixes',
          items: [
            {
              title: 'Ticket View Page',
              items: [
                {
                  title: 'Fixed a bug where users could not download attachments',
                },
                {
                  title: 'Fixed a bug where notes were not opening as intended',
                },
                {
                  title: 'Fixed issues with some notes not saving',
                },
                {
                  title: 'Fixed a bug with the bundle modal not closing',
                },
                {
                  title: 'Fixed a bug with notifications',
                },
              ],
            },
            {
              title: 'Site Wide',
              items: [
                {
                  title: 'Times now accommodate for timezones',
                }
              ]
            }
          ],
        },
      ]
    },
    {
      date: '04/09/2024',
      title: 'New Features and Improvements',
      version: '3.0.11',
      descriptions: 'New ticket view page, organization and cotacts pages, ability to search for contacts and more.',
      sections: [
        {
          title: 'New Features',
          items: [
            {
              title: 'Ticket View Page',
              items: [
                {
                  title: 'New ticket view page UI'
                },
                {
                  title: 'Clicking on a name or organization in the ticket view page will take you to the respective page'
                },
                {
                  title: 'Caching has been added to ticket notes, so you can switch between tabs without losing your notes'
                },
                {
                  title: 'New most recent message box that is colour coded red and green for internal and external messages'
                },
                {
                  title: 'Pod access buttons introduced to make navigating the ticket view page side panel easier'
                },
                {
                  title: 'Ability to add multiple contacts to a ticket'
                },
                {
                  title: 'Organizations propegate from contacts added to a ticket'
                },
                {
                  title: 'SLA pod added to the ticket view page, to show if an engineer is in breach of SLA'
                },
                {
                  title: 'Customers can now rate an engineers performance'
                },
                {
                  title: 'New ticket view page timeline showcasing ticket updates'
                },
                {
                  title: 'Notes tab has been refactored to include the internal/external colour coding'
                }
              ]
            },
            {
              title: 'Organization Page',
              items: [
                {
                  title: 'Ability to view an organizations details'
                },
                {
                  title: 'Ticket table that shows the organizations open tickets'
                },
                {
                  title: 'Contacts table that shows the organizations active contacts'
                },
                {
                  title: 'Side panel that allows for engineers to edit the organization'
                }
              ]
            },
            {
              title: 'Contacts Page',
              items: [
                {
                  title: 'Ability to view a contacts details'
                },
                {
                  title: 'Ticket table that shows the contacts open tickets'
                },
                {
                  title: 'Shows the contacts most recent message'
                },
                {
                  title: 'Side panel that allows for engineers to edit the contacts information'
                }
              ]
            },
            {
              title: 'Internal',
              items: [
                {
                  title: 'Earn badges based on your performance'
                },
                {
                  title: 'Metrics Dashboard now visible to all engineers'
                },
                {
                  title: 'Leaderboard now visible to all engineers'
                }
              ]
            }
          ]
        },
        {
          title: 'Bug Fixes',
          items: [
            {
              title: 'Ticket View Page',
              items: [
                {
                  title: 'Fixed issue with being unable to change the ticket to private.'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: '26/03/2024',
      title: 'Ticket Table Refactor and additional Improvements',
      version: '3.0.10',
      descriptions: 'Ticket Table Refactor, improved ticket quick create, updated translations and more.',
      sections: [
        {
          title: 'New Features',
          items: [
            {
              title: 'Ticket Table',
              items: [
                {
                  title: 'Complete Refactor of the ticket table, increased performance and useability aswell as added new features'
                }
              ]
            },
            {
              title: 'Ticket Quick Create',
              items: [
                {
                  title: 'Added the ability to set priority'
                },
                {
                  title: 'Added the ability to set Title'
                },
                {
                  title: 'Added the ability to set Status'
                },
              ]
            },
            {
              title: 'Forms',
              items: [
                {
                  title: 'Updated the new starter form for certain companies'
                },
                {
                  title: 'Updated date selector in forms to use d/m/y format'
                },
                {
                  title: 'Updated translations for forms.'
                }
              ]
            },
            {
              title: 'Site Wide',
              items: [
                {
                  title: 'Added Admin Panel for admininstrators.'
                }
              ]
            }
          ]
        },
        {
          title: 'Bug Fixes',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Various issues with the old ticket table should now be resolved with the new table'
                },
                {
                  title: 'Incorrectly formatted times displayed'
                }
              ]
            },
            {
              title: 'Backend',
              items: [
                {
                  title: 'Fixed further issues with some users not being able to login'
                },
                {
                  title: 'Updated some incorrect user information such as email addresses.'
                },
                {
                  title: 'Fixed performance issues with querying tickets and users.'
                },
              ]
            }
          ]
        },
        {
          title: 'Known Issues',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Tickets incorrectly updating last updated information.'
                },
                {
                  title: 'Issues with submitting Notes'
                },
                {
                  title: "Issues with receiving notifications from a ticket updated."
                },
                {
                  title: 'Workplace/Teams notifications not coming through',
                },
                {
                  title: 'Add more detail to notifications like who changed what in a ticket not just ticket is updated',
                },
                {
                  title: 'Tabs to remember input when switching between them',
                },
                {
                  title: 'Cant manually add documents to a ticket',
                },
                {
                  title: 'Cant add images to forms',
                },
                {
                  title: 'Cannot see customer images in the ticket view page.'
                },
                {
                  title: 'Resource tracker not showing the correct data'
                },
                {
                  title: 'Child ticket requesters are not getting updates made to the parent ticket'
                },
                {
                  title: 'After updating a ticket, notifications say it was updated by someone else'
                },
                {
                  title: 'Cannot add a device to a ticket'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: '22/02/2024',
      title: 'New Features and Improvements',
      version: '3.0.9',
      descriptions: 'New Features and Improvements',
      sections: [
        {
          title: 'New Features',
          items: [
            {
              title: 'Site Wide',
              items: [
                {
                  title: 'Enabled searching on drop downs, this should remove the stuck rendering issue',
                },
                {
                  title: 'Updated the quick create to take you to the ticket view page after creating a ticket',
                },
                {
                  title: 'Added a ticket summary field when quick creating a ticket, the summary will be visible on the ticket view page.',
                },
                {
                  title: "Six Star Connect - Hotel Co payslips are now enabled"
                },
              ],
            },
            {
              title: 'Ticket View',
              items: [
                {
                  title: 'Added ability to add multi level bullet points to ticket notes',
                },
                {
                  title: 'Removed New and Unknown from the selectable options when chaging a ticket status'
                },
                {
                  title: 'Reworked teams so that wer can have sub teams'
                },
                {
                  title: 'Added the contact Six Star Global Resourcing for acuity emails'
                },
                {
                  title: 'Removed old staff members from the quick create assignee list'
                },

              ]
            },
            {
              title: 'UI/UX Improvements',
              items: [
                {
                  title: 'Added release notes as a menu item in the header for easy access. To access the release notes, click on your profile picture in the top right and then click on release notes',
                }
              ]
            },
            {
              title: "Call Numbers",
              items: [
                {
                  title: "International support numbers updated in help section"
                }
              ]

            },
            {
              title: "Ticket Table",
              items: [
                {
                  title: "Removed the help button from the ticket table for more visibility"
                },
                {
                  title: 'Changed filters on teams to indent sub teams'
                }
              ]
            },
            {
              title: "New Requests",
              items: [

                {
                  title: 'Updated standard new starter from'
                }
              ]
            },
            {
              title: "Backend",
              items: [
                {
                  title: "Added logging of failed login attempts so we can see if there is a pattern and easier assist users with login issues"
                },
                {
                  title: 'Avatars now being pulled from the microsoft profile and saved in manage profile'
                }
              ]
            }
          ]
        },
        {
          title: 'Bug Fixes',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Fixed issue with quick create auto complete not searching correctly',
                },
                {
                  title: 'Added missing translations to the bell tooltip',
                },
                {
                  title: 'URL in email changed from .click to .support'
                },
                {
                  title: 'Some incorrect Thai translations updated'
                },
                {
                  title: 'When a Related ticket is bundled, there is a was a defined field error this is now resolved'
                }
              ],
            },
            {
              title: 'Backend',
              items: [
                {
                  title: 'Fixed issue with some users not being able to login',
                }
              ]
            }
          ]
        },
        {
          title: 'Known Issues',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Project tickets missing from the tickets table',
                },
                {
                  title: 'Hotel Co new starter form',
                },
                {
                  title: 'Workplace/Teams notifications not coming through',
                },
                {
                  title: 'Add more detail to notifications like who changed what in a ticket not just ticket is updated',
                },
                {
                  title: 'Lots of valued customers when a new ticket is created',
                },
                {
                  title: 'Tabs to remember input when switching between them',
                },
                {
                  title: 'Cant manually add documents to a ticket',
                },
                {
                  title: 'Cant add images to forms',
                },
                {
                  title: 'Cannot see customer images in the ticket view page.'
                },
                {
                  title: 'Resource tracker not showing the correct data'
                },
                {
                  title: 'Child tickets not showing in the ticket table'
                },
                {
                  title: 'Ticket SLA not accuately displaying the times'
                },
                {
                  title: 'Child ticket requesters are not getting updates made to the parent ticket'
                },
                {
                  title: 'After updating a ticket, notifications say it was updated by someone else'
                },
                {
                  title: 'Cannot add a device to a ticket'
                },
                {
                  title: 'Incoming emails for resolved tickets not being actioned accordingly'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      date: '09/02/2024',
      title: 'Hide Ticket Button',
      version: '3.0.8',
      descriptions: 'Button to make a ticket hidden from the public and some bug fixes.',
      sections: [
        {
          title: 'Features',
          items: [
            {
              title: 'Ticket View Page',
              items: [
                {
                  title: 'Added ability to hide a ticket from the public, eye button has replaced the favourite button. Click it and then add a note and the ticket will be hidden from the public. This is demonstrated with a slash through the eye.',
                }
              ]
            }
          ]
        },
        {
          title: 'Bug Fixes',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Inactive users or organizations will no longer show in the dropdowns',
                },

              ],
            },
            {
              title: 'Backend',
              items: [
                {
                  title: 'Fixed login issues for some users'
                }
              ]
            }
          ]
        },
        {
          title: 'Known Issues',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Project tickets missing from the tickets table',
                },
                {
                  title: 'Hotel Co new starter form',
                },
                {
                  title: 'Workplace/Teams notifications not coming through',
                },
                {
                  title: 'Add more detail to notifications like who changed what in a ticket not just ticket is updated',
                },
                {
                  title: 'Lots of valued customers when a new ticket is created',
                },
                {
                  title: "Some dropdowns saying 'rendering' when they should be showing the options",
                },
                {
                  title: 'Tabs to remember input when switching between them',
                },
                {
                  title: 'Legacy notes missing on a few tickets',
                },
                {
                  title: 'Cant manually add documents to a ticket',
                },
                {
                  title: 'Cant add images to forms',
                },
                {
                  title: 'Thai language translations being incorrect.'
                },
                {
                  title: '404 error on attempting to view a ticket.'
                },
                {
                  title: 'Cannot see customer images in the ticket view page.'
                },
                {
                  title: 'The tooltip for the Alert bell is tooltip.alert not defined.'
                },
              ]
            }
          ]
        }
      ]
    },
    {
      date: '08/02/2024',
      title: 'Timeline Feature',
      version: '3.0.7',
      descriptions: 'Ticket Teams rollout and bug fixes.',
      sections: [
        {
          title: 'Features',
          items: [
            {
              title: 'Ticket View Page',
              items: [
                {
                  title: 'Timeline now enabled on ticket view page',
                }
              ]
            }
          ]
        },
        {
          title: 'Known Issues',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Project tickets missing from the tickets table',
                },
                {
                  title: 'Hotel Co new starter form',
                },
                {
                  title: 'Workplace/Teams notifications not coming through',
                },
                {
                  title: 'Add more detail to notifications like who changed what in a ticket not just ticket is updated',
                },
                {
                  title: 'Lots of valued customers when a new ticket is created',
                },
                {
                  title: "Some dropdowns saying 'rendering' when they should be showing the options",
                },
                {
                  title: 'Tabs to remember input when switching between them',
                },
                {
                  title: 'Legacy notes missing on a few tickets',
                },
                {
                  title: 'Cant manually add documents to a ticket',
                },
                {
                  title: 'Cant add images to forms',
                },
                {
                  title: 'Thai language translations being incorrect.'
                },
                {
                  title: '404 error on attempting to view a ticket.'
                },
              ],
            }
          ]

        }
      ]
    },
    {
      date: '07/02/2024',
      title: 'Various Bug Fixes and Improvements',
      version: '3.0.6',
      descriptions: 'Ticket Teams rollout and bug fixes.',
      sections: [
        {
          title: 'Bug Fixes and Improvements',
          items: [
            {
              title: 'Improvements',
              items: [
                {
                  title: 'Changed permissions to be based off of teams',
                },
                {
                  title: 'Assigned Team are update when you assign the engineer'
                },
                {
                  title: 'Updated the team options in the filters for the ticket table'
                },
              ]
            },
            {
              title: 'Bug Fixes',
              items: [
                {
                  title: 'Fixed missing translations for child and parent tickets.'
                },
                {
                  title: 'Fixed Styling for child and parent tickets'
                },
                {
                  title: 'Fixed low priority displaying as urgent'
                },
                {
                  title: 'Fixed search not resetting when you delete the search input'
                },
                {
                  title: 'Disabled debugging mode for forms'
                },
                {
                  title: 'Typo on feedback form'
                }
              ]
            },
          ]
        },
        {
          title: 'Known Issues',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Project tickets missing from the tickets table',
                },
                {
                  title: 'Hotel Co new starter form',
                },
                {
                  title: 'Workplace/Teams notifications not coming through',
                },
                {
                  title: 'Add more detail to notifications like who changed what in a ticket not just ticket is updated',
                },
                {
                  title: 'Lots of valued customers when a new ticket is created',
                },
                {
                  title: "Some dropdowns saying 'rendering' when they should be showing the options",
                },
                {
                  title: 'Tabs to remember input when switching between them',
                },
                {
                  title: 'Legacy notes missing on a few tickets',
                },
                {
                  title: 'Cant manually add documents to a ticket',
                },
                {
                  title: 'Cant add images to forms',
                },
                {
                  title: 'Thai language translations being incorrect.'
                },
                {
                  title: '404 error on attempting to view a ticket.'
                },
              ],
            }
          ]

        }
      ]
    },
    {
      date: '06/02/2024',
      title: 'Ticket Table Improvements and bug fixes',
      version: '3.0.5',
      descriptions: 'We have made some caching to the ticket table and fixed some bugs in both the ticket table and table view.',
      sections: [
        {
          title: 'Bug Fixes and Improvements',
          items: [
            {
              title: 'Improvements',
              items: [
                {
                  title: 'The ticket table now remebers the your unsaved search when you change page.',
                },
                {
                  title: 'The ticket table now remebers the your unsaved filters when you change page.',
                },
                {
                  title: 'The ticket table now remebers the your unsaved sort when you change page.',
                },
                {
                  title: 'The ticket table now remebers the your unsaved pagination when you change page.',
                }
              ]
            },
            {
              title: 'Bug Fixes',
              items: [
                {
                  title: 'Fixed a crash caused by a user not having permissions to access a child ticket.'
                },
                {
                  title: 'Fixed missing translations for child and parent tickets.'
                }
              ]
            },
          ]
        },
        {
          title: 'Known Issues',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Project tickets missing from the tickets table',
                },
                {
                  title: 'Hotel Co new starter form',
                },
                {
                  title: 'Workplace/Teams notifications not coming through',
                },
                {
                  title: 'Add more detail to notifications like who changed what in a ticket not just ticket is updated',
                },
                {
                  title: 'Lots of valued customers when a new ticket is created',
                },
                {
                  title: "Some dropdowns saying 'rendering' when they should be showing the options",
                },
                {
                  title: 'Tabs to remember input when switching between them',
                },
                {
                  title: 'Legacy notes missing on a few tickets',
                },
                {
                  title: 'Cant manually add documents to a ticket',
                },
                {
                  title: 'Cant add images to forms',
                },
                {
                  title: 'Thai language translations being incorrect.'
                },
                {
                  title: '404 error on attempting to view a ticket.'
                },
                {
                  title: "No ability to view child tickets in the ticket view page."
                },
                {
                  title: "Team Filters on the ticket table."
                }
              ],
            },
          ],
        },
      ]
    },
    {
      date: '03/02/2024',
      title: 'Triage Global View For Engineers',
      version: '3.0.4',
      descriptions: 'We have addeed a triage view to all engineers for the Tickets table. It shows all unassigned tickets sorted by date created. This is to help engineers see all unassigned tickets and help with the triage process.',
      sections: [
        {
          title: 'Bug Fixes and Improvements',
          items: [
            {
              title: 'Improvements',
              items: [
                {
                  title: 'Triage view added to the tickets table for all engineers',
                },
              ]
            },
          ]
        },
        {
          title: 'Known Issues',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Project tickets missing from the tickets table',
                },
                {
                  title: 'Hotel Co new starter form',
                },
                {
                  title: 'Workplace/Teams notifications not coming through',
                },
                {
                  title: 'Add more detail to notifications like who changed what in a ticket not just ticket is updated',
                },
                {
                  title: 'Lots of valued customers when a new ticket is created',
                },
                {
                  title: "Some dropdowns saying 'rendering' when they should be showing the options",
                },
                {
                  title: 'Tabs to remember input when switching between them',
                },
                {
                  title: 'Legacy notes missing on a few tickets',
                },
                {
                  title: 'Cant manually add documents to a ticket',
                },
                {
                  title: 'Cant add images to forms',
                },
              ],
            },
          ],
        },
      ]
    },
    {
      date: '02/02/2024',
      title: 'February 2024 Update Small Bug Fixes',
      version: '3.0.3',
      description: '',
      sections: [
        {
          title: 'Bug Fixes and Improvements',
          items: [
            {
              title: 'Bug Fixes',
              items: [
                {
                  title: 'Fixed a bug that make inputting whitespace count towards the comment character limit',
                },
                {
                  title: 'Fixed an issue downloading the eml from the ticket comments',
                },
                {
                  title: 'Fixed an issue with some tickets not opening when person id was not found',
                },
                {
                  title: 'Fixed react ref warnings for tooltips',
                },
                {
                  title: 'Fixed ticket page react warnings',
                },
                {
                  title: 'Fixed search bar for notes not working',
                },
                {
                  title: "New starter and leaver forms where not always completing as expected"
                },
                {
                  title: "Fixed an issue with dateTime fields rendering incorrectly on forms"
                }
              ],
            },
            {
              title: 'Improvements',
              items: [
                {
                  title: "Ability to change tou default view on the tickets table with your custom search filters"
                },
                {
                  title: 'Moved language selector tooltip to not overlap the selections',
                },
                {
                  title: 'Copied ticket number notification now auto closes after 2 seconds',
                },
                {
                  title: 'Search is now case insensitive',
                },
                {
                  title: 'Updated translations',
                },
                {
                  title: 'Error page styling updated',
                },
                {
                  title: 'Added tooltips to Documents eye icons',
                },
                {
                  title: 'Documents / Attachments now easier to click on',
                },
                {
                  title: 'Export button on ticket table now enabled',
                },
                {
                  title: "Added team to the ticket table so it can be filtered by the header filters as a short term work around"
                },
                {
                  title: "Pagination options for the tables are now saved when switching between tabs and refreshing the page"
                },
                {
                  title: "Updated the new incident form to have more accurate titles for the related ticket"
                }
              ],
            },
          ],
        },
        {
          title: 'Backend Changes',
          items: [
            {
              title: 'New Features',
              items: [
                {
                  title: 'Mail parser now better handles email attachments',
                },
              ],
            },
          ],
        },
        {
          title: 'Known Issues',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Project tickets missing from the tickets table',
                },
                {
                  title: 'Hotel Co new starter form',
                },
                {
                  title: 'Workplace/Teams notifications not coming through',
                },
                {
                  title: 'Add more detail to notifications like who changed what in a ticket not just ticket is updated',
                },
                {
                  title: 'Lots of valued customers when a new ticket is created',
                },
                {
                  title: "Some dropdowns saying 'rendering' when they should be showing the options",
                },
                {
                  title: 'Tabs to remember input when switching between them',
                },
                {
                  title: 'Legacy notes missing on a few tickets',
                },
                {
                  title: 'Cant manually add documents to a ticket',
                },
                {
                  title: 'Cant add images to forms',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      date: '29/01/2024',
      title: 'January 2024 Update - Bug Fixes and Improvements',
      description:
        "We are excited to announce the latest development release that addresses several issues and brings improvements to enhance your experience. Here's a summary of the fixes",
      sections: [
        {
          title: 'New Features',
          items: [
            {
              title: 'Ticket View Page',
              items: [
                {
                  title: 'Added CC pod to ticket view page',
                },
                {
                  title: 'Added ability to bundle tickets together from the ticket view page',
                },
                {
                  title: 'Added comments, cc, and attachments to the ticket view page from child tickets',
                },
                {
                  title: 'Added ability to copy ticket number on the ticket view page',
                },
              ],
            },
            {
              title: 'Tickets Table',
              items: [
                {
                  title: "Quick close added to ticket's view page, this will close the ticket and add a note with the reason for closing",
                },
              ],
            },
            {
              title: 'Site Wide',
              items: [
                {
                  title: 'Backend admin panel now in beta',
                },
                {
                  title: 'Added error pages',
                },
                {
                  title: 'Added release notes sections',
                },
              ],
            },
          ],
        },
        {
          title: 'Bug Fixes and Improvements',
          items: [
            {
              title: 'Bug Fixes',
              items: [
                {
                  title: 'Fixed issue related to general logging in',
                },
                {
                  title: 'removed extra m in the sla time frame',
                },
                {
                  title: 'Fixed error that sometimes occurred when creating a ticket row',
                },
                {
                  title: 'Fixed issue around sla time left calculation',
                },
                {
                  title: 'Fixed redirect on login to go to the correct page',
                },
                {
                  title: 'Fixed issue where the add note that would sometime cause the page to crash',
                },
                {
                  title: 'Fixed but that sometimes showed the requestor to not show',
                },
              ],
            },
            {
              title: 'Improvements',
              items: [
                {
                  title: 'Updated translations',
                },
                {
                  title: 'Removed child tickets from the ticket view page',
                },
                {
                  title: 'Added scrolling to the tickets view filters',
                },
                {
                  title:
                    "Saved view's completely reworked to be more responsive and faster, the selected view now works across changing tabs and refreshing the page",
                },
                {
                  title: 'Added tooltips to header buttons',
                },
                {
                  title: 'Updated language used for assignee and team members on the ticket view page',
                },
              ],
            },
          ],
        },
        {
          title: 'Backend Changes',
          items: [
            {
              title: 'Bug Fixes',
              items: [
                {
                  title: 'Fixed issue with websocket disconnecting',
                },
                {
                  title: 'Fixed issue with websocket crashing when some uers logged in',
                },
                {
                  title: 'Fixed issue with Hotel Co emails being incorrect in the DB',
                },
                {
                  title: 'Various permission fixes',
                },
                {
                  title: 'Fixed issue with workflow server not staring up correctly',
                },
              ],
            },
          ],
        },
        {
          title: 'Known Issues',
          items: [
            {
              title: 'Frontend',
              items: [
                {
                  title: 'Cant Export tickets from the tickets table',
                },
                {
                  title: 'Going back to ticket table goes back to first page',
                },
                {
                  title: 'Project tickets missing from the tickets table',
                },
                {
                  title: 'Copied ticket number notification does not auto close',
                },
                {
                  title: 'Change of default filters including Hold and Pending to be reviewed',
                },
                {
                  title: 'Hotel Co new starter form',
                },
                {
                  title: 'Opening eml does not work',
                },
                {
                  title: 'Search is case insensitive',
                },
                {
                  title: 'Workplace/Teams notifications not coming through',
                },
                {
                  title: 'Add more detail to notifications like who changed what in a ticket not just ticket is updated',
                },
                {
                  title: 'Lots of valued customers when a new ticket is created',
                },
                {
                  title: "Some dropdowns saying 'rendering' when they should be showing the options",
                },
                {
                  title: 'Tabs to remember input when switching between them',
                },
                {
                  title: 'Hard to open attachments clickable area is to small',
                },
                {
                  title: 'Legacy notes missing on a few tickets',
                },
                {
                  title: 'Cant manually add documents to a ticket',
                },
                {
                  title: 'Cant filter by team',
                },
                {
                  title: 'Cant add images to forms',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      date: '12/01/2024',
      title: 'Bug Fixes and Improvements in Manage 3.0 since launch',
      description:
        "We are excited to announce the latest development release that addresses several issues and brings improvements to enhance your experience. Here's a summary of the fixes",
      sections: [
        {
          title: 'Performance Tweaks for Improved Speed',
          items: [
            {
              title: 'Optimized Loading Times',
              items: [
                {
                  title:
                    'Our development team has fine-tuned various aspects of the system to reduce loading times significantly. You can expect a smoother and more responsive experience when navigating through different sections.',
                },
              ],
            },
            {
              title: 'Efficient Resource Utilization',
              items: [
                {
                  title:
                    'We have analyzed and optimized resource usage to ensure a more efficient allocation of system resources. This leads to improved performance without compromising on functionality.',
                },
              ],
            },
            {
              title: 'Streamlined Database Queries',
              items: [
                {
                  title:
                    'Refactored and streamlined database queries to enhance the overall efficiency of data retrieval. This results in quicker access to information and a more responsive user interface.',
                },
              ],
            },
            {
              title: 'Continuous Monitoring and Iterative Improvements',
              items: [
                {
                  title:
                    'Our team is committed to continuous monitoring of system performance. We will use the feedback and usage data to make iterative improvements, ensuring an optimized experience for all users.',
                },
              ],
            },
          ],
        },
        {
          title: 'Bug Fixes and Improvements',
          items: [
            {
              title: 'Ticket Search Functionality',
              items: [
                {
                  title:
                    'Users reported issues with the search function not working as expected. We have addressed this problem, and you should now experience improved search capabilities.',
                },
              ],
            },
            {
              title: 'Assignee List Enhancement',
              items: [
                {
                  title: 'The assignee list for tickets has been refined. It now accurately reflects members of the SSG.',
                },
              ],
            },
            {
              title: 'User Interface Enhancements',
              items: [
                {
                  title:
                    "Moved the 'X' button on tabs and items from the top right to the top left for a more user-friendly experience. This change aims to make it easier for users to close tabs without adjusting the mouse position.",
                },
                {
                  title:
                    'Resolved the column dragging issue where columns would revert to their original positions after a few seconds. Users can now seamlessly customize their interface by dragging columns into new positions.',
                },
                {
                  title:
                    'Addressed the problem of newly created ticket board views disappearing after a certain period. Your custom views should now persist without any unexpected removal. You can now also delete and replace views.',
                },
                {
                  title: 'Decreased the default size on the ticket board; you can now also pick between 3 size options.',
                },
                {
                  title: 'Number of rows per page on the ticket view table changed to 10, 25, 50.',
                },
              ],
            },
            {
              title: 'Ticket Number Copy Functionality',
              items: [
                {
                  title: 'Implemented an easy-to-use ticket number copy feature. Users can now conveniently copy ticket numbers without any hassle.',
                },
              ],
            },
            {
              title: 'Right-hand Panel Reorganization',
              items: [
                {
                  title:
                    'Re-arranged the right-hand panel in tickets to prioritize more relevant information at the top. The new order is as follows: information, engineer, organization, contact, with additional details below.',
                },
              ],
            },
            {
              title: 'UI Icon Update',
              items: [
                {
                  title:
                    "Changed the '+' icon beside contact and organization to a pen icon for better alignment with the button's function, providing a clearer user interface.",
                },
              ],
            },
            {
              title: 'Persistent Filters',
              items: [
                {
                  title:
                    'Resolved the issue where filters would clear and refresh when changing tabs. Users can now enjoy a smoother experience with filters persisting across different tabs.',
                },
              ],
            },
            {
              title: 'Floating Beta Button Fix',
              items: [
                {
                  title: 'Addressed the redirection issue with the floating beta button, ensuring it now correctly directs users to the fixed form.',
                },
              ],
            },
            {
              title: 'Typographical Errors in Emails',
              items: [
                {
                  title: 'Corrected typos and grammatical errors in email templates to ensure accurate and professional communication',
                },
              ],
            },
            {
              title: 'Ticket Page UI',
              items: [
                {
                  title: 'Fixed issue stopping some engineers from opening the ticket page',
                },
              ],
            },
            {
              title: "Changed 'MM' to 'M' for SLA Metrics",
              items: [
                {
                  title:
                    "Addressed a clarity issue in the SLA User Interface by updating the unit of measurement for certain metrics. 'MM' (minutes) has been changed to 'M' (minutes) for consistency and to avoid any confusion regarding the unit.",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  return (
    <PageBase title='releaseNotesPage.title'>
      {
        releaseNotes.map((releaseNote, index) => {
          return (
            <Card key={index} sx={{ marginBottom: '20px' }}>
              <CardContent>
                <Box sx={{ marginBottom: '20px' }}>
                  <Box sx={{ marginBottom: '20px' }}>
                    <Typography variant='h4' gutterBottom>
                      <strong>{releaseNote.title}</strong>
                    </Typography>
                    <Typography variant='h6' gutterBottom>
                      {releaseNote.date}
                    </Typography>
                  </Box>
                  <Typography variant='body' gutterBottom>
                    {releaseNote.description}
                  </Typography>
                </Box>
                {releaseNote.sections.map((section, index) => {
                  return (
                    <>
                      <Typography variant='h5' gutterBottom>
                        <strong>{section.title}</strong>
                      </Typography>
                      <ul key={index} style={{ marginBottom: '20px' }}>
                        {section.items.map((item, index) => {
                          return (
                            <li key={index} style={{ listStyle: 'none', marginBottom: '20px' }}>
                              <Typography variant='body' gutterBottom>
                                {item?.title?.toUpperCase()}:
                              </Typography>
                              {item?.items?.map((item, index) => {
                                return (
                                  <ul key={index} style={{ listStyle: 'initial' }}>
                                    <li>
                                      <Typography variant='body' gutterBottom>
                                        {item.title}
                                      </Typography>
                                    </li>
                                  </ul>
                                );
                              })}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  );
                })}
              </CardContent>
            </Card>
          );
        })
      }
    </PageBase >
  );
}

ReleaseNotes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true },
)(ReleaseNotes);
