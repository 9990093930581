const pages = {
  'dashboard.pageTitle': 'Dashboard',
  'dashboard.greeting.morning': 'Goedemorgen',
  'dashboard.greeting.afternoon': 'Goedemiddag',
  'dashboard.greeting.evening': 'Goedenavond',
  'dashboard.welcomeMessage': 'Je hebt {tickets} actieve tickets en {messages} berichten om op te reageren.',
  'notAuthorizedPage.title': 'Niet Geautoriseerd',
  'notAuthorizedPage.desc': 'Je hebt geen toegang tot deze pagina.',
  'dashboard.welcomeText': 'Welkom bij {ssg}!',
  'dashboard.button.addWidget': 'Widget Toevoegen',
  'dashboard.button.close': 'Sluiten',
  'dashboard.button.edit': 'Bewerken',
  'dashboard.button.save': 'Opslaan',
  'settingsPage.pageTitle': 'Instellingen',
  'ticketViewPage.priority': 'Prioriteit',
  'ticketViewPage.assignee': 'Toegewezen aan',
  'ticketViewPage.team': 'Team',
  'ticketViewPage.sixStarTeam': 'Aanvullende Six Star Teamleden',
  'ticketViewPage.resourcesOnTicket': 'Middelen Op Ticket',
  'ticketViewPage.followers': 'Volgers',
  'ticketViewPage.tags': 'Tags',
  'ticketViewPage.takeIt': 'neem het',
  'ticketViewPage.notes': 'Notities',
  'ticketViewPage.activity': 'Activiteit',
  'ticketViewPage.timeline': 'Tijdlijn',
  'ticketViewPage.tasks': 'Taken',
  'ticketViewPage.search': 'Zoeken',
  'ticketViewPage.noteAddedBy': 'Notitie toegevoegd door',
  'ticketViewPage.moreButton': 'Meer',
  'ticketViewPage.makeInternal': 'Intern Maken',
  'ticketViewPage.makePublic': 'Openbaar Maken',
  'ticketViewPage.deleteComment': 'Verwijderen',
  'ticketViewPage.reportComment': 'Rapporteren',
  'ticketViewPage.internalTicketComment': 'Dit is een interne opmerking',
  'ticketViewPage.publicTicketComment': 'Dit is een openbare opmerking',
  'ticketViewPage.ticketSource.internal_web': 'Intern Web',
  'ticketViewPage.ticketSource.external_email': 'Interne Email',
  'ticketViewPage.followTicket': 'Ticket Volgen',
  'ticketViewPage.unfollowTicket': 'Ticket Niet Meer Volgen',
  'ticketViewPage.tasks.priority.completed': 'Voltooid',
  'ticketViewPage.tasks.priority.in_progress': 'Bezig',
  'ticketViewPage.tasks.priority.not_started': 'Niet Gestart',
  'ticketViewPage.tasks.priority.low': 'Laag',
  'ticketViewPage.tasks.priority.medium': 'Middel',
  'ticketViewPage.tasks.priority.important': 'Belangrijk',
  'ticketViewPage.tasks.priority.urgent': 'Dringend',
  'ticketViewPage.tasks.assignedTo': 'Toegewezen Aan',
  'ticketViewPage.tasks.completedBy': 'Voltooid Door',
  'ticketViewPage.requester': 'Aanvrager',
  'ticketViewPage.organization': 'Organisatie',
  'ticketViewPage.button.cancel': 'Annuleren',
  'ticketViewPage.button.close': 'Sluiten',
  'ticketViewPage.downloadEml': 'EML Downloaden',
  'newRequestPage.header': 'Nieuwe Aanvraag',
  'newRequestPage.button.internalRequest': 'Interne Aanvraag',
  'newRequestPage.button.serviceRequest': 'Dienstaanvraag',
  'newRequestPage.button.procurementRequest': 'Inkoopaanvraag',
  'newRequestPage.card.urgentHelp': 'Dringend hulp nodig?',
  'newRequestPage.button.callUs': 'Bel Ons',
  'newRequestPage.button.passwordReset': 'Wachtwoordreset voor Domein/Computer',
  'newRequestPage.button.newRequest': 'Ondersteuningsverzoek',
  'formPage.title.supportRequest': 'Ondersteuningsverzoek',
  'formPage.title.newStarterRequest': 'Nieuw Starterverzoek',
  'formPage.title.serviceRequest': 'Dienstaanvraag',
  'formPage.title.newStarter': 'Nieuwe Starter / Rolwijziging',
  'formPage.title.leaver': 'Vertrekker',
  'formPage.title.engineerVisit': 'Bezoek van Ingenieur',
  'formPage.title.procurementRequest': 'Inkoopaanvraag',
  'formPage.title.developmentRequest': 'Ontwikkelingsverzoek',
  'formPage.title.internalRequest': 'Interne Aanvraag',
  'ldapPasswordResetPage.button.submit': 'Wachtwoord Resetten',
  'ldapPasswordResetPage.button.generatePassword': 'Wachtwoord Genereren',
  'ldapPasswordResetPage.title': 'Wachtwoordreset voor Domein/Computer',
  'ldapPasswordResetPage.paragraph.intro':
    'Reset je computerdomein wachtwoord. Afhankelijk van het beleid van je hotel moet je misschien elke 90 dagen je wachtwoord wijzigen.',
  'licencePage.title': 'Licenties',
  'licencePage.office365Licences': 'Office 365 Licenties',
  'licencePage.license': 'Licentie',
  'licencePage.active': 'Actief',
  'licencePage.consumed': 'Gebruikt',
  'licencePage.unused': 'Ongebruikt',
  'assetPage.title': 'Middelen',
  'resourceTracker.title': 'Middelen Tracker',
  'ticketViewPage.overview': 'Overzicht',
  'ticketViewPage.roles': 'Rollen',
  'ticketViewPage.addContact': 'Contact Toevoegen',
  'ticketViewPage.status': 'Status',
  'ticketViewPage.createdAt': 'Aangemaakt Op',
  'ticketViewPage.updatedAt': 'Bijgewerkt Op',
  'ticketViewPage.devices': 'Apparaten',
  'ticketViewPage.contacts': 'Contacten',
  'ticketViewPage.documents': 'Documenten',
  'ticketViewPage.addNote': 'Notitie Toevoegen',
  'ticketViewPage.info': 'Info',
  'ticketViewPage.created': 'Gecreëerd',
  'ticketViewPage.lastActivity': 'Laatste Activiteit',
  'ticketViewPage.priorityLevel': 'Prioriteitsniveau',
  'ticketViewPage.assigned': 'Toegewezen',
  'ticketViewPage.ticketSLA': 'Ticket SLA',
  'ticketViewPage.firstResponse': 'Eerste Reactie',
  'ticketViewPage.resolution': 'Resolutie',
  'ticketViewPage.nextUpdate': 'Volgende Update',
  'ticketViewPage.mostRecentMessages': 'Meest Recente Bericht',
  'organizationViewPage.overview': 'Overzicht',
  'organizationViewPage.activity': 'Activiteit',
  'assetPage.name': 'Naam',
  'assetPage.type': 'Type',
  'assetPage.status': 'Status',
  'assetPage.serial': 'Serie',
  'assetPage.manufacturer': 'Fabrikant',
  'assetPage.cpu': 'CPU',
  'assetPage.memory': 'Geheugen',
  'assetPage.lastUser': 'Laatst Gezien',
  'assetPage.lastSeen': 'Laatst Gezien Op',
  'assetPage.operatingSystem': 'Besturingssysteem',
  'sixStarMeet.header': 'De Technologie Achter Elke Geweldige Vergadering',

  'ticketViewPage.approvedAt': 'Goedgekeurd op',
  'ticketViewPage.rejectedAt': 'Afgewezen op',
  'ticketViewPage.approvedBy': 'Goedgekeurd door',
  'ticketViewPage.rejectedBy': 'Afgewezen door',
  'ticketViewPage.approvalStatus': 'Goedkeuringsstatus',
  'ticketViewPage.approvalStatus.approved': 'Goedgekeurd',
  'ticketViewPage.approvalStatus.rejected': 'Afgewezen',
  'ticketViewPage.approvalStatus.pending': 'In afwachting',
  'ticketViewPage.requestedBy': 'Aangevraagd door',

  // Eerste keer instellen pagina
  'firstTimeSetupPage.firstLookAtManage': 'Eerste kennismaking met Manage 3.0',
  'firstTimeSetupPage.elevatingYourITManagementExperience': 'Verbeter uw IT-beheerervaring',
  'firstTimeSetupPage.weAreThrilledToPresent':
    'We zijn verheugd om de teaser te presenteren voor een nieuw hoofdstuk in uw IT-beheerreis met de bèta van Six Star: Manage 3.0.',
  'firstTimeSetupPage.asAValuedMember':
    'Als gewaardeerde leden van de Six Star Global-familie, die al profiteren van onze oplossingen, introduceren we u graag bij wat een aangepaste en verbeterde ervaring zal zijn, waardoor uw IT-beheer dynamischer, gestroomlijnder en intuïtiever wordt.',
  'firstTimeSetupPage.aNewChapterInITManagement': 'Een nieuw hoofdstuk in IT-beheer',
  'firstTimeSetupPage.technologicalTakeOver': 'Een technologische make-over:',
  'firstTimeSetupPage.redesign':
    'Een modern redesign dat rekening houdt met snelheid en stabiliteit. Prestatie is een topprioriteit voor ons, dus we hebben ervoor gezorgd dat u overal, op mobiel of op desktop, uw IT-portfolio binnen handbereik heeft.',
  'firstTimeSetupPage.effortlessLicenseOverview': 'Zonder moeite licentieoverzicht:',
  'firstTimeSetupPage.simplifyLicenceManagement':
    'Vereenvoudig moeiteloos licentiebeheer. Manage 3.0 stelt u in staat om naadloos software- en gebruikerslicenties te beheren, zodat naleving wordt gegarandeerd en uitgaven worden geoptimaliseerd.',
  'firstTimeSetupPage.smartReportGeneration': 'Slimme rapportgeneratie: ',
  'frstTimeSetupPage.harnessThePowerOfData':
    'Benut de kracht van gegevens voor strategische besluitvorming. Genereer op maat gemaakte rapporten met behulp van ticketstatistieken, waarmee u operaties kunt verfijnen en de servicekwaliteit binnen uw organisatie kunt verbeteren.',
  'firstTimeSetupPage.tailoredDashboardAtYourFingertips': 'Aangepast dashboard binnen handbereik: ',
  'firstTimeSetupPage.craftDashboard':
    'Maak een gepersonaliseerd IT-dashboard met diverse widgets, waardoor u snel inzicht krijgt in ticketstatussen en trends die aansluiten bij uw specifieke interesses.',
  'firstTimeSetupPage.benefitsTitle': 'Voordelen van Manage 3.0 voor uw hotelactiviteiten',
  'firstTimeSetupPage.amplifiedEfficiency': 'Versterkte efficiëntie: ',
  'firstTimeSetupPage.guestExperience':
    'Richt u op het leveren van uitstekende gastervaringen, met geautomatiseerde processen die problemen naadloos aanpakken.',
  'firstTimeSetupPage.realTimeIntelligence': 'Realtime intelligentie: ',
  'firstTimeSetupPage.instantlyAccess': 'Direct toegang tot up-to-date gegevens en ticketstatistieken voor snelle, geïnformeerde beslissingen.',
  'firstTimeSetupPage.tailoredToYou': 'Aangepast aan u: ',
  'firstTimeSetupPage.customiseDashboard':
    'Een op maat gemaakt dashboard toont precies de informatie die u nodig heeft en biedt een uitgebreid overzicht van uw IT-operaties.',
  'firstTimeSetupPage.futureProof': 'Toekomstbestendige garantie:',
  'firstTimeSetupPage.atThisIminentLaunch':
    'Aangezien deze aanstaande lancering slechts de bètaversie is, kunt u regelmatige updates en innovatieve functies verwachten die gelijke tred houden met de veranderende behoeften van uw IT-infrastructuur.',
  'firstTimeSetUpPage.button.getStarted': 'Aan de slag',

  // Laden
  'loading.title': 'Laden...',

  // Ticketweergave
  'ticketView.activityModule.title': 'Activiteit',
  'ticketView.emailModule.title': 'E-mails',
  'ticketView.infoModule.subheaderOne': 'Ondersteuning',
  'ticketView.infoModule.subheaderTwo': 'Technisch team',
  'ticketView.infoModule.value 1': 'Optie 1',
  'ticketView.infoModule.value 2': 'Optie 2',
  'ticketView.infoModule.value 3': 'Optie 3',
  'ticketView.infoModule.value 4': 'Optie 4',
  'ticketView.notesModule.title': 'Notities',
  'ticketView.tasksModule.title': 'Taken',

  'ticketViewPage.modal.bundle.title': 'Tickets bundelen',
  'ticketViewPage.modal.bundle.parentTicket': 'Hoofdticket',
  'ticketViewPage.modal.bundle.childTicket': 'Subticket',
  'ticketViewPage.modal.bundle.button': 'Bundel',
  'ticketViewPage.modal.bundle.button.cancel': 'Annuleren',
  'companyContactPage.tabs.overview': 'Overzicht',
  'companyContactPage.tabs.activities': 'Activiteiten',
  'companyContactPage.tabs.tasks': 'Taken',
  'companyContactPage.tabs.overview.lastActivity': 'Laatste activiteit',
  'companyContactPage.tabs.overview.customerStatus': 'Klantstatus',
  'companyContactPage.tabs.overview.userCreated': 'Gebruiker aangemaakt',
  'companyContactPage.tabs.overview.company': 'Bedrijf',
  'companyContactPage.tabs.overview.expiringLicences': 'Verlopende licenties',
  'companyContactPage.tabs.overview.opemTickets': 'Open tickets',
  'companyContactPage.tabs.overview.mostRecentActivity': 'Meest recente activiteit',
  'companyContactPage.tabs.overview.Drive': 'Drive',
  'companyContactPage.tabs.overview.lastActivity: ': 'Laatste activiteit: ',
  'companyContactPage.tabs.search': 'Zoeken',
  'companyContactPage.tabs.overview.AddedNote': 'Toegevoegde opmerking',
  'companyContactPage.tabs.sidePanel.contacts': 'Contacten',
  'companyContactPage.tabs.sidePanel.other': 'Overige',
  'companyContactPage.tabs.sidePanel.licenses': 'Licenties',
  'companyContactPage.tabs.sidePanel.procurement': 'Inkoop',
  'companyContactPage.tabs.sidePanel.assets': 'Assets',
  'companyContactPage.tabs.sidePanel.invoices': 'Facturen',
  'companyContactPage.tabs.sidePanel.company': 'Bedrijf',
  'ticketViewPage.checklist': 'Checklist',
  'ticketViewPage.attachments': 'Bijlagen',
  'ticketViewPage.relatedItems': 'Gerelateerde items',
  'sixStarTVPage.header': 'Leiders in functionaliteit, stabiliteit en gebruiksgemak',
  'sixStarTVPage.integration': 'Integratie',
  'sixStarTVPage.webBasedManagement': 'Webgebaseerd beheer',
  'sixStarTVPage.gaming': 'Gaming',
  'sixStarTVPage.fullyCustomisable': 'Volledig aanpasbaar voor een merkervaring',
  'sixStarTVPage.extraFeatures': 'Een hele reeks extra functies',
  'sixStarTVPage.plus': 'met Six Star: TV Plus',
  'sixStarTVPage.offer':
    "Six Star: TV Plus biedt een scala aan extra's om het verblijf van uw gasten nog waardevoller te maken en tegelijkertijd die belangrijke extra verkopen veilig te stellen.",
  'sixStarTVPage.wakeUp': 'Wakker worden',
  'sixStarTVPage.internationalViewing': 'Internationale weergave',
  'sixStarTVPage.roomService': 'Roomservice',
  'sixStar.GetInTouch': 'Neem contact met ons op',
  'sixStar.BackToTop': 'Terug naar boven',
  'quickCreateTicketModal.title': 'Snel ticket aanmaken',
  'quickCreateTicketModal.button.submit': 'Verzenden',
  'quickCreateTicketModal.button.submitAndView': 'Verzenden en ticket bekijken',
  'quickCreateTicketModal.disclaimer': 'Dit ticket wordt automatisch gepost als medium prioriteit en de status als NIEUW',
  'quickCreateTicketModal.success': 'Ticket succesvol aangemaakt',
  'quickCreateTicketModal.button.close': 'Sluiten',

  'contactUsPage.number.button': 'Klik hier',
  'contactUsPage.title.second': 'Bel ons',
  'contactUsPage.title.first': 'Hallo, hoe kunnen we je helpen?',
  'contactUsPage.header.boxs.one': 'Hoe log ik een ticket?',
  'contactUsPage.header.boxs.two': 'Hoe meld ik me af?',
  'contactUsPage.header.boxs.three': 'Waar zie ik al mijn tickets?',
  'contactUsPage.header.boxs.four': 'Hoe voeg ik widgets toe?',
  'contactUsPage.search.answers': 'Zoek naar antwoorden...',
  'contactUsPage.search': 'Zoek naar antwoorden...',

  //sixstar black page
  // "sixStarBlackPage.header": "Verhef Wereldreizen en Presidentiële Suites",
  // "sixStarBlackPage.traveler": "Reiziger",
  // "sixStarBlackPage.hotel": "Hotel",

  //sixstar vip page
  'sixStarVIPPage.header': "Executives' Keuze voor Snelle Oplossingen met GM Prioriteitstoegang",
  'sixStarVIPPage.directToSeniorSupport': 'Direct naar Senior Support',
  'sixStarVIPPage.contactCards': 'Contactkaarten',
  'sixStarVIPPage.reducedSLA': 'Verminderde SLA voor leidinggevenden',
  'sixStarVIPPage.contactCardsForGM': "Contactkaarten voor GM's",

  //sixstar cast page
  'sixStarCastPage.header': 'Gasten Entertainment vanuit Huis',
  'sixStarCastPage.oneStepAuthentication': 'Eénstapsauthenticatie',
  'sixStarCastPage.streamCastableApp': 'Gasten kunnen elke castbare app streamen',
  'sixStarCastPage.CloudManaged': 'Cloud Beheerd',

  // sixstar internet page
  'sixStarInternetPage.header': 'Verhef Connectiviteit, Verlaag Kosten, en Ontketen Ongelijke Ondersteuning',
  'sixStarInternetPage.lowCost': 'Lage Kosten',
  'sixStarInternetPage.mobileConnectivity': 'Mobiele Connectiviteit',
  'sixStarInternetPage.installations': 'Installaties',

  // sixstar display page
  'sixStarDisplayPage.header': 'Ruimtes Transformeren & Ervaringen Verhogen',
  'sixStarDisplayPage.workWithAnyScreen': 'Werkt met elk Scherm',
  'sixStarDisplayPage.wayFinding': 'Wegvinding & Aanbiedingsvinding',
  'sixStarDisplayPage.mobileWebEditor': 'Mobiele Web Editor',

  'sixStarPlus.getInTouch': 'Neem contact op',
  'sixStarTVPage.externalTVInputs': 'Externe TV-ingangen',
  'sixStarTVPage.p2':
    'Gebruik HDMI-poorten voor consoles en andere systemen, evenals apps zoals AirPlay en Six Star: Cast om gasten in staat te stellen te streamen vanaf hun eigen persoonlijke apparaten.',
  'sixStarTVPage.p3': 'Investeringskosten kunnen worden verlaagd door gebruik te maken van bestaande infrastructuur.',
  'sixStarTVPage.p4': 'In-house monitoring en contentbeheer rechtstreeks vanuit een browser.',
  'sixStarTVPage.p5': 'Een snel stijgende vraag voor horecadienstverleners die SS:TV met verve kan leveren.',
  'sixStarTVPage.fullyCustomizable1080p':
    'Een volledig aanpasbaar 1080p-menu zonder starre ontwerplimieten. Begin met op maat gemaakte sjablonen voor een soepele start of laat SSG helpen met het ontwerp.',

  'sixStarVoicePage.header': 'Ontdek de kracht van Six Star: Voice',
  'sixStarVoicePage.p1': 'Ongeëvenaarde communicatie',
  'sixStarVoicePage.advancedIntegrations': 'Geavanceerde integraties',
  'sixStarVoicePage.lineRedundancy': 'Lijn redundantie',
  'sixStarVoicePage.customisableSolutions': 'Aanpasbare oplossingen',
  'sixStarVoicePage.seamlessConnect': 'Maak naadloos verbinding met apps van derden voor veelzijdige systeemfunctionaliteit.',
  'sixStarVoicePage.enjoy': 'Geniet van ononderbroken communicatie met standaard lijn redundantie.',
  'sixStarVoicePage.tailor': 'Pas Six Star: Voice aan om uw unieke horecaoperaties te optimaliseren.',
  'sixStarVoicePage.p2': 'Naadloze communicatie',
  'sixStarVoicePage.sayGoodbye':
    'Zeg vaarwel tegen downtime. Ons systeem is gebouwd met lijn redundantie als standaardfunctie, wat zorgt voor ononderbroken communicatie, zelfs in het geval van onverwachte uitdagingen. We hebben ook toegewijde telefoonteamleden klaar om u te ondersteunen als dat nodig is.',

  'sixStarCastPage.low-Cost': 'Betaalbare castingoplossing voor gastapparaten die kan worden toegevoegd aan elke HDMI-compatibele tv.',
  'sixStarCastPage.guestCan': 'Gasten kunnen direct toegang krijgen tot hun entertainment zodra ze hun kamer binnenkomen.',
  'sixStarCastPage.netflix': 'Netflix, Hulu, Disney+ en nog veel meer beschikbaar.',
  'sixStarCastPage.easilyIntegrated': 'Gemakkelijk te integreren met bestaande tv-oplossingen.',
  'sixStarCastPage.p1':
    "Gebruik het branded marketingportaal voor directe communicatie met in-house gasten. Via het op web gebaseerde contentmanagementsysteem (CMS) kunnen marketingmedewerkers van het hotel gasten op de hoogte houden van hotel-evenementen en voorzieningen, speciale aanbiedingen, openingstijden, loyaliteitsprogramma's en nog veel meer.",

  'sixStarDisplayPage.subHeader':
    'API-gecentreerd displaysysteem voor vergaderruimtes, reclame, wegwijzers, vluchtborden en menuborden. Integraties met veel externe services.',
  'sixStarDisplayPage.noFancy': 'Geen fancy hardware om te beginnen, de enige hardwarevereiste is een HDMI-poort.',
  'sixStarDisplayPage.mutlipleGoals': 'Six Star: Display kan meerdere doelen bereiken.',
  'sixStarDisplayPage.plan': 'Plan overal en toon overal omdat alles wordt beheerd in de cloud.',
  'sixStarDisplayPage.powerful': 'Krachtige API-connectiviteit trekt de aandacht.',
  'sixStarDisplayPage.p1':
    'Tap in op nieuws, weer, verkeer en vluchtgegevens om van uw schermen realtime informatiekrachtpatsers te maken. Combineer API-gegevens met uw eigen merkinhoud of gebruik API-triggers om uw berichten automatisch te wijzigen.',

  'sixStarParkPage.header': 'Beheer parkeren zoals kamers en bedden (inclusief inkomsten)',
  'sixStarParkPage.customerSat': 'Klanttevredenheid',
  'sixStarParkPage.improve': 'Verbeter de algehele parkeerervaring en maximaliseer de winstgevendheid.',
  'sixStarParkPage.realTimeInformation': 'Real-time informatie',
  'sixStarParkPage.provide': 'Bied real-time informatie over bezetting en inkomsten via één portal.',
  'sixStarParkPage.geoMarketing': 'Geo-marketing',
  'sixStarParkPage.drive': 'Stimuleer promoties in de winkel voor app-gebruikers en genereer loop naar uw faciliteiten.',
  'sixStarParkPage.extraFeatures': 'Extra functies',
  'sixStarParkPage.p1':
    'Six Star: Park heeft veel meer functies als het gaat om sociale, economische en organisatorische factoren van uw parkeerbelang. Met de mogelijkheid om EV-laders in de mix op te nemen, Express Payments, Pre-Booking en betalen via mobiel, verhoogt u directe inkomsten en maximaliseert u de algehele ervaring voor gasten.',
  'sixStarParkPage.preparing': 'Voorbereiden op de toekomst',
  'sixStarParkPage.p2':
    "Ons hotel Parking-product beschikt over twee geavanceerde EV-laadoplossingen: TRONIQ 50, een 50 kW DC-lader met universele compatibiliteit en op afstand onderhoud, en DUAL BUSINESSLINE, een 7,4 kW AC-lader die twee auto's tegelijkertijd oplaadt met efficiënte stroomverdeling via Load Balancing. Deze geavanceerde oplossingen verbeteren de algehele gastervaring door snelle, betrouwbare en handige opties voor het opladen van elektrische voertuigen te bieden.",
  'sixStarBlackPage.header': 'Verhef Wereldwijde Reizen en Presidentiële Suites',
  'sixStarBlackPage.subHeader':
    "Het merk 'Six Star: Black' biedt twee onderscheidende producten op maat gemaakt voor individuen van hoge status en luxe accommodaties die de behoeften van hun gasten op de eerste plaats zetten.",
  'sixStarBlackPage.traveler': 'Reiziger',
  'sixStarBlackPage.hotel': 'Hotel',
  'sixStarBlackPage.aService':
    'Een dienst voor de bewoners van de hoogste suites binnen luxe hotels, gericht op het verbeteren van de algehele ervaring van gasten die in deze exclusieve accommodaties verblijven.',
  'sixStarBlackPage.provides':
    'Biedt eersteklas wereldwijde IT-ondersteuning ter plaatse om te voldoen aan de technologische behoeften van individuen van hoge status terwijl ze onderweg zijn.',
  'sixStarBlackPage.p1':
    'Ervaar elite reisondersteuning met Six Star: Black (Reiziger). Ons eersteklas wereldwijde IT-team richt zich op individuen van hoge status en hun families, en levert naadloze, gepersonaliseerde technische ondersteuning waar je avonturen je ook brengen, van vervanging van laagspanningsapparatuur tot vooraf geconfigureerde apparatuur die rechtstreeks naar je wordt verzonden.',
  'sixStarBlackPage.p2':
    'Geïntegreerd met de bestaande aanbiedingen van het hotel om een naadloze en verheven ervaring voor gasten te creëren. We bieden toonaangevende diensten om te voldoen aan de unieke en verheven verwachtingen van gasten die presidentiële en ambassadeursuites bezetten, zodat een echt luxueus verblijf wordt gegarandeerd.',

  'sixStarVIPPage.subHeader': "Premium ondersteuning rechtstreeks naar senior-engineers en verminderde SLA's voor uw uitvoerend team.",
  'sixStarVIPPage.whenYouCall':
    'Wanneer u belt of een ticket aanmaakt, bent u gegarandeerd van een van onze senior-engineers om u te helpen bij uw probleem.',
  'sixStarVIPPage.onTheGo': 'On-The-Go contactkaarten met een direct nummer voor ondersteuning.',
  'sixStarVIPPage.bigOrSmall': 'Of het probleem nu groot of klein is, we kunnen een lagere SLA-tijd garanderen.',
  'sixStarVIPPage.plus':
    'Six Star: VIP Plus, het ultieme niveau van ondersteuning ontworpen om uw ervaring te verheffen naar ongekende niveaus van uitmuntendheid. Met VIP Plus krijgt u toegang tot een rijkdom aan privileges die klantenservice herdefiniëren.',
  'sixStarVIPPage.instant': 'Directe Ondersteuning',
  'sixStarVIPPage.dedicated': 'Dedicated Ondersteuningsnummer',
  'sixStarVIPPage.priority': "Prioriteit SLA's",
  'sixStarVIPPage.withPlus': 'met Six Star: VIP Plus',

  'sixStarInternetPage.subHeader':
    'Six Star: Internet combineert kosteneffectieve datacircuits, uitgebreide mobiele connectiviteit en SIP-trunks met gratis projectmanagement en robuuste ondersteuning.',
  'sixStarInternetPage.leverage':
    'We maken gebruik van strategische partnerschappen, efficiënte infrastructuur en technologische oplossingen om kostenvoordelen te bieden aan onze klanten.',
  'sixStarInternetPage.mobileEnhance':
    'Mobiele connectiviteit verbetert de flexibiliteit en productiviteit voor bedrijven in een steeds mobielere wereld.',
  'sixStarInternetPage.inclusion': 'De inclusie van projectmanagementdiensten betekent een soepele en probleemloze onboarding-ervaring voor klanten.',
  'sixStarInternetPage.p1':
    'Six Star geeft prioriteit aan kosteneffectiviteit door datacircuits aan te bieden tegen de laagst mogelijke tarieven. Deze functie is ontworpen om bedrijven een concurrentievoordeel te bieden door de kosten van internetconnectiviteit te minimaliseren. Door betaalbare datacircuits aan te bieden, stelt Six Star bedrijven in staat hun middelen efficiënter in te zetten, waardoor groei en duurzaamheid worden bevorderd.',
  'sixStarInternetPage.p2':
    'Six Star biedt niet alleen SIP-trunks voor efficiënte spraakcommunicatie, maar onderscheidt zich ook door gratis projectmanagementdiensten te bieden. Dit omvat deskundige begeleiding en ondersteuning gedurende het implementatieproces. Zodra geïmplementeerd, zal de service desk van Six Star 24/7 beschikbaar zijn voor eventuele vragen of problemen die zich kunnen voordoen.',
  'sixStarInternetPage.projectManagement': 'Projectmanagement en Ondersteuning',
  'sixStarInternetPage.maximize': 'Maximaliseer Besparingen met Datacircuits',

  'sixStarPrintPage.header': 'Eenvoudige Beheerde Print voor Naadloze Productiviteit',
  'sixStarPrintPage.subHeader': 'Slim Printen, Geen Capex: Directe Oplossingen met Concurrerende Verbruiksartikelen',
  'sixStarPrintPage.printing': 'Kapitaalvrij Printen',
  'sixStarPrintPage.rapidResponse': 'Snelle Respons',
  'sixStarPrintPage.costEffectiveConsumables': 'Kosteneffectieve Verbruiksartikelen',
  'sixStarPrintPage.providing': 'Het bieden van een Beheerde Printoplossing zonder voorafgaande kosten.',
  'sixStarPrintPage.enjoy': 'Geniet van ondersteuning op locatie op dezelfde dag/volgende dag voor ononderbroken printen.',
  'sixStarPrintPage.save': 'Bespaar met concurrerende prijzen voor printverbruiksartikelen van Six Star Global.',
  'sixStarPrintPage.hassle': 'Probleemloos Printen',
  'sixStarPrintPage.unrivaled': 'Ongeëvenaarde Uptime Assurance',
  'sixStarPrintPage.p1':
    'Geniet van printen zonder de financiële last. De Beheerde Printoplossing van Six Star Global bevrijdt u van voorafgaande kosten, waardoor efficiënt printen toegankelijk wordt zonder de kapitaalinvestering.',
  'sixStarPrintPage.p2':
    'Verhef uw printervaring met Six Star Global. Profiteer van snelle ondersteuning op locatie op dezelfde dag/volgende dag, zodat uw printactiviteiten soepel verlopen zonder onderbrekingen.',

  'sixStarGuestPage.header': "Specifiek ontworpen voor lobby-pc's voor gasten of zakencentra",
  'sixStarGuestPage.subHeader':
    'Verhoog de ervaring van uw gast en stroomlijn uw bedrijfsvoering met onze geavanceerde technologie voor toegang door gasten.',
  'sixStarGuestPage.secure': 'Veilige Omgeving',
  'sixStarGuestPage.fully': 'Volledig Gelicenseerd',
  'sixStarGuestPage.custom': 'Aangepaste Oplossingen',
  'sixStarGuestPage.B2B': 'B2B & Frontale Ondersteuning voor Gasten',
  'sixStarGuestPage.p1':
    'Verhoog uw zakencentrum met onze robuuste B2B-ondersteuningsservices en verbeter de ervaring van de gast met betrouwbare en gebruiksvriendelijke openbare werkstations. Wij bieden op maat gemaakte IT-oplossingen voor zakelijke klanten en creëren tegelijkertijd een probleemloze omgeving voor gasten, of ze nu internettoegang nodig hebben, documenten moeten afdrukken of andere computernoden hebben.',
  'sixSTarGuestPage.customised': 'Aangepaste Oplossingen voor Elke Omgeving',
  'sixStarGuestPage.p2':
    'Erkennend dat elke horecagelegenheid uniek is, bieden wij aanpasbare oplossingen om aan uw specifieke eisen te voldoen. Van branding tot softwareconfiguraties passen wij onze diensten aan om aan te sluiten bij uw bedrijfsdoelstellingen, terwijl u de zekerheid heeft van onze 24/7 ondersteuning.',

  'sixStarPayPage.header': 'Verhoog Uw Gastvrijheid, Vereenvoudig Uw Transacties',
  'sixStarPayPage.subHeader': 'Stroomlijn Uitstekende Gastvrijheid, Een Transactie per Keer',
  'sixStarPayPage.flexible': 'Flexibele Betaling',
  'sixStarPayPage.variousPayment': 'We ondersteunen verschillende betaalopties, gasten kunnen betalen zoals ze willen.',
  'sixStarPayPage.roomCharging': 'Kamerin rekening brengen',
  'sixStarPayPage.orderRouting': 'Eenvoudige routering van bestellingen, gasten kunnen items/diensten op hun kamer in rekening brengen.',
  'sixStarPayPage.payAtTable': 'Betalen aan Tafel',
  'sixStarPayPage.ourPay': 'Onze Pay-At-Table oplossing zorgt ervoor dat uw menu in realtime wordt gesynchroniseerd op alle platforms.',
  'sixStarPayPage.increasedEfficiency': 'Verhoogde Efficiëntie en Gastgeluk',
  'sixStarPayPage.p1':
    'Bespaar tijd en verbeter de efficiëntie met onze gesynchroniseerde menubeheer- en bestelrouteringsfuncties. Richt u op het leveren van een uitzonderlijke eetervaring zonder operationele problemen. Maak van die tevreden klant een terugkerende klant.',
  'sixStarPayPage.integration': 'Integratie en Eenvoudige Installatie',
  'sixStarPayPage.p2':
    'Naadloze integratie met de meeste belangrijke PMS- en POS-systemen betekent dat u uw voorkeurstools kunt blijven gebruiken terwijl u profiteert van de extra voordelen van Six Star: Pay.',
  'formLoader.BackButton': 'Terug',
  'formLoader.forwardButton': 'Vooruit',
  'formLoader.submitButton': 'Verzenden',
  'formLoader.reSubmitButton': 'Opnieuw verzenden',
  'dataTablePro.error': 'Fout',
  'sixStarTV.design': 'Laten we het over Design hebben',
  'ticketViewPage.button.bundle': 'Bundel',
  'ticketViewPage.button.goBack': 'Terug',
  'ticketViewPage.ticket.summary': 'Ticketoverzicht: ',
  'ticketViewPage.formSummary': 'Formulieroverzicht',
  'ticketViewPage.masterDetail.createdAt': 'Gemaakt op:',
  'ticketViewPage.masterDetail.assignedMember': 'Toegewezen leden',
  'ticketViewPage.masterDetail.ticketContacts': 'Ticketcontacten',
  'ticketViewPage.masterDetail.ticketCreatedAt': 'Ticket gemaakt op',
  'ticketViewPage.masterDetail.mostRecentActivity': 'Meest recente activiteit',
  'ticketViewPage.masterDetail.ticketUpdatedBy': 'Ticket bijgewerkt door',
  'ticketViewPage.masterDetail.viewTicket': 'Ticket bekijken',
  'ticketViewPage.masterDetail.quickNote': 'Snel notitie',
  'ticketViewPage.carbonCopy': 'Kopie voor archief',
  'ticketViewPage.masterDetail.noRecentActivity': 'Geen recente activiteit',
  // "ticketViewPage.carbonCopyNotAvailable": "Geen CC beschikbaar voor dit ticket",
  "ticketViewPage.carbonCopyNotAvailable": "Geen CC beschikbaar voor dit ticket",
  "TicketViewPage.toolbar.quickClose.model.subTitle": "Weet u zeker dat u {count} tickets snel wilt oplossen?",
  "TicketViewPage.toolbar.quickClose.model.title": "Tickets Snel Oplossen",
  "TicketViewPage.toolbar.label.quickClose": "Snel Oplossen",
  "TicketViewPage.toolbar.quickClose.submitButton": "Snel Oplossen",
  "TicketViewPage.toolbar.quickClose.cancelButton": "Annuleren",
  "TicketViewPage.toolbar.quickClose.model.reason": "Voer alstublieft een korte reden in voor het sluiten van het ticket, bijvoorbeeld, rommel",
  "TicketViewPage.toolbar.quickClose.model.reasonPlaceholder": "Reden",
  "TicketViewPage.toolbar.quickClose.model.finished": "Tickets zijn snel opgelost",
  "TicketViewPage.toolbar.quickClose.model.updating": "Tickets bijwerken",
  "TicketViewPage.toolbar.quickClose.model.error": "Fout bij het bijwerken van tickets",
  "TicketViewPage.toolbar.quickClose.closeButton": "Sluiten",
  "menu.manage.helpSection.releaseNotes.tabText": "Release-opmerkingen",
  "menu.manage.helpSection.releaseNotes.menuText": "Release-opmerkingen",
  "releaseNotesPage.title": "Release-opmerkingen",
  "notFoundPage.pageDevelopment": "In ontwikkeling",
  "notFoundPage.pageDevelopmentMessage": "Sorry, dit gedeelte is momenteel in ontwikkeling. Kom later terug voor spannende updates van ons technische team",
  "notFoundPage.button.dashboard": "Dashboard",
  "notFoundPage.button.ticket": "Mijn Tickets",
  "ticketViewPage.modal.bundle.childTicketField": "Zoek Onderliggend Ticket",
  "TicketViewPage.toolbar.saveView": "Opslaan weergave",
  "TicketViewPage.toolbar.saveViewAs": "Opslaan weergave als",
  "TicketViewPage.toolbar.saveViewAsDuplicate": "Opslaan weergave als duplicaat",
  "TicketViewPage.toolbar.modal.areYouSure": "Weet u het zeker?",
  "TicketViewPage.toolbar.modal.yes": "Ja",
  "TicketViewPage.toolbar.modal.no": "Nee",
  "ticketViewPage.childTickets": "Subtickets",
  "ticketViewPage.parent": "Hoofdticket",
  "ticketViewPage.ticketSummary": "Ticket samenvatting",
  "ticketViewPage.ticketNumber": "Ticketnummer",
  "TicketViewPage.toolbar.label.filters": "Filters",
  "TicketViewPage.toolbar.label.addContact": "Contact toevoegen",
  "ticketViewPage.addNote": "Notitie toevoegen",
  "ticketViewPage.ticketUpdate": "Ticket update",
  "ticketViewPage.infoRequest": "Informatieaanvraag",
  "ticketViewPage.approval": "Goedkeuring",
  "ticketViewPage.requestForAction": "Verzoek om actie",
  "ticketViewPage.resolution": "Oplossing",
  "ticketviewPage.tab.overview": "Overzicht",
  "ticketviewPage.tab.timeline": "Tijdlijn",
  "ticketviewPage.tab.formSummary": "Formulier samenvatting",
  "ticketviewPage.tab.notes": "Notities",
  "ticketviewPage.tab.tasks": "Taken",
  "ticketviewPage.tab.activity": "Activiteit",
  "ticketViewPage.createdAt": "Gemaakt op",
  "ticketViewPage.lastUpdate": "Laatste update",
  "ticketViewPage.priority": "Prioriteit",
  "ticketViewPage.ticketSLA": "Ticket SLA",
  "ticketViewPage.requester": "Aanvrager",
  "ticketViewPage.ticketOwner": "Ticket eigenaar",
  "ticketViewPage.ticketStatus": "Ticketstatus:",
  "ticketViewPage.ticketSummary": "Ticket samenvatting",
  "ticketViewPage.ticketNumber": "Ticketnummer: #",
  "ticketViewPage.podAccess": "Pod toegang",
  "ticketViewPage.podAccessDesc": "Deze pod geeft je snelle toegang tot verschillende delen van het ticket",
  "ticketViewPage.addContact": "Contact toevoegen",
  "ticketViewPage.editType": "Type bewerken",
  "ticketViewPage.assigneeOrRequester": "Assignee/Requester bijwerken",
  "ticketViewPage.contacts": "Contacten",
  "ticketViewPage.contactDecs": "Deze pod toont alle contacten gerelateerd aan dit ticket",
  "menu.manage.internalSection.forms.tabText": "Formulieren",
  "menu.manage.internalSection.forms.menuText": "Formulieren",
  "ticketViewPage.toolbar.label.quickEdit": "Snelle bewerking",
  "ticketViewPage.toolbar.editTicket.submitButton": "Indienen",
  "datatableViews.toolbar.currentView": "Huidige weergave",
  "datatableViews.toolbar.loading": "Laden...",
  "ticketviewPage.tab.overview": "Overzicht",
  "ticketviewPage.tab.timeline": "Tijdlijn",
  "ticketviewPage.tab.notes": "Notities",
  "ticketviewPage.tab.tasks": "Taken",
  "ticketviewPage.tab.formSummary": "Formulier samenvatting",
  "ticketviewPage.tab.activity": "Activiteit",
  "ticketViewPage.timeline.ticketCreated": "Ticket gemaakt",
  "ticketViewPage.timeline.ticketUpdated": "Ticket bijgewerkt",
  "ticketViewPage.timeline.messageAdded": "Bericht toegevoegd",
  "ticketViewPage.timeline.titleChange": "Titelwijziging",
  "ticketViewPage.organisations": "Organisaties",
  "ticketViewPage.ticketDevices": "Ticketapparaten",
  "ticketViewPage.ticketCC": "Ticket CC",
  "menu.manage.companyContact.tabText": "Bedrijf",
  "menu.manage.companyContact.menuText": "Bedrijf",
  "ticketViewPage.rateYourService": "Beoordeel uw service",
  "ticketViewPage.relatedTickets.desc": "Deze pod toont alle gerelateerde tickets",
  "ticketViewPage.ticketCC.desc": "Deze pod toont alle gerelateerde kopieën",
  "ticketViewPage.ticketDevices.desc": "Deze pod toont alle gerelateerde apparaten",
  "ticketViewPage.ticketSLA.desc": "Deze pod toont het SLA van dit ticket",
  "ticketViewPage.documents.desc": "Deze pod toont alle documenten die met dit ticket te maken hebben",
  "ticketViewPage.organisations.desc": "Deze pod toont alle organisaties die met dit ticket te maken hebben",
  "ticketViewPage.initialResponse": "Eerste reactie",
  "ticketViewPage.nextUpdate": "Volgende update",
  "ticketViewPage.estimatedResolution": "Geschatte oplossing",
  "ticketViewPage.resolution": "Oplossing",
  "ticketViewPage.relatedTickets": "Gerelateerde Tickets",
  "ticketViewPage.bundleTicket": "Ticket bundelen",
  "ticketViewPage.shareTicket": "Ticket delen",
  "ticketViewPage.contact": "Contact",
  "ticketViewPage.email": "E-mail",
  "ticketViewPage.rateYourServiceComment": "U kunt uw service op elk moment tijdens uw sessie met Six Star Global beoordelen.",
  "ticketViewPage.responseHistory": "Antwoordgeschiedenis",
  "ticketViewPage.initialComment": "Initiële Opmerking",
  "ticketViewPage.agentResponse": "Antwoord van de Agent",
  "ticketVIewPage.notClosed": "Niet gesloten",
  "ticketViewPage.created": "Gemaakt op",
  "ticketViewPage.firstResponse": "Eerste Reactie",
  "ticketViewPage.estimatedResolution": "Geschatte Oplossing",
  "ticketViewPage.actualResolution": "Werkelijke Oplossing",
  "createOrg.title": "Organisatie Aanmaken",
  "ticketViewPage.addManager": "Accountmanager Toevoegen",
  "editManager.addNew": "Nieuw Toevoegen",
  "editManager.currentManagers": "Huidige Managers",
  "companyContactPage.tabs.overview.region": "Regio",
  "ticketViewPage.ratings": "Servicebeoordelingen",
  "ticketViewPage.ratingsdesc": "Deze pod toont eventuele beoordelingen die voor dit ticket zijn achtergelaten.",
  "menu.manage.userSection.createOrganization.tabText": "Organisatie Aanmaken",
  "menu.manage.userSection.createOrganization.menuText": "Organisatie Aanmaken",
  "companyPage.info": "Bedrijfsinformatie",
  "companyPage.AccountManager": "Accountmanager",
  "companyPage.location": "Locatie",
  "companyPage.relatedOrganizations": "Gerelateerde Organisaties",
  "companyPage.parentOrganization": "Moederorganisatie",
  "companyPage.childOrganizations": "Dochterorganisaties",
  "companyPage.noParentOrganization": "Geen Moederorganisatie",
  "companyPage.noChildOrganizations": "Geen Dochterorganisaties",
  "ticketViewPage.infoDesc": "Deze pod toont alle informatie met betrekking tot dit ticket",
  "ticketViewPage.ticketInfo": "Ticket Info",
  "ticketViewPage.timeline.priorityChange": "Prioriteitswijziging",
  "ticketViewPage.timeline.statusChange": "Statuswijziging",
  "ticketViewPage.timeline.ticketResolved": "Ticket Opgelost",
  'ticketViewPage.infoDesc': 'Deze module toont alle informatie met betrekking tot dit ticket',
  'ticketViewPage.ticketInfo': 'Ticket Info',
  'ticketViewPage.timeline.priorityChange': 'Prioriteitswijziging',
  'ticketViewPage.timeline.statusChange': 'Statuswijziging',
  'ticketViewPage.timeline.ticketResolved': 'Ticket opgelost',
  'ticketViewPage.addSummary': 'Voeg Samenvatting Toe',
  'ticketViewPage.notResponded': 'Niet Geantwoord',
  'ticketViewPage.noInitialComment': 'Geen Initiële Opmerking',
  'ticketViewPage.noAgentResponse': 'Geen Agentantwoord',
  'ticketViewPage.makePrivate': 'Privé Maken',
  'ticketViewPage.makePublic': 'Openbaar Maken',
  'ticketViewPage.pod.team': 'Team',
  'ticketViewPage.team.desc': 'Deze module toont alle teamleden die verband houden met dit ticket',
};

export default pages;
