const login = {
  "login.title": "SIX STAR GLOBAL",
  "login.headline": "Das weltweit führende IT Managed Services Unternehmen.",
  "login.signInText": "Sign Into Six Star: Manage",
  "login.topCallToActionTitle": "Noch kein Teil von Six Star?",
  "login.topCallToActionLink": "Lassen Sie uns chatten",
  "loginPage.input.email": "E-Mail",
  "loginPage.input.password": "Passwort",
  "loginPage.input.mfa": "MFA-Code",
  "loginPage.rememberMe": "Angemeldet bleiben",
  "loginPage.button.login": "Anmelden",
  "loginPage.button.forgotPassword": "Passwort vergessen?",
  "loginPage.apiMfaInProgress": "Bitte geben Sie Ihren MFA-Code ein",
  "login.forgotPasswordText": "Passwort vergessen",
  "loginPage.apiMultipleUsers": "Benutzerkonto-Fehler, kontaktieren Sie Six Star",
  "loginPage.apiNoPassword": "Bitte geben Sie ein Passwort ein",
  "loginPage.apiNoUser": "Bitte geben Sie eine E-Mail-Adresse ein",
  "loginPage.apiInvalidTOTP": "Ungültiger MFA-Code",
  "loginPage.apiInvalidPassword": "Ungültiges Passwort",
  "login.sso.starting": "Einmalanmeldung wird gestartet",
  "loginPage.button.normalSignIn": "Anmelden mit Benutzername/Passwort",
  "loginPage.text.msssoLoading": "Laden...",
  "loginPage.label.msssoSignIn": "Anmelden mit Microsoft",
  "loginPage.label.msssoSignInEmail": "Microsoft-E-Mail-Adresse",
  "loginPage.text.msssoError": "Fehler beim Anmelden",

  "loginPage.emailPrompt": "Geben Sie Ihre E-Mail-Adresse unten ein, um Ihr Passwort zurückzusetzen.",
  "loginPage.ForgotPassword.GoBack": "Zurück",
  "loginPage.ForgotPassword.Submit": "Senden",
  "loginPage.label.email": "E-Mail",
  "loginPage.helpIcon": "Hilfe",
  "loginPage.label.fullName": "Vollständiger Name",
  "loginPage.label.password": "Passwort",
  "loginPage.label.confirmPassword": "Passwort bestätigen",
  "loginPage.register.button.goback": "Zurück",
  "loginPage.register.button.register": "Registrieren",
  "loginPage.register.button.signUp": "Anmelden",

};
export default login;
