const toAdd = {
  'ticketViewPage.ticketTags': 'Tags',
  'ticketViewPage.addTag': 'Add Tag',
  'createOrg.title': 'Create Organization',
  "ticketViewPage.addManager": "Add Account Manager",
  'editManager.addNew': 'Add New',
  'editManager.currentManagers': 'Current Managers',
  'companyContactPage.tabs.overview.region': 'Region',
  'ticketViewPage.ratings': 'Service Ratings',
  'ticketViewPage.ratingsdesc': 'This pod shows any ratings that have been left for this ticket.',
  "menu.manage.userSection.createOrganization.tabText": "Create Organization",
  "menu.manage.userSection.createOrganization.menuText": "Create Organization",
  'companyPage.info': 'Company Info',
  'companyPage.AccountManager': 'Account Manager',
  'companyPage.location': 'Location',
  'companyPage.relatedOrganizations': 'Related Organizations',
  'companyPage.parentOrganization': 'Parent Organization',
  'companyPage.childOrganizations': 'Child Organizations',
  'companyPage.noParentOrganization': 'No Parent Organization',
  'companyPage.noChildOrganizations': 'No Child Organizations',
  // below rto be translated 

};

export default toAdd;
