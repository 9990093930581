import {
  NAVIGATION_JOIN_PAGE,
  NAVIGATION_JOIN_PAGE_SUCCESS,
  NAVIGATION_JOIN_PAGE_FAIL,
} from "../constants";

export function joinPage(content) {
  return {
    type: "socket",
    types: [
      NAVIGATION_JOIN_PAGE,
      NAVIGATION_JOIN_PAGE_SUCCESS,
      NAVIGATION_JOIN_PAGE_FAIL,
    ],
    promise: (socket) => {
      return socket.emit(NAVIGATION_JOIN_PAGE, content).then((result) => {
        let resultData = JSON.parse(result);
        return resultData;
      });
    },
  };
}
