import React from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import MuiButton from "@mui/material/Button";
import graphQLMutations from "graphql/audit";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useAuditAction } from "containers/App/Hooks/useAuditAction";

function Button(props) {
    let {
        children,
        classes,
        color,
        component,
        disabled,
        disableElevation,
        disableFocusRipple,
        disableRipple,
        endIcon,
        fullWidth,
        href,
        metadata,
        onClick,
        size,
        startIcon,
        sx,
        variant,
        theme
    } = props;

    const { handleInsertAuditLog } = useAuditAction();
    
    return (
        <MuiButton
            children={children}
            classes={classes}
            color={color}
            component={component}
            disabled={disabled}
            disableElevation={disableElevation}
            disableFocusRipple={disableFocusRipple}
            disableRipple={disableRipple}
            endIcon={endIcon}
            fullWidth={fullWidth}
            href={href}
            size={size}
            startIcon={startIcon}
            sx={sx}
            variant={variant}
            theme={theme}
            onClick={
                (event) => {
                    if (onClick) {
                        handleInsertAuditLog("mui_button_clicked", metadata)
                        onClick();
                    }
                }
            }
        >
        </MuiButton>
    )
}

Button.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    color: PropTypes.oneOf(["inherit", "primary", "secondary", "success", "error", "info", "warning", PropTypes.string]),
    component: PropTypes.elementType,
    disabled: PropTypes.bool,
    disableElevation: PropTypes.bool,
    disableFocusRipple: PropTypes.bool,
    disableRipple: PropTypes.bool,
    endIcon: PropTypes.node,
    fullWidth: PropTypes.bool,
    href: PropTypes.string,
    metadata: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(["small", "medium", "large", PropTypes.string]),
    startIcon: PropTypes.node,
    sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    variant: PropTypes.oneOf(["text", "outlined", "contained", PropTypes.string]),
    theme: PropTypes.object,
}

Button.defaultProps = {
    children: null,
    classes: null,
    color: "primary",
    component: null,
    disabled: false,
    disableElevation: false,
    disableFocusRipple: false,
    disableRipple: false,
    endIcon: null,
    fullWidth: false,
    href: null,
    metadata: "undefined",
    onClick: null,
    size: "medium",
    startIcon: null,
    sx: null,
    variant: "text",
    theme: null,
}

export default withStyles(
    (theme) => ({
        ...layoutStyles(theme),
        ...styles(theme),
    }),
    { withTheme: true }
)(Button); 