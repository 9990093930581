import Button from "components/MuiOverride/Button";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from '@mui/material/Modal';
import CreateContact from "containers/Pages/CreateContact";

function CreateContactModal({showAddContact}) {
    
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(showAddContact);
    }, [showAddContact]);

    const handleClose = () => {
        setOpen(false);
    };
    return(
        <>
        <Modal open={open} onClose={handleClose}>
            <CreateContact/>
        </Modal>
        </>
    )
}

export default CreateContactModal;