const pages = {
  'dashboard.pageTitle': 'Cruscotto',
  'dashboard.greeting.morning': 'Buongiorno',
  'dashboard.greeting.afternoon': 'Buon pomeriggio',
  'dashboard.greeting.evening': 'Buonasera',
  'dashboard.welcomeMessage': 'Hai {tickets} ticket attivi e {messages} messaggi a cui rispondere.',
  'notAuthorizedPage.title': 'Non autorizzato',
  'notAuthorizedPage.desc': 'Non sei autorizzato ad accedere a questa pagina.',
  'dashboard.welcomeText': 'Benvenuto in {ssg}!',
  'dashboard.button.addWidget': 'Aggiungi Widget',
  'dashboard.button.close': 'Chiudi',
  'dashboard.button.edit': 'Modifica',
  'dashboard.button.save': 'Salva',
  'settingsPage.pageTitle': 'Impostazioni',
  'ticketViewPage.priority': 'Priorità',
  'ticketViewPage.assignee': 'Assegnatario',
  'ticketViewPage.team': 'Squadra',
  'ticketViewPage.sixStarTeam': 'Membri aggiuntivi del team Six Star',
  'ticketViewPage.resourcesOnTicket': 'Risorse sul ticket',
  'ticketViewPage.followers': 'Seguaci',
  'ticketViewPage.tags': 'Tag',
  'ticketViewPage.takeIt': 'prendilo',
  'ticketViewPage.notes': 'Note',
  'ticketViewPage.activity': 'Attività',
  'ticketViewPage.timeline': 'Cronologia',
  'ticketViewPage.tasks': 'Compiti',
  'ticketViewPage.search': 'Ricerca',
  'ticketViewPage.noteAddedBy': 'Nota aggiunta da',
  'ticketViewPage.moreButton': 'Altro',
  'ticketViewPage.makeInternal': 'Rendi interno',
  'ticketViewPage.makePublic': 'Rendi pubblico',
  'ticketViewPage.deleteComment': 'Elimina',
  'ticketViewPage.reportComment': 'Segnala',
  'ticketViewPage.internalTicketComment': 'Questo è un commento interno',
  'ticketViewPage.publicTicketComment': 'Questo è un commento pubblico',
  'ticketViewPage.ticketSource.internal_web': 'Web interno',
  'ticketViewPage.ticketSource.external_email': 'Email interna',
  'ticketViewPage.followTicket': 'Segui Ticket',
  'ticketViewPage.unfollowTicket': 'Smetti di seguire Ticket',
  'ticketViewPage.tasks.priority.completed': 'Completato',
  'ticketViewPage.tasks.priority.in_progress': 'In corso',
  'ticketViewPage.tasks.priority.not_started': 'Non iniziato',
  'ticketViewPage.tasks.priority.low': 'Basso',
  'ticketViewPage.tasks.priority.medium': 'Medio',
  'ticketViewPage.tasks.priority.important': 'Importante',
  'ticketViewPage.tasks.priority.urgent': 'Urgente',
  'ticketViewPage.tasks.assignedTo': 'Assegnato a',
  'ticketViewPage.tasks.completedBy': 'Completato da',
  'ticketViewPage.requester': 'Richiedente',
  'ticketViewPage.organization': 'Organizzazione',
  'ticketViewPage.button.cancel': 'Annulla',
  'ticketViewPage.button.close': 'Chiudi',
  'ticketViewPage.downloadEml': 'Scarica EML',
  'newRequestPage.header': 'Nuova Richiesta',
  'newRequestPage.button.internalRequest': 'Richiesta interna',
  'newRequestPage.button.serviceRequest': 'Richiesta di servizio',
  'newRequestPage.button.procurementRequest': 'Richiesta di approvvigionamento',
  'newRequestPage.card.urgentHelp': 'Hai bisogno di aiuto urgente?',
  'newRequestPage.button.callUs': 'Chiamaci',
  'newRequestPage.button.passwordReset': 'Ripristino password del Dominio/Computer',
  'newRequestPage.button.newRequest': 'Richiesta di supporto',
  'formPage.title.supportRequest': 'Richiesta di supporto',
  'formPage.title.newStarterRequest': 'Richiesta di nuovo inizio',
  'formPage.title.serviceRequest': 'Richiesta di servizio',
  'formPage.title.newStarter': 'Nuovo inizio / Cambio di ruolo',
  'formPage.title.leaver': 'Leaver',
  'formPage.title.engineerVisit': "Visita dell'ingegnere",
  'formPage.title.procurementRequest': 'Richiesta di approvvigionamento',
  'formPage.title.developmentRequest': 'Richiesta di sviluppo',
  'formPage.title.internalRequest': 'Richiesta interna',
  'ldapPasswordResetPage.button.submit': 'Reimposta password',
  'ldapPasswordResetPage.button.generatePassword': 'Genera password',
  'ldapPasswordResetPage.title': 'Ripristino password del Dominio/Computer',
  'ldapPasswordResetPage.paragraph.intro':
    'Reimposta la password del dominio del tuo computer. A seconda della politica del tuo hotel, potresti essere obbligato a cambiare la tua password ogni 90 giorni.',
  'licencePage.title': 'Licenze',
  'licencePage.office365Licences': 'Licenze Office 365',
  'licencePage.license': 'Licenza',
  'licencePage.active': 'Attivo',
  'licencePage.consumed': 'Consumato',
  'licencePage.unused': 'Non utilizzato',
  'assetPage.title': 'Beni',
  'resourceTracker.title': 'Tracciatore di Risorse',
  'ticketViewPage.overview': 'Panoramica',
  'ticketViewPage.roles': 'Ruoli',
  'ticketViewPage.addContact': 'Aggiungi Contatto',
  'ticketViewPage.status': 'Stato',
  'ticketViewPage.createdAt': 'Creato Il',
  'ticketViewPage.updatedAt': 'Aggiornato Il',
  'ticketViewPage.devices': 'Dispositivi',
  'ticketViewPage.contacts': 'Contatti',
  'ticketViewPage.documents': 'Documenti',
  'ticketViewPage.addNote': 'Aggiungi Nota',
  'ticketViewPage.info': 'Informazioni',
  'ticketViewPage.created': 'Creato',
  'ticketViewPage.lastActivity': 'Ultima Attività',
  'ticketViewPage.priorityLevel': 'Livello di Priorità',
  'ticketViewPage.assigned': 'Assegnato',
  'ticketViewPage.ticketSLA': 'SLA Ticket',
  'ticketViewPage.firstResponse': 'Prima Risposta',
  'ticketViewPage.resolution': 'Risoluzione',
  'ticketViewPage.nextUpdate': 'Prossimo Aggiornamento',
  'ticketViewPage.mostRecentMessages': 'Messaggio Più Recente',
  'organizationViewPage.overview': 'Panoramica',
  'organizationViewPage.activity': 'Attività',
  'assetPage.name': 'Nome',
  'assetPage.type': 'Tipo',
  'assetPage.status': 'Stato',
  'assetPage.serial': 'Seriale',
  'assetPage.manufacturer': 'Produttore',
  'assetPage.cpu': 'CPU',
  'assetPage.memory': 'Memoria',
  'assetPage.lastUser': 'Ultimo Visto',
  'assetPage.lastSeen': 'Ultimo Visto Il',
  'assetPage.operatingSystem': 'Sistema Operativo',
  'sixStarMeet.header': 'La Tecnologia Dietro Ogni Grande Incontro',
  'contactUsPage.number.button': 'Clicca Qui',
  'contactUsPage.title.second': 'Chiamaci',
  'contactUsPage.title.first': 'Ciao, come possiamo aiutarti?',
  'contactUsPage.header.boxs.one': 'Come faccio a registrare un ticket?',
  'contactUsPage.header.boxs.two': 'Come faccio a disconnettermi?',
  'contactUsPage.header.boxs.three': 'Dove vedo tutti i miei ticket?',
  'contactUsPage.header.boxs.four': 'Come aggiungo widget?',
  'contactUsPage.search.answers': 'Cerca risposte...',
  'contactUsPage.search': 'Cerca risposte...',
  // Below = not yet translated
  'ticketViewPage.approvedAt': 'Approvato Il',
  'ticketViewPage.rejectedAt': 'Rifiutato Il',
  'ticketViewPage.approvedBy': 'Approvato Da',
  'ticketViewPage.rejectedBy': 'Rifiutato Da',
  'ticketViewPage.approvalStatus': "Stato dell'Approvazione",
  'ticketViewPage.approvalStatus.approved': 'Approvato',
  'ticketViewPage.approvalStatus.rejected': 'Rifiutato',
  'ticketViewPage.approvalStatus.pending': 'In Attesa',
  'ticketViewPage.requestedBy': 'Richiesto Da',

  //   "ticketViewPage.approvedAt": "Approvato il",
  // "ticketViewPage.rejectedAt": "Respinto il",
  // "ticketViewPage.approvedBy": "Approvato da",
  // "ticketViewPage.rejectedBy": "Respinto da",
  // "ticketViewPage.approvalStatus": "Stato dell'approvazione",
  // "ticketViewPage.approvalStatus.approved": "Approvato",
  // "ticketViewPage.approvalStatus.rejected": "Respinto",
  // "ticketViewPage.approvalStatus.pending": "In sospeso",
  // "ticketViewPage.requestedBy": "Richiesto da",

  // First time set up page
  'firstTimeSetupPage.firstLookAtManage': 'Prima occhiata a Manage 3.0',
  'firstTimeSetupPage.elevatingYourITManagementExperience': 'Elevare la tua esperienza di gestione IT',
  'firstTimeSetupPage.weAreThrilledToPresent':
    "Siamo entusiasti di presentare l'anteprima di un nuovo capitolo nel tuo percorso di gestione IT con la versione beta di Six Star: Manage 3.0.",
  'firstTimeSetupPage.asAValuedMember':
    "Come membri apprezzati della famiglia Six Star Global, che già traggono beneficio dalle nostre soluzioni, siamo entusiasti di presentarti ciò che sarà un'esperienza modificata e migliorata che significa una gestione IT più dinamica, efficiente e intuitiva.",
  'firstTimeSetupPage.aNewChapterInITManagement': 'Un nuovo capitolo nella gestione IT',
  'firstTimeSetupPage.technologicalTakeOver': 'Un rinnovamento tecnologico:',
  'firstTimeSetupPage.redesign':
    'Un moderno restyling che tiene conto di velocità e stabilità. La performance è una delle nostre principali preoccupazioni, quindi ci siamo assicurati che ovunque tu sia, su mobile o desktop, tu abbia il tuo portfolio IT a portata di mano.',
  'firstTimeSetupPage.effortlessLicenseOverview': 'Visione senza sforzo delle licenze:',
  'firstTimeSetupPage.simplifyLicenceManagement':
    'Semplifica senza sforzo la gestione delle licenze. Manage 3.0 ti permette di supervisionare software e licenze utente in modo fluido, garantendo la conformità e ottimizzando le spese.',
  'firstTimeSetupPage.smartReportGeneration': 'Generazione intelligente di report:',
  'frstTimeSetupPage.harnessThePowerOfData':
    'Sfrutta la potenza dei dati per decisioni strategiche. Genera report personalizzati utilizzando le statistiche dei ticket, aiutandoti a perfezionare le operazioni e migliorare la qualità del servizio nella tua struttura.',
  'firstTimeSetupPage.tailoredDashboardAtYourFingertips': 'Dashboard personalizzata a portata di mano:',
  'firstTimeSetupPage.craftDashboard':
    'Crea una dashboard IT personalizzata con una varietà di widget, offrendoti rapide intuizioni sullo stato dei ticket e sulle tendenze in linea con i tuoi interessi specifici.',
  'firstTimeSetupPage.benefitsTitle': 'Benefici di Manage 3.0 per le operazioni alberghiere',
  'firstTimeSetupPage.amplifiedEfficiency': 'Efficienza amplificata:',
  'firstTimeSetupPage.guestExperience':
    'Concentrati sulla fornitura di esperienze straordinarie per gli ospiti, con processi automatizzati che affrontano i problemi in modo fluido.',
  'firstTimeSetupPage.realTimeIntelligence': 'Intelligenza in tempo reale:',
  'firstTimeSetupPage.instantlyAccess': 'Accedi istantaneamente a dati aggiornati e statistiche sui ticket per decisioni rapide e informate.',
  'firstTimeSetupPage.tailoredToYou': 'Su misura per te:',
  'firstTimeSetupPage.customiseDashboard':
    'Una dashboard personalizzata visualizza esattamente le informazioni di cui hai bisogno, offrendo una visione completa delle tue operazioni IT.',
  'firstTimeSetupPage.futureProof': 'Assicurazione a prova di futuro:',
  'firstTimeSetupPage.atThisIminentLaunch':
    'Poiché questo imminente lancio è solo la versione beta, aspettati aggiornamenti regolari e funzionalità innovative che terranno il passo con le mutevoli esigenze della tua infrastruttura IT.',
  'firstTimeSetUpPage.button.getStarted': 'Inizia',

  // loading
  'loading.title': 'Caricamento...',

  // ticket view
  'ticketView.activityModule.title': 'Attività',
  'ticketView.emailModule.title': 'Email',
  'ticketView.infoModule.subheaderOne': 'Supporto',
  'ticketView.infoModule.subheaderTwo': 'Team tecnico',
  'ticketView.infoModule.value 1': 'Opzione 1',
  'ticketView.infoModule.value 2': 'Opzione 2',
  'ticketView.infoModule.value 3': 'Opzione 3',
  'ticketView.infoModule.value 4': 'Opzione 4',
  'ticketView.notesModule.title': 'Note',
  'ticketView.tasksModule.title': 'Compiti',

  'ticketViewPage.modal.bundle.title': 'Raggruppa i ticket',
  'ticketViewPage.modal.bundle.parentTicket': 'Ticket principale',
  'ticketViewPage.modal.bundle.childTicket': 'Ticket secondario',
  'ticketViewPage.modal.bundle.button': 'Raggruppa',
  'ticketViewPage.modal.bundle.button.cancel': 'Annulla',
  'companyContactPage.tabs.overview': 'Panoramica',
  'companyContactPage.tabs.activities': 'Attività',
  'companyContactPage.tabs.tasks': 'Compiti',
  'companyContactPage.tabs.overview.lastActivity': 'Ultima attività',
  'companyContactPage.tabs.overview.customerStatus': 'Stato del cliente',
  'companyContactPage.tabs.overview.userCreated': 'Utente creato',
  'companyContactPage.tabs.overview.company': 'Azienda',
  'companyContactPage.tabs.overview.expiringLicences': 'Licenze in scadenza',
  'companyContactPage.tabs.overview.opemTickets': 'Ticket aperti',
  'companyContactPage.tabs.overview.mostRecentActivity': 'Attività più recente',
  'companyContactPage.tabs.overview.Drive': 'Drive',
  'companyContactPage.tabs.overview.lastActivity: ': 'Ultima attività: ',
  'companyContactPage.tabs.search': 'Cerca',
  'companyContactPage.tabs.overview.AddedNote': 'Nota aggiunta',
  'companyContactPage.tabs.sidePanel.contacts': 'Contatti',
  'companyContactPage.tabs.sidePanel.other': 'Altro',
  'companyContactPage.tabs.sidePanel.licenses': 'Licenze',
  'companyContactPage.tabs.sidePanel.procurement': 'Approvvigionamento',
  'companyContactPage.tabs.sidePanel.assets': 'Asset',
  'companyContactPage.tabs.sidePanel.invoices': 'Fatture',
  'companyContactPage.tabs.sidePanel.company': 'Azienda',
  'ticketViewPage.checklist': 'Checklist',
  'ticketViewPage.attachments': 'Allegati',
  'ticketViewPage.relatedItems': 'Elementi correlati',
  'sixStarTVPage.header': "Leader in funzionalità, stabilità e facilità d'uso",
  'sixStarTVPage.integration': 'Integrazione',
  'sixStarTVPage.webBasedManagement': 'Gestione basata su Web',
  'sixStarTVPage.gaming': 'Gaming',
  'sixStarTVPage.fullyCustomisable': "Totalmente personalizzabile per un'esperienza di marca",
  'sixStarTVPage.extraFeatures': 'Una serie di funzionalità extra',
  'sixStarTVPage.plus': 'con Six Star: TV Plus',
  'sixStarTVPage.offer':
    'Six Star: TV Plus offre una gamma completa di extra per rendere il soggiorno dei tuoi ospiti ancora più prezioso, garantendo nel contempo quelle vendite aggiuntive così importanti.',
  'sixStarTVPage.wakeUp': 'Svegliati',
  'sixStarTVPage.internationalViewing': 'Visualizzazione internazionale',
  'sixStarTVPage.roomService': 'Servizio in camera',
  'sixStar.GetInTouch': 'Contattaci',
  'sixStar.BackToTop': "Torna all'inizio",
  'quickCreateTicketModal.title': 'Creazione rapida del ticket',
  'quickCreateTicketModal.button.submit': 'Invia',
  'quickCreateTicketModal.button.submitAndView': 'Invia e visualizza il ticket',
  'quickCreateTicketModal.disclaimer': 'Questo ticket verrà automaticamente pubblicato come priorità media e lo stato come NUOVO',
  'quickCreateTicketModal.success': 'Ticket creato con successo',
  'quickCreateTicketModal.button.close': 'Chiudi',
  //sixstar black page
  // "sixStarBlackPage.header": "Elevalo Viaggi Globali e Suite Presidenziali",
  // "sixStarBlackPage.traveler": "Viaggiatore",
  // "sixStarBlackPage.hotel": "Hotel",

  //sixstar vip page
  'sixStarVIPPage.header': 'Scelta esecutiva per soluzioni rapide con accesso prioritario per i GM',
  'sixStarVIPPage.directToSeniorSupport': 'Accesso diretto al supporto senior',
  'sixStarVIPPage.contactCards': 'Carte di contatto',
  'sixStarVIPPage.reducedSLA': 'SLA ridotta per i dirigenti',
  'sixStarVIPPage.contactCardsForGM': 'Carte di contatto per i GM',

  //sixstar cast page
  'sixStarCastPage.header': 'Intrattenimento per gli ospiti da casa',
  'sixStarCastPage.oneStepAuthentication': 'Autenticazione in un solo passo',
  'sixStarCastPage.streamCastableApp': 'Gli ospiti possono trasmettere qualsiasi app trasmettibile',
  'sixStarCastPage.CloudManaged': 'Gestito dal cloud',

  // sixstar internet page
  'sixStarInternetPage.header': 'Elevalo Connessione, Riduci i Costi e Rilascia un Supporto Ineguagliato',
  'sixStarInternetPage.lowCost': 'Costo basso',
  'sixStarInternetPage.mobileConnectivity': 'Connessione mobile',
  'sixStarInternetPage.installations': 'Installazioni',

  // sixstar display page
  'sixStarDisplayPage.header': 'Trasformare Spazi ed Elevare Esperienze',
  'sixStarDisplayPage.workWithAnyScreen': 'Funziona con qualsiasi schermo',
  'sixStarDisplayPage.wayFinding': 'Segnaletica e Ricerca delle Offerte',
  'sixStarDisplayPage.mobileWebEditor': 'Editor web mobile',

  'sixStarPlus.getInTouch': 'Entrare in contatto',
  'sixStarTVPage.externalTVInputs': 'Ingressi TV esterni',
  'sixStarTVPage.p2':
    'Utilizza le porte HDMI per console e altri sistemi, nonché app come AirPlay e Six Star: Cast per consentire agli ospiti di trasmettere dai loro dispositivi personali.',
  'sixStarTVPage.p3': "I costi di investimento possono essere ridotti utilizzando l'infrastruttura esistente.",
  'sixStarTVPage.p4': 'Monitoraggio interno e gestione dei contenuti direttamente da un browser.',
  'sixStarTVPage.p5': 'Una richiesta in rapida crescita per i fornitori di servizi di ospitalità che SS:TV può soddisfare con grinta.',
  'sixStarTVPage.fullyCustomizable1080p':
    'Un menu completamente personalizzabile in 1080p senza limiti rigidi di design. Inizia con modelli personalizzati per un avvio senza intoppi o lascia che SSG ti aiuti con il design.',

  'sixStarVoicePage.header': 'Scopri la potenza di Six Star: Voice',
  'sixStarVoicePage.p1': 'Comunicazione senza pari',
  'sixStarVoicePage.advancedIntegrations': 'Integrazioni avanzate',
  'sixStarVoicePage.lineRedundancy': 'Redondanza di linea',
  'sixStarVoicePage.customisableSolutions': 'Soluzioni personalizzabili',
  'sixStarVoicePage.seamlessConnect': 'Connettiti senza soluzione di continuità con app di terze parti per una funzionalità di sistema versatile.',
  'sixStarVoicePage.enjoy': 'Goditi una comunicazione ininterrotta con la redondanza di linea standard.',
  'sixStarVoicePage.tailor': 'Personalizza Six Star: Voice per ottimizzare le tue operazioni di ospitalità uniche.',
  'sixStarVoicePage.p2': 'Comunicazione senza soluzione di continuità',
  'sixStarVoicePage.sayGoodbye':
    'Dì addio ai tempi di inattività. Il nostro sistema è progettato con la redondanza di linea come funzionalità standard, garantendo una comunicazione ininterrotta anche di fronte a sfide impreviste. Abbiamo anche membri dedicati del team di telefonia pronti a supportarti se ne hai bisogno.',

  'sixStarCastPage.low-Cost':
    'Soluzione di casting per dispositivi degli ospiti a basso costo che può essere aggiunta a qualsiasi TV compatibile HDMI.',
  'sixStarCastPage.guestCan': 'Gli ospiti possono accedere al loro intrattenimento immediatamente appena entrano nella loro stanza.',
  'sixStarCastPage.netflix': 'Netflix, Hulu, Disney+ e molti altri sono disponibili.',
  'sixStarCastPage.easilyIntegrated': 'Facilmente integrato alle soluzioni TV esistenti.',
  'sixStarCastPage.p1':
    "Utilizza il portale di marketing di marca per la comunicazione diretta con gli ospiti interni. Attraverso il sistema di gestione dei contenuti basato sul web (CMS), il personale di marketing dell'hotel può tenere gli ospiti informati sugli eventi dell'hotel e le comodità, offerte speciali, orari di apertura, programmi fedeltà e molto altro.",

  'sixStarDisplayPage.subHeader':
    'Sistema di visualizzazione per sale riunioni, pubblicità, segnaletica, tabellone voli e menu basato su API. Integrazioni con molti servizi di terze parti.',
  'sixStarDisplayPage.noFancy': "Nessuna hardware sofisticato per iniziare, l'unico requisito hardware è una porta HDMI.",
  'sixStarDisplayPage.mutlipleGoals': 'Six Star: Display può raggiungere molteplici obiettivi.',
  'sixStarDisplayPage.plan': 'Pianifica ovunque e visualizza ovunque, poiché tutto è gestito nel cloud.',
  'sixStarDisplayPage.powerful': "La potente connettività API attira l'attenzione.",
  'sixStarDisplayPage.p1':
    'Accedi a notizie, meteo, traffico e dati dei voli per trasformare i tuoi schermi in centri di informazioni in tempo reale. Fonde i dati delle API con i tuoi contenuti di marca, o utilizza trigger delle API per cambiare automaticamente il tuo messaggio.',

  'sixStarParkPage.header': 'Gestisci il parcheggio come le camere e i letti (inclusi i ricavi)',
  'sixStarParkPage.customerSat': 'Soddisfazione del cliente',
  'sixStarParkPage.improve': "Migliora l'esperienza complessiva di parcheggio e massimizza la redditività.",
  'sixStarParkPage.realTimeInformation': 'Informazioni in tempo reale',
  'sixStarParkPage.provide': 'Fornisci informazioni in tempo reale su occupazione e ricavi tramite un unico portale.',
  'sixStarParkPage.geoMarketing': 'Geo-Marketing',
  'sixStarParkPage.drive': "Promuovi le promozioni in negozio agli utenti dell'app e genera affluenza nelle tue strutture.",
  'sixStarParkPage.extraFeatures': 'Funzionalità aggiuntive',
  'sixStarParkPage.p1':
    "Six Star: Park offre molte altre funzionalità per quanto riguarda fattori sociali, economici e organizzativi del tuo parcheggio. Con la possibilità di includere caricabatterie per veicoli elettrici, pagamenti express, prenotazione anticipata e pagamento tramite cellulare, aumenterai i ricavi diretti massimizzando l'esperienza complessiva degli ospiti.",
  'sixStarParkPage.preparing': 'Preparazione per il futuro',
  'sixStarParkPage.p2':
    "Il nostro prodotto di parcheggio per hotel presenta due soluzioni avanzate di ricarica per veicoli elettrici: TRONIQ 50, un caricatore DC da 50 kW con compatibilità universale e manutenzione remota, e DUAL BUSINESSLINE, un caricatore AC da 7,4 kW che carica contemporaneamente due auto con distribuzione efficiente della potenza tramite bilanciamento del carico. Queste soluzioni all'avanguardia migliorano l'esperienza complessiva degli ospiti fornendo opzioni di ricarica per veicoli elettrici veloci, affidabili e convenienti.",
  'sixStarBlackPage.header': 'Eleva i Viaggi Globali e le Suite Presidenziali',
  'sixStarBlackPage.subHeader':
    "Il marchio 'Six Star: Black' offre due prodotti distinti pensati per individui di alto profilo e strutture di lusso che mettono al primo posto le esigenze dei loro ospiti.",
  'sixStarBlackPage.traveler': 'Viaggiatore',
  'sixStarBlackPage.hotel': 'Hotel',
  'sixStarBlackPage.aService':
    "Un servizio dedicato agli occupanti delle suite di livello più alto all'interno degli hotel di lusso, con l'obiettivo di migliorare l'esperienza complessiva degli ospiti che soggiornano in queste sistemazioni esclusive.",
  'sixStarBlackPage.provides':
    'Offre supporto IT globale di alta qualità per soddisfare le esigenze tecnologiche di individui di alto profilo durante i loro spostamenti.',
  'sixStarBlackPage.p1':
    "Vivi un supporto di viaggio d'elite con Six Star: Black (Traveler). Il nostro team globale di IT di alto livello si occupa di individui di alto profilo e delle loro famiglie, fornendo assistenza tecnica personalizzata e senza intoppi ovunque ti portino le tue avventure, dalla sostituzione di attrezzature a bassa tensione all'invio diretto di attrezzature preconfigurate.",
  'sixStarBlackPage.p2':
    "Integrato con le offerte esistenti dell'hotel per creare un'esperienza fluida ed elevata per gli ospiti. Forniamo servizi leader del settore per soddisfare le aspettative uniche e elevate degli ospiti che occupano suite di livello presidenziale e ambasciatore, garantendo un soggiorno veramente lussuoso.",

  'sixStarVIPPage.subHeader': 'Supporto premium diretto a ingegneri senior e SLA ridotti per il tuo team esecutivo.',
  'sixStarVIPPage.whenYouCall':
    'Quando chiami o crei un ticket, sei garantito di parlare con uno dei nostri ingegneri senior per aiutarti con il tuo problema.',
  'sixStarVIPPage.onTheGo': 'Carte di contatto On-The-Go con un numero diretto per il supporto.',
  'sixStarVIPPage.bigOrSmall': 'Che il problema sia grande o piccolo, possiamo garantire un tempo di SLA inferiore.',
  'sixStarVIPPage.plus':
    'Six Star: VIP Plus, il livello definitivo di supporto progettato per elevare la tua esperienza a livelli di eccellenza senza precedenti. Con VIP Plus, ottieni accesso a un insieme di privilegi che ridefiniscono il supporto al cliente.',
  'sixStarVIPPage.instant': 'Supporto Diretto Istantaneo',
  'sixStarVIPPage.dedicated': 'Numero di Supporto Dedicato',
  'sixStarVIPPage.priority': 'SLA Prioritari',
  'sixStarVIPPage.withPlus': 'con Six Star: VIP Plus',

  'sixStarInternetPage.subHeader':
    'Six Star: Internet combina circuiti dati economici, connettività mobile completa e tronconi SIP con gestione progetti gratuita e supporto robusto.',
  'sixStarInternetPage.leverage':
    'Sfruttiamo partenariati strategici, infrastrutture efficienti e soluzioni tecnologiche per offrire risparmi ai suoi clienti.',
  'sixStarInternetPage.mobileEnhance':
    'La connettività mobile potenzia la flessibilità e la produttività per le aziende in un mondo sempre più centrato sulla mobilità.',
  'sixStarInternetPage.inclusion':
    "L'inclusione di servizi di gestione progetti significa un'esperienza di integrazione senza intoppi per i clienti.",
  'sixStarInternetPage.p1':
    'Six Star dà priorità alla convenienza offrendo circuiti dati alle tariffe più basse possibili. Questa caratteristica è progettata per fornire alle aziende un vantaggio competitivo riducendo al minimo le spese relative alla connettività Internet. Offrendo circuiti dati convenienti, Six Star consente alle aziende di allocare le loro risorse in modo più efficiente, promuovendo la crescita e la sostenibilità.',
  'sixStarInternetPage.p2':
    "Six Star non offre solo tronconi SIP (Session Initiation Protocol) per una comunicazione vocale efficiente, ma si distingue anche per la fornitura di servizi gratuiti di gestione progetti. Ciò include una guida esperta e supporto durante l'intero processo di implementazione. Una volta implementato, il servizio di assistenza di Six Star sarà disponibile 24/7 per qualsiasi domanda o problema che potresti avere.",
  'sixStarInternetPage.projectManagement': 'Gestione Progetti e Supporto',
  'sixStarInternetPage.maximize': 'Massimizzazione dei Risparmi con Circuiti Dati',

  'sixStarPrintPage.header': 'Stampa Gestita senza Sforzo per una Produttività Continua',
  'sixStarPrintPage.subHeader': 'Stampa Intelligente, Senza Capex: Soluzioni Istantanee con Consumabili Competitivi',
  'sixStarPrintPage.printing': 'Stampa senza Capitale',
  'sixStarPrintPage.rapidResponse': 'Risposta Rapida',
  'sixStarPrintPage.costEffectiveConsumables': 'Consumabili Economici',
  'sixStarPrintPage.providing': 'Fornire una soluzione di stampa gestita senza costi iniziali.',
  'sixStarPrintPage.enjoy': 'Goditi il supporto in loco nello stesso giorno/il giorno successivo per una stampa ininterrotta.',
  'sixStarPrintPage.save': 'Risparmia con prezzi competitivi sui consumabili di stampa di Six Star Global.',
  'sixStarPrintPage.hassle': 'Stampa senza Problemi',
  'sixStarPrintPage.unrivaled': 'Garanzia di Tempo di Attività Ineguagliata',
  'sixStarPrintPage.p1':
    "Goditi la stampa senza il fardello finanziario. La soluzione di stampa gestita di Six Star Global ti libera dai costi iniziali, rendendo la stampa efficiente accessibile senza l'investimento di capitale.",
  'sixStarPrintPage.p2':
    'Eleva la tua esperienza di stampa con Six Star Global. Beneficia di un supporto in loco rapido, nello stesso giorno/il giorno successivo, garantendo che le tue operazioni di stampa procedano senza intoppi.',

  'sixStarGuestPage.header': 'Progettato Specificamente per PC Ospiti in Reception o Centri Business',
  'sixStarGuestPage.subHeader':
    "Eleva l'esperienza dei tuoi ospiti e semplifica le operazioni aziendali con la nostra tecnologia all'avanguardia per l'accesso degli ospiti.",
  'sixStarGuestPage.secure': 'Ambiente Sicuro',
  'sixStarGuestPage.fully': 'Totalmente con Licenza',
  'sixStarGuestPage.custom': 'Soluzioni Personalizzate',
  'sixStarGuestPage.B2B': 'Supporto B2B e Frontale per gli Ospiti',
  'sixStarGuestPage.p1':
    "Eleva il tuo centro business con i nostri robusti servizi di supporto B2B e migliora l'esperienza degli ospiti con postazioni di lavoro pubbliche affidabili e facili da usare. Forniamo soluzioni IT su misura per clienti aziendali, creando nel contempo un ambiente senza problemi per gli ospiti, che abbiano bisogno di accesso a Internet, stampa di documenti o altre esigenze informatiche.",
  'sixSTarGuestPage.customised': 'Soluzioni Personalizzate per Qualsiasi Ambiente',
  'sixStarGuestPage.p2':
    'Riconoscendo che ogni struttura di ospitalità è unica, offriamo soluzioni personalizzabili per soddisfare i tuoi requisiti specifici. Dal branding alla configurazione del software, adattiamo i nostri servizi per allinearsi con gli obiettivi aziendali, garantendo nel contempo il supporto 24/7.',

  'sixStarPayPage.header': 'Eleva la Tua Ospitalità, Semplifica le Tue Transazioni',
  'sixStarPayPage.subHeader': "Ottimizzazione dell'Eccellenza Ospitale, una Transazione alla Volta",
  'sixStarPayPage.flexible': 'Pagamento Flessibile',
  'sixStarPayPage.variousPayment': 'Supportiamo varie opzioni di pagamento. Gli ospiti possono pagare come desiderano.',
  'sixStarPayPage.roomCharging': 'Addebito in Camera',
  'sixStarPayPage.orderRouting': 'Instradamento Ordini Semplificato, gli ospiti possono addebitare articoli/servizi sulla loro camera.',
  'sixStarPayPage.payAtTable': 'Paga al Tavolo',
  'sixStarPayPage.ourPay': 'La nostra soluzione Pay-At-Table assicura che il tuo menu sia sincronizzato in tempo reale su tutte le piattaforme.',
  'sixStarPayPage.increasedEfficiency': 'Efficienza Aumentata e Felicità degli Ospiti',
  'sixStarPayPage.p1':
    "Risparmia tempo e migliora l'efficienza con la gestione sincronizzata del menu e le funzionalità di instradamento ordini. Concentrati su offrire un'esperienza gastronomica eccezionale senza problemi operativi. Fai di quel cliente soddisfatto un cliente abituale.",
  'sixStarPayPage.integration': 'Integrazione e Installazione Semplici',
  'sixStarPayPage.p2':
    "L'integrazione senza soluzione di continuità con la maggior parte dei principali sistemi PMS e POS significa che puoi continuare a utilizzare i tuoi strumenti preferiti mentre usufruisci dei vantaggi aggiuntivi di Six Star: Pay.",
  'formLoader.BackButton': 'Indietro',
  'formLoader.forwardButton': 'Avanti',
  'formLoader.submitButton': 'Invia',
  'formLoader.reSubmitButton': 'Re-invia',
  'dataTablePro.error': 'Errore',
  'sixStarTV.design': 'Parliamo di Design',
  'ticketViewPage.button.bundle': 'Bundle',
  'ticketViewPage.button.goBack': 'Indietro',
  'ticketViewPage.ticket.summary': 'Riepilogo del ticket: ',
  'ticketViewPage.formSummary': 'Riepilogo del modulo',
  'ticketViewPage.masterDetail.createdAt': 'Creato il:',
  'ticketViewPage.masterDetail.assignedMember': 'Membri assegnati',
  'ticketViewPage.masterDetail.ticketContacts': 'Contatti del ticket',
  'ticketViewPage.masterDetail.ticketCreatedAt': 'Ticket creato il',
  'ticketViewPage.masterDetail.mostRecentActivity': 'Attività più recente',
  'ticketViewPage.masterDetail.ticketUpdatedBy': 'Ticket aggiornato da',
  'ticketViewPage.masterDetail.viewTicket': 'Visualizza ticket',
  'ticketViewPage.masterDetail.quickNote': 'Nota veloce',
  'ticketViewPage.carbonCopy': 'Copia Carbonio',
  'ticketViewPage.masterDetail.noRecentActivity': 'Nessuna attività recente',
  // "ticketViewPage.carbonCopyNotAvailable": "Nessuna copia carbonio disponibile su questo ticket",
  "ticketViewPage.carbonCopyNotAvailable": "Nessuna CC disponibile per questo ticket",
  "TicketViewPage.toolbar.quickClose.model.subTitle": "Sei sicuro di voler risolvere rapidamente {count} ticket?",
  "TicketViewPage.toolbar.quickClose.model.title": "Risolvi Ticket Rapidamente",
  "TicketViewPage.toolbar.label.quickClose": "Risoluzione Rapida",
  "TicketViewPage.toolbar.quickClose.submitButton": "Risolvi Rapidamente",
  "TicketViewPage.toolbar.quickClose.cancelButton": "Annulla",
  "TicketViewPage.toolbar.quickClose.model.reason": "Inserisci un breve motivo per la chiusura del ticket, ad esempio, spazzatura",
  "TicketViewPage.toolbar.quickClose.model.reasonPlaceholder": "Motivo",
  "TicketViewPage.toolbar.quickClose.model.finished": "I ticket sono stati risolti rapidamente",
  "TicketViewPage.toolbar.quickClose.model.updating": "Aggiornamento dei ticket",
  "TicketViewPage.toolbar.quickClose.model.error": "Errore durante l'aggiornamento dei ticket",
  "TicketViewPage.toolbar.quickClose.closeButton": "Chiudi",
  "menu.manage.helpSection.releaseNotes.tabText": "Note sulla Versione",
  "menu.manage.helpSection.releaseNotes.menuText": "Note sulla Versione",
  "releaseNotesPage.title": "Note sulla Versione",
  "notFoundPage.pageDevelopment": "In sviluppo",
  "notFoundPage.pageDevelopmentMessage": "Spiacenti, questa sezione è attualmente in fase di sviluppo. Si prega di controllare più tardi per aggiornamenti entusiasmanti dal nostro team tecnico",
  "notFoundPage.button.dashboard": "Dashboard",
  "notFoundPage.button.ticket": "I Miei Ticket",
  "ticketViewPage.modal.bundle.childTicketField": "Cerca Ticket Figlio",
  "TicketViewPage.toolbar.saveView": "Salva vista",
  "TicketViewPage.toolbar.saveViewAs": "Salva vista come",
  "TicketViewPage.toolbar.saveViewAsDuplicate": "Salva vista come duplicato",
  "TicketViewPage.toolbar.modal.areYouSure": "Sei sicuro?",
  "TicketViewPage.toolbar.modal.yes": "Sì",
  "TicketViewPage.toolbar.modal.no": "No",
  "ticketViewPage.childTickets": "Ticket figlio",
  "ticketViewPage.parent": "Principale",
  "ticketViewPage.ticketSummary": "Sommario del biglietto",
  "ticketViewPage.ticketNumber": "Numero del biglietto",
  "TicketViewPage.toolbar.label.filters": "Filtri",
  "TicketViewPage.toolbar.label.addContact": "Aggiungi contatto",
  "ticketViewPage.addNote": "Aggiungi nota",
  "ticketViewPage.ticketUpdate": "Aggiornamento ticket",
  "ticketViewPage.infoRequest": "Richiesta di informazioni",
  "ticketViewPage.approval": "Approvazione",
  "ticketViewPage.requestForAction": "Richiesta di azione",
  "ticketViewPage.resolution": "Risoluzione",
  "ticketviewPage.tab.overview": "Panoramica",
  "ticketviewPage.tab.timeline": "Cronologia",
  "ticketviewPage.tab.formSummary": "Riepilogo modulo",
  "ticketviewPage.tab.notes": "Note",
  "ticketviewPage.tab.tasks": "Compiti",
  "ticketviewPage.tab.activity": "Attività",
  "ticketViewPage.createdAt": "Creato il",
  "ticketViewPage.lastUpdate": "Ultimo aggiornamento",
  "ticketViewPage.priority": "Priorità",
  "ticketViewPage.ticketSLA": "SLA del ticket",
  "ticketViewPage.requester": "Richiedente",
  "ticketViewPage.ticketOwner": "Proprietario del ticket",
  "ticketViewPage.ticketStatus": "Stato del ticket:",
  "ticketViewPage.ticketSummary": "Riepilogo del ticket",
  "ticketViewPage.ticketNumber": "Numero del ticket: #",
  "ticketViewPage.podAccess": "Accesso rapido",
  "ticketViewPage.podAccessDesc": "Questo pod ti darà un rapido accesso a diverse sezioni del ticket",
  "ticketViewPage.addContact": "Aggiungi contatto",
  "ticketViewPage.editType": "Modifica tipo",
  "ticketViewPage.assigneeOrRequester": "Aggiorna assegnatario/richiedente",
  "ticketViewPage.contacts": "Contatti",
  "ticketViewPage.contactDecs": "Questo pod mostrerà tutti i contatti relativi a questo ticket",
  "menu.manage.internalSection.forms.tabText": "Moduli",
  "menu.manage.internalSection.forms.menuText": "Moduli",
  "ticketViewPage.toolbar.label.quickEdit": "Modifica rapida",
  "ticketViewPage.toolbar.editTicket.submitButton": "Invia",
  "datatableViews.toolbar.currentView": "Vista attuale",
  "datatableViews.toolbar.loading": "Caricamento...",
  "ticketviewPage.tab.overview": "Panoramica",
  "ticketviewPage.tab.timeline": "Cronologia",
  "ticketviewPage.tab.notes": "Note",
  "ticketviewPage.tab.tasks": "Compiti",
  "ticketviewPage.tab.formSummary": "Riepilogo modulo",
  "ticketviewPage.tab.activity": "Attività",
  "ticketViewPage.timeline.ticketCreated": "Ticket creato",
  "ticketViewPage.timeline.ticketUpdated": "Ticket aggiornato",
  "ticketViewPage.timeline.messageAdded": "Messaggio aggiunto",
  "ticketViewPage.timeline.titleChange": "Cambiamento di titolo",
  "ticketViewPage.organisations": "Organizzazioni",
  "ticketViewPage.ticketDevices": "Dispositivi del ticket",
  "ticketViewPage.ticketCC": "Ticket CC",
  "menu.manage.companyContact.tabText": "Azienda",
  "menu.manage.companyContact.menuText": "Azienda",
  "ticketViewPage.rateYourService": "Valuta il tuo servizio",
  "ticketViewPage.relatedTickets.desc": "Questo pod mostrerà tutti i ticket correlati",
  "ticketViewPage.ticketCC.desc": "Questo pod mostrerà tutte le copie carbonio correlate",
  "ticketViewPage.ticketDevices.desc": "Questo pod mostrerà tutti i dispositivi correlati",
  "ticketViewPage.ticketSLA.desc": "Questo pod mostrerà il SLA di questo ticket",
  "ticketViewPage.documents.desc": "Questo pod mostrerà tutti i documenti relativi a questo ticket",
  "ticketViewPage.organisations.desc": "Questo pod mostrerà tutte le organizzazioni correlate a questo ticket",
  "ticketViewPage.initialResponse": "Risposta iniziale",
  "ticketViewPage.nextUpdate": "Prossimo aggiornamento",
  "ticketViewPage.estimatedResolution": "Risoluzione stimata",
  "ticketViewPage.resolution": "Risoluzione",
  "ticketViewPage.relatedTickets": "Biglietti Correlati",
  "ticketViewPage.bundleTicket": "Unisci Biglietto",
  "ticketViewPage.shareTicket": "Condividi Biglietto",
  "ticketViewPage.contact": "Contatto",
  "ticketViewPage.email": "Email",
  "ticketViewPage.rateYourServiceComment": "Puoi valutare il tuo servizio in qualsiasi momento durante la tua sessione con Six Star Global.",
  "ticketViewPage.responseHistory": "Cronologia delle Risposte",
  "ticketViewPage.initialComment": "Commento Iniziale",
  "ticketViewPage.agentResponse": "Risposta dell'Agente",
  "ticketVIewPage.notClosed": "Non Chiuso",
  "ticketViewPage.created": "Creato il",
  "ticketViewPage.firstResponse": "Prima Risposta",
  "ticketViewPage.estimatedResolution": "Risoluzione Stimata",
  "ticketViewPage.actualResolution": "Risoluzione Effettiva",
  "createOrg.title": "Crea Organizzazione",
  "ticketViewPage.addManager": "Aggiungi Account Manager",
  "editManager.addNew": "Aggiungi Nuovo",
  "editManager.currentManagers": "Manager Attuali",
  "companyContactPage.tabs.overview.region": "Regione",
  "ticketViewPage.ratings": "Valutazioni del Servizio",
  "ticketViewPage.ratingsdesc": "Questo pod mostra eventuali valutazioni lasciate per questo ticket.",
  "menu.manage.userSection.createOrganization.tabText": "Crea Organizzazione",
  "menu.manage.userSection.createOrganization.menuText": "Crea Organizzazione",
  "companyPage.info": "Informazioni sull'Azienda",
  "companyPage.AccountManager": "Account Manager",
  "companyPage.location": "Posizione",
  "companyPage.relatedOrganizations": "Organizzazioni Correlate",
  "companyPage.parentOrganization": "Organizzazione Madre",
  "companyPage.childOrganizations": "Organizzazioni Figlie",
  "companyPage.noParentOrganization": "Nessuna Organizzazione Madre",
  "companyPage.noChildOrganizations": "Nessuna Organizzazione Figlia",
  "ticketViewPage.infoDesc": "Questo pod mostrerà tutte le informazioni relative a questo ticket",
  "ticketViewPage.ticketInfo": "Info Ticket",
  "ticketViewPage.timeline.priorityChange": "Cambio di Priorità",
  "ticketViewPage.timeline.statusChange": "Cambio di Stato",
  "ticketViewPage.timeline.ticketResolved": "Ticket Risolto",
  'ticketViewPage.infoDesc': 'Questo modulo visualizzerà tutte le informazioni relative a questo ticket',
  'ticketViewPage.ticketInfo': 'Informazioni sul Ticket',
  'ticketViewPage.timeline.priorityChange': 'Cambio di Priorità',
  'ticketViewPage.timeline.statusChange': 'Cambio di Stato',
  'ticketViewPage.timeline.ticketResolved': 'Ticket Risolto',
  'ticketViewPage.addSummary': 'Aggiungi Riassunto',
  'ticketViewPage.notResponded': 'Non Risposto',
  'ticketViewPage.noInitialComment': 'Nessun Commento Iniziale',
  'ticketViewPage.noAgentResponse': 'Nessuna Risposta dell\'Agente',
  'ticketViewPage.makePrivate': 'Rendere Privato',
  'ticketViewPage.makePublic': 'Rendere Pubblico',
  'ticketViewPage.pod.team': 'Squadra',
  'ticketViewPage.team.desc': 'Questo modulo mostrerà tutti i membri del team relativi a questo ticket',
};

export default pages;
