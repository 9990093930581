import React, { useEffect, useState } from "react";
import {
  CenterPanel,
  SidePanel, //
  SidePanelCollapse,
  SidePanelScrollWrapper,
  TopPanel, //
} from "containers/Layout/ThreePanelView/components/Styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "@mui/styles/withStyles";
import { Box, Grid, Tooltip } from "@mui/material";
import { useElementSize, useHover } from "@mantine/hooks";
import SidePanelTabScroller from "containers/Layout/ThreePanelView/components/SidePanelTabScroller";
const ThreePanelView = (props) => {
  const {
    sectionScrollPadding = 40,
    loading,
    topSection,
    leftSection,
    centerSection,
    rightSection,
    panelWidth = 350,
    collapsedWidth = 65,
    pageHeaderHeight = 160,
    topPanelHeight = 75,
    singlePanel,
    theme,
  } = props;
  let topPanelHeightRef = topPanelHeight;
  if (!topSection) {
    topPanelHeightRef = 0;
  }
  const [showLeftPanel, setShowLeftPanel] = useState(true);
  const [showRightPanel, setShowRightPanel] = useState(true);
  const [leftPanelWidth, setLeftPanelWidth] = useState(panelWidth);
  const [rightPanelWidth, setRightPanelWidth] = useState(panelWidth);
  useEffect(() => {
    if (showLeftPanel) {
      setLeftPanelWidth(panelWidth);
    } else {
      setLeftPanelWidth(collapsedWidth);
    }
    if (showRightPanel) {
      setRightPanelWidth(panelWidth);
    } else {
      setRightPanelWidth(collapsedWidth);
    }
  }, [showLeftPanel, showRightPanel]);

  const handleCollapse = (panel) => {
    if (panel === "left") {
      setShowLeftPanel(!showLeftPanel);
      if (showLeftPanel) {
        setLeftPanelWidth(collapsedWidth);
      } else {
        setLeftPanelWidth(panelWidth);
      }
    }
    if (panel === "right") {
      setShowRightPanel(!showRightPanel);
      if (showRightPanel) {
        setRightPanelWidth(collapsedWidth);
      } else {
        setRightPanelWidth(panelWidth);
      }
    }
  };
  const { ref: mainWrapperRef, width: mainWrapperWidth } = useElementSize();

  let leftSectionWithProps = null;
  if (leftSection) {
    leftSectionWithProps = React.cloneElement(leftSection, {
      handleCollapse,
      showLeftPanel,
    });
  }
  let rightSectionWithProps = null;
  if (rightSection) {
    rightSectionWithProps = React.cloneElement(rightSection, {
      handleCollapse,
      showRightPanel,
    });
  }
  return (
    <Box
      ref={mainWrapperRef}
      sx={{
        width: "100%",
        height: `calc(100vh - ${pageHeaderHeight}px)`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // overflow: 'hidden',
        // ['@media (max-width: ' + theme.breakpoints.values.lg + 'px)']: {
        //     overflow: 'auto !important'
        // },
      }}
    >
      {!loading && (
        <>
          <Grid
            container
            spacing={0}
            rowSpacing={0}
            rowGap={0}
            style={{ height: "100%" }}
          >
            {topSection && (
              <TopPanel item xs={12} height={topPanelHeightRef}>
                {topSection}
              </TopPanel>
            )}
            <Grid
              container
              spacing={0}
              rowSpacing={0}
              rowGap={0}
              justifyContent="space-between"
              style={{
                height: `calc(100vh - ${topPanelHeightRef + pageHeaderHeight
                  }px )`,
              }}
            >
              <SidePanelTabScroller>
                {" "}
                {leftSectionWithProps} {rightSectionWithProps}
              </SidePanelTabScroller>

              {!singlePanel && (
                <SidePanel
                  item
                  width={leftPanelWidth}
                  display={{ xs: "none", md: "none", lg: "block" }}
                  height={pageHeaderHeight + topPanelHeightRef}
                  panel={"left"}
                >
                  <SidePanelCollapse
                    onClick={() => handleCollapse("left")}
                    right
                  >
                    <FontAwesomeIcon
                      icon={[
                        "fas",
                        `chevron-${showLeftPanel ? "left" : "right"}`,
                      ]}
                      fixedWidth
                    />
                  </SidePanelCollapse>
                  {leftSection && (
                    <SidePanelScrollWrapper
                      paddingBottom={sectionScrollPadding}
                    >
                      {leftSectionWithProps}
                    </SidePanelScrollWrapper>
                  )}
                </SidePanel>
              )}
              <CenterPanel
                item
                xs={12}
                md={12}
                // width={mainWrapperWidth - leftPanelWidth - rightPanelWidth}
                // width={mainWrapperWidth - rightPanelWidth}
                width={
                  singlePanel
                    ? mainWrapperWidth - rightPanelWidth
                    : mainWrapperWidth - leftPanelWidth - rightPanelWidth
                }
              >
                {centerSection && <>{centerSection}</>}
              </CenterPanel>
              <SidePanel
                item
                width={rightPanelWidth}
                display={{ xs: "none", md: "none", lg: "block" }}
                height={pageHeaderHeight + topPanelHeightRef}
                panel={"right"}
              >
                <SidePanelCollapse onClick={() => handleCollapse("right")} left>
                  <Tooltip title={showRightPanel ? "Collapse Panel" : "Open Panel"}>
                  <FontAwesomeIcon
                    icon={[
                      "fas",
                      `chevron-${showRightPanel ? "right" : "left"}`,
                    ]}
                    fixedWidth
                  />
                  </Tooltip>
                </SidePanelCollapse>
                {rightSection && (
                  <SidePanelScrollWrapper paddingBottom={sectionScrollPadding}>
                    {rightSectionWithProps}
                  </SidePanelScrollWrapper>
                )}
              </SidePanel>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default withStyles((theme) => ({}), { withTheme: true })(ThreePanelView);
