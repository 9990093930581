import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";
import Drawer from "@mui/material/Drawer";
import Collapse from "@mui/material/Collapse";
import {
  openView,
  openDynamicView,
  selectMenuItem,
  openDynamicParentView,
} from "containers/App/Redux/menu/actions";
import {
  findMenuItem,
  getSelectedMenuItem,
  getDynamicItem,
} from "utils/menuHelper";
import LeftSideDrawer from "components/Layout/LeftSideBar/LeftSideDrawer";
import LeftSideBarMenuList from "components/Layout/LeftSideBar/LeftSideBarMenuList";
import LeftSideBarSubMenuItem from "components/Layout/LeftSideBar/LeftSideBarSubMenuItem";
import LeftSideBarMenuItem from "components/Layout/LeftSideBar/LeftSideBarMenuItem";
import layoutStyles from "../styles";
class LeftSideBar extends React.Component {
  constructor(props) {
    // console.log('LeftSideBar constructor',props);
    super(props);

    this.state = {
      openDrawerOnHover: false,
      collapseOpenViews: true,
      collapseMenu: true,
      openSubMenu: {},
    };

    this.isClosingOpenView = false;
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.location !== prevProps.location) {
      return {
        location: nextProps.location,
      };
    }
    return null;
  }

  componentDidMount() {
    this.openSubMenuOrDynamicView();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.openSubMenuOrDynamicView();
    }
    if (prevProps.selectedMenuItem !== this.props.selectedMenuItem) {
      this.openSubMenuOrDynamicView();
    }
  }

  openSubMenuOrDynamicView = () => {
    const { selectedParentMenuItem } = getSelectedMenuItem();

    if (selectedParentMenuItem) {
      // this.handleOpenSubMenuClick(selectedParentMenuItem.id, true);
    } else {
      this.handleOpenDynamicView({ ...this.props });
    }
    this.isClosingOpenView = false;
  };

  handleOpenViewTitleClick = () => {
    const { collapseOpenViews } = this.state;
    this.setState({ collapseOpenViews: !collapseOpenViews });
  };

  handleOpenSubMenuClick = (id, forceValue) => {
    const { openSubMenu } = this.state;
    if (openSubMenu[id] === undefined) {
      openSubMenu[id] = false;
    }
    Object.keys(openSubMenu).forEach((key) => {
      if (id !== key) {
        openSubMenu[key] = false;
      }
    });

    openSubMenu[id] = forceValue || !openSubMenu[id];
    // console.log(openSubMenu);
    this.setState({ openSubMenu });
  };

  handleMenuTitleClick = () => {
    const { collapseMenu } = this.state;
    this.setState({ collapseMenu: !collapseMenu });
  };

  handleMenuNavigation = (item, icon) => {
    if (item.isExternalUrl) {
      const win = window.open(item.url);
      win.focus();
      return;
    }
    if (this.isClosingOpenView) {
      return;
    }
    let menuItem = item;

    if (item.isDynamic) {
      const firstChild = item.children[0];
      menuItem = firstChild;
    }
    const { menu, openViews } = this.props;
    const itemExists = openViews.find(
      (openedView) => openedView.id === menuItem.id
    );
    if (!itemExists) {
      this.props.dispatch(openView(menuItem, icon, openViews)); // Add new openView item
    } else {
      let result = findMenuItem(menu, "id", menuItem.id);
      const { foundMenuItem } = result;

      result = findMenuItem(openViews, "id", menuItem.id);
      const foundOpenViewItem = result.foundMenuItem;

      if (foundMenuItem) {
        // Select menu item
        this.props.dispatch(
          selectMenuItem(foundMenuItem, foundOpenViewItem, openViews)
        );
      }
    }
  };

  openDrawer = () => {

    const { type } = this.props;
    this.setState({ openDrawerOnHover: type === "permanent" });
  };

  closeDrawer = () => {
    this.setState({ openDrawerOnHover: false });
  };

  handleCloseView = (menuItem) => {
    this.isClosingOpenView = true;
    this.props.handleCloseView(menuItem);
  };

  handleOpenDynamicView = (props) => {
    const { menu, openViews, location, selectedMenuItem } = props;
    const menuItemExists = findMenuItem(menu, "url", location.pathname);
    if (selectedMenuItem.parent?.parentId) {
      this.handleOpenSubMenuClick(selectedMenuItem.parent.parentId, true);
    }
    // Does the pathname have 2 parts? /path/:id

    const pathnameArr = location.pathname.split("/");
    const pathLength = pathnameArr.length;

    if (
      (pathLength === 3 && !menuItemExists.foundMenuItem) ||
      (pathLength === 4 && !menuItemExists.foundMenuItem)
    ) {
      // is Dynamic route
      let id = pathnameArr[2];
      let value = `/${pathnameArr[1]}/:id`;
      if (pathnameArr.length === 4) {
        id = pathnameArr[3];
        value = `/${pathnameArr[1]}/${pathnameArr[2]}/:id`;
      }

      let { foundMenuItem } = findMenuItem(menu, "url", value);

      if (foundMenuItem) {
        let parentItem;
        if (pathLength === 4) {
          let { foundMenuItem: foundParent } = findMenuItem(
            menu,
            "id",
            foundMenuItem.parent.parentOfParentId
          );
          parentItem = foundParent;
        } else {
          let { foundMenuItem: foundParent } = findMenuItem(
            menu,
            "id",
            foundMenuItem.parent.parentId
          );
          parentItem = foundParent;
        }

        const dynamicItem = getDynamicItem(id, foundMenuItem);

        this.props.dispatch(
          openDynamicView(dynamicItem, parentItem, openViews)
        );
      }
    } else {
      if (menuItemExists?.foundMenuItem?.parent?.showParent === true) {
        const parentItem = menu.find(
          (menuItem) =>
            menuItem.id === menuItemExists.foundMenuItem.parent.parentId
        );

        this.props.dispatch(
          openDynamicParentView(
            menuItemExists.foundMenuItem,
            parentItem.children,
            openViews
          )
        );
      }
    }
  };

  renderCloseMenuItemIcon = (menuItem) => {
    const { classes } = this.props;

    return (
      <i
        role="button"
        tabIndex={menuItem.index}
        onClick={() => this.handleCloseView(menuItem)}
        onKeyPress={() => { }}
        className={classNames(
          "material-icons",
          "left-sidebar-close",
          classes.menuItemCloseIcon
        )}
        style={menuItem.index === 0 ? { display: "none" } : null}
      >
        cancel
      </i>
    );
  };

  renderOpenViews = () => {
    const { openDrawerOnHover } = this.state;
    const { drawerIsOpen, openViews, selectedMenuItem } = this.props;
    const open = drawerIsOpen || openDrawerOnHover;

    return (
      open &&
      openViews.map((item) => {
        return (
          <LeftSideBarMenuItem
            key={`id-${item.id}`}
            item={item}
            handleOnClick={this.handleMenuNavigation}
            selectedMenuItem={selectedMenuItem}
            sidebarIsOpen={open}
            icon={item.icon}
          >
            {this.renderCloseMenuItemIcon(item)}
          </LeftSideBarMenuItem>
        );
      })
    );
  };

  renderSubMenuItem = (item) => {
    const { openDrawerOnHover, openSubMenu } = this.state;
    const { drawerIsOpen, selectedMenuItem } = this.props;
    const open = drawerIsOpen || openDrawerOnHover;

    return (
      <LeftSideBarSubMenuItem
        handleOnClick={this.handleOpenSubMenuClick}
        item={item}
        openSubMenu={openSubMenu}
        sidebarIsOpen={open}
        selectedMenuItemParentId={selectedMenuItem.parent?.parentId}
        selectedMenuItem={selectedMenuItem}
      />
    );
  };

  renderMenuItem = (item, nestedClass) => {
    const { openDrawerOnHover } = this.state;
    const { drawerIsOpen, selectedMenuItem } = this.props;
    const open = drawerIsOpen || openDrawerOnHover;
    let selectedMenuItemParentId;
    if (selectedMenuItem.parent) {
      const { parentId, parentOfParentId } = selectedMenuItem.parent;
      selectedMenuItemParentId = parentId;
      if (parentOfParentId) {
        selectedMenuItemParentId = parentOfParentId;
      }
    }
    return (
      <LeftSideBarMenuItem
        nestedClass={nestedClass}
        icon={item.icon}
        item={item}
        selectedMenuItem={selectedMenuItem}
        selectedMenuItemParentId={selectedMenuItemParentId}
        sidebarIsOpen={open}
      />
    );
  };

  renderCollapseSubMenu = (item) => {
    const { openDrawerOnHover, openSubMenu } = this.state;
    const { classes, drawerIsOpen, user } = this.props;
    const open = drawerIsOpen || openDrawerOnHover;

    return (
      <Collapse in={openSubMenu[item.id]} unmountOnExit>
        {open &&
          item.children.map((subItem) => {
            let authorized = false;
            if (subItem.roles) {
              subItem.roles.forEach((role) => {
                // role = 'ROLE_' + role;
                if (user.roles.some((item) => item === role)) {
                  authorized = true;
                }
              });
            }
            if (authorized && !subItem.dynamic) {
              let disabled = subItem?.disabled ? true : false;
              // console.log(subItem);
              return (
                <button
                  type="button"
                  key={`id-${subItem.id}`}
                  className={classes.link}
                  onClick={() =>
                    !disabled && this.handleMenuNavigation(subItem, item.icon)
                  }
                >
                  {this.renderMenuItem(subItem, classes.nested, item.icon)}
                </button>
              );
            } else {
              return <></>;
            }
          })}
      </Collapse>
    );
  };

  renderMenuItems = () => {
    const { classes, user, menu } = this.props;

    return (
      menu &&
      menu.map((item, index) => {
        let authorized = false;
        if (item.roles) {
          item.roles.forEach((role) => {
            // role = 'ROLE_' + role;
            if (user.roles.some((item) => item === role)) {
              authorized = true;
            }
          });
        }
        if (authorized) {
          let disabled = item?.disabled ? true : false;

          return !item.children || item.isDynamic ? (
            <div
              key={`id-${item.id}`}
              className={classes.link}
              onClick={() =>
                !disabled && this.handleMenuNavigation(item, item.icon)
              }
              onKeyPress={() => { }}
              role="button"
              tabIndex={index}
            >
              {this.renderMenuItem(item, null, item.icon)}
            </div>
          ) : (
            <div key={`id-${item.id}`}>
              {this.renderSubMenuItem(item)}
              {this.renderCollapseSubMenu(item)}
            </div>
          );
        } else {
          return <React.Fragment key={`id-${item.id}`}></React.Fragment>;
        }
      })
    );
  };

  renderOpenViewsList = () => {
    const { openDrawerOnHover } = this.state;
    const { classes, drawerIsOpen } = this.props;
    const { collapseOpenViews } = this.state;
    const open = drawerIsOpen || openDrawerOnHover;

    return (
      <div className="open-views-list">
        {open ? (
          <LeftSideBarMenuList
            handleOnClick={this.handleOpenViewTitleClick}
            isCollapsed={collapseOpenViews}
            menuListClass={classes.openViews}
            sidebarIsOpen={open}
            subHeaderClass={classes.subHeader}
            title="OPEN VIEWS"
          >
            {this.renderOpenViews()}
          </LeftSideBarMenuList>
        ) : null}
      </div>
    );
  };

  renderMenuList = () => {
    const { openDrawerOnHover } = this.state;
    const { classes, drawerIsOpen } = this.props;
    const { collapseMenu } = this.state;
    const open = drawerIsOpen || openDrawerOnHover;

    return (
      <div className="menu-list">
        <LeftSideBarMenuList
          handleOnClick={this.handleMenuTitleClick}
          isCollapsed={collapseMenu}
          menuListClass={classes.root}
          sidebarIsOpen={open}
          subHeaderClass={classes.subHeader}
          title="MENU"
        >
          {this.renderMenuItems()}
        </LeftSideBarMenuList>
      </div>
    );
  };

  renderDrawerMenuItems = () => {
    const { showOpenViews } = this.props;

    return (
      <div>
        {showOpenViews && this.renderOpenViewsList()}
        {this.renderMenuList()}
      </div>
    );
  };

  renderSideBar = () => {
    const { openDrawerOnHover } = this.state;
    const { classes, theme, drawerIsOpen, type } = this.props;
    const open = drawerIsOpen || openDrawerOnHover;

    return (
      <Drawer
        id={`sidebar-menu${type === "temporary" ? "-mobile" : ""}`}
        variant={type}
        anchor={theme.direction === "rtl" ? "right" : "left"}
        open={open}
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !open && classes.drawerPaperClose
          ),
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        onMouseEnter={type === "permanent" ? this.openDrawer : null}
        onMouseLeave={type === "permanent" ? this.closeDrawer : null}
        onClose={this.props.handleDrawerToggle}
      >
        <LeftSideDrawer {...this.props}>
          {this.renderDrawerMenuItems()}
        </LeftSideDrawer>
      </Drawer>
    );
  };

  render() {
    return this.renderSideBar();
  }
}

LeftSideBar.propTypes = {
  drawerIsOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  closeView: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  handleCloseView: PropTypes.func.isRequired,
  location: PropTypes.any.isRequired,
  menu: PropTypes.array.isRequired,
  openViews: PropTypes.array.isRequired,
  selectedMenuItem: PropTypes.object.isRequired,
  showOpenViews: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu.menu,
    openViews: state.menu.openViews[state.menu.currentMenu],
    selectedMenuItem: state.menu.selectedMenuItem[state.menu.currentMenu],
    location: state.router.location,
    showOpenViews: state.menu.showOpenViews,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles((theme) => layoutStyles(theme), {
    withTheme: true,
  })(LeftSideBar)
);
