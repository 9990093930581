import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import TranslationComponent from "components/Global/Translation";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "components/MuiOverride/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql, useLazyQuery } from "@apollo/client";
import { useDebouncedValue } from "@mantine/hooks";
import { Close } from "@mui/icons-material";
import Button from "@mui/material/Button";

const catagoriesIcons = {
    'Tickets': 'ticket',
    'Contacts': 'address-book',
    'Organization': 'building',
}

const Loading = ({ title }) => (
    <ListItem
        sx={{
            backgroundColor: 'rgba(105, 111, 125, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: '10px',
        }}
    >
        <FontAwesomeIcon
            icon={['fas', catagoriesIcons[title]]}
            size={'2x'}
        />
        <ListItemText
            primary={`Searching ${title}`}
            primaryTypographyProps={{
                fontSize: '1.0rem',
                fontWeight: 'bold',
            }}
        />
    </ListItem>
);


const ticketSearch = gql`
    query TicketQuery($query: String = "", $queryNumber: bigint)  {
    ticket(where: {_or: [
        {summary: {_ilike: $query}},
        {subject: {_ilike: $query}},
        {alias: {_eq: $queryNumber}}
    ]}, limit: 5) {
            alias
            id
            summary
            subject
            priority{
                name
            }
            status {
                name
            }
        }
    }
`
const personSearch = gql`
    query PersonQuery($query: String = "") {
    person(where: {first_name: {_ilike: $query}}, limit: 5) {
            alias
            id
            first_name
            last_name
        }
    }
`

const organizationSearch = gql`
    query OrganizationQuery($query: String = "") {
    organization(where: {name: {_ilike: $query}}, limit: 5) {
            alias
            id
            name
        }
    }
`


const Search = React.forwardRef(({ text }, ref) => {
    const [showSearch, setShowSearch] = useState(false);
    const [query, setQuery] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [loading, setLoading] = useState({
        Tickets: false,
        Contacts: false,
        Organization: false,
    });
    const [debounced] = useDebouncedValue(query, 400);
    const [categorys, setCategorys] = useState({
        Tickets: [],
        Contacts: [],
        Organization: [],
    });

    const [getTickets, { data: ticketsData, loading: ticketsLoading }] = useLazyQuery(ticketSearch);
    const [getPeople, { data: contactsData, loading: contactsLoading }] = useLazyQuery(personSearch);
    const [getOrganization, { data: organizationData, loading: organizationLoading }] = useLazyQuery(organizationSearch);

    useEffect(() => {
        if (showSearch === false) {
            setCategorys({
                Tickets: [],
                Contacts: [],
                Organization: [],
            });
            setQuery('');
            setSelectedRecord(null);
            setLoading({
                Tickets: false,
                Contacts: false,
                Organization: false,
            });
        }
    }, [showSearch]);

    useEffect(() => {
        console.log(debounced);
        if (debounced.length > 0) {
            // check if the query is a number
            const queryNumber = parseInt(debounced);
            if (isNaN(queryNumber)) {

                getTickets({ variables: { query: `%${debounced}%`, queryNumber: 0 } });
                getPeople({ variables: { query: `%${debounced}%` } });
                getOrganization({ variables: { query: `%${debounced}%` } });
            }
            else {
                getTickets({ variables: { query: `%${debounced}%`, queryNumber: queryNumber } });
                getPeople({ variables: { query: `%${debounced}%` } });
                getOrganization({ variables: { query: `%${debounced}%` } });
            }
        }
        if (debounced.length === 0) {
            setCategorys({
                Tickets: [],
                Contacts: [],
                Organization: [],
            });
        }
    }, [debounced]);

    useEffect(() => {
        setLoading({
            Tickets: ticketsLoading,
            Contacts: contactsLoading,
            Organization: organizationLoading,
        });
    }, [ticketsLoading, contactsLoading, organizationLoading]);

    useEffect(() => {
        if (ticketsData || contactsData || organizationData) {
            setCategorys({
                Tickets: ticketsData ? ticketsData.ticket : [],
                Contacts: contactsData ? contactsData.person : [],
                Organization: organizationData ? organizationData.organization : [],
            });
        }
    }, [ticketsData, contactsData, organizationData]);



    const handleSearch = (e) => {
        const searchValue = e.target.value;
        setQuery(searchValue);
        // if (searchValue.length > 0) {
        //     const results = {};
        //     for (const category in mockDataCategories) {
        //         results[category] = mockDataCategories[category].filter(item =>
        //             item.label.toLowerCase().includes(searchValue.toLowerCase())
        //         );
        //     }

        //     setFilteredResults(results);
        // } else {
        //     setFilteredResults([]);
        // }
    };

    return (

        <>
            <IconButton aria-label='search' size='large' onClick={() => setShowSearch(!showSearch)}>
                <Tooltip title={<TranslationComponent tag='datatable.toolbar.quickFilterPlaceholder' />}>
                    <Box>
                        <SearchIcon />
                    </Box>
                </Tooltip>
            </IconButton>
            <Modal
                open={showSearch}
                onClose={() => setShowSearch(false)}
                aria-labelledby='search-modal'
                aria-describedby='search-modal'
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', maxWidth: '100vw', maxHeight: '100vh' }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '10vh',
                    left: '10vw',
                    right: '10vw',
                    width: '80vw',
                    boxShadow: 24,
                    backgroundColor: 'transparent',
                    p: 0,
                    m: 0,
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                }}>
                    <Paper elevation={3} sx={{ p: 0, m: 0, width: '80vw', bgcolor: 'rgba(205, 211, 225, 0.9)', borderRadius: '5px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                p: 0,
                                m: 0,
                                marginTop: '-5px'
                            }}
                        >
                            <SearchIcon
                                sx={{
                                    fontSize: '2.5rem',
                                    padding: '0px',
                                    borderRadius: '0px',
                                    borderColor: 'transparent',
                                    marginLeft: '10px',
                                    marginRight: '5px',
                                    marginTop: '10px',
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Manage Search"
                                value={query}
                                onChange={handleSearch}
                                sx={{
                                    border: 'none',
                                    "& fieldset": { border: 'none' }
                                }}
                                InputProps={{
                                    style: {
                                        fontSize: '1.5rem',
                                        padding: '0px',
                                        borderRadius: '0px',
                                        borderColor: 'transparent'
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                p: 0,
                                m: 0,
                                transition: 'all 0.5s',
                            }}
                        >
                            {loading.Tickets && <Loading title='Tickets' />}
                            {loading.Contacts && <Loading title='Contacts' />}
                            {loading.Organization && <Loading title='Organization' />}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                flexDirection: 'row',
                                p: 0,
                                m: 0,
                                height: '100%',
                                transition: 'all 0.5s',
                            }}
                        >
                            <Box
                                sx={{
                                    maxHeight: '70vh',
                                    overflow: 'auto',
                                    transition: 'all 0.5s',
                                    flexGrow: 1,
                                    borderRight: '1px solid rgba(105, 111, 125, 0.5)',
                                }}
                            >
                                {Object.keys(categorys).map((category) => {
                                    if (categorys[category].length === 0 || loading[category] === true) {
                                        return null;
                                    }
                                    return (
                                        <List
                                            key={category}
                                            sx={{
                                                paddingTop: '0px',
                                            }}
                                        >
                                            <ListItem
                                                sx={{
                                                    backgroundColor: 'rgba(105, 111, 125, 0.5)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    flexDirection: 'row',
                                                    gap: '10px',
                                                    transition: 'all 0.5s',
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', catagoriesIcons[category]]}
                                                    size={'2x'}
                                                />
                                                <ListItemText
                                                    primary={category}
                                                    primaryTypographyProps={{
                                                        fontSize: '1.0rem',
                                                        fontWeight: 'bold',
                                                        transition: 'all 0.5s',
                                                    }}

                                                />
                                            </ListItem>
                                            {categorys[category].map((result) => (
                                                <>
                                                    {category === 'Tickets' && (
                                                        <ListItem key={result.id} button onClick={() => {
                                                            setSelectedRecord({
                                                                type: 'Ticket',
                                                                data: result
                                                            });
                                                        }}>
                                                            <ListItemText primary={result.subject} />

                                                        </ListItem>
                                                    )}
                                                    {category === 'Contacts' && (
                                                        <ListItem key={result.id} button onClick={() => {
                                                            setSelectedRecord({
                                                                type: 'Person',
                                                                data: result
                                                            });
                                                        }}>
                                                            <ListItemText primary={`${result.first_name} ${result.last_name}`} />
                                                        </ListItem>
                                                    )}
                                                    {category === 'Organization' && (
                                                        <ListItem key={result.id} button onClick={() => {
                                                            setSelectedRecord({
                                                                type: 'Organization',
                                                                data: result
                                                            });
                                                        }}>
                                                            <ListItemText primary={result.name} />
                                                        </ListItem>
                                                    )}
                                                </>
                                            ))}
                                        </List>
                                    )
                                })}
                            </Box>
                            {selectedRecord && (
                                <Box
                                    sx={{
                                        maxWidth: '30vw',
                                        height: '100%',
                                        flexGrow: 1,
                                        overflowY: 'auto',
                                        borderTop: '1px solid rgba(105, 111, 125, 0.5)',
                                        borderLeft: '1px solid rgba(105, 111, 125, 0.5)',
                                        marginLeft: '-1px',
                                        transition: 'all 0.5s',
                                        padding: '10px',
                                        paddingTop: '0px',
                                        maxHeight: '45vh',
                                    }}
                                >
                                    {selectedRecord.type === 'Ticket' && (
                                        <Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <h5
                                                    style={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    Alias: {selectedRecord.data.alias}
                                                </h5>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexDirection: 'row',
                                                        gap: '10px',
                                                    }}
                                                >
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                    >
                                                        View Ticket
                                                    </Button>
                                                    <Close
                                                        onClick={() => setSelectedRecord(null)}
                                                    />
                                                </Box>
                                            </Box>
                                            <h3>{selectedRecord.data.subject}</h3>
                                            <p>{selectedRecord?.data?.priority?.name}</p>
                                            <p>{selectedRecord?.data?.status?.name}</p>
                                            <p>{selectedRecord.data.summary}</p>
                                        </Box>
                                    )}
                                    {selectedRecord.type === 'Person' && (
                                        <Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <h5
                                                    style={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    Alias: {selectedRecord.data.alias}
                                                </h5>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexDirection: 'row',
                                                        gap: '10px',
                                                    }}
                                                >
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                    >
                                                        View Person
                                                    </Button>
                                                    <Close
                                                        onClick={() => setSelectedRecord(null)}
                                                    />
                                                </Box>
                                            </Box>
                                            <h3>{`${selectedRecord.data.first_name} ${selectedRecord.data.last_name}`}</h3>
                                        </Box>
                                    )}
                                    {selectedRecord.type === 'Organization' && (
                                        <Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <h5
                                                    style={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    Alias: {selectedRecord.data.alias}
                                                </h5>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexDirection: 'row',
                                                        gap: '10px',
                                                    }}
                                                >
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                    >
                                                        View Organization
                                                    </Button>
                                                    <Close
                                                        onClick={() => setSelectedRecord(null)}
                                                    />
                                                </Box>
                                            </Box>
                                            <h3>{selectedRecord.data.name}</h3>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Paper>
                </Box >
            </Modal >
        </>
    )
});

Search.propTypes = {
    text: PropTypes.string,
};

export default Search;



