import { createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";

const themeSky = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "15px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#4292CD",
          "&.Mui-checked": {
            color: "#4292CD",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#4292CD",
        },
      },
    },
    // sx={{
    //   border: '0px solid transparent',
    //   backgroundColor: 'transparent'
    // }}
    MuiCard: {
      variants: [
        {
          props: { variant: "transparent" },
          style: {
            backgroundColor: "transparent",
            border: "1px solid transparent",
          },
        },
      ],
    },
    MuiLoadingButton: {
      variants: [
        {
          props: { variant: "login" },
          style: {
            backgroundColor: "#4292CD",
            color: "red",
            "&:hover": {
              backgroundColor: "#2A203B",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: "15px",
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          boxSizing: "content-box",
          padding: 3,
          fontSize: "1.125rem",
        },
      },
    },
  },
  palette: {
    primary: {
      ...blue,
      500: "#39527b",
    },
    action: {
      active: "#7591bf",
    },
    text: {
      primary: "#97adcf",
      secondary: "#6383b7",
      divider: "#31466a80",
    },
  },
  typography: {
    fontFamily: ["Futura", "Futura Condensed PT"].join(","),
    subtitle1: {
      fontSize: 14,
    },
  },
  listItemText: {
    color: "green",
  },
  custom: {
    link: {
      color: "#e10050",
    },
    appBar: {
      backgroundColor: "#f8f9fa",
    },
    drawerPaper: {
      backgroundColor: "#39527b",
    },
    drawerHeader: {
      backgroundColor: "#31466a",
      color: "#fff",
    },
    drawerMenuList: {
      backgroundColor: "#31466a",
    },
    selectedItem: {
      backgroundColor: "#db1430",
    },
    selectedItemText: {
      color: "#deeeee",
    },
    text: "#757575",
    title: {
      color: "#d6d7e1",
    },
  },
});

export default themeSky;
