const styles = (theme) => ({
  filtersButton: {
    zIndex: 1,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.lightBlue.main,
    cursor: 'pointer',
    color: theme.palette.lightBlue.contrastText,
    borderBottomLeftRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  }
});

export default styles;
