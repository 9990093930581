import React from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import layoutStyles from "containers/Layout/styles";
import Transition from "components/Transition";
import styles from "./styles";
import PageHeader from "components/Global/PageHeader";
import Translation from "utils/translation";

class PageBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: this.props.loading,
    };
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.loading !== prevProps.loading) {
      return {
        loading: nextProps.loading,
      };
    }
    return null;
  }

  render() {
    const { loading, noTitle } = this.state;
    const {
      classes,
      children,
      minHeight,
      navigation,
      showLoadingText,
      title,
      wrapContent,
      noPadding,
      height = "100%"
    } = this.props;

    const content = (
      <div style={{ minHeight: minHeight || 500, height: height }}>
        {loading && (
          <div>
            <div className={classes.loading}>
              <LinearProgress
                color="primary"
                className={classes.loadingIndicator}
              />
            </div>
            {showLoadingText && (
              <div className={classes.loadingText}>{Translation("loading.title")}</div>
            )}
          </div>
        )}
        <Transition animate>{children}</Transition>
      </div>
    );

    return (
      <div
        className={`${classes.mainContainer}  ${
          noPadding ? classes.noPadding : ""
        }`}
      >
        {title && (
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={3}
            className={`${classes.container}`}
          >
            <Grid item width={"100%"}>
              <PageHeader text={title} />
            </Grid>

            {/*{navigation && (*/}
            {/*  <Grid item className={classes.navigationContainer}>*/}
            {/*    <span className={classes.navigationText}>{navigation}</span>*/}
            {/*  </Grid>*/}
            {/*)}*/}
          </Grid>
        )}
        {wrapContent ? (
          <Paper className={classes.paper}>{content}</Paper>
        ) : (
          <div>{content}</div>
        )}
      </div>
    );
  }
}

PageBase.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  minHeight: PropTypes.number,
  navigation: PropTypes.string,
  showLoadingText: PropTypes.bool,
  title: PropTypes.string,
  wrapContent: PropTypes.bool,
};

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  {
    withTheme: true,
  }
)(PageBase);
