import { gql } from "@apollo/client";

const graphQLQueries = () => {
  const GET_ORGANIZATION_PROFILE = gql`
subscription organization_profile($organizationID: uuid!) {
  organization(where: {id: {_eq: $organizationID}}) {
    account_id
    active
    alias
    id
    legal_name
    name
    parent_organization_id
    time_zone_id
    child_organizations {
      name
      id
      locations {
        active
        address_line_one
        address_line_two
        billing
        city
        country_id
        created_at
        deleted
        id
        latitude
        longitude
        mailing
        main
        name
        phone_numbers {
          phone_number {
            account_id
            country_id
            id
            main
            number
            type {
              name
            }
          }
        }
        shipping
        state
        time_zone {
          name
          id
        }
        updated_at
        zip_code
        country {
          name
        }
      }
    }
    account_owners {
      person {
        active
        deleted
        email_address
        id
        name
        organization {
          name
          id
        }
        phone_numbers {
          phone_number {
            main
            number
            type {
              name
              id
            }
            country {
              id
              name
              dialing_prefix
            }
          }
        }
      }
    }
    external_organization_identifiers {
      type_id
      organization_id
      metadata
      ignore
      identifier
      id
      account_id
      type {
        name
        id
      }
    }
    locations {
      active
      address_line_one
      address_line_two
      billing
      city
      country_id
      created_at
      deleted
      id
      latitude
      longitude
      mailing
      main
      name
      zip_code
      country {
        name
      }
      updated_at
      time_zone_id
      state
      shipping
      phone_numbers {
        phone_number {
          type_id
          type {
            id
            name
          }
          number
          main
          id
          country {
            id
            name
            dialing_prefix
          }
        }
      }
    }
    people(limit: 5) {
      active
      deleted
      email_address
      id
      name
      organization {
        name
        id
      }
      phone_numbers {
        phone_number {
          main
          number
          type {
            name
            id
          }
          country {
            id
            name
            dialing_prefix
          }
        }
      }
    }
    service_numbers {
      service_number {
        active
        deleted
        name
        number
      }
    }
    account {
      created_at
      active
    }
    parent_organization {
      active
      id
      legal_name
      name
      locations {
        address_line_one
        address_line_two
        city
        country_id
        country {
          name
        }
      }
    }
  }
}
  `;
  const GET_PRIORITY1S = gql`
    query Priority1s($org_ids: [uuid!] = []) {
      ticket_aggregate(
        where: {
          parent_tickets_aggregate: {count: {predicate: {_eq: 0}}}
          priority: { name: { _eq: "high" } }
          status: { open: { _eq: true } }
          organization_id: {_in: $org_ids}
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
  const GET_PRIORITY2S = gql`
    query Priority2s($org_ids: [uuid!] = []) {
      ticket_aggregate(
        where: {
          parent_tickets_aggregate: {count: {predicate: {_eq: 0}}}
          priority: { name: { _eq: "normal" } }
          status: { open: { _eq: true } }
          organization_id: {_in: $org_ids}
          }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
  const GET_OPENS = gql`
    query Opens($org_ids: [uuid!] = []) {
      ticket_aggregate(
        where: {
          parent_tickets_aggregate: {count: {predicate: {_eq: 0}}}
          status: { open: { _eq: true } }
          organization_id: {_in: $org_ids}
          }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
  const GET_ORGANIZATIONS = gql`
    query getOrganizations  {
      organization (where: {active: {_eq: true}}){
        id
        name
        permissions {
          id
        }
      }
    }
  `;

  return {
    GET_ORGANIZATION_PROFILE,
    GET_PRIORITY1S,
    GET_PRIORITY2S,
    GET_OPENS,
    GET_ORGANIZATIONS
  };
};

export default graphQLQueries;
