import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  exportWidgetLayout: null,
  exportWidget: null,
};

export const clipboardSlice = createSlice({
  name: "clipboard",
  initialState,
  reducers: {
    clipboardExportWidgetLayout: (state, action) => {
      state.exportWidgetLayout = action.payload;
      navigator.clipboard.writeText(action.payload);
    },
    clipboardExportWidget: (state, action) => {
      state.exportWidget = action.payload;
      navigator.clipboard.writeText(action.payload);
    },
  },
});

export const { clipboardExportWidgetLayout, clipboardExportWidget } =
  clipboardSlice.actions;

export default clipboardSlice.reducer;
