import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Modal } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Translation from 'utils/translation';


function SignOutModal (props) {

    return (
        <>
        <Modal 
               open={true} 
               onClose={props.onClose}
               sx={{  display: 'flex',
               alignItems: 'center',
               justifyContent: 'center'}}>
        <Card>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
        <Typography variant='h4'>{Translation("contactUsModals.signOutModal.title")}</Typography>

        </Box>
            <Box sx={{padding: 2}}>
            <Typography>{Translation("contactUsModals.signOutModal.step1")}</Typography>

            <Typography>{Translation("contactUsModals.signOutModal.step2")}</Typography>

            <Typography>{Translation("contactUsModals.signOutModal.step3")}</Typography>

            <Typography>{Translation("contactUsModals.signOutModal.step4")}</Typography>
                
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <Button onClick={props.onClose} 
                
                variant="contained" color="primary">
                Close
                </Button>
                </Box>
        </Card>
        </Modal>
        </>

    );

}

    export default SignOutModal;