import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import PageBase from "components/PageBase";
import useTranslation from "containers/App/Hooks/useTranslation";
import SSG from "images/error/SSG.png";
import DevelopmentStill from "images/error/DevelopmentStill.png";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

export default function NotAuthorizedPage() {
  const { translate } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageBase minHeight={500} loading={false}>
      <Card sx={{ display: 'flex', padding: 0 }}>
        <Box sx={{ width: '50%', padding: '20px', display: 'flex', flexDirection: 'column' }}>
          <Typography variant={"h3"} marginBottom={2}>
            {translate("notAuthorizedPage.desc")}
          </Typography>
          <Box sx={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
            <Button
              variant='contained'
              sx={{ backgroundColor: '#182C48' }}
              onClick={() => navigate("/")}
            >
              {translate("notFoundPage.button.dashboard")}
            </Button>
            <Button
              variant='contained'
              sx={{ backgroundColor: '#182C48' }}
              onClick={() => navigate("/support/tickets")}
            >
              {translate("notFoundPage.button.ticket")}
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'flex-end' }}>
            <img src={SSG} style={{}} />
          </Box>
        </Box>
        <Box sx={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
          <img src={DevelopmentStill} style={{ height: '100%!important', borderRadius: '0 10px 10px 0', overflow: 'hidden' }} />
        </Box>
      </Card>
    </PageBase >
  );
}
