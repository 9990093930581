import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

import gb from 'flag-icons/flags/4x3/gb.svg';
import nl from 'flag-icons/flags/4x3/nl.svg';
import de from 'flag-icons/flags/4x3/de.svg';
import it from 'flag-icons/flags/4x3/it.svg';
import es from 'flag-icons/flags/4x3/es.svg';
import pl from 'flag-icons/flags/4x3/pl.svg';
import th from 'flag-icons/flags/4x3/th.svg';
import LanguageOption from 'components/LanguageOption';
import { Box, Tooltip } from '@mui/material';
import { changeLocale } from 'slices/language';
import { updateLanguage } from 'slices/user';
import useTranslation from 'containers/App/Hooks/useTranslation';
import TranslationComponent from 'components/Global/Translation';

const LanguageSelector = (props) => {
  const [flagSize, setFlagSize] = React.useState(25);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { language } = useSelector((state) => state.user);
  const open = Boolean(anchorEl);
  const languages = [
    {
      image: gb,
      tag: 'en',
      language: 'languageSelector.english',
    },
    {
      image: nl,
      tag: 'nl',
      language: 'languageSelector.dutch',
    },
    {
      image: de,
      tag: 'de',
      language: 'languageSelector.german',
    },
    {
      image: it,
      tag: 'it',
      language: 'languageSelector.italian',
    },
    {
      image: es,
      tag: 'es',
      language: 'languageSelector.spanish',
    },
    {
      image: pl,
      tag: 'pl',
      language: 'languageSelector.polish',
    },
    {
      image: th,
      tag: 'th',
      language: 'languageSelector.thai',
    },
  ];

  const findLanguage = (lang) => {
    return languages.find((language) => language.tag === lang);
  };
  const [currentLanguage, setCurrentLanguage] = React.useState(findLanguage(language));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const changeLanguage = (lang) => {
    dispatch(updateLanguage(lang));
  };
  useEffect(() => {
    setCurrentLanguage(findLanguage(language));
    dispatch(changeLocale(language));
  }, [language]);
  const { translate } = useTranslation();
  return (
    <div>
      <Tooltip title={<TranslationComponent tag='tooltip.chooseLanguage' />} disableInteractive>
        <IconButton
          size='large'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <img src={currentLanguage.image} width={flagSize} height={flagSize} alt='English' />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          padding: '10px',
        }}
      >
        <Box onClick={() => changeLanguage('en')}>
          <LanguageOption flag={gb} flagSize={flagSize} language={translate('languageSelector.english')} />
        </Box>
        <Box onClick={() => changeLanguage('nl')}>
          <LanguageOption flag={nl} flagSize={flagSize} language={translate('languageSelector.dutch')} />
        </Box>
        <Box onClick={() => changeLanguage('de')}>
          <LanguageOption flag={de} flagSize={flagSize} language={translate('languageSelector.german')} />
        </Box>
        <Box onClick={() => changeLanguage('it')}>
          <LanguageOption flag={it} flagSize={flagSize} language={translate('languageSelector.italian')} />
        </Box>
        <Box onClick={() => changeLanguage('es')}>
          <LanguageOption flag={es} flagSize={flagSize} language={translate('languageSelector.spanish')} />
        </Box>
        <Box onClick={() => changeLanguage('pl')}>
          <LanguageOption flag={pl} flagSize={flagSize} language={translate('languageSelector.polish')} />
        </Box>
        <Box onClick={() => changeLanguage('th')}>
          <LanguageOption flag={th} flagSize={flagSize} language={translate('languageSelector.thai')} />
        </Box>
      </Menu>
    </div>
  );
};

export default LanguageSelector;
