import React, { useState, useEffect, useRef } from "react";
import { useScrollIntoView } from "@mantine/hooks";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import PageBase from "components/PageBase";
import { Box } from "@mui/system";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import useTranslation from "containers/App/Hooks/useTranslation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles";
import { useTheme } from "@mui/system";
import BlackHeader from "images/black/BlackHeader.png";
import plus from "images/tv/plus.png";
import { Button } from "@mui/material";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Translation from "utils/translation";
import { useMediaQuery } from "@mui/material";
import BlackStill1 from "images/black/BlackStill1.png";
import BlackStill2 from "images/black/BlackStill2.png";

const SixStarBlackPage = ({ classes }) => {
    const theme = useTheme();
    const { translate } = useTranslation();
    const [loading, setLoading] = useState(true);
    const isPhoneSize = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        let loadingTimer = setTimeout(() => setLoading(false), 300);
        return () => {
            clearTimeout(loadingTimer);
        };
    });

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <>
            <PageBase minHeight={500} loading={loading} noPadding={true}>
                <Box sx={{ background: '#000000' }}>
                    <img

                        src={BlackHeader}
                        alt={"Six Star Voice"}
                        style={{
                            width: "67%",
                            margin: "auto",
                            display: "block",
                        }}
                    />

                    <Box sx={{ background: "white!important", height: 'auto', }}>
                        <Typography
                            variant={"h3"}
                            align={"center"}
                            sx={{
                                fontWeight: 500,
                                display: "block",
                                paddingTop: theme.spacing(6)

                            }}
                        >
                            {translate("sixStarBlackPage.header")}
                        </Typography>
                        <Box
                            align={"center"}
                            sx={{
                                paddingTop: theme.spacing(4),
                                display: "block",
                                paddingX: 4
                            }}>
                            <Typography>{translate("sixStarBlackPage.subHeader")}</Typography>
                        </Box>

                        <Box  spacing={5} style={{ alignItems: 'center',justifyContent:'center', paddingTop: theme.spacing(4), height: 'auto', display: 'flex', flexDirection: isPhoneSize ? "column" : "row"  }}>
                            <Box sx={{padding: 2}}>

                                <FontAwesomeIcon
                                    size="xl"
                                    icon={["fas", "hotel"]}
                                    color="white"
                                    style={{
                                        fontSize: "5rem",
                                        backgroundColor: "black",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" fontWeight={500}>{translate("sixStarBlackPage.hotel")}</Typography>
                                <Typography align="center" style={{ whiteSpace: "normal" }}>{translate("sixStarBlackPage.aService")}</Typography>
                            </Box>
                            <Box sx={{padding: 2}}>
                                <FontAwesomeIcon
                                    size="xl"
                                    icon={["fas", "suitcase"]}
                                    color="white"
                                    style={{
                                        fontSize: "5rem",
                                        backgroundColor: "black",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" fontWeight={500}>{translate("sixStarBlackPage.traveler")}</Typography>
                                <Typography align="center" style={{ whiteSpace: "normal" }}>{translate("sixStarBlackPage.provides")}</Typography>
                            </Box>

                 


                        </Box>

                        <Box
                            sx={{ background: "#000000", marginTop: theme.spacing(4), height: 'auto', paddingBottom: 8 }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ marginTop: 8 }}>
                                        <img

                                            src={BlackStill1}
                                            alt={"Six Star TV"}
                                            style={{
                                                width: "90%",
                                                margin: "auto",
                                                display: "block", 
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ width: '100%', align: 'center', paddingX: 8 }}>
                                        <Typography align={"center"} variant="h4" sx={{ color: "white!important", marginTop: 16, fontWeight: 500, paddingBottom: 6  }}>Six Star: Black <br/> {translate("sixStarBlackPage.traveler")}</Typography>
                                        <Typography align={"center"}  sx={{ color: "white!important" }}>{translate("sixStarBlackPage.p1")}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>



                        </Box>

                        <Box
                            sx={{ background: "white", marginTop: theme.spacing(4), height: 'auto', paddingBottom: 8 }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={isPhoneSize ? 12 : 6}>



                                    <Box sx={{ width: '100%', align: 'center', paddingX: 8 }}>
                                        <Typography align={"center"} variant="h4" sx={{ marginTop: 16, fontWeight: 500, paddingBottom: 6  }}>Six Star: Black <br/> {Translation("sixStarBlackPage.hotel")}</Typography>
                                        <Typography align={"center"}  sx={{}}>{translate("sixStarBlackPage.p2")}</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ marginTop: 8 }}>
                                        <img

                                            src={BlackStill2}
                                            alt={"Six Star TV"}
                                            style={{
                                                width: isPhoneSize? '100%':"90%",
                                                margin: "auto",
                                                display: "block",
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>



                        </Box>

                    </Box>

                    <Box sx={{ background: "#000000", height: 'auto', paddingBottom: 4 }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop:4 }}>
                                <Button
                                    variant="contained"
                                    color="white"
                                    sx={{ borderRadius: 8, width: isPhoneSize ? '60%' : '30%', margin: 1, }}
                                >
                                    {translate("sixStar.GetInTouch")}
                                </Button>
                            </Grid>
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start', paddingTop:4 }}>
                                <Button
                                    variant="contained"
                                    color="white"
                                    sx={{ borderRadius: 8, width: isPhoneSize ? '60%' : '30%', margin: 1 }}
                                    onClick={handleScrollToTop}
                                >
                                    
                                    {translate("sixStar.BackToTop")}
                                    <FontAwesomeIcon icon={faArrowUp} style={{ marginLeft: 8 }} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>


            </PageBase>

        </>
    );

}

export default SixStarBlackPage;