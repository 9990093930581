import React from 'react'
import { Box, Button, FormControl, Select, Popover } from '@mui/material'
import GridToolbarViewSelectorItem from './GridToolbarViewSelectorItem'
import GridToolbarViewSelectorSaveItem from './GridToolbarViewSelectorSaveItem'
import PreviewIcon from '@mui/icons-material/Preview';
import Translation from 'utils/translation';

const GridToolbarViewSelector = ({ avaliableViews, currentView, setCurrentView, saveNewView, deleteView }) => {
    const [anchorEl, setAnchorEl] = React.useState(false);
    const sortedViews = avaliableViews.sort((a, b) => a.name.localeCompare(b.name));
    const id = 'simple-popover'

    if (!avaliableViews || !currentView) return null

    const openDrowdown = () => {
        setAnchorEl(true)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <FormControl variant="standard">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    aria-describedby={id}
                    size='small'
                    onClick={handleClick}
                    startIcon={<PreviewIcon />}
                    sx={{
                        margin: 0,
                        padding: 0,
                    }}
                >
                    {Translation('ticketViewPage.toolbar.label.openViews')}
                </Button>
            </Box>
            <Popover
                id={'FILTERS-BUTTON'}
                open={anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    '& .MuiPopover-paper': {
                        overflow: 'hidden',
                    }
                }}
            >
                {sortedViews.map((view) => (
                    view.hidden || view.name === 'ssg_hidden' ? null : (
                        <GridToolbarViewSelectorItem
                            key={view.id}
                            id={view.id}
                            name={view.name}
                            default_flag={view.default_flag}
                            global={view.global}
                            deleteView={deleteView}
                            setCurrentView={setCurrentView}
                            avaliableViews={avaliableViews}
                            currentView={currentView}
                            setAnchorEl={setAnchorEl}
                        />
                    )
                ))}
                <GridToolbarViewSelectorSaveItem saveNewView={saveNewView} setAnchorEl={setAnchorEl} />
            </Popover>

        </FormControl>
    )
}

export default GridToolbarViewSelector