const widgets = {
  "title.editWidget.transparent": "Transparent",
  "title.editWidget.opaque": "Opaque",
  "title.editWidget.edit": "Edit",
  "title.editWidget.export": "Export",
  "title.editWidget.delete": "Delete",
  "form.widget.import.field": "Import String",
  "title.widget.options": "Options",
  "title.widget.import.widget": "Import Widget",
  "title.widget.import.layout": "Import Widget Layout",
  "button.widget.add": "add",
  "button.widget.save": "save",
  "button.widget.edit": "edit",
  "button.widget.cancel": "cancel",
  "button.widget.move": "move/resize",
  "button.widget.export": "export",
  "button.widget.import": "import",
  "blank.widget.name": "New Shiny Blank Widget",
  "blank.widget.description": "A blank widget what more do you want?",
  "coda.widget.name": "Coda Embed Document",
  "coda.widget.description": "Coda Embed Document",
  "notification.widget.export": "Export copied to clipboard",
  "notification.widget.exportFailed": "Export failed to copy to clipboard",
  "links.widget.name": "Links",
  "links.widget.description":
    "This widget can provide a list of links that a custom can click and be taken to",
  "links.button.delete": "Delete",
  "links.dropdown.newWindow": "New Window",
  "links.dropdown.sameWindow": "Same Window",
  "links.button.addNew": "Add New Link",
};
export default widgets;
