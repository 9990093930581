import PropTypes from 'prop-types'
import SSGFormCondition from 'components/SSGForm/SSGFormCondition'
import Checkbox from '@mui/material/Checkbox'

const SSGFormCheckBoxOptions = ({ field, handleChange, formValues }) => {
  const { options, name, required, placeholder, label } = field
  return (

    <>
      <label>{label}{required && '*'}</label>

      {options.length > 0 &&
        options.map((option, index) => {
          return (
            <div key={index}>
              <Checkbox
                label={option.label}
                onChange={(e, v) => {
                  handleChange(option.label, v)
                }}
              />
              {/* <input
                type="checkbox"
                name={name}
                required={required}
                placeholder={placeholder}
                value={option.value}
                checked={formValues[name][option.value]}
                onChange={handleChange}
              />
              */}
              <label>{option.label}</label>
              {option.subLabel && <label>{option.subLabel}</label>}
            </div>
          )
        })
      }
    </>

  )
}


export default SSGFormCheckBoxOptions