const widgets = {
  "widget.barChart.name": "Widget del grafico a barre",
  "widget.barChart.description":
    "Widget del grafico a barre che visualizza il numero di ticket prioritari aperti.",
  "widget.organizationTickets.name": "Widget dei ticket dell'organizzazione",
  "widget.organizationTickets.description":
    "Questo è un widget che visualizza il numero di ticket prioritari aperti.",
  "widget.outlookInbox.name": "Widget della casella di posta di Outlook",
  "widget.outlookInbox.description":
    "Questo è un widget che visualizza il numero di email nella tua casella di posta.",
  "widget.personTickets.name": "Widget dei ticket personali",
  "widget.personTickets.description":
    "Questo è un widget che visualizza il numero di ticket prioritari aperti.",
  "widget.pieChart.name": "Widget del grafico a torta",
  "widget.pieChart.description":
    "Widget del grafico a torta che visualizza il numero di ticket aperti per un contatto.",
  "widget.quickLinks.name": "Widget dei collegamenti rapidi",
  "widget.quickLinks.description":
    "Questo è un widget che visualizza un elenco di collegamenti ad altre pagine del sito.",
  "widget.social.name": "Widget del feed sociale",
  "widget.social.description":
    "Questo è un widget di feed sociale collegato agli account dei social media di Six Star.",
  "widget.ticketTable.name": "Widget della tabella dei ticket",
  "widget.ticketTable.description":
    "Widget che visualizza una tabella dei ticket.",
  "widget.social.feedType": "Facebook / Twitter",
  "widget.quickLinks.newRequest": "Nuova richiesta",
  "widget.quickLinks.licenceManagement": "Gestione licenze",
  "widget.quickLinks.assetManagement": "Gestione degli asset",
  "widget.quickLinks.sixStarDrive": "Six Star Drive",
  "widget.quickLinks.sixStarGlobal": "Six Star Global",

  "widget.quickLinks.button.newRequest": "Nuova Richiesta",
"widget.quickLinks.button.licenceManagement": "Gestione Licenze",
"widget.quickLinks.button.assetManagement": "Gestione Asset",
"widget.quickLinks.button.sixStarDrive": "Six Star Drive",

"widgets.org.tickets.p1": "Biglietti Org P1",
"widgets.org.tickets.p2": "Biglietti Org P2",
"widgets.org.tickets.open": "Biglietti Org Aperti",

"widgets.personal.tickets.p1": "Personal P1",
"widgets.personal.tickets.p2": "Personal P2",
"widgets.personal.tickets.open": "Personal Aperti",
"widgets.personal.tickets.p1": "P1 Personali",
"widgets.personal.tickets.p2": "P2 Personali",
"widgets.personal.tickets.open": "Aperti Personali",
"widget.Guage.name": "Widget Misuratore Risolto",
"widget.Guage.description": "Questo è un widget che mostra il numero di ticket risolti dalla coda di oggi.",
"widget.LineGraph.name": "Widget Grafico a Linee",
"widget.LineGraph.description": "Questo è un widget che mostra il numero di ticket creati negli ultimi 30 giorni.",
"widget.muiBarChart.name": "Widget Grafico a Barre",
"widget.muiBarChart.description": "Questo è un widget che mostra il numero di ticket, per priorità, creati negli ultimi 30 giorni.",
"widget.ReleaseNotes.name": "Widget Note sulla Versione",
"widget.ReleaseNotes.description": "Questo è un widget che mostra le ultime note sulla versione.",
"widget.DailyUpdates.name": "Widget Aggiornamenti Giornalieri",
"widget.DailyUpdates.description": "Questo è un widget che mostra gli aggiornamenti giornalieri.",
"widget.TicketPieChart.name": "Widget Grafico a Torta dei Ticket",
"widget.TicketPieChart.description": "Questo è un widget che mostra il numero di ticket per stato.",
"widget.barchart.title" : "Ticket per Priorità",
"widget.linechart.title" : "Ticket Creati Negli Ultimi 30 Giorni",
"widget.piechart.title" : "Ticket per Stato",
"widget.guage.title" : "Ticket Risolti dalla Coda di Oggi",

};
export default widgets;
