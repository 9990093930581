const styles = (theme) => ({
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "40px",
        backgroundColor: '#DAD9EC',
        padding: theme.spacing(3),
      
    },
    headingText: {
        fontWeight: "bold",
    },
    searchContainer: {
        width: "60%",
        height: "50px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        padding: "0 5px",
        borderRadius: "15px",
    },
    searchInput: {
        border: "none",
        flexGrow: 1,
        height: "100%",
        borderRadius: "15px",
    },
    boxContainer: {
        display: "flex",
        flexDirection: "row",
        width: "60%",
        gap: "20px",
        justifyContent: "space-between",

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column', 
            gap: "20px", 
          },
    },
    headingTextSecond: {
        marginBottom: "40px"
    },
    callUsButton: {
        backgroundColor: "red",
        color: "white",
        borderRadius: "15px",
        width: "30%!important",
        height: "50px!important",
        marginLeft: "auto",
    },
});

export default styles;
