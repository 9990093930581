import React, { useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import {
  Box,
  Chip,
  OutlinedInput,
  Select,
  MenuItem,
  FormControl,
  FilledInput,
  InputLabel,
} from "@mui/material";
import TextField from "components/MuiOverride/TextField";

function DropdownSelect(props) {
  let { classes, theme, options, label, onChange, value } = props;
  console.log(options)
  const [selected, setSelected] = React.useState([]);
  useEffect(() => {
    if (value === null) {
      setSelected([]);
    }
  }, [value]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length === 0) {
      onChange(event, null);
    } else {
      onChange(event, value);
    }
    setSelected(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <FormControl fullWidth size={"small"}>
        <InputLabel id={`${label}-multiple-chip-label`}>{label}</InputLabel>
        <Select
          label={label}
          multiple
          value={selected}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{
                    boxShadow: "0",
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((name) => {
            return (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}

export default withStyles(
  (theme) => ({
    ...styles(theme),
  }),
  { withTheme: true }
)(DropdownSelect);
