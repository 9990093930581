import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
  Button,
  Card,
  Grid,
  TextField,
  CardContent,
  Tooltip,
} from '@mui/material';
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

export const TicketPageHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: 84,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  position: 'sticky', // Fixed position
  top: 0, // Position from the top

  // zIndex: 9999,
}));

export const TicketPageHeaderIcon = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.ticketHeaderIcons.primary,
  color: theme.palette.ticketHeaderIcons.icon,
  fontSize: 16,
  width: 30,
  height: 26,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(0.5),
  userSelect: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
}));

export const TicketPageHeaderTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
}));

export const TicketPageHeaderButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.5, 1.5),
  cursor: 'pointer',
  backgroundColor: theme.palette.ticketHeaderIcons.primary,
  color: theme.palette.text.light,
  borderRadius: theme.spacing(1.25),
  fontSize: 16,
  gap: theme.spacing(2),
  fontWeight: 600,
  userSelect: 'none',
}));

export const TicketPageTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.tabs.background.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  top: 0,
  position: 'sticky',
  zIndex: 1,
}));

export const TicketPageTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.tabs.text.primary,
  backgroundColor: theme.palette.tabs.background.primary,
  fontSize: 16,
  fontWeight: 600,
  textTransform: 'none',
  padding: theme.spacing(1, 2),
  '&.Mui-selected': {
    borderTopRightRadius: theme.spacing(1.875),
    color: theme.palette.tabs.text.selected,
    backgroundColor: theme.palette.tabs.background.secondary,
    '&:not(:first-of-type)': {
      borderTopLeftRadius: theme.spacing(1.875),
    },
  },
}));

export const TicketPageTabPanel = styled(Box)(({ theme, value, index }) => ({
  backgroundColor: theme.palette.background.dark,
  height: '100%',
  flexGrow: 1,
  display: 'flex',
  position: 'relative',
  display: value !== index ? 'none' : 'block',
  padding: theme.spacing(6.25),
  overflow: 'auto',
  // overflow: 'auto',
}));

export const TicketPageOverview = styled(Paper)(({ theme, content }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderRadius: theme.spacing(1.25),
  borderTopLeftRadius: 0,
  position: 'relative',
  marginTop: theme.spacing(0),
  zIndex: 0,
}));

export const TicketNumber = styled(Box)(({ theme, ticketalias }) => ({
  height: '40px',
  width: ticketalias?.length > 6 ? '272px' : '250px',
  backgroundColor: theme.palette.background.paper,
  border: '1px solid #ccc',
  left: '-1px',
  borderBottom: 'none',
  borderRadius: `${theme.spacing(1.25)} ${theme.spacing(1.25)} 0 0`,
  // zIndex: 1,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 2),
  top: 0,
}));

export const TicketNumberText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  cursor: 'pointer',
  userSelect: 'none',
}));

export const TicketPageOverViewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  height: '100%',
  width: '100%',
}));

export const TicketPageOverViewContainerColumn = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  alignItems: 'center',
  justifyContent: 'space-around',
}));

export const TicketOverviewSLA = styled(Typography)(({ theme, variant }) => ({
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.text.light,
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.spacing(2.5),
  fontSize: 14,
  fontWeight: 600,
  ...(variant === 'success' && {
    backgroundColor: theme.palette.success.main,
  }),
  ...(variant === 'error' && {
    backgroundColor: theme.palette.error.main,
  }),
  ...(variant === 'warn' && {
    backgroundColor: theme.palette.warn.main,
  }),
}));

export const TicketPageOverviewClickable = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  userSelect: 'none',
  color: theme.palette.links.main,
  '&:hover': {
    color: theme.palette.links.hover,
  },
}));

export const TicketPageOverviewSummary = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderRadius: theme.spacing(1.25),
  position: 'relative',
  marginTop: theme.spacing(6),
  zIndex: 0,
  borderTopLeftRadius: 0,
}));

export const TicketPageMessageContainer = styled(Paper)(({ theme, type }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  height: 'auto',
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderRadius: theme.spacing(1.25),
  position: 'relative',
  marginTop: theme.spacing(6.25),
  zIndex: 0,
  paddingLeft: theme.spacing(3.375),
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 11,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    borderTopLeftRadius: theme.spacing(1.25),
    borderBottomLeftRadius: theme.spacing(1.25),
    ...(type === 'success' && {
      backgroundColor: theme.palette.success.main,
    }),
    ...(type === 'error' && {
      backgroundColor: theme.palette.error.main,
    }),
    ...(type === 'warn' && {
      backgroundColor: theme.palette.warn.main,
    }),
  },
}));



export const TicketPageMessageContainerPreview = styled(Box)(({ theme, type, isSelected }) => ({
  padding: theme.spacing(2),
  backgroundColor: isSelected ? theme.palette.grey[300] : theme.palette.background.paper,
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  zIndex: 0,
  paddingLeft: theme.spacing(3.375),
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 11,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    ...(type === 'success' && {
      backgroundColor: theme.palette.success.main,
    }),
    ...(type === 'error' && {
      backgroundColor: theme.palette.error.main,
    }),
    ...(type === 'warn' && {
      backgroundColor: theme.palette.warn.main,
    }),
  },
}));

export const TicketPageMessageContainerPreviewFull = styled(Paper)(({ theme, type }) => ({
  padding: theme.spacing(2),
  // backgroundColor: theme.palette.background.paper,
  height: '55vh',
  overflow: 'auto',
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderRadius: theme.spacing(1.25),
  // justifyContent: 'center',
  // alignItems: 'center',
  position: 'relative',
  // smarginTop: theme.spacing(6.25),
  // zIndex: 1000,
  paddingLeft: theme.spacing(3.375),
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: 11,
    // backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    borderTopLeftRadius: theme.spacing(1.25),
    borderBottomLeftRadius: theme.spacing(1.25),
    ...(type === 'success' && {
      backgroundColor: theme.palette.success.main,
    }),
    ...(type === 'error' && {
      backgroundColor: theme.palette.error.main,
    }),
    ...(type === 'warn' && {
      backgroundColor: theme.palette.warn.main,
    }),
  },
}));

export const TicketPageMessageContainerInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
}));

export const TicketPageMessageContainerInnerLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));

export const TicketPageMessageContainerInnerLeftPreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  flexDirection: 'row',
  width: '100%',
}));

export const TicketPageMessageContainerInnerRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 2),
  flexGrow: 1,
  width: '90%',
}));

export const TicketPageMessageContainerInnerRightPreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 2),
  // flexGrow: 1,
  width: '90%',
}));

export const TicketPageMessageUsersName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.links.main,
  textAlign: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  '&:hover': {
    color: theme.palette.links.hover,
  },
  //no wrap
  whiteSpace: 'nowrap',
}));

export const TicketPageMessageContainerDateStatus = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  width: '100%',
}));

export const TicketPageMessageContainerName = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  width: '100%',
}));

export const TicketPageFullNoteHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(1.25),
}));

export const TicketPageFullNoteBody = styled(Box)(({ theme }) => ({
  // height: '100%',
  overflowY: 'auto',
  display: 'flex',
  height: '70%',
  top: 0,
}));

export const TicketPageMessageContainerInnerRightMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: theme.spacing(2),
  width: '100%',
  flexDirection: 'column',
}));

export const TicketPageMessageContainerInnerRightMessageTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(3),
  cursor: 'pointer',
}));

export const TicketPageMessageContainerInnerRightMessageText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: theme.spacing(4.25),
  width: '100%',
  height: 'auto',
}));

export const TicketPageMessageContainerInnerRightMessageTextPreview = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingLeft: theme.spacing(4.25),
  width: '100%',
  height: 'auto',
}));

export const TicketPageNotesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: '55vh',
}));

export const TicketMessageAllNotesContainer = styled(Box)(({ theme }) => ({
  height: '55vh',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  // width: '40%',
  overflowX: 'hidden',
}));

export const TicketPageOpenNoteContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  height: '55vh',
  // backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
}));


export const TicketPageMainSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
  width: '100%',
  // border: `5px solid ${theme.palette.generic.foreground}`,
  // overflow: 'auto',
  top: 0,
  backgroundColor: theme.palette.background.dark,
}));

export const TabsSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 278px)',
  width: '100%',
  marginTop: 0,
  marginBottom: 1,
  backgroundColor: theme.palette.background.dark,
  // borderBottom: `5px solid ${theme.palette.generic.foreground}`,
}));

export const TicketPodsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  // width: '345px',
  // maxWidth: '345px',
  // minWidth: '345px',
  // height: '100%',
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
  // marginTop: theme.spacing(6),
  position: 'sticky',
  right: 0,
  // marginTop: 0,
  //border: `5px solid ${theme.palette.generic.foreground}`,
}));

export const TicketPodsContainerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  //  width: '100%',
  height: 48,
  backgroundColor: theme.palette.tabs.background.primary,
  position: 'sticky',
  //marginTop: theme.spacing(5),
  top: 0,
  zIndex: 1000
}));

export const TicketPagePodsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(0, 1),
  borderBottom: `1px solid ${theme.palette.generic.foreground}`,
  paddingBottom: theme.spacing(2.75),
  // border : `5px solid ${theme.palette.generic.foreground}`,
}));

export const TicketPagePodsContainerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  padding: theme.spacing(0, 1),
  height: 48,
}));

export const TicketPagePodsContainerHeaderIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(0.75),
}));

export const TicketPagePodsContainerHeaderTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 48,
  fontWeight: 600,
  fontSize: 12,
}));

export const TicketPagePodsContainerHeaderQuestionMark = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  height: 14,
  width: 14,
  backgroundColor: theme.palette.pods.header.icon.primary,
  color: theme.palette.pods.header.icon.textColor,
  borderRadius: '50%',
  marginLeft: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.pods.header.icon.hover,
  },
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
}));

export const TicketPagePodsContainerHeaderActionButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  height: 24,
  width: 24,
  backgroundColor: theme.palette.pods.header.icon.hover,
  color: theme.palette.pods.header.icon.textColor,
  borderRadius: '50%',
  marginLeft: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.pods.header.icon.primary,
  },
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
}));

export const TickePagePodsContainerHeaderHorizontalSpacer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
}));

export const PodsPodAccessButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  cursor: 'pointer',
  userSelect: 'none',
  borderRadius: '50%',
  width: 42,
  height: 42,
  backgroundColor: theme.palette.pods.header.icon.hover,
  color: theme.palette.pods.header.icon.textColor,
  '&:hover': {
    backgroundColor: theme.palette.pods.header.icon.primary,
  },
}));

export const PodsPodAccessButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
}));

export const TicketPagePodsBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
}));

export const PodSubSectionHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 12,
  color: theme.palette.text.primary,
  marginTop: theme.spacing(1),
}));

export const TicketPodContactContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.3, 0),
}));

export const TicketPodContactContainerName = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.links.main,
  width: '50%',
  cursor: 'pointer',
  '$.hover': {
    color: theme.palette.links.hover,
  },
}));

export const TicketPodContactContainerTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.links.secondary,
  textDecoration: 'underline',
  width: '50%',
  cursor: 'pointer',
  margin: theme.spacing(0.3, 0),
  '$.hover': {
    color: theme.palette.links.hover,
  },
}));

export const TicketPodOrganisationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.main,
  borderRadius: theme.spacing(1.25),
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  transition: 'all 0.3s ease',
  margin: theme.spacing(0.4, 0),
}));

export const TicketPodOrganisationContainerType = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.links.main,
  cursor: 'pointer',
  '$.hover': {
    color: theme.palette.links.hover,
  },

}));

export const TicketPodOrganisationRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

export const TicketPodOrganisationContainerName = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.links.secondary,
  textDecoration: 'underline',
  cursor: 'pointer',
  textAlign: 'right',
}));

export const TicketPageDocumentPodSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
}));

export const TicketPageDocumentPodSectionContainerTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
}));

export const TicketPageDocumentPodItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  maxWidth: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: theme.spacing(0.4, 0),
  overflow: 'hidden',
}));

export const TicketPageDocumentPodItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.links.main,
  textAlign: 'left',
  flexGrow: 1,
  cursor: 'pointer',
  userSelect: 'none',
  '$.hover': {
    color: theme.palette.links.hover,
  },
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const TicketPageDocumentPodItemIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  userSelect: 'none',
  width: 18,
  height: 18,
  minWidth: 18,
}));

export const TicketPageDocumentPodShowMore = styled(Button)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  marginTop: theme.spacing(1),
}));

export const TicketPageSLAContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

export const TicketPageSLARow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

export const TicketPageSLARowPart = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '50%',
}));

export const TicketPageSLARowTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
  color: 'theme.palette.text.primary',
}));

export const TicketPageSLARowText = styled(Typography)(({ theme, type }) => ({
  fontWeight: 400,
  fontSize: 10,
  color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  ...(type && {
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: 10,
      height: 10,
      borderRadius: '50%',
      marginRight: theme.spacing(0.3),
      backgroundColor: theme.palette[type].main,
    },
  }),
}));

export const TicketPageSLAHeader = styled(Typography)(({ theme, type }) => ({
  fontWeight: 500,
  fontSize: 11,
  // color: theme.palette.text.secondary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  ...(type && {
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: 10,
      height: 10,
      borderRadius: '50%',
      marginRight: theme.spacing(0.3),
      backgroundColor: theme.palette[type].main,
    },
  }),
}));

export const TicketPageMessageLockContainer = styled(Box)(({ theme, type }) => ({
  ...(type && {
    color: theme.palette[type].main,
  }),
}));

export const TicketPageModelContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TicketPageModelContainerInner = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '60%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1.25),
}));

export const TicketPageModelContainerButton = styled(Button)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  padding: theme.spacing(1, 2),
}));

export const TicketPageModelContainerButtonGroup = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
}));

export const TicketPageModelContainerTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
}));

export const TimeLineHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.generic.foreground}`,
}));

export const TimeLineHeaderTitle = styled(Typography)(({ theme }) => ({
  textDecoration: 'underline',
  fontWeight: 600,
}));

export const HeaderButtonGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  position: 'flex-end',
}));

export const TicketTimeLineItemGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
}));

export const LeftGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const MidGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '450px',
}));

export const RightGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '150px',
  // flexWrap: 'wrap',
}));

export const TimeLineNames = styled(Typography)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.links.main,
  cursor: 'pointer',
}));

export const TimeLineUpdateTypeBox = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const TicketCreationIcon = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  padding: theme.spacing(1),
  height: 60,
  width: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

export const ResourceAddedBox = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  padding: theme.spacing(1),
  height: 60,
  width: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

export const PublicTicketReplyBox = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.links.main,
  color: theme.palette.success.contrastText,
  padding: theme.spacing(1),
  height: 60,
  width: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

export const AttatchmentAddedBox = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.links.main,
  color: theme.palette.success.contrastText,
  padding: theme.spacing(1),
  height: 60,
  width: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

export const InternalReplyBox = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: '#623D80',
  color: theme.palette.success.contrastText,
  padding: theme.spacing(1),
  height: 60,
  width: 60,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

export const TimeLineMessageAddedBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.25),
}));

export const TimeLineDivider = styled(Box)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.generic.foreground}`,
  height: '106px',
  marginLeft: theme.spacing(5),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const StatusBox = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.main,
  height: '30px',
  borderRadius: theme.spacing(1.25),
  width: 'fit-content',
}));

// const statusId = {
//   '115dc3ec-c6ca-4377-8d6b-d24045c345c8': 'new',
//   'e4829c81-314e-4634-81bc-903976e0ea99': 'hold',
//   'c7dc5b89-e3cd-41ec-baf1-d7d43d079725': 'resolved',
//   '6b888b99-cd8d-4cf5-a255-1cca328effa2': 'open',
//   '9736681b-a657-45d2-9415-f4befe48aece': 'unkown',
//   '63ffbdc8-b443-42c6-8bc2-6e58a4011f93': 'pending',
// };

const getStatusColor = (type, theme) => {
  switch (type) {
    case '115dc3ec-c6ca-4377-8d6b-d24045c345c8':
      return '#F9B953';
    case 'e4829c81-314e-4634-81bc-903976e0ea99':
      return '#623D80';
    case '9736681b-a657-45d2-9415-f4befe48aece':
      return '#000000';
    case '6b888b99-cd8d-4cf5-a255-1cca328effa2':
      return '#4FCF83';
    case 'c7dc5b89-e3cd-41ec-baf1-d7d43d079725':
      return '#E2434D';
    case '63ffbdc8-b443-42c6-8bc2-6e58a4011f93':
      return '#3F76B6';
    default:
      return 'pink';
  }
};
export const OpenColor = styled(Box)(({ theme, type }) => ({
  backgroundColor: getStatusColor(type),
  padding: theme.spacing(1),
  borderRadius: '50%',
  height: '16px',
  width: '16px',
}));

export const ClosedColor = styled(Box)(({ theme, type }) => ({
  backgroundColor: theme.palette.error.main,
  padding: theme.spacing(1),
  borderRadius: '50%',
  height: '16px',
  width: '16px',
}));

export const SixStarTag = styled(Box)(({ theme, type }) => ({
  backgroundColor: '#623D80',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1.25),
  height: '30px',
  gap: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: 'white',
  justifyContent: 'center',
  width: 'fit-content',
}));

export const ExternalTag = styled(Box)(({ theme, type }) => ({
  backgroundColor: '#F9B953',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1.25),
  height: '30px',
  gap: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: 'white',
  justifyContent: 'center',
  width: 'fit-content',
}));

export const NormalTag = styled(Box)(({ theme, type }) => ({
  backgroundColor: theme.palette.success.main,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1.25),
  height: '30px',
  gap: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: 'white',
  justifyContent: 'center',
  width: 'fit-content',
}));

export const HightTag = styled(Box)(({ theme, type }) => ({
  backgroundColor: theme.palette.error.main,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1.25),
  height: '30px',
  gap: theme.spacing(1),
  // padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: 'white',
  justifyContent: 'center',
  width: 'fit-content',
}));

export const LowTag = styled(Box)(({ theme, type }) => ({
  backgroundColor: '#CACACA',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1.25),
  height: '30px',
  gap: theme.spacing(1),
  // padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: 'white',
  justifyContent: 'center',
  width: 'fit-content',
}));

export const CommentEllipsis = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  cursor: 'pointer',
  gap: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const HorizontalDivider = styled('br')(({ theme }) => ({
  width: '100%',
  height: '5px',
  backgroundColor: 'black',
  border: 'none'
}));

export const CommentEllipsisFull = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  cursor: 'pointer',
  gap: theme.spacing(1),
  display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'flex-end',
}));


export const AddNoteContainer = styled(Box)(({ theme }) => ({
  // bottom: 0,
  // position: 'absolute',
  marginTop: theme.spacing(4),
  width: '100%',
  // backroundColor: 'red!important'
}));

export const CommentTextEditorBox = styled(Box)(({ theme }) => ({
  zIndex: 100,
  position: 'relative',
  bottom: 0,
  background: 'white',
  padding: 2.5,
  borderRadius: theme.spacing(1.25),
  margin: theme.spacing(2),
  transform: 'translateY(50px)',
  // border: `3px solid #623D80`,
}));

const SAVE_HEIGHT = 64;
export const TicketNeedsSavingBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.main,
  height: SAVE_HEIGHT + 'px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  // gap: theme.spacing(2),
  padding: theme.spacing(2, 2),
  borderTop: '1px solid #e0e0e0',
  //   borderRadius: theme.spacing(1.25),
  width: '100%',
}));

export const ClearChangesButton = styled(Button)(({ theme }) => ({
  fullwidth: true,
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  boxShadow: 'none',
  fontWeight: 'bold',
}));

export const TicketPodModal = styled(Box)(({ theme, width = 800 }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: width,
  backgroundColor: theme.palette.common.white,
  // border: '1px solid #000',
  boxShadow: 24,
  p: theme.spacing(2),
  borderRadius: theme.spacing(1.25),
}));

export const TicketPodModalHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.generic.foreground}`,
  backgroundColor: theme.palette.primary.main,
  borderRadius: `${theme.spacing(1.25)} ${theme.spacing(1.25)} 0 0`,
  color: theme.palette.common.white,
}));

export const AddContactModalContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  alignContent: 'center',
  justifyContent: 'center',
}));

export const TicketPodAddButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  width: '95%',
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(2),
}));

export const ModalCancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  boxShadow: 'none',
  fontWeight: 'bold',
  marginRight: 'auto',
}));

export const ModalAddButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  boxShadow: 'none',
  fontWeight: 'bold',
  marginLeft: 'auto',
}));

export const PageContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  overflow: 'auto',
}));

export const NoteTypeBox = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.common.grey,
  borderRadius: theme.spacing(1.25),
  placeContent: 'center',
}));

export const NoteType = styled(Box)(({ theme, type }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  //  height: '30px',
  borderRadius: theme.spacing(1.25),
  width: '300px',
  border: `3px solid #623D80`,
  cursor: 'pointer',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));

export const TicketInfoHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginBottom: theme.spacing(1),
}));

export const TicketInfoHeaderRows = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '50%',
}));

export const TicketInfoRowText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 12,
  color: theme.palette.text.primary,
}));

export const TicketInfoHeaderText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 14,
  color: theme.palette.text.primary,
}));

export const TicketSubjectEdit = styled(TextField)(({ theme }) => ({
  padding: theme.spacing(1.8, 1),
  marginBottom: theme.spacing(0),
  marginLeft: theme.spacing(0),
  width: "80%",
  height: "75px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    fontSize: theme.typography.h6.fontSize,
    fontWeight: `${theme.typography.fontWeightBold} !important`,
  },
}));

export const SidePanelBoldTitle = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(0, 0, 1, 0),
  margin: 0,
  display: "flex",
  alignItems: "center",
}));

export const SidePanelParagraph = styled(SidePanelBoldTitle)(
  ({ sx, theme }) => ({
    fontWeight: 400,
    lineHeight: 1.3,
    ...sx,
    "& svg": {
      paddingRight: theme.spacing(1),
    },
  })
);

export const SidePanelItemCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.borderRadius,
  backgroundColor: theme.palette.grey[200],
  // border: `1px solid ${theme.palette.grey[400]}`,
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
  boxShadow: theme.shadows[2],
  wordBreak: "break-word",
}));

export const TicketChipList = styled("ul")(({ theme, sx }) => ({
  display: "flex",
  flexWrap: "wrap",
  listStyle: "none",
  padding: 0,
  margin: 0,
  paddingBottom: theme.spacing(0),
  ...sx,
}));
export const TicketChipListItem = styled("li")(({ theme, sx }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(0),
  backgroundColor: "none!important",
  ...sx,
}));
export const CommentAuthorWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.secondary.main,
}));
export const CommentAuthor = styled(CommentAuthorWrapper)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  display: "block",
  lineHeight: 1.2,
}));
export const ToggleCommentIcon = styled(Icon)(({ theme }) => ({
  // paddingTop:'10px',
  cursor: "pointer",
}));
export const CommentTextWrapper = styled(Box)(({ theme }) => ({
  minHeight: "150px",
  position: "relative",
  wordBreak: "break-word",
  width: "100%",
  "p:first-of-type": {
    marginTop: 0,
  },
}));
export const CommentTextWrapperHide = styled(CommentTextWrapper)(
  ({ theme }) => ({
    maxHeight: "70px",
    minHeight: "70px",
    overflow: "hidden",
    position: "relative",
    "&:after": {
      content: '" "',
      position: "absolute",
      zIndex: 1,
      bottom: 0,
      left: 0,
      width: "100%",
      height: "4rem",
      backgroundImage: `linear-gradient(to bottom,
                    rgba(255,255,255, 0),
                    rgba(255,255,255, 1) 90%)`,
    },
  })
);
export const CommentMoreButton = styled(Button)(({ theme }) => ({
  fontSize: `calc(${theme.typography.body2.fontSize} * 0.80)`,
  padding: theme.spacing(0.5, 1, 0.5, 1),
  lineHeight: "0.8",
  alignItems: "center",
  textTransform: "none",
  fontWeight: theme.typography.fontWeightBold,
}));
export const CommentMoreIconButton = styled(IconButton)(({ theme }) => ({
  // fontSize: `calc(${theme.typography.body2.fontSize} * 1)`,
  marginLeft: theme.spacing(1),
  padding: theme.spacing(0.5, 1, 0.5, 1),
  lineHeight: "0.8",
  alignItems: "center",
  textTransform: "none",
  width: "26px",
  fontWeight: theme.typography.fontWeightBold,
}));

export const CommentCard = styled(Card)(({ theme }) => ({
  // border: `1px solid ${theme.palette.grey[200]}`,
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(3),
  borderRadius: `0 ${theme.borderRadius}  ${theme.borderRadius} 0  `,
  padding: theme.spacing(1),
  // marginRight: theme.spacing(2),
}));

export const CommentCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  paddingBottom: 0,
}));

export const CommentSubject = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  display: "block",
  width: "100%",
  fontWeight: theme.typography.fontWeightMedium,
}));

export const TicketTabWrapper = styled(Box, {
  // indicate that this prop name should be used to conditionally
  // style the component only and should not be spread to the DOM element.
  shouldForwardProp: (propName) => propName !== "selectedTab",
})(({ theme, selectedTab }) => ({
  // justifyContent:'center',
  //   display: 'flex',
  // flex:1,

  //     paddingTop: theme.spacing(1),
  backgroundColor:
    selectedTab === 0 ? theme.palette.background.dark : theme.palette.grey[200],
}));
export const TicketTab = styled(Tab)(({ theme, tab, disabled = false }) => ({
  padding: theme.spacing(0),
  fontWeight: 600,
  // borderRadius: `0 0 150px 150px `,
  backgroundColor: theme.palette.grey[200],
  "&.Mui-selected": {
    overflow: "hidden",
    // borderRadius: ` ${tab === 0 ? ` 0 ${theme.borderRadius} 0 0` : tab < 3 ? `${theme.borderRadius}  ${theme.borderRadius}  0 0` : `${theme.borderRadius} 0 0 0`} `,
    borderRadius: `${theme.borderRadius} ${theme.borderRadius} 0 0`,
    // border:`1px solid ${theme.palette.grey[200]}`,
    borderBottom: 0,
    textDecoration: "underline",

    backgroundColor: theme.palette.background.dark,
    textDecorationThickness: "3px",
    // textDecorationStyle:'wavy',
    textUnderlineOffset: "5px",
  },
}));

export const NoteHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white!important',
  borderRadius: '10px',
  padding: '10px',
  marginBottom: '10px'
}));


export const SidePanelTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.tabs.main,
    color: theme.palette.text.light,
    boxShadow: theme.shadows[3],
    fontSize: 15,
    borderRadius: theme.borderRadius,
  },
}));

