const menu = {
  "menu.manage.dashboard.tabText": "Dashboard",
  "menu.manage.dashboard.menuText": "Dashboard",
  "menu.manage.supportSection.text": "Support",
  "menu.manage.supportSection.menuText": "Support",
  "menu.manage.supportSection.dashboard.tabText": "Dashboard",
  "menu.manage.supportSection.dashboard.menuText": "Dashboard",
  "menu.manage.supportSection.newRequest.tabText": "New Request",
  "menu.manage.supportSection.newRequest.menuText": "New Request",
  "menu.manage.supportSection.myTicketsOverview.tabText": "My Tickets Overview",
  "menu.manage.supportSection.myTicketsOverview.menuText":
    "My Tickets Overview",
  "menu.manage.supportSection.approvals.tabText": "Approvals",
  "menu.manage.supportSection.approvals.menuText": "Approvals",
  "menu.manage.sixstarMeet.tabText": "Six Star Meet",
  "menu.manage.sixstarMeet.menuText": "Six Star Meet",
  "menu.manage.manageSixStarConnect.tabText": "Six Star Connect",
  "menu.manage.manageSixStarConnect.menuText": "Six Star Connect",
  "menu.manage.manageManageSection.text": "Manage",
  "menu.manage.manageManageSection.menuText": "Manage",
  "menu.manage.manageManageSection.dashboard.tabText": "Dashboard",
  "menu.manage.manageManageSection.dashboard.menuText": "Dashboard",
  "menu.manage.manageSection.text": "Manage",
  "menu.manage.manageSection.menuText": "Manage",
  "menu.manage.manageSection.documents.tabText": "Documents",
  "menu.manage.manageSection.documents.menuText": "Documents",
  "menu.manage.manageSection.securityReports.tabText": "Security Reports",
  "menu.manage.manageSection.securityReports.menuText": "Security Reports",
  "menu.manage.manageSection.serviceReports.tabText": "Service Reports",
  "menu.manage.manageSection.serviceReports.menuText": "Service Reports",
  "menu.manage.manageSection.licenceManagement.tabText": "Licence Management",
  "menu.manage.manageSection.licenceManagement.menuText": "Licence Management",
  "menu.manage.manageSection.assetManagement.tabText": "Asset Management",
  "menu.manage.manageSection.assetManagement.menuText": "Asset Management",
  "menu.manage.manageSection.upcomingExpiries.tabText": "Upcoming Expiries",
  "menu.manage.manageSection.upcomingExpiries.menuText": "Upcoming Expiries",
  "menu.manage.manageSection.bookACallWithYourAccountManager.tabText":
    "Book A Call With Your Account Manager",
  "menu.manage.manageSection.bookACallWithYourAccountManager.menuText":
    "Book A Call With Your Account Manager",
  "menu.manage.financeSection.text": "Finance",
  "menu.manage.financeSection.menuText": "Finance",
  "menu.manage.financeSection.dashboard.tabText": "Dashboard",
  "menu.manage.financeSection.dashboard.menuText": "Dashboard",
  "menu.manage.financeSection.ordersQuotesInvoices.tabText":
    "Orders/Quotes/Invoices",
  "menu.manage.financeSection.ordersQuotesInvoices.menuText":
    "Orders/Quotes/Invoices",
  "menu.manage.financeSection.creditApplication.tabText": "Credit Application",
  "menu.manage.financeSection.creditApplication.menuText": "Credit Application",
  "menu.manage.helpSection.text": "Help",
  "menu.manage.helpSection.menuText": "Help",
  "menu.manage.helpSection.contactUs.tabText": "Contact Us",
  "menu.manage.helpSection.contactUs.menuText": "Contact Us",
  "menu.manage.helpSection.giveFeedback.tabText": "Give Feedback",
  "menu.manage.helpSection.giveFeedback.menuText": "Give Feedback",
  "menu.manage.helpSection.guides.tabText": "Guides",
  "menu.manage.helpSection.guides.menuText": "Guides",
  "menu.manage.helpSection.customerForms.tabText": "Customer Forms",
  "menu.manage.helpSection.customerForms.menuText": "Customer Forms",
  "menu.manage.engineerSection.text": "Engineer",
  "menu.manage.engineerSection.menuText": "Engineer",
  "menu.manage.engineerSection.myTasks.tabText": "My Tasks",
  "menu.manage.engineerSection.myTasks.menuText": "My Tasks",
  "menu.manage.engineerSection.ticketBoard.tabText": "Tickets",
  "menu.manage.engineerSection.ticketBoard.menuText": "Tickets",
  "menu.manage.engineerSection.triageTickets.tabText": "Triage Tickets",
  "menu.manage.engineerSection.triageTickets.menuText": "Triage Tickets",
  "menu.manage.engineerSection.priorityTickets.tabText": "Priority Tickets",
  "menu.manage.engineerSection.priorityTickets.menuText": "Priority Tickets",
  "menu.manage.engineerSection.escalations.tabText": "Escalations",
  "menu.manage.engineerSection.escalations.menuText": "Escalations",
  "menu.manage.internalSection.text": "Internal",
  "menu.manage.internalSection.menuText": "Internal",
  "menu.manage.internalSection.orderTracker.tabText": "Order Tracker",
  "menu.manage.internalSection.orderTracker.menuText": "Order Tracker",
  "menu.manage.internalSection.resourceTracker.tabText": "Resource Tracker",
  "menu.manage.internalSection.resourceTracker.menuText": "Resource Tracker",
  "menu.manage.internalSection.knowledgeBase.tabText": "Knowledge Base",
  "menu.manage.internalSection.knowledgeBase.menuText": "Knowledge Base",
  "menu.manage.internalSection.twoFactor.tabText": "Two Factor",
  "menu.manage.internalSection.twoFactor.menuText": "Two Factor",
  "menu.manage.internalSection.internal.tabText": "Internal",
  "menu.manage.internalSection.internal.menuText": "Internal",
  "menu.manage.internalSection.expenses.tabText": "Expenses",
  "menu.manage.internalSection.expenses.menuText": "Expenses",
  "menu.manage.userSection.text": "User",
  "menu.manage.userSection.menuText": "User",
  "menu.manage.userSection.createContact.tabText": "Create Contact",
  "menu.manage.userSection.createContact.menuText": "Create Contact",
  "menu.manage.userSection.contactSearch.tabText": "Contact Search",
  "menu.manage.userSection.contactSearch.menuText": "Contact Search",
  "menu.manage.userSection.zoomPresence.tabText": "Zoom Presence",
  "menu.manage.userSection.zoomPresence.menuText": "Zoom Presence",
  "menu.manage.userSection.zoomCallQueues.tabText": "Zoom Call Queues",
  "menu.manage.userSection.zoomCallQueues.menuText": "Zoom Call Queues",
  "menu.manage.userSection.mergeContacts.tabText": "Merge Contacts",
  "menu.manage.userSection.mergeContacts.menuText": "Merge Contacts",
  "menu.manage.adminSection.text": "Admin",
  "menu.manage.adminSection.menuText": "Admin",
  "menu.manage.adminSection.dashboard.tabText": "Dashboard",
  "menu.manage.adminSection.dashboard.menuText": "Dashboard",
  "menu.manage.adminSection.impersonate.tabText": "Impersonate",
  "menu.manage.adminSection.impersonate.menuText": "Impersonate",
  "menu.manage.supportSection.newRequestDashboard.tabText": "New Request",
  "menu.manage.supportSection.newRequestDashboard.menuText": "New Request",
  "menu.manage.supportSection.newRequestForm.tabText.supportRequest":
    "Support Request",
  "menu.manage.supportSection.newRequestForm.tabText.serviceRequest":
    "Service Request",
  "menu.manage.supportSection.newRequestForm.tabText.procurementRequest":
    "Procurement Request",
  "menu.manage.supportSection.newRequestForm.tabText.internalRequest":
    "Internal Request",
  "menu.manage.supportSection.newRequestForm.tabText.passwordReset":
    "Password Reset",
  "menu.manage.supportSection.newRequestForm.tabText.newStarter":
    "New Starter / Role Change",
  "menu.manage.supportSection.newRequestForm.tabText.leaverRequest":
    "Leaver Request",
  "menu.manage.supportSection.newRequestForm.tabText.engineerVisit":
    "Engineer Visit",
  "menu.manage.supportSection.newRequestForm.tabText.developmentRequest":
    "Development Request",
  "menu.manage.engineerSection.ticketViewPage.tabText": "Ticket# {id}",
  "menu.manage.engineerSection.ticketViewPage.menuText": "Ticket {id}",
  "menu.manage.engineerSection.ticketsBoard.tabText": "Tickets",
  "menu.manage.engineerSection.ticketsBoard.menuText": "Tickets",
  "menu.manage.supportSection.newRequestForm.tabText": "{id}",
  "menu.manage.supportSection.newRequestForm.menuText": "{id}",
  "menu.manage.engineerSection.drive.tabText": "Six Star Drive",
  "menu.manage.engineerSection.drive.menuText": "Six Star Drive",
  "menu.manage.engineerSection.reports.tabText": "Reports",
  "menu.manage.engineerSection.reports.menuText": "Reports",
  "menu.manage.engineerSection.companyProfile.tabText": "My Organization",
  "menu.manage.engineerSection.companyProfile.menuText": "My Organization",
  "menu.manage.engineerSection.chatGPT.tabText": "Chat GPT",
  "menu.manage.engineerSection.chatGPT.menuText": "Chat GPT",
  "menu.manage.financeSection.Invoices.tabText": "Invoices",
  "menu.manage.financeSection.Invoices.menuText": "Invoices",
  "menu.manage.financeSection.quotesProposals.tabText": "Quotes",
  "menu.manage.financeSection.quotesProposals.menuText": "Quotes",
  "menu.manage.financeSection.creditTerms.tabText": "Credit Terms",
  "menu.manage.financeSection.creditTerms.menuText": "Credit Terms",
  "menu.manage.internalSection.impersonate.tabText": "Impersonate",
  "menu.manage.internalSection.impersonate.menuText": "Impersonate",
  "menu.manage.managedServicesSection.text": "Managed Services",
  "menu.manage.managedServicesSection.menuText": "Managed Services",
  "menu.manage.managedServicesSection.dashboard.tabText": "Dashboard",
  "menu.manage.managedServicesSection.dashboard.menuText": "Dashboard",
  "menu.manage.managedServicesSection.serviceOverview.tabText":
    "Service Overview",
  "menu.manage.managedServicesSection.serviceOverview.menuText":
    "Service Overview",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.tabText":
    "Endpoint Security",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.menuText":
    "Endpoint Security",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.tabText":
    "Backup",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.menuText":
    "Backup",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.tabText":
    "Secure Forms",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.menuText":
    "Secure Forms",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.tabText":
    "Scheduled Site Visits",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.menuText":
    "Scheduled Site Visits",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.tabText":
    "E-Waste Disposals",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.menuText":
    "E-Waste Disposals",
  "menu.manage.sixStarSecureSection.text": "Six Star Secure",
  "menu.manage.sixStarSecureSection.menuText": "Six Star Secure",
  "menu.manage.sixStarSecureSection.dashboard.tabText": "Dashboard",
  "menu.manage.sixStarSecureSection.dashboard.menuText": "Dashboard",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.tabText":
    "Endpoint Protection (Antivirus & Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.menuText":
    "Endpoint Protection (Antivirus & Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.tabText":
    "Endpoint Detection & Response",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.menuText":
    "Endpoint Detection & Response",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.tabText":
    "Firewall Management",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.menuText":
    "Firewall Management",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.tabText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.menuText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.tabText":
    "Device Encryption",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.menuText":
    "Device Encryption",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.tabText":
    "Office 365/Azure Management",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.menuText":
    "Office 365/Azure Management",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.tabText":
    "Backup & Disaster Recovery",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.menuText":
    "Backup & Disaster Recovery",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.tabText":
    "Security Reports",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.menuText":
    "Security Reports",
  "menu.manage.softwareLicenceManagementSection.text":
    "Software Licence Management",
  "menu.manage.softwareLicenceManagementSection.menuText":
    "Software Licence Management",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.tabText":
    "Authorised Software Catalogue",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.menuText":
    "Authorised Software Catalogue",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.tabText":
    "Licence Management",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.menuText":
    "Licence Management",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.tabText":
    "Software Management",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.menuText":
    "Software Management",
  "menu.manage.assetManagementSection.text": "Asset Management",
  "menu.manage.assetManagementSection.menuText": "Asset Management",
  "menu.manage.assetManagementSection.assetManagementDashboard.tabText":
    "Dashboard",
  "menu.manage.assetManagementSection.assetManagementDashboard.menuText":
    "Dashboard",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.tabText":
    "Authorised Hardware Catalogue",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.menuText":
    "Authorised Hardware Catalogue",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.tabText":
    "Device Management",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.menuText":
    "Device Management",
  "menu.manage.sixStarAdditionsSection.text": "Six Star Additions",
  "menu.manage.sixStarAdditionsSection.menuText": "Six Star Additions",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.tabText":
    "Guest Device Support",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.menuText":
    "Guest Device Support",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.tabText":
    "Six Star: Voice",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.menuText":
    "Six Star: Voice",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.tabText":
    "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.menuText":
    "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.tabText":
    "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.menuText":
    "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.tabText":
    "Six Star: Meet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.menuText":
    "Six Star: Meet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.tabText":
    "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.menuText":
    "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.tabText":
    "Six Star: Cast",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.menuText":
    "Six Star: Cast",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.tabText":
    "Six Star: Pay",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.menuText":
    "Six Star: Pay",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.tabText":
    "Six Star: Display",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.menuText":
    "Six Star: Display",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.tabText":
    "Six Star: Park",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.menuText":
    "Six Star: Park",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.tabText":
    "Six Star: Black",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.menuText":
    "Six Star: Black",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.tabText":
    "Six Star: Print",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.menuText":
    "Six Star: Print",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.tabText":
    "Six Star: Internet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.menuText":
    "Six Star: Internet",
  "menu.manage.companyContact.tabText": "Profile",
  "menu.manage.companyContact.menuText": "Profile",
  "menu.manage.personContact.menuText": "Profile",
  "menu.manage.userSection.editContact.tabText": "Edit Contact",
  "menu.manage.userSection.editContact.menuText": "Edit Contact",
  "menu.manage.managedServicesSection.manageManagedServicesPayslips.tabText": "Payslips",
  "menu.manage.managedServicesSection.manageManagedServicesPayslips.menuText": "Payslips",
  "menu.manage.personContact.tabText": "{id}",
  "menu.manage.personContact.menuText": "Profile",

};
export default menu;
