import * as React from "react";
import { palette, styled } from "@mui/system";
import { Tabs, Card, Box, CardContent, Button } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";

export const MenuTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  "& .MuiButtonBase-root ": {
    color: theme.palette.text.secondary,
  },
  "& .MuiButtonBase-root.Mui-selected ": {
    color: theme.palette.text.light,
    backgroundColor: theme.palette.tabs.main,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.tabs.indicator,
  },
}));

export const MenuAppBar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.tabs.main,
  boxShadow: "none",
}));

export const MenuSubTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.text.light,
  "& .MuiTab-root ": {
    color: theme.palette.text.light,
  },
  "& .MuiButtonBase-root.Mui-selected ": {},
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.tabs.indicator,
  },
}));
