const styles = (theme) => ({
    modelContainer: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
        height: '100vh',
    },
    modelContent: {
        backgroundColor: 'rgba(255, 255, 255, 1.0)',
        padding: 40,
        borderRadius: 10,
        width: '70%',
        maxHeight: '90vh',
        height: '90vh',
        overflowY: 'scroll',
    },
    center: {
        textAlign: 'center',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    closeButton: {
        width: '100%',
    },
    fabContainer: {
        position: 'fixed',
        bottom: '16px',
        right: '16px',
        zIndex: 1000
    },
    fabContainerItem1: {
        position: 'fixed',
        bottom: '86px',
        right: '16px',
        zIndex: 1000,
        width: '45px',
        height: '45px'
    },
    fabContainerItem2: {
        position: 'fixed',
        bottom: '86px',
        right: '16px',
        zIndex: 1000,
        width: '45px',
        height: '45px'
    },
    h1: {
        backgroundColor: 'green',
        textAlign: 'center',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
});

export default styles;
