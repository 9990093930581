import React, { useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, LinearProgress } from '@mui/material';
import useTableState from './hooks/useTableState';
import CustomToolbar from './components/CustomToolbar';
import CustomFilterPanel from './components/CustomFilterPanel';
import CustomPagination from './components/CustomPagination';


const TableTest = ({ columns, siteData, sx }) => {
    const [tempFilters, setTempFilters] = React.useState({})
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

    const {
        apiRef,
        rows,
        tableLoading,
        setPage,
        pageSize,
        filtersHasChanged,
        rowCount,
        error,
        pageSizeOptions,
        density,
        setDensity,
        handlePaginationModelChange,
        tableName,
        dataTableIdentifier,
        sortOptionsHaveChanged
    } = siteData;

    const {
        state,
        avaliableViews,
        currentView,
        setCurrentView,
        updateView,
        saveNewView,
        initialState,
        deleteView
    } = useTableState({ tableName, dataTableIdentifier, apiRef })

    const prevViewId = React.useRef(null)

    if (error) console.log('error', error);

    useEffect(() => {
        if (currentView) {
            // apiRef.current.restoreState(currentView.state)
            filtersHasChanged(currentView?.state?.filter?.filterModel)
            setTempFilters({})
            if (prevViewId.current !== currentView.id) {

                updateView('currentView') //Just updates time.
            }
            prevViewId.current = currentView.id
        }
    }, [currentView])


    useEffect(() => {
        apiRef.current.autosizeColumns({ subject: true })
    }, [rows])

    const handleQuickSearch = () => {
        filtersHasChanged(tempFilters)
    }


    return (
        <Box sx={{ height: 'calc(100vh - 96px)', width: '100%' }}>
            <DataGridPro
                sx={sx}
                apiRef={apiRef}
                initialState={{
                    ...initialState,
                }}
                state={{
                    keyboard: {
                        cell: null,
                        columnHeader: null,
                        isMultipleKeyPressed: false,
                    }
                }}
                loading={tableLoading}
                rows={rows || []}
                columns={columns}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                checkboxSelection
                disableSelectionOnClick
                rowSelectionModel={rowSelectionModel.map((r) => r.id)}
                pagination
                paginationMode='server'
                filterMode='server'
                sortingMode='server'
                keepNonExistentRowsSelected
                rowCount={rowCount || 0}
                onPageChange={(params) =>
                    setPage(params.page)
                }
                density={density}
                slots={{
                    toolbar: CustomToolbar,
                    filterPanel: CustomFilterPanel,
                    loadingOverlay: LinearProgress,
                    pagination: CustomPagination,
                }}
                slotProps={{
                    toolbar: {
                        selectedCheckboxRows: rowSelectionModel,
                        setSelectedCheckboxRows: setRowSelectionModel,
                        columns,
                        apiRef,
                        avaliableViews,
                        currentView,
                        setCurrentView,
                        saveNewView,
                        deleteView,
                        filtersHasChanged,
                        handleQuickSearch
                    },
                    filterPanel: {
                        apiRef
                    },
                }}
                onStateChange={(params) => {
                    if (params.density.value !== density) setDensity(params.density.value)
                }}
                // columnVisibilityModel={}
                onFilterModelChange={(params) => {
                    setTempFilters(params)
                    if (params?.items?.length === 0 && params?.quickFilterValues?.length === 0) {
                        filtersHasChanged(params)
                        setTempFilters({})
                    }


                }}
                onColumnVisibilityModelChange={(params) => {
                    updateView('columns')
                }}
                onPreferencePanelClose={(params) => {
                    if (params?.openedPanelValue === 'filters') {
                        // if (tempFilters?.items?.length > 0) {
                        if (currentView?.state?.filter?.filterModel?.items?.length === tempFilters?.items?.length) {
                            let diff = false
                            currentView?.state?.filter?.filterModel?.items?.forEach((item, index) => {
                                if (item.field !== tempFilters?.items?.[index]?.field || item.value !== tempFilters?.items?.[index]?.value) {
                                    diff = true
                                }
                            })
                            if (diff) {
                                setCurrentView(avaliableViews.find(view => view.id === '79acd0f7-cdbb-4587-94a4-17467dc6bb9d'));
                            } else {
                                updateView('filters')
                            }
                        } else {
                            setCurrentView(avaliableViews.find(view => view.id === '79acd0f7-cdbb-4587-94a4-17467dc6bb9d'));
                        }
                        filtersHasChanged(tempFilters)
                        setTempFilters({})
                    }
                }}
                onPaginationModelChange={
                    (params) => {
                        handlePaginationModelChange(params)
                        updateView('pagination')
                    }
                }
                onSortModelChange={(params) => {
                    sortOptionsHaveChanged(params)
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    let selectedRows = [];
                    newRowSelectionModel.forEach((row) => {
                        rows
                            ?.filter((r) => r.id === row)
                            ?.forEach((r) => {
                                selectedRows.push(r);
                            });
                    });

                    setRowSelectionModel((prevState) => {
                        //remove any from prev state that are not in newRowSelectionModel
                        let newSelection = prevState?.filter((r) => newRowSelectionModel.includes(r.id));
                        return [...newSelection, ...selectedRows];
                    });
                }}
            />
        </Box>
    );
};

export default TableTest
