import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import layoutStyles from 'containers/Layout/styles';
import PageBase from 'components/PageBase';
import styles from '../../containers/Pages/DataTableTest/styles';
import { useSubscription, gql, useQuery } from '@apollo/client';
// import DataGrid from "components/MuiOverride/DataGrid";
import { DataGridPro as DataGrid, gridClasses } from '@mui/x-data-grid-pro';
import DataTableFilters from 'components/DataTable/components/DataTableFilters';
import { filterItems, sortComposer, processColumns } from 'utils/dataTable';
import { get, find, over, set, findIndex } from 'lodash';
import MasterDetail from 'components/DataTable/containers/MasterDetail';
import { styled } from '@mui/system';
// import { useElementSize } from '@mantine/hooks';
import Toolbar from 'components/DataTable/components/Toolbar';
import Translation from 'utils/translation';
import { useGridApiRef } from '@mui/x-data-grid';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentDataTableView } from 'slices/user';
import { useMutation } from '@apollo/client';
import { useDebouncedValue } from '@mantine/hooks';

import { useDatatableviewUpdate } from 'containers/App/Hooks/useDatatableviewUpdate';
import { graphQLMutations as viewMutations } from 'graphql/datatableview';
import { graphQLQueries as viewQueries } from 'graphql/datatableview';
import { _ } from 'core-js';
import CreateContactModal from 'Modals/CreateContact';
import createPersistedState from 'use-persisted-state';

function DataTablePro(props) {
  const {
    classes,
    queryDataIdentifier,
    incomingColumns,
    filters,
    toolbar,
    table,
    initialView,
    handleRowClick,
    masterDetail,
    overrideQuery,
    searchQuery,
    defaultFilter = {
      items: [
        {
          items: [],
          logicOperator: 'and',
          quickFilterValues: [],
          quickFilterLogicOperator: 'and',
        },
      ],
    },
    defaultSort = [],
    searchParams,
    rowsPerPage = [5, 10, 15],
    checkboxSelection = false,
    permFilters = null,
    footer = true,
  } = props;

  const currentPaginationState = createPersistedState(`${table}_${window.location.pathname}_pagination`);
  const [paginationModel, setPaginationModel] = currentPaginationState({
    page: 0,
    pageSize: 10,
  });
  const currentSortState = createPersistedState(`${table}_${window.location.pathname}_sort`);
  const [sortModel, setSortModel] = currentSortState(defaultSort);
  const currentFilterModel = createPersistedState(`${table}_${window.location.pathname}_filter`);
  const [filterModel, setFilterModel] = currentFilterModel(defaultFilter);
  const currentViewSaved = createPersistedState(`${table}_${window.location.pathname}_view`);
  const [currentView, setCurrentView] = currentViewSaved(initialView ?? 0);
  const currentSearchSaved = createPersistedState(`${table}_${window.location.pathname}_search`);
  const [currentSearch, setCurrentSearch] = currentSearchSaved('');

  // const { ref, width, height } = useElementSize();
  const { fields, columns } = processColumns(incomingColumns);
  const [isLoading, setIsLoading] = useState(true);
  const [isViewsLoading, setIsViewsLoading] = useState(true);
  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  // const [sortModel, setSortModel] = React.useState(defaultSort);
  // const [filterModel, setFilterModel] = React.useState(defaultFilter);
  const [filterQuery, setFilterQuery] = React.useState('');
  const [showToolbar, setShowToolbar] = React.useState(toolbar ?? false);
  const [showFilters, setShowFilters] = React.useState(false);
  const [showAddContact, setShowAddContact] = React.useState(false);
  const [viewArray, setViewArray] = React.useState([]);
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const [reduxLoader, setReduxLoader] = useState();
  const user = useSelector((state) => state);
  const currentViewsRedux = useSelector((state) => state?.user.datatable_views);
  const [selectedCheckboxRows, setSelectedCheckboxRows] = useState([]);

  const [search, setSearch] = useState('');
  const [searchBigInt, setSearchBigInt] = useState(0);
  const [stateChanged, setStateChanged] = useState(false);
  const [debouncedState] = useDebouncedValue(stateChanged, 500);
  const { INSERT_DATATABLE_VIEW, UPDATE_DATATABLE_VIEW, DELETE_DATATABLE_VIEW } = viewMutations();
  const [insertView, { error: datatableviewInsertError, data: datatableviewInsertData }] = useMutation(INSERT_DATATABLE_VIEW);
  const [updateView, { error: datatableviewUpdateError, data: datatableviewUpdateData }] = useMutation(UPDATE_DATATABLE_VIEW);
  const [deleteView, { error: datatableviewDeleteError, data: datatableviewDeleteData }] = useMutation(DELETE_DATATABLE_VIEW);

  const MainQuery = useQuery(
    gql`
    query MainQuery
       {
      ${queryDataIdentifier}(limit: ${paginationModel.pageSize}, 
        offset: ${paginationModel.page * paginationModel.pageSize}, 
        order_by: ${sortComposer(sortModel)}, 
        where: {
          ${permFilters ? permFilters : ''}
          ${filterQuery}
        }
        ) {
        ${fields}
      }
    }
    `,
  );

  const QueryCount = useQuery(
    gql`
      query CountQuery {
        ${queryDataIdentifier}_aggregate(
          where: {
            ${permFilters ? permFilters : ''}
            ${filterQuery}
          }) {
              aggregate {
                count
              }
            }
      }
    `,
    {
      variables: {},
    },
  );

  const SearchQuery = useQuery(
    gql`
      query SearchQuery($_search: String = "", $_searchBigInt: bigint = 0) {
        ${queryDataIdentifier}(limit: ${paginationModel.pageSize}, 
          offset: ${paginationModel.page * paginationModel.pageSize}, 
          order_by: ${sortComposer(sortModel)}, 
          where: {
            ${permFilters ? permFilters : ''}
            ${searchQuery ? searchQuery : ''}
            ${filterQuery}
          }
          ) {
          ${fields}
        }
      }
    `,
    {
      variables: { _search: search || '', _searchBigInt: searchBigInt || 0 },
    },
  );

  let Query = searchParams ? SearchQuery : MainQuery;
  useEffect(() => {}, [filterQuery]);

  useEffect(() => {
    if (Query?.data?.[queryDataIdentifier]) {
      setRows(Query?.data?.[queryDataIdentifier] ?? []);
    }
    if (QueryCount?.data?.[`${queryDataIdentifier}_aggregate`]) {
      setRowCount(QueryCount?.data?.[`${queryDataIdentifier}_aggregate`]?.aggregate?.count ?? 0);
    }
  }, [Query.data, QueryCount.data]);

  useEffect(() => {
    if (Query.error) {
      console.log({ Query: Query.error });
      console.log({ filterModel: filterModel });
      console.log({ filterQuery: filterQuery });
    }
    if (QueryCount.error) {
      console.log({ QueryCount: QueryCount.error });
    }
  }, [Query.error, QueryCount.error]);

  useEffect(() => {
    setIsLoading(Query.loading);
  }, [Query.loading]);

  useEffect(async () => {
    // console.log({ paginationModel: paginationModel, sortModel: sortModel, filterModel: filterModel })
    setFilterQuery(await filterItems(filterModel));
  }, [paginationModel, sortModel, filterModel]);

  // useEffect(() => {
  //   console.log({filterModel: filterModel})
  // }, [filterModel])

  // useEffect(() => {
  //   console.log({filterQuery: filterQuery})
  // }, [filterQuery])

  useEffect(() => {
    // console.log('Setting Current View: ', currentView)
    if (currentView !== undefined) {
      if (viewArray.length > 0) {
        if (apiRef && apiRef.current && viewArray[currentView] && viewArray[currentView].state) {
          apiRef.current.restoreState(viewArray[currentView].state);
        }
        if (viewArray[currentView]?.filter_model) {
          setFilterModel(viewArray[currentView].filter_model);
        }
        if (viewArray[currentView]?.sort_model) {
          setSortModel(viewArray[currentView].sort_model);
        }
        dispatch(
          setCurrentDataTableView({
            table: table,
            route: window.location.pathname,
            viewIndex: currentView,
          }),
        );
      }
    }
  }, [currentView]);

  const { GET_DATATABLE_VIEWS } = viewQueries();

  // .

  // GraphQL subscription
  const { data: subscriptionData, error: subscriptionError } = useSubscription(GET_DATATABLE_VIEWS, {
    variables: {
      datatable: table,
      route: window.location.pathname,
    },
  });

  useEffect(() => {
    if (subscriptionData) {
      if (viewArray.length !== 0 && subscriptionData.datatable_view.length > viewArray.length) {
        loadViews(subscriptionData.datatable_view);
        console.log({ subscriptionData: subscriptionData.datatable_view.length - 1 });
        setCurrentView(subscriptionData.datatable_view.length - 1);
      } else {
        loadViews(subscriptionData.datatable_view);
      }
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionError) {
      console.log('Subscription error:', subscriptionError);
    }
  }, [subscriptionError]);

  useEffect(() => {
    handleSearch(currentSearch);
  }, [currentSearch]);

  const handleRenameView = (index, name) => {
    if (viewArray[index]?.global) {
    } else {
      setViewArray((prevState) => {
        let temp = [...prevState];
        temp[index].name = name;
        return temp;
      });
      updateView({
        variables: {
          _eq: viewArray[index].id,
          _set: {
            name: name,
          },
        },
      });
    }
  };

  const handleUpdateView = (index) => {
    updateView({
      variables: {
        _eq: viewArray[index].id,
        _set: {
          sort_model: sortModel,
          filter_model: filterModel,
          state: apiRef?.current?.exportState() ?? {},
        },
      },
    });
  };

  const handleAddView = (name) => {
    insertView({
      variables: {
        person_id: user?.user?.user?.id,
        sort_model: sortModel,
        filter_model: filterModel,
        state: apiRef?.current?.exportState() ?? {},
        name: name,
        route: window.location.pathname,
        datatable: table,
      },
    });
  };

  const handleDeleteView = (index) => {
    if (viewArray[index].default_flag) {
      alert('You cannot delete the default view');
    } else if (viewArray.length === 1) {
      alert('You cannot delete the last view');
    } else if (viewArray[index].global) {
      alert('You cannot delete a global view');
    } else {
      deleteView({
        variables: {
          _eq: viewArray[index].id,
        },
      });
      console.log('deleting view');
      setCurrentView(0);
    }
  };

  const loadViews = (views) => {
    let defaultViewIndex = _.findIndex(views, { default_flag: true });
    // console.log({ defaultViewIndex: defaultViewIndex })
    if (defaultViewIndex === -1) {
      insertView({
        variables: {
          person_id: user?.user?.user?.id,
          sort_model: sortModel,
          filter_model: filterModel,
          state: apiRef?.current?.exportState() ?? {},
          name: 'Default',
          route: window.location.pathname,
          datatable: table,
          defaultFlag: true,
        },
      });
    } else {
      let defaultView = views.splice(defaultViewIndex, 1)[0];
      views.unshift(defaultView);
    }
    setViewArray(views);
    let reduxCurrentViewIndex = _.findIndex(currentViewsRedux, { datatable: table, route: window.location.pathname });
    if (reduxCurrentViewIndex !== -1) {
      // console.log(currentViewsRedux[reduxCurrentViewIndex]?.currentView ?? 0)
      setCurrentView(currentViewsRedux[reduxCurrentViewIndex]?.currentView ?? 0);
    }
  };

  const handleSearch = (value) => {
    if (value !== null || value !== undefined || value !== '') {
      setSearch(value);
      //if it is a number, set the bigint
      let temp = parseInt(value);
      if (!isNaN(temp)) {
        setSearchBigInt(temp);
      }
    } else {
      setSearch('');
      setSearchBigInt(0);
    }
  };
  const addFilter = (data) => {
    const { value, queryField } = data;

    let returnValue = {
      items: [...filterModel.items],
      logicOperator: 'custom',
      quickFilterValues: [],
      quickFilterLogicOperator: 'and',
      fromInput: 'mui-73',
    };

    returnValue.items = returnValue.items.filter((item) => item.field !== queryField);

    if (value?.length > 0 && value !== null && value !== undefined) {
      value.map((item) => {
        let returnItemValue = item;
        if (typeof item === 'object') {
          returnItemValue = get(item, queryField);
        }
        returnValue.items.push({
          field: queryField,
          operator: 'contains',
          value: returnItemValue,
          id: Math.floor(Math.random() * (100000000000000 - 1 + 1) + 1),
        });
      });
    }
    setFilterModel(returnValue);
  };

  return (
    <>
      {queryDataIdentifier !== '' && incomingColumns.length > 0 ? (
        <PageBase>
          {showAddContact && <CreateContactModal showAddContact={() => setShowAddContact(!showAddContact)} />}
          {showFilters && (
            <Box
              sx={{
                transition: 'all 0.5s ease',
                backgroundColor: '#fff',
                top: '96px',
                right: `${showFilters ? '0px' : '-320px'}`,
                width: `${showFilters ? '350px' : '0px'}`,
                zIndex: 100,
                position: 'fixed',
                boxShadow: '-10px 0px 10px 0px rgba(0,0,0,0.25)',
                overflowY: 'auto',
                marginTop: '40px', // Adjust the value as needed
                marginRight: '40px',
                borderRadius: '0 10px 0 10px', // Round top right and bottom left corners
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              <DataTableFilters
                valueChanged={(data) => {
                  addFilter(data);
                }}
                setShowFilters={() => setShowFilters(!showFilters)}
                filterOptions={filters}
                filterView={filterModel?.items ?? []}
                setCurrentView={(value) => {
                  setCurrentView(value);
                }}
              />
            </Box>
          )}
          <Box
            sx={{
              position: 'relative',
              minHeight: '100%',
            }}
          >
            {masterDetail ? (
              <></>
            ) : (
              // <DataGrid
              //   checkboxSelection={checkboxSelection}
              //   onRowSelectionModelChange={(newRowSelectionModel) => {
              //     let selectedRows = []
              //     newRowSelectionModel.map((row) => {
              //       let foundRow = rows.filter((item) => item.id === row)
              //       if (foundRow.length > 0) {
              //         if (foundRow[0].id) {
              //           if (foundRow[0]?.status?.name !== "resolved") {
              //             selectedRows.push({
              //               id: foundRow[0].id,
              //               alias: foundRow[0].alias ?? "",
              //               requester: foundRow[0]?.requester?.name ?? "",
              //               status: foundRow[0]?.status?.name.charAt(0).toUpperCase() + foundRow[0]?.status?.name.slice(1) ?? "",
              //               subject: foundRow[0]?.subject ?? "",
              //             })
              //           }
              //         }
              //       }
              //     })
              //     setSelectedCheckboxRows(selectedRows)
              //   }}
              //   disableColumnReorder={true}
              //   style={{ minHeight: '750px' }}
              //   autoHeight={false}
              //   apiRef={apiRef}
              //   // ref={ref}
              //   columns={columns}
              //   rows={rows}
              //   pagination
              //   sortingMode="server"
              //   filterMode="server"
              //   paginationMode="server"
              //   onPaginationModelChange={setPaginationModel}
              //   onSortModelChange={setSortModel}
              //   onFilterModelChange={setFilterModel}
              //   // onColumnOrderChange={() => handleSaveView(viewArray[currentView].label)}
              //   onRowClick={handleRowClick ?? null}
              //   rowCount={rowCount}
              //   pageSizeOptions={[5, 10, 15]}
              //   initialState={{
              //     pagination: { paginationModel: { pageSize: paginationModel.pageSize } },
              //   }}
              //   loading={isLoading}
              //   filterDebounceMs={500}
              //   // getRowHeight={(params) => 50} // to be removed when we have density selector
              //   getDetailPanelContent={({ row }) => <MasterDetail row={row} width={"50%"} />}
              //   // getDetailPanelHeight={() => 'auto'}
              //   // getDetailPanelHeight={({ row }) => 100} // Optional, default is 500px.
              //   getRowClassName={(params) =>
              //     params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              //   }
              //   rowThreshold={0}
              //   slots={{
              //     toolbar: Toolbar,
              //   }}
              //   slotProps={{
              //     toolbar: {
              //       viewArray: viewArray,
              //       handleSaveView: (view) => handleSaveView(view),
              //       currentView: currentView,
              //       setCurrentView: (value) => setCurrentView(value),
              //       setShowFilters: () => setShowFilters(!showFilters),
              //       handleSearch: (value) => handleSearch(value),
              //       selectedCheckboxRows: selectedCheckboxRows,
              //       toolbar: toolbar,
              //     }
              //   }}
              // />
              <DataGrid
                hideFooter={!footer}
                checkboxSelection={checkboxSelection}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  let selectedRows = [];
                  newRowSelectionModel.map((row) => {
                    let foundRow = rows.filter((item) => item.id === row);
                    if (foundRow.length > 0) {
                      if (foundRow[0].id) {
                        if (foundRow[0]?.status?.name !== 'resolved') {
                          selectedRows.push({
                            id: foundRow[0].id,
                            alias: foundRow[0].alias ?? '',
                            requester: foundRow[0]?.requester?.name ?? '',
                            status: foundRow[0]?.status?.name.charAt(0).toUpperCase() + foundRow[0]?.status?.name.slice(1) ?? '',
                            subject: foundRow[0]?.subject ?? '',
                          });
                        }
                      }
                    }
                  });
                  setSelectedCheckboxRows(selectedRows);
                }}
                style={{ minHeight: '350px' }}
                autoHeight={true}
                sx={{ '--DataGrid-overlayHeight': '350px' }}
                apiRef={apiRef}
                // ref={ref}
                columns={columns}
                rows={rows}
                pagination
                sortingMode='server'
                filterMode='server'
                paginationMode='server'
                onPaginationModelChange={(paginationModelChange) => {
                  console.log({ paginationModelChange: paginationModelChange });
                  setPaginationModel(paginationModelChange);
                }}
                onSortModelChange={setSortModel}
                onFilterModelChange={setFilterModel}
                onRowClick={handleRowClick ?? null}
                rowCount={rowCount}
                density='compact'
                pageSizeOptions={rowsPerPage ?? [5, 10, 15]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: paginationModel?.pageSize ?? rowsPerPage[0] ?? 10,
                      page: paginationModel?.page ?? 0,
                    },
                  },
                }}
                loading={isLoading}
                filterDebounceMs={500}
                // getRowHeight={(params) => 50} // to be removed when we have density selector
                // getDetailPanelHeight={() => 'auto'}
                // getDetailPanelHeight={({ row }) => 100} // Optional, default is 500px.
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                rowThreshold={0}
                slots={{
                  toolbar: Toolbar,
                }}
                slotProps={{
                  toolbar: {
                    viewArray: viewArray,
                    handleAddView: (name) => handleAddView(name),
                    handleUpdateView: (view) => handleUpdateView(view),
                    handleRenameView: (index, name) => handleRenameView(index, name),
                    currentView: currentView,
                    setCurrentView: (value) => setCurrentView(value),
                    setShowFilters: () => setShowFilters(!showFilters),
                    currentSearch: currentSearch,
                    handleSearch: (value) => setCurrentSearch(value),
                    selectedCheckboxRows: selectedCheckboxRows,
                    toolbar: toolbar,
                    handleDeleteView: (index) => handleDeleteView(index),
                    setShowAddContact: () => setShowAddContact(!showAddContact),
                    apiRef: apiRef,
                  },
                }}
              />
            )}
          </Box>
        </PageBase>
      ) : (
        <div>
          <div>{Translation('dataTablePro.error')} </div>
          <div>queryDataIdentifier: {queryDataIdentifier ?? 'not found'}</div>
          <div>incomingColumns Length: {incomingColumns.length}</div>
          <div>filters Length: {filters.length}</div>
        </div>
      )}
    </>
  );
}

DataTablePro.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  queryDataIdentifier: PropTypes.string.isRequired,
  incomingColumns: PropTypes.array.isRequired,
  filters: PropTypes.array,
};

DataTablePro.defaultProps = {
  filters: [],
  queryDataIdentifier: null,
  incomingColumns: [],
  masterDetail: false,
};

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true },
)(DataTablePro);
