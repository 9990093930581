import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import PageBase from "components/PageBase";
import styles from "./styles";
import {
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useSelector } from 'react-redux'
import { useMutation, gql } from "@apollo/client"
import useTranslation from "containers/App/Hooks/useTranslation";
import Button from "@mui/material/Button";

function AdminPanel(props) {
  const { translate } = useTranslation();
  const [loadingAdmin, setLoadingAdmin] = useState(true);
  const [errorAdmin, setErrorAdmin] = useState(false);
  const [redirectedAdmin, setRedirectedAdmin] = useState(false);
  const [adminLink, setAdminLink] = useState(null)

  const { token } = useSelector(state => state.user.tokens)

  const loginMutation = gql`
    mutation MyMutation {
      admin_panel_login {
        error
        login_link
      }
    }`

  const [login, { data, loading, error }] = useMutation(loginMutation, {
    variables: {
      token: token,
    },
  });

  useEffect(() => {
    if (token) {
      login()
    }
  }, [token])

  useEffect(() => {
    if (data) {
      if (data.admin_panel_login.error) {
        setErrorAdmin(true)
        setLoadingAdmin(false)
        setRedirectedAdmin(false)
      } else {
        if (data?.admin_panel_login?.login_link && data?.admin_panel_login?.login_link !== "") {
          setRedirectedAdmin(true)
          setLoadingAdmin(false)
          setErrorAdmin(false)
          // setAdminLink(data?.admin_panel_login?.login_link)


          // take data?.admin_panel_login?.login_link and look for https://cli.sixstar.click/ and change it to http://http://localhost:4001/
          let link = data?.admin_panel_login?.login_link
          // link = link.replace("https://cli.sixstar.click/", "http://localhost:4001/")
          setAdminLink(link)
          window.open(link, "_blank")
        } else {
          setErrorAdmin(true)
          setLoadingAdmin(false)
          setRedirectedAdmin(false)
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (error) {
      setErrorAdmin(true)
      setLoadingAdmin(false)
      setRedirectedAdmin(false)
    }
  }, [error])

  return (
    <PageBase title="admin.adminPanel.title">
      <Card>
        <CardContent>
          {loadingAdmin && <Typography>{translate("admin.adminPanel.loading")}</Typography>}
          {errorAdmin && <Typography>{translate("admin.adminPanel.error")}</Typography>}
          {redirectedAdmin && <Typography>{translate("admin.adminPanel.redirected")}</Typography>}
          {adminLink && (
            <Button 
              sx={{ marginTop: 4 }}
              href={adminLink}
              target="_blank"
              fullWidth
            variant={"contained"}
            color={"primary"}
            >
              <Typography>{translate("admin.adminPanel.openAdminPanel")}</Typography>
            </Button>
          )}
        </CardContent>
      </Card>
    </PageBase>
  );
}

AdminPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(AdminPanel);
