import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  openSettingsDrawer,
  closeSettingsDrawer,
} from "containers/App/Redux/menu/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SettingsButton extends React.PureComponent {
  openCloseSettingsDrawer = () => {
    const { openSettingDrawer } = this.props;

    if (openSettingDrawer) {
      this.props.dispatch(closeSettingsDrawer());
    } else {
      this.props.dispatch(openSettingsDrawer());
    }
  };
  notificationCount = () => {
    const { user } = this.props;
    const notificationCount = user?.notifications?.notificationUnreadCount;
    if (notificationCount > 0) {
      return notificationCount;
    }
    return 0;
  };
  render() {
    if (this.notificationCount() === 0) {
      return (
        <IconButton
          className="settings-button"
          onClick={this.openCloseSettingsDrawer}
          size="large"
        >
          {/* <span className="fa-layers fa-fw "> */}
          <FontAwesomeIcon
            icon={["fas", "bell"]}
            fixedWidth
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          {/* </span> */}
        </IconButton>
      );
    }
    return (
      <IconButton
        className="settings-button"
        onClick={this.openCloseSettingsDrawer}
        size="large"
      >
        {/* <span className="fa-layers fa-fw "> */}
        <FontAwesomeIcon
          icon={["fas", "bell-on"]}
          fixedWidth
          style={{
            width: "20px",
            height: "20px",
          }}
        />
        <span className="fa-layers-counter ">{this.notificationCount()}</span>
        {/* </span> */}
      </IconButton>
    );
  }
}

SettingsButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.any.isRequired,
  openSettingDrawer: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    openSettingDrawer: state.menu.openSettingDrawer,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SettingsButton);
