import { createGlobalStyle } from "styled-components";
/*
  Global Styles for the application
*/

const GlobalStyle = createGlobalStyle`
  
  .ssg-container-content, .ssg-widget-content{
    height: 100%;
  }
  .ssg-container-content{
    padding: 16px!important;
  }
  *,*:focus,*:hover{
    outline:none;
  }
#typeahead-menu{
  z-index: 1000;
}
  .SnackbarContainer-top {
    top: 60px !important;
  }
  //React Grid

  .settingsIconContainer {
    position: absolute;
    right: 10px;
    top: 10px;
  }

   #content {
     width: 100%;
   }
  .react-grid-layout {
    // margin-left: -25px;
    // margin-right: -25px;
    // margin-top: 0px;
    position: relative;
  }
   .layoutJSON {
     background: #ddd;
     border: 1px solid black;
     margin-top: 10px;
     padding: 10px;
   }
   .columns {
     -moz-columns: 120px;
     -webkit-columns: 120px;
     columns: 120px;
   }
   .react-grid-item {
     box-sizing: border-box;
   }

  //  .react-grid-layout {
    //  margin-left: -10px;
    //  margin-right: 10px;
  //    // width: calc(100% + (15 * -2)); // evens it out
  //  }

   .react-grid-item.resizing {
     opacity: 0.9;
   }
  .react-grid-item.static {
    background: #cce;
  }
   .react-grid-item .text {
     font-size: 24px;
     text-align: center;
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     margin: auto;
     height: 24px;
   }
   .react-grid-item .minMax {
     font-size: 12px;
   }
   .react-grid-item .add {
     cursor: pointer;
   }
   .react-grid-dragHandleExample {
     cursor: move; /* fallback if grab cursor is unsupported */
     cursor: grab;
     cursor: -moz-grab;
     cursor: -webkit-grab;
   }
   .toolbox {
     background-color: #dfd;
     width: 100%;
     height: 120px;
     overflow: scroll;
   }
   .hide-button {
     cursor: pointer;
     position: absolute;
     font-size: 20px;
     top: 0px;
     right: 5px;
   }
   .toolbox__title {
     font-size: 24px;
     margin-bottom: 5px;
   }
   .toolbox__items {
     display: block;
   }
  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  // End React Grid

  //React Resize Grid

  .react-resizable {
    position: relative;
  }
  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
  }
  .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }
  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }
  .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }
  .react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }
  .react-resizable-handle-n,
  .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }
  .react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }
  //End React Resize Grid
  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-Bold.eot");
    src: url("/fonts/Futura/FuturaPT-Bold.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-Bold.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-Bold.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-Bold.svg#FuturaPT-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-HeavyOblique.eot");
    src: url("/fonts/Futura/FuturaPT-HeavyOblique.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-HeavyOblique.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-HeavyOblique.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-HeavyOblique.svg#FuturaPT-HeavyOblique")
    format("svg");
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-DemiBoldOblique.eot");
    src: url("/fonts/Futura/FuturaPT-DemiBoldOblique.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-DemiBoldOblique.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-DemiBoldOblique.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-DemiBoldOblique.svg#FuturaPT-DemiBoldOblique")
    format("svg");
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-Light.eot");
    src: url("/fonts/Futura/FuturaPT-Light.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-Light.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-Light.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-Light.svg#FuturaPT-Light") format("svg");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-BookOblique.eot");
    src: url("/fonts/Futura/FuturaPT-BookOblique.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-BookOblique.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-BookOblique.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-BookOblique.svg#FuturaPT-BookOblique")
    format("svg");
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: "Futura Condensed PT";
    src: url("/fonts/Futura/FuturaCondensedPT-Book.eot");
    src: url("/fonts/Futura/FuturaCondensedPT-Book.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaCondensedPT-Book.woff2") format("woff2"),
    url("/fonts/Futura/FuturaCondensedPT-Book.woff") format("woff"),
    url("/fonts/Futura/FuturaCondensedPT-Book.svg#FuturaCondensedPT-Book")
    format("svg");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-Heavy.eot");
    src: url("/fonts/Futura/FuturaPT-Heavy.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-Heavy.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-Heavy.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-Heavy.svg#FuturaPT-Heavy") format("svg");
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-BoldOblique.eot");
    src: url("/fonts/Futura/FuturaPT-BoldOblique.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-BoldOblique.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-BoldOblique.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-BoldOblique.svg#FuturaPT-BoldOblique")
    format("svg");
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-Book.eot");
    src: url("/fonts/Futura/FuturaPT-Book.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-Book.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-Book.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-Book.svg#FuturaPT-Book") format("svg");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Futura Condensed PT";
    src: url("/fonts/Futura/FuturaCondensedPT-Medium.eot");
    src: url("/fonts/Futura/FuturaCondensedPT-Medium.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaCondensedPT-Medium.woff2") format("woff2"),
    url("/fonts/Futura/FuturaCondensedPT-Medium.woff") format("woff"),
    url("/fonts/Futura/FuturaCondensedPT-Medium.svg#FuturaCondensedPT-Medium")
    format("svg");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-MediumOblique.eot");
    src: url("/fonts/Futura/FuturaPT-MediumOblique.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-MediumOblique.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-MediumOblique.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-MediumOblique.svg#FuturaPT-MediumOblique")
    format("svg");
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-DemiBold.eot");
    src: url("/fonts/Futura/FuturaPT-DemiBold.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-DemiBold.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-DemiBold.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-DemiBold.svg#FuturaPT-DemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-Medium.eot");
    src: url("/fonts/Futura/FuturaPT-Medium.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-Medium.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-Medium.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-Medium.svg#FuturaPT-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Futura";
    src: url("/fonts/Futura/FuturaPT-LightOblique.eot");
    src: url("/fonts/Futura/FuturaPT-LightOblique.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaPT-LightOblique.woff2") format("woff2"),
    url("/fonts/Futura/FuturaPT-LightOblique.woff") format("woff"),
    url("/fonts/Futura/FuturaPT-LightOblique.svg#FuturaPT-LightOblique")
    format("svg");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "Futura Condensed PT";
    src: url("/fonts/Futura/FuturaCondensedPT-MediumOblique.eot");
    src: url("/fonts/Futura/FuturaCondensedPT-MediumOblique.eot?#iefix")
    format("embedded-opentype"),
    url("/fonts/Futura/FuturaCondensedPT-MediumOblique.woff2") format("woff2"),
    url("/fonts/Futura/FuturaCondensedPT-MediumOblique.woff") format("woff"),
    url("/fonts/Futura/FuturaCondensedPT-MediumOblique.svg#FuturaCondensedPT-MediumOblique")
    format("svg");
    font-weight: 500;
    font-style: italic;
  }


  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    
  }
  body{
    overflow-y: auto;
  }
  #app {
    background-color: #EAE9F4;
    min-height: 100%;
    min-width: 100%;
  }

  .app-frame {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }

  p,
  label {
    line-height: 1.5em;
  }

  /* app wrapper */
  #app-wrapper {
    margin: 0;
    display: flex;
    min-height: 100%;
    flex-direction: column;
  }

  /* header appbar */

  header .tabs button:hover i {
    visibility: visible !important;
  }

  /* header primary appbar */
  .primary-appbar {
    height: 56px;
    margin-top: -8px;
    box-shadow: none;
  }

  /* header secondary appbar */

  .secondary-appbar {
   
    margin-top: 48px;
    min-height: 48px;
  }

  .secondary-appbar .empty-icon {
    width: 60px;
  }

  /* sidebar left */
  #sidebar-menu > div,
  #sidebar-menu-mobile > div {
    overflow-y: hidden !important;
    position: fixed !important;
  }

  .open-views-list ul,
  .menu-list ul {
    overflow-y: hidden !important;
  }

  .open-views-list ul:hover,
  .menu-list ul:hover {
    overflow-y: auto !important;
  }

  .open-views-list {
    margin-top: 1px;
  }

  .menu-list {
    margin-top: -7px;
  }

  #sidebar-menu ul,
  #sidebar-menu-mobile ul {
    overflow-y: auto;
  }

  /* main container */
  main.main-container {
    background-color: #EAE9F4;
    position: relative;
width: 0;
  }

  main.main-container-sidebar-closed {
    margin-left: 60px;
  }

  main.main-container-sidebar-opened {
    margin-left: 240px;
   overflow: hidden;
    
  }

  @media screen and (max-width: 959px) {
    main.main-container-sidebar-opened,
    main.main-container-sidebar-closed {
      margin-left: 0px;
    }
  }

  .main-container.subtabs {
    height: calc(100% - 112px);
  }

  .primary-appbar.no-box-shadow {
    box-shadow: none;
  }

  .secondary-appbar > div {
    min-height: 48px;
  }

  @media (max-width: 600px) {
    .main-container {
      margin-top: 44px !important;
    }
    .main-container.subtabs {
      height: calc(100% - 128px);
     
      margin-top: 95px !important;
    
    }
    .primary-appbar {
      height: 52px;
    }
    .secondary-appbar {
      
      margin-top: 44px;
       
    }
    .settings-button, .secondary-appbar .empty-icon {
      display: none !important;
    }
    .open-views-list ul,
    .menu-list ul {
      overflow-y: auto !important;
    }
  }

  @media (min-width: 600px) {
    .main-container.subtabs {
      height: calc(100% - 128px);
     
      margin-top: 95px;
     
    }
  }

  /* sidebar items */
  .truncate-list-item-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .menu-icon{
    min-width: 40px!important;
  }

  .menu-padding{
    padding-left: 56px;
  }
  .menu-margin{
    margin-top: 4px!important;
    margin-bottom: 4px!important;
  }
  .truncate-list-item-text h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .open-views-list li:hover i {
    visibility: visible !important;
  }

  /* scrollbars style */
  body::-webkit-scrollbar, *::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  body::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
  }

  body::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: darkgrey;
    outline: 0px solid slategrey;
  }

  body::-webkit-scrollbar {
      width: 1em;
  }

  /* LeftSidebar */
  .left-sidebar-close {
    cursor: default;
  }

  /* header tabs */
  .tabs .close-tab .material-icons {
    position: absolute;
    left: 0px;
    top: 0px;
    visibility: hidden;
    cursor: default;
  }

  .tabs .close-tab:hover span  {
    visibility: visible;
  }

  .tabs .close-tab {
    min-height: 48px !important
  }

  /* CSSTransition */
  .fade-appear {
    opacity: 0.01;
  }
  .fade-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
  }

  /* recharts */
  .recharts-wrapper, .ReactTable {
    font-size: 14px;
  }


/* Video Chat */

.video-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.myVideo {
height: 300px;
border-radius: 1rem;
margin: 0.5rem;
width: 400px;
object-fit: cover;
transform: rotateY(180deg);
-webkit-transform: rotateY(180deg);
-moz-transform: rotateY(180deg);
}

.myScreenShare{
  height: 500px;
  border-radius: 1rem;
  margin: 0.5rem;
  width: 740px;
  object-fit: contain;
}

.myScreenShareCanvas{
  display: none
}


  
`;

export default GlobalStyle;
