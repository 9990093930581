
import { get } from "lodash";

const filterOperatorConverter = (operator) => {
    // console.log(operator)
    switch (operator) {
        case "contains":
            return "_ilike";
        case "equals":
            return "_eq";
        case "startsWith":
            return "_ilike";
        case "endsWith":
            return "_ilike";
        case "is_null":
            return "_is_null";
        default:
            return "_eq";
    }
}

const dotFilter = (field, operator, postProcess, itemValue) => {
    // console.log(field)
    // console.log(operator)
    // console.log(postProcess)
    // console.log(itemValue)
    let fieldArray = field.split(".")
    // if (fieldArray.length === 3) {
    //     console.log(`{_or: [{requester: {organization: {name:  {_ilike: "${postProcess + itemValue + postProcess}"}} }}]}`)
    //     return `{_or: [{requester: {organization: {name:  {_ilike: "${postProcess + itemValue + postProcess}"}} }}]}`
    // }
    const newField = fieldArray.reduceRight((acc, curr, index) => {
        if (index === fieldArray.length - 1) {
            return `${curr}: ${acc}`;
        } else {
            if (operator === "_is_null") {
                return `${curr}: {${acc} {${operator}: ${itemValue}}}`;
            } else {
                return `${curr}: {${acc} {${operator}: "${postProcess + itemValue + postProcess}"}}`;
            }
        }
    }, '');

    // console.log(newField)
    return newField
}


const sortComposer = (sortModel) => {
    if (sortModel?.length > 0) {
        if (sortModel[0].field.includes(".")) {
            let fieldArray = sortModel[0].field.split(".")
            const newField = fieldArray.reduceRight((acc, curr, index) => {
                if (index === fieldArray.length - 1) {
                    return `${curr}: ${acc} ${sortModel[0].sort}`;
                } else {
                    return `${curr}: {${acc}}`;
                }
            }, '');
            // console.log(newField)
            return `{${newField}}`
        }
        else {
            return `{${sortModel.length > 0 ? sortModel.map((sort) => `${sort.field}: ${sort.sort}`).join(", ") : ""}}`
        }
    } else {
        return null
    }
}

const filterItems = (filterModel) => {
    // console.log(filterModel)
    return new Promise((resolve, reject) => {
        if (filterModel.items.length > 0) {
            let where = "";
            let itemsCount = filterModel?.items?.length ?? 0;
            let items = [];

            filterModel.items.forEach((item) => {
                if (!item.value) {
                    if (item.operator === "is_null") {
                        items.push(item);
                    }
                    itemsCount = itemsCount - 1;
                } else {
                    items.push(item);
                }
            });

            if (filterModel.logicOperator === "or") {
                where = items
                    .map((filter) => {
                        // console.log(filter)
                        let postProcess = "";
                        let operator = filterOperatorConverter(filter.operator);
                        if (operator === "_ilike") {
                            postProcess = "%";
                        }
                        if (filter.field.includes(".")) {
                            return dotFilter(filter.field, operator, postProcess, filter.value);
                        } else {
                            if (filter.operator === "is_null") {
                                return `${filter.field}: {${operator}: ${filter.value}}`;
                            } else {
                                return `${filter.field}: {${operator}: "${postProcess + filter.value + postProcess}"}`;
                            }
                        }
                    })
                    .join("}, {");

                where = `_or: [{${where}}]`;
            } else if (filterModel.logicOperator === "and") {
                // single and
                if (itemsCount === 1) {
                    //  console.log("itemCount = 1")
                    let postProcess = "";
                    let operator = filterOperatorConverter(items[0].operator);
                    if (operator === "_ilike") {
                        postProcess = "%";
                    }
                    if (items[0].field.includes(".")) {
                        where = dotFilter(items[0].field, operator, postProcess, items[0].value);
                    } else {
                        if (items[0].operator === "is_null") {
                            where = `${items[0].field}: {${operator}: ${items[0].value}}`;
                        } else {
                        where = `${items[0].field}: {${operator}: "${postProcess + items[0].value + postProcess}"}`;
                        }
                    }
                } else if (itemsCount > 1) {
                    // console.log("itemCount > 1")
                    // more than 1 and
                    where = items
                        .map((filter) => {
                            let postProcess = "";
                            let operator = filterOperatorConverter(filter.operator);
                            if (operator === "_ilike") {
                                postProcess = "%";
                            }
                            if (filter.field.includes(".")) {
                                return dotFilter(filter.field, operator, postProcess, filter.value);
                            } else {
                                if (filter.operator === "is_null") {
                                    return `${filter.field}: {${operator}: ${filter.value}}`;
                                } else {
                                    return `{${filter.field}: {${operator}: "${postProcess + filter.value + postProcess}"}`;
                                }
                            }
                        })
                        .join(", _and: {");

                    for (let i = 0; i < itemsCount; i++) {
                        where = `${where}}`;
                    }

                    // remove the last character from where
                    where = where.slice(0, -1);
                }
            } else if (filterModel.logicOperator === "custom") {
                // console.log(items)
                // split items into multiple arrays based on the item field
                let itemsArray = [];
                let itemsArrayCount = 0;
                items.forEach((item) => {
                    if (itemsArray.length === 0) {
                        itemsArray.push([item]);
                    } else {
                        let found = false;
                        itemsArray.forEach((array) => {
                            if (array[0].field === item.field) {
                                array.push(item);
                                found = true;
                            }
                        });
                        if (!found) {
                            itemsArray.push([item]);
                        }
                    }
                });
                // console.log(itemsArray)
                let arrayOFOrs = []
                itemsArray.map((item) => {
                    let andResult = createOr(item)
                    arrayOFOrs.push(andResult)
                })

                where += `_and: [`
                arrayOFOrs.map((item) => {
                    where += `{${item}}`
                })
                where += `]`

            }
            // console.log(where)
            resolve(where);
        } else {
            resolve("");
        }
    });
};


// query MyQuery {
//     ticket(where: 
//       {_and: [
//         {_or: [
//           {status: 
//             {name: 
//               {_ilike: "%new%"}
//             }
//           },
//           {status: 
//             {name: 
//               {_ilike: "%pending%"}
//             }
//           }
//         ]}
//         {assignee: 
//           {name: 
//               {_ilike: "%Jason Smyth%"}
//           }
//         }
//       ]
//       }) {
//       alias
//       assignee {
//         name
//       }
//       status {
//         name
//       }
//     }
//   }


function createOr(items) {
    let where = ""
    where = items
        .map((filter) => {
            let postProcess = "";
            let operator = filterOperatorConverter(filter.operator);
            if (operator === "_ilike") {
                postProcess = "%";
            }
            if (filter.field.includes(".")) {
                return dotFilter(filter.field, operator, postProcess, filter.value);
            } else {
                if (filter.operator === "is_null") {
                    return `${filter.field}: {${operator}: ${filter.value}}`;
                } else {
                return `${filter.field}: {${operator}: "${postProcess + filter.value + postProcess}"}`;
                }
            }
        })
        .join("}, {");

    where = `_or: [{${where}}]`;

    return where
}


function processColumns(incomingColumns) {
    let columns = []
    let fields = incomingColumns.map((column) => column.field).join(", ");
    if (fields.includes(".")) {
        let fieldsArray = fields.split(", ");
        let newFieldsArray = [];
        fieldsArray.forEach((field) => {
            if (field.includes(".")) {
                let fieldArray = field.split(".");
                const newField = fieldArray.reduceRight((acc, curr, index) => {
                    if (index === fieldArray.length - 1) {
                        return `${curr} ${acc}`;
                    } else {
                        return `${curr} {${acc}}`;
                    }
                }, "");
                newFieldsArray.push(newField);

                let column = incomingColumns.find((column) => column.field === field);
                column.valueGetter = (params) => get(params.row, field, "-");
            } else {
                newFieldsArray.push(field);
            }
        });
        fields = newFieldsArray.join(", ");
    }
    // delete columns if visibility is false
    incomingColumns.map((column, index) => {
        if (column.visability === false) {
            return
        } else {
            columns.push(column)
        }
    });

    return { fields, columns };
}

export {
    filterItems,
    sortComposer,
    dotFilter,
    processColumns
};