import React, { useEffect, useState } from 'react';
import { $getRoot, $getSelection, CLEAR_EDITOR_COMMAND } from 'lexical';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import ToolbarPlugin from './plugins/ToolBarPlugin.js';

import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MentionNode } from './nodes/MentionNode.js';
import withStyles from '@mui/styles/withStyles';
import styles from './styles.js';
import CodeHighlightPlugin from './plugins/CodeHighlightingPlugin.js';
// import MentionsPlugin from "./plugins/MentionsPlugin";
import { $generateHtmlFromNodes } from '@lexical/html';
import { Box } from '@mui/material';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import EditorToHtml from './plugins/EditorToHtml.js';
import ToolBarSubmitPlugin from './plugins/ToolBarSubmitPlugin.js';
import ToolBarSubjectPlugin from './plugins/ToolBarSubjectPlugin.js';
import ToolBarContactsPlugin from './plugins/ToolBarContactsPlugin.js';
import useInsertTicketComment from 'containers/App/Hooks/useInsertTicketComment';
import useWarningNotification from 'utils/useWarningNotification';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { useSelector } from 'react-redux';
import { KEY_TAB_COMMAND, OUTDENT_CONTENT_COMMAND, INDENT_CONTENT_COMMAND, COMMAND_PRIORITY_EDITOR } from 'lexical';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';


const blankRoot = {
  root: {
    children: [
      {
        children: [],
        direction: null,
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1,
      },
    ],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
};

function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Load editor state from localStorage
    const savedState = loadEditorState();
    if (savedState) {
      editor.update(() => {
        const editorState = editor.parseEditorState(savedState);
        editor.setEditorState(editorState);
      });
    } else {
      editor.focus();
    }

    // Register update listener to save editor state on every change
    editor.registerUpdateListener(({ editorState }) => {
      saveEditorState(editorState);
    });

    // Register tab key handling
    editor.registerCommand(
      KEY_TAB_COMMAND,
      (payload) => {
        const event = payload;
        event.preventDefault();
        return editor.dispatchCommand(event.shiftKey ? OUTDENT_CONTENT_COMMAND : INDENT_CONTENT_COMMAND);
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return null;
}

// Save editor state to localStorage with the url as the key
const saveEditorState = (editorState) => {
  const serializedState = JSON.stringify(editorState.toJSON());
  const url = window.location.href;
  localStorage.setItem(`editorState_${url}`, serializedState);

};

// Load editor state from localStorage
const loadEditorState = () => {
  const url = window.location.href;
  const savedState = localStorage.getItem(`editorState_${url}`);
  return savedState ? JSON.parse(savedState) : null;
};


// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
// function onError(error) {
//   throw error;
// }

// function Placeholder() {
//   return (
//     <div className="editor-placeholder">
//       Play around with the Markdown plugin...
//     </div>
//   );
// }

const TicketCommentTextEditor = (props) => {
  const {
    ticket,
    readOnly = false,
    value = blankRoot,
    handleComposerCollapse,
    classes,
    namespace = 'editor',
    id,
    emailListOpen,
    handleCloseEmails,
    setEmailListOpen,
    toArray,
    setToArray,
    emailOptions,
    setEmailOptions,
    setShowNoteTypes
  } = props;
  const [editorState, setEditorState] = useState(blankRoot);
  const [htmlString, setHtmlString] = React.useState(null);
  const [commentValues, setCommentValues] = useState({ public: true });
  const [addResources, setAddResources] = useState(false);
  const [sendWarningNotification] = useWarningNotification();
  const { handle: submitComment } = useInsertTicketComment({
    ticketID: ticket?.id,
    ticketAssignee: ticket?.assignee?.name,
    ticket: ticket,
  });

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!commentValues.subject && ticket?.subject) {
      setCommentValues((prevState) => {
        return { ...prevState, subject: ticket?.subject };
      });
    }
  }, [ticket, commentValues]);
  const htmlCallBack = (html) => {
    setHtmlString(html);
  };

  useEffect(() => { }, [commentValues]);

  //loop through object with arrays and look for an object with a key of "text" and cound the character length of the values
  const countCharacters = (obj) => {
    let count = 0;
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        count += countCharacters(obj[key]);
      } else if (key === 'text') {
        let text = obj[key];
        text = text.replace(/\s/g, '');
        text = text.replace(/(.)\1+/g, '$1');
        count += text.length;
      }
    }
    return count;
  };

  const clearCachedEditorState = () => {
    const url = window.location.href;
    localStorage.removeItem(`editorState_${url}`);
  };

  const submitCommentHandler = (editor) => {
    if (countCharacters(editorState) > 15) {
      handleComposerCollapse();
      clearCachedEditorState();
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, null);

      // console.log('ticket', ticket);
      // console.log('commentValues', commentValues);

      let variables = {
        public: commentValues.public,
        subject: commentValues.subject,
        lexical: editorState,
        comment: htmlString,
        statusID: ticket.status.id,
        priorityID: ticket.priority.id,
        closedAt: ticket.closed_at,
        agent: user?.user?.roles?.includes('agent') ? true : false,
        alias: ticket.alias,
        assignee: ticket?.contacts[0]?.roles[1]?.contacts,
        resources: ticket?.contacts[0]?.roles[0]?.contacts,
        team: ticket.team,
        assignee: ticket?.assingee?.name,
      };
      if (commentValues.resolved && !ticket.closed_at) {
        variables.closedAt = 'now';
      }
      if (ticket.closed_at && !commentValues.resolved && commentValues.resolved !== undefined) {
        variables.closedAt = null;
      }
      if (commentValues.status_id) {
        variables.statusID = commentValues.status_id;
      }
      if (commentValues.priority_id) {
        variables.priorityID = commentValues.priority_id;
      }
      if (commentValues.cc) {
        variables.cc = commentValues.cc;
      }
      if (commentValues.to) {
        variables.to = commentValues.to;
      }
      if (commentValues.mentions) {
        variables.mentions = commentValues.mentions;
      }

      // console.log('variables', variables);

      submitComment(variables).then((res) => {
        // console.log(res);
        // mutations.handleSubmitTopLevel();
      });
    } else {
      sendWarningNotification({
        persist: false,
        message: 'Please enter a more detailed comment before submitting',
      });
    }
  };

  function onChange(editorState) {
    editorState.read(() => {
      // Read the contents of the EditorState here.
      $getRoot();
      $getSelection();
    });

    const json = editorState.toJSON();
    setEditorState(json);
  }

  const editorConfig = {
    editorState: JSON.stringify(value),
    namespace: namespace,
    // theme: Theme(props.classes),
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    editable: !readOnly,
    // Any custom nodes go here
    nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, CodeNode, CodeHighlightNode, TableNode, TableCellNode, TableRowNode, AutoLinkNode, LinkNode, MentionNode],
  };

  return (
    <Box
      {...(!readOnly
        ? {
          sx: {
            // padding: props.theme.spacing(2),
            // paddingTop: 0,
            backgroundColor: props.theme.palette.background.main,
            // zIndex: 1000,
            // width: '90%',

            borderRadius: 2,

          },
        }
        : {})}
    >
      <LexicalComposer initialConfig={editorConfig} key={id}>
        <div className={`${props.classes['editor-container']} ${!readOnly && props.classes['editor-container-shadow']} `}>
          {!readOnly && (
            <>
              <ToolBarContactsPlugin
                addResources={addResources}
                classes={classes}
                ticket={ticket}
                editorState={editorState}
                formValues={commentValues}
                setFormValues={setCommentValues}
                open={emailListOpen}
                onClose={handleCloseEmails}
                setEmailListOpen={setEmailListOpen}
                toArray={toArray}
                setToArray={setToArray}
                emailOptions={emailOptions}
                setEmailOptions={setEmailOptions}
              />
              <ToolBarSubjectPlugin classes={classes} commentValues={commentValues} setCommentValues={setCommentValues} ticket={ticket} />
              <ToolbarPlugin
                blankRoot={blankRoot}
                editorState={editorState}
                htmlString={htmlString}
                ticket={ticket}
                handleComposerCollapse={handleComposerCollapse}
                addResources={setAddResources}
              />
            </>
          )}
          <div className={props.classes['editor-inner']}>
            <RichTextPlugin
              contentEditable={<ContentEditable readOnly={true} className={`${props.classes['editor-input']} ${!readOnly && props.classes['editor-input-read-only']}`} />}
              placeholder={<></>}
              ErrorBoundary={LexicalErrorBoundary}
            />

            {!readOnly && (
              <>
                <OnChangePlugin onChange={onChange} />
                <MyCustomAutoFocusPlugin />
              </>
            )}
            <ListPlugin />
            <LinkPlugin />
            {/*<MentionsPlugin />*/}
            <CodeHighlightPlugin />
            <HistoryPlugin />
            <ClearEditorPlugin />
            <EditorToHtml htmlCallBack={htmlCallBack} />
            {/*<MarkdownShortcutPlugin transformers={TRANSFORMERS} />*/}
          </div>
          {!readOnly && (
            <ToolBarSubmitPlugin
              ticket={ticket}
              classes={classes}
              handleComposerCollapse={handleComposerCollapse}
              setCommentValues={setCommentValues}
              submitCommentHandler={submitCommentHandler}
              setShowNoteTypes={setShowNoteTypes}
            />
          )}
          {/*<ActionsPlugin />*/}
        </div>
      </LexicalComposer>
    </Box>
  );
};

export default withStyles(
  (theme) => ({
    ...styles(theme),
  }),
  { withTheme: true },
)(TicketCommentTextEditor);
