import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useWarningNotification = () => {
  const [conf, setConf] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const Notification = (key) => {
    let message = 'There is a general warning. Please Contact Sixstar Support Development Team.';
    let title = 'Warning';

    if (conf?.title) {
      title += ` - ${conf.title}`;
    }

    if (conf?.message) {
      message = conf.message;
    }

    return (
      <Box sx={{ minWidth: '230px' }} className='notification'>
        <Grid container spacing={2} rowSpacing={0}>
          <Grid item xs={'auto'}>
            <div>{title}</div>
            <div>{message}</div>
          </Grid>
        </Grid>
      </Box>
    );
  };
  useEffect(() => {
    if (conf?.message) {
      try {
        enqueueSnackbar(<Notification />, {
          variant: 'warning',
          persist: conf?.persist ?? true,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: conf?.autoHideDuration ?? 5000,
          action: (key) => (
            <IconButton
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              <FontAwesomeIcon icon={['fas', 'times']} fixedWidth color={'white'} />
            </IconButton>
          ),
        });
      } catch (e) {
        // console.log(e);
      }
    }
  }, [conf]);
  return [setConf];
};

export default useWarningNotification;
