import Settings from "containers/Pages/SettingsPage/Loadable";
import DashboardPage from "containers/Pages/DashboardPage/Loadable";

let meet = [
  {
    id: "dashboard",
    text: "dashboard.tabText",
    menuText: "dashboard.menuText",
    icon: "fal faw fa-home",
    url: "/",
    component: <DashboardPage />,
    roles: ["user"],
  },
  {
    id: "settings",
    text: "settings.tabText",
    menuText: "settings.menuText",
    icon: "fal faw fa-cog",
    url: "/settings",
    component: <Settings />,
    roles: ["user"],
  },
];

export default meet;
