const pages = {
  'dashboard.pageTitle': 'Dashboard',
  'dashboard.greeting.morning': 'Good Morning',
  'dashboard.greeting.afternoon': 'Good Afternoon',
  'dashboard.greeting.evening': 'Good Evening',
  'dashboard.welcomeMessage': "You've got {tickets} tickets active and {messages} messages to respond to.",
  'notAuthorizedPage.title': 'Not Authorized',
  'notAuthorizedPage.desc': 'You are not authorized to access this page.',
  'dashboard.welcomeText': 'Welcome To {ssg}!',
  'dashboard.button.addWidget': 'Add Widget',
  'dashboard.button.close': 'Close',
  'dashboard.button.edit': 'Edit',
  'dashboard.button.save': 'Save',
  'settingsPage.pageTitle': 'Settings',
  'ticketViewPage.priority': 'Priority',
  'ticketViewPage.assignee': 'Assignee',
  'ticketViewPage.team': 'Team',
  'ticketViewPage.sixStarTeam': 'Additional Six Star Team Members',
  'ticketViewPage.resourcesOnTicket': 'Resources On Ticket',
  'ticketViewPage.followers': 'Followers',
  'ticketViewPage.tags': 'Tags',
  'ticketViewPage.takeIt': 'take it',
  'ticketViewPage.notes': 'Notes',
  'ticketViewPage.activity': 'Activity',
  'ticketViewPage.timeline': 'Timeline',
  'ticketViewPage.tasks': 'Tasks',
  'ticketViewPage.search': 'Search',
  'ticketViewPage.noteAddedBy': 'Note added by',
  'ticketViewPage.moreButton': 'More',
  'ticketViewPage.makeInternal': 'Make Internal',
  'ticketViewPage.makePublic': 'Make Public',
  'ticketViewPage.deleteComment': 'Delete',
  'ticketViewPage.reportComment': 'Report',
  'ticketViewPage.internalTicketComment': 'This is an internal comment',
  'ticketViewPage.publicTicketComment': 'This is a public comment',
  'ticketViewPage.ticketSource.internal_web': 'Internal Web',
  'ticketViewPage.ticketSource.external_email': 'Internal Email',
  'ticketViewPage.followTicket': 'Follow Ticket',
  'ticketViewPage.unfollowTicket': 'Unfollow Ticket',
  'ticketViewPage.tasks.priority.completed': 'Completed',
  'ticketViewPage.tasks.priority.in_progress': 'In Progress',
  'ticketViewPage.tasks.priority.not_started': 'Not Started',
  'ticketViewPage.tasks.priority.low': 'Low',
  'ticketViewPage.tasks.priority.medium': 'Medium',
  'ticketViewPage.tasks.priority.important': 'important',
  'ticketViewPage.tasks.priority.urgent': 'Urgent',
  'ticketViewPage.tasks.assignedTo': 'Assigned To',
  'ticketViewPage.tasks.completedBy': 'Completed By',
  'ticketViewPage.requester': 'Requester',
  'ticketViewPage.organization': 'Organization',
  'ticketViewPage.button.cancel': 'Cancel',
  'ticketViewPage.button.close': 'Close',
  'ticketViewPage.downloadEml': 'Download EML',
  'newRequestPage.header': 'New Request',
  'newRequestPage.button.internalRequest': 'Internal Request',
  'newRequestPage.button.serviceRequest': 'Service Request',
  'newRequestPage.button.procurementRequest': 'Procurement Request',
  'newRequestPage.card.urgentHelp': 'Need Urgent Help?',
  'newRequestPage.button.callUs': 'Call Us',
  'newRequestPage.button.passwordReset': 'Domain/Computer Password Reset',
  'newRequestPage.button.newRequest': 'Support Request',
  'formPage.title.supportRequest': 'Support Request',
  'formPage.title.newStarterRequest': 'New Starter Request',
  'formPage.title.serviceRequest': 'Service Request',
  'formPage.title.newStarter': 'New Starter / Role Change',
  'formPage.title.leaver': 'Leaver',
  'formPage.title.engineerVisit': 'Engineer Visit',
  'formPage.title.procurementRequest': 'Procurement Request',
  'formPage.title.developmentRequest': 'Development Request',
  'formPage.title.internalRequest': 'Internal Request',
  'ldapPasswordResetPage.button.submit': 'Reset Password',
  'ldapPasswordResetPage.button.generatePassword': 'Generate Password',
  'ldapPasswordResetPage.title': 'Domain/Computer Password Reset',
  'ldapPasswordResetPage.paragraph.intro': 'Reset your computer domain password. Depending on your hotels policy you maybe required to change your password every 90 days.',
  'licencePage.title': 'Licences',
  'licencePage.office365Licences': 'Office 365 Licences',
  'licencePage.license': 'License',
  'licencePage.active': 'Active',
  'licencePage.consumed': 'Consumed',
  'licencePage.unused': 'Unused',
  'assetPage.title': 'Assets',
  'resourceTracker.title': 'Resource Tracker',
  'ticketViewPage.overview': 'Overview',
  'ticketViewPage.roles': 'Roles',
  'ticketViewPage.addContact': 'Add Contact',
  'ticketViewPage.status': 'Status',
  'ticketViewPage.createdAt': 'Created At',
  'ticketViewPage.updatedAt': 'Updated At',
  'ticketViewPage.devices': 'Devices',
  'ticketViewPage.contacts': 'Contacts',
  'ticketViewPage.documents': 'Documents',
  'ticketViewPage.addNote': 'Add Note',
  'ticketViewPage.info': 'Info',
  'ticketViewPage.created': 'Created',
  'ticketViewPage.lastActivity': 'Last Activity',
  'ticketViewPage.priorityLevel': 'Priority Level',
  'ticketViewPage.assigned': 'Assigned',
  'ticketViewPage.ticketSLA': 'Ticket SLA',
  'ticketViewPage.firstResponse': 'First Response',
  'ticketViewPage.resolution': 'Resolution',
  'ticketViewPage.nextUpdate': 'Next Update',
  'ticketViewPage.mostRecentMessages': 'Most Recent Message',
  'organizationViewPage.overview': 'Overview',
  'organizationViewPage.activity': 'Activity',
  'assetPage.name': 'Name',
  'assetPage.type': 'Type',
  'assetPage.status': 'Status',
  'assetPage.serial': 'Serial',
  'assetPage.manufacturer': 'Manufacturer',
  'assetPage.cpu': 'CPU',
  'assetPage.memory': 'Memory',
  'assetPage.lastUser': 'Last Seen',
  'assetPage.lastSeen': 'Last Seen On',
  'assetPage.operatingSystem': 'Operating System',
  'sixStarMeet.header': 'The Technology Behind Every Great Meeting',
  'contactUsPage.number.button': 'Click Here',
  'contactUsPage.title.second': 'Give us a call',
  'contactUsPage.title.first': 'Hello, how can we help you?',
  'contactUsPage.header.boxs.one': 'How do I log a ticket?',
  'contactUsPage.header.boxs.two': 'How do I sign out?',
  'contactUsPage.header.boxs.three': 'Where do i see all my tickets?',
  'contactUsPage.header.boxs.four': 'How do I add widgets?',

  'contactUsPage.search.answers': 'Search for answers...',
  'contactUsPage.search': 'Search for answers...',

  // Below = Need translation confirmation
  'ticketViewPage.approvedAt': 'Approved At',
  'ticketViewPage.rejectedAt': 'Rejected At',
  'ticketViewPage.approvedBy': 'Approved By',
  'ticketViewPage.rejectedBy': 'Rejected By',
  'ticketViewPage.approvalStatus': 'Approval Status',
  'ticketViewPage.approvalStatus.approved': 'Approved',
  'ticketViewPage.approvalStatus.rejected': 'Rejected',
  'ticketViewPage.approvalStatus.pending': 'Pending',
  'ticketViewPage.requestedBy': 'Requested By',
  'licencePage.overview.heading': 'OVERVIEW',
  'licencePage.summary.active': 'ACTIVE',
  'licencePage.summary.consumed': 'CONSUMED',
  'licencePage.summary.unused': 'UNUSED',
  'licencePage.summary.closeToExpiry': 'CLOSE TO EXPIRY',
  'licencePage.summary.consumedButInactive': 'CONSUMED BUT INACTIVE',

  // First time set up page
  'firstTimeSetupPage.firstLookAtManage': 'First Look at Manage 3.0',
  'firstTimeSetupPage.elevatingYourITManagementExperience': 'Elevating Your IT Management Experience',
  'firstTimeSetupPage.weAreThrilledToPresent': 'We are thrilled to present the teaser for a new chapter in your IT management journey with the beta of Six Star: Manage 3.0.',
  'firstTimeSetupPage.asAValuedMember':
    'As valued members of the Six Star Global family, who are already benefiting from our solutions, we are excited to introduce you to what will be a modified and improved experience that means your IT management is more dynamic, streamlined and intuitive.',
  'firstTimeSetupPage.aNewChapterInITManagement': 'A New Chapter in IT Management',
  'firstTimeSetupPage.technologicalTakeOver': 'A Technological Makeover:',
  'firstTimeSetupPage.redesign':
    'A modern redesign that takes into account speed and stability. Performance is a top tier concern of ours, so we have made sure that no matter where you are, mobile or on desktop, you�ve got your IT portfolio at your fingertips.',
  'firstTimeSetupPage.effortlessLicenseOverview': 'Effortless License Overview:',
  'firstTimeSetupPage.simplifyLicenceManagement':
    'Simplify license management effortlessly. Manage 3.0 empowers you to oversee software and user licenses seamlessly, ensuring compliance and optimising expenditure.',
  'firstTimeSetupPage.smartReportGeneration': 'Smart Report Generation: ',
  'frstTimeSetupPage.harnessThePowerOfData':
    'Harness the power of data for strategic decision-making. Generate tailored reports using ticket statistics, helping you fine-tune operations and enhance service quality within your establishment.',
  'firstTimeSetupPage.tailoredDashboardAtYourFingertips': 'Tailored Dashboard at Your Fingertips: ',
  'firstTimeSetupPage.craftDashboard':
    'Craft a personalised IT dashboard with a variety of widgets, giving you quick insights into ticket statuses and trends that align with your specific interests.',
  'firstTimeSetupPage.benefitsTitle': 'Benefits of Manage 3.0 for Your Hotel Operations',
  'firstTimeSetupPage.amplifiedEfficiency': 'Amplified Efficiency: ',
  'firstTimeSetupPage.guestExperience': 'Focus on delivering outstanding guest experiences, with automated processes that address issues seamlessly.',
  'firstTimeSetupPage.realTimeIntelligence': 'Real-time Intelligence: ',
  'firstTimeSetupPage.instantlyAccess': 'Instantly access up-to-date data and ticket statistics for swift, informed decisions.',
  'firstTimeSetupPage.tailoredToYou': 'Tailored to You: ',
  'firstTimeSetupPage.customiseDashboard': 'A bespoke dashboard displays precisely the information you need, offering a comprehensive view of your IT operations.',
  'firstTimeSetupPage.futureProof': 'Future-Proof Assurance:',
  'firstTimeSetupPage.atThisIminentLaunch':
    'As this imminent launch is just the beta version, anticipate regular updates and innovative features that will keep pace with the changing needs of your IT infrastructure.',
  'firstTimeSetUpPage.button.getStarted': 'Get Started',

  // loading
  'loading.title': 'Loading...',

  // ticket view
  'ticketView.activityModule.title': 'Activity',
  'ticketView.emailModule.title': 'Emails',
  'ticketView.infoModule.subheaderOne': 'Support',
  'ticketView.infoModule.subheaderTwo': 'Tech Team',
  'ticketView.infoModule.value 1': 'Option 1',
  'ticketView.infoModule.value 2': 'Option 2',
  'ticketView.infoModule.value 3': 'Option 3',
  'ticketView.infoModule.value 4': 'Option 4',
  'ticketView.notesModule.title': 'Notes',
  'ticketView.tasksModule.title': 'Tasks',

  'ticketViewPage.modal.bundle.title': 'Bundle tickets',
  'ticketViewPage.modal.bundle.parentTicket': 'Parent Ticket',
  'ticketViewPage.modal.bundle.childTicket': 'Child Ticket',
  'ticketViewPage.modal.bundle.button': 'Bundle',
  'ticketViewPage.modal.bundle.button.cancel': 'Cancel',

  //company contact page tab headings
  'companyContactPage.tabs.overview': 'Overview',
  'companyContactPage.tabs.activities': 'Activity',
  'companyContactPage.tabs.tasks': 'Tasks',

  // overview tab
  'companyContactPage.tabs.overview.lastActivity': 'Last Activity',
  'companyContactPage.tabs.overview.customerStatus': 'Customer Status',
  'companyContactPage.tabs.overview.userCreated': 'User created',
  'companyContactPage.tabs.overview.company': 'Company',
  'companyContactPage.tabs.overview.expiringLicences': 'Expiring Licences',
  'companyContactPage.tabs.overview.opemTickets': 'Open Tickets',
  'companyContactPage.tabs.overview.mostRecentActivity': 'Most Recent Activity',
  'companyContactPage.tabs.overview.Drive': 'Drive',
  'companyContactPage.tabs.overview.lastActivity: ': 'Last Activity: ',
  'companyContactPage.tabs.search': 'Search',
  'companyContactPage.tabs.overview.AddedNote': 'Added Note',

  //side panel
  'companyContactPage.tabs.sidePanel.contacts': 'Contacts',
  'companyContactPage.tabs.sidePanel.other': 'Other',
  'companyContactPage.tabs.sidePanel.licenses': 'Licenses',
  'companyContactPage.tabs.sidePanel.procurement': 'Procurement',
  'companyContactPage.tabs.sidePanel.assets': 'Assets',
  'companyContactPage.tabs.sidePanel.invoices': 'Invoices',
  'companyContactPage.tabs.sidePanel.company': 'Company',
  'ticketViewPage.checklist': 'Checklist',
  'ticketViewPage.attachments': 'Attachments',
  'ticketViewPage.relatedItems': 'Related Items',

  //Six star tv page
  'sixStarTVPage.header': 'Leaders In Functionality, Stability and Ease Of Use',
  'sixStarTVPage.integration': 'Integration',
  'sixStarTVPage.webBasedManagement': 'Web Based Management',
  'sixStarTVPage.gaming': 'Gaming',
  'sixStarTVPage.fullyCustomisable': 'Fully Customisable For a Branded Experience',
  'sixStarTVPage.extraFeatures': 'A Whole Host Of Extra Features',
  'sixStarTVPage.plus': 'with Six Star: TV Plus',
  'sixStarTVPage.offer': 'Six Star: TV Plus offers a whole range of extras to make your guests stay even more valuable whilst securing those all important add-on sales.',
  'sixStarTVPage.wakeUp': 'Wake Up',
  'sixStarTVPage.internationalViewing': 'International Viewing',
  'sixStarTVPage.roomService': 'Room Service',
  'sixStar.GetInTouch': 'Get In Touch',
  'sixStar.BackToTop': 'Back To Top',

  // Quick create ticket modal

  "quickCreateTicketModal.title": "Quick Ticket Creation",
  "quickCreateTicketModal.button.submit": "Submit",
  "quickCreateTicketModal.button.submitAndView": "Submit & View Ticket",
  "quickCreateTicketModal.disclaimer": "This ticket will automatically be posted as medium priority and the status as NEW, if no Priority or Status is selected.",
  "quickCreateTicketModal.success": "Ticket Created Successfully",
  "quickCreateTicketModal.button.close": "Close",
  "quickCreateTicketModal.button.createAnother": "Create Another",
  "quickCreateTicketModal.groupHeader.optionalFields": "Optional Fields",
  "quickCreateTicketModal.groupHeader.requiredFields": "Required Fields",

  //sixstar black page
  'sixStarBlackPage.header': 'Elevate Global Travels and Presidential Suites',
  'sixStarBlackPage.traveler': 'Traveler',
  'sixStarBlackPage.hotel': 'Hotel',

  //sixstar vip page
  'sixStarVIPPage.header': "Executives' Choice for Swift Solutions with GM Priority Access",
  'sixStarVIPPage.directToSeniorSupport': 'Direct to Senior Support',
  'sixStarVIPPage.contactCards': 'Contact Cards',
  'sixStarVIPPage.reducedSLA': 'Reduced SLA for execs',
  'sixStarVIPPage.contactCardsForGM': "Contact cards for GM's",

  //sixstar cast page
  'sixStarCastPage.header': 'Guest Entertainment from Home',
  'sixStarCastPage.oneStepAuthentication': 'One Step Authentication',
  'sixStarCastPage.streamCastableApp': 'Guests can stream any castable app',
  'sixStarCastPage.CloudManaged': 'Cloud Managed',

  // sixstar internet page
  'sixStarInternetPage.header': 'Elevate Connectivity, Slash Costs, and Unleash Unrivaled Support',
  'sixStarInternetPage.lowCost': 'Low Cost',
  'sixStarInternetPage.mobileConnectivity': 'Mobile Connectivity',
  'sixStarInternetPage.installations': 'Installations',

  // sixstar display page
  'sixStarDisplayPage.header': 'Transforming Spaces & Elevating Experiences',
  'sixStarDisplayPage.workWithAnyScreen': 'Works with Any Screen',
  'sixStarDisplayPage.wayFinding': 'wayfinding & Dealfinding',
  'sixStarDisplayPage.mobileWebEditor': 'Mobile web editor',

  'sixStarPlus.getInTouch': 'Get In Touch',
  'sixStarTVPage.externalTVInputs': 'External TV Inputs',
  'sixStarTVPage.p2': 'Utilise HDMI ports for consoles and other systems and apps like AirPlay and Six Star: Cast to allow guests to stream from their own personal devices.',

  'sixStarTVPage.p3': 'Investment costs can be reduced by using existing infrastructure',
  'sixStarTVPage.p4': 'In-house monitoring and content managing done straight from a browser',
  'sixStarTVPage.p5': 'A rapidly rising requirement for hospitality providers that SS:TV can deliver with oomph.',
  'sixStarTVPage.fullyCustomizable1080p':
    'A fully customisable 1080p menu with no rigid limits to design. Start off with bespoke templates for a smooth start or SSG can assist with design.',

  'sixStarVoicePage.header': 'Discover the Power of Six Star: Voice',
  'sixStarVoicePage.p1': 'Unparalleled Comunication',
  'sixStarVoicePage.advancedIntegrations': 'Advanced Integrations',
  'sixStarVoicePage.lineRedundancy': 'Line Redundancy',
  'sixStarVoicePage.customisableSolutions': 'Customisable Solutions',
  'sixStarVoicePage.seamlessConnect': 'Seamlessly connect with third-party apps for versatile system functionality.',
  'sixStarVoicePage.enjoy': 'Enjoy uninterrupted communication with standard line redundancy.',
  'sixStarVoicePage.tailor': 'Tailor Six Star: Voice to optimise your unique hospitality operations.',
  'sixStarVoicePage.p2': 'Seamless Communication',
  'sixStarVoicePage.sayGoodbye':
    'Say goodbye to downtime. Our system is built with line redundancy as a standard feature, ensuring uninterrupted communication even in the face of unexpected challenges. We’ve also got dedicated telephony team members ready to support you if you should need it.',

  'sixStarCastPage.low-Cost': 'Low-cost guest device casting solution that can be added to any HDMI compatible TV.',
  'sixStarCastPage.guestCan': 'Guests can access their entertainment straight away as soon as they enter their room.',
  'sixStarCastPage.netflix': 'Netflix, Hulu, Disney+ and many more available.',
  'sixStarCastPage.easilyIntegrated': 'Easily integrated to existing TV solutions',
  'sixStarCastPage.p1':
    'Use the branded marketing portal for direct communication to in-house guests. Via the web-based content management system (CMS), hotel marketing staff can keep guests informed of hotel events and amenities, special offers, opening hours, loyalty programs and much more.',

  'sixStarDisplayPage.subHeader':
    'API-centric hospitality meeting room display, advertising, way-finding, flight board and menu-board display system. Integrations with many third party services.',
  'sixtarDisplayPage.noFancy': 'No fancy hardware to get started, the only hardware requirement is a HDMI Port.',
  'sixStarDisplayPage.mutlipleGoals': 'Six Star: Display can achieve multiple goals',
  'sixStarDisplayPage.plan': 'Plan anywhere and display everywhere as everything is managed within the Cloud.',
  'sixStarDisplayPage.powerful': 'Powerful API Connectivity Draws the Eye',
  'sixStarDisplayPage.p1':
    'Tap into news, weather, traffic, and flight data to turn your screens into real-time information powerhouses. Blend API data with your branded content, or use API triggers to change your messaging automatically.',

  'sixStarParkPage.header': 'Manage Parking Like Rooms and Beds (Including the Revenue)',
  'sixStarParkPage.customerSat': 'Customer Satisfaction',
  'sixStarParkPage.improve': 'Improve overall parking experience and maximise profitability',
  'sixStarParkPage.realTimeInformation': 'Real-time Information',
  'sixStarParkPage.provide': 'Provide real-time information on occupancy and revenue via a single portal',
  'sixStarParkPage.geoMarketing': 'Geo-Marketing',
  'sixStarParkPage.drive': 'Drive in-store promotions to app users and generate footfall into your facilities',
  'sixStarParkPage.extraFeatures': 'Extra Features',
  'sixStarParkPage.p1':
    'Six Star: Park boasts so many more features when it comes to social, economic and organising factors of your parking stake. With the possibility of including EV Chargers within the mix, Express Payments, Pre-Booking and Paying by Mobile, you’ll increase direct revenue as well as maximising the overall experience for guests.',
  'sixStarParkPage.preparing': 'Preparing for the Future',
  'sixStarParkPage.p2':
    'Our hotel Parking product features two advanced EV charging solutions: TRONIQ 50, a 50 kW DC charger with universal compatibility and remote maintenance, and DUAL BUSINESSLINE, a 7.4 kW AC charger that simultaneously charges two cars with efficient power distribution through Load Balancing. These cutting-edge solutions enhance the overall guest experience by providing fast, reliable, and convenient electric vehicle charging options.',

  'sixStarBlackPage.subHeader':
    "The 'Six Star: Black' brand offers two distinct products tailored for high-profile individuals and luxury accommodations who put their guests needs first.",

  'sixStarBlackPage.aService':
    'A service to the occupants of the highest-tier suites within luxury hotels. aiming to enhance the overall experience of guests staying in these exclusive accommodations.',
  'sixStarBlackPage.provides': 'Provides top-tier global desk-side IT support to meet the technology needs of high-profile individuals while they are on the move.',
  'sixStarBlackPage.p1':
    'Experience elite travel support with Six Star: Black (Traveller). Our top-tier global IT team caters to high-profile individuals and their families, delivering seamless, personalised tech assistance wherever your adventures take you from replacement low-voltage equipment to pre-setup equipment shipped directly to you. ',
  'sixStarBlackPage.p2':
    "Integrated with the hotel's existing offerings to create a seamless and elevated experience for guests. We provide industry-leading services to meet the unique and elevated expectations of guests occupying Presidential and Ambassadorial-level suites, ensuring a truly luxurious stay.",

  'sixStarVIPPage.subHeader': 'Premium direct-to-senior-engineer support, and reduced SLAs for your Executive Team.',
  'sixStarVIPPage.whenYouCall': 'When you call or create a ticket, you’re guaranteed one of our senior engineers to help with your issue.',
  'sixStarVIPPage.onTheGo': 'On-The-Go contact cards with a direct number for support.',
  'sixStarVIPPage.bigOrSmall': 'Whether the issue is big or small we can guarantee a lower SLA time ',
  'sixStarVIPPage.plus':
    'Six Star: VIP Plus, the ultimate tier of support designed to elevate your experience to unprecedented levels of excellence. With VIP Plus, you gain access to a realm of privileges that redefine customer support.',
  'sixStarVIPPage.instant': 'Instant Direct Support',
  'sixStarVIPPage.dedicated': 'Dedicated Support Number',
  'sixStarVIPPage.priority': "Priority SLA's",
  'sixStarVIPPage.withPlus': 'with Six Star: VIP Plus',

  'sixStarInternetPage.subHeader':
    'Six Star: Internet combines cost-effective data circuits, comprehensive mobile connectivity, and SIP trunks with free project management and robust support.',
  'sixStarInternetPage.leverage': 'We leverage strategic partnerships, efficient infrastructure, and technology solutions to deliver cost savings to its customers.',
  'sixStarInternetPage.mobileEnhance': 'Mobile connectivity enhances flexibility and productivity for businesses in an increasingly mobile-centric world.',
  'sixStarInternetPage.inclusion': 'The inclusion of project management services means a smooth and hassle-free onboarding experience for customers',
  'sixStarInternetPage.p1':
    'Six Star prioritises cost-effectiveness, offering data circuits at the lowest possible rates. This feature is designed to provide businesses with a competitive edge by minimising expenses related to internet connectivity. By offering affordable data circuits, Six Star enables businesses to allocate their resources more efficiently, fostering growth and sustainability.',
  'sixStarInternetPage.p2':
    'Six Star not only offers SIP (Session Initiation Protocol) trunks for efficient voice communication but also distinguishes itself by providing free project management services. This includes expert guidance and support throughout the implementation process. Once implemented Six Star’s service desk will be available 24/7 for any questions or issues you might have.',
  'sixStarInternetPage.projectManagement': 'Project Management and Support',
  'sixStarInternetPage.maximize': 'Maximising Savings with Data Circuits',

  'sixStarPrintPage.header': 'Effortless Managed Print for Seamless Productivity',
  'sixStarPrintPage.subHeader': 'Smart Print, No Capex: Instant Solutions with Competitive Consumables',
  'sixStarPrintPage.printing': 'Capital Free Printing',
  'sixStarPrintPage.rapidResponse': 'Rapid Response',
  'sixStarPrintPage.costEffectiveConsumables': 'Cost-Effective Consumables',
  'sixStarPrintPage.providing': 'Providing a Managed Print solution without upfront costs.',
  'sixStarPrintPage.enjoy': 'Enjoy same-day/next-day on-site support for uninterrupted printing.',
  'sixStarPrintPage.save': 'Save with competitive pricing on print consumables by Six Star Global.',
  'sixStarPrintPage.hassle': 'Zero-Hassle Printing',
  'sixStarPrintPage.unrivaled': 'Unrivaled Uptime Assurance',
  'sixStarPrintPage.p1':
    "Enjoy printing without the financial burden. Six Star Global's Managed Print solution frees you from upfront costs, making efficient printing accessible without the capital investment.",
  'sixStarPrintPage.p2':
    'Elevate your printing experience with Six Star Global. Benefit from rapid, same-day/next-day on-site support, ensuring your printing operations run smoothly without disruptions.',

  'sixStarGuestPage.header': 'Specifically designed for lobby Guest PCs or Business Centres',
  'sixStarGuestPage.subHeader': 'Elevate your guest experience and streamline your business operations with our cutting-edge Guest Accessable Technology.',
  'sixStarGuestPage.secure': 'Secure Environment',
  'sixStarGuestPage.fully': 'Fully Licensed',
  'sixStarGuestPage.custom': 'Custom Solutions',
  'sixStarGuestPage.B2B': 'B2B & Guest Facing Support',
  'sixStarGuestPage.p1':
    'Elevate your business centre with our robust B2B support services, and enhance the guest experience with reliable and user-friendly public-use workstations. We provide tailored IT solutions for corporate clients while creating a hassle-free environment for guests, whether they need internet access, document printing, or other computing needs.',
  'sixSTarGuestPage.customised': 'Customised Solutions for Any Environment',
  'sixStarGuestPage.p2':
    'Recognising that each hospitality establishment is unique, we offer customisable solutions to meet your specific requirements. From branding to software configurations, we tailor our services to align with your business objectives, whilst you’ve got the assurance with our 24/7 support.',

  'sixStarPayPage.header': 'Elevate Your Hospitality, Simplify Your Transactions',
  'sixStarPayPage.subHeader': 'Streamlining Hospitality Excellence, One Transaction at a Time',
  'sixStarPayPage.flexible': 'Flexible Payment',
  'sixStarPayPage.variousPayment': 'We support various payment options, guests can pay the way they want.',
  'sixStarPayPage.roomCharging': 'Room Charging',
  'sixStarPayPage.orderRouting': 'Order routing made simple, guests are able to charge items/services to their room.',
  'sixStarPayPage.payAtTable': 'Pay-At-Table',
  'sixStarPayPage.ourPay': 'Our Pay-At-Table solution ensures that your menu is synchronised in real-time across all platforms.',
  'sixStarPayPage.increasedEfficiency': 'Increased Efficiency and Guest Happiness',
  'sixStarPayPage.p1':
    'Save time and enhance efficiency with our synchronised menu management and order-routing features. Focus on delivering an exceptional dining experience without the operational hassles. Make that satisfied customer a returning customer.',
  'sixStarPayPage.integration': 'Integration and Setup Made Easy',
  'sixStarPayPage.p2':
    'Seamless integration with most major PMS and POS systems means that you can continue using your preferred tools while enjoying the added benefits of Six Star: Pay.',
  'sixStarTV.design': "Let's Talk About Design",

  'formLoader.BackButton': 'Back',
  'formoader.forwardButton': 'Forward',
  'formLoader.submitButton': 'Submit',
  'formLoader.reSubmitButton': 'Re-submit',
  //Below not translated

  'dataTablePro.error': 'Error',

  'ticketViewPage.button.bundle': 'Bundle',
  'ticketViewPage.button.goBack': 'Go Back',
  'ticketViewPage.ticket.summary': 'Ticket Summary: ',
  'ticketViewPage.formSummary': 'Form Summary',

  "ticketViewPage.button.bundle": "Bundle",
  "ticketViewPage.button.goBack": "Go Back",
  "ticketViewPage.ticket.summary": "Ticket Summary: ",
  "ticketViewPage.formSummary": "Summary",

  'ticketViewPage.masterDetail.viewTicket': 'View Ticket',
  'ticketViewPage.masterDetail.quickNote': 'Quick Note',
  'ticketViewPage.carbonCopy': 'Carbon Copy',
  'ticketViewPage.masterDetail.noRecentActivity': 'No Recent Activity',
  'ticketViewPage.carbonCopyNotAvailable': "No CC's available on this ticket",
  'ticketViewPage.toolbar.quickClose.model.subTitle': 'Are you sure you want to quick resolve {count} tickets?',
  'ticketViewPage.toolbar.quickClose.model.title': 'Quick Resolve Tickets',
  'ticketViewPage.toolbar.label.quickClose': 'Quick Resolve',
  'ticketViewPage.toolbar.quickClose.submitButton': 'Quick Resolve',
  'ticketViewPage.toolbar.quickClose.cancelButton': 'Cancel',
  'ticketViewPage.toolbar.quickClose.model.reason': 'Please enter a short reason for closing the ticket i.e junk',
  'ticketViewPage.toolbar.quickClose.model.reasonPlaceholder': 'Reason',
  'ticketViewPage.toolbar.quickClose.model.finished': 'Tickets have been quick resolved',
  'ticketViewPage.toolbar.quickClose.model.updating': 'Updating tickets',
  'ticketViewPage.toolbar.quickClose.model.error': 'Error updating tickets',
  'ticketViewPage.toolbar.quickClose.closeButton': 'Close',
  'menu.manage.helpSection.releaseNotes.tabText': 'Release Notes',
  'menu.manage.helpSection.releaseNotes.menuText': 'Release Notes',
  'releaseNotesPage.title': 'Release Notes',
  'notFoundPage.pageDevelopment': 'Under development',
  'notFoundPage.pageDevelopmentMessage': 'Sorry, this section is currently under development. Please check back later for exciting updates from our tech team',
  'notFoundPage.button.dashboard': 'Dashboard',
  'notFoundPage.button.ticket': 'My Tickets',
  'ticketViewPage.modal.bundle.childTicketField': 'Search Child Ticket',

  'ticketViewPage.toolbar.saveView': 'Save View',
  'ticketViewPage.toolbar.saveViewAs': 'Save New View As',
  'ticketViewPage.toolbar.saveViewAsDuplicate': 'Overwrite Existing View',
  'ticketViewPage.toolbar.modal.areYouSure': 'Are you sure?',
  'ticketViewPage.toolbar.modal.yes': 'Yes',
  'ticketViewPage.toolbar.modal.no': 'No',

  'dashboard.modal.button.Close': 'Close',
  'dashboard.modal.button.Add': 'Submit',
  'dashboard.modal.addContact': 'Create New Contact',
  'ticketViewPage.relatedTickets': 'Related Tickets',
  'createContact.success': 'Contact Created Successfully',
  'ticketViewPage.childTickets': 'Child Tickets',
  'ticketViewPage.parent': 'Parent',
  // below not translated

  "ticket.documents.show": "Show",
  "ticket.documents.hide": "Hide",
  "ticketViewPage.ticketSummary": "Ticket Summary",
  "ticketViewPage.ticketNumber": "Ticket Number",

  "TicketViewPage.toolbar.label.filters": "Filters",
  "TicketViewPage.toolbar.label.addContact": "Add Contact",


  // 
  'ticketViewPage.addNote': 'Add Note',
  'ticketViewPage.ticketUpdate': 'Ticket Update',
  'ticketViewPage.infoRequest': 'Info Request',
  'ticketViewPage.approval': 'Approval',
  'ticketViewPage.requestForAction': 'Action Request',
  'ticketViewPage.resolution': 'Resolution',
  'ticketviewPage.tab.overview': 'Overview',
  'ticketviewPage.tab.timeline': 'Timeline',
  'ticketviewPage.tab.formSummary': 'Form Summary',
  'ticketviewPage.tab.notes': 'Notes',
  'ticketviewPage.tab.tasks': 'Tasks',
  'ticketviewPage.tab.activity': 'Activity',
  'ticketViewPage.createdAt': 'Created At',
  'ticketViewPage.lastUpdate': 'Last Update',
  'ticketViewPage.priority': 'Priority',
  'ticketViewPage.ticketSLA': 'Ticket SLA',
  'ticketViewPage.requester': 'Requester',
  'ticketViewPage.ticketOwner': 'Ticket Owner',
  'ticketViewPage.ticketStatus': 'Ticket Status :',
  'ticketViewPage.ticketSummary': 'Ticket Summary',
  'ticketViewPage.ticketNumber': 'Ticket Number: #',
  'ticketViewPage.podAccess': 'Pod Access',
  'ticketViewPage.podAccessDesc': 'This pod will give you quick access to different sections of the ticket',
  'ticketViewPage.addContact': 'Add Contact',
  'ticketViewPage.editType': 'Edit Type',
  'ticketViewPage.assigneeOrRequester': 'Update Assignee/Requster',
  'ticketViewPage.contacts': 'Contacts',
  'ticketViewPage.contactDecs': 'This pod will display all the contacts related to this ticket',
  "menu.manage.internalSection.forms.tabText": "Forms",
  "menu.manage.internalSection.forms.menuText": "Forms",
  'ticketViewPage.toolbar.label.quickEdit': 'Quick Edit',
  'ticketViewPage.toolbar.editTicket.submitButton': 'Submit',
  'datatableViews.toolbar.currentView': 'Current View',
  'datatableViews.toolbar.loading': 'Loading...',
  'ticketviewPage.tab.overview': 'Overview',
  'ticketviewPage.tab.timeline': 'Timeline',
  'ticketviewPage.tab.notes': 'Notes',
  'ticketviewPage.tab.tasks': 'Tasks',
  'ticketviewPage.tab.formSummary': 'Form Summary',
  'ticketviewPage.tab.activity': 'Activity',
  'ticketViewPage.timeline.ticketCreated': 'Ticket Created',
  'ticketViewPage.timeline.ticketUpdated': 'Ticket Updated',
  'ticketViewPage.timeline.messageAdded': 'Message Added',
  'ticketViewPage.timeline.titleChange': 'Title Change',
  'ticketViewPage.organisations': 'Organizations',
  'ticketViewPage.ticketDevices': 'Ticket Devices',
  'ticketViewPage.ticketCC': 'Ticket CC',
  "menu.manage.companyContact.tabText": "Company",
  "menu.manage.companyContact.menuText": "Company",
  'ticketViewPage.rateYourService': 'Rate Your Service',
  'ticketViewPage.relatedTickets.desc': 'This pod will display all the related tickets',
  'ticketViewPage.ticketCC.desc': 'This pod will display all the related carbon copies',
  'ticketViewPage.ticketDevices.desc': 'This pod will display all the related devices',
  'ticketViewPage.ticketSLA.desc': 'This pod will display the SLA of this ticket',
  'ticketViewPage.documents.desc': 'This pod will display all the documents related to this ticket',
  'ticketViewPage.organisations.desc': 'This pod will display all the organisations related to this ticket',
  'ticketViewPage.initialResponse': 'Initial Response',
  'ticketViewPage.nextUpdate': 'Next Update',
  'ticketViewPage.estimatedResolution': 'Estimated Resolution',
  'ticketViewPage.resolution': 'Resolution',
  'ticketViewPage.relatedTickets': 'Related Tickets',
  'ticketViewPage.bundleTicket': 'Bundle Ticket',
  'ticketViewPage.shareTicket': 'Share Ticket',
  "ticketViewPage.contact": "Contact",
  "ticketViewPage.email": "Email",
  'ticketViewPage.rateYourServiceComment': "You're welcome to rate your service at any time during your session with Six Star Global.",
  'ticketViewPage.responseHistory': 'Response History',
  'ticketViewPage.initialComment': 'Initial Comment',
  'ticketViewPage.agentResponse': 'Agent Response',
  'ticketVIewPage.notClosed': 'Not Closed',
  'ticketViewPage.created': 'Created At',
  'ticketViewPage.firstResponse': 'First Response',
  'ticketViewPage.estimatedResolution': 'Estimated Resolution',
  'ticketViewPage.actualResolution': 'Actual Resolution',
  // below to be translated
  'ticketViewPage.infoDesc': 'This pod will display all the information related to this ticket',
  'ticketViewPage.ticketInfo': 'Ticket Info',
  'ticketViewPage.timeline.priorityChange': 'Priority Change',
  'ticketViewPage.timeline.statusChange': 'Status Change',
  'ticketViewPage.timeline.ticketResolved': 'Ticket Resolved',
  'ticketViewPage.addSummary': 'Add Summary',
  'ticketViewPage.notResponded': 'Not Responded',
  'ticketViewPage.noInitialComment': 'No Initial Comment',
  'ticketViewPage.noAgentResponse': 'No Agent Response',
  'ticketViewPage.makePrivate': 'Make Private',
  'ticketViewPage.makePublic': 'Make Public',
  'ticketViewPage.pod.team': 'Team',
  'ticketViewPage.team.desc': 'This pod will display all the team members related to this ticket',
};

export default pages;
