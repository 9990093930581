import React from 'react';
import { Box, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import ViewSelectorIcon from './GridToolbarViewSelectorIcon';

const GridToolbarViewSelectorItem = ({ id, name, default_flag, global, deleteView, setCurrentView, avaliableViews, currentView, setAnchorEl }) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    return (
        <MenuItem
            value={id}
            sx={{
                width: 300,
                userSelect: 'none',
                height: 45,
            }}
            selected={currentView.id === id}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <Box
                onClick={() => {
                    setCurrentView(avaliableViews.find((view) => view.id === id))
                    setAnchorEl(false)
                }}
                sx={{
                    flexGrow: 1,
                }}
            >
                {name}
            </Box>
            <Box
                onClick={() => {
                    if (default_flag) return;
                    if (global) return;
                    setShowDeleteModal(true);
                }}
            >
                {default_flag ? (
                    <ViewSelectorIcon
                        icon={['fas', 'star']}
                        disabled={true}
                    />
                ) : (
                    <>
                        {!global && (
                            <ViewSelectorIcon
                                icon={['fas', 'trash']}
                            />
                        )}
                    </>
                )}
            </Box>
            <Dialog
                open={showDeleteModal}
                onClose={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowDeleteModal(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete View?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete view {name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            deleteView(id);
                            setShowDeleteModal(false);
                            setAnchorEl(false);
                        }}
                        color="error"
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => setShowDeleteModal(false)}
                        autoFocus
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </MenuItem>
    )
}

export default GridToolbarViewSelectorItem;