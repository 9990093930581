import { gql } from "@apollo/client";

export const graphQLMutations = () => {
    const INSERT_AUDIT_LOG = gql`
    mutation addAuditLog(
        $action: String!,
        $person_id: uuid,
        $metadata: json,
    ){
        insert_audit_action_log(
            objects: {
                action: $action,
                person_id: $person_id,
                metadata: $metadata,
            }
        ){
            returning {
                id
              }
        }
    }
    `

    return {
        INSERT_AUDIT_LOG
    }
}

export default graphQLMutations