const login = {
  "login.title": "SIX STAR GLOBAL",
  "login.headline": "Wiodąca światowa firma oferująca zarządzane usługi IT.",
  "login.signInText": "Sign Into Six Star: Manage",
  "login.topCallToActionTitle": "Nie jesteś częścią Six Star?",
  "login.topCallToActionLink": "Porozmawiajmy",
  "loginPage.input.email": "E-mail",
  "loginPage.input.password": "Hasło",
  "loginPage.input.mfa": "Kod MFA",
  "loginPage.rememberMe": "Zapamiętaj mnie",
  "loginPage.button.login": "Zaloguj się",
  "loginPage.button.forgotPassword": "Zapomniałeś hasła?",
  "loginPage.apiMfaInProgress": "Wprowadź swój kod MFA",
  "login.forgotPasswordText": "Zapomniałeś hasła",
  "loginPage.apiMultipleUsers": "Błąd konta użytkownika, skontaktuj się z Six Star",
  "loginPage.apiNoPassword": "Wprowadź hasło",
  "loginPage.apiNoUser": "Wprowadź adres e-mail",
  "loginPage.apiInvalidTOTP": "Nieprawidłowy kod MFA",
  "loginPage.apiInvalidPassword": "Nieprawidłowe hasło",
  "login.sso.starting": "Rozpoczynamy Single Sign On",
  "loginPage.button.normalSignIn": "Logowanie za pomocą nazwy użytkownika/hasła",
  "loginPage.text.msssoLoading": "Ładowanie...",
  "loginPage.label.msssoSignIn": "Zaloguj się przez Microsoft",
  "loginPage.label.msssoSignInEmail": "Adres e-mail Microsoft",
  "loginPage.text.msssoError": "Błąd podczas logowania",

  "loginPage.emailPrompt": "Wprowadź poniżej swój adres e-mail, aby zresetować hasło.",
  "loginPage.ForgotPassword.GoBack": "Wróć",
  "loginPage.ForgotPassword.Submit": "Prześlij",
  "loginPage.label.email": "E-mail",
  "loginPage.helpIcon": "Pomoc",
  "loginPage.label.fullName": "Pełna nazwa",
  "loginPage.label.password": "Hasło",
  "loginPage.label.confirmPassword": "Potwierdź hasło",
  "loginPage.register.button.goback": "Wróć",
  "loginPage.register.button.register": "Zarejestruj",
  "loginPage.register.button.signUp": "Zaloguj się"

};
export default login;
