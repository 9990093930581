const menu = {
  "menu.manage.dashboard.tabText": "Tableau de bord",
  "menu.manage.dashboard.menuText": "Tableau de bord",
  "menu.manage.supportSection.text": "Support",
  "menu.manage.supportSection.menuText": "Support",
  "menu.manage.supportSection.dashboard.tabText": "Tableau de bord",
  "menu.manage.supportSection.dashboard.menuText": "Tableau de bord",
  "menu.manage.supportSection.newRequest.tabText": "Nouvelle demande",
  "menu.manage.supportSection.newRequest.menuText": "Nouvelle demande",
  "menu.manage.supportSection.myTicketsOverview.tabText":
    "Aperçu de mes tickets",
  "menu.manage.supportSection.myTicketsOverview.menuText":
    "Aperçu de mes tickets",
  "menu.manage.supportSection.approvals.tabText": "Approbations",
  "menu.manage.supportSection.approvals.menuText": "Approbations",
  "menu.manage.sixstarMeet.tabText": "Rencontre Six Étoiles",
  "menu.manage.sixstarMeet.menuText": "Rencontre Six Étoiles",
  "menu.manage.manageSixStarConnect.tabText": "Connect Six Étoiles",
  "menu.manage.manageSixStarConnect.menuText": "Connect Six Étoiles",
  "menu.manage.manageManageSection.text": "Gérer",
  "menu.manage.manageManageSection.menuText": "Gérer",
  "menu.manage.manageManageSection.dashboard.tabText": "Tableau de bord",
  "menu.manage.manageManageSection.dashboard.menuText": "Tableau de bord",
  "menu.manage.manageSection.text": "Gérer",
  "menu.manage.manageSection.menuText": "Gérer",
  "menu.manage.manageSection.documents.tabText": "Documents",
  "menu.manage.manageSection.documents.menuText": "Documents",
  "menu.manage.manageSection.securityReports.tabText": "Rapports de sécurité",
  "menu.manage.manageSection.securityReports.menuText": "Rapports de sécurité",
  "menu.manage.manageSection.serviceReports.tabText": "Rapports de service",
  "menu.manage.manageSection.serviceReports.menuText": "Rapports de service",
  "menu.manage.manageSection.licenceManagement.tabText": "Gestion des licences",
  "menu.manage.manageSection.licenceManagement.menuText":
    "Gestion des licences",
  "menu.manage.manageSection.assetManagement.tabText": "Gestion des actifs",
  "menu.manage.manageSection.assetManagement.menuText": "Gestion des actifs",
  "menu.manage.manageSection.upcomingExpiries.tabText": "Expirations à venir",
  "menu.manage.manageSection.upcomingExpiries.menuText": "Expirations à venir",
  "menu.manage.manageSection.bookACallWithYourAccountManager.tabText":
    "Réserver un appel avec votre gestionnaire de compte",
  "menu.manage.manageSection.bookACallWithYourAccountManager.menuText":
    "Réserver un appel avec votre gestionnaire de compte",
  "menu.manage.financeSection.text": "Finance",
  "menu.manage.financeSection.menuText": "Finance",
  "menu.manage.financeSection.dashboard.tabText": "Tableau de bord",
  "menu.manage.financeSection.dashboard.menuText": "Tableau de bord",
  "menu.manage.financeSection.ordersQuotesInvoices.tabText":
    "Commandes/Devis/Factures",
  "menu.manage.financeSection.ordersQuotesInvoices.menuText":
    "Commandes/Devis/Factures",
  "menu.manage.financeSection.creditApplication.tabText": "Demande de crédit",
  "menu.manage.financeSection.creditApplication.menuText": "Demande de crédit",
  "menu.manage.helpSection.text": "Aide",
  "menu.manage.helpSection.menuText": "Aide",
  "menu.manage.helpSection.contactUs.tabText": "Contactez-nous",
  "menu.manage.helpSection.contactUs.menuText": "Contactez-nous",
  "menu.manage.helpSection.giveFeedback.tabText": "Donner un avis",
  "menu.manage.helpSection.giveFeedback.menuText": "Donner un avis",
  "menu.manage.helpSection.guides.tabText": "Guides",
  "menu.manage.helpSection.guides.menuText": "Guides",
  "menu.manage.helpSection.customerForms.tabText":
    "Formulaires pour les clients",
  "menu.manage.helpSection.customerForms.menuText":
    "Formulaires pour les clients",
  "menu.manage.engineerSection.text": "Ingénieur",
  "menu.manage.engineerSection.menuText": "Ingénieur",
  "menu.manage.engineerSection.myTasks.tabText": "Mes tâches",
  "menu.manage.engineerSection.myTasks.menuText": "Mes tâches",
  "menu.manage.engineerSection.ticketBoard.tabText": "Tableau des tickets",
  "menu.manage.engineerSection.ticketBoard.menuText": "Tableau des tickets",
  "menu.manage.engineerSection.triageTickets.tabText": "Tickets de triage",
  "menu.manage.engineerSection.triageTickets.menuText": "Tickets de triage",
  "menu.manage.engineerSection.priorityTickets.tabText": "Tickets prioritaires",
  "menu.manage.engineerSection.priorityTickets.menuText":
    "Tickets prioritaires",
  "menu.manage.engineerSection.escalations.tabText": "Escalades",
  "menu.manage.engineerSection.escalations.menuText": "Escalades",
  "menu.manage.internalSection.text": "Interne",
  "menu.manage.internalSection.menuText": "Interne",
  "menu.manage.internalSection.orderTracker.tabText": "Suivi de commande",
  "menu.manage.internalSection.orderTracker.menuText": "Suivi de commande",
  "menu.manage.internalSection.resourceTracker.tabText": "Suivi des ressources",
  "menu.manage.internalSection.resourceTracker.menuText":
    "Suivi des ressources",
  "menu.manage.internalSection.knowledgeBase.tabText": "Base de connaissances",
  "menu.manage.internalSection.knowledgeBase.menuText": "Base de connaissances",
  "menu.manage.internalSection.twoFactor.tabText": "Double authentification",
  "menu.manage.internalSection.twoFactor.menuText": "Double authentification",
  "menu.manage.internalSection.internal.tabText": "Interne",
  "menu.manage.internalSection.internal.menuText": "Interne",
  "menu.manage.internalSection.expenses.tabText": "Dépenses",
  "menu.manage.internalSection.expenses.menuText": "Dépenses",
  "menu.manage.userSection.text": "Utilisateur",
  "menu.manage.userSection.menuText": "Utilisateur",
  "menu.manage.userSection.createContact.tabText": "Créer un contact",
  "menu.manage.userSection.createContact.menuText": "Créer un contact",
  "menu.manage.userSection.contactSearch.tabText": "Recherche de contact",
  "menu.manage.userSection.contactSearch.menuText": "Recherche de contact",
  "menu.manage.userSection.zoomPresence.tabText": "Présence Zoom",
  "menu.manage.userSection.zoomPresence.menuText": "Présence Zoom",
  "menu.manage.userSection.zoomCallQueues.tabText":
    "Files d'attente d'appels Zoom",
  "menu.manage.userSection.zoomCallQueues.menuText":
    "Files d'attente d'appels Zoom",
  "menu.manage.userSection.mergeContacts.tabText": "Fusionner les contacts",
  "menu.manage.userSection.mergeContacts.menuText": "Fusionner les contacts",
  "menu.manage.adminSection.text": "Admin",
  "menu.manage.adminSection.menuText": "Admin",
  "menu.manage.adminSection.dashboard.tabText": "Tableau de bord",
  "menu.manage.adminSection.dashboard.menuText": "Tableau de bord",
  "menu.manage.adminSection.impersonate.tabText": "Usurper",
  "menu.manage.adminSection.impersonate.menuText": "Usurper",
  "menu.manage.supportSection.newRequestDashboard.tabText": "Nouvelle demande",
  "menu.manage.supportSection.newRequestDashboard.menuText": "Nouvelle demande",
  "menu.manage.supportSection.newRequestForm.tabText.supportRequest":
    "Demande de support",
  "menu.manage.supportSection.newRequestForm.tabText.serviceRequest":
    "Demande de service",
  "menu.manage.supportSection.newRequestForm.tabText.procurementRequest":
    "Demande d'achat",
  "menu.manage.supportSection.newRequestForm.tabText.internalRequest":
    "Demande interne",
  "menu.manage.supportSection.newRequestForm.tabText.passwordReset":
    "Réinitialisation du mot de passe",
  "menu.manage.supportSection.newRequestForm.tabText.newStarter":
    "Nouveau départ / Changement de rôle",
  "menu.manage.supportSection.newRequestForm.tabText.leaverRequest":
    "Demande de départ",
  "menu.manage.supportSection.newRequestForm.tabText.engineerVisit":
    "Visite d'ingénieur",
  "menu.manage.supportSection.newRequestForm.tabText.developmentRequest":
    "Demande de développement",
  "menu.manage.engineerSection.ticketViewPage.tabText": "Ticket# {id}",
  "menu.manage.engineerSection.ticketViewPage.menuText": "Ticket {id}",
  "menu.manage.engineerSection.ticketsBoard.tabText": "Tableau des tickets",
  "menu.manage.engineerSection.ticketsBoard.menuText": "Tableau des tickets",
  "menu.manage.supportSection.newRequestForm.tabText": "{id}",
  "menu.manage.supportSection.newRequestForm.menuText": "{id}",
  "menu.manage.engineerSection.drive.tabText": "Six Star Drive",
  "menu.manage.engineerSection.drive.menuText": "Six Star Drive",
  "menu.manage.engineerSection.reports.tabText": "Rapports",
  "menu.manage.engineerSection.reports.menuText": "Rapports",
  "menu.manage.engineerSection.companyProfile.tabText": "Mon organisation",
  "menu.manage.engineerSection.companyProfile.menuText": "Mon organisation",
  "menu.manage.engineerSection.chatGPT.tabText": "Chat GPT",
  "menu.manage.engineerSection.chatGPT.menuText": "Chat GPT",
  "menu.manage.userSection.editContact.tabText": "Modifier le contact",
"menu.manage.userSection.editContact.menuText": "Modifier le contact",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.tabText": "Bulletins de paie",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.menuText": "Bulletins de paie",
};
export default menu;
