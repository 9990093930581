import React from "react";
import { useNavigate } from 'react-router-dom'
import TranslationComponent from "components/Global/Translation";
import MenuItem from "@mui/material/MenuItem";

const MenuNavigationItem = (props) => {
    const {to, tag, disabled} = props
    const navigate = useNavigate()

    return (
            <MenuItem 
            disabled={disabled} 
            onClick={() => navigate(to)}>
              <TranslationComponent tag={tag}/>
            </MenuItem>   
    )
}

export default MenuNavigationItem