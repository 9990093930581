import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findMenuItem } from "utils/menuHelper";
import {
  selectMenuItem,
  showHideSearch,
} from "containers/App/Redux/menu/actions";
import HeaderTabs from "components/Layout/Header/HeaderTabs";
import HeaderSubTabs from "components/Layout/Header/HeaderSubTabs";
import HeaderBanner from "components/Layout/Header/HeaderBanner";
import QuickCreateTicket from "Modals/QuickCreateTicket";
import { useNavigate } from 'react-router-dom';
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.isClosingOpenView = false;
    this.state = {
      showQuickCreateModal: false,
    };
    this.isClosingOpenView = false;
    this.openQuickCreateModal = this.openQuickCreateModal.bind(this);
  }

  openQuickCreateModal() {
    this.setState({ showQuickCreateModal: true });
  }
  
  closeQuickCreateModal() {
    this.setState({ showQuickCreateModal: false });
  }

  openQuickCreateModal = () => {
    this.setState({ showQuickCreateModal: true });
  };
 

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.isClosingOpenView = false;
    }
  }

  handleTabChange = (event, value, topLevel = false) => {
    if (this.isClosingOpenView) {
      return;
    }

    const { menu, openViews, activeSubMenuItem } = this.props;

    let result = findMenuItem(menu, "index", value);
    const { foundMenuItem } = result;

    let resultTwo = findMenuItem(openViews, "index", value);

    const foundOpenViewItem = resultTwo.foundMenuItem;

    if (foundMenuItem) {
      // Select menu item

      this.props.dispatch(
        selectMenuItem(
          foundMenuItem,
          foundOpenViewItem,
          openViews,
          activeSubMenuItem,
          topLevel
        )
      );
    }
  };

  handleCloseView = (menuItem) => {
    const isParentItem = menuItem.children;
    this.isClosingOpenView = true;
    this.props.handleCloseView(menuItem, menuItem.children, isParentItem);
  };

  getCalculatedOpenViews = () => {
    const { openViews } = this.props;
    const parentOpenViews = [];

    openViews.forEach((item) => {
      const menuItem = item;

      if (menuItem.parent) {
        const { id, index, url } = menuItem;
        const { parentId, parentText } = menuItem.parent;
        const parentExits = parentOpenViews.find(
          (parent) => parent.parentId === parentId
        );

        if (!parentExits) {
          parentOpenViews.push({
            id,
            url,
            index,
            parentId,
            children: [{ ...menuItem, firstChildId: menuItem.id }],
            text: parentText,
          });
        } else {
          parentExits.children.push({
            ...menuItem,
            firstChildId: parentExits.id,
          });
        }
      } else {
        parentOpenViews.push({ ...menuItem, parentId: menuItem.id });
      }
    });

    return parentOpenViews;
  };

  getCalculatedSelectedMenuItem = (calculatedOpenViews) => {
    const { selectedMenuItem } = this.props;

    const view =
      calculatedOpenViews.find(
        (item) =>
          item.parentId ===
          (selectedMenuItem.parent && selectedMenuItem.parent.parentId)
      ) || selectedMenuItem;
    // console.log('view', view)
    return view;
  };

  handleShowSearch = (value) => {
    this.props.dispatch(showHideSearch(value));
  };

  handlePlusClick = () => {
    
    const { user, openQuickCreateModal } = this.props;
    //console.log("user", user?.user?.roles);
    // if statement, if user is agent or engineer, plus button brings up the quick create
    
    if (user?.user?.roles.includes("agent") || user?.user?.roles.includes("engineer")) {
     // console.log("user is agent or engineer");
      openQuickCreateModal();
     
    } else {
      // if user is user then navigate to form
    //  return <FormLoader />;
    //using use navigate to go to form
    const navigate = useNavigate();
    navigate("/support/new-request")
     //("/support/new-request");
    }
  };

  handleCloseQuickCreateModal = () => {
    this.setState({ showQuickCreateModal: false });
  };
  

  render() {
    const {
      selectedMenuItem,
      drawerIsOpen,
      handleDrawerToggle,
      showHeaderTabs,
      showSearch,
      user,
      handleModal,
    } = this.props;
    const calculatedOpenViews = this.getCalculatedOpenViews();
    const calculatedSelectedMenuItem =
      this.getCalculatedSelectedMenuItem(calculatedOpenViews);
    return (
      <div>
        <HeaderTabs
          drawerIsOpen={drawerIsOpen}
          selectedMenuItemHasChildren={
            showHeaderTabs && calculatedSelectedMenuItem.children !== undefined
          }
          openViews={showHeaderTabs ? calculatedOpenViews : []}
          showSearch={showSearch}
          onShowSearch={this.handleShowSearch}
          selectedMenuItem={calculatedSelectedMenuItem}
          handleDrawerToggle={handleDrawerToggle}
          handleCloseView={this.handleCloseView}
          handleTabChange={this.handleTabChange}
          user={user}
          location={this.props.location}
          handleModal={this.handlePlusClick} 
        />
       {/* {this.state.showQuickCreateModal && (
        
      )} */}
        {/*<HeaderBanner*/}
        {/*  drawerIsOpen={drawerIsOpen}*/}
        {/*  selectedMenuItem={calculatedSelectedMenuItem}*/}
        {/*  selectedSubMenuItem={selectedMenuItem}*/}
        {/*  showHeaderTabs={showHeaderTabs}*/}
        {/*/>*/}
        {showHeaderTabs && calculatedSelectedMenuItem.children ? (
          <HeaderSubTabs
            drawerIsOpen={drawerIsOpen}
            selectedMenuItem={selectedMenuItem}
            openViews={calculatedSelectedMenuItem.children}
            handleCloseView={this.handleCloseView}
            handleTabChange={this.handleTabChange}
          />
        ) : null}
      </div>
    );
  }
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  drawerIsOpen: PropTypes.bool.isRequired,
  handleCloseView: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  location: PropTypes.any.isRequired,
  menu: PropTypes.array.isRequired,
  openViews: PropTypes.array.isRequired,
  selectedMenuItem: PropTypes.object.isRequired,
  activeSubMenuItem: PropTypes.object.isRequired,
  showHeaderTabs: PropTypes.bool.isRequired,
  showSearch: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    menu: state.menu.menu,
    currentMenu: state.menu.currentMenu,
    openViews: state.menu.openViews[state.menu.currentMenu],
    selectedMenuItem: state.menu.selectedMenuItem[state.menu.currentMenu],
    showHeaderTabs: state.menu.showHeaderTabs,
    showSearch: state.menu.showSearch,
    activeSubMenuItem: state.menu.activeSubMenuItem[state.menu.currentMenu],
    user: state.user,
    onOpenQuickCreateModal: PropTypes.func.isRequired,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
