const login = {
  "login.title": "SIX STAR GLOBAL",
  "login.headline": "La société leader mondiale en services gérés de TI.",
  "login.signInText": "Sign Into Six Star: Manage",
  "login.topCallToActionTitle": "Vous ne faites pas partie de Six Star?",
  "login.topCallToActionLink": "Discutons",
  "loginPage.input.email": "E-mail",
  "loginPage.input.password": "Mot de passe",
  "loginPage.input.mfa": "Code MFA",
  "loginPage.rememberMe": "Se souvenir de moi",
  "loginPage.button.login": "Se connecter",
  "loginPage.button.forgotPassword": "Mot de passe oublié?",
  "loginPage.apiMfaInProgress": "Veuillez entrer votre code MFA",
  "login.forgotPasswordText": "Mot de passe oublié",
  "loginPage.apiMultipleUsers": "Erreur de compte utilisateur, contactez Six Star",
  "loginPage.apiNoPassword": "Veuillez entrer un mot de passe",
  "loginPage.apiNoUser": "Veuillez entrer une adresse e-mail",
  "loginPage.apiInvalidTOTP": "Code MFA invalide",
  "loginPage.apiInvalidPassword": "Mot de passe invalide",
  "login.sso.starting": "Démarrage de la connexion unique",
  "loginPage.button.normalSignIn": "Connexion avec nom d'utilisateur/mot de passe",
  "loginPage.text.msssoLoading": "Chargement...",
  "loginPage.label.msssoSignIn": "Se connecter avec Microsoft",
  "loginPage.label.msssoSignInEmail": "Adresse e-mail Microsoft",
  "loginPage.text.msssoError": "Erreur lors de la connexion",

  "loginPage.emailPrompt": "Entrez votre adresse e-mail ci-dessous pour réinitialiser votre mot de passe.",
  "loginPage.ForgotPassword.GoBack": "Retour",
  "loginPage.ForgotPassword.Submit": "Soumettre",
  "loginPage.label.email": "E-mail",
  "loginPage.helpIcon": "Aide",
  "loginPage.label.fullName": "Nom complet",
  "loginPage.label.password": "Mot de passe",
  "loginPage.label.confirmPassword": "Confirmer le mot de passe",
  "loginPage.register.button.goback": "Retour",
  "loginPage.register.button.register": "S'inscrire",
  "loginPage.register.button.signUp": "Se connecter"

};
export default login;
