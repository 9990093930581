import { Typography } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Translation from 'utils/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ExpiringLicences from './ExpiringLicences';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faTableCells } from '@fortawesome/free-solid-svg-icons';
import SidePanel from './SidePanel';
import CircularProgress from '@mui/material/CircularProgress';
import Summary from './Summary';
import OpenTickets from './OpenTickets';


const OverviewTab = (company) => {

  return (
    <>
      <Box
        sx={{ 
          overflowY: 'scroll',
          marginTop: 2,
        }}
      >
        {/* Summary box below */}
    
        <Summary company={company} />

        <OpenTickets company={company} />
  
       
      </Box>
    </>
  );
};

export default OverviewTab;
