import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Typography, TextField, Button } from "@mui/material";
import Box from "@mui/material/Box";
import { GridColDef } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useSearchParams } from "react-router-dom";

function StyledTextField(props) {
  const { label, feedBack, setFeedBack, identifier } = props;
  return (
    <TextField
      label={label}
      multiline={true}
      fullWidth
      minRows={6}
      sx={{
        backgroundColor: "#ffff",
        borderRadius: "10px",
        boxShadow: "0px 2px 8px rgba(0,0,0,0.32)",
      }}
      onChange={(event) => {
        // console.log(event);
        setFeedBack({ ...feedBack, [label]: event.target.value });
      }}
    />
  );
}
function FormStackLoader(props) {
  const {
    classes,
    defaultHeight,
    heightBuffer,
    formURL,
    dynamicHeight,
    disableScrolling,
    pageTitle,
    hiddenFields,
    loadedHandler,
    errorHandler,
  } = props;
  const [iframeHeight, setIframeHeight] = useState(defaultHeight ?? 400);
  const [generatedURL, setGeneratedURL] = useState("");
  const { user } = useSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(async () => {
    // console.log("userAgent", navigator?.userAgent ?? "undefined");
    // console.log("appName", navigator?.appName ?? "undefined");
    // console.log("appVersion", navigator?.appVersion ?? "undefined");
    // console.log("appCodeName", navigator?.appCodeName ?? "undefined");
    // console.log("platform", navigator?.platform ?? "undefined");
    // console.log("product", navigator?.product ?? "undefined");
    // console.log("productSub", navigator?.productSub ?? "undefined");
    // console.log("vendor", navigator?.vendor ?? "undefined");
    // console.log("vendorSub", navigator?.vendorSub ?? "undefined");
    // console.log("userAgentData", navigator?.userAgentData ?? "undefined");
    // console.log("language", navigator?.language ?? "undefined");
    // console.log("languages", navigator?.languages ?? "undefined");
    // console.log("onLine", navigator?.onLine ?? "undefined");
    // console.log("cookieEnabled", navigator?.cookieEnabled ?? "undefined");
    // console.log("doNotTrack", navigator?.doNotTrack ?? "undefined");
    // console.log(
    //   "hardwareConcurrency",
    //   navigator?.hardwareConcurrency ?? "undefined"
    // );
    // console.log("maxTouchPoints", navigator?.maxTouchPoints ?? "undefined");
    // console.log("mediaDevices", navigator?.mediaDevices ?? "undefined");
    // console.log("mimeTypes", navigator?.mimeTypes ?? "undefined");
    // console.log("permissions", navigator?.permissions ?? "undefined");
    // console.log("plugins", navigator?.plugins ?? "undefined");
    // console.log("serviceWorker", navigator?.serviceWorker ?? "undefined");
    // console.log("storage", navigator?.storage ?? "undefined");
    // console.log("screenWidth", window?.screen?.width ?? "undefined");
    // console.log("screenHeight", window?.screen?.height ?? "undefined");
    // console.log("screenColorDepth", window?.screen?.colorDepth ?? "undefined");
    // console.log("screenPixelDepth", window?.screen?.pixelDepth ?? "undefined");
    // console.log(
    //   "screenOrientation",
    //   window?.screen?.orientation ?? "undefined"
    // );
    // console.log("browserWidth", window?.innerWidth ?? "undefined");
    // console.log("browserHeight", window?.innerHeight ?? "undefined");

    if (pageTitle) {
      document.title = `${document.title} - ${pageTitle}`;
    }
    if (hiddenFields) {
      let newURL = await generateHiddenFields();
      setGeneratedURL(newURL);
    } else {
      setGeneratedURL(formURL);
    }
    const handleHeightChange = (event) => {
      if (event.data.type === "heightChange") {
        const height = event.data.data;
        if (dynamicHeight) {
          setIframeHeight(height + (heightBuffer ?? 0));
        }
      }
    };
    window.addEventListener("message", handleHeightChange);
    return () => window.removeEventListener("message", handleHeightChange);
  }, []);

  const generateHiddenFields = async () => {
    let generatedURL = formURL;
    try {
      let bID,
        userID,
        companyID,
        firstName,
        lastName,
        reqName,
        reqCompany,
        reqID,
        reqEmail,
        reqEmail2,
        email,
        phone,
        screenSize,
        screenX,
        screenY,
        windowX,
        windowY,
        windowSize,
        orientation,
        language,
        languages,
        platform,
        cookiesEnabled,
        mobile,
        brands,
        route = "";
      generatedURL = generatedURL + "?";
      let prefix = "ssghidden_";
      bID = prefix + "board";
      userID = prefix + "userid=" + user?.id ?? "";
      if (user?.id !== undefined) generatedURL += userID + "&";
      companyID = prefix + "companyid=" + user?.organization_id ?? "";
      if (user?.organization_id !== undefined) generatedURL += companyID + "&";
      firstName =
        "your_name-first=" + user?.first_name ??
        "" + "&" + "requestor_name-first=" + user?.first_name ??
        "";
      if (user?.first_name !== undefined) generatedURL += firstName + "&";
      lastName =
        "your_name-last=" + user?.last_name ??
        "" + "&" + "requestor_name-last=" + user?.last_name ??
        "";
      if (user?.last_name !== undefined) generatedURL += lastName + "&";
      reqName =
        prefix + "requestername=" + user?.first_name ??
        "" + " " + user?.last_name ??
        "";
      if (user?.first_name !== undefined && user?.last_name !== undefined)
        generatedURL += reqName + "&";
      reqCompany =
        prefix + "requestercompany=" + user?.companyName ??
        "" + "&" + "company_name=" + user?.companyName ??
        "";
      if (user?.companyName !== undefined) generatedURL += reqCompany + "&";
      reqID = prefix + "requesterid=" + user?.id ?? "";
      if (user?.id !== undefined) generatedURL += reqID + "&";
      reqEmail = prefix + "requesteremail=" + user?.emailAddress ?? "";
      if (user?.emailAddress !== undefined) generatedURL += reqEmail + "&";
      reqEmail2 = "employee_name=" + user?.emailAddress ?? "";
      if (user?.emailAddress !== undefined) generatedURL += reqEmail2 + "&";
      email =
        "your_email_address=" + user?.emailAddress ??
        "" + "&" + "requestor_email=" + user?.emailAddress ??
        "";
      if (user?.emailAddress !== undefined) generatedURL += email + "&";
      phone =
        "your_phone_number=" + user?.phoneNumber ??
        "" + "&" + "requestor_phone_number=" + user?.phoneNumber ??
        "";
      if (user?.phoneNumber !== undefined) generatedURL += phone + "&";
      screenX = window?.screen?.width ?? "";
      screenY = window?.screen?.height ?? "";
      screenSize = prefix + "screenSize=" + screenX + "x" + screenY;
      if (window?.screen !== undefined) generatedURL += screenSize + "&";
      windowX = window?.innerWidth ?? "";
      windowY = window?.innerHeight ?? "";
      windowSize = prefix + "windowSize=" + windowX + "x" + windowY;
      if (window?.innerWidth !== undefined) generatedURL += windowSize + "&";
      orientation =
        prefix + "orientation=" + window?.screen?.orientation?.type ?? "";
      if (window?.screen?.orientation !== undefined)
        generatedURL += orientation + "&";
      language = prefix + "language=" + navigator?.language ?? "";
      // if (navigator?.language !== undefined) generatedURL += language + "&";
      // languages = prefix + "languages=" + navigator?.languages ?? "";
      platform = prefix + "platform=" + navigator?.platform ?? "";
      if (navigator?.platform !== undefined) generatedURL += platform + "&";
      cookiesEnabled =
        prefix + "cookiesEnabled=" + navigator?.cookieEnabled ?? "";
      if (navigator?.cookieEnabled !== undefined)
        generatedURL += cookiesEnabled + "&";

      mobile = prefix + "mobile=" + navigator?.userAgentData.mobile ?? "";
      // console.log(mobile);
      if (navigator?.userAgentData.mobile !== undefined)
        generatedURL += mobile + "&";

      brands =
        prefix + "brands=" + JSON.stringify(navigator?.userAgentData.brands) ??
        "";
      if (navigator?.userAgentData.brands !== undefined)
        generatedURL += brands + "&";

      searchParams.forEach((value, key) => {
        if (key === "route") {
          // console.log(value);
          if (value === "/") {
            route = prefix + "route=" + "dashboard";
          }
        } else {
          route = prefix + "route=" + value;
        }
      });
      if (route !== undefined) {
        generatedURL += route + "&";
      }

      if (generatedURL.endsWith("&")) {
        generatedURL = generatedURL.slice(0, -1);
      }
    } catch (error) {
      // console.log(error);
    }
    return generatedURL;
  };

  const [value, setValue] = useState("");
  const [feedBack, setFeedBack] = useState({});
  const [formStage, setFormStage] = useState(0);
  const feedbackTitleDisplay = true;

  useEffect(() => {}, [value]);
  useEffect(() => {
    // console.log(feedBack);
  }, [feedBack]);

  const handleRadioChange = (event) => {
    if (event.target.value === value) {
      // setValue("");
    } else {
      setValue(event.target.value);
      switch (event.target.value) {
        case "comment":
          setFormStage(1);
          break;
        case "request":
          setFormStage(2);
          break;
        case "bug":
          setFormStage(5);
          break;
        case "other":
          setFormStage(8);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = () => {
    let returnObject = {};
    switch (value) {
      case "comment":
        returnObject = {
          FormID: "5435091",
          field_150913367: "comment",
          field_150913371: feedBack["Description"],
        };
        break;
      case "request":
        break;
      case "bug":
        break;
      case "other":
        break;
      default:
        break;
    }

    // console.log(returnObject);
    // fetchData(returnObject).then((r) => {
    //   // console.log(r);
    // });
  };

  async function fetchData(obj) {
    let response = await fetch(
      "https://ki8keu4kbb.execute-api.eu-west-2.amazonaws.com/default/ssg-formstack-submit",
      {
        method: "POST",
        body: JSON.stringify(obj),
      }
    );
    return await response.json();
  }

  return (
    // <Box textAlign="-webkit-center">
    //   {formStage === 0 ? (
    //     <FormControl focused={true}>
    //       <FormLabel>
    //         <Typography variant="h6">Feedback Type</Typography>
    //       </FormLabel>
    //       <Box
    //         sx={{
    //           backgroundColor: "#ffff",
    //           borderRadius: "5px",
    //           boxShadow: "0px 2px 8px rgba(0,0,0,0.32)",
    //           marginBottom: "1rem",
    //           marginTop: "1rem",
    //           padding: "1rem",
    //         }}
    //       >
    //         <RadioGroup value={value}>
    //           <FormControlLabel
    //             value="comment"
    //             control={<Radio onClick={handleRadioChange} />}
    //             label="General Comment"
    //           />
    //           <FormControlLabel
    //             value="request"
    //             control={<Radio onClick={handleRadioChange} />}
    //             label="Feature Request"
    //           />
    //           <FormControlLabel
    //             value="bug"
    //             control={<Radio onClick={handleRadioChange} />}
    //             label="Technical Issue / Bug"
    //           />
    //           <FormControlLabel
    //             value="other"
    //             control={<Radio onClick={handleRadioChange} />}
    //             label="Other"
    //           />
    //         </RadioGroup>
    //       </Box>{" "}
    //     </FormControl>
    //   ) : formStage === 1 ? (
    //     <Box
    //       display="flex"
    //       flexDirection="column"
    //       maxWidth="50%"
    //       // alignItems="center"
    //     >
    //       {feedbackTitleDisplay && (
    //         <Typography variant="h6" marginBottom="1rem" alignSelf="center">
    //           General Comment
    //         </Typography>
    //       )}
    //       <StyledTextField
    //         label="Description"
    //         feedBack={feedBack}
    //         setFeedBack={setFeedBack}
    //       />
    //       <Box
    //         display="flex"
    //         flexDirection="row"
    //         marginTop="1rem"
    //         justifyContent="space-between"
    //       >
    //         <Button
    //           onClick={() => setFormStage(0)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-left" />
    //         </Button>
    //         <Button
    //           onClick={() => handleSubmit()}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <Box>Submit</Box>
    //         </Button>
    //         <Button
    //           onClick={() => setFormStage(4)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //           disabled={true}
    //         >
    //           <FontAwesomeIcon icon="arrow-right" />
    //         </Button>
    //       </Box>
    //     </Box>
    //   ) : formStage === 2 ? (
    //     <Box
    //       display="flex"
    //       flexDirection="column"
    //       maxWidth="50%"
    //       // alignItems="center"
    //     >
    //       {feedbackTitleDisplay && (
    //         <Typography variant="h6" marginBottom="1rem" alignSelf="center">
    //           Feature Request
    //         </Typography>
    //       )}
    //       <StyledTextField
    //         label="What do you want to have happen?"
    //         feedBack={feedBack}
    //         setFeedBack={setFeedBack}
    //       />
    //       <Box
    //         display="flex"
    //         flexDirection="row"
    //         marginTop="1rem"
    //         justifyContent="space-between"
    //       >
    //         <Button
    //           onClick={() => setFormStage(0)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-left" />
    //         </Button>
    //         <Button
    //           onClick={() => handleSubmit()}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //           disabled={true}
    //         >
    //           <Box>Submit</Box>
    //         </Button>
    //         <Button
    //           onClick={() => setFormStage(3)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-right" />
    //         </Button>
    //       </Box>
    //     </Box>
    //   ) : formStage === 3 ? (
    //     <Box
    //       display="flex"
    //       flexDirection="column"
    //       maxWidth="50%"
    //       // alignItems="center"
    //     >
    //       {feedbackTitleDisplay && (
    //         <Typography variant="h6" marginBottom="1rem" alignSelf="center">
    //           Feature Request
    //         </Typography>
    //       )}
    //       <StyledTextField
    //         label="What do you want to have happen?"
    //         feedBack={feedBack}
    //         setFeedBack={setFeedBack}
    //       />
    //       <Box
    //         display="flex"
    //         flexDirection="row"
    //         marginTop="1rem"
    //         justifyContent="space-between"
    //       >
    //         <Button
    //           onClick={() => setFormStage(2)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-left" />
    //         </Button>
    //         <Button
    //           onClick={() => handleSubmit()}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //           disabled={true}
    //         >
    //           <Box>Submit</Box>
    //         </Button>
    //         <Button
    //           onClick={() => setFormStage(4)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-right" />
    //         </Button>
    //       </Box>
    //     </Box>
    //   ) : formStage === 4 ? (
    //     <Box
    //       display="flex"
    //       flexDirection="column"
    //       maxWidth="50%"
    //       // alignItems="center"
    //     >
    //       {feedbackTitleDisplay && (
    //         <Typography variant="h6" marginBottom="1rem" alignSelf="center">
    //           Feature Request
    //         </Typography>
    //       )}
    //       <StyledTextField
    //         label="Where do you want this to occur?"
    //         feedBack={feedBack}
    //         setFeedBack={setFeedBack}
    //       />
    //       <Box
    //         display="flex"
    //         flexDirection="row"
    //         marginTop="1rem"
    //         justifyContent="space-between"
    //       >
    //         <Button
    //           onClick={() => setFormStage(3)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-left" />
    //         </Button>
    //         <Button
    //           onClick={() => handleSubmit()}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <Box>Submit</Box>
    //         </Button>
    //         <Button
    //           onClick={() => setFormStage(4)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //           disabled={true}
    //         >
    //           <FontAwesomeIcon icon="arrow-right" />
    //         </Button>
    //       </Box>
    //     </Box>
    //   ) : formStage === 5 ? (
    //     <Box
    //       display="flex"
    //       flexDirection="column"
    //       maxWidth="50%"
    //       // alignItems="center"
    //     >
    //       {feedbackTitleDisplay && (
    //         <Typography variant="h6" marginBottom="1rem" alignSelf="center">
    //           Technical Issue / Bug
    //         </Typography>
    //       )}
    //       <StyledTextField
    //         label="What is the problem?"
    //         feedBack={feedBack}
    //         setFeedBack={setFeedBack}
    //       />
    //       <Box
    //         display="flex"
    //         flexDirection="row"
    //         marginTop="1rem"
    //         justifyContent="space-between"
    //       >
    //         <Button
    //           onClick={() => setFormStage(0)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-left" />
    //         </Button>
    //         <Button
    //           onClick={() => handleSubmit()}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //           disabled={true}
    //         >
    //           <Box>Submit</Box>
    //         </Button>
    //         <Button
    //           onClick={() => setFormStage(6)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-right" />
    //         </Button>
    //       </Box>
    //     </Box>
    //   ) : formStage === 6 ? (
    //     <Box
    //       display="flex"
    //       flexDirection="column"
    //       maxWidth="50%"
    //       // alignItems="center"
    //     >
    //       {feedbackTitleDisplay && (
    //         <Typography variant="h6" marginBottom="1rem" alignSelf="center">
    //           Technical Issue / Bug
    //         </Typography>
    //       )}
    //       <StyledTextField
    //         label="Where did you encounter the problem?"
    //         feedBack={feedBack}
    //         setFeedBack={setFeedBack}
    //       />
    //       <Box
    //         display="flex"
    //         flexDirection="row"
    //         marginTop="1rem"
    //         justifyContent="space-between"
    //       >
    //         <Button
    //           onClick={() => setFormStage(5)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-left" />
    //         </Button>
    //         <Button
    //           onClick={() => handleSubmit()}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //           disabled={true}
    //         >
    //           <Box>Submit</Box>
    //         </Button>
    //         <Button
    //           onClick={() => setFormStage(8)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-right" />
    //         </Button>
    //       </Box>
    //     </Box>
    //   ) : formStage === 7 ? (
    //     <Box
    //       display="flex"
    //       flexDirection="column"
    //       maxWidth="50%"
    //       // alignItems="center"
    //     >
    //       {feedbackTitleDisplay && (
    //         <Typography variant="h6" marginBottom="1rem" alignSelf="center">
    //           Technical Issue / Bug
    //         </Typography>
    //       )}
    //       <StyledTextField
    //         label="How could the problem be reproduced?"
    //         feedBack={feedBack}
    //         setFeedBack={setFeedBack}
    //       />
    //       <Box
    //         display="flex"
    //         flexDirection="row"
    //         marginTop="1rem"
    //         justifyContent="space-between"
    //       >
    //         <Button
    //           onClick={() => setFormStage(6)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-left" />
    //         </Button>
    //         <Button
    //           onClick={() => handleSubmit()}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <Box>Submit</Box>
    //         </Button>
    //         <Button
    //           onClick={() => setFormStage(4)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //           disabled={true}
    //         >
    //           <FontAwesomeIcon icon="arrow-right" />
    //         </Button>
    //       </Box>
    //     </Box>
    //   ) : formStage === 8 ? (
    //     <Box
    //       display="flex"
    //       flexDirection="column"
    //       maxWidth="50%"
    //       // alignItems="center"
    //     >
    //       {feedbackTitleDisplay && (
    //         <Typography variant="h6" marginBottom="1rem" alignSelf="center">
    //           Other
    //         </Typography>
    //       )}
    //       <StyledTextField
    //         label="Description"
    //         feedBack={feedBack}
    //         setFeedBack={setFeedBack}
    //       />
    //       <Box
    //         display="flex"
    //         flexDirection="row"
    //         marginTop="1rem"
    //         justifyContent="space-between"
    //       >
    //         <Button
    //           onClick={() => setFormStage(0)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <FontAwesomeIcon icon="arrow-left" />
    //         </Button>
    //         <Button
    //           onClick={() => handleSubmit()}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //         >
    //           <Box>Submit</Box>
    //         </Button>
    //         <Button
    //           onClick={() => setFormStage(4)}
    //           variant="contained"
    //           size="large"
    //           color="secondary"
    //           disabled={true}
    //         >
    //           <FontAwesomeIcon icon="arrow-right" />
    //         </Button>
    //       </Box>
    //     </Box>
    //   ) : (
    //     <></>
    //   )}
    // </Box>
    <iframe
      src={generatedURL}
      width="100%"
      height={`${iframeHeight}px`}
      className={classes.formStackIFrame}
      scrolling={disableScrolling ? "no" : "yes"}
      onLoad={() => {
        loadedHandler(true);
      }}
      onError={() => {
        errorHandler(false);
      }}
    />
    // <Grid container spacing={2}>
    //   <Grid item xs={2}>
    //     <Box
    //       // textAlign={"center"}
    //       sx={{
    //         backgroundColor: "#ffff",
    //         borderRadius: "5px",
    //         boxShadow: "0px 2px 8px rgba(0,0,0,0.32)",
    //         marginBottom: "1rem",
    //         padding: "1rem",
    //       }}
    //     >
    // <FormControl focused={true}>
    //   <FormLabel>
    //     <Typography variant="h6">Feedback Type</Typography>
    //   </FormLabel>
    //   <RadioGroup value={value}>
    //     <FormControlLabel
    //       value="comment"
    //       control={<Radio onClick={handleRadioChange} />}
    //       label="General Comment"
    //     />
    //     <FormControlLabel
    //       value="request"
    //       control={<Radio onClick={handleRadioChange} />}
    //       label="Feature Request"
    //     />
    //     <FormControlLabel
    //       value="bug"
    //       control={<Radio onClick={handleRadioChange} />}
    //       label="Technical Issue / Bug"
    //     />
    //     <FormControlLabel
    //       value="other"
    //       control={<Radio onClick={handleRadioChange} />}
    //       label="Other"
    //     />
    //   </RadioGroup>
    // </FormControl>
    //     </Box>
    // <Button
    //   onClick={() => handleSubmit()}
    //   variant="contained"
    //   size="large"
    //   color="secondary"
    // >
    //   <Box>Submit</Box>
    // </Button>
    //   </Grid>
    //   {value !== "" ? (
    //     <Grid item xs={10}>
    //       <Box
    //       // padding="1rem"
    //       // textAlign={"center"}
    //       // minWidth="25rem"
    //       // sx={{
    //       //   backgroundColor: "#ffff",
    //       //   borderRadius: "5px",
    //       //   boxShadow: "0px 2px 8px rgba(0,0,0,0.32)",
    //       // }}
    //       >
    //         {value === "comment" ? (
    // <>
    //   {feedbackTitleDisplay && (
    //     <Typography variant="h6" marginBottom="1rem">
    //       General Comment
    //     </Typography>
    //   )}
    //   <StyledTextField
    //     label="Description"
    //     feedBack={feedBack}
    //     setFeedBack={setFeedBack}
    //   />
    // </>
    //         ) : value === "request" ? (
    // <>
    //   <Grid container spacing={2}>
    //     {feedbackTitleDisplay && (
    //       <Typography variant="h6" marginBottom="1rem">
    //         Feature Request
    //       </Typography>
    //     )}
    //     <Grid item xs={6}>
    //       <StyledTextField
    //         label="What do you want to have happen?"
    //         feedBack={feedBack}
    //         setFeedBack={setFeedBack}
    //       />
    //     </Grid>
    //     <Grid item xs={6}>
    //       <StyledTextField
    //         label="Where do you want this to occur?"
    //         feedBack={feedBack}
    //         setFeedBack={setFeedBack}
    //       />
    //     </Grid>
    //   </Grid>
    // </>
    //         ) : value === "bug" ? (
    //           <>
    //             <Grid container spacing={2}>
    //               {feedbackTitleDisplay && (
    //                 <Typography variant="h6" marginBottom="1rem">
    //                   Technical Issue / Bug
    //                 </Typography>
    //               )}
    //               <Grid item xs={4}>
    // <StyledTextField
    //   label="What is the problem?"
    //   feedBack={feedBack}
    //   setFeedBack={setFeedBack}
    // />
    //               </Grid>
    //               <Grid item xs={4}>
    // <StyledTextField
    //   label="Where did you encounter the problem?"
    //   feedBack={feedBack}
    //   setFeedBack={setFeedBack}
    // />
    //               </Grid>
    //               <Grid item xs={4}>
    //                 <StyledTextField
    //                   label="How could the problem be reproduced?"
    //                   feedBack={feedBack}
    //                   setFeedBack={setFeedBack}
    //                 />
    //               </Grid>
    //             </Grid>
    //           </>
    //         ) : value === "other" ? (
    //           <>
    //             {feedbackTitleDisplay && (
    //               <Typography variant="h6" marginBottom="1rem">
    //                 Other
    //               </Typography>
    //             )}
    // <StyledTextField
    //   label="Details"
    //   feedBack={feedBack}
    //   setFeedBack={setFeedBack}
    // />
    //           </>
    //         ) : (
    //           <></>
    //         )}
    //       </Box>
    //     </Grid>
    //   ) : (
    //     <></>
    //   )}
    // </Grid>
  );
}

FormStackLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  defaultHeight: PropTypes.number,
  heightBuffer: PropTypes.number,
  formURL: PropTypes.string.isRequired,
  dynamicHeight: PropTypes.bool,
  disableScrolling: PropTypes.bool,
  pageTitle: PropTypes.string,
  hiddenFields: PropTypes.bool,
  loadedHandler: PropTypes.func,
  errorHandler: PropTypes.func,
};

FormStackLoader.defaultProps = {
  dynamicHeight: false,
  disableScrolling: false,
  hiddenFields: false,
  loadedHandler: () => {},
  errorHandler: () => {},
};

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(FormStackLoader);
