import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import layoutStyles from "containers/Layout/styles";

const LeftSideDrawer = ({ classes, children }) => (
  <div className={classes.drawerInner}>
    <div className={classes.drawerHeader} style={{ left: "15px !important" }}>
      {/* <i
                className="fak fa-sixstar-six-logo xl fa-xl"
                style={{
                    marginTop: "10px",
                    color: "white",
                    height: "30px",
                }}>
            </i>
            <Box
                display={"flex"}
                marginLeft={"15px"}
                marginTop={"-12px"}
            >
                <div className={classes.sidebarTitle}>
                    <span className={classes.sidebarTitleBold}>SIX STAR </span>
                    MANAGE
                </div>
            </Box> */}
    </div>
    {children}
  </div>
);

LeftSideDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles((theme) => layoutStyles(theme), {
  withTheme: true,
})(LeftSideDrawer);
