import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import useErrorNotification from "utils/useErrorNotification";

import useWarningNotification from "utils/useWarningNotification";
import { graphQLMutations } from "graphql/ticket";
import { graphQLMutations as notifactionMutation } from "graphql/notification";

const useInsertTicketComment = (props) => {
    const { ticketID = null, ticketAssignee, ticket } = props ?? {};
    const [superLoading, setSuperLoading] = useState(false);
    const [sendErrorNotification] = useErrorNotification();
    const [clearMessage, setClearMessage] = useState(false);

    const user = useSelector((state) => state?.user?.user ?? {});
    // const { id: userID, account_id: accountID, name, email_address } = user;

    const userID = user?.id ?? null
    const accountID = user?.account_id ?? null
    const name = user?.name ?? null
    const email_address = user?.email_address ?? null

    // console.log('userID', userID)

    // console.log('user', useSelector((state) => state?.user?.user ?? {}))

    const [sendWarningNotification] = useWarningNotification();

    const { INSERT_TICKET_COMMENT, INSERT_TICKET_COMMENT_NO_EMAIL, INSERT_TICKET_COMMENT_NO_NOTIFICATION } = graphQLMutations();
    const { INSERT_NOTIFICATION } = notifactionMutation();

    const [submitAddCommentNoNotification,
        {
            data: addCommentNoNotificationData,
            error: addCommentNoNotificationError,
        },] = useMutation(INSERT_TICKET_COMMENT_NO_NOTIFICATION);

    const [
        submitAddComment,
        {
            data: addCommentData,
            error: addCommentError,
        },
    ] = useMutation(INSERT_TICKET_COMMENT);

    const [
        submitAddCommentNoEmail,
        {
            data: addCommentNoEmailData,
            error: addCommentNoEmailError,
        },
    ] = useMutation(INSERT_TICKET_COMMENT_NO_EMAIL);


    const [
        submitAddNotification,
        {
            data: addNotificationData,
            error: addNotificationError,
        },
    ] = useMutation(INSERT_NOTIFICATION);

    const sendNotifications = ({ assignee, requester, followers, sender, url = `/support/tickets/${ticketID}`, title = 'Ticket Update', body = 'Your ticket has been updated' }) => {
        // Concat the arrays of props, remove the sender from the array and remove any duplicates and push a mutation of notifications to each of them
        // followers exist inside another object of person, take the person object out of the array
        followers = Array.isArray(followers) ? followers.map((follower) => follower?.person) : [];
        let recipients = [assignee, requester, ...followers];
        recipients = recipients.filter((recipient) => recipient?.id !== sender?.id);
        recipients = recipients.filter((recipient, index, self) => self.findIndex((t) => t.id === recipient.id) === index);
        recipients.map((recipient) => {
            const variables = {
                account_id: accountID,
                body: body,
                notificationBody: body,
                notificationTitle: title,
                recipientID: recipient?.id ?? null,
                notificationURL: url,
            };
            return submitAddNotification({ variables });
        }
        );
    };

    const handleSubmitAddComment = ({
        comment = null,
        public: publicValue = true,
        subject = null,
        lexical = null,
        priorityID,
        statusID,
        closedAt = null,
        cc = null,
        to = null,
        agent = false,
        mentions = null,
        alias = null,
        emailTemplate = null,
        emailSubject = null,
        emailPriority = null,
        emailRecipient = null,
        emailFrom = null,
        emailPlus_address = null,
        resources = null,
        assignee = null,
        team = null
    }) => {

        const sender = userID

        if (ticketID) {

            //     console.log('props', props)


            setSuperLoading(true);
            // sendWarningNotification({ message: 'Submitting Comment', title: 'Add Comment' })
            // sendErrorNotification({ error: 'Not a valid ticket number', location: 'Add Comment' })
            if (mentions) {
                mentions = mentions.map((mention) => {
                    return {
                        created_by_id: userID,
                        person_id: mention.id,
                    };
                });

                mentions = { data: mentions };

            }
            let body_preview = null;
            if (comment) {
                body_preview = comment;


            }
            let text = comment
            function convertHtmlToText(htmlContent) {
                // Replace <br> tags with newline characters
                let text = htmlContent.replace(/<br\s*\/?>/gi, '\n');

                // Replace </p> tags with double newline characters
                text = text.replace(/<\/p>/gi, '\n');
                text = text.replace(/<p[^>]*>/gi, ''); // Remove opening <p> tags

                // Remove any other HTML tags
                text = text.replace(/<[^>]+>/g, '');

                // Trim the result to remove extra whitespace
                return text.trim();
            }
            //.replace(/<br\s*\/?>/gi, '\n');

            //     // Replace <p> tags with double newline characters
            //     text = text.replace(/<\/p>/gi, '\n\n');
            //     text = text.replace(/<p[^>]*>/gi, ''); // Remove opening <p> tags

            //     // Remove any other HTML tags (optional)
            //     text = text.replace(/<[^>]+>/g, '');
            //  // console.log('text', text)
            // Trim the result to remove extra whitespace

            // const parser = new DOMParser();
            // const doc = parser.parseFromString(comment, 'text/html');
            // const text = doc.body.textContent;



            // let resourceEmails = resources?.map((resource) => {
            //   resourceEmails.push (resource.person.email_address)
            // }
            let emailAddresses = []
            // if(to[0]?.email_address){
            //   emailAddresses = [to[0].email_address]
            // }


            if (to?.length > 0) {
                to?.map((to) => {
                    emailAddresses.push(to?.email_address)
                })
            }
            //   Add any resources
            if (resources?.length > 0) {
                resources?.map((resource) => {
                    emailAddresses.push(resource?.person?.email_address)
                }
                )
            }
            // Add any assignees

            if (assignee !== null) {
                assignee?.map((assignee) => {
                    emailAddresses.push(assignee?.person?.email_address)

                }
                )
            } else {

                team?.people?.map((person) => {
                    emailAddresses.push(person?.email_address)
                }
                )
            }
            //   console.log('assignee', assignee)

            let status;

            if (statusID === '6b888b99-cd8d-4cf5-a255-1cca328effa2') {
                status = 'Open';
            } else if (statusID === 'c7dc5b89-e3cd-41ec-baf1-d7d43d079725') {
                status = 'Resolved';
            } else if (statusID === 'e4829c81-314e-4634-81bc-903976e0ea99') {
                status = 'Hold';
            } else if (statusID === '115dc3ec-c6ca-4377-8d6b-d24045c345c8') {
                status = 'New';
            } else if (statusID === '9736681b-a657-45d2-9415-f4befe48aece') {
                status = 'Unknown';
            } else if (statusID === '63ffbdc8-b443-42c6-8bc2-6e58a4011f93') {
                status = 'Pending';
            } else {
                status = 'Unknown'; // or any default value you prefer
            }

            let priority;

            if (priorityID === '0820adea-a913-41ea-bca2-23a5836dcacd') {
                priority = 'Low';
            } else if (priorityID === '629698ef-a3f6-4523-9d80-700a5ff1a491') {
                priority = 'Medium';
            } else if (priorityID === 'e447041f-d5d6-4c41-b22d-2e0157d23c19') {
                priority = 'High';
            }




            const variables = {
                accountID: accountID,
                ticketID: ticketID,
                body: comment ?? "",
                body_preview: body_preview ?? "",
                public: publicValue,
                agent: agent ?? false,
                authorID: userID,
                subject: subject,
                sourceTypeID: process.env.REACT_APP_ADD_COMMENT_SOURCE_TYPE_ID,
                lexical: lexical,
                priorityID: priorityID,
                statusID: statusID,
                closedAt: typeof closedAt === 'object' ? closedAt?.raw ?? null : closedAt,
                cc: cc ?? null,
                to: to ?? null,
                sender: sender ?? null,
                mentions: mentions ?? null,
                assignee: ticketAssignee,
                emailTemplate: {
                    "name": "TicketUpdateTest",
                    "params": {
                        "subject": {
                            "ticketNumber": subject + " - Ticket #" + alias.toString()
                        },
                        "body": {
                            "assetUrl": "https://ssg-send-email-templates.s3.eu-west-2.amazonaws.com",
                            "ticketNumber": alias.toString(),
                            "ticketLink": `https://manage.sixstar.click/support/tickets/` + alias,
                            "contact": to[0]?.name ?? null,
                            "summary": convertHtmlToText(text),
                            "priority": priority,
                            "status": status,
                            "SSGOwner": ticketAssignee,
                            "signature": name
                        }
                    }
                },
                emailSubject: "subject" ?? null,
                emailPriority: emailPriority ?? null,
                emailRecipient: emailAddresses ?? null,
                emailFrom: sender ?? null,
                emailPlus_address: alias.toString() ?? null,
            };


            if (to.length > 0 || cc.length > 0) {
                variables.sender = {
                    name: name,
                    email_address: email_address,
                    id: userID,
                };
            }
            if (to.length === 0) {
                variables.to = null;
            }
            if (cc.length === 0) {
                variables.cc = null;
            }

            variables.recipientID = to[0]?.id ?? null;
            variables.notificationTitle = 'Ticket Update'


            if (alias) {
                variables.notificationURL = `/support/tickets/${alias}`
                variables.notificationBody = `Your ticket ${alias} has been updated`
            } else {
                variables.notificationURL = '/support/tickets'
                variables.notificationBody = 'Your ticket has been updated'
            }
            let foundNonCompanyEmail = false;
            //remove any value that is null or undefined from variables.emailRecipient
            variables.emailRecipient = variables.emailRecipient.filter(function (el) {
                return el != null;
            });
            if (!publicValue) {

                for (let email of variables.emailRecipient) {

                    if (!email.endsWith('@sixstar.global')) {
                        foundNonCompanyEmail = true;
                        break;
                    }
                }

                if (foundNonCompanyEmail) {

                    variables.emailRecipient = 'private@sixstar.global';
                }
            }

            let agentFollowers = ticket?.followers.filter((follower) => follower?.person.organization.name === "Six Star Global") ?? [];

            if (to.length > 0) {
                if (publicValue) {
                    sendNotifications({ assignee: ticket.assignee, requester: ticket.requester, followers: ticket.followers, sender: variables.sender, url: variables.notificationURL, title: variables.notificationTitle, body: variables.notificationBody });
                    return submitAddComment({ variables });

                } else {
                    if (foundNonCompanyEmail) {
                        sendNotifications({ assignee: ticket.assignee, requester: ticket.requester, followers: ticket.followers, sender: variables.sender, url: variables.notificationURL, title: variables.notificationTitle, body: variables.notificationBody });
                        return submitAddCommentNoNotification({ variables });
                    } else {
                        return submitAddComment({ variables });
                    }
                }
            } else {
                sendNotifications({ assignee: ticket.assignee, requester: [], followers: agentFollowers, sender: variables.sender, url: variables.notificationURL, title: variables.notificationTitle, body: variables.notificationBody });
                return submitAddCommentNoEmail({ variables });
            }


        } else {
            sendErrorNotification({
                error: "Not a valid ticket number",
                location: "Add Comment",
            });
        }
    };

    useEffect(() => {
        if (clearMessage) {
            setClearMessage(false);
        }
    }, [clearMessage]);

    useEffect(async () => {
        try {

            if (addCommentNoNotificationData) {
                setSuperLoading(false);
                setClearMessage(true);
            }
            if (addCommentData) {
                setSuperLoading(false);
                setClearMessage(true);
            }
            if (addCommentNoEmailData) {
                setSuperLoading(false);
                setClearMessage(true);
            }
        } catch (error) {
            console.log(error);
            sendErrorNotification({ error: error, location: "Add Comment" });
            setSuperLoading(false);
        }
    }, [addCommentData, addCommentNoEmailData, addCommentNoNotificationData]);

    useEffect(() => {
        if (addCommentNoNotificationError) {
            sendErrorNotification({
                error: addCommentNoNotificationError,
                location: "Add Comment",
            });
        }
        if (addCommentError) {
            sendErrorNotification({
                error: addCommentError,
                location: "Add Comment",
            });
        }
        if (addCommentNoEmailError) {
            sendErrorNotification({
                error: addCommentNoEmailError,
                location: "Add Comment",
            });
        }

    }, [addCommentError, addCommentNoEmailError, addCommentNoNotificationError]);

    return {
        handle: handleSubmitAddComment,
        loading: superLoading,
        clear: clearMessage,
    };
};

export default useInsertTicketComment;
