import { gql } from '@apollo/client';

export const graphQLQueries = () => {
  const BUNDLE_SEARCH_ALIAS = gql`
    query GetChildByAlias($ticket_alias: bigint!) {
      ticket(where: { alias: { _eq: $ticket_alias } }) {
        id
        alias
        subject
        child_tickets_aggregate {
          aggregate {
            count
          }
        }
        parent_ticket {
          id
          child_id
          master_id
          master {
            alias
            subject
          }
        }
      }
    }
  `;
  const GET_WELCOME_COUNT = gql`
    query GET_WELCOME_COUNT($_in_organization: [uuid!], $_in_status: [uuid!], $_requester: uuid) {
      ticket_aggregate(where: { organization_id: { _in: $_in_organization }, status_id: { _in: $_in_status }, requester_id: { _eq: $_requester } }) {
        aggregate {
          count
        }
      }
      ticket(where: { organization_id: { _in: $_in_organization }, status_id: { _in: $_in_status }, requester_id: { _eq: $_requester } }) {
        id
        alias
        subject
        assignee {
          name
        }
        comments(limit: 1, order_by: { created_at: asc }) {
          id
          source {
            body_preview
            subject
          }
        }
      }
    }
  `;
  const GET_WELCOME_COUNT_ENGINEER = gql`
    query GET_WELCOME_COUNT_ENGINEER($_in_status: [uuid!], $_assignee: uuid) {
      ticket_aggregate(where: { status_id: { _in: $_in_status }, assignee_id: { _eq: $_assignee } }) {
        aggregate {
          count
        }
      }
      ticket(where: { status_id: { _in: $_in_status }, assignee_id: { _eq: $_assignee } }) {
        id
        alias
        subject
        assignee {
          name
        }
        comments(limit: 1, order_by: { created_at: asc }) {
          id
          source {
            body_preview
            subject
          }
        }
      }
    }
  `;
  const CHECK_TICKET_EXISTS = gql`
    query CheckTicketExists($ticketAlias: bigint = "") {
      ticket_aggregate(where: { alias: { _eq: $ticketAlias } }) {
        aggregate {
          count
        }
      }
    }
  `;

  const GET_AUDIT_LOG = gql`
    query GetAuditLog($ticketAlias: bigint = "") {
      ticket_audit(where: { object: { alias: { _eq: $ticketAlias } } }, order_by: { created_at: desc }) {
        created_at
        diffs
        person {
          avatar
          id
          name
          email_address
          organization {
            name
          }
        }
        type
      }
      ticket_comment_audit(where: { object: { ticket: { alias: { _eq: $ticketAlias } } } }, order_by: { created_at: desc }) {
        created_at
        diffs
        person {
          avatar
          id
          name
          email_address
        }
        type
      }
    }
  `;

  //   const USER_GET_AUDIT_LOG = gql`
  // query GetUserAuditLog(
  //   $ticketAlias: bigint = "",
  //   $org_ids: [uuid!],
  //   ) {
  //   ticket_audit(
  //     where: {
  //       object: {
  //         alias: { _eq: $ticketAlias }
  //         organization_id: {_in: $org_ids}
  //       }
  //      }
  //     order_by: { created_at: desc }
  //   ) {
  //     created_at
  //     diffs
  //     person {
  //       avatar
  //       id
  //       name
  //       email_address
  //     }
  //     type
  //   }
  //   ticket_comment_audit(
  //     where: {
  //       object: {
  //         alias: { _eq: $ticketAlias }
  //         organization_id: {_in: $org_ids}
  //       }
  //      }
  //     order_by: { created_at: desc }
  //   ) {
  //     created_at
  //     diffs
  //     person {
  //       avatar
  //       id
  //       name
  //       email_address
  //     }
  //     type
  //   }
  // }
  // `;

  const GET_TICKET = gql`
    subscription TicketSubscription($ticketAlias: bigint = "") {
      ticket(where: { alias: { _eq: $ticketAlias } }) {
        id
        alias
        subject
        summary
        closed_at
        updated_at
        created_at
        assignee_id
        ticket_audits(order_by: { created_at: desc }) {
          type
          person {
            name
            organization {
              name
            }
          }
          diffs
          created_at
        }
        updated_by {
          id
          name
          avatar
          email_address
        }
        priority {
          id
          name
        }
        status {
          id
          name
        }
        contacts(order_by: {group: {sort: asc}, role: {sort: asc}}) {
          person {
            alias
            name
            email_address
            id
            organization {
            name
            id
            }
            organization_name
          }
          role {
            name
            sort
            id
          }
          group {
            name
            sort
            id
          }
        }
        public
        tags(where: { tag: { deleted: { _eq: false } } }) {
          tag {
            id
            name
          }
        }
        cc(where: { person_id: { _is_null: false } }) {
          person_id
          person {
            name
          }
        }
        child_tickets_aggregate {
          aggregate {
            count
          }
        }
        parent_ticket {
          id
          child_id
          master_id
          master {
            alias
            subject
          }
        }
        attachments(where: { deleted: { _eq: false } }) {
          id
          created_at
          name
          mime_type
          public
          url
          inline
        }
        form {
          submission {
            data {
              field_id
              value
            }
            form {
            name
              fields {
                id
                name
              }
            }
          }
        }
        external_devices {
          device {
            id
            metadata
            type {
              name
              id
            }
          }
        }
        followers {
          person {
            id
            name
            avatar
            email_address
            organization {
              name
            }
            ticket_roles(where: { ticket: { alias: { _eq: $ticketAlias } } }) {
              id
              role {
                id
                name
              }
            }
          }
        }
        resources {
          person {
            avatar
            name
            id
            email_address
            ticket_roles(where: { ticket: { alias: { _eq: $ticketAlias } } }) {
              id
              role {
                id
                name
              }
            }
          }
        }
        assignee {
          name
          id
          avatar
          email_address
          organization {
            name
            id
          }
          ticket_roles(where: { ticket: { alias: { _eq: $ticketAlias } } }) {
            id
            role {
              id
              name
            }
          }
            organization_name
        }
        roles {
          person {
            name
            id
          }
          role {
            id
            name
          }
          role_id
        }
        board_id
        board {
          id
          name
          teams {
            team {
              name
              people {
                person {
                  name
                  id
                }
              }
            }
          }
        }
        team_id
        team {
          id
          name
        }
        parent_ticket {
          master {
            alias
          }
        }
        child_tickets {
          child {
            alias
            cc {
              person_id
              person {
                name
              }
            }
            attachments(where: { deleted: { _eq: false } }) {
              id
              created_at
              name
              mime_type
              public
              url
              inline
            }
            comments(order_by: { created_at: desc }, where: { deleted: { _eq: false } }) {
              alias
              created_at
              id
              public
              updated_at
              agent
              ticket_id
              person {
                avatar
                name
                id
              }
              documents(where: { deleted: { _eq: false } }) {
                url
                public
                name
                mime_type
                internet_message_id
                conversation_id
                content_id
                created_at
                id
                inline
              }
              source {
                subject
                bcc
                cc
                conversation_id
                id
                lexical
                body_preview
                body
                internet_message_id
                recipient
                sender
                type {
                  name
                }
              }
            }
          }
        }
        comments(order_by: { created_at: desc }, where: { deleted: { _eq: false } }) {
          alias
          created_at
          id
          public
          updated_at
          agent
          person {
            avatar
            name
            id
          }
          documents(where: { deleted: { _eq: false } }) {
            url
            public
            name
            mime_type
            internet_message_id
            conversation_id
            content_id
            created_at
            id
            inline
          }
          ticket_id
          source {
            subject
            bcc
            cc
            conversation_id
            id
            lexical
            body
            body_preview
            internet_message_id
            recipient
            sender
            type {
              name
            }
          }
        }
        approvals {
          approved
          approved_at
          approver_id
          created_at
          id
          requestor_id
          ticket_id
          updated_at
          approval_type_id
          approval_type {
            type
            description
            id
          }
          requestor {
            name
            id
          }
          approver {
            name
            id
          }
        }
        organization {
          alias
          avatar
          id
          legal_name
          name
          locations {
            main
            name
            address_line_one
            address_line_two
            city
            state
            zip_code
            country {
              name
            }
            active
            deleted
            id
            phone_numbers {
              phone_number {
                number
                main
                type {
                  name
                }
                country {
                  dialing_prefix
                }
              }
            }
          }
        }
        requester {
          email_address
          id
          name
          avatar
          organization {
            name
            id
          }
          phone_numbers {
            phone_number {
              number
              main
              type {
                name
              }
              country {
                dialing_prefix
              }
            }
          }
        }
        tasks {
          planner_task_id
          task {
            bucket_id
            completed
            completed_at
            due_date
            end_date
            name
            tickets {
              ticket_id
            }
            completed_by_id
            completed_by {
              name
              id
            }
            id
            priority {
              icon
              id
              name
            }
            progress {
              icon
              id
              name
            }
            assigned {
              person {
                name
                id
                email_address
              }
            }
            checklists(order_by: { id: asc }) {
              completed
              id
              completed_by_id
              task_id
              text
            }
          }
        }
      }
    }
  `;

  const USER_GET_TICKET = gql`
    subscription UserTicketSubscription($ticketAlias: bigint = "", $org_ids: [uuid!]) {
      ticket(where: { alias: { _eq: $ticketAlias }, organization_id: { _in: $org_ids } }) {
        id
        alias
        subject
        status_id
        closed_at
        updated_at
        created_at
        priority_id
        assignee_id
        external_devices {
          device {
            id
            metadata
            type {
              name
              id
            }
          }
        }
        followers {
          person {
            id
            name
            avatar
            email_address
            organization {
              name
            }
          }
        }
        resources {
          person {
            id
            name
            avatar
            email_address
            organization {
              name
            }
          }
        }
        assignee {
            id
            name
            avatar
            email_address
            organization {
              name
              id
            }
        }
        roles {
          person {
            name
            id
          }
          role {
            id
            name
          }
          role_id
        }
        board_id
        board {
          id
          name
          teams {
            team {
              name
              people {
                person {
                  name
                  id
                }
              }
            }
          }
        }
        team_id
        team {
          id
          name
        }
        comments(order_by: { created_at: desc }, where: { deleted: { _eq: false } }) {
          alias
          created_at
          id
          public
          updated_at
          agent
          person {
            avatar
            name
            id
          }
          documents(where: { deleted: { _eq: false } }) {
            url
            public
            name
            mime_type
            internet_message_id
            conversation_id
            content_id
            created_at
            id
            inline
          }
          ticket_id
          source {
            subject
            bcc
            cc
            conversation_id
            id
            lexical
            body_preview
            internet_message_id
            recipient
            sender
            type {
              name
            }
          }
        }
        approvals {
          approved
          approved_at
          approver_id
          created_at
          id
          requestor_id
          ticket_id
          updated_at
          approval_type_id
          approval_type {
            type
            description
            id
          }
          requestor {
            name
            id
          }
          approver {
            name
            id
          }
        }
        organization {
          alias
          avatar
          id
          legal_name
          name
          locations {
            main
            name
            address_line_one
            address_line_two
            city
            state
            zip_code
            country {
              name
            }
            active
            deleted
            id
            phone_numbers {
              phone_number {
                number
                main
                type {
                  name
                }
                country {
                  dialing_prefix
                }
              }
            }
          }
        }
        requester {
            id
            name
            avatar
            email_address
            organization {
              name
            }
          organization {
            name
            id
          }
          phone_numbers {
            phone_number {
              number
              main
              type {
                name
              }
              country {
                dialing_prefix
              }
            }
          }
        }
        tasks {
          planner_task_id
          task {
            bucket_id
            completed
            completed_at
            due_date
            end_date
            name
            tickets {
              ticket_id
            }
            completed_by_id
            completed_by {
              name
              id
            }
            id
            priority {
              icon
              id
              name
            }
            progress {
              icon
              id
              name
            }
            assigned {
              person {
                name
                id
                ticket_roles(where: { ticket: { alias: { _eq: $ticketAlias } } }) {
                  id
                  role {
                    id
                    name
                  }
                }
              }
            }
            checklists(order_by: { id: asc }) {
              completed
              id
              completed_by_id
              task_id
              text
            }
          }
        }
      }
    }
  `;

  const GET_TICKET_UPDATE_DATA = gql`
    query TicketAdjacentData {
      ticket_role(where: { deleted: { _eq: false } }) {
        id
        name
      }
      ticket_priority(where: { active: { _eq: true }, deleted: { _eq: false } }) {
        id
        name
      }
      ticket_status(where: { active: { _eq: true }, deleted: { _eq: false } }) {
        id
        name
        resolved
        open
        new
        hold
        pending
      }
      team(order_by: { name: asc }) {
        main
        name
        id
        people(where: { person: { active: { _eq: true }, deleted: { _eq: false } } }, order_by: { person: { name: asc } }) {
          person {
            name
            id
            email_address
          }
        }
      }
      board(where: { active: { _eq: true }, deleted: { _eq: false } }) {
        id
        name
        teams(where: {}, order_by: { team: { name: asc } }) {
          team {
            id
            name
            people(order_by: { person: { name: asc } }) {
              person {
                name
                id
              }
            }
          }
        }
      }
      planner_progress {
        icon
        id
        name
      }
      planner_priority {
        icon
        id
        name
      }
    }
  `;

  const GET_ORGANIZATION_OPEN_TICKETS = gql`
  query MyQuery($orgID: uuid = "") {
    ticket( where: {organization_id: {_eq: $orgID}, status: {resolved: {_eq: false}}}) {
      alias
      assignee {
        avatar
        email_address
        first_name
        last_name
        name
      }
      created_at
      priority {
        name
      }
      requester {
        avatar
        email_address
        first_name
        id
        last_name
        name
      }
      status {
        name
      }
      subject
    }
  }

  `;

  const GET_LAST_X_DAYS_TICKETS = gql`
  query LastXDays($_date: timestamptz = "03/10/3000") {
    ticket(where: {created_at: {_gte: $_date}}, order_by: {created_at: asc}) {
      id
      created_at
      priority_id
      status_id
    }
    ticket_aggregate(where: {created_at: {_gte: $_date}}) {
      aggregate {
        count
      }
    }
  }
  `
  return {
    GET_WELCOME_COUNT,
    GET_WELCOME_COUNT_ENGINEER,
    CHECK_TICKET_EXISTS,
    GET_TICKET,
    USER_GET_TICKET,
    GET_TICKET_UPDATE_DATA,
    GET_AUDIT_LOG,
    // USER_GET_AUDIT_LOG,
    BUNDLE_SEARCH_ALIAS,
    GET_ORGANIZATION_OPEN_TICKETS,
    GET_LAST_X_DAYS_TICKETS
  };
};
export const graphQLMutations = () => {
  const DELETE_TICKET_COMMENT = gql`
    mutation DeleteTicketComment($id: uuid!, $deleted: Boolean!) {
      update_ticket_comment_by_pk(pk_columns: { id: $id }, _set: { deleted: $deleted }) {
        id
      }
    }
  `;
  const UPDATE_TICKET_COMMENT_PUBLIC = gql`
    mutation UpdateCommentPublic($commentID: uuid!, $public: Boolean!) {
      update_ticket_comment_by_pk(pk_columns: { id: $commentID }, _set: { public: $public }) {
        id
        public
      }
    }
  `;

  const INSERT_TICKET_COMMENT_NO_EMAIL = gql`
    mutation AddComment(
      $sender: jsonb = null
      $to: jsonb = null
      $cc: jsonb = null
      $priorityID: uuid!
      $statusID: uuid!
      $lexical: jsonb!
      $subject: String!
      $accountID: uuid!
      $ticketID: uuid!
      $public: Boolean!
      $body: String!
      $body_preview: String!
      $authorID: uuid!
      $agent: Boolean!
      $sourceTypeID: uuid!
      $closedAt: timestamptz
      $mentions: ticket_comment_mention_arr_rel_insert_input = null
    ) {
      insert_ticket_comment_one(
        object: {
          author_id: $authorID
          mentions: $mentions
          ticket_id: $ticketID
          agent: $agent
          account_id: $accountID
          created_at: "now"
          updated_at: "now"
          public: $public
          source: {
            data: {
              type_id: $sourceTypeID
              body: $body
              body_preview: $body_preview
              lexical: $lexical
              subject: $subject
              recipient: $to
              cc: $cc
              sender: $sender
            }
          }
        }
      ) {
        id
      }

      update_ticket_by_pk(
        pk_columns: { id: $ticketID }
        _set: { status_id: $statusID, priority_id: $priorityID, closed_at: $closedAt, updated_at: "now" }
      ) {
        id
      }
    }
  `;
  const INSERT_TICKET_COMMENT_NO_NOTIFICATION = gql`
    mutation AddComment(
      $sender: jsonb = null
      $to: jsonb = null
      $cc: jsonb = null
      $priorityID: uuid!
      $statusID: uuid!
      $lexical: jsonb!
      $subject: String!
      $accountID: uuid!
      $ticketID: uuid!
      $public: Boolean!
      $body: String!
      $body_preview: String!
      $authorID: uuid!
      $agent: Boolean!
      $sourceTypeID: uuid!
      $closedAt: timestamptz
      $mentions: ticket_comment_mention_arr_rel_insert_input = null
      $emailTemplate: json!
      $emailSubject: String = ""
      $emailPriority: String = ""
      $emailRecipient: json!
      $emailFrom: String
      $emailPlus_address: String
    ) {
      insert_ticket_comment_one(
        object: {
          author_id: $authorID
          mentions: $mentions
          ticket_id: $ticketID
          agent: $agent
          account_id: $accountID
          created_at: "now"
          updated_at: "now"
          public: $public
          source: {
            data: {
              type_id: $sourceTypeID
              body: $body
              body_preview: $body_preview
              lexical: $lexical
              subject: $subject
              recipient: $to
              cc: $cc
              sender: $sender
            }
          }
        }
      ) {
        id
      }

      update_ticket_by_pk(
        pk_columns: { id: $ticketID }
        _set: { status_id: $statusID, priority_id: $priorityID, closed_at: $closedAt, updated_at: "now" }
      ) {
        id
      }

      send_email(
        priority: $emailPriority
        recipient: $emailRecipient
        subject: $emailSubject
        template: $emailTemplate
        from: $emailFrom
        plus_address: $emailPlus_address
      ) {
        id
        metadata
        delivered
      }
    }
  `;
  const INSERT_TICKET_COMMENT = gql`
    mutation AddComment(
      $sender: jsonb = null
      $to: jsonb = null
      $cc: jsonb = null
      $priorityID: uuid!
      $statusID: uuid!
      $lexical: jsonb!
      $subject: String!
      $accountID: uuid!
      $ticketID: uuid!
      $public: Boolean!
      $body: String!
      $body_preview: String!
      $authorID: uuid!
      $agent: Boolean!
      $sourceTypeID: uuid!
      $closedAt: timestamptz
      $mentions: ticket_comment_mention_arr_rel_insert_input = null
      $emailTemplate: json!
      $emailSubject: String = ""
      $emailPriority: String = ""
      $emailRecipient: json!
      $emailFrom: String
      $emailPlus_address: String
    ) {
      insert_ticket_comment_one(
        object: {
          author_id: $authorID
          mentions: $mentions
          ticket_id: $ticketID
          agent: $agent
          account_id: $accountID
          created_at: "now"
          updated_at: "now"
          public: $public
          source: {
            data: {
              type_id: $sourceTypeID
              body: $body
              body_preview: $body_preview
              lexical: $lexical
              subject: $subject
              recipient: $to
              cc: $cc
              sender: $sender
            }
          }
        }
      ) {
        id
      }

      update_ticket_by_pk(
        pk_columns: { id: $ticketID }
        _set: { status_id: $statusID, priority_id: $priorityID, closed_at: $closedAt, updated_at: "now" }
      ) {
        id
      }

      send_email(
        priority: $emailPriority
        recipient: $emailRecipient
        subject: $emailSubject
        template: $emailTemplate
        from: $emailFrom
        plus_address: $emailPlus_address
      ) {
        id
        metadata
        delivered
      }
    }
  `;
  // sendEmail(
  // $emailTemplate: json!,
  // $emailSubject: String = "",
  // $emailPriority: String = "",
  // $emailRecipient: json!,
  // $emailFrom: String,
  // $emailPlus_address: String
  // ) {
  // send_email(
  //     emailPriority: $emailPriority,
  //     emailRecipient: $emailRecipient,
  //     emailSubject: $emailSubject,
  //     emailTemplate: $emailTemplate,
  //     emailFrom: $emailFrom,
  //     emailPlus_address: $plus_address) {
  //     id
  //     metadata
  //     delivered
  //   }
  // }

  const ADD_TICKET_FOLLOWER = gql`
    mutation AddFollower($personID: uuid!, $ticketID: uuid!) {
      insert_ticket_followers_one(object: { person_id: $personID, ticket_id: $ticketID }) {
        person_id
        ticket_id
      }
    }
  `;
  const REMOVE_TICKET_FOLLOWER = gql`
    mutation RemoveFollower($personID: uuid!, $ticketID: uuid!) {
      delete_ticket_followers_by_pk(person_id: $personID, ticket_id: $ticketID) {
        person_id
        ticket_id
      }
    }
  `;

  const APPROVE_REJECT_TICKET_APPROVAL = gql`
    mutation APPROVE_REJECT_TICKET_APPROVAL($approved: Boolean = false, $ticketID: uuid = "", $approver_id: uuid = "") {
      update_approvals(
        where: { ticket_id: { _eq: $ticketID } }
        _set: { approved: $approved, updated_at: "now()", approver_id: $approver_id, approved_at: "now()" }
      ) {
        affected_rows
      }
    }
  `;

  const INSERT_TICKET = gql`
    mutation MyMutation($objects: [ticket_insert_input!] = {}) {
      insert_ticket(objects: $objects) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;

  const GET_TICKET_ALIAS = gql`
  query MyQuery {
    ticket(where: {id: {_eq: ""}}) {
      alias
    }
  }
  `;

  const UPDATE_TICKET_MERGE_PARENT = gql`
    mutation MyMutation($parent_ticket: uuid!, $master_id: uuid!) {
      update_ticket_merge_by_pk(pk_columns: { id: $parent_ticket }, _set: { master_id: $master_id }) {
        master {
          alias
        }
      }
    }
  `;
  const INSERT_NEW_TICKET_MERGE = gql`
    mutation InsertNewTicketMerge($account_id: uuid = "92cf2939-e08a-4a03-908a-f4d136b6f1e0", $child_id: uuid!, $master_id: uuid) {
      insert_ticket_merge_one(object: { account_id: $account_id, child_id: $child_id, master_id: $master_id }) {
        id
      }
      update_ticket_by_pk(pk_columns: { id: $child_id }, _set: { closed_at: "now", status_id: "c7dc5b89-e3cd-41ec-baf1-d7d43d079725" }) {
        id
      }
    }
  `;

  const UPDATE_TICKET_ASSIGNEE = gql`
    mutation UpdateTicketAssignee($ticketID: uuid!, $assigneeID: uuid!) {
      update_ticket_by_pk(pk_columns: { id: $ticketID }, _set: { assignee_id: $assigneeID }) {
        id
      }
    }
  `;
  return {
    INSERT_TICKET_COMMENT_NO_NOTIFICATION,
    INSERT_TICKET_COMMENT,
    INSERT_TICKET_COMMENT_NO_EMAIL,
    UPDATE_TICKET_COMMENT_PUBLIC,
    DELETE_TICKET_COMMENT,
    ADD_TICKET_FOLLOWER,
    REMOVE_TICKET_FOLLOWER,
    APPROVE_REJECT_TICKET_APPROVAL,
    INSERT_TICKET,
    UPDATE_TICKET_MERGE_PARENT,
    INSERT_NEW_TICKET_MERGE,
    UPDATE_TICKET_ASSIGNEE,
    GET_TICKET_ALIAS
  };
};
export default graphQLQueries;
