import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";

import layoutStyles from "containers/Layout/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import { width } from "@mui/system";
import Translation from "utils/translation";
class LeftSideBarMenuList extends React.Component {
  renderExpandIcons(collapse) {
    const { classes } = this.props;

    const expandIcon = collapse ? (
      <ExpandLess
        color="action"
        className={classNames(classes.expandCollapseIcon)}
      />
    ) : (
      <ExpandMore
        color="action"
        className={classNames(classes.expandCollapseIcon)}
      />
    );

    return expandIcon;
  }

  render() {
    const {
      children,
      classes,
      isCollapsed,
      menuListClass,
      sidebarIsOpen,
      subHeaderClass,
      title,
    } = this.props;

    return (
      <MenuList
        className={classNames(
          menuListClass,
          sidebarIsOpen ? "sidebar-opened" : "sidebar-closed"
        )}
        subheader={
          <Box
            display={`flex`}
            alignItems={"center"}
            // marginLeft={`${sidebarIsOpen ? "0px" : "15px"} `}
            marginLeft={"15px"}
            justifyContent={"flex-start"}
            // justifyContent={`${!sidebarIsOpen ? "flex-start" : "center"} `}
          >
            {/* {sidebarIsOpen && ( */}
            <>
              <i
                className="fak fa-sixstar-six-logo xl fa-xl"
                style={{
                  marginTop: "10px",
                  color: "white",
                  height: "30px",
                }}
              ></i>
              <Box
                display={`${sidebarIsOpen ? "flex" : "none"}`}
                marginLeft={"15px"}
                marginTop={"-12px"}
              >
                <div className={classes.sidebarTitle}>
                  <span className={classes.sidebarTitleBold}> SIXSTAR </span>
                  MANAGE
                </div> 
              </Box>
            </>
            {/* )} */}
          </Box>
        }
        // subheader={
        //   <ListItem
        //       button
        //       onClick={this.props.handleOnClick}
        //       className={classNames(
        //           classes.menuTitleContainer,
        //           !sidebarIsOpen ? subHeaderClass : null,
        //       )}
        //   >
        //     <ListItemText
        //         secondary={title}
        //         className={classNames(classes.menuTitle)}
        //     />
        //     {this.renderExpandIcons(isCollapsed)}
        //   </ListItem>
        // }
      >
        <Collapse unmountOnExit in={isCollapsed}>
          {children}
        </Collapse>
      </MenuList>
    );
  }
}

LeftSideBarMenuList.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  menuListClass: PropTypes.string.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
  subHeaderClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles((theme) => layoutStyles(theme), {
  withTheme: true,
})(LeftSideBarMenuList);
