const login = {
  "login.title": "SIX STAR GLOBAL",
  "login.headline": "La empresa líder mundial en servicios gestionados de TI.",
  "login.signInText": "Sign Into Six Star: Manage",
  "login.topCallToActionTitle": "¿No eres parte de Six Star?",
  "login.topCallToActionLink": "Hablemos",
  "loginPage.input.email": "Correo electrónico",
  "loginPage.input.password": "Contraseña",
  "loginPage.input.mfa": "Código MFA",
  "loginPage.rememberMe": "Recordarme",
  "loginPage.button.login": "Iniciar sesión",
  "loginPage.button.forgotPassword": "¿Olvidaste tu contraseña?",
  "loginPage.apiMfaInProgress": "Por favor, introduce tu código MFA",
  "login.forgotPasswordText": "Contraseña olvidada",
  "loginPage.apiMultipleUsers": "Error en la cuenta de usuario, contacta a Six Star",
  "loginPage.apiNoPassword": "Por favor, introduce una contraseña",
  "loginPage.apiNoUser": "Por favor, introduce una dirección de correo electrónico",
  "loginPage.apiInvalidTOTP": "Código MFA inválido",
  "loginPage.apiInvalidPassword": "Contraseña inválida",
  "login.sso.starting": "Iniciando inicio de sesión único",
  "loginPage.button.normalSignIn": "Iniciar sesión con usuario/contraseña",
  "loginPage.text.msssoLoading": "Cargando...",
  "loginPage.label.msssoSignIn": "Iniciar sesión con Microsoft",
  "loginPage.label.msssoSignInEmail": "Dirección de correo electrónico de Microsoft",
  "loginPage.text.msssoError": "Error al iniciar sesión",

  "loginPage.emailPrompt": "Ingrese su dirección de correo electrónico a continuación para restablecer su contraseña.",
  "loginPage.ForgotPassword.GoBack": "Volver",
  "loginPage.ForgotPassword.Submit": "Enviar",
  "loginPage.label.email": "Correo electrónico",
  "loginPage.helpIcon": "Ayuda",
  "loginPage.label.fullName": "Nombre completo",
  "loginPage.label.password": "Contraseña",
  "loginPage.label.confirmPassword": "Confirmar contraseña",
  "loginPage.register.button.goback": "Volver",
  "loginPage.register.button.register": "Registrarse",
  "loginPage.register.button.signUp": "Iniciar sesión",

};
export default login;
