const widgets = {
  "widget.barChart.name": "Staafdiagram Widget",
  "widget.barChart.description":
    "Dit is een zeer coole Test-widget, deze is geïmporteerd en kan in andere add-ons worden gebruikt",
  "widget.organizationTickets.name": "Organisatie Tickets Widget",
  "widget.organizationTickets.description":
    "Dit is een widget die het aantal openstaande prioriteit 1s weergeeft.",
  "widget.outlookInbox.name": "Outlook Inbox Widget",
  "widget.outlookInbox.description":
    "Dit is een widget die het aantal e-mails in uw inbox weergeeft.",
  "widget.personTickets.name": "Persoonlijke Tickets Widget",
  "widget.personTickets.description":
    "Dit is een widget die het aantal openstaande prioriteit 1s weergeeft.",
  "widget.pieChart.name": "Taartdiagram Widget",
  "widget.pieChart.description":
    "Dit is een zeer coole Test-widget, deze is geïmporteerd en kan in andere add-ons worden gebruikt",
  "widget.quickLinks.name": "Snelkoppelingen Widget",
  "widget.quickLinks.description":
    "Dit is een widget die een lijst met links naar andere pagina's op de site weergeeft.",
  "widget.social.name": "Sociale Feed Widget",
  "widget.social.description":
    "Dit is een sociale feed widget verbonden aan Six Star social media-accounts.",
  "widget.ticketTable.name": "Ticket Tabel Widget",
  "widget.ticketTable.description":
    "Dit is een zeer coole Test-widget, deze is geïmporteerd en kan in andere add-ons worden gebruikt",
  "widget.social.feedType": "Facebook / Twitter",
  "widget.quickLinks.newRequest": "Nieuw Verzoek",
  "widget.quickLinks.licenceManagement": "Licentiebeheer",
  "widget.quickLinks.assetManagement": "Assetbeheer",
  "widget.quickLinks.sixStarDrive": "Six Star Drive",
  "widget.quickLinks.sixStarGlobal": "Six Star Global",

  "widget.quickLinks.button.newRequest": "Nieuw Verzoek",
"widget.quickLinks.button.licenceManagement": "Licentiebeheer",
"widget.quickLinks.button.assetManagement": "Assetbeheer",
"widget.quickLinks.button.sixStarDrive": "Six Star Drive",

"widgets.org.tickets.p1": "Org P1 Tickets",
"widgets.org.tickets.p2": "Org P2 Tickets",
"widgets.org.tickets.open": "Org Open Tickets",

"widgets.personal.tickets.p1": "Persoonlijke P1s",
"widgets.personal.tickets.p2": "Persoonlijke P2s",
"widgets.personal.tickets.open": "Persoonlijk Open",
"widgets.personal.tickets.p1": "Persoonlijke P1's",
"widgets.personal.tickets.p2": "Persoonlijke P2's",
"widgets.personal.tickets.open": "Open Persoonlijk",
"widget.Guage.name": "Oplossing Gauge Widget",
"widget.Guage.description": "Dit is een widget die het aantal opgeloste tickets uit de wachtrij van vandaag weergeeft.",
"widget.LineGraph.name": "Lijn Grafiek Widget",
"widget.LineGraph.description": "Dit is een widget die het aantal aangemaakte tickets in de laatste 30 dagen weergeeft.",
"widget.muiBarChart.name": "Staafdiagram Widget",
"widget.muiBarChart.description": "Dit is een widget die het aantal tickets, per prioriteit, weergeeft dat in de laatste 30 dagen is aangemaakt.",
"widget.ReleaseNotes.name": "Release Notes Widget",
"widget.ReleaseNotes.description": "Dit is een widget die de laatste release-opmerkingen weergeeft.",
"widget.DailyUpdates.name": "Dagelijkse Updates Widget",
"widget.DailyUpdates.description": "Dit is een widget die de dagelijkse updates weergeeft.",
"widget.TicketPieChart.name": "Ticket Taartdiagram Widget",
"widget.TicketPieChart.description": "Dit is een widget die het aantal tickets per status weergeeft.",
"widget.barchart.title" : "Tickets per Prioriteit",
"widget.linechart.title" : "Tickets Aangemaakt In De Laatste 30 Dagen",
"widget.piechart.title" : "Tickets per Status",
"widget.guage.title" : "Opgeloste Tickets Uit de Wachtrij van Vandaag",

};
export default widgets;
