import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { Divider, Typography } from "@mui/material";
import Translation from "utils/translation";
import PropTypes from "prop-types";

function PageHeader(props) {
  const { text = "not set" } = props;

  return (
    <>
      <Typography variant="h4" fontWeight={"bold"} sx={{ marginTop: 0 }}>
        {Translation(text)}
      </Typography>
      <Divider
        sx={{ marginTop: 3, marginBottom: 5, borderBottomWidth: "medium" }}
        role="presentation"
      />
    </>
  );
}

PageHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default withStyles((theme) => ({}), { withTheme: true })(PageHeader);
