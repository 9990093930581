import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import layoutStyles from "containers/Layout/styles";
import Translation from "../../../../utils/translation";
import MenuIcon from "components/Layout/LeftSideBar/LeftSideBarMenuIcon";

const LeftSideBarSubMenuItem = ({
  classes,
  handleOnClick,
  item,
  openSubMenu,
  sidebarIsOpen,
  selectedMenuItemParentId,
  selectedMenuItem,
}) => {
  // console.log(item);
  let disabled = item?.disabled ? true : false;
  return (
    <MenuItem
      // button
      sx={{ paddingY: 1.189 }}
      onClick={() => handleOnClick(item.id)}
      disabled={disabled}
    >
      <ListItemIcon
        className={classNames(
          item.id === selectedMenuItem.id ||
            item.id === selectedMenuItemParentId
            ? classes.selectedItemText
            : classes.itemIcon,
          "menu-icon"
        )}
      >
        <MenuIcon icon={item.icon} />
        {/*<Icon className={item.icon} style={{ overflow: 'visible' }} />*/}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={Translation(item.menuText)}
        className={classNames(
          sidebarIsOpen ? classes.noPadding : "menu-padding",
          classes.listItemText,
          "truncate-list-item-text",
          "menu-margin"
        )}
      />
      {openSubMenu[item.id] ? (
        <ExpandLess
          color="action"
          className={classNames(classes.expandCollapseIcon)}
        />
      ) : (
        <ExpandMore
          color="action"
          className={classNames(classes.expandCollapseIcon)}
        />
      )}
    </MenuItem>
  );
};

LeftSideBarSubMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  openSubMenu: PropTypes.object.isRequired,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

export default withStyles((theme) => layoutStyles(theme), {
  withTheme: true,
})(LeftSideBarSubMenuItem);
