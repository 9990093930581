import { useSelector } from "react-redux";
import { gql, useSubscription } from "@apollo/client";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "@mui/styles/withStyles";
import styles from "containers/Pages/TicketViewPage/styles";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
const dateNowString = new Date().toISOString();


function NotificationSubscriber(props) {
  const user = useSelector((state) => state.user.user);
  let organizations = [];
  if (user?.permissions?.organizations) {
    user.permissions.organizations.map(
      (organization) => {
        // if (organization?.organization?.id)  organizations.push(organization?.organization?.id)
        if (organization) {
          if (organization?.organization) {
            if (organization?.organization?.id) {
              organizations.push(organization?.organization?.id)
            }
          }
        }
      }
      // (organization) => organization?.organization?.id ?? null
    );
  }
  

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const NotificationStream = gql`
        subscription MyQuery {
            notification_stream(
                batch_size: 2, 
                cursor: {initial_value: {created_at: "${dateNowString}"}, ordering: ASC},
                where:{_or:[
                    {_and:[
                        { person_id:{_eq:"${user?.id}"}},
                        {organization_id:{_is_null:true}}
                    ]},
                    {_and:[
                        { person_id:{_is_null:true}},
                        {organization_id:{_in:${JSON.stringify(organizations)}}}
                    ]}

                ] }
            
            
            ){
                body
                title
                read
                read_at
                url
                priority {
                    name
                }
                person {
                    name
                }
                organization {
                    name
                }
                icon
                created_at
            }
        }
    `;
  const {
    data: incomingNotifications,
    loading,
    error,
  } = useSubscription(NotificationStream);
  useEffect(() => {
    if (incomingNotifications) {
      incomingNotifications.notification_stream.forEach((notification) => {
        // console.log(notification);
        enqueueSnackbar(
          <Notification notification={notification} theme={props.theme} />,
          {
            variant: "secondary",
            persist: true,
            preventDuplicate: true,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            className: props.classes.snackbar,

            action: (key) => (
              <IconButton
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                <FontAwesomeIcon
                  icon={["fas", "times"]}
                  fixedWidth
                  color={props.theme.palette.text.light}
                />
              </IconButton>
            ),
          }
        );
      });
    }
  }, [incomingNotifications]);
  return <></>;
}

function Notification(props) {
  const { notification, theme } = props;
  //split notification.icon spring to array
  const navigate = useNavigate();
  let icon = null;
  if (notification.icon || notification.length > 3)
    icon = notification.icon.split(" ");

  return (
    <Box sx={{ minWidth: "230px" }} className="notification">
      <Grid container spacing={2} rowSpacing={0}>
        {icon && (
          <Grid item xs={"auto"}>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                alignContent: "center",
                alignItems: "center",
              }}
              className="notification__icon"
            >
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                color={theme.palette.text.light}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={"auto"}>
          <div>{notification.title}</div>

          <div>{notification.body}</div>
          {notification.url && (
            <IconButton
              onClick={() => {
                //navigate to url
                navigate(notification.url);
              }}
            >
              <FontAwesomeIcon
                icon={["fal", "arrow-right"]}
                size={"2xs"}
                color={theme.palette.text.light}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
export default withStyles(
  (theme) => ({
    snackbar: {
      backgroundColor: theme.palette.secondary.main + "!important",
    },
  }),
  { withTheme: true }
)(NotificationSubscriber);
