const misc = {
  'tooltip.disabled': 'Uitgeschakeld',
  'languageSelector.english': 'Engels',
  'languageSelector.dutch': 'Nederlands',
  'languageSelector.german': 'Duits',
  'languageSelector.french': 'Frans',
  'languageSelector.spanish': 'Spaans',
  'languageSelector.polish': 'Pools',
  'languageSelector.italian': 'Italiaans',
  'languageSelector.thai': 'Thais',
  'datatable.toolbar.quickFilterPlaceholder': 'Zoeken...',
  'datatable.toolbar.columns': 'Kolommen',
  'datatable.toolbar.density': 'Dichtheid',
  'datatable.toolbar.densityComfortable': 'Comfortabel',
  'datatable.toolbar.densityCompact': 'Compact',
  'datatable.toolbar.densityStandard': 'Standaard',
  'datatable.toolbar.export': 'Exporteren',
  'datatable.toolbar.filters': 'Filters',
  'socket.connected': 'Verbonden',
  'socket.disconnected': 'Verbroken',
  'profile.menu.viewProfile': 'Profiel Bekijken',
  'profile.menu.editProfile': 'Profiel Bewerken',
  'profile.menu.help': 'Hulp',
  'profile.menu.signOut': 'Afmelden',
  'addon.add': 'Toevoegen',
  'addon.modal.save': 'Opslaan',
  'addon.edit': 'Bewerken',
  'notFoundPage.pageDevelopment': 'Pagina In Ontwikkeling',
  'notFoundPage.pageDevelopmentMessage':
    'Bedankt voor het verkennen van onze website. Deze specifieke pagina is nog in ontwikkeling omdat we momenteel in onze bètafase zitten. We waarderen uw geduld en begrip.',
  'notFoundPage.pageDevelopmentMessage2':
    'Let op: onze site ondergaat regelmatige updates en verbeteringen om u de best mogelijke ervaring te bieden. Kom binnenkort terug voor nieuwe inhoud en functies!',
  general: 'Algemeen',
  'ticket.role.first_responder': 'Eerste Responder',
  'ticket.role.first_responder.tooltip':
    'Eerste Responder: Eerste aanspreekpunt voor IT-problemen met hoge prioriteit, verantwoordelijk voor snelle oplossing en minimale downtime.',
  'ticket.role.lead_engineer': 'Leidinggevend Ingenieur',
  'ticket.role.lead_engineer.tooltip':
    'Leidinggevend Ingenieur: Verantwoordelijk voor de algehele technische richting van het project en voor het waarborgen dat het project op tijd en binnen budget wordt voltooid.',
  'ticket.status_id': 'Status',
  'ticket.priority_id': 'Prioriteit',
  'ticket.closed_at': 'Gesloten Op',
  'ticket.subject': 'Onderwerp',
  'team.team': 'Team',
  'ticket.created_at': 'Gemaakt Op',
  'ticket.updated_at': 'Bijgewerkt Op',
  'ticket.requester_id': 'Aanvrager',
  'ticket.organization_id': 'Organisatie',
  'ticket.assignee_id': 'Toegewezen aan',
  'ticket.author_id': 'Auteur',
  'ticket.public': 'Openbaar',
  'ticket.alias': 'ID',
  'ticket.status': 'Status',
  'ticket.priority': 'Prioriteit',
  'ticket.team_id': 'Team',
  'ticket.assignee': 'Toegewezen aan',
  'ticket.requester': 'Aanvrager',
  'ticket.has_incidents': 'Heeft Incidenten',
  'ticket.updated_by_id': 'Bijgewerkt Door',
  'ticket.author': 'Auteur',
  'person.assignee': 'Toegewezen aan',
  'person.requester': 'Aanvrager',
  'ticket_status.status': 'Status',
  'ticket_priority.priority': 'Prioriteit',
  'organization.organization': 'Bedrijf',
  'ticket_priority.priority.normal': 'Normaal',
  'ticket_priority.priority.high': 'Hoog',
  'ticket_priority.priority.low': 'Laag',
  'ticket_status.status.new': 'Nieuw',
  'ticket_status.status.pending': 'In Afwachting',
  'ticket_status.status.hold': 'In de Wacht',
  'ticket_status.status.open': 'Open',
  'ticket_status.status.resolved': 'Opgelost',
  'person.name': 'Naam',
  'person.avatar': 'Avatar',
  'person.email_address': 'E-mail',
  'person.active': 'Actief',
  'person.alias': 'ID',
  'organization.name': 'Naam',
  'organization.active': 'Actief',
  'organization.alias': 'ID',
  'board.board': 'Bord',

  // new translations
  'dateTime.utc': 'UTC',
  'ticket.devices.RMM.install': 'Installeer Six Star RMM',
  'ticket.devices.RMM.login': 'Log in bij Six Star RMM',
  'ticket.devices.RMM.title': 'Om de laatste informatie te bekijken',

  // Data tabel filters
  'dataTable.filters.reset': 'Herstellen',
  'dataTable.filters.loading': 'Bezig met laden...',
  'dataTable.filters.header': 'Filters',

  // Voorbeeld van de gegevenstabel
  'dataTable.preview.viewTicket': 'Bekijk ticket',
  'dataTable.preview.quickNotes': 'Snelle notities',
  'dataTable.preview.contacts': 'Contacten',
  'dataTable.preview.followers': 'Volgers',
  'dataTable.preview.relatedTickets': 'Gerelateerde tickets',
  'dataTable.preview.updateIn': 'Bijwerken over',
  'dataTable.preview.devices': 'Apparaten',
  'dataTable.preview.engineer': 'Technicus',
  'dataTable.preview.ticketCreated': 'Ticket aangemaakt:',

  // ChatGPT
  'chatgpt.somethingWentWrong': 'Er is iets misgegaan, probeer het opnieuw',
  'chatgpt.history': 'Geschiedenis',

  // Aangepaste groepsknop
  'customGroupButton.one.expandAll': 'Alles uitklappen',
  'customGroupButton.two.collapseAll': 'Alles inklappen',

  // Vlaggetjesnummers
  'flagNumber.button.callUs': 'Bel ons',

    "HelpOptions.Title": "Hoe kunnen we u helpen?",
    "FloatingBeta.ReportIssue": "Een probleem melden",
    "FloatingBeta.Feedback": "Feedback verzenden",
    "dateTime.utc": "UTC",
    "ticket.devices.RMM.install": "Installeer Six Star RMM",
    "ticket.devices.RMM.login": "Inloggen bij Six Star RMM",
    "ticket.devices.RMM.title": "Om de laatste informatie te bekijken",
    "dataTable.filters.reset": "Herstellen",
    "dataTable.filters.loading": "Bezig met laden...",
    "dataTable.filters.header": "Filters",
    "dataTable.preview.viewTicket": "Ticket bekijken",
    "dataTable.preview.quickNotes": "Snelle notities",
    "dataTable.preview.contacts": "Contactpersonen",
    "dataTable.preview.followers": "Volgers",
    "dataTable.preview.relatedTickets": "Gerelateerde tickets",
    "dataTable.preview.updateIn": "Bijwerken in",
    "dataTable.preview.devices": "Apparaten",
    "dataTable.preview.engineer": "Ingenieur",
    "dataTable.preview.ticketCreated": "Ticket aangemaakt: ",
    "chatgpt.somethingWentWrong": "Er is iets misgegaan. Probeer het opnieuw.",
    "chatgpt.history": "Geschiedenis",
    "customGroupButton.one.expandAll": "Alles uitvouwen",
    "customGroupButton.two.collapseAll": "Alles inklappen",
    "flagNumber.button.callUs": "Bel ons",
    "profile.menu.viewCompany": "Bekijk bedrijf",
    "contactUsModals.addWidgetModal.title": "Hoe voeg ik een widget toe aan mijn dashboard",
    "contactUsModals.addWidgetModal.step1": "1. Ga naar je dashboard",
    "contactUsModals.addWidgetModal.step2": "2. Zoek het paarse lint bovenaan je scherm",
    "contactUsModals.addWidgetModal.step3": "3. Klik op de knop Widget toevoegen",
    "contactUsModals.addWidgetModal.step4": "4. Selecteer een widget uit de lijst",
    "contactUsModals.addWidgetModal.step5": "5. Klik op de knop Toevoegen",
    "contactUsModals.signOutModal.title": "Hoe uitloggen",
    "contactUsModals.signOutModal.step1": "1. Vind je avatar rechtsboven op het scherm",
    "contactUsModals.signOutModal.step2": "2. Klik op je avatar",
    "contactUsModals.signOutModal.step3": "3. Vind de afmeldknop in het uitklapmenu",
    "contactUsModals.signOutModal.step4": "4. Klik op de afmeldknop",
    "TicketViewPage.toolbar.label": "Tickets zoeken",
    "TicketViewPage.toolbar.view": "Weergave",
    "TicketViewPage.toolbar.saveView": "Weergave opslaan",
    "TicketViewPage.toolbar.saveViewPrompt": "Voer de naam in waarmee u deze weergave wilt opslaan.",
    "TicketViewPage.toolbar.label.export": "Exporteren",
    "TicketViewPage.toolbar.label.filters": "Filters",
    "formLoader.submissionSuccess": "Bedankt voor het indienen van het formulier",
    "formLoader.submissionError": "Er is een fout opgetreden bij het indienen van het formulier",
    "reportIssue.submit": "Verzenden",
    "reportIssue.issue": "Probleem...",
    "ticketViewPage.modal.button.bunle": "Bundel",
    "ticketViewPage.modal.button.back": "Terug",
    "flagNumber.button.callUs": "Bel ons",
    "ticketDocuments.notFound": "Geen documenten gevonden",
    "ticketViewPage.childTicketsNone": "Geen kindertickets",
    "ticketViewPage.parentNone": "Geen ouderlijk ticket",
    "ticketViewPage.restricted": "Beperkt",
    "ticketViewPage.modal.button.close": "Sluiten",
    "tooltip.edit": "Bewerken",
    "tooltip.notifcations": "Meldingen",
    "tooltip.chooseLanguage": "Taal kiezen",
    "tooltip.logTicket": "Ticket loggen",
    "tooltip.contactUs": "Contacteer ons",
    "tooltip.hideTray": "Bak verbergen",
    "tooltip.notifications": "Meldingen"

};
export default misc;
