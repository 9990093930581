import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import { useQuery, useLazyQuery } from "@apollo/client";
import { get, groupBy, set } from "lodash";
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };

    const hideHeaderValue = {
        display: 'none',
    };

    const GroupHeader = styled('div')(({ theme }) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 10px',
        color: 'white',
        backgroundColor: 'lightBlue'
    }));

    const GroupItems = styled('ul')({
        padding: 0,
    });

    if (dataSet[0].key.includes(':::GROUPHEADER:::')) {
        return (
            <GroupHeader key={dataSet.key} style={inlineStyle}>
                {`${dataSet[0].key.replace(/:::GROUPHEADER:::.*/, '')}`}
            </GroupHeader>
        );
    }

    if (dataSet[1]) {
        return (
            <GroupItems {...dataSet[0]} noWrap style={inlineStyle}>
                {`${dataSet[1] ?? 'Data not found'}`}
            </GroupItems>
        );
    } else {
        let [value, header] = dataSet[0].key.split(':::');
        if (value === 'undefined') {
            value = 'Data Not Found'
        }
        return (
            <GroupItems {...dataSet[0]} noWrap style={inlineStyle}>
                <span>{value}</span>
                <span style={hideHeaderValue}>{header}</span>
            </GroupItems>
        );
    }
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}
// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};


export default function Virtualize(props) {
    const { query, endpoint, field, label = "Input", group, callback, capitalize, defaultValue, disabled, multiple = true, returnField, variables } = props

    // console.log({props})
    const [getData, { loading: loadingQuery, error, data }] = useLazyQuery(query, { variables: variables });
    const [loadingData, setLoadingData] = useState(true)
    const [OPTIONS, setOPTIONS] = useState([])
    const [open, setOpen] = React.useState(false);
    const loading = open && data === undefined;

    React.useEffect(() => {

        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active) {
                if (data) {

                } else {
                    getData()
                }

            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);
    ;

    useEffect(() => {
        if (error) {
            console.log({ error });
        }
        if (data) {
            const indent = String.fromCharCode(parseInt('U+00A0', 16)) + String.fromCharCode(parseInt('U+00A0', 16)) + String.fromCharCode(parseInt('U+00A0', 16))

            let newData = { ...data }


            newData[endpoint] = newData[endpoint].map((item) => {
                if (item.name.includes(':::')) {
                    return {
                        ...item,
                        name: indent + item.name.split(':::')[1]
                    }
                }
                return item;
            })

            if (group) {
                setOPTIONS(sortOptions(newData[endpoint]))
            } else {
                setOPTIONS(newData[endpoint].map((item) => {
                    return item
                }))
            }
            // }  
        }
    }, [loadingQuery, error]);

    useEffect(() => {
        if (OPTIONS.length > 0) {
            setLoadingData(false)
        }
    }, [OPTIONS])

    const sortOptions = (data) => {
        const uniqueCompanies = [];
        const companyIndices = [];
        const flatData = [];

        for (const row of data) {
            const companyName = row?.organization?.name ?? row?.parent_organization?.name ?? 'Other';

            if (!uniqueCompanies.includes(companyName)) {
                uniqueCompanies.push(companyName);
                companyIndices.push(0);
                flatData.push(companyName + ':::GROUPHEADER:::');
            }

            const personName = row.name;
            const companyPersonKey = `${companyName}_${personName}`;

            if (!uniqueCompanies.includes(companyPersonKey)) {
                uniqueCompanies.push(companyPersonKey);

                const companyIndex = flatData.findIndex((company) => company.includes(companyName + ':::GROUPHEADER:::'));
                flatData[companyIndex] += `:::${personName}`;

                flatData.push(personName + `:::${companyName}:::`);
            }
        }
        return flatData;
    };






    const additionProps = {}

    if (group === "") {
        additionProps.getOptionLabel = (option) => option?.name ?? ''
    }

    return (
        <>
            {(
                <Autocomplete
                    disabled={disabled}
                    multiple={multiple}
                    id="virtualize-demo"
                    sx={{ width: 300, marginBottom: 2 }}
                    disableListWrap
                    ListboxComponent={ListboxComponent}
                    options={OPTIONS}
                    defaultValue={defaultValue ?? []}
                    renderInput={(params) => {
                        return <TextField {...params} label={label} />
                    }}
                    renderOption={(props, option, state) => {
                        let optionValue
                        if (field?.includes(".")) {
                            optionValue = get(option, field)
                        }
                        else {
                            optionValue = option[field]
                        }
                        if (capitalize) {
                            if (optionValue) {
                                optionValue = optionValue?.charAt(0)?.toUpperCase() + optionValue?.slice(1)
                            }
                            if (props.key) {
                                optionValue = props.key?.charAt(0)?.toUpperCase() + props.key?.slice(1)
                            }
                        }
                        return [props, optionValue, state.index]
                    }}
                    renderTags={(value, getTagProps) => (
                        value.map((option, index) => {
                            let value
                            let header
                            try {
                                let [tempValue, tempHeader] = option.split(':::');
                                value = tempValue
                                header = tempHeader
                            }
                            catch (e) {
                                value = option.name
                            }
                            return (
                                <Chip
                                    variant="outlined"
                                    label={value}
                                    size="small"
                                    {...getTagProps({ index })}
                                />
                            )
                        })
                    )}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    noOptionsText={'No Options Found'}
                    loading={loading}
                    onChange={(e, v) => {
                        let returnArray = []
                        if (v?.length > 0) {

                            v.map((item) => {
                                let value
                                let header
                                try {
                                    let [tempValue, tempHeader] = item.split(':::');
                                    value = tempValue
                                    header = tempHeader
                                }
                                catch (e) {
                                    value = item
                                }
                                if (returnField) {
                                    // console.log({ value })
                                    returnArray.push(value[returnField])
                                } else if (value.name) {
                                    returnArray.push(value.name)
                                } else {
                                    returnArray.push(value)
                                }

                            })
                            if (callback) {
                                callback(returnArray)
                            }
                        } else {
                            if (multiple) {
                                if (callback) {
                                    callback([""])
                                }
                            } else {
                                if (callback) {
                                    if (returnField) {
                                        try {
                                            callback(v[returnField])

                                        } catch (e) {
                                            callback("")
                                        }
                                    } else {
                                        callback(v)
                                    }
                                }
                            }
                        }


                    }}
                    {...additionProps}
                />
            )}

        </>
    );
}