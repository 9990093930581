import {
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
} from "../constants";

export function refreshToken(content) {
  return {
    type: "socket",
    types: [REFRESH_TOKEN, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAIL],
    promise: (socket) =>
      socket.emit(REFRESH_TOKEN, content).then((result) => {
        // console.log('refreshToken')
        // console.log(result)
        let resultData = JSON.parse(result);
        return resultData;
      }),
  };
}
