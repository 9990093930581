import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Box, Button, Stack, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import Translation from 'utils/translation';
import { SidePanelBoldTitle, SidePanelItemCard, SidePanelParagraph } from 'containers/Pages/TicketViewPage/components/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import useWarningNotification from 'utils/useWarningNotification';
import { useNavigate } from 'react-router-dom';

function CompanyInfo(props) {
  const { company } = props;
  const navigate = useNavigate();

  const [showWarningNotification] = useWarningNotification();
  // function to copy email to clipboard
  const copyToClipboard = (userEmail) => {
    navigator.clipboard.writeText(userEmail);
    showWarningNotification({
      message: `Copied ${userEmail} to clipboard`,
      title: 'Copied to clipboard',
    });
  };
  const handleChildClick = (id) => () => {
    navigate(`/admin/company/${id}`);
  };
  return (
    <>
      <Box sx={{ marginTop: 2 }}>
        <Typography color='#838383'>{Translation('companyPage.parentOrganization')}</Typography>
      </Box>

      {company?.organization?.[0]?.parent_organization_id ? (
        <SidePanelItemCard>
          <Box sx={{ width: '100%' }}>
            <Typography style={{ color: '#3F76B6', fontWeight: 'bold', cursor: 'pointer' }} onClick={handleChildClick(company?.organization?.[0]?.parent_organization_id)}>
              {company?.organization?.[0]?.parent_organization?.name}
            </Typography>
          </Box>
        </SidePanelItemCard>
      ) : (
        <SidePanelItemCard>
          <Box sx={{ width: '100%' }}>
            <Typography style={{ color: '#3F76B6', fontWeight: 'bold' }}>{Translation('companyPage.noParentOrganization')}</Typography>
          </Box>
        </SidePanelItemCard>
      )}

      <Box sx={{}}>
        <Typography color='#838383'>{Translation('companyPage.childOrganizations')}</Typography>

        {company?.organization?.[0]?.child_organizations?.map((child, index) => (
          <SidePanelItemCard key={index}>
            <Box sx={{ width: '100%' }}>
              <Typography style={{ color: '#3F76B6', fontWeight: 'bold', cursor: 'pointer' }} onClick={handleChildClick(child?.id)}>
                {child?.name}
              </Typography>
            </Box>
          </SidePanelItemCard>
        ))}
        {company?.organization?.[0]?.child_organizations?.length === 0 && (
            <SidePanelItemCard>
            <Box sx={{ width: '100%' }}>
              <Typography style={{ color: '#3F76B6', fontWeight: 'bold' }}>{Translation('companyPage.noChildOrganizations')}</Typography>
            </Box>
          </SidePanelItemCard>
        )}
      </Box>
    </>
  );
}

export default CompanyInfo;
