import React from "react";

import CircularProgress from "@mui/material/CircularProgress";

import Wrapper from "./Wrapper";

const LoadingIndicator = () => (
  <Wrapper>
    <CircularProgress />
  </Wrapper>
);

export default LoadingIndicator;
