import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import graphQLQueries from 'graphql/person';
import { Autocomplete, TextField } from '@mui/material';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { ModalCancelButton, ModalAddButton } from 'containers/Pages/TicketViewPage/components/styled';
import { useParams } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const EditRelatedOrgs = (props) => {
  const { company, handleClose } = props;
  const [value, setValue] = useState('child');
  const [parentID, setParentID] = useState();
  const [childID, setChildID] = useState();
  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const organizationQuery = gql`
query MyQuery {
  organization(order_by: {name: asc}, where: {active: {_eq: true}}) {
    id
    name
  }
}
  `;
  const [getOrganizations, { loading: loadingOrganizations, error: errorOrganizations, data: dataOrganizations }] = useLazyQuery(organizationQuery);

  useEffect(() => {
    getOrganizations();
  }, []);

  const ADD_PARENT_ORG = gql`
  mutation MyMutation($_eq: uuid = "", $parent_organization_id: uuid = "") {
  update_organization(where: {id: {_eq: $_eq}}, _set: {parent_organization_id: $parent_organization_id}) {
    affected_rows
  }
}
    `;

    const [addParentOrg] = useMutation(ADD_PARENT_ORG);

    const ADD_CHILD_ORG = gql`
    mutation MyMutation($_eq: uuid = "", $child_organization_id: uuid = "") {
    update_organization(where: {id: {_eq: $_eq}}, _set: {child_organization_id: $child_organization_id}) {
        affected_rows
    }
    }
    `;

    const [addChildOrg] = useMutation(ADD_CHILD_ORG);

    const handleAdd = () => {
        setErrorMessage('');
        if (value === 'parent' && !parentID) {
            setErrorMessage('Please select a parent organization');
            return;
        } else if (value === 'child' && !childID) {
            setErrorMessage('Please select a child organization');
            return;
        }


        if (value === 'parent') {
            addParentOrg({
            variables: {
                _eq: id,
                parent_organization_id: parentID,
            },
            });
            setParentID('');

        } else {
            addChildOrg({
            variables: {
                _eq: id,
                child_organization_id: childID,
            },
            });
            setChildID('');
        }
        handleClose();
        };

        const isButtonDisabled = () => {
            if (company?.organization?.[0]?.parent_organization_id) {
                return true;
            } else {
                return false;
            }
        }


  return (
    <>
      {errorMessage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: 4 }}>
          <Typography color='error'>
            {errorMessage}
          </Typography>
        </Box>
      )  
      }
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: 4 }}>
    {value === 'parent' ? (
        <Box>
 
          <Autocomplete
            
            loading={loadingOrganizations}
            id='combo-box-demo'
            options={dataOrganizations?.organization}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Parent Organization'
                variant='outlined'
                onChange={(event, value) => {
                  setParentID(value.id);
                }}
              />
            )}
          />
        </Box>
      ) : (
        <Box>
          <Autocomplete
            loading={loadingOrganizations}
            id='combo-box-demo'
            options={dataOrganizations?.organization}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Child Organization'
                variant='outlined'
                onChange={(event, value) => {
                  setChildID(value.id);
                }}
              />
            )}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // flexDirection: 'row',
          // gap: 3,
        }}
      >

        <FormControl>
          <FormLabel id='demo-row-radio-buttons-group-label'>Relationship Type</FormLabel>
          <RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={value} onChange={handleChange}>
            <FormControlLabel 
            value='parent' 
            control={<Radio />} 
            label='Add Parent' 
            disabled={isButtonDisabled()}
            />
            <FormControlLabel value='child' control={<Radio />} label='Add Child' />
          </RadioGroup>
        </FormControl>
      </Box>


      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          // marginBottom: 2,
          marginTop: 4,
        }}
      >
        <ModalCancelButton variant='contained' onClick={handleClose}>
          Cancel
        </ModalCancelButton>
        <ModalAddButton
          variant='contained'
          onClick={handleAdd}
        >
          Add
        </ModalAddButton>
      </Box>
    </>
  );
};

export default EditRelatedOrgs;
