import React, { useEffect, useState, useRef } from 'react';
import withStyles from '@mui/styles/withStyles';
import layoutStyles from 'containers/Layout/styles';
import Button from '@mui/material/Button';
import { useQuery, useMutation, gql } from '@apollo/client';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Translation from 'utils/translation';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import PageBase from 'components/PageBase';
import { graphQLQueries } from 'graphql/organization';
import { useLazyQuery } from '@apollo/client';
import organization from 'graphql/organization';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const CreateOrg = (props) => {
  const [value, setValue] = React.useState('No');
  const nameRef = useRef();
  const legalNameRef = useRef();
  const emailDomainRef = useRef();
  const [parentID, setParentID] = useState();
  const { GET_ORGANIZATIONS } = organization();
  const [errorMessage, setErrorMessage] = useState();

  const [getOrganizations, { loading: loadingOrganizations, error: errorOrganizations, data: dataOrganizations }] = useLazyQuery(GET_ORGANIZATIONS);

  useEffect(() => {
    getOrganizations();
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  let createOrganizationMutation = gql`
  mutation MyMutation($account_id: uuid = "", $legal_name: String = "", $parent_organization_id: uuid = "", $name: String = "", $type_id: uuid = "", $time_zone_id: uuid = "", $email_address_domain: String = "") {
    insert_organization(
      objects: {
        account_id: $account_id
        active: true
        legal_name: $legal_name
        name: $name
        parent_organization_id: $parent_organization_id
        type_id: $type_id
        time_zone_id: $time_zone_id
        email_address_domain: $email_address_domain
      }
    ) {
      affected_rows
    }
  }
`;

  const [createOrganization, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(createOrganizationMutation);

  useEffect(() => {
    setErrorMessage(mutationError?.message);
  }, [mutationError]);


  const handleSubmit = () => {
    setErrorMessage("");
    let variables = {
        account_id: '92cf2939-e08a-4a03-908a-f4d136b6f1e0',
        legal_name: legalNameRef.current.value,
        name: nameRef.current.value,
        parent_organization_id: parentID || null,
        type_id: '018d794b-8111-72f8-901a-ec7193486712',
        time_zone_id: '0185e8a3-364c-746c-a95f-89ff9caf1fff',
        email_address_domain: emailDomainRef.current.value,
        };

        if (!variables.name || !variables.legal_name || !variables.email_address_domain) {
            setErrorMessage('Please fill out all fields');
            return;
            }


        createOrganization({ variables: variables });
        

  
  };
  return (
    <>
      <PageBase>
        <Card sx={{}}>
        {mutationData?.insert_person?.affected_rows >= 1 ? (
            <CardContent>
              <Typography variant="h5" component="div">
                Organization Created!
              </Typography>

              {/* <Button variant="contained" onClick={() => navigate('/')}>Dashboard</Button> */}
            </CardContent>

          ) : (
          <CardContent>
            <Typography id='modal-modal-title' variant='h6' component='h2' sx={{ alignItems: 'center', textAlign: 'center' }}>
              {Translation('createOrg.title')}
            </Typography>
            {errorMessage && (
                <Typography variant="h4" color="error" sx={{ alignItems: 'center', textAlign: 'center' }}>{errorMessage}</Typography>
              )}
            <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column' }}>
              <TextField label='Organization Name' inputRef={nameRef} sx={{ width: '100%' }} />

              <TextField label='Organization Legal Name' inputRef={legalNameRef} sx={{ marginTop: 2, width: '100%' }} />

              <TextField label='Email Domain' inputRef={emailDomainRef} sx={{ marginTop: 2, width: '100%' }} />

              <FormControl
                sx={{
                  marginTop: 2,
                }}
              >
                <FormLabel id='demo-row-radio-buttons-group-label'>Does the organization have a parent organization?</FormLabel>
                <RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' value={value} onChange={handleChange}>
                  <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                  <FormControlLabel value='No' control={<Radio />} label='No' />
                </RadioGroup>
              </FormControl>

              {value === 'Yes' ? (
                <Autocomplete
                  disablePortal
                  id='organization'
                  options={dataOrganizations?.organization}
                  getOptionLabel={(option) => option.name}
                  multiple={false}
                  onChange={(event, value) => {
                    setParentID(value.id);
                  }}
                  //   inputRef={emailRef}
                  sx={{ marginTop: 2, width: '100%' }}
                  renderInput={(params) => <TextField {...params} label='Parent Organization' />}
                />
              ) : null}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2, left: '50%' }}>
              <Button
                disabled={mutationLoading}
                variant='contained'
                sx={{ left: '37%', width: '25%' }}
                  onClick={handleSubmit}
              >
                {Translation('dashboard.modal.button.Add')}
              </Button>
            </Box>
          </CardContent>
        )}
        </Card>
      </PageBase>
    </>
  );
};

export default CreateOrg;
