const misc = {
  'tooltip.disabled': 'Désactivé',
  'languageSelector.english': 'Anglais',
  'languageSelector.dutch': 'Néerlandais',
  'languageSelector.german': 'Allemand',
  'languageSelector.french': 'Français',
  'languageSelector.spanish': 'Espagnol',
  'languageSelector.polish': 'Polonais',
  'languageSelector.italian': 'Italien',
  'languageSelector.thai': 'Thaïlandais',
  'datatable.toolbar.quickFilterPlaceholder': 'Recherche...',
  'datatable.toolbar.columns': 'Colonnes',
  'datatable.toolbar.density': 'Densité',
  'datatable.toolbar.densityComfortable': 'Confortable',
  'datatable.toolbar.densityCompact': 'Compact',
  'datatable.toolbar.densityStandard': 'Standard',
  'datatable.toolbar.export': 'Exporter',
  'datatable.toolbar.filters': 'Filtres',
  'socket.connected': 'Connecté',
  'socket.disconnected': 'Déconnecté',
  'profile.menu.viewProfile': 'Voir le Profil',
  'profile.menu.editProfile': 'Modifier le Profil',
  'profile.menu.help': 'Aide',
  'profile.menu.signOut': 'Se Déconnecter',
  'addon.add': 'Ajouter',
  'addon.modal.save': 'Sauvegarder',
  'addon.edit': 'Modifier',
  'notFoundPage.pageDevelopment': 'Page en Développement',
  'notFoundPage.pageDevelopmentMessage':
    "Merci d'explorer notre site web. Cette page en particulier est encore en développement car nous sommes actuellement en phase bêta. Nous apprécions votre patience et votre compréhension.",
  'notFoundPage.pageDevelopmentMessage2':
    "Veuillez noter que notre site fait l'objet de mises à jour et d'améliorations régulières pour vous offrir la meilleure expérience possible. Revenez bientôt pour de nouveaux contenus et fonctionnalités !",
  general: 'Général',
  'ticket.role.first_responder': 'Premier Intervenant',
  'ticket.role.first_responder.tooltip':
    "Premier Intervenant : Point de contact initial pour les problèmes informatiques de haute priorité, responsable d'une résolution rapide et d'un temps d'arrêt minimal.",
  'ticket.role.lead_engineer': 'Ingénieur en Chef',
  'ticket.role.lead_engineer.tooltip':
    "Ingénieur en Chef : Responsable de l'orientation technique globale du projet, et de veiller à ce que le projet soit achevé dans les délais et le budget prévus.",
  'ticket.status_id': 'Statut',
  'ticket.priority_id': 'Priorité',
  'ticket.closed_at': 'Fermé à',
  'ticket.subject': 'Sujet',
  'team.team': 'Équipe',
  'ticket.created_at': 'Créé à',
  'ticket.updated_at': 'Mis à jour à',
  'ticket.requester_id': 'Demandeur',
  'ticket.organization_id': 'Organisation',
  'ticket.assignee_id': 'Attributaire',
  'ticket.author_id': 'Auteur',
  'ticket.public': 'Public',
  'ticket.alias': 'ID',
  'ticket.status': 'Statut',
  'ticket.priority': 'Priorité',
  'ticket.team_id': 'Équipe',
  'ticket.assignee': 'Attributaire',
  'ticket.requester': 'Demandeur',
  'ticket.has_incidents': 'A des Incidents',
  'ticket.updated_by_id': 'Mis à jour par',
  'ticket.author': 'Auteur',
  'person.assignee': 'Attributaire',
  'person.requester': 'Demandeur',
  'ticket_status.status': 'Statut',
  'ticket_priority.priority': 'Priorité',
  'organization.organization': 'Entreprise',
  'ticket_priority.priority.normal': 'Normal',
  'ticket_priority.priority.high': 'Élevé',
  'ticket_priority.priority.low': 'Faible',
  'ticket_status.status.new': 'Nouveau',
  'ticket_status.status.pending': 'En Attente',
  'ticket_status.status.hold': 'En Suspens',
  'ticket_status.status.open': 'Ouvert',
  'ticket_status.status.resolved': 'Résolu',
  'person.name': 'Nom',
  'person.avatar': 'Avatar',
  'person.email_address': 'Courriel',
  'person.active': 'Actif',
  'person.alias': 'ID',
  'organization.name': 'Nom',
  'organization.active': 'Actif',
  'organization.alias': 'ID',
  'board.board': 'Tableau',

  // new translations
  'dateTime.utc': 'UTC',
  'ticket.devices.RMM.install': 'Installer Six Star RMM',
  'ticket.devices.RMM.login': 'Se connecter à Six Star RMM',
  'ticket.devices.RMM.title': 'Pour consulter les dernières informations',

  // Filtres de la table de données
  'dataTable.filters.reset': 'Réinitialiser',
  'dataTable.filters.loading': 'Chargement...',
  'dataTable.filters.header': 'Filtres',

  // Aperçu de la table de données
  'dataTable.preview.viewTicket': 'Voir le ticket',
  'dataTable.preview.quickNotes': 'Notes rapides',
  'dataTable.preview.contacts': 'Contacts',
  'dataTable.preview.followers': 'Abonnés',
  'dataTable.preview.relatedTickets': 'Tickets associés',
  'dataTable.preview.updateIn': 'Mise à jour dans',
  'dataTable.preview.devices': 'Appareils',
  'dataTable.preview.engineer': 'Ingénieur',
  'dataTable.preview.ticketCreated': 'Ticket créé :',

  // ChatGPT
  'chatgpt.somethingWentWrong': "Quelque chose s'est mal passé, veuillez réessayer",
  'chatgpt.history': 'Historique',

  // Bouton de groupe personnalisé
  'customGroupButton.one.expandAll': 'Développer tout',
  'customGroupButton.two.collapseAll': 'Réduire tout',

  // Numéros de drapeau
  'flagNumber.button.callUs': 'Appelez-nous',

  'HelpOptions.Title': 'Comment pouvons-nous vous aider?',
  'FloatingBeta.ReportIssue': 'Signaler un problème',
  'FloatingBeta.Feedback': 'Envoyer des commentaires',
  'dateTime.utc': 'UTC',
  'ticket.devices.RMM.install': 'Installer Six Star RMM',
  'ticket.devices.RMM.login': 'Se connecter à Six Star RMM',
  'ticket.devices.RMM.title': 'Pour voir les dernières informations',
  'dataTable.filters.reset': 'Réinitialiser',
  'dataTable.filters.loading': 'Chargement...',
  'dataTable.filters.header': 'Filtres',
  'dataTable.preview.viewTicket': 'Voir le ticket',
  'dataTable.preview.quickNotes': 'Notes rapides',
  'dataTable.preview.contacts': 'Contacts',
  'dataTable.preview.followers': 'Abonnés',
  'dataTable.preview.relatedTickets': 'Tickets associés',
  'dataTable.preview.updateIn': 'Mise à jour dans',
  'dataTable.preview.devices': 'Appareils',
  'dataTable.preview.engineer': 'Ingénieur',
  'dataTable.preview.ticketCreated': 'Ticket créé : ',
  'chatgpt.somethingWentWrong': "Quelque chose s'est mal passé. Veuillez réessayer.",
  'chatgpt.history': 'Historique',
  'customGroupButton.one.expandAll': 'Tout développer',
  'customGroupButton.two.collapseAll': 'Tout réduire',
  'flagNumber.button.callUs': 'Appelez-nous',
  'profile.menu.viewCompany': "Voir l'entreprise",
  'contactUsModals.addWidgetModal.title': 'Comment ajouter un widget à votre tableau de bord',
  'contactUsModals.addWidgetModal.step1': '1. Accédez à votre tableau de bord',
  'contactUsModals.addWidgetModal.step2': '2. Repérez le ruban violet en haut de votre écran',
  'contactUsModals.addWidgetModal.step3': '3. Cliquez sur le bouton Ajouter un widget',
  'contactUsModals.addWidgetModal.step4': '4. Sélectionnez un widget dans la liste',
  'contactUsModals.addWidgetModal.step5': '5. Cliquez sur le bouton Ajouter',
  'contactUsModals.signOutModal.title': 'Comment se déconnecter',
  'contactUsModals.signOutModal.step1': "1. Trouvez votre avatar en haut à droite de l'écran",
  'contactUsModals.signOutModal.step2': '2. Cliquez sur votre avatar',
  'contactUsModals.signOutModal.step3': '3. Trouvez le bouton de déconnexion dans le menu déroulant',
  'contactUsModals.signOutModal.step4': '4. Cliquez sur le bouton de déconnexion',
  'ticketViewPage.toolbar.label': 'Rechercher des tickets',
  'ticketViewPage.toolbar.view': 'Vue',
  'ticketViewPage.toolbar.saveView': 'Enregistrer la vue',
  'ticketViewPage.toolbar.saveViewPrompt': 'Veuillez entrer le nom sous lequel vous souhaitez enregistrer cette vue.',
  'ticketViewPage.toolbar.label.export': 'Exporter',
  'ticketViewPage.toolbar.label.filters': 'Filtres',
  'formLoader.submissionSuccess': "Merci d'avoir soumis le formulaire",
  'formLoader.submissionError': "Une erreur s'est produite lors de la soumission du formulaire",
  'reportIssue.submit': 'Envoyer',
  'reportIssue.issue': 'Problème...',
  'ticketViewPage.modal.button.bunle': 'Regrouper',
  'ticketViewPage.modal.button.back': 'Retour',
  'flagNumber.button.callUs': 'Appelez-nous',
  'ticketDocuments.notFound': 'Aucun document trouvé',
  'ticketViewPage.childTicketsNone': 'Aucun ticket enfant',
  'ticketViewPage.parentNone': 'Aucun ticket parent',
  'ticketViewPage.restricted': 'Restreint',
};
export default misc;
