import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";
import Button from "components/MuiOverride/Button";
import Paper from "@mui/material/Paper";
import TextField from "components/MuiOverride/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import styles from "./styles";
import SSGWhite from "../../images/ssgWhite.svg";
import SSGLoginPage from "../../images/six-star-global-login.png";
import Translation from "utils/translation";
import { isMobile } from "utils/menuHelper";

const ForgotPassword = ({
  classes,
  email,
  errorMessage,
  onEmailChange,
  onForgotPassword,
  onPasswordChange,
  onRememberMeChange,
  onSignIn,
  password,
  rememberMe,
  twoFactor,
  code,
  onCodeChange,
  loggingIn,
  onGoBack,
}) => {
  const [showEmailSentMessage] = useState(false);
  const [message] = useState(
    "Enter your e-mail address below to reset your password."
  );

  const LoginBoxHeader = () => {
    return (
      <div className={classNames(classes.LoginBoxHeader)}>
        <Typography color={"#2A203B"} variant={"h6"} align={"left"}>
          {Translation("login.forgotPasswordText")}
        </Typography>
      </div>
    );
  };

  const ErrorMessageBox = () => {
    return (
      <>
        {errorMessage ? (
          <Typography paragraph className={classes.errorMessage}>
            * {Translation(errorMessage)}
          </Typography>
        ) : null}
      </>
    );
  };

  const LoginForm = () => {
    return (
      <form>
        <Typography paragraph>{message}</Typography>
        <div>
          {showEmailSentMessage ? null : (
            <TextField
              metadata="forgot_password_email"

              label={Translation("loginPage.label.email")}
              fullWidth
              defaultValue={email}
              onBlur={onEmailChange}
            />
          )}

          <div className={classes.forgotPasswordButtonsContainer}>
            <Button
              metadata="forgot_password_go_back"
              color="error"
              variant="contained"
              onClick={onGoBack}
              className={classes.forgotPasswordGoBack}
            >
              {Translation("loginPage.ForgotPassword.GoBack")}
            </Button>

            {showEmailSentMessage ? null : (
              <Button
                metadata="forgot_password_send_email"
                color="primary"
                variant="contained"
                className={classes.forgotPasswordButton}
                onClick={() => console.log("send email")}
              >
                {Translation("loginPage.ForgotPassword.Submit")}
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  };

  const ForgotPasswordBox = () => {
    return (
      <div
        style={{marginRight: 135}}
        className={classNames(
          classes.boxContainer,
          isMobile() ? classes.mobileContainer : {}
        )}
      >
        <div className={classes.boxWrapper}>
          <Paper className={classes.paper} elevation={3} >
            <LoginBoxHeader />
            <ErrorMessageBox />
            <LoginForm />
          </Paper>
        </div>
      </div>
    );
  };

  return (
    // <>
    //   {/* <div className={classNames(classes.loginCallToAction)} >
    //     <Typography sx={{}}>{Translation('login.topCallToActionTitle')} </Typography> &nbsp;
    //     <Link underline="hover" href="#" fontWeight={600}>{Translation('login.topCallToActionLink')}</Link>
    //   </div> */}
    //   <div className={classNames(classes.loginContainer)} >
    //     <Grid container spacing={2} >
    //       <Grid className={classNames(classes.loginLeftSide)} item xs={4.5} sx={{ display: { xs: 'none', md: 'block' } }}>
    //         <Box sx={{ color: 'white' }} p={3}>
    //           <img src={SSGWhite} className={classNames(classes.leftSideLogo)} alt="Six Star Logo" />
    //           <Typography variant="h3" sx={{ textTransform: 'uppercase', fontWeight: 600 }}> {Translation('login.title')}</Typography>
    //           <Typography> {Translation('login.headline')}</Typography>
    //         </Box>

    //       </Grid>
    //       <Grid
    //         sx={{ backgroundColor: { xs: '#2A203B', md: 'white' } }}
    //         className={classNames(classes.loginRightSide)} item xs={12} md={7.5}>
    //         <ForgotPasswordBox />

    //       </Grid>
    //     </Grid>
    //   </div >
    // </>
    // // <div className={classes.boxContainer}>
    // //   <div className={classes.boxWrapper}>
    // //     <Paper className={classes.paper} elevation={3} square>
    // //       <div>
    // //         <div className={classes.title}>Forgot Password?</div>
    // //         <div className={classes.logoSmallContainer}>
    // //           <img
    // //             src="https://s3.amazonaws.com/fortressassets/logo-small.png"
    // //             alt="Fortress Admin Template"
    // //           />
    // //         </div>
    // //       </div>
    // //       <hr />
    // //       <Typography paragraph>{this.state.message}</Typography>
    // //       <form>
    // //         {showEmailSentMessage ? null : (
    // //           <TextField
    // //             label="E-mail"
    // //             fullWidth
    // //             defaultValue={email}
    // //             onBlur={onEmailChange}
    // //           />
    // //         )}

    // //         <div className={classes.buttonsContainer}>
    // //           <Button onClick={onGoBack}>Go Back</Button>

    // //           {showEmailSentMessage ? null : (
    // //             <Button
    // //               color="primary"
    // //               className={classes.boxBtn}
    // //               onClick={this.sentEmail}
    // //             >
    // //               Submit
    // //             </Button>
    // //           )}
    // //         </div>
    // //       </form>
    // //     </Paper>
    // //   </div>
    // // </div>
    <Box
      sx={{
        minWidth: { md: "427.05px" },
        width: { md: "427.05px" },
        flexDirection: { xs: "column", md: "row" },
      }}
      className={classNames(classes.loginContainer)}
    >
      <Grid
        container
        sx={{ position: "relative", display: { xs: "flex", md: "none" } }}
      >
        <Box
          className={classNames(classes.horizontalTopBar)}
          sx={{ display: "flex" }}
        >
          <Box sx={{ color: "white", padding: "10px", width: "70px" }} p={3}>
            <img
              src={SSGWhite}
              className={classNames(classes.topSideLogo)}
              alt="Six Star Logo"
            />
          </Box>
          <div className={classNames(classes.horizontalSpacer)} />
          <Box className={classNames(classes.topSideBarIcon)}>
            <i className="fa-regular fa-circle-question"></i>
          </Box>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid
          className={classNames(classes.loginLeftSide)}
          item
          xs={4.5}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Box sx={{ color: "white", padding: "10px" }} p={3}>
            <img
              src={SSGWhite}
              className={classNames(classes.leftSideLogo)}
              alt="Six Star Logo"
            />
            {/* <Typography variant="h3" sx={{ textTransform: 'uppercase', fontWeight: 600 }}> {Translation('login.title')}</Typography> */}
            {/* <Typography> {Translation('login.headline')}</Typography> */}
          </Box>
          <div className={classNames(classes.verticalSpacer)} />
          <Box className={classNames(classes.leftSideBarBottomIcon)}>
            <i className="fa-regular fa-circle-question"></i>
          </Box>
        </Grid>
        <Grid
          sx={{
            // backgroundColor: {
            //   xs: '#2A203B',
            //   md: 'white'
            // },
            backgroundImage: `url(${SSGLoginPage})`,
            backgroundSize: "cover",
            justifyContent: { xs: "center", md: "right" },
          }}
          className={classNames(classes.loginRightSide)}
          item
          xs={12}
          md={7.5}
        >
          <ForgotPasswordBox /> 
        </Grid>
      </Grid>
    </Box>
  );
};

ForgotPassword.propTypes = {
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(() => styles(), {
  withTheme: true,
})(ForgotPassword);
