import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  SidePanelBoldTitle,
  SidePanelItemCard,
  SidePanelParagraph,
} from "containers/Pages/TicketViewPage/components/styled";
import SidePanelItem from 'containers/Layout/ThreePanelView/components/SidePanelItem';
import Translation from 'utils/translation';
import usePermissions from 'containers/App/Hooks/usePermissions';
import { useSelector } from 'react-redux';
import CompanyInfo from '../CompanyInfo';
import AccountManagers from '../AccountManagers';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import graphQLQueries from 'graphql/person';
import { Autocomplete, TextField } from '@mui/material';
import { useLazyQuery,gql, useMutation } from '@apollo/client';
import {
  TicketPodModal,
  TicketPodModalHeader,
  AddContactModalContent,
  TicketPodAddButtonContainer,
  ModalCancelButton,
  ModalAddButton,
} from 'containers/Pages/TicketViewPage/components/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';

const EditManager = (props) => {
  const {company, handleClose} = props;
  const { GET_ENGINEER_ALL_NAME } = graphQLQueries();
  const [contactID, setContactID] = useState();
  const { id } = useParams();
  const [error, setError] = useState();


  const [getAgents, { loading: loadingAgents, error: errorAgents, data: dataAgents }] = useLazyQuery(GET_ENGINEER_ALL_NAME);

  useEffect(() => {
    getAgents();
  }, []);

  let ADD_ACCOUNT_MANAGER = gql`
  mutation MyMutation($organization_id: uuid = "", $person_id: uuid = "") {
  insert_organization_account_managers(objects: {organization_id: $organization_id, person_id: $person_id}) {
    affected_rows
  }
}
  `;

  const [addAccountManager] = useMutation(ADD_ACCOUNT_MANAGER);

  let DELETE_ACCOUNT_MANAGER = gql`
  mutation MyMutation($organization_id: uuid = "", $person_id: uuid = "") {
  delete_organization_account_managers(where: {organization_id: {_eq: $organization_id}, person_id: {_eq: $person_id}}){
  affected_rows
  }
}
  `;

  const [deleteAccountManager] = useMutation(DELETE_ACCOUNT_MANAGER);






  const handleAddManager = () => {
    if (!contactID) {
      setError('Please select a manager');
      return;
    }

    addAccountManager({
      variables: {
        organization_id: id,
        person_id: contactID
      }
    });
    handleClose();
    setContactID('');
  };

  const handleDelete = (removeID) => {
    deleteAccountManager({
      variables: {
        organization_id: id,
        person_id: removeID
      }
    });
  };


    return (
      <>
            {/* <TicketPodModalHeader>
            <Typography variant='h6'>{Translation('ticketViewPage.addManager')}</Typography>
            <FontAwesomeIcon icon={['fas', 'user-tie']} style={{ marginLeft: 4 }} />
            <FontAwesomeIcon icon={['fas', 'times']} onClick={handleClose} style={{ marginLeft: 'auto', cursor: 'pointer' }} />
          </TicketPodModalHeader> */}
          
      <Box sx={{  
       display: 'flex', flexDirection: 'column', gap: 2 
      }}>
       
        <Box sx={{marginTop: 2, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 2 }}>
        {error && <Typography color='error'>{error}</Typography>}
        <Typography>{Translation('editManager.currentManagers')}</Typography>
        </Box>
      <Stack direction="row" spacing={1} sx={{}}>
         {company?.organization?.[0]?.account_owners?.map((account_manager, index) => (
      <Chip label={account_manager?.person?.name} key={index} variant="outlined" onDelete={() => handleDelete(account_manager?.person?.id)} />
         ))}
    </Stack>

    <Box sx={{
      alignItems: 'center', 
      justifyContent: 'center', 
      gap: 2,
      display: 'flex',
      flexDirection:'column',
      marginBottom: 2
    }}>
      <Typography>{Translation('editManager.addNew')}</Typography>
    </Box> 

    <Autocomplete
                    loading={loadingAgents}
                    disablePortal
                    id='combo-box-demo'
                    options={dataAgents?.person ? dataAgents?.person : []}
                    onChange={(event, value) => {
                
                      setContactID(value?.id);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label='Manager' />}
                    getOptionLabel={(option) => option.name}
                  />

<Box sx={{
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center',
   justifyContent: 'center',
   gap: 1,
  // width: '95%',
   marginBottom: 2,
  // marginLeft: theme.spacing(2),
  marginTop: 4,
}}>
            <ModalCancelButton variant='contained' 
             onClick={handleClose}
            >
              Cancel
            </ModalCancelButton>
            <ModalAddButton variant='contained'
            onClick={handleAddManager}
             >
              Add
            </ModalAddButton>
          </Box>
  </Box>
    </>
    )
}

export default EditManager;