import { useSelector } from 'react-redux';

export const usePermissions = (REQUIRED_PERMISSION) => {
  const user = useSelector((state) => state?.user?.user);

  const { access } = user?.permissions;
  const { organizations } = user?.permissions;

  let result = {}

  if (!REQUIRED_PERMISSION) return false;

  if (REQUIRED_PERMISSION?.access) {
    const accessResult = checkAccess({ REQUIRED_ACCESS: REQUIRED_PERMISSION.access, ACCESS: access });
    result = {
      ...result,
      access: accessResult,
    }
  }


  if (REQUIRED_PERMISSION?.organizations) {
    const organizationResult = checkOrganization({ REQUIRED_ACCESS: REQUIRED_PERMISSION.organizations, ORGANIZATIONS: organizations });
    result = {
      ...result,
      organizations: organizationResult,
    }
  }

  if (REQUIRED_PERMISSION?.access?.priority || REQUIRED_PERMISSION?.organizations?.priority) {
    result = checkOrder({ REQUIRED_PERMISSION, RESULT: result })
  }

  return result
};

const checkOrder = ({ REQUIRED_PERMISSION, RESULT }) => {
  let accessOrder = 0
  let organizationOrder = 0

  if (REQUIRED_PERMISSION?.access?.priority) {
    accessOrder = REQUIRED_PERMISSION?.access.priority
  }

  if (REQUIRED_PERMISSION?.organizations?.priority) {
    organizationOrder = REQUIRED_PERMISSION?.organizations.priority
  }

  if (accessOrder > 0 && organizationOrder > 0) {
    if (accessOrder < organizationOrder) {
      return RESULT.access
    }
    else{
      return RESULT.organizations
    }
  }

  return RESULT
}

const checkOrganization = ({ REQUIRED_ACCESS, ORGANIZATIONS }) => {
  if (!REQUIRED_ACCESS || !ORGANIZATIONS) return false
  if (typeChecker({ type: REQUIRED_ACCESS.type }) === false) return false

  let result = false
  let count = 0

  ORGANIZATIONS.map((organization) => {
    REQUIRED_ACCESS.permissions.map((requiredAccess) => {
      if (organization?.organization?.id === requiredAccess.organization) {
        organization.access.map((access) => {
          if (access.key === requiredAccess.key && access.value === requiredAccess.value) {
            result = true
            count++;
          }
        })
      }
    })
  })

  if (REQUIRED_ACCESS.type === 'all' && count !== REQUIRED_ACCESS.permissions.length) {
    result = false
  }

  return result
}


const checkAccess = ({ REQUIRED_ACCESS, ACCESS }) => {
  if (!REQUIRED_ACCESS || !ACCESS) return false

  if (typeChecker({ type: REQUIRED_ACCESS.type }) === false) return false

  let result = false
  let count = 0

  ACCESS.map((access) => {
    REQUIRED_ACCESS.permissions.map((requiredAccess) => {
      if (access.key === requiredAccess.key && access.value === requiredAccess.value) {
        result = true
        count++;
      }
    })
  })

  if (REQUIRED_ACCESS.type === 'all' && count !== REQUIRED_ACCESS.permissions.length) {
    result = false
  }

  return result
}

const typeChecker = ({ type }) => {
  if (type !== 'one' && type !== 'all') {
    // console.log('Invalid type, Type must be one or all')
    return false
  } else {
    return true
  }
}


export default usePermissions;