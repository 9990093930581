import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_LOCALE } from "i18n";

const initialState = {
  locale: DEFAULT_LOCALE,
};

export const language = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLocale: (state, action) => {
      state.locale = action.payload;
    },
  },
});

export const { changeLocale } = language.actions;

export default language.reducer;
