const styles = (theme) => ({
  footer: {
    padding: theme.spacing(2),
    overflow: "auto",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "space-between",
    color: "black",
    fontWeight: 400,
    alignItems: "center",
  },
  scrollMenu: {
    // backgroundColor: "#333",
    overflow: "auto",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
  },
  scrollMenuItem: {
    display: "inline-block",
    color: "white",
    textAlign: "center",
    padding: "14px",
    textDecoration: "none",
  },
  filterItems: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary.main,
      borderWidth: "2px",
    },
  },
});

export default styles;
