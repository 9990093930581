const menu = {
  "menu.manage.dashboard.tabText": "Tablero",
  "menu.manage.dashboard.menuText": "Tablero",
  "menu.manage.supportSection.text": "Soporte",
  "menu.manage.supportSection.menuText": "Soporte",
  "menu.manage.supportSection.dashboard.tabText": "Tablero",
  "menu.manage.supportSection.dashboard.menuText": "Tablero",
  "menu.manage.supportSection.newRequest.tabText": "Nueva Solicitud",
  "menu.manage.supportSection.newRequest.menuText": "Nueva Solicitud",
  "menu.manage.supportSection.myTicketsOverview.tabText": "Resumen de Mis Tickets",
  "menu.manage.supportSection.myTicketsOverview.menuText": "Resumen de Mis Tickets",
  "menu.manage.supportSection.approvals.tabText": "Aprobaciones",
  "menu.manage.supportSection.approvals.menuText": "Aprobaciones",
  "menu.manage.sixstarMeet.tabText": "Six Star Encuentro",
  "menu.manage.sixstarMeet.menuText": "Six Star Encuentro",
  "menu.manage.manageSixStarConnect.tabText": "Six Star Conectar",
  "menu.manage.manageSixStarConnect.menuText": "Six Star Conectar",
  "menu.manage.manageManageSection.text": "Gestionar",
  "menu.manage.manageManageSection.menuText": "Gestionar",
  "menu.manage.manageManageSection.dashboard.tabText": "Tablero",
  "menu.manage.manageManageSection.dashboard.menuText": "Tablero",
  "menu.manage.manageSection.text": "Gestionar",
  "menu.manage.manageSection.menuText": "Gestionar",
  "menu.manage.manageSection.documents.tabText": "Documentos",
  "menu.manage.manageSection.documents.menuText": "Documentos",
  "menu.manage.manageSection.securityReports.tabText": "Informes de Seguridad",
  "menu.manage.manageSection.securityReports.menuText": "Informes de Seguridad",
  "menu.manage.manageSection.serviceReports.tabText": "Informes de Servicio",
  "menu.manage.manageSection.serviceReports.menuText": "Informes de Servicio",
  "menu.manage.manageSection.licenceManagement.tabText": "Gestión de Licencias",
  "menu.manage.manageSection.licenceManagement.menuText": "Gestión de Licencias",
  "menu.manage.manageSection.assetManagement.tabText": "Gestión de Activos",
  "menu.manage.manageSection.assetManagement.menuText": "Gestión de Activos",
  "menu.manage.manageSection.upcomingExpiries.tabText": "Próximas Expiraciones",
  "menu.manage.manageSection.upcomingExpiries.menuText": "Próximas Expiraciones",
  "menu.manage.manageSection.bookACallWithYourAccountManager.tabText": "Reservar una Llamada con su Gerente de Cuenta",
  "menu.manage.manageSection.bookACallWithYourAccountManager.menuText": "Reservar una Llamada con su Gerente de Cuenta",
  "menu.manage.financeSection.text": "Finanzas",
  "menu.manage.financeSection.menuText": "Finanzas",
  "menu.manage.financeSection.dashboard.tabText": "Tablero",
  "menu.manage.financeSection.dashboard.menuText": "Tablero",
  "menu.manage.financeSection.ordersQuotesInvoices.tabText": "Pedidos/Cotizaciones/Facturas",
  "menu.manage.financeSection.ordersQuotesInvoices.menuText": "Pedidos/Cotizaciones/Facturas",
  "menu.manage.financeSection.creditApplication.tabText": "Solicitud de Crédito",
  "menu.manage.financeSection.creditApplication.menuText": "Solicitud de Crédito",
  "menu.manage.helpSection.text": "Ayuda",
  "menu.manage.helpSection.menuText": "Ayuda",
  "menu.manage.helpSection.contactUs.tabText": "Contáctenos",
  "menu.manage.helpSection.contactUs.menuText": "Contáctenos",
  "menu.manage.helpSection.giveFeedback.tabText": "Dar Retroalimentación",
  "menu.manage.helpSection.giveFeedback.menuText": "Dar Retroalimentación",
  "menu.manage.helpSection.guides.tabText": "Guías",
  "menu.manage.helpSection.guides.menuText": "Guías",
  "menu.manage.helpSection.customerForms.tabText": "Formularios para Clientes",
  "menu.manage.helpSection.customerForms.menuText": "Formularios para Clientes",
  "menu.manage.engineerSection.text": "Ingeniero",
  "menu.manage.engineerSection.menuText": "Ingeniero",
  "menu.manage.engineerSection.myTasks.tabText": "Mis Tareas",
  "menu.manage.engineerSection.myTasks.menuText": "Mis Tareas",
  "menu.manage.engineerSection.ticketBoard.tabText": "Tickets",
  "menu.manage.engineerSection.ticketBoard.menuText": "Tickets",
  "menu.manage.engineerSection.triageTickets.tabText": "Tickets de Triaje",
  "menu.manage.engineerSection.triageTickets.menuText": "Tickets de Triaje",
  "menu.manage.engineerSection.priorityTickets.tabText": "Tickets Prioritarios",
  "menu.manage.engineerSection.priorityTickets.menuText": "Tickets Prioritarios",
  "menu.manage.engineerSection.escalations.tabText": "Escalaciones",
  "menu.manage.engineerSection.escalations.menuText": "Escalaciones",
  "menu.manage.internalSection.text": "Interno",
  "menu.manage.internalSection.menuText": "Interno",
  "menu.manage.internalSection.orderTracker.tabText": "Seguimiento de Pedidos",
  "menu.manage.internalSection.orderTracker.menuText": "Seguimiento de Pedidos",
  "menu.manage.internalSection.resourceTracker.tabText": "Seguimiento de Recursos",
  "menu.manage.internalSection.resourceTracker.menuText": "Seguimiento de Recursos",
  "menu.manage.internalSection.knowledgeBase.tabText": "Base de Conocimiento",
  "menu.manage.internalSection.knowledgeBase.menuText": "Base de Conocimiento",
  "menu.manage.internalSection.twoFactor.tabText": "Doble Factor",
  "menu.manage.internalSection.twoFactor.menuText": "Doble Factor",
  "menu.manage.internalSection.internal.tabText": "Interno",
  "menu.manage.internalSection.internal.menuText": "Interno",
  "menu.manage.internalSection.expenses.tabText": "Gastos",
  "menu.manage.internalSection.expenses.menuText": "Gastos",
  "menu.manage.userSection.text": "Usuario",
  "menu.manage.userSection.menuText": "Usuario",
  "menu.manage.userSection.createContact.tabText": "Crear Contacto",
  "menu.manage.userSection.createContact.menuText": "Crear Contacto",
  "menu.manage.userSection.contactSearch.tabText": "Buscar Contacto",
  "menu.manage.userSection.contactSearch.menuText": "Buscar Contacto",
  "menu.manage.userSection.zoomPresence.tabText": "Presencia en Zoom",
  "menu.manage.userSection.zoomPresence.menuText": "Presencia en Zoom",
  "menu.manage.userSection.zoomCallQueues.tabText": "Colas de Llamadas en Zoom",
  "menu.manage.userSection.zoomCallQueues.menuText": "Colas de Llamadas en Zoom",
  "menu.manage.userSection.mergeContacts.tabText": "Fusionar Contactos",
  "menu.manage.userSection.mergeContacts.menuText": "Fusionar Contactos",
  "menu.manage.adminSection.text": "Administrador",
  "menu.manage.adminSection.menuText": "Administrador",
  "menu.manage.adminSection.dashboard.tabText": "Tablero",
  "menu.manage.adminSection.dashboard.menuText": "Tablero",
  "menu.manage.adminSection.impersonate.tabText": "Suplantar",
  "menu.manage.adminSection.impersonate.menuText": "Suplantar",
  "menu.manage.supportSection.newRequestDashboard.tabText": "Nueva Solicitud",
  "menu.manage.supportSection.newRequestDashboard.menuText": "Nueva Solicitud",
  "menu.manage.supportSection.newRequestForm.tabText.supportRequest": "Solicitud de Soporte",
  "menu.manage.supportSection.newRequestForm.tabText.serviceRequest": "Solicitud de Servicio",
  "menu.manage.supportSection.newRequestForm.tabText.procurementRequest": "Solicitud de Adquisición",
  "menu.manage.supportSection.newRequestForm.tabText.internalRequest": "Solicitud Interna",
  "menu.manage.supportSection.newRequestForm.tabText.passwordReset": "Restablecimiento de Contraseña",
  "menu.manage.supportSection.newRequestForm.tabText.newStarter": "Nuevo Inicio / Cambio de Rol",
  "menu.manage.supportSection.newRequestForm.tabText.leaverRequest": "Solicitud de Salida",
  "menu.manage.supportSection.newRequestForm.tabText.engineerVisit": "Visita del Ingeniero",
  "menu.manage.supportSection.newRequestForm.tabText.developmentRequest": "Solicitud de Desarrollo",
  "menu.manage.engineerSection.ticketViewPage.tabText": "Ticket# {id}",
  "menu.manage.engineerSection.ticketViewPage.menuText": "Ticket {id}",
  "menu.manage.engineerSection.ticketsBoard.tabText": "Tickets",
  "menu.manage.engineerSection.ticketsBoard.menuText": "Tickets",
  "menu.manage.supportSection.newRequestForm.tabText": "{id}",
  "menu.manage.supportSection.newRequestForm.menuText": "{id}",
  "menu.manage.engineerSection.drive.tabText": "Six Star Drive",
  "menu.manage.engineerSection.drive.menuText": "Six Star Drive",
  "menu.manage.engineerSection.reports.tabText": "Informes",
  "menu.manage.engineerSection.reports.menuText": "Informes",
  "menu.manage.engineerSection.companyProfile.tabText": "Mi Organización",
  "menu.manage.engineerSection.companyProfile.menuText": "Mi Organización",
  "menu.manage.engineerSection.chatGPT.tabText": "Chat GPT",
  "menu.manage.engineerSection.chatGPT.menuText": "Chat GPT",
  "menu.manage.financeSection.Invoices.tabText": "Facturas",
  "menu.manage.financeSection.Invoices.menuText": "Facturas",
  "menu.manage.financeSection.quotesProposals.tabText": "Cotizaciones",
  "menu.manage.financeSection.quotesProposals.menuText": "Cotizaciones",
  "menu.manage.financeSection.creditTerms.tabText": "Términos de Crédito",
  "menu.manage.financeSection.creditTerms.menuText": "Términos de Crédito",
  "menu.manage.internalSection.impersonate.tabText": "Suplantar",
  "menu.manage.internalSection.impersonate.menuText": "Suplantar",
  "menu.manage.managedServicesSection.text": "Servicios Gestionados",
  "menu.manage.managedServicesSection.menuText": "Servicios Gestionados",
  "menu.manage.managedServicesSection.dashboard.tabText": "Tablero",
  "menu.manage.managedServicesSection.dashboard.menuText": "Tablero",
  "menu.manage.managedServicesSection.serviceOverview.tabText": "Resumen del Servicio",
  "menu.manage.managedServicesSection.serviceOverview.menuText": "Resumen del Servicio",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.tabText": "Seguridad de Punto Final",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.menuText": "Seguridad de Punto Final",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.tabText": "Respaldo",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.menuText": "Respaldo",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.tabText": "Formularios Seguros",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.menuText": "Formularios Seguros",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.tabText": "Visitas al Sitio Programadas",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.menuText": "Visitas al Sitio Programadas",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.tabText": "Eliminación de Residuos Electrónicos",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.menuText": "Eliminación de Residuos Electrónicos",
  "menu.manage.sixStarSecureSection.text": "Six Star Seguro",
  "menu.manage.sixStarSecureSection.menuText": "Six Star Seguro",
  "menu.manage.sixStarSecureSection.dashboard.tabText": "Tablero",
  "menu.manage.sixStarSecureSection.dashboard.menuText": "Tablero",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.tabText": "Protección de Punto Final (Antivirus y Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.menuText": "Protección de Punto Final (Antivirus y Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.tabText": "Detección y Respuesta de Punto Final",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.menuText": "Detección y Respuesta de Punto Final",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.tabText": "Gestión de Cortafuegos",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.menuText": "Gestión de Cortafuegos",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.tabText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.menuText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.tabText": "Cifrado de Dispositivo",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.menuText": "Cifrado de Dispositivo",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.tabText": "Gestión de Office 365/Azure",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.menuText": "Gestión de Office 365/Azure",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.tabText": "Respaldo y Recuperación de Desastres",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.menuText": "Respaldo y Recuperación de Desastres",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.tabText": "Informes de Seguridad",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.menuText": "Informes de Seguridad",
  "menu.manage.softwareLicenceManagementSection.text": "Gestión de Licencias de Software",
  "menu.manage.softwareLicenceManagementSection.menuText": "Gestión de Licencias de Software",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.tabText": "Catálogo de Software Autorizado",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.menuText": "Catálogo de Software Autorizado",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.tabText": "Gestión de Licencia",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.menuText": "Gestión de Licencia",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.tabText": "Gestión de Software",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.menuText": "Gestión de Software",
  "menu.manage.assetManagementSection.text": "Gestión de Activos",
  "menu.manage.assetManagementSection.menuText": "Gestión de Activos",
  "menu.manage.assetManagementSection.assetManagementDashboard.tabText": "Panel de Control",
  "menu.manage.assetManagementSection.assetManagementDashboard.menuText": "Panel de Control",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.tabText": "Catálogo de Hardware Autorizado",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.menuText": "Catálogo de Hardware Autorizado",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.tabText": "Gestión de Dispositivos",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.menuText": "Gestión de Dispositivos",
  "menu.manage.sixStarAdditionsSection.text": "Adiciones de Six Star",
  "menu.manage.sixStarAdditionsSection.menuText": "Adiciones de Six Star",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.tabText": "Soporte para Dispositivos de Huéspedes",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.menuText": "Soporte para Dispositivos de Huéspedes",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.tabText": "Six Star: Voz",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.menuText": "Six Star: Voz",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.tabText": "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.menuText": "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.tabText": "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.menuText": "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.tabText": "Six Star: Reuniones",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.menuText": "Six Star: Reuniones",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.tabText": "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.menuText": "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.tabText": "Six Star: Transmisión",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.menuText": "Six Star: Transmisión",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.tabText": "Six Star: Pago",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.menuText": "Six Star: Pago",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.tabText": "Six Star: Pantalla",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.menuText": "Six Star: Pantalla",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.tabText": "Six Star: Parque",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.menuText": "Six Star: Parque",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.tabText": "Six Star: Negro",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.menuText": "Six Star: Negro",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.tabText": "Six Star: Impresión",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.menuText": "Six Star: Impresión",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.tabText": "Six Star: Internet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.menuText": "Six Star: Internet",
  "menu.manage.userSection.editContact.tabText": "Editar contacto",
"menu.manage.userSection.editContact.menuText": "Editar contacto",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.tabText": "Nóminas",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.menuText": "Nóminas",
};
export default menu;
