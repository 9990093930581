import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import ForgotPassword from "components/Auth/forgotPassword";
import Login from "components/Auth/login";
import {
  signIn,
  clearAuthenticationMessage,
  clearTwoFactor,
  clearLoggingIn,
  register,
  resetPassword,
  signInError,
  setTwoFactor,
} from "slices/user";
import axios from "axios";
import { loginSSO } from "containers/App/websocket/login";
import generateRandomCode from "utils/generateRandomCode";
import { setSSOCode } from "slices/socket";

class AuthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: {
        email: process.env.REACT_APP_TEST_LOGIN_USERNAME ?? "", // default values, leave it empty when implementing your logic
        password: process.env.REACT_APP_TEST_LOGIN_PASSWORD ?? "", // default values, leave it empty when implementing your logic
        rememberMe: true,
        code: null,
      },
      // register: {
      //   fullName: 'John Smith',
      //   email: 'demo@test.com',
      //   password: 'demo',
      //   confirmPassword: 'demo',
      // },
      forgotPassword: {
        email: process.env.REACT_APP_TEST_LOGIN_USERNAME ?? "",
      },
      showForgotPassword: false,
      showRegister: false,
      errorMessage: props.authenticationErrorMessage,
      twoFactor: props.authenticationTwoFactor,
      loggingIn: props.userIsLoggingIn,
      ssoCode: props.ssoCode,
      ssoLoginUrl: props.ssoLoginUrl,
      showSSOLogin: props.ssoLoginUrl ? true : false,
    };
  }

  componentDidMount() {
    this.props.dispatch(clearAuthenticationMessage());
    this.props.dispatch(clearTwoFactor());
    this.props.dispatch(clearLoggingIn());
    this.signInSso();
  }

  componentWillUnmount() {
    this.state = null;
  }

  // componentDidUpdate(prevProps) {
  //   if (!this.props.ssoCode) {
  //     this.props.dispatch(clearAuthenticationMessage());
  //     this.props.dispatch(clearTwoFactor());
  //     this.props.dispatch(clearLoggingIn());
  //     this.signInSso()
  //   }
  //   else {
  //     console.log('************************************************************')
  //     console.log(this.props.ssoCode)
  //     console.log('************************************************************')
  //   }
  //   if (!this.props.ssoLoginUrl) {
  //     this.props.dispatch(clearAuthenticationMessage());
  //     this.props.dispatch(clearTwoFactor());
  //     this.props.dispatch(clearLoggingIn());
  //     this.signInSso()
  //   }
  // }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.ssoLoginUrl !== prevProps.ssoLoginUrl) {
      return {
        ssoLoginUrl: nextProps.ssoLoginUrl,
        showSSOLogin: nextProps.ssoLoginUrl ? true : false,
      };
    }
    if (
      nextProps.authenticationErrorMessage !==
      prevProps.authenticationErrorMessage ||
      nextProps.authenticationTwoFactor !== prevProps.authenticationTwoFactor ||
      nextProps.userIsLoggingIn !== prevProps.userIsLoggingIn
    ) {
      return {
        errorMessage: nextProps.authenticationErrorMessage,
        twoFactor: nextProps.authenticationTwoFactor,
        loggingIn: nextProps.userIsLoggingIn,
      };
    }

    return null;
  }

  signIn = (props) => {
    const { login, twoFactor } = this.state;
    // console.log(login)
    // console.log({ "mfa code***************": twoFactor })

    const { email, password, code } = login;
    // validations goes here
    // const type = 'username'
    let data = {
      account_id: process.env.REACT_APP_ACCOUNT_ID,
      email_address: email.toLocaleLowerCase(),
      password: password,
    };

    if (code !== null && code !== "") {
      data = {
        ...data,
        totp: code,
      };
    }

    data = JSON.stringify(data);
    const url = process.env.REACT_APP_API_REST + "/login";
    let config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log(process.env.REACT_APP_API_REST + "/login");

    axios(config)
      .then(function (response) {
        // console.log(response.status);
        if (response.status === 400) {
          // log error message to user
          // console.log(response.data.error);
        }
        if (response.status !== 200) throw new Error("Server Error");
        if (!response.data) throw new Error("No Responce Data");
        if (!response?.data?.login_person) throw new Error("No Person Data");

        if (response?.data?.login_person?.mfa === true) {
          // console.log('need to enter mfa')
          // console.log(response.data)
          // console.log(twoFactor)
          props.dispatch(setTwoFactor(true));
        } else {
          // console.log('no mfa need log in user')
          // console.log(response.data)

          let data = response.data.login_person;
          // console.log(data)
          let loginData = {
            token: response.data.login_person.token,
            refreshToken: response.data.login_person.refresh,
          };
          delete data.token;
          delete data.refresh;
          loginData = {
            ...loginData,
            user: data,
          };
          loginData = {
            ...loginData,
            user: {
              ...loginData.user,
            },
          };

          props.dispatch(signIn(loginData));
        }
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          props.dispatch(signInError(error.response.data.error));
        }
        console.log(error);
      });
  };

  signInSso = async () => {
    // console.log('sign in sso')
    let ssoCode;

    if (!this.state.ssoCode.code || this.state.ssoCode.expires < Date.now()) {
      let code = generateRandomCode(56);
      ssoCode = { code: code, expires: new Date().getTime() + 60 * 1000 * 1 };
      this.props.dispatch(setSSOCode(ssoCode));
    }

    let data = ssoCode?.code ?? this.state.ssoCode.code;

    // console.log(data)

    const loginSSOResult = await this.props.dispatch(loginSSO(data));
    // why is this being dispatched?
    // console.log({ "loginSSOResult": loginSSOResult })

    let { result } = loginSSOResult;
    // if (result.error === false) {
    //   console.log(result.data);
    // }
  };

  loginEmailChanged = (event) => {
    const email = event.target.value;
    const { login } = this.state;
    const loginModified = Object.assign({}, login, { email });

    this.setState({
      login: loginModified,
    });
    this.props.dispatch(clearTwoFactor());
    this.props.dispatch(clearAuthenticationMessage());
  };

  loginPasswordChanged = (event) => {
    const password = event.target.value;
    const { login } = this.state;
    const loginModified = Object.assign({}, login, { password });

    this.setState({
      login: loginModified,
    });
    this.props.dispatch(clearAuthenticationMessage());
  };
  loginTwoFactorChanged = (event) => {
    const code = event.target.value;
    // console.log(code);
    const { login } = this.state;
    const codeModified = Object.assign({}, login, { code });

    this.setState({
      login: codeModified,
    });

    this.props.dispatch(clearAuthenticationMessage());
  };
  loginRememberMeChanged = (event) => {
    const rememberMe = event.target.checked;
    const { login } = this.state;
    const loginModified = Object.assign({}, login, { rememberMe });

    this.setState({
      login: loginModified,
    });
  };

  registerUser = () => {
    // validations goes here
    const { fullName, email, password } = this.state;
    const payload = {
      fullName,
      email: email.toLocaleLowerCase(),
      password,
    };

    this.props.dispatch(register(payload));
  };

  registerFullNameChanged = (event) => {
    const fullName = event.target.value;
    const registerState = this.state.register;
    const registerModified = Object.assign({}, registerState, { fullName });

    this.setState({
      register: registerModified,
    });
  };

  registerEmailChanged = (event) => {
    const email = event.target.value;
    const registerState = this.state.register;
    const registerModified = Object.assign({}, registerState, { email });

    this.setState({
      register: registerModified,
    });
  };

  registerPasswordChanged = (event) => {
    const password = event.target.value;
    const registerState = this.state.register;
    const registerModified = Object.assign({}, registerState, { password });

    this.setState({
      register: registerModified,
    });
  };

  registerConfirmPasswordChanged = (event) => {
    const confirmPassword = event.target.value;
    const registerState = this.state.register;
    const registerModified = Object.assign({}, registerState, {
      confirmPassword,
    });

    this.setState({
      register: registerModified,
    });
  };

  resetPassword = () => {
    // validations goes here
    const payload = {
      email: this.state.forgotPassword.email.toLocaleLowerCase(),
    };

    this.props.dispatch(resetPassword(payload));
  };

  forgotPasswordEmailChanged = (event) => {
    this.setState({
      forgotPassword: {
        email: event.target.value,
      },
    });
  };

  showLogin = () => {
    this.setState({
      // showRegister: false,
      showForgotPassword: false,
    });
  };

  showRegister = () => {
    this.setState({
      // showRegister: true,
      showForgotPassword: false,
    });
  };

  showForgotPassword = () => {
    this.setState({
      // showRegister: false,
      showForgotPassword: true,
    });
  };

  render() {
    const { login, errorMessage, forgotPassword, twoFactor, loggingIn } =
      this.state;

    return (
      <div>
        {this.state.showForgotPassword ? (
          <ForgotPassword
            email={forgotPassword.email}
            onEmailChange={this.forgotPasswordEmailChanged}
            onGoBack={this.showLogin}
          />
        ) : (
          <Login
            email={login.email}
            onEmailChange={this.loginEmailChanged}
            password={login.password}
            onPasswordChange={this.loginPasswordChanged}
            onSignIn={() => this.signIn(this.props)}
            onSignInSso={() => {
              this.props.dispatch(signInError("login.sso.starting"));
              let url = this.state.ssoLoginUrl;
              window.open(url, "", "width=500, height=500");
            }}
            showSSOLogin={this.state.showSSOLogin}
            onForgotPassword={this.showForgotPassword}
            onRegister={this.showRegister}
            rememberMe={login.rememberMe}
            code={login.code}
            onCodeChange={this.loginTwoFactorChanged}
            onRememberMeChange={this.loginRememberMeChanged}
            errorMessage={errorMessage}
            twoFactor={twoFactor}
            loggingIn={loggingIn}
            theme={this.props.theme}
          />
        )}
      </div>
    );
  }
}

AuthPage.propTypes = {
  authenticationErrorMessage: PropTypes.string.isRequired,
  authenticationTwoFactor: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = (state) => {
  return {
    authenticationErrorMessage: state.user.authenticationErrorMessage,
    authenticationTwoFactor: state.user.twoFactor,
    userIsAuthenticated: state.user.userIsAuthenticated,
    userIsLoggingIn: state.user.userIsLoggingIn,
    ssoCode: state.socket.SSOCode,
    ssoLoginUrl: state.socket.SSOLoginURL,
    counter: state.counter,
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AuthPage);
