import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { signIn, signInSSO, signOut, userLoaded, updateMsssoError } from 'slices/user';
import { setGmAgentWidgets } from 'slices/widgets'
import { loginSSO, logOutSocketServer } from 'containers/App/websocket/login';
import generatePermissions from 'utils/generatePermissions';
import { socketStatus } from 'slices/socket';
import graphQLQueries from 'graphql/ticket';
import { useLazyQuery } from '@apollo/client';
import { setUsersNoDataWidgets } from 'slices/widgets';


const userMiddleware = createListenerMiddleware();

const HASURA_OPERATION = `query MyQuery($id: uuid = "") {
    person(where: {id: {_eq: $id}}) {
    account_id
      avatar
    id
    email_address
    alias
    first_name
    last_name
    middle_name
    name
    first_time_setup
    permissions {
      id
      individual {
        id
        access {
            value
            access_key {
              key
              name
            }
            organization {
                 id
                 name
                   avatar
                }
          }
          boards {
            id
            value
             organization {
                 id
                 name
                   avatar
                }
            board {
              name
              id
            }
          }
          teams {
            id
            value
             organization {
                 id
                 name
                   avatar
                }
            team {
              name
              id
            }
          }
      }
      groups {
        group_permission {
          id
          key
          name
          description
          access {
            value
            access_key {
              key
              name
            }
          }
           teams {
            id
            value
            team {
              name
              id
            }
          }
          boards {
            id
            value
            board {
              name
              id
            }
          }
        }
      }
      organizations {
        organization_permission {
          id
          organization {
          id
            name
            avatar
          }
          access {
            value
            access_key {
              key
              name
            }
          }
           teams {
            id
            value
            team {
              name
              id
            }
          }
          boards {
            id
            value
            board {
              name
              id
            }
          }
        }
      }
      
    }
    organization {
      name
      id
      alias
      parent_organization_id
      ldap_configurations {
        id
        host
        group_dn
        dn
        dir_sync
        account_id
      }
      external_organization_identifiers {
        id
        identifier
        metadata
        type_id
      }
    }
  }
}

`;
const GET_LAST_X_DAYS_TICKETS = `query LastXDays($_date: timestamptz = "03/10/3000") {
  ticket(where: {created_at: {_gte: $_date}, status: {_not: {resolved: {_eq: false}}}}, order_by: {created_at: asc}) {
    id
    created_at
    priority_id
    status_id
  }
  ticket_aggregate(where: {created_at: {_gte: $_date}}) {
    aggregate {
      count
    }
  }
}`;

const executeLastXDaysTickets = async (variables, token) => {
  const fetchResponse = await fetch(
    process.env.REACT_APP_API_SERVER,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: GET_LAST_X_DAYS_TICKETS,
        variables,
      }),
    },
  );
  const jsonResponse = await fetchResponse.json();
  // console.log(jsonResponse);
  return jsonResponse;
};
const execute = async (variables, token) => {



  // console.log('lastTicketData', lastTicketData);

  const fetchResponse = await fetch(
    process.env.REACT_APP_API_SERVER,

    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: HASURA_OPERATION,
        variables,
      }),
    },
  );
  return await fetchResponse.json();
};
userMiddleware.startListening({
  actionCreator: signOut,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(logOutSocketServer());
  },
});
userMiddleware.startListening({
  actionCreator: socketStatus,
  effect: async (action, listenerApi) => {
    // console.log('userMiddleware', action)
    const state = listenerApi.getState();

    listenerApi.dispatch(loginSSO(state.socket?.SSOCode?.code));
    // let data = ssoCode?.code ?? this.state.ssoCode.code
  },
});


userMiddleware.startListening({

  matcher: isAnyOf(signInSSO, signIn),
  effect: async (action, listenerApi) => {
    console.log('userMiddleware', action);
    if (action.payload?.error) {
      listenerApi.dispatch(updateMsssoError(action.payload));
      return;
    }
    localStorage.clear()
    const token = action.payload.token;
    // console.log('token', token)
    const today = new Date();
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(today.getDate() - 30);


    // Format the date as 'YYYY-MM-DD'
    const formattedDate = `${thirtyDaysAgo.getFullYear()}-${(thirtyDaysAgo.getMonth() + 1).toString().padStart(2, '0')}-${thirtyDaysAgo.getDate().toString().padStart(2, '0')}`;

    const lastTicketData = await executeLastXDaysTickets({ _date: formattedDate }, token);
    //  console.log('lastTicketData', lastTicketData);
    console.log({ "MISSING DATA": action.payload })
    const variables = { id: action.payload.user.id };
    // console.log(token)
    await execute(variables, token).then((data) => {
      if (data.data?.person?.length > 0) {
        // if (data.data?.datatable_view?.length > 0) {
        //   // console.log(data.data.datatable_view);
        //   datatable_views = data.data.datatable_view;
        //   // add datatable_views from person datatable_views
        //   // data.data.person[0].datatable_views.forEach((view) => {
        //   //   datatable_views.push(view);
        //   // });
        // }
        // console.log(data.data.person[0].permissions?.groups?.length )
        if (
          data.data.person[0].permissions?.groups?.length === 0 ||
          data.data.person[0].permissions?.groups === undefined ||
          data.data.person[0].permissions?.groups === null
        ) {
          listenerApi.dispatch(signOut());
        } else {
          const roles = data.data.person[0].permissions.groups.map((group) => {
            return group.group_permission.key;
          });
          // getting the lastticket data from the executelasxticket query



          // change widget layout
          if (!lastTicketData || lastTicketData?.data?.ticket <= 0) {
            //  console.log('line 274lastTicketData', lastTicketData);
            console.log('setUsersNoDataWidgets')
            // console.log(lastTicketData)
            listenerApi.dispatch(setUsersNoDataWidgets())
          }
          else if (roles.includes('agent') || roles.includes('group_gm')) {
            console.log('change widget layout')
            listenerApi.dispatch(setGmAgentWidgets())
          }




          const permissions = generatePermissions(data.data.person[0]);
          listenerApi.dispatch(
            userLoaded({
              ...data.data.person[0],
              permissions: permissions,
              roles: roles,
              datatable_views: [],
            }),
          );
        }
      } else {
        listenerApi.dispatch(signOut());
      }
    });
  },
});

export default userMiddleware;
