const styles = (theme) => ({
    toolbar: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overflow: "auto",
        whiteSpace: "nowrap",
       // justifyContent: "space-between",
        padding: theme.spacing(2),
    },
    modalContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 420,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    modalContent: {

    }
  });
  
  export default styles;
  