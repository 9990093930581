import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Button from "components/MuiOverride/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import TextField from "components/MuiOverride/TextField";
import Typography from "@mui/material/Typography";
import { isMobile } from "utils/menuHelper";
import styles from "./styles";
import Translation from "utils/translation";
import Grid from "@mui/material/Grid";
import SSGWhite from "../../images/ssgWhite.svg";
import MsSsoLogin from "../../images/microsoftSsoLoginWhite.svg";
import SSGLoginPage from "../../images/six-star-global-login.png";
import { Alert, AlertTitle, Box, CircularProgress } from "@mui/material";

import { useDispatch } from "react-redux";
import { loginSSO, msssoLoginCode } from "containers/App/websocket/login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LanguageSelector from "components/LanguageSelector";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

import { useContext } from "react";
import { SiteData } from "context/siteData";

const Login = ({
  classes,
  email,
  errorMessage,
  onEmailChange,
  onForgotPassword,
  onPasswordChange,
  onRememberMeChange,
  onSignIn,
  onSignInSso,
  password,
  rememberMe,
  twoFactor,
  code,
  onCodeChange,
  loggingIn,
  showSSOLogin,
  theme
}) => {
  // console.log(showSSOLogin)

  const [normalSignIn, setNormalSignIn] = React.useState(false);
  const [doingSignIn, setdoingSignIn] = React.useState(false);
  const dispatch = useDispatch();
  const [msssoError, setMsssoError] = React.useState({ error: false, message: '' });
  const urlParams = new URLSearchParams(window.location.search);
  const c = urlParams.get('c');
  const e = urlParams.get('e');
  const siteData = useContext(SiteData);

  siteData.functions.deleteDatabase();
  useEffect(() => {
    if (errorMessage) {

      setdoingSignIn(false)
    }
  }, [errorMessage]);
  useEffect(() => {
    if (c) {
      console.log(`got code ${c}, logging in`)
      setdoingSignIn(true)
      console.log('here')
      dispatch(msssoLoginCode({
        type: 'msssoLoginCode',
        data: c
      })).then((res) => {
        console.log('msssoLoginCode responce')
        console.log(res)
      }).catch((err) => {
        console.log('msssoLoginCode error')
        console.log(err)
      })

      console.log('finished dispatch')

      window.history.replaceState({}, document.title, window.location.pathname);
    }
    if (e) {
      //   console.log(`got error ${e}`)
      setMsssoError({ error: true, message: e })
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [c, e])

  const newSSOLogin = () => {
    // const currentUrl = window.location.href;
    //remove the domain from the url and return the path
    const currentUrl = window.location.href.replace(window.location.origin, '');
    let currentDomain = window.location.origin ?? 'https://manage.sixstar.support';
    dispatch(loginSSO({
      type: 'getSSOCode',
      data: email,
      redirectTo: currentUrl,
      source: currentDomain
    })).then((res) => {
      //  console.log('callback')
      //   console.log(res)
      if (res.result.status === 'success') {
        //   console.log(res.result.url)
        window.location.href = res.result.url
      }
    })
    // dispatch(loginSSO({
    //   type: 'getSSOCode',
    //   data: email
    // }))
  }


  const newGoogleLogin = useGoogleLogin({
    ux_mode: "redirect",
    redirect_uri: `${process.env.REACT_APP_INTERNAL_API}/google`,
    login_uri: `${process.env.REACT_APP_INTERNAL_API}/google`,
    flow: "auth-code",
  });

  return (
    <Box
      sx={{
        minWidth: { md: "427.05px" },
        width: { md: "427.05px" },
        flexDirection: { xs: "column", md: "row" },
      }}
      className={classNames(classes.loginContainer)}
    >
      <Grid
        container
        sx={{ position: "relative", display: { xs: "flex", md: "none" } }}
      >
        <Box
          className={classNames(classes.horizontalTopBar)}
          sx={{ display: "flex" }}
        >
          <Box sx={{ color: "white", padding: "10px", width: "70px" }} p={3}>
            <img
              src={SSGWhite}
              className={classNames(classes.topSideLogo)}
              alt="Six Star Logo"
            />
          </Box>
          <div className={classNames(classes.horizontalSpacer)} />
          <LanguageSelector />
          {/*<Box className={classNames(classes.topSideBarIcon)}>*/}
          {/*  <i className="fa-regular fa-circle-question"></i> */}
          {/*</Box>*/}
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid
          className={classNames(classes.loginLeftSide)}
          item
          xs={4.5}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Box sx={{ color: "white", padding: "10px" }} p={3}>
            <img
              src={SSGWhite}
              className={classNames(classes.leftSideLogo)}
              alt="Six Star Logo"
            />
            {/* <Typography variant="h3" sx={{ textTransform: 'uppercase', fontWeight: 600 }}> {Translation('login.title')}</Typography> */}
            {/* <Typography> {Translation('login.headline')}</Typography> */}
          </Box>
          <div className={classNames(classes.verticalSpacer)} />
          <LanguageSelector />
          {/*<Box className={classNames(classes.leftSideBarBottomIcon)}>*/}
          {/*  <i className="fa-regular fa-circle-question"></i>*/}
          {/*</Box>*/}
        </Grid>
        <Grid
          sx={{
            // backgroundColor: {
            //   xs: '#2A203B',
            //   md: 'white'
            // },
            backgroundImage: `url(${SSGLoginPage})`,
            backgroundSize: "cover",
            justifyContent: { xs: "center", md: "right" },
          }}
          className={classNames(classes.loginRightSide)}
          item
          xs={12}
          md={7.5}
        >
          <Box
            className={classNames(
              classes.boxContainer,
              isMobile() ? classes.mobileContainer : {}
            )}
            sx={{ paddingRight: { xs: "0", md: "10%" } }}
          >
            <div className={classes.boxWrapper}>
              <Paper className={classes.paper} elevation={3}>
                <div className={classNames(classes.LoginBoxHeader)}>
                  <Typography color={"#2A203B"} variant={"h4"} align={"left"}>
                    {Translation("login.signInText")}
                  </Typography>
                </div>
                {errorMessage && errorMessage !== "login.sso.starting" && (
                  <Typography paragraph className={classes.errorMessage}>
                    * {Translation(errorMessage)}
                  </Typography>
                )}
                {errorMessage && errorMessage === "login.sso.starting" && (
                  <Typography paragraph className={classes.errorMessageGreen}>
                    * {Translation(errorMessage)}
                  </Typography>
                )}
                <form>
                  {/*{normalSignIn && (*/}
                  {/* <Box
                    sx={{
                      display: `${normalSignIn ? "block" : "none"}`,
                    }}
                  >
                    {!twoFactor ? (
                      <>
                        <TextField
                          metadata="login_email"
                          margin="normal"
                          label={Translation("loginPage.input.email")}
                          fullWidth
                          autoFocus={true}
                          defaultValue={email}
                          onChange={onEmailChange}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              onSignIn();
                            }
                          }}
                        />
                        <TextField
                          metadata="login_password"
                          margin="normal"
                          label={Translation("loginPage.input.password")}
                          fullWidth
                          type="password"
                          defaultValue={password}
                          onChange={onPasswordChange}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              onSignIn();
                            }
                          }}
                        />
                      </>
                    ) : null}
                    {twoFactor ? (
                      <TextField
                        metadata="login_mfa"
                        margin="normal"
                        label={Translation("loginPage.input.mfa")}
                        fullWidth
                        type="number"
                        autoFocus={true}
                        defaultValue={code}
                        onChange={onCodeChange}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            onSignIn();
                          }
                        }}
                      />
                    ) : null}
                    <div className={classNames(classes.buttonsContainer)}>
                      <LoadingButton
                        color="primary"
                        loading={loggingIn}
                        onClick={onSignIn}
                        className={classes.loginButton}
                        variant="contained"
                        sx={{
                          paddingLeft: 4,
                          paddingRight: 4,
                          paddingTop: 1,
                          paddingBottom: 1,
                        }}
                      >
                        {Translation("loginPage.button.login")}
                      </LoadingButton>

                      <div className={classes.buttonsDiv}>
                        <Button
                          metadata="forgot_password"
                          sx={{
                            boxShadow: "none",
                          }}
                          onClick={onForgotPassword}
                          className={classes.flatButton}
                          icon={<Icon className="material-icons">help</Icon>}
                        >
                          {Translation("loginPage.button.forgotPassword")}
                        </Button>
                      </div>
                    </div>
                    <div className={classes.checkRememberContainer}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.checkRemember}
                            checked={rememberMe}
                            value="rememberMe"
                            onChange={onRememberMeChange}
                          />
                        }
                        label={Translation("loginPage.rememberMe")}
                      />
                    </div>
                  </Box> */}
                  {/*)}*/}

                  {/* <LoadingButton
                    sx={{
                      boxShadow: "none",
                      marginTop: "20px",
                    }}
                    loading={loggingIn}
                    onClick={onSignInSso}
                    className={classes.loginButtonSso}
                    disabled={!showSSOLogin}
                    style={{ display: showSSOLogin ? "block" : "none" }}
                  > */}
                  {msssoError.error &&
                    <Alert severity="error" sx={{
                      marginTop: theme.spacing(3)
                    }}>
                      <AlertTitle>{Translation("loginPage.text.msssoError")}</AlertTitle>
                      {msssoError.message}
                    </Alert>

                  }
                  <Box
                    sx={{
                      boxShadow: "none",
                      marginTop: theme.spacing(4),
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      gap: theme.spacing(4)
                    }}
                  >
                    {/* <TextField
                      metadata="login_mssso"
                      label={Translation("loginPage.label.msssoSignInEmail")}
                      fullWidth
                      defaultValue={email}
                      onBlur={onEmailChange}
                    /> */}
                    {doingSignIn &&
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: theme.spacing(3)
                      }}>
                        <CircularProgress />
                        <Typography>{Translation("loginPage.text.msssoLoading")}</Typography>
                      </Box>
                    }

                    {!doingSignIn && (
                      <>
                        <Box
                          onClick={newSSOLogin}
                          sx={{
                            border: `1px solid ${theme.palette.primary.main}`,
                            borderRadius: theme.borderRadius,
                            padding: theme.spacing(1, 4),
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: theme.spacing(2),
                            fontWeight: 600,
                            width: 350,
                            userSelect: 'none'
                          }}
                        >
                          <FontAwesomeIcon icon={['fab', 'microsoft']} size={'2x'} />
                          {Translation("loginPage.label.msssoSignIn")}
                        </Box>
                        {/* <GoogleLogin
                          // ux_mode="redirect"
                          // redirect_uri="https://websocketapi.amison.work/google"
                          // login_uri="https://websocketapi.amison.work/google"
                          onSuccess={credentialResponse => {
                            console.log(credentialResponse);
                          }}
                          onError={() => {
                            console.log('Login Failed');
                          }}
                          select_account={true}
                          useOneTap={false}
                          type="standard"
                        /> */}


                        <Box
                          onClick={newGoogleLogin}
                          sx={{
                            border: `1px solid ${theme.palette.primary.main}`,
                            borderRadius: theme.borderRadius,
                            padding: theme.spacing(1, 4),
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: theme.spacing(2),
                            fontWeight: 600,
                            width: 350,
                            userSelect: 'none'
                          }}
                        >
                          <FontAwesomeIcon icon={['fab', 'google']} size={'2x'} /> {Translation("loginPage.label.googleSignIn")}
                        </Box>
                      </>
                    )}
                  </Box>
                  {/* </LoadingButton> */}
                  {/* {!normalSignIn && (
                    <div className={classes.buttonsDiv}>
                      <Button
                        sx={{
                          boxShadow: "none",
                        }}
                        onClick={() => setNormalSignIn(!normalSignIn)}
                        className={classes.flatButton}
                        icon={<Icon className="material-icons">help</Icon>}
                      >
                        {Translation("loginPage.button.normalSignIn")}
                      </Button>
                    </div>
                  )} */}
                </form>
              </Paper>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
Login.propTypes = {
  email: PropTypes.string.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  rememberMe: PropTypes.any.isRequired,
  onRememberMeChange: PropTypes.func.isRequired,
  onSignIn: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(() => styles(), {
  withTheme: true,
})(Login);
