const misc = {
  'tooltip.disabled': 'Deaktiviert',
  'languageSelector.english': 'Englisch',
  'languageSelector.dutch': 'Niederländisch',
  'languageSelector.german': 'Deutsch',
  'languageSelector.french': 'Französisch',
  'languageSelector.spanish': 'Spanisch',
  'languageSelector.polish': 'Polnisch',
  'languageSelector.italian': 'Italienisch',
  'languageSelector.thai': 'Thailändisch',
  'datatable.toolbar.quickFilterPlaceholder': 'Suche...',
  'datatable.toolbar.columns': 'Spalten',
  'datatable.toolbar.density': 'Dichte',
  'datatable.toolbar.densityComfortable': 'Komfortabel',
  'datatable.toolbar.densityCompact': 'Kompakt',
  'datatable.toolbar.densityStandard': 'Standard',
  'datatable.toolbar.export': 'Exportieren',
  'datatable.toolbar.filters': 'Filter',
  'socket.connected': 'Verbunden',
  'socket.disconnected': 'Getrennt',
  'profile.menu.viewProfile': 'Profil Ansehen',
  'profile.menu.editProfile': 'Profil Bearbeiten',
  'profile.menu.help': 'Hilfe',
  'profile.menu.signOut': 'Abmelden',
  'addon.add': 'Hinzufügen',
  'addon.modal.save': 'Speichern',
  'addon.edit': 'Bearbeiten',
  'notFoundPage.pageDevelopment': 'Seite in Entwicklung',
  'notFoundPage.pageDevelopmentMessage':
    'Vielen Dank für das Erkunden unserer Webseite. Diese spezielle Seite befindet sich noch in der Entwicklung, da wir derzeit in unserer Betaphase sind. Wir schätzen Ihr Geduld und Verständnis.',
  'notFoundPage.pageDevelopmentMessage2':
    'Bitte beachten Sie, dass unsere Website regelmäßigen Aktualisierungen und Verbesserungen unterzogen wird, um Ihnen die bestmögliche Erfahrung zu bieten. Schauen Sie bald wieder vorbei für neue Inhalte und Funktionen!',
  general: 'Allgemein',
  'ticket.role.first_responder': 'Ersthelfer',
  'ticket.role.first_responder.tooltip':
    'Ersthelfer: Erster Ansprechpartner für IT-Probleme mit hoher Priorität, verantwortlich für schnelle Lösung und minimale Ausfallzeiten.',
  'ticket.role.lead_engineer': 'Leitender Ingenieur',
  'ticket.role.lead_engineer.tooltip':
    'Leitender Ingenieur: Verantwortlich für die gesamte technische Ausrichtung des Projekts und dafür, dass das Projekt pünktlich und innerhalb des Budgets abgeschlossen wird.',
  'ticket.status_id': 'Status',
  'ticket.priority_id': 'Priorität',
  'ticket.closed_at': 'Geschlossen Am',
  'ticket.subject': 'Betreff',
  'team.team': 'Team',
  'ticket.created_at': 'Erstellt Am',
  'ticket.updated_at': 'Aktualisiert Am',
  'ticket.requester_id': 'Anforderer',
  'ticket.organization_id': 'Organisation',
  'ticket.assignee_id': 'Beauftragter',
  'ticket.author_id': 'Autor',
  'ticket.public': 'Öffentlich',
  'ticket.alias': 'ID',
  'ticket.status': 'Status',
  'ticket.priority': 'Priorität',
  'ticket.team_id': 'Team',
  'ticket.assignee': 'Beauftragter',
  'ticket.requester': 'Anforderer',
  'ticket.has_incidents': 'Hat Vorfälle',
  'ticket.updated_by_id': 'Aktualisiert Von',
  'ticket.author': 'Autor',
  'person.assignee': 'Beauftragter',
  'person.requester': 'Anforderer',
  'ticket_status.status': 'Status',
  'ticket_priority.priority': 'Priorität',
  'organization.organization': 'Unternehmen',
  'ticket_priority.priority.normal': 'Normal',
  'ticket_priority.priority.high': 'Hoch',
  'ticket_priority.priority.low': 'Niedrig',
  'ticket_status.status.new': 'Neu',
  'ticket_status.status.pending': 'Ausstehend',
  'ticket_status.status.hold': 'Zurückgehalten',
  'ticket_status.status.open': 'Offen',
  'ticket_status.status.resolved': 'Gelöst',
  'person.name': 'Name',
  'person.avatar': 'Avatar',
  'person.email_address': 'E-Mail',
  'person.active': 'Aktiv',
  'person.alias': 'ID',
  'organization.name': 'Name',
  'organization.active': 'Aktiv',
  'organization.alias': 'ID',
  'board.board': 'Tafel',

  // new translations
  'dateTime.utc': 'UTC',
  'ticket.devices.RMM.install': 'Installiere Six Star RMM',
  'ticket.devices.RMM.login': 'Anmelden bei Six Star RMM',
  'ticket.devices.RMM.title': 'Um die neuesten Informationen anzuzeigen',

  // Datenfilter
  'dataTable.filters.reset': 'Zurücksetzen',
  'dataTable.filters.loading': 'Laden...',
  'dataTable.filters.header': 'Filter',

  // Daten Tabellenvorschau
  'dataTable.preview.viewTicket': 'Ticket anzeigen',
  'dataTable.preview.quickNotes': 'Schnelle Notizen',
  'dataTable.preview.contacts': 'Kontakte',
  'dataTable.preview.followers': 'Follower',
  'dataTable.preview.relatedTickets': 'Verwandte Tickets',
  'dataTable.preview.updateIn': 'Aktualisierung in',
  'dataTable.preview.devices': 'Geräte',
  'dataTable.preview.engineer': 'Ingenieur',
  'dataTable.preview.ticketCreated': 'Ticket erstellt:',

  // ChatGPT
  'chatgpt.somethingWentWrong': 'Etwas ist schiefgegangen, bitte versuchen Sie es erneut',
  'chatgpt.history': 'Verlauf',

  // Benutzerdefinierte Gruppenschaltfläche
  'customGroupButton.one.expandAll': 'Alle erweitern',
  'customGroupButton.two.collapseAll': 'Alle minimieren',

  // Flaggennummern
  'flagNumber.button.callUs': 'Rufen Sie uns an',

  'HelpOptions.Title': 'Wie können wir Ihnen helfen?',
  'FloatingBeta.ReportIssue': 'Problem melden',
  'FloatingBeta.Feedback': 'Feedback senden',
  'dateTime.utc': 'UTC',
  'ticket.devices.RMM.install': 'Six Star RMM installieren',
  'ticket.devices.RMM.login': 'Bei Six Star RMM anmelden',
  'ticket.devices.RMM.title': 'Um die neuesten Informationen anzuzeigen',
  'dataTable.filters.reset': 'Zurücksetzen',
  'dataTable.filters.loading': 'Laden...',
  'dataTable.filters.header': 'Filter',
  'dataTable.preview.viewTicket': 'Ticket anzeigen',
  'dataTable.preview.quickNotes': 'Schnelle Notizen',
  'dataTable.preview.contacts': 'Kontakte',
  'dataTable.preview.followers': 'Follower',
  'dataTable.preview.relatedTickets': 'Verwandte Tickets',
  'dataTable.preview.updateIn': 'Aktualisierung in',
  'dataTable.preview.devices': 'Geräte',
  'dataTable.preview.engineer': 'Ingenieur',
  'dataTable.preview.ticketCreated': 'Ticket erstellt: ',
  'chatgpt.somethingWentWrong': 'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut.',
  'chatgpt.history': 'Verlauf',
  'customGroupButton.one.expandAll': 'Alle erweitern',
  'customGroupButton.two.collapseAll': 'Alle reduzieren',
  'flagNumber.button.callUs': 'Rufen Sie uns an',
  'profile.menu.viewCompany': 'Unternehmen anzeigen',
  'contactUsModals.addWidgetModal.title': 'Wie füge ich ein Widget zu meinem Dashboard hinzu',
  'contactUsModals.addWidgetModal.step1': '1. Navigieren Sie zu Ihrem Dashboard',
  'contactUsModals.addWidgetModal.step2': '2. Suchen Sie das lila Band oben auf Ihrem Bildschirm',
  'contactUsModals.addWidgetModal.step3': '3. Klicken Sie auf die Schaltfläche Widget hinzufügen',
  'contactUsModals.addWidgetModal.step4': '4. Wählen Sie ein Widget aus der Liste aus',
  'contactUsModals.addWidgetModal.step5': '5. Klicken Sie auf die Schaltfläche Hinzufügen',
  'contactUsModals.signOutModal.title': 'Wie man sich ausloggt',
  'contactUsModals.signOutModal.step1': '1. Finden Sie Ihr Avatar oben rechts auf dem Bildschirm',
  'contactUsModals.signOutModal.step2': '2. Klicken Sie auf Ihren Avatar',
  'contactUsModals.signOutModal.step3': '3. Finden Sie die Abmelden-Schaltfläche im Dropdown-Menü',
  'contactUsModals.signOutModal.step4': '4. Klicken Sie auf die Abmelden-Schaltfläche',
  'ticketViewPage.toolbar.label': 'Tickets suchen',
  'ticketViewPage.toolbar.view': 'Ansicht',
  'ticketViewPage.toolbar.saveView': 'Ansicht speichern',
  'ticketViewPage.toolbar.saveViewPrompt': 'Bitte geben Sie den Namen ein, unter dem Sie diese Ansicht speichern möchten.',
  'ticketViewPage.toolbar.label.export': 'Exportieren',
  'ticketViewPage.toolbar.label.filters': 'Filter',
  'formLoader.submissionSuccess': 'Vielen Dank für das Absenden des Formulars',
  'formLoader.submissionError': 'Beim Absenden des Formulars ist ein Fehler aufgetreten',
  'reportIssue.submit': 'Absenden',
  'reportIssue.issue': 'Problem...',
  'ticketViewPage.modal.button.bunle': 'Bündel',
  'ticketViewPage.modal.button.back': 'Zurück',
  'flagNumber.button.callUs': 'Rufen Sie uns an',
  'ticketDocuments.notFound': 'Keine Dokumente gefunden',
  'ticketViewPage.childTicketsNone': 'Keine Untertickets',
  'ticketViewPage.parentNone': 'Kein übergeordnetes Ticket',
  'ticketViewPage.restricted': 'Eingeschränkt',
};
export default misc;
