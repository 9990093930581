import { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import { useSelector } from "react-redux";
import useErrorNotification from "utils/useErrorNotification";
import { useDispatch } from "react-redux";
import { cloneDeep, set } from "lodash";
import { addTicket } from "slices/tickets";
import { graphQLMutations } from "graphql/ticket";
export const useCommentDelete = (props) => {
  const { commentID = null, ticketID = null } = props ?? {};
  const [superLoading, setSuperLoading] = useState(false);
  const [sendErrorNotification] = useErrorNotification();
  const { followers } = useSelector(
    (state) => state?.tickets[ticketID]?.current ?? []
  );
  const ticket = useSelector((state) => state?.tickets[ticketID] ?? null);
  const dispatch = useDispatch();
  const { DELETE_TICKET_COMMENT } = graphQLMutations();
  const [
    submitDeleteComment,
    {
      data: deleteCommentData,
      loading: deleteCommentLoading,
      error: deleteCommentError,
    },
  ] = useMutation(DELETE_TICKET_COMMENT);

  const handleSubmitDeleteComment = () => {
    setSuperLoading(true);
    const variables = {
      id: commentID,
      deleted: true,
    };
    submitDeleteComment({ variables });
  };

  useEffect(async () => {
    try {
      if (deleteCommentData) {
        let tempCuttentTicket = cloneDeep(ticket?.current);
        let tempComments = tempCuttentTicket?.comments ?? [];
        tempComments = tempComments.filter((item) => item?.id !== commentID);
        tempCuttentTicket = set(tempCuttentTicket, "comments", tempComments);
        await dispatch(
          addTicket({ data: tempCuttentTicket, location: "current" })
        );
        setSuperLoading(false);
      } else {
        setSuperLoading(false);
      }
      setSuperLoading(false);
    } catch (error) {
      console.log(error);
      sendErrorNotification({ error: error, location: "Delete Comment" });
      setSuperLoading(false);
    }
  }, [deleteCommentData]);

  useEffect(() => {
    if (deleteCommentError) {
      sendErrorNotification({
        error: deleteCommentError,
        location: "Delete Comment",
      });
    }
  }, [deleteCommentError]);

  return {
    handle: handleSubmitDeleteComment,
    loading: superLoading,
  };
};

export default useCommentDelete;
