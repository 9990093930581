import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Translation from "utils/translation";
import layoutStyles from "containers/Layout/styles";
import MenuIcon from "components/Layout/LeftSideBar/LeftSideBarMenuIcon";

const LeftSideBarMenuItem = ({
  classes,
  children,
  handleOnClick,
  icon,
  item,
  nestedClass,
  sidebarIsOpen,
  selectedMenuItem,
  selectedMenuItemParentId,
}) => {
  let disabled = item?.disabled ? true : false;

  return (
    <MenuItem
      // button
      sx={{ paddingY: 1.189 }}
      className={classNames(
        nestedClass,
        item.id === selectedMenuItem.id || item.id === selectedMenuItemParentId
          ? classes.selectedMenuItem
          : null,
        !icon && classes.emptyIcon,
        classes.rippleColor
      )}
      selected={
        item.id === selectedMenuItem.id || item.id === selectedMenuItemParentId
      }
      onClick={() => handleOnClick && handleOnClick(item)}
      disabled={disabled}
    >
      {icon ? (
        <ListItemIcon
          className={classNames(
            item.id === selectedMenuItem.id ||
              item.id === selectedMenuItemParentId
              ? classes.selectedItemText
              : classes.itemIcon,
            "menu-icon"
          )}
        >
          <MenuIcon icon={icon} />
          {/*<Icon className={icon} style={{ overflow: 'visible' }} />*/}
        </ListItemIcon>
      ) : null}
      <ListItemText
        disableTypography
        className={classNames(
          sidebarIsOpen ? classes.noPadding : "menu-padding",
          item.id === selectedMenuItem.id ||
            item.id === selectedMenuItemParentId
            ? classes.selectedItemText
            : null,
          classes.listItemText,
          "truncate-list-item-text",
          "menu-margin"
        )}
        primary={Translation(item.menuText)}
        secondaryTypographyProps={{ color: "textSecondary" }}
      />
      {children}
    </MenuItem>
  );
};

LeftSideBarMenuItem.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func,
  icon: PropTypes.array,
  item: PropTypes.object.isRequired,
  nestedClass: PropTypes.string,
  selectedMenuItem: PropTypes.object.isRequired,
  selectedMenuItemParentId: PropTypes.string,
  sidebarIsOpen: PropTypes.bool.isRequired,
};

LeftSideBarMenuItem.defaultProps = {
  children: null,
  handleOnClick: null,
  nestedClass: "",
  icon: null,
};

export default withStyles((theme) => layoutStyles(theme), {
  withTheme: true,
})(LeftSideBarMenuItem);
