const pages = {
  'dashboard.pageTitle': 'Tablero',
  'dashboard.greeting.morning': 'Buenos Días',
  'dashboard.greeting.afternoon': 'Buenas Tardes',
  'dashboard.greeting.evening': 'Buenas Noches',
  'dashboard.welcomeMessage': 'Tienes {tickets} tickets activos y {messages} mensajes para responder.',
  'notAuthorizedPage.title': 'No Autorizado',
  'notAuthorizedPage.desc': 'No está autorizado para acceder a esta página.',
  'dashboard.welcomeText': '¡Bienvenido a {ssg}!',
  'dashboard.button.addWidget': 'Añadir Widget',
  'dashboard.button.close': 'Cerrar',
  'dashboard.button.edit': 'Editar',
  'dashboard.button.save': 'Guardar',
  'settingsPage.pageTitle': 'Configuración',
  'ticketViewPage.priority': 'Prioridad',
  'ticketViewPage.assignee': 'Asignado',
  'ticketViewPage.team': 'Equipo',
  'ticketViewPage.sixStarTeam': 'Miembros adicionales del equipo Seis Estrellas',
  'ticketViewPage.resourcesOnTicket': 'Recursos en el Ticket',
  'ticketViewPage.followers': 'Seguidores',
  'ticketViewPage.tags': 'Etiquetas',
  'ticketViewPage.takeIt': 'Tomar',
  'ticketViewPage.notes': 'Notas',
  'ticketViewPage.activity': 'Actividad',
  'ticketViewPage.timeline': 'Cronología',
  'ticketViewPage.tasks': 'Tareas',
  'ticketViewPage.search': 'Buscar',
  'ticketViewPage.noteAddedBy': 'Nota añadida por',
  'ticketViewPage.moreButton': 'Más',
  'ticketViewPage.makeInternal': 'Hacer Interno',
  'ticketViewPage.makePublic': 'Hacer Público',
  'ticketViewPage.deleteComment': 'Eliminar',
  'ticketViewPage.reportComment': 'Reportar',
  'ticketViewPage.internalTicketComment': 'Este es un comentario interno',
  'ticketViewPage.publicTicketComment': 'Este es un comentario público',
  'ticketViewPage.ticketSource.internal_web': 'Web Interna',
  'ticketViewPage.ticketSource.external_email': 'Correo Interno',
  'ticketViewPage.followTicket': 'Seguir Ticket',
  'ticketViewPage.unfollowTicket': 'Dejar de Seguir Ticket',
  'ticketViewPage.tasks.priority.completed': 'Completado',
  'ticketViewPage.tasks.priority.in_progress': 'En Progreso',
  'ticketViewPage.tasks.priority.not_started': 'No Iniciado',
  'ticketViewPage.tasks.priority.low': 'Bajo',
  'ticketViewPage.tasks.priority.medium': 'Medio',
  'ticketViewPage.tasks.priority.important': 'Importante',
  'ticketViewPage.tasks.priority.urgent': 'Urgente',
  'ticketViewPage.tasks.assignedTo': 'Asignado a',
  'ticketViewPage.tasks.completedBy': 'Completado por',
  'ticketViewPage.requester': 'Solicitante',
  'ticketViewPage.organization': 'Organización',
  'ticketViewPage.button.cancel': 'Cancelar',
  'ticketViewPage.button.close': 'Cerrar',
  'ticketViewPage.downloadEml': 'Descargar EML',
  'newRequestPage.header': 'Nueva Solicitud',
  'newRequestPage.button.internalRequest': 'Solicitud Interna',
  'newRequestPage.button.serviceRequest': 'Solicitud de Servicio',
  'newRequestPage.button.procurementRequest': 'Solicitud de Adquisición',
  'newRequestPage.card.urgentHelp': '¿Necesita ayuda urgente?',
  'newRequestPage.button.callUs': 'Llámenos',
  'newRequestPage.button.passwordReset': 'Restablecer contraseña de Dominio/Computadora',
  'newRequestPage.button.newRequest': 'Solicitud de Soporte',
  'formPage.title.supportRequest': 'Solicitud de Soporte',
  'formPage.title.newStarterRequest': 'Solicitud para Nuevo Inicio',
  'formPage.title.serviceRequest': 'Solicitud de Servicio',
  'formPage.title.newStarter': 'Nuevo Inicio / Cambio de Rol',
  'formPage.title.leaver': 'Saliente',
  'formPage.title.engineerVisit': 'Visita de Ingeniero',
  'formPage.title.procurementRequest': 'Solicitud de Adquisición',
  'formPage.title.developmentRequest': 'Solicitud de Desarrollo',
  'formPage.title.internalRequest': 'Solicitud Interna',
  'ldapPasswordResetPage.button.submit': 'Restablecer Contraseña',
  'ldapPasswordResetPage.button.generatePassword': 'Generar Contraseña',
  'ldapPasswordResetPage.title': 'Restablecimiento de Contraseña del Dominio',
  'ldapPasswordResetPage.paragraph.intro':
    'Restablezca la contraseña de su dominio de computadora. Dependiendo de la política de su hotel, puede que tenga que cambiar su contraseña cada 90 días.',
  'licencePage.title': 'Licencias',
  'licencePage.office365Licences': 'Licencias de Office 365',
  'licencePage.license': 'Licencia',
  'licencePage.active': 'Activo',
  'licencePage.consumed': 'Consumido',
  'licencePage.unused': 'Sin Usar',
  'assetPage.title': 'Recursos',
  'resourceTracker.title': 'Rastreador de Recursos',
  'ticketViewPage.overview': 'Resumen',
  'ticketViewPage.roles': 'Roles',
  'ticketViewPage.addContact': 'Añadir Contacto',
  'ticketViewPage.status': 'Estado',
  'ticketViewPage.createdAt': 'Creado en',
  'ticketViewPage.updatedAt': 'Actualizado en',
  'ticketViewPage.devices': 'Dispositivos',
  'ticketViewPage.contacts': 'Contactos',
  'ticketViewPage.documents': 'Documentos',
  'ticketViewPage.addNote': 'Añadir Nota',
  'ticketViewPage.info': 'Información',
  'ticketViewPage.created': 'Creado',
  'ticketViewPage.lastActivity': 'Última Actividad',
  'ticketViewPage.priorityLevel': 'Nivel de Prioridad',
  'ticketViewPage.assigned': 'Asignado',
  'ticketViewPage.ticketSLA': 'SLA del Ticket',
  'ticketViewPage.firstResponse': 'Primera Respuesta',
  'ticketViewPage.resolution': 'Resolución',
  'ticketViewPage.nextUpdate': 'Próxima Actualización',
  'ticketViewPage.mostRecentMessages': 'Mensaje Más Reciente',
  'organizationViewPage.overview': 'Resumen',
  'organizationViewPage.activity': 'Actividad',
  'assetPage.name': 'Nombre',
  'assetPage.type': 'Tipo',
  'assetPage.status': 'Estado',
  'assetPage.serial': 'Serial',
  'assetPage.manufacturer': 'Fabricante',
  'assetPage.cpu': 'CPU',
  'assetPage.memory': 'Memoria',
  'assetPage.lastUser': 'Último Usuario',
  'assetPage.lastSeen': 'Visto por Última Vez',
  'assetPage.operatingSystem': 'Sistema Operativo',
  'sixStarMeet.header': 'La Tecnología Detrás de Cada Gran Reunión',
  'contactUsPage.number.button': 'Haga Clic Aquí',
  'contactUsPage.title.second': 'Llámenos',
  'contactUsPage.title.first': 'Hola, ¿en qué podemos ayudarte?',
  'contactUsPage.header.boxs.one': '¿Cómo registro un ticket?',
  'contactUsPage.header.boxs.two': '¿Cómo cierro sesión?',
  'contactUsPage.header.boxs.three': '¿Dónde veo todos mis tickets?',
  'contactUsPage.header.boxs.four': '¿Cómo añado widgets?',
  'contactUsPage.search.answers': 'Buscar respuestas...',
  'contactUsPage.search': 'Buscar respuestas...',
  // Below = not yet translated
  // "ticketViewPage.approvedAt": "Aprobado en",
  // "ticketViewPage.rejectedAt": "Rechazado en",
  // "ticketViewPage.approvedBy": "Aprobado por",
  // "ticketViewPage.rejectedBy": "Rechazado por",
  // "ticketViewPage.approvalStatus": "Estado de Aprobación",
  // "ticketViewPage.approvalStatus.approved": "Aprobado",
  // "ticketViewPage.approvalStatus.rejected": "Rechazado",
  // "ticketViewPage.approvalStatus.pending": "Pendiente",
  // "ticketViewPage.requestedBy": "Solicitado por",
  'ticketViewPage.approvedAt': 'Aprobado el',
  'ticketViewPage.rejectedAt': 'Rechazado el',
  'ticketViewPage.approvedBy': 'Aprobado por',
  'ticketViewPage.rejectedBy': 'Rechazado por',
  'ticketViewPage.approvalStatus': 'Estado de aprobación',
  'ticketViewPage.approvalStatus.approved': 'Aprobado',
  'ticketViewPage.approvalStatus.rejected': 'Rechazado',
  'ticketViewPage.approvalStatus.pending': 'Pendiente',
  'ticketViewPage.requestedBy': 'Solicitado por',

  // Página de configuración inicial
  'firstTimeSetupPage.firstLookAtManage': 'Primer vistazo a Manage 3.0',
  'firstTimeSetupPage.elevatingYourITManagementExperience': 'Elevando tu experiencia de gestión de IT',
  'firstTimeSetupPage.weAreThrilledToPresent':
    'Estamos emocionados de presentar el adelanto de un nuevo capítulo en tu viaje de gestión de IT con la versión beta de Six Star: Manage 3.0.',
  'firstTimeSetupPage.asAValuedMember':
    'Como miembros valiosos de la familia Six Star Global, que ya se benefician de nuestras soluciones, estamos emocionados de presentarte lo que será una experiencia modificada y mejorada para que tu gestión de IT sea más dinámica, eficiente e intuitiva.',
  'firstTimeSetupPage.aNewChapterInITManagement': 'Un nuevo capítulo en la gestión de IT',
  'firstTimeSetupPage.technologicalTakeOver': 'Una renovación tecnológica:',
  'firstTimeSetupPage.redesign':
    'Un rediseño moderno que tiene en cuenta la velocidad y la estabilidad. El rendimiento es una de nuestras principales preocupaciones, así que nos hemos asegurado de que, estés donde estés, ya sea en el móvil o en la computadora de escritorio, tengas tu cartera de IT al alcance de tus dedos.',
  'firstTimeSetupPage.effortlessLicenseOverview': 'Visión sencilla de licencias:',
  'firstTimeSetupPage.simplifyLicenceManagement':
    'Simplifica la gestión de licencias sin esfuerzo. Manage 3.0 te permite supervisar software y licencias de usuarios de manera fluida, garantizando el cumplimiento y optimizando los gastos.',
  'firstTimeSetupPage.smartReportGeneration': 'Generación inteligente de informes:',
  'frstTimeSetupPage.harnessThePowerOfData':
    'Aprovecha el poder de los datos para la toma de decisiones estratégicas. Genera informes personalizados utilizando estadísticas de tickets, lo que te ayuda a ajustar operaciones y mejorar la calidad del servicio en tu establecimiento.',
  'firstTimeSetupPage.tailoredDashboardAtYourFingertips': 'Tablero personalizado al alcance de tus dedos:',
  'firstTimeSetupPage.craftDashboard':
    'Crea un tablero de IT personalizado con una variedad de widgets, brindándote información rápida sobre el estado y las tendencias de los tickets que se alinean con tus intereses específicos.',
  'firstTimeSetupPage.benefitsTitle': 'Beneficios de Manage 3.0 para tus operaciones hoteleras',
  'firstTimeSetupPage.amplifiedEfficiency': 'Eficiencia amplificada:',
  'firstTimeSetupPage.guestExperience':
    'Enfócate en ofrecer experiencias excepcionales a los huéspedes, con procesos automatizados que aborden problemas de manera fluida.',
  'firstTimeSetupPage.realTimeIntelligence': 'Inteligencia en tiempo real:',
  'firstTimeSetupPage.instantlyAccess':
    'Accede instantáneamente a datos actualizados y estadísticas de tickets para decisiones rápidas e informadas.',
  'firstTimeSetupPage.tailoredToYou': 'Adaptado a ti:',
  'firstTimeSetupPage.customiseDashboard':
    'Un tablero personalizado muestra precisamente la información que necesitas, ofreciendo una vista integral de tus operaciones de IT.',
  'firstTimeSetupPage.futureProof': 'Garantía de futuro:',
  'firstTimeSetupPage.atThisIminentLaunch':
    'Dado que este lanzamiento inminente es solo la versión beta, espera actualizaciones regulares y funciones innovadoras que se mantendrán al ritmo de las cambiantes necesidades de tu infraestructura de IT.',
  'firstTimeSetUpPage.button.getStarted': 'Empezar',

  // Cargando
  'loading.title': 'Cargando...',

  // Vista de ticket
  'ticketView.activityModule.title': 'Actividad',
  'ticketView.emailModule.title': 'Correos electrónicos',
  'ticketView.infoModule.subheaderOne': 'Soporte',
  'ticketView.infoModule.subheaderTwo': 'Equipo técnico',
  'ticketView.infoModule.value 1': 'Opción 1',
  'ticketView.infoModule.value 2': 'Opción 2',
  'ticketView.infoModule.value 3': 'Opción 3',
  'ticketView.infoModule.value 4': 'Opción 4',
  'ticketView.notesModule.title': 'Notas',
  'ticketView.tasksModule.title': 'Tareas',

  'ticketViewPage.modal.bundle.title': 'Agrupar tickets',
  'ticketViewPage.modal.bundle.parentTicket': 'Ticket principal',
  'ticketViewPage.modal.bundle.childTicket': 'Ticket secundario',
  'ticketViewPage.modal.bundle.button': 'Agrupar',
  'ticketViewPage.modal.bundle.button.cancel': 'Cancelar',
  'companyContactPage.tabs.overview': 'Resumen',
  'companyContactPage.tabs.activities': 'Actividades',
  'companyContactPage.tabs.tasks': 'Tareas',
  'companyContactPage.tabs.overview.lastActivity': 'Última actividad',
  'companyContactPage.tabs.overview.customerStatus': 'Estado del cliente',
  'companyContactPage.tabs.overview.userCreated': 'Usuario creado',
  'companyContactPage.tabs.overview.company': 'Empresa',
  'companyContactPage.tabs.overview.expiringLicences': 'Licencias por vencer',
  'companyContactPage.tabs.overview.opemTickets': 'Tickets abiertos',
  'companyContactPage.tabs.overview.mostRecentActivity': 'Actividad más reciente',
  'companyContactPage.tabs.overview.Drive': 'Drive',
  'companyContactPage.tabs.overview.lastActivity: ': 'Última actividad: ',
  'companyContactPage.tabs.search': 'Buscar',
  'companyContactPage.tabs.overview.AddedNote': 'Nota añadida',
  'companyContactPage.tabs.sidePanel.contacts': 'Contactos',
  'companyContactPage.tabs.sidePanel.other': 'Otros',
  'companyContactPage.tabs.sidePanel.licenses': 'Licencias',
  'companyContactPage.tabs.sidePanel.procurement': 'Adquisiciones',
  'companyContactPage.tabs.sidePanel.assets': 'Activos',
  'companyContactPage.tabs.sidePanel.invoices': 'Facturas',
  'companyContactPage.tabs.sidePanel.company': 'Empresa',
  'ticketViewPage.checklist': 'Lista de verificación',
  'ticketViewPage.attachments': 'Adjuntos',
  'ticketViewPage.relatedItems': 'Ýtems relacionados',
  'sixStarTVPage.header': 'Líderes en funcionalidad, estabilidad y facilidad de uso',
  'sixStarTVPage.integration': 'Integración',
  'sixStarTVPage.webBasedManagement': 'Gestión basada en web',
  'sixStarTVPage.gaming': 'Juegos',
  'sixStarTVPage.fullyCustomisable': 'Totalmente personalizable para una experiencia de marca',
  'sixStarTVPage.extraFeatures': 'Una variedad de características adicionales',
  'sixStarTVPage.plus': 'con Six Star: TV Plus',
  'sixStarTVPage.offer':
    'Six Star: TV Plus ofrece una gama de extras para hacer que la estancia de sus huéspedes sea aún más valiosa y al mismo tiempo garantizar esas ventas adicionales tan importantes.',
  'sixStarTVPage.wakeUp': 'Despertador',
  'sixStarTVPage.internationalViewing': 'Visualización internacional',
  'sixStarTVPage.roomService': 'Servicio a la habitación',
  'sixStar.GetInTouch': 'Ponte en contacto',
  'sixStar.BackToTop': 'Volver arriba',
  'quickCreateTicketModal.title': 'Creación rápida de tickets',
  'quickCreateTicketModal.button.submit': 'Enviar',
  'quickCreateTicketModal.button.submitAndView': 'Enviar y ver ticket',
  'quickCreateTicketModal.disclaimer': 'Este ticket se publicará automáticamente como prioridad media y el estado como NUEVO',
  'quickCreateTicketModal.success': 'Ticket creado exitosamente',
  'quickCreateTicketModal.button.close': 'Cerrar',

  //sixstar black page
  // "sixStarBlackPage.header": "Elevar Viajes Globales y Suites Presidenciales",
  // "sixStarBlackPage.traveler": "Viajero",
  // "sixStarBlackPage.hotel": "Hotel",

  //sixstar vip page
  'sixStarVIPPage.header': 'Elección ejecutiva para soluciones rápidas con acceso prioritario para GM',
  'sixStarVIPPage.directToSeniorSupport': 'Acceso directo a soporte senior',
  'sixStarVIPPage.contactCards': 'Tarjetas de contacto',
  'sixStarVIPPage.reducedSLA': 'SLA reducida para ejecutivos',
  'sixStarVIPPage.contactCardsForGM': 'Tarjetas de contacto para los GM',

  //sixstar cast page
  'sixStarCastPage.header': 'Entretenimiento para invitados desde casa',
  'sixStarCastPage.oneStepAuthentication': 'Autenticación de un solo paso',
  'sixStarCastPage.streamCastableApp': 'Los invitados pueden transmitir cualquier aplicación compatible',
  'sixStarCastPage.CloudManaged': 'Gestionado en la nube',

  // sixstar internet page
  'sixStarInternetPage.header': 'Elevar la conectividad, reducir costos y liberar un soporte incomparable',
  'sixStarInternetPage.lowCost': 'Costo bajo',
  'sixStarInternetPage.mobileConnectivity': 'Conectividad móvil',
  'sixStarInternetPage.installations': 'Instalaciones',

  // sixstar display page
  'sixStarDisplayPage.header': 'Transformación de espacios y elevación de experiencias',
  'sixStarDisplayPage.workWithAnyScreen': 'Funciona con cualquier pantalla',
  'sixStarDisplayPage.wayFinding': 'Señalización y búsqueda de ofertas',
  'sixStarDisplayPage.mobileWebEditor': 'Editor web móvil',

  'sixStarPlus.getInTouch': 'Ponte en contacto',
  'sixStarTVPage.externalTVInputs': 'Entradas de TV externas',
  'sixStarTVPage.p2':
    'Utilice puertos HDMI para consolas y otros sistemas, así como aplicaciones como AirPlay y Six Star: Cast para permitir a los huéspedes transmitir desde sus propios dispositivos personales.',
  'sixStarTVPage.p3': 'Los costos de inversión pueden reducirse utilizando la infraestructura existente',
  'sixStarTVPage.p4': 'Monitoreo interno y gestión de contenido directamente desde un navegador',
  'sixStarTVPage.p5': 'Una creciente demanda de proveedores de hospitalidad que SS: TV puede satisfacer con fuerza.',
  'sixStarTVPage.fullyCustomizable1080p':
    'Un menú personalizable de 1080p sin límites rígidos de diseño. Comience con plantillas personalizadas para un inicio suave o SSG puede ayudar con el diseño.',
  'sixStarVoicePage.header': 'Descubre el poder de Six Star: Voice',
  'sixStarVoicePage.p1': 'Comunicación incomparable',
  'sixStarVoicePage.advancedIntegrations': 'Integraciones avanzadas',
  'sixStarVoicePage.lineRedundancy': 'Redundancia de línea',
  'sixStarVoicePage.customisableSolutions': 'Soluciones personalizables',
  'sixStarVoicePage.seamlessConnect': 'Conéctese sin problemas con aplicaciones de terceros para una funcionalidad versátil del sistema.',
  'sixStarVoicePage.enjoy': 'Disfruta de una comunicación ininterrumpida con redundancia de línea estándar.',
  'sixStarVoicePage.tailor': 'Adapta Six Star: Voice para optimizar las operaciones únicas de tu hospitalidad.',
  'sixStarVoicePage.p2': 'Comunicación sin problemas',
  'sixStarVoicePage.sayGoodbye':
    'Di adiós al tiempo de inactividad. Nuestro sistema está construido con redundancia de línea como una característica estándar, asegurando una comunicación ininterrumpida incluso frente a desafíos inesperados. También contamos con miembros dedicados del equipo de telefonía listos para apoyarte si lo necesitas.',
  'sixStarCastPage.low-Cost':
    'Solución de transmisión de dispositivos para huéspedes de bajo costo que se puede agregar a cualquier televisor compatible con HDMI.',
  'sixStarCastPage.guestCan': 'Los huéspedes pueden acceder a su entretenimiento de inmediato tan pronto como ingresan a su habitación.',
  'sixStarCastPage.netflix': 'Netflix, Hulu, Disney+ y muchos más disponibles.',
  'sixStarCastPage.easilyIntegrated': 'Fácilmente integrado en soluciones de TV existentes',
  'sixStarCastPage.p1':
    'Utilice el portal de marketing de marca para la comunicación directa con los huéspedes internos. A través del sistema de gestión de contenido basado en la web (CMS), el personal de marketing del hotel puede mantener informados a los huéspedes sobre eventos y comodidades del hotel, ofertas especiales, horarios de apertura, programas de lealtad y mucho más.',
  'sixStarDisplayPage.subHeader':
    'Sistema de visualización de salas de reuniones de hospitalidad centrado en API, publicidad, señalización, tablero de vuelos y menú. Integraciones con muchos servicios de terceros.',
  'sixtarDisplayPage.noFancy': 'No hay hardware elegante para empezar, el único requisito de hardware es un puerto HDMI.',
  'sixStarDisplayPage.mutlipleGoals': 'Six Star: Display puede lograr múltiples objetivos',
  'sixStarDisplayPage.plan': 'Planifique en cualquier lugar y muestre en todas partes, ya que todo se administra en la nube.',
  'sixStarDisplayPage.powerful': 'La conectividad poderosa de la API atrae la atención',
  'sixStarDisplayPage.p1':
    'Acceda a noticias, clima, tráfico y datos de vuelos para convertir sus pantallas en centros de información en tiempo real. Mezcle datos de la API con su contenido de marca o use desencadenadores de API para cambiar su mensaje automáticamente.',
  'sixStarParkPage.header': 'Administra el estacionamiento como habitaciones y camas (incluidos los ingresos)',
  'sixStarParkPage.customerSat': 'Satisfacción del cliente',
  'sixStarParkPage.improve': 'Mejora la experiencia de estacionamiento en general y maximiza la rentabilidad',
  'sixStarParkPage.realTimeInformation': 'Información en tiempo real',
  'sixStarParkPage.provide': 'Proporcione información en tiempo real sobre ocupación e ingresos a través de un solo portal',
  'sixStarParkPage.geoMarketing': 'Geo-Marketing',
  'sixStarParkPage.drive': 'Impulse promociones en la tienda a usuarios de aplicaciones y genere tráfico a sus instalaciones',
  'sixStarParkPage.extraFeatures': 'Funciones adicionales',
  'sixStarParkPage.p1':
    'Six Star: Park cuenta con muchas más funciones en lo que respecta a factores sociales, económicos y organizativos de su participación en estacionamiento. Con la posibilidad de incluir cargadores para vehículos eléctricos en la mezcla, pagos express, reservas previas y pagos por móvil, aumentará los ingresos directos y maximizará la experiencia general para los huéspedes.',
  'sixStarParkPage.preparing': 'Preparándose para el futuro',
  'sixStarParkPage.p2':
    'Nuestro producto de estacionamiento de hotel cuenta con dos soluciones avanzadas de carga para vehículos eléctricos: TRONIQ 50, un cargador de CC de 50 kW con compatibilidad universal y mantenimiento remoto, y DUAL BUSINESSLINE, un cargador de CA de 7.4 kW que carga simultáneamente dos autos con distribución eficiente de energía mediante equilibrio de carga. Estas soluciones de vanguardia mejoran la experiencia general del huésped al proporcionar opciones de carga de vehículos eléctricos rápidas, confiables y convenientes.',
  'sixStarBlackPage.header': 'Elevar Viajes Globales y Suites Presidenciales',
  'sixStarBlackPage.subHeader':
    "La marca 'Six Star: Black' ofrece dos productos distintos adaptados para personas de alto perfil y alojamientos de lujo que ponen las necesidades de sus huéspedes en primer lugar.",
  'sixStarBlackPage.traveler': 'Viajero',
  'sixStarBlackPage.hotel': 'Hotel',
  'sixStarBlackPage.aService':
    'Un servicio para los ocupantes de las suites de más alto nivel dentro de hoteles de lujo, con el objetivo de mejorar la experiencia general de los huéspedes que se alojan en estos alojamientos exclusivos.',
  'sixStarBlackPage.provides':
    'Ofrece soporte de TI de primer nivel en persona para satisfacer las necesidades tecnológicas de personas de alto perfil mientras están en movimiento.',
  'sixStarBlackPage.p1':
    'Experimenta el soporte de viaje de élite con Six Star: Black (Traveler). Nuestro equipo global de TI de primer nivel atiende a personas de alto perfil y sus familias, brindando asistencia técnica personalizada y sin problemas dondequiera que te lleven tus aventuras, desde el reemplazo de equipos de baja tensión hasta el envío directo de equipos preconfigurados.',
  'sixStarBlackPage.p2':
    'Integrado con las ofertas existentes del hotel para crear una experiencia fluida y elevada para los huéspedes. Brindamos servicios líderes en la industria para cumplir con las expectativas únicas y elevadas de los huéspedes que ocupan suites de nivel presidencial y de embajador, asegurando una estadía verdaderamente lujosa.',

  'sixStarVIPPage.subHeader': 'Soporte premium directo a ingenieros senior y SLAs reducidos para su equipo ejecutivo.',
  'sixStarVIPPage.whenYouCall': 'Cuando llamas o creas un ticket, te garantizamos uno de nuestros ingenieros senior para ayudarte con tu problema.',
  'sixStarVIPPage.onTheGo': 'Tarjetas de contacto On-The-Go con un número directo para soporte.',
  'sixStarVIPPage.bigOrSmall': 'Ya sea que el problema sea grande o pequeño, podemos garantizar un tiempo de SLA más bajo.',
  'sixStarVIPPage.plus':
    'Six Star: VIP Plus, el nivel definitivo de soporte diseñado para elevar tu experiencia a niveles de excelencia sin precedentes. Con VIP Plus, obtienes acceso a un conjunto de privilegios que redefinen el soporte al cliente.',
  'sixStarVIPPage.instant': 'Soporte Directo Instantáneo',
  'sixStarVIPPage.dedicated': 'Número de Soporte Dedicado',
  'sixStarVIPPage.priority': 'SLAs Prioritarios',
  'sixStarVIPPage.withPlus': 'con Six Star: VIP Plus',

  'sixStarInternetPage.subHeader':
    'Six Star: Internet combina circuitos de datos rentables, conectividad móvil integral y troncales SIP con gestión de proyectos gratuita y soporte robusto.',
  'sixStarInternetPage.leverage':
    'Aprovechamos alianzas estratégicas, infraestructura eficiente y soluciones tecnológicas para ofrecer ahorros de costos a sus clientes.',
  'sixStarInternetPage.mobileEnhance':
    'La conectividad móvil mejora la flexibilidad y productividad para las empresas en un mundo cada vez más centrado en la movilidad.',
  'sixStarInternetPage.inclusion':
    'La inclusión de servicios de gestión de proyectos significa una experiencia de incorporación suave y sin problemas para los clientes.',
  'sixStarInternetPage.p1':
    'Six Star prioriza la rentabilidad, ofreciendo circuitos de datos a las tarifas más bajas posibles. Esta característica está diseñada para proporcionar a las empresas una ventaja competitiva al minimizar los gastos relacionados con la conectividad a Internet. Al ofrecer circuitos de datos asequibles, Six Star permite a las empresas asignar sus recursos de manera más eficiente, fomentando el crecimiento y la sostenibilidad.',
  'sixStarInternetPage.p2':
    'Six Star no solo ofrece troncales SIP (Protocolo de Inicio de Sesión) para una comunicación de voz eficiente, sino que también se distingue al proporcionar servicios gratuitos de gestión de proyectos. Esto incluye orientación y apoyo experto durante todo el proceso de implementación. Una vez implementado, el servicio de asistencia de Six Star estará disponible las 24 horas, los 7 días de la semana para cualquier pregunta o problema que pueda tener.',
  'sixStarInternetPage.projectManagement': 'Gestión de Proyectos y Soporte',
  'sixStarInternetPage.maximize': 'Maximización de Ahorros con Circuitos de Datos',

  'sixStarPrintPage.header': 'Impresión Gestionada sin Esfuerzo para una Productividad Continua',
  'sixStarPrintPage.subHeader': 'Impresión Inteligente, Sin Capex: Soluciones Instantáneas con Consumibles Competitivos',
  'sixStarPrintPage.printing': 'Impresión sin Capital',
  'sixStarPrintPage.rapidResponse': 'Respuesta Rápida',
  'sixStarPrintPage.costEffectiveConsumables': 'Consumibles Rentables',
  'sixStarPrintPage.providing': 'Proporcionando una solución de impresión gestionada sin costos iniciales.',
  'sixStarPrintPage.enjoy': 'Disfruta de soporte en el lugar el mismo día/al día siguiente para una impresión ininterrumpida.',
  'sixStarPrintPage.save': 'Ahorra con precios competitivos en consumibles de impresión de Six Star Global.',
  'sixStarPrintPage.hassle': 'Impresión sin Problemas',
  'sixStarPrintPage.unrivaled': 'Garantía de Tiempo de Funcionamiento sin Igual',
  'sixStarPrintPage.p1':
    'Disfruta de la impresión sin la carga financiera. La solución de impresión gestionada de Six Star Global te libera de los costos iniciales, haciendo que la impresión eficiente sea accesible sin la inversión de capital.',
  'sixStarPrintPage.p2':
    'Eleva tu experiencia de impresión con Six Star Global. Benefíciate de un soporte en el lugar rápido, el mismo día/al día siguiente, asegurando que tus operaciones de impresión funcionen sin problemas y sin interrupciones.',

  'sixStarGuestPage.header': 'Diseñado Específicamente para PC de Invitados en el Vestíbulo o Centros de Negocios',
  'sixStarGuestPage.subHeader':
    'Eleva tu experiencia de invitado y optimiza tus operaciones comerciales con nuestra vanguardista Tecnología Accesible para Invitados.',
  'sixStarGuestPage.secure': 'Entorno Seguro',
  'sixStarGuestPage.fully': 'Totalmente Licenciado',
  'sixStarGuestPage.custom': 'Soluciones Personalizadas',
  'sixStarGuestPage.B2B': 'Soporte B2B y Facing del Cliente',
  'sixStarGuestPage.p1':
    'Eleva tu centro de negocios con nuestros robustos servicios de soporte B2B y mejora la experiencia del invitado con estaciones de trabajo públicas confiables y fáciles de usar. Ofrecemos soluciones de TI personalizadas para clientes corporativos mientras creamos un entorno sin problemas para los invitados, ya sea que necesiten acceso a Internet, impresión de documentos u otras necesidades informáticas.',
  'sixSTarGuestPage.customised': 'Soluciones Personalizadas para Cualquier Entorno',
  'sixStarGuestPage.p2':
    'Reconociendo que cada establecimiento de hospitalidad es único, ofrecemos soluciones personalizables para satisfacer tus requisitos específicos. Desde el branding hasta las configuraciones de software, adaptamos nuestros servicios para alinearnos con tus objetivos comerciales, mientras tienes la seguridad con nuestro soporte las 24 horas, los 7 días de la semana.',

  'sixStarPayPage.header': 'Eleva tu Hospitalidad, Simplifica tus Transacciones',
  'sixStarPayPage.subHeader': 'Optimizando la Excelencia en Hospitalidad, una Transacción a la Vez',
  'sixStarPayPage.flexible': 'Pago Flexible',
  'sixStarPayPage.variousPayment': 'Admitimos varias opciones de pago. Los huéspedes pueden pagar como deseen.',
  'sixStarPayPage.roomCharging': 'Cargos a la Habitación',
  'sixStarPayPage.orderRouting': 'Enrutamiento de Pedidos Simplificado, los huéspedes pueden cargar artículos/servicios a su habitación.',
  'sixStarPayPage.payAtTable': 'Paga en la Mesa',
  'sixStarPayPage.ourPay': 'Nuestra solución Pay-At-Table asegura que tu menú esté sincronizado en tiempo real en todas las plataformas.',
  'sixStarPayPage.increasedEfficiency': 'Eficiencia Aumentada y Felicidad del Huésped',
  'sixStarPayPage.p1':
    'Ahorra tiempo y mejora la eficiencia con nuestra gestión de menús sincronizada y características de enrutamiento de pedidos. Concéntrate en brindar una experiencia gastronómica excepcional sin los inconvenientes operativos. Convierte a ese cliente satisfecho en un cliente recurrente.',
  'sixStarPayPage.integration': 'Integración e Instalación Sencillas',
  'sixStarPayPage.p2':
    'La integración perfecta con la mayoría de los principales sistemas PMS y POS significa que puedes seguir utilizando tus herramientas preferidas mientras disfrutas de los beneficios adicionales de Six Star: Pay.',
  'formLoader.BackButton': 'Atrás',
  'formLoader.forwardButton': 'Adelante',
  'formLoader.submitButton': 'Enviar',
  'formLoader.reSubmitButton': 'Volver a enviar',
  'dataTablePro.error': 'Error',
  'sixStarTV.design': 'Hablemos de Diseño',
  'ticketViewPage.button.bundle': 'Paquete',
  'ticketViewPage.button.goBack': 'Volver',
  'ticketViewPage.ticket.summary': 'Resumen del ticket: ',
  'ticketViewPage.formSummary': 'Resumen del formulario',
  'ticketViewPage.masterDetail.createdAt': 'Creado en:',
  'ticketViewPage.masterDetail.assignedMember': 'Miembros asignados',
  'ticketViewPage.masterDetail.ticketContacts': 'Contactos del ticket',
  'ticketViewPage.masterDetail.ticketCreatedAt': 'Ticket creado en',
  'ticketViewPage.masterDetail.mostRecentActivity': 'Actividad más reciente',
  'ticketViewPage.masterDetail.ticketUpdatedBy': 'Ticket actualizado por',
  'ticketViewPage.masterDetail.viewTicket': 'Ver ticket',
  'ticketViewPage.masterDetail.quickNote': 'Nota rápida',
  'ticketViewPage.carbonCopy': 'Copia de Carbón',
  'ticketViewPage.masterDetail.noRecentActivity': 'Sin actividad reciente',
  // "ticketViewPage.carbonCopyNotAvailable": "No hay CC disponibles en este ticket",
  "ticketViewPage.carbonCopyNotAvailable": "No hay CC disponibles en este ticket",
  "TicketViewPage.toolbar.quickClose.model.subTitle": "¿Estás seguro de que quieres resolver {count} tickets rápidamente?",
  "TicketViewPage.toolbar.quickClose.model.title": "Resolver Tickets Rápidamente",
  "TicketViewPage.toolbar.label.quickClose": "Cerrar Rápidamente",
  "TicketViewPage.toolbar.quickClose.submitButton": "Resolver Rápidamente",
  "TicketViewPage.toolbar.quickClose.cancelButton": "Cancelar",
  "TicketViewPage.toolbar.quickClose.model.reason": "Por favor, ingrese un motivo breve para cerrar el ticket, por ejemplo, basura",
  "TicketViewPage.toolbar.quickClose.model.reasonPlaceholder": "Motivo",
  "TicketViewPage.toolbar.quickClose.model.finished": "Los tickets se han resuelto rápidamente",
  "TicketViewPage.toolbar.quickClose.model.updating": "Actualizando tickets",
  "TicketViewPage.toolbar.quickClose.model.error": "Error al actualizar tickets",
  "TicketViewPage.toolbar.quickClose.closeButton": "Cerrar",
  "menu.manage.helpSection.releaseNotes.tabText": "Notas de la Versión",
  "menu.manage.helpSection.releaseNotes.menuText": "Notas de la Versión",
  "releaseNotesPage.title": "Notas de la Versión",
  "notFoundPage.pageDevelopment": "En desarrollo",
  "notFoundPage.pageDevelopmentMessage": "Lo sentimos, esta sección está actualmente en desarrollo. Por favor, vuelva más tarde para obtener actualizaciones emocionantes de nuestro equipo técnico",
  "notFoundPage.button.dashboard": "Tablero",
  "notFoundPage.button.ticket": "Mis Tickets",
  "ticketViewPage.modal.bundle.childTicketField": "Buscar Ticket Secundario",
  "TicketViewPage.toolbar.saveView": "Guardar vista",
  "TicketViewPage.toolbar.saveViewAs": "Guardar vista como",
  "TicketViewPage.toolbar.saveViewAsDuplicate": "Guardar vista como duplicado",
  "TicketViewPage.toolbar.modal.areYouSure": "¿Estás seguro?",
  "TicketViewPage.toolbar.modal.yes": "Sí",
  "TicketViewPage.toolbar.modal.no": "No",
  "ticketViewPage.carbonCopyNotAvailable": "No hay CC disponibles en este ticket",
  "dashboard.modal.button.Close": "Cerrar",
  "dashboard.modal.button.Add": "Agregar",
  "dashboard.modal.addContact": "Crear Nuevo Contacto",
  "ticketViewPage.relatedTickets": "Tickets Relacionados",
  "createContact.success": "Contacto creado exitosamente",
  "ticketViewPage.childTickets": "Tickets secundarios",
  "ticketViewPage.parent": "Principal",
  "ticketViewPage.ticketSummary": "Resumen del ticket",
  "ticketViewPage.ticketNumber": "Número de ticket",
  "TicketViewPage.toolbar.label.filters": "Filtros",
  "TicketViewPage.toolbar.label.addContact": "Agregar contacto",
  "ticketViewPage.addNote": "Añadir nota",
  "ticketViewPage.ticketUpdate": "Actualización del ticket",
  "ticketViewPage.infoRequest": "Solicitud de información",
  "ticketViewPage.approval": "Aprobación",
  "ticketViewPage.requestForAction": "Solicitud de acción",
  "ticketViewPage.resolution": "Resolución",
  "ticketviewPage.tab.overview": "Resumen",
  "ticketviewPage.tab.timeline": "Cronología",
  "ticketviewPage.tab.formSummary": "Resumen del formulario",
  "ticketviewPage.tab.notes": "Notas",
  "ticketviewPage.tab.tasks": "Tareas",
  "ticketviewPage.tab.activity": "Actividad",
  "ticketViewPage.createdAt": "Creado en",
  "ticketViewPage.lastUpdate": "Última actualización",
  "ticketViewPage.priority": "Prioridad",
  "ticketViewPage.ticketSLA": "Ticket SLA",
  "ticketViewPage.requester": "Solicitante",
  "ticketViewPage.ticketOwner": "Propietario del ticket",
  "ticketViewPage.ticketStatus": "Estado del ticket:",
  "ticketViewPage.ticketSummary": "Resumen del ticket",
  "ticketViewPage.ticketNumber": "Número de ticket: #",
  "ticketViewPage.podAccess": "Acceso rápido",
  "ticketViewPage.podAccessDesc": "Este pod te dará acceso rápido a diferentes secciones del ticket",
  "ticketViewPage.addContact": "Añadir contacto",
  "ticketViewPage.editType": "Editar tipo",
  "ticketViewPage.assigneeOrRequester": "Actualizar asignado/solicitante",
  "ticketViewPage.contacts": "Contactos",
  "ticketViewPage.contactDecs": "Este pod mostrará todos los contactos relacionados con este ticket",
  "menu.manage.internalSection.forms.tabText": "Formularios",
  "menu.manage.internalSection.forms.menuText": "Formularios",
  "ticketViewPage.toolbar.label.quickEdit": "Edición rápida",
  "ticketViewPage.toolbar.editTicket.submitButton": "Enviar",
  "datatableViews.toolbar.currentView": "Vista actual",
  "datatableViews.toolbar.loading": "Cargando...",
  "ticketviewPage.tab.overview": "Resumen",
  "ticketviewPage.tab.timeline": "Cronología",
  "ticketviewPage.tab.notes": "Notas",
  "ticketviewPage.tab.tasks": "Tareas",
  "ticketviewPage.tab.formSummary": "Resumen del formulario",
  "ticketviewPage.tab.activity": "Actividad",
  "ticketViewPage.timeline.ticketCreated": "Ticket creado",
  "ticketViewPage.timeline.ticketUpdated": "Ticket actualizado",
  "ticketViewPage.timeline.messageAdded": "Mensaje añadido",
  "ticketViewPage.timeline.titleChange": "Cambio de título",
  "ticketViewPage.organisations": "Organizaciones",
  "ticketViewPage.ticketDevices": "Dispositivos del ticket",
  "ticketViewPage.ticketCC": "Ticket CC",
  "menu.manage.companyContact.tabText": "Empresa",
  "menu.manage.companyContact.menuText": "Empresa",
  "ticketViewPage.rateYourService": "Califique su servicio",
  "ticketViewPage.relatedTickets.desc": "Este pod mostrará todos los tickets relacionados",
  "ticketViewPage.ticketCC.desc": "Este pod mostrará todas las copias de carbón relacionadas",
  "ticketViewPage.ticketDevices.desc": "Este pod mostrará todos los dispositivos relacionados",
  "ticketViewPage.ticketSLA.desc": "Este pod mostrará el SLA de este ticket",
  "ticketViewPage.documents.desc": "Este pod mostrará todos los documentos relacionados con este ticket",
  "ticketViewPage.organisations.desc": "Este pod mostrará todas las organizaciones relacionadas con este ticket",
  "ticketViewPage.initialResponse": "Respuesta inicial",
  "ticketViewPage.nextUpdate": "Próxima actualización",
  "ticketViewPage.estimatedResolution": "Resolución estimada",
  "ticketViewPage.resolution": "Resolución",
  "ticketViewPage.relatedTickets": "Tickets Relacionados",
  "ticketViewPage.bundleTicket": "Agrupar Ticket",
  "ticketViewPage.shareTicket": "Compartir Ticket",
  "ticketViewPage.contact": "Contacto",
  "ticketViewPage.email": "Correo Electrónico",
  "ticketViewPage.rateYourServiceComment": "Puede calificar su servicio en cualquier momento durante su sesión con Six Star Global.",
  "ticketViewPage.responseHistory": "Historial de Respuestas",
  "ticketViewPage.initialComment": "Comentario Inicial",
  "ticketViewPage.agentResponse": "Respuesta del Agente",
  "ticketVIewPage.notClosed": "No Cerrado",
  "ticketViewPage.created": "Creado en",
  "ticketViewPage.firstResponse": "Primera Respuesta",
  "ticketViewPage.estimatedResolution": "Resolución Estimada",
  "ticketViewPage.actualResolution": "Resolución Real",
  "createOrg.title": "Crear Organización",
  "ticketViewPage.addManager": "Agregar Gerente de Cuenta",
  "editManager.addNew": "Agregar Nuevo",
  "editManager.currentManagers": "Gerentes Actuales",
  "companyContactPage.tabs.overview.region": "Región",
  "ticketViewPage.ratings": "Calificaciones del Servicio",
  "ticketViewPage.ratingsdesc": "Este pod muestra cualquier calificación que se haya dejado para este ticket.",
  "menu.manage.userSection.createOrganization.tabText": "Crear Organización",
  "menu.manage.userSection.createOrganization.menuText": "Crear Organización",
  "companyPage.info": "Información de la Empresa",
  "companyPage.AccountManager": "Gerente de Cuenta",
  "companyPage.location": "Ubicación",
  "companyPage.relatedOrganizations": "Organizaciones Relacionadas",
  "companyPage.parentOrganization": "Organización Principal",
  "companyPage.childOrganizations": "Organizaciones Hijas",
  "companyPage.noParentOrganization": "Sin Organización Principal",
  "companyPage.noChildOrganizations": "Sin Organizaciones Hijas",
  "ticketViewPage.infoDesc": "Este pod mostrará toda la información relacionada con este ticket",
  "ticketViewPage.ticketInfo": "Información del Ticket",
  "ticketViewPage.timeline.priorityChange": "Cambio de Prioridad",
  "ticketViewPage.timeline.statusChange": "Cambio de Estado",
  "ticketViewPage.timeline.ticketResolved": "Ticket Resuelto",
  'ticketViewPage.infoDesc': 'Este módulo mostrará toda la información relacionada con este ticket',
  'ticketViewPage.ticketInfo': 'Información del Ticket',
  'ticketViewPage.timeline.priorityChange': 'Cambio de Prioridad',
  'ticketViewPage.timeline.statusChange': 'Cambio de Estado',
  'ticketViewPage.timeline.ticketResolved': 'Ticket Resuelto',
  'ticketViewPage.addSummary': 'Agregar Resumen',
  'ticketViewPage.notResponded': 'No Respondido',
  'ticketViewPage.noInitialComment': 'Sin Comentario Inicial',
  'ticketViewPage.noAgentResponse': 'Sin Respuesta del Agente',
  'ticketViewPage.makePrivate': 'Hacer Privado',
  'ticketViewPage.makePublic': 'Hacer Público',
  'ticketViewPage.pod.team': 'Equipo',
  'ticketViewPage.team.desc': 'Este módulo mostrará a todos los miembros del equipo relacionados con este ticket',
};

export default pages;
