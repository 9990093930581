const components = {
  "components.search": "Suche",

  // Neue Übersetzungen
  "components.leftSideBarMenuList.TitleA": "SIX STAR",
  "components.leftSideBarMenuList.TitleB": "VERWALTEN",
  
  // Passwortüberprüfung
  "components.passwordVerifyComponent.mustContain": "Das Passwort muss folgende Kriterien erfüllen: ",
  "components.passwordVerifyComponent.upperCase": "Ein Großbuchstabe",
  "components.passwordVerifyComponent.lowerCase": "Ein Kleinbuchstabe",
  "components.passwordVerifyComponent.number": "Eine Zahl",
  "components.passwordVerifyComponent.min": "Mindestens 8 Zeichen",
  "components.passwordVerifyComponent.special": "Ein Sonderzeichen",
  "components.passwordVerifyComponent.match": "Passwörter müssen übereinstimmen",
  "components.passwordVerifyComponent.enterNew": "Geben Sie Ihr neues Passwort ein",
  "components.passwordVerifyComponent.confirm": "Bestätigen Sie Ihr neues Passwort",
  "components.passwordVerifyComponent.random": "Ihr zufälliges Passwort lautet:",
  "components.passwordVerifyComponent.copiedToClipboard": "In die Zwischenablage kopiert",
  "components.passwordVerifyComponent.makeNote": "Bitte notieren Sie sich dies.",
  
  // Passwort zurücksetzen
  "components.passwordReset.error": "Wir entschuldigen uns, aber wir können Ihr Passwort derzeit nicht zurücksetzen.",
  "components.passwordReset.submitRequest": "Bitte senden Sie eine Support-Anfrage, damit wir Ihnen beim Zurücksetzen Ihres Passworts helfen können.",
  "components.passwordReset.thankYou": "Vielen Dank für Ihr Verständnis.",
  "components.passwprdReset.success.pleaseWait": "Bitte warten Sie, Passwort wird zurückgesetzt",

};
export default components;
