// TODO: this need moving into a DB call

const flagNumbers = [
  {
    url: "https://contact-us-flags.s3.eu-west-2.amazonaws.com/united-kingdom.png",
    number: "+44 33 3360 8800",
  },
  {
    url: "https://contact-us-flags.s3.eu-west-2.amazonaws.com/united-states-of-america.png",
    number: "+1 866 766 7401",
  },
  {
    url: "https://contact-us-flags.s3.eu-west-2.amazonaws.com/germany.png",
    number: "+49 1529 0012 517",
  },
  {
    url: "https://contact-us-flags.s3.eu-west-2.amazonaws.com/ireland.png",
    number: "+35 31447 0298",
  },
  {
    url: "https://contact-us-flags.s3.eu-west-2.amazonaws.com/france.png",
    number: "+31 20 7038 322",
  },
  // {
  //   url: "https://contact-us-flags.s3.eu-west-2.amazonaws.com/belgium.png",
  //   number: "+32 2881 0466",
  // },
  {
    url: "https://contact-us-flags.s3.eu-west-2.amazonaws.com/greece.jpg",
    number: "+30 2111 987 900",
  },
  // {
  //   url: "https://contact-us-flags.s3.eu-west-2.amazonaws.com/poland.png",
  //   number: "+48 585 359 198",
  // },
  {
    url: "https://contact-us-flags.s3.eu-west-2.amazonaws.com/spain.png",
    number: "+34 900 87 6178",
  },
  {
    url: "https://contact-us-flags.s3.eu-west-2.amazonaws.com/thailand.png",
    number: "+66 2 026 0371",
  }
];

export default flagNumbers;
