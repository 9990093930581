import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import withStyles from "@mui/styles/withStyles";

const MenuIcon = (props) => {
  const { icon, theme } = props;

  return <FontAwesomeIcon icon={icon} fixedWidth style={{ fontSize: 22 }} />;
};

export default withStyles((theme) => ({}), { withTheme: true })(MenuIcon);
