const pages = {
  'dashboard.pageTitle': 'Pulpit',
  'dashboard.greeting.morning': 'Dzień Dobry',
  'dashboard.greeting.afternoon': 'Dobry Wieczór',
  'dashboard.greeting.evening': 'Dobry Wieczór',
  'dashboard.welcomeMessage': 'Masz {tickets} aktywnych zgłoszeń i {messages} wiadomości do odpowiedzi.',
  'notAuthorizedPage.title': 'Brak uprawnień',
  'notAuthorizedPage.desc': 'Nie masz uprawnień do dostępu do tej strony.',
  'dashboard.welcomeText': 'Witaj w {ssg}!',
  'dashboard.button.addWidget': 'Dodaj Widget',
  'dashboard.button.close': 'Zamknij',
  'dashboard.button.edit': 'Edytuj',
  'dashboard.button.save': 'Zapisz',
  'settingsPage.pageTitle': 'Ustawienia',
  'ticketViewPage.priority': 'Priorytet',
  'ticketViewPage.assignee': 'Przypisany',
  'ticketViewPage.team': 'Zespół',
  'ticketViewPage.sixStarTeam': 'Dodatkowi członkowie zespołu Six Star',
  'ticketViewPage.resourcesOnTicket': 'Zasoby na zgłoszeniu',
  'ticketViewPage.followers': 'Obserwujący',
  'ticketViewPage.tags': 'Tagi',
  'ticketViewPage.takeIt': 'weź to',
  'ticketViewPage.notes': 'Notatki',
  'ticketViewPage.activity': 'Aktywność',
  'ticketViewPage.timeline': 'Oś czasu',
  'ticketViewPage.tasks': 'Zadania',
  'ticketViewPage.search': 'Szukaj',
  'ticketViewPage.noteAddedBy': 'Notatka dodana przez',
  'ticketViewPage.moreButton': 'Więcej',
  'ticketViewPage.makeInternal': 'Ustaw jako wewnętrzny',
  'ticketViewPage.makePublic': 'Ustaw jako publiczny',
  'ticketViewPage.deleteComment': 'Usuń',
  'ticketViewPage.reportComment': 'Zgłoś',
  'ticketViewPage.internalTicketComment': 'To jest wewnętrzny komentarz',
  'ticketViewPage.publicTicketComment': 'To jest publiczny komentarz',
  'ticketViewPage.ticketSource.internal_web': 'Wewnętrzna strona internetowa',
  'ticketViewPage.ticketSource.external_email': 'Wewnętrzny email',
  'ticketViewPage.followTicket': 'Śledź zgłoszenie',
  'ticketViewPage.unfollowTicket': 'Przestań śledzić zgłoszenie',
  'ticketViewPage.tasks.priority.completed': 'Zakończone',
  'ticketViewPage.tasks.priority.in_progress': 'W trakcie',
  'ticketViewPage.tasks.priority.not_started': 'Nie rozpoczęte',
  'ticketViewPage.tasks.priority.low': 'Niski',
  'ticketViewPage.tasks.priority.medium': 'Średni',
  'ticketViewPage.tasks.priority.important': 'Ważny',
  'ticketViewPage.tasks.priority.urgent': 'Pilny',
  'ticketViewPage.tasks.assignedTo': 'Przypisane do',
  'ticketViewPage.tasks.completedBy': 'Zakończone przez',
  'ticketViewPage.requester': 'Zgłaszający',
  'ticketViewPage.organization': 'Organizacja',
  'ticketViewPage.button.cancel': 'Anuluj',
  'ticketViewPage.button.close': 'Zamknij',
  'ticketViewPage.downloadEml': 'Pobierz EML',
  'newRequestPage.header': 'Nowe zgłoszenie',
  'newRequestPage.button.internalRequest': 'Wewnętrzne zgłoszenie',
  'newRequestPage.button.serviceRequest': 'Zgłoszenie serwisowe',
  'newRequestPage.button.procurementRequest': 'Zgłoszenie zakupowe',
  'newRequestPage.card.urgentHelp': 'Potrzebujesz pilnej pomocy?',
  'newRequestPage.button.callUs': 'Zadzwoń do nas',
  'newRequestPage.button.passwordReset': 'Resetowanie hasła dla domeny/komputera',
  'newRequestPage.button.newRequest': 'Zgłoś problem',
  'formPage.title.supportRequest': 'Zgłoszenie wsparcia',
  'formPage.title.newStarterRequest': 'Nowy wniosek o uruchomienie',
  'formPage.title.serviceRequest': 'Zgłoszenie serwisowe',
  'formPage.title.newStarter': 'Nowy starter / Zmiana roli',
  'formPage.title.leaver': 'Osoba odchodząca',
  'formPage.title.engineerVisit': 'Wizyta inżyniera',
  'formPage.title.procurementRequest': 'Wniosek o zamówienie',
  'formPage.title.developmentRequest': 'Wniosek o rozwój',
  'formPage.title.internalRequest': 'Wniosek wewnętrzny',
  'ldapPasswordResetPage.button.submit': 'Zresetuj hasło',
  'ldapPasswordResetPage.button.generatePassword': 'Wygeneruj hasło',
  'ldapPasswordResetPage.title': 'Resetowanie hasła dla domeny/komputera',
  'ldapPasswordResetPage.paragraph.intro':
    'Zresetuj hasło do domeny komputera. W zależności od polityki hotelu, możesz być zobowiązany do zmiany hasła co 90 dni.',
  'licencePage.title': 'Licencje',
  'licencePage.office365Licences': 'Licencje Office 365',
  'licencePage.license': 'Licencja',
  'licencePage.active': 'Aktywna',
  'licencePage.consumed': 'Wykorzystana',
  'licencePage.unused': 'Niewykorzystana',
  'assetPage.title': 'Zasoby',
  'resourceTracker.title': 'Śledzenie zasobów',
  'ticketViewPage.overview': 'Przegląd',
  'ticketViewPage.roles': 'Role',
  'ticketViewPage.addContact': 'Dodaj kontakt',
  'ticketViewPage.status': 'Status',
  'ticketViewPage.createdAt': 'Utworzono',
  'ticketViewPage.updatedAt': 'Zaktualizowano',
  'ticketViewPage.devices': 'Urządzenia',
  'ticketViewPage.contacts': 'Kontakty',
  'ticketViewPage.documents': 'Dokumenty',
  'ticketViewPage.addNote': 'Dodaj notatkę',
  'ticketViewPage.info': 'Informacje',
  'ticketViewPage.created': 'Utworzono',
  'ticketViewPage.lastActivity': 'Ostatnia aktywność',
  'ticketViewPage.priorityLevel': 'Poziom priorytetu',
  'ticketViewPage.assigned': 'Przypisano',
  'ticketViewPage.ticketSLA': 'SLA zgłoszenia',
  'ticketViewPage.firstResponse': 'Pierwsza odpowiedź',
  'ticketViewPage.resolution': 'Rozwiązanie',
  'ticketViewPage.nextUpdate': 'Następna aktualizacja',
  'ticketViewPage.mostRecentMessages': 'Najnowsza wiadomość',
  'organizationViewPage.overview': 'Przegląd',
  'organizationViewPage.activity': 'Aktywność',
  'assetPage.name': 'Nazwa',
  'assetPage.type': 'Typ',
  'assetPage.status': 'Status',
  'assetPage.serial': 'Numer seryjny',
  'assetPage.manufacturer': 'Producent',
  'assetPage.cpu': 'Procesor',
  'assetPage.memory': 'Pamięć',
  'assetPage.lastUser': 'Ostatnio widziany',
  'assetPage.lastSeen': 'Ostatnio widziany',
  'assetPage.operatingSystem': 'System operacyjny',
  'sixStarMeet.header': 'Technologia za każdym świetnym spotkaniem',
  'contactUsPage.number.button': 'Kliknij tutaj',
  'contactUsPage.title.second': 'Zadzwoń do nas',
  'contactUsPage.title.first': 'Cześć, w czym możemy Ci pomóc?',
  'contactUsPage.header.boxs.one': 'Jak zgłosić problem?',
  'contactUsPage.header.boxs.two': 'Jak się wylogować?',
  'contactUsPage.header.boxs.three': 'Gdzie zobaczyć wszystkie moje zgłoszenia?',
  'contactUsPage.header.boxs.four': 'Jak dodać widgety?',
  'contactUsPage.search.answers': 'Szukaj odpowiedzi...',
  'contactUsPage.search': 'Szukaj odpowiedzi...',
  'ticketViewPage.approvedAt': 'Zatwierdzono',
  'ticketViewPage.rejectedAt': 'Odrzucono',
  'ticketViewPage.approvedBy': 'Zatwierdzono przez',
  'ticketViewPage.rejectedBy': 'Odrzucono przez',
  'ticketViewPage.approvalStatus': 'Status zatwierdzenia',
  'ticketViewPage.approvalStatus.approved': 'Zatwierdzony',
  'ticketViewPage.approvalStatus.rejected': 'Odrzucony',
  'ticketViewPage.approvalStatus.pending': 'Oczekujący',
  'ticketViewPage.requestedBy': 'Zgłoszono przez',

  // "ticketViewPage.approvedAt": "Zatwierdzone o",
  // "ticketViewPage.rejectedAt": "Odrzucone o",
  // "ticketViewPage.approvedBy": "Zatwierdzone przez",
  // "ticketViewPage.rejectedBy": "Odrzucone przez",
  // "ticketViewPage.approvalStatus": "Status zatwierdzenia",
  // "ticketViewPage.approvalStatus.approved": "Zatwierdzone",
  // "ticketViewPage.approvalStatus.rejected": "Odrzucone",
  // "ticketViewPage.approvalStatus.pending": "Oczekujące",
  // "ticketViewPage.requestedBy": "Wniosek od",

  // Strona pierwszej konfiguracji
  'firstTimeSetupPage.firstLookAtManage': 'Pierwszy rzut oka na Zarządzanie 3.0',
  'firstTimeSetupPage.elevatingYourITManagementExperience': 'Podnoszenie poziomu doświadczeń z zarządzania IT',
  'firstTimeSetupPage.weAreThrilledToPresent':
    'Jesteśmy podekscytowani prezentacją zwiastuna nowego rozdziału w Twojej podróży z zarządzaniem IT w wersji beta Six Star: Zarządzanie 3.0.',
  'firstTimeSetupPage.asAValuedMember':
    'Jako cenieni członkowie rodziny Six Star Global, którzy już korzystają z naszych rozwiązań, z przyjemnością przedstawiamy Ci zmodyfikowane i ulepszone doświadczenie oznaczające, że zarządzanie IT staje się bardziej dynamiczne, zoptymalizowane i intuicyjne.',
  'firstTimeSetupPage.aNewChapterInITManagement': 'Nowy rozdział w zarządzaniu IT',
  'firstTimeSetupPage.technologicalTakeOver': 'Przejęcie technologiczne:',
  'firstTimeSetupPage.redesign':
    'Nowoczesny redesign uwzględniający prędkość i stabilność. Wydajność to nasz priorytet, dlatego sprawiliśmy, że bez względu na to, gdzie jesteś - na urządzeniu mobilnym czy komputerze stacjonarnym - masz swój portfel IT pod ręką.',
  'firstTimeSetupPage.effortlessLicenseOverview': 'Bezproblemowy przegląd licencji:',
  'firstTimeSetupPage.simplifyLicenceManagement':
    'Bezproblemowe zarządzanie licencjami. Zarządzanie 3.0 umożliwia łatwe zarządzanie oprogramowaniem i licencjami użytkowników, zapewniając zgodność i optymalizację wydatków.',
  'firstTimeSetupPage.smartReportGeneration': 'Inteligentne generowanie raportów: ',
  'frstTimeSetupPage.harnessThePowerOfData':
    'Wykorzystaj moc danych do podejmowania strategicznych decyzji. Generuj spersonalizowane raporty na podstawie statystyk z wniosków, pomagając dostosować operacje i poprawić jakość obsługi w Twojej placówce.',
  'firstTimeSetupPage.tailoredDashboardAtYourFingertips': 'Spersonalizowany pulpit na wyciągnięcie ręki: ',
  'firstTimeSetupPage.craftDashboard':
    'Stwórz spersonalizowany pulpit IT z różnymi widżetami, umożliwiając szybkie zrozumienie stanu wniosków i trendów zgodnych z Twoimi konkretnymi zainteresowaniami.',
  'firstTimeSetupPage.benefitsTitle': 'Korzyści płynące z Zarządzania 3.0 dla operacji hotelowych',
  'firstTimeSetupPage.amplifiedEfficiency': 'Wzmożona efektywność: ',
  'firstTimeSetupPage.guestExperience':
    'Skoncentruj się na dostarczaniu wyjątkowych doświadczeń dla gości, dzięki zautomatyzowanym procesom rozwiązywania problemów w sposób płynny.',
  'firstTimeSetupPage.realTimeIntelligence': 'Inteligencja w czasie rzeczywistym: ',
  'firstTimeSetupPage.instantlyAccess': 'Natychmiastowy dostęp do aktualnych danych i statystyk wniosków dla szybkich, świadomych decyzji.',
  'firstTimeSetupPage.tailoredToYou': 'Dopasowane do Ciebie: ',
  'firstTimeSetupPage.customiseDashboard':
    'Spersonalizowany pulpit wyświetla dokładnie te informacje, których potrzebujesz, oferując pełny widok Twoich operacji IT.',
  'firstTimeSetupPage.futureProof': 'Zapewnienie przyszłościowej pewności:',
  'firstTimeSetupPage.atThisIminentLaunch':
    'Ponieważ ta nadchodząca premiera to tylko wersja beta, możesz oczekiwać regularnych aktualizacji i innowacyjnych funkcji, które nadążają za zmieniającymi się potrzebami Twojej infrastruktury IT.',
  'firstTimeSetUpPage.button.getStarted': 'Rozpocznij',

  // ŝadowanie
  'loading.title': 'ŝadowanie...',

  // Widok wniosku
  'ticketView.activityModule.title': 'Działania',
  'ticketView.emailModule.title': 'Emaile',
  'ticketView.infoModule.subheaderOne': 'Wsparcie',
  'ticketView.infoModule.subheaderTwo': 'Zespół techniczny',
  'ticketView.infoModule.value 1': 'Opcja 1',
  'ticketView.infoModule.value 2': 'Opcja 2',
  'ticketView.infoModule.value 3': 'Opcja 3',
  'ticketView.infoModule.value 4': 'Opcja 4',
  'ticketView.notesModule.title': 'Notatki',
  'ticketView.tasksModule.title': 'Zadania',

  'ticketViewPage.modal.bundle.title': 'Pakietowanie biletów',
  'ticketViewPage.modal.bundle.parentTicket': 'Bilet nadrzędny',
  'ticketViewPage.modal.bundle.childTicket': 'Bilet podrzędny',
  'ticketViewPage.modal.bundle.button': 'Pakiet',
  'ticketViewPage.modal.bundle.button.cancel': 'Anuluj',
  'companyContactPage.tabs.overview': 'Przegląd',
  'companyContactPage.tabs.activities': 'Aktywności',
  'companyContactPage.tabs.tasks': 'Zadania',
  'companyContactPage.tabs.overview.lastActivity': 'Ostatnia aktywność',
  'companyContactPage.tabs.overview.customerStatus': 'Status klienta',
  'companyContactPage.tabs.overview.userCreated': 'Utworzony przez użytkownika',
  'companyContactPage.tabs.overview.company': 'Firma',
  'companyContactPage.tabs.overview.expiringLicences': 'Licencje wygasające',
  'companyContactPage.tabs.overview.opemTickets': 'Otwarte bilety',
  'companyContactPage.tabs.overview.mostRecentActivity': 'Najnowsza aktywność',
  'companyContactPage.tabs.overview.Drive': 'Dysk',
  'companyContactPage.tabs.overview.lastActivity: ': 'Ostatnia aktywność: ',
  'companyContactPage.tabs.search': 'Szukaj',
  'companyContactPage.tabs.overview.AddedNote': 'Dodana notatka',
  'companyContactPage.tabs.sidePanel.contacts': 'Kontakty',
  'companyContactPage.tabs.sidePanel.other': 'Inne',
  'companyContactPage.tabs.sidePanel.licenses': 'Licencje',
  'companyContactPage.tabs.sidePanel.procurement': 'Zakupy',
  'companyContactPage.tabs.sidePanel.assets': 'Zasoby',
  'companyContactPage.tabs.sidePanel.invoices': 'Faktury',
  'companyContactPage.tabs.sidePanel.company': 'Firma',
  'ticketViewPage.checklist': 'Lista kontrolna',
  'ticketViewPage.attachments': 'Załączniki',
  'ticketViewPage.relatedItems': 'Powiązane elementy',
  'sixStarTVPage.header': 'Liderzy w funkcjonalności, stabilności i łatwości użytkowania',
  'sixStarTVPage.integration': 'Integracja',
  'sixStarTVPage.webBasedManagement': 'Zarządzanie oparte na sieci',
  'sixStarTVPage.gaming': 'Gry',
  'sixStarTVPage.fullyCustomisable': 'W pełni dostosowalne dla marki doświadczenie',
  'sixStarTVPage.extraFeatures': 'Cała masa dodatkowych funkcji',
  'sixStarTVPage.plus': 'z Six Star: TV Plus',
  'sixStarTVPage.offer':
    'Six Star: TV Plus oferuje całą gamę dodatków, aby umilić pobyt gości i jednocześnie zabezpieczyć te ważne dodatkowe sprzedaże.',
  'sixStarTVPage.wakeUp': 'Obudź się',
  'sixStarTVPage.internationalViewing': 'Międzynarodowy odbiór',
  'sixStarTVPage.roomService': 'Obsługa pokoju',
  'sixStar.GetInTouch': 'Skontaktuj się z nami',
  'sixStar.BackToTop': 'Wróć do góry',
  'quickCreateTicketModal.title': 'Szybkie tworzenie biletu',
  'quickCreateTicketModal.button.submit': 'Prześlij',
  'quickCreateTicketModal.button.submitAndView': 'Prześlij i zobacz bilet',
  'quickCreateTicketModal.disclaimer': 'Ten bilet zostanie automatycznie opublikowany jako średni priorytet, a status jako NOWY',
  'quickCreateTicketModal.success': 'Bilet utworzony pomyślnie',
  'quickCreateTicketModal.button.close': 'Zamknij',

  // sixstar black page
  // "sixStarBlackPage.header": "Podnieś Globalne Podróże i Prezydenckie Apartamenty",
  // "sixStarBlackPage.traveler": "Podróżnik",
  // "sixStarBlackPage.hotel": "Hotel",

  // sixstar VIP page
  'sixStarVIPPage.header': 'Wybór Kierownictwa dla Szybkich Rozwiązań z Priorytetowym Dostępem do GM',
  'sixStarVIPPage.directToSeniorSupport': 'Bezpośrednie Wsparcie dla Seniorów',
  'sixStarVIPPage.contactCards': 'Karty Kontaktowe',
  'sixStarVIPPage.reducedSLA': 'Zredukowany SLA dla kierownictwa',
  'sixStarVIPPage.contactCardsForGM': 'Karty kontaktowe dla GM-ów',

  // sixstar cast page
  'sixStarCastPage.header': 'Rozrywka dla Gości z Domu',
  'sixStarCastPage.oneStepAuthentication': 'Autoryzacja w Jednym Kroku',
  'sixStarCastPage.streamCastableApp': 'Goście mogą transmitować dowolną aplikację do rzutowania',
  'sixStarCastPage.CloudManaged': 'Zarządzane w Chmurze',

  // sixstar internet page
  'sixStarInternetPage.header': 'Podnieś ŝączność, Ogranicz Koszty i Uwolnij Niespotykane Wsparcie',
  'sixStarInternetPage.lowCost': 'Niskie Koszty',
  'sixStarInternetPage.mobileConnectivity': 'ŝączność Mobilna',
  'sixStarInternetPage.installations': 'Instalacje',

  // sixstar display page
  'sixStarDisplayPage.header': 'Transformacja Przestrzeni i Podniesienie Doświadczeń',
  'sixStarDisplayPage.workWithAnyScreen': 'Działa z Dowolnym Ekranem',
  'sixStarDisplayPage.wayFinding': 'Nawigacja i Wyszukiwanie Okazji',
  'sixStarDisplayPage.mobileWebEditor': 'Mobilny Edytor Sieci Web',

  'sixStarPlus.getInTouch': 'Skontaktuj się',
  'sixStarTVPage.externalTVInputs': 'Zewnętrzne wejścia TV',
  'sixStarTVPage.p2':
    'Wykorzystuj porty HDMI do konsol i innych systemów oraz aplikacji takich jak AirPlay i Six Star: Cast, aby umożliwić gościom przesyłanie strumieniowe z ich własnych urządzeń osobistych.',
  'sixStarTVPage.p3': 'Koszty inwestycji można zredukować, korzystając z istniejącej infrastruktury.',
  'sixStarTVPage.p4': 'Monitorowanie wewnętrzne i zarządzanie treściami bezpośrednio z przeglądarki.',
  'sixStarTVPage.p5': 'Szybko rosnące zapotrzebowanie dostawców usług hotelarskich, które SS:TV może dostarczyć z werwą.',
  'sixStarTVPage.fullyCustomizable1080p':
    'W pełni dostosowywalne menu 1080p bez sztywnych ograniczeń projektowych. Zacznij od dedykowanych szablonów, aby łatwo rozpocząć, lub skorzystaj z pomocy SSG w projektowaniu.',

  'sixStarVoicePage.header': 'Odkryj Moc Six Star: Voice',
  'sixStarVoicePage.p1': 'Bezprecedensowa Komunikacja',
  'sixStarVoicePage.advancedIntegrations': 'Zaawansowane Integracje',
  'sixStarVoicePage.lineRedundancy': 'Redundancja Linii',
  'sixStarVoicePage.customisableSolutions': 'Rozwiązania Dostosowane',
  'sixStarVoicePage.seamlessConnect': 'ŝączność z aplikacjami zewnętrznymi w celu uzyskania wszechstronnej funkcjonalności systemu.',
  'sixStarVoicePage.enjoy': 'Ciesz się nieprzerwaną komunikacją dzięki standardowej redundancji linii.',
  'sixStarVoicePage.tailor': 'Dostosuj Six Star: Voice, aby zoptymalizować unikalne operacje hotelowe.',
  'sixStarVoicePage.p2': 'Bezproblemowa Komunikacja',
  'sixStarVoicePage.sayGoodbye':
    'Pożegnaj się z czasem przestoju. Nasz system jest zbudowany z redundancją linii jako standardową funkcją, zapewniając nieprzerwaną komunikację nawet w obliczu nieoczekiwanych wyzwań. Mamy również dedykowanych członków zespołu telefonicznego gotowych do udzielenia wsparcia w razie potrzeby.',

  'sixStarCastPage.low-Cost': 'Niskokosztowe rozwiązanie do rzutowania treści z urządzeń gości na dowolny telewizor kompatybilny z HDMI.',
  'sixStarCastPage.guestCan': 'Goście mogą uzyskać dostęp do swojej rozrywki od razu po wejściu do swojego pokoju.',
  'sixStarCastPage.netflix': 'Netflix, Hulu, Disney+ i wiele innych dostępne.',
  'sixStarCastPage.easilyIntegrated': 'ŝatwo integrowane z istniejącymi rozwiązaniami telewizyjnymi.',
  'sixStarCastPage.p1':
    'Korzystaj z markowego portalu marketingowego do bezpośredniej komunikacji z gośćmi w hotelu. Za pomocą systemu zarządzania treścią opartego na przeglądarkach internetowych (CMS) personel marketingowy hotelu może informować gości o wydarzeniach w hotelu, udogodnieniach, ofertach specjalnych, godzinach otwarcia, programach lojalnościowych i wiele więcej.',

  'sixStarDisplayPage.subHeader':
    'API-centriczny system wyświetlania informacji o spotkaniach, reklamie, wskazówkach, tablicach lotów i menu. Integracje z wieloma usługami zewnętrznymi.',
  'sixStarDisplayPage.noFancy': 'Brak wymaganej skomplikowanej aparatury do rozpoczęcia, jedynym wymaganiem jest port HDMI.',
  'sixStarDisplayPage.mutlipleGoals': 'Six Star: Display może osiągnąć wiele celów.',
  'sixStarDisplayPage.plan': 'Planuj w dowolnym miejscu i wyświetlaj wszędzie, ponieważ wszystko jest zarządzane w chmurze.',
  'sixStarDisplayPage.powerful': 'Potężne połączenie z API przyciąga wzrok.',
  'sixStarDisplayPage.p1':
    'Korzystaj z danych na temat aktualności, pogody, ruchu drogowego i lotów, aby zamienić swoje ekrany w centra informacji czasu rzeczywistego. ŝącz dane z API z treściami markowymi lub używaj wyzwalaczy API do automatycznej zmiany treści.',

  'sixStarParkPage.header': 'Zarządzaj Parkingiem Jak Pokojami i ŝóżkami (Wliczając Dochody)',
  'sixStarParkPage.customerSat': 'Zadowolenie Klienta',
  'sixStarParkPage.improve': 'Popraw ogólną jakość doświadczenia z parkowaniem i maksymalizuj rentowność.',
  'sixStarParkPage.realTimeInformation': 'Informacje w czasie rzeczywistym',
  'sixStarParkPage.provide': 'Zapewnij informacje w czasie rzeczywistym na temat zajętości i dochodów za pośrednictwem jednego portalu.',
  'sixStarParkPage.geoMarketing': 'Geo-Marketing',
  'sixStarParkPage.drive': 'Wprowadź promocje w sklepie dla użytkowników aplikacji i generuj ruch do swoich obiektów.',
  'sixStarParkPage.extraFeatures': 'Dodatkowe Funkcje',
  'sixStarParkPage.p1':
    'Six Star: Park oferuje wiele dodatkowych funkcji dotyczących czynników społecznych, ekonomicznych i organizacyjnych związanych z Twoim obszarem parkingowym. Dzięki możliwości uwzględnienia ładowarek do pojazdów elektrycznych, ekspresowych płatności, rezerwacji i płatności za pośrednictwem telefonu komórkowego, zwiększysz bezpośrednie przychody oraz zwiększysz ogólne doświadczenie gości.',
  'sixStarParkPage.preparing': 'Przygotowania do Przyszłości',
  'sixStarParkPage.p2':
    'Nasz hotelowy produkt parkingowy oferuje dwie zaawansowane rozwiązania ładowania pojazdów elektrycznych: TRONIQ 50, ładowarka o mocy 50 kW DC z uniwersalną kompatybilnością i zdalnym utrzymaniem, oraz DUAL BUSINESSLINE, ładowarka o mocy 7,4 kW AC, która jednocześnie ładować dwa samochody przy efektywnym rozdziale mocy poprzez równoważenie obciążenia. Te nowoczesne rozwiązania zwiększają ogólne doświadczenie gości, zapewniając szybkie, niezawodne i wygodne opcje ładowania pojazdów elektrycznych.',
  'sixStarBlackPage.header': 'Podnieś Globalne Podróże i Apartamenty Prezydenckie',
  'sixStarBlackPage.subHeader':
    "Marka 'Six Star: Black' oferuje dwie różne produkty dostosowane do osób o wysokim profilu i luksusowych obiektów, które stawiają potrzeby swoich gości na pierwszym miejscu.",
  'sixStarBlackPage.traveler': 'Podróżny',
  'sixStarBlackPage.hotel': 'Hotel',
  'sixStarBlackPage.aService':
    'Usługa dla lokatorów apartamentów najwyższej klasy w luksusowych hotelach, mająca na celu poprawę ogólnej jakości pobytu gości w tych ekskluzywnych miejscach.',
  'sixStarBlackPage.provides': 'Zapewnia wsparcie IT na najwyższym poziomie dla osób o wysokim profilu podczas podróży.',
  'sixStarBlackPage.p1':
    'Doświadcz elitarnej obsługi podróży dzięki Six Star: Black (Podróżny). Nasza globalna drużyna IT obsługuje osoby o wysokim profilu i ich rodziny, dostarczając płynnej, spersonalizowanej pomocy technicznej, gdziekolwiek ich przygody ich zaprowadzą – od wymiany sprzętu niskonapięciowego po dostawę wcześniej skonfigurowanego sprzętu bezpośrednio do ciebie.',
  'sixStarBlackPage.p2':
    'Zintegrowane z istniejącą ofertą hotelu, aby stworzyć płynne i podniesione doświadczenie dla gości. Oferujemy usługi na najwyższym poziomie, aby sprostać unikalnym i wyższym oczekiwaniom gości zajmujących apartamenty na poziomie prezydenckim i ambasadorskim, zapewniając naprawdę luksusowy pobyt.',

  'sixStarVIPPage.subHeader':
    'Premiumowe wsparcie bezpośrednie od starszych inżynierów oraz skrócone czasy realizacji dla Twojego zespołu wykonawczego.',
  'sixStarVIPPage.whenYouCall': 'Gdy dzwonisz lub tworzysz zgłoszenie, masz gwarancję obsługi przez jednego z naszych starszych inżynierów.',
  'sixStarVIPPage.onTheGo': 'Karty kontaktowe On-The-Go z bezpośrednim numerem do wsparcia.',
  'sixStarVIPPage.bigOrSmall': 'Niezależnie od tego, czy problem jest duży czy mały, możemy zagwarantować niższy czas realizacji.',
  'sixStarVIPPage.plus':
    'Six Star: VIP Plus, ostateczny poziom wsparcia zaprojektowany, aby podnieść Twoje doświadczenie do niespotykanych poziomów doskonałości. Dzięki VIP Plus zyskasz dostęp do bogactwa przywilejów, które definiują obsługę klienta na nowo.',
  'sixStarVIPPage.instant': 'Natychmiastowe Bezpośrednie Wsparcie',
  'sixStarVIPPage.dedicated': 'Dedykowany Numer Wsparcia',
  'sixStarVIPPage.priority': 'Priorytetowe Czasy Realizacji',
  'sixStarVIPPage.withPlus': 'z Six Star: VIP Plus',

  'sixStarInternetPage.subHeader':
    'Six Star: Internet łączy ekonomiczne obwody danych, kompleksowe połączenie mobilne i trumny SIP z darmowym zarządzaniem projektem oraz solidnym wsparciem.',
  'sixStarInternetPage.leverage':
    'Wykorzystujemy strategiczne partnerstwa, efektywną infrastrukturę i rozwiązania technologiczne, aby dostarczyć oszczędności kosztów naszym klientom.',
  'sixStarInternetPage.mobileEnhance': 'Połączenie mobilne zwiększa elastyczność i produktywność firm w coraz bardziej mobilnym świecie.',
  'sixStarInternetPage.inclusion': 'Włączenie usług zarządzania projektem oznacza gładkie i bezproblemowe doświadczenia wdrażania dla klientów.',
  'sixStarInternetPage.p1':
    'Six Star priorytetuje ekonomiczność, oferując obwody danych po najniższych możliwych stawkach. Ta funkcja ma na celu dostarczenie firmom przewagi konkurencyjnej poprzez minimalizację kosztów związanych z łącznością internetową. Oferując przystępne ceny obwodów danych, Six Star umożliwia firmom efektywniejsze alokowanie zasobów, sprzyjając wzrostowi i zrównoważonemu rozwojowi.',
  'sixStarInternetPage.p2':
    'Six Star nie tylko oferuje trumny SIP (Session Initiation Protocol) do efektywnej komunikacji głosowej, ale wyróżnia się także świadczeniem bezpłatnych usług zarządzania projektami. Obejmuje to fachowe wsparcie i pomoc przez cały proces wdrożenia. Po wdrożeniu usługi Six Star Service Desk będzie dostępny 24/7, aby udzielić pomocy w razie pytań lub problemów.',
  'sixStarInternetPage.projectManagement': 'Zarządzanie Projektami i Wsparcie',
  'sixStarInternetPage.maximize': 'Maksymalizacja Oszczędności z Obwodami Danych',

  'sixStarPrintPage.header': 'Bezproblemowe Zarządzane Druki dla Bezszwowej Produktywności',
  'sixStarPrintPage.subHeader': 'Inteligentne Druki, Brak CAPEX: Natychmiastowe Rozwiązania z Konkurencyjnymi Materiałami Eksploatacyjnymi',
  'sixStarPrintPage.printing': 'Druki bez Kapitału',
  'sixStarPrintPage.rapidResponse': 'Szybka Reakcja',
  'sixStarPrintPage.costEffectiveConsumables': 'Kosztowe Materiały Eksploatacyjne',
  'sixStarPrintPage.providing': 'Oferowanie rozwiązania zarządzanego drukowania bez kosztów początkowych.',
  'sixStarPrintPage.enjoy': 'Ciesz się wsparciem na miejscu tego samego dnia/następnego dnia, aby nieprzerwanie drukować.',
  'sixStarPrintPage.save': 'Oszczędzaj dzięki konkurencyjnym cenom na materiały eksploatacyjne do drukowania w Six Star Global.',
  'sixStarPrintPage.hassle': 'Druk bez Kłopotów',
  'sixStarPrintPage.unrivaled': 'Bezkonkurencyjna Gwarancja Czasu Pracy',
  'sixStarPrintPage.p1':
    'Ciesz się drukowaniem bez finansowego obciążenia. Rozwiązanie zarządzane drukiem Six Star Global uwalnia cię od początkowych kosztów, umożliwiając efektywne drukowanie bez konieczności inwestycji kapitałowej.',
  'sixStarPrintPage.p2':
    'Podnieś swoje doświadczenie związanego z drukowaniem z Six Star Global. Skorzystaj z szybkiego wsparcia na miejscu tego samego dnia/następnego dnia, zapewniając, że operacje drukowania przebiegają sprawnie bez zakłóceń.',

  'sixStarGuestPage.header': 'Specjalnie zaprojektowane dla lobby gości lub centrów biznesowych',
  'sixStarGuestPage.subHeader':
    'Podnieś doświadczenie gości i usprawnij swoje operacje biznesowe dzięki naszej nowoczesnej technologii dostępu dla gości.',
  'sixStarGuestPage.secure': 'Bezpieczne Środowisko',
  'sixStarGuestPage.fully': 'W pełni Licencjonowane',
  'sixStarGuestPage.custom': 'Niestandardowe Rozwiązania',
  'sixStarGuestPage.B2B': 'Wsparcie B2B i Frontowe Dla Gości',
  'sixStarGuestPage.p1':
    'Podnieś swoje centrum biznesowe dzięki naszym solidnym usługom wsparcia B2B i popraw doświadczenia gości dzięki niezawodnym i łatwym w użyciu publicznym stanowiskom roboczym. Oferujemy dostosowane rozwiązania IT dla klientów korporacyjnych, jednocześnie tworząc bezproblemowe środowisko dla gości, czy potrzebują dostępu do Internetu, drukowania dokumentów czy innych potrzeb komputerowych.',
  'sixSTarGuestPage.customised': 'Niestandardowe Rozwiązania dla Dowolnego Środowiska',
  'sixStarGuestPage.p2':
    'Zdając sobie sprawę, że każde miejsce noclegowe jest wyjątkowe, oferujemy rozwiązania dostosowane do spełnienia konkretnych wymagań. Od brandingu po konfiguracje oprogramowania dostosowujemy nasze usługi, dostosowując je do Twoich celów biznesowych, jednocześnie zapewniając wsparcie 24/7.',

  'sixStarPayPage.header': 'Podnieś Swoją Gościnność, Ułatw Transakcje',
  'sixStarPayPage.subHeader': 'Usprawnianie Doskonałości w Gościnności, Jedna Transakcja na Raz',
  'sixStarPayPage.flexible': 'Elastyczna Płatność',
  'sixStarPayPage.variousPayment': 'Wspieramy różne opcje płatności, goście mogą płacić tak, jak chcą.',
  'sixStarPayPage.roomCharging': 'Obciążanie Pobytu',
  'sixStarPayPage.orderRouting': 'Prosta trasa zamówień, goście mogą obciążać przedmioty/usługi na swoim pokoju.',
  'sixStarPayPage.payAtTable': 'Płacenie przy Stole',
  'sixStarPayPage.ourPay': 'Nasze rozwiązanie Pay-At-Table zapewnia synchronizację menu w czasie rzeczywistym na wszystkich platformach.',
  'sixStarPayPage.increasedEfficiency': 'Zwiększona Efektywność i Szczęście Gości',
  'sixStarPayPage.p1':
    'Oszczędzaj czas i zwiększaj efektywność dzięki naszym zsynchronizowanym funkcjom zarządzania menu i trasy zamówień. Skoncentruj się na dostarczaniu wyjątkowego doświadczenia gastronomicznego bez operacyjnych kłopotów. Zadowolony klient to powracający klient.',
  'sixStarPayPage.integration': 'ŝatwa Integracja i Konfiguracja',
  'sixStarPayPage.p2':
    'Bezproblemowa integracja z większością głównych systemów PMS i POS oznacza, że możesz nadal korzystać z preferowanych narzędzi, jednocześnie ciesząc się dodatkowymi korzyściami z Six Star: Pay.',
  'formLoader.BackButton': 'Wstecz',
  'formLoader.forwardButton': 'Dalej',
  'formLoader.submitButton': 'Wyślij',
  'formLoader.reSubmitButton': 'Ponownie wyślij',
  'dataTablePro.error': 'Błąd',
  'sixStarTV.design': 'Porozmawiajmy o Designie',
  'ticketViewPage.button.bundle': 'Paczka',
  'ticketViewPage.button.goBack': 'Wróć',
  'ticketViewPage.ticket.summary': 'Podsumowanie biletu: ',
  'ticketViewPage.formSummary': 'Podsumowanie formularza',
  'ticketViewPage.masterDetail.createdAt': 'Utworzone w:',
  'ticketViewPage.masterDetail.assignedMember': 'Przypisani członkowie',
  'ticketViewPage.masterDetail.ticketContacts': 'Kontakty związane z biletem',
  'ticketViewPage.masterDetail.ticketCreatedAt': 'Bilet utworzony w',
  'ticketViewPage.masterDetail.mostRecentActivity': 'Najnowsza aktywność',
  'ticketViewPage.masterDetail.ticketUpdatedBy': 'Bilet zaktualizowany przez',
  'ticketViewPage.masterDetail.viewTicket': 'Zobacz bilet',
  'ticketViewPage.masterDetail.quickNote': 'Szybka notatka',
  'ticketViewPage.carbonCopy': 'Kopie Węglowa',
  'ticketViewPage.masterDetail.noRecentActivity': 'Brak ostatniej aktywności',
  // "ticketViewPage.carbonCopyNotAvailable": "Brak kopii węglowej dostępnych dla tego zgłoszenia",
  "ticketViewPage.carbonCopyNotAvailable": "Brak kopii dołączonej do tego zgłoszenia",
  "TicketViewPage.toolbar.quickClose.model.subTitle": "Czy na pewno chcesz szybko rozwiązać {count} zgłoszeń?",
  "TicketViewPage.toolbar.quickClose.model.title": "Szybkie Rozwiązanie Zgłoszeń",
  "TicketViewPage.toolbar.label.quickClose": "Szybkie Rozwiązanie",
  "TicketViewPage.toolbar.quickClose.submitButton": "Szybkie Rozwiązanie",
  "TicketViewPage.toolbar.quickClose.cancelButton": "Anuluj",
  "TicketViewPage.toolbar.quickClose.model.reason": "Proszę podać krótki powód zamknięcia zgłoszenia, np. śmieci",
  "TicketViewPage.toolbar.quickClose.model.reasonPlaceholder": "Powód",
  "TicketViewPage.toolbar.quickClose.model.finished": "Zgłoszenia zostały szybko rozwiązane",
  "TicketViewPage.toolbar.quickClose.model.updating": "Aktualizowanie zgłoszeń",
  "TicketViewPage.toolbar.quickClose.model.error": "Błąd podczas aktualizowania zgłoszeń",
  "TicketViewPage.toolbar.quickClose.closeButton": "Zamknij",
  "menu.manage.helpSection.releaseNotes.tabText": "Notatki o Wersji",
  "menu.manage.helpSection.releaseNotes.menuText": "Notatki o Wersji",
  "releaseNotesPage.title": "Notatki o Wersji",
  "notFoundPage.pageDevelopment": "W trakcie opracowywania",
  "notFoundPage.pageDevelopmentMessage": "Przepraszamy, ta sekcja jest obecnie w trakcie opracowywania. Prosimy o sprawdzenie później, aby uzyskać interesujące aktualizacje od naszego zespołu technicznego",
  "notFoundPage.button.dashboard": "Panel",
  "notFoundPage.button.ticket": "Moje Zgłoszenia",
  "ticketViewPage.modal.bundle.childTicketField": "Wyszukaj Zgłoszenie Potomne",
  "TicketViewPage.toolbar.saveView": "Zapisz widok",
  "TicketViewPage.toolbar.saveViewAs": "Zapisz widok jako",
  "TicketViewPage.toolbar.saveViewAsDuplicate": "Zapisz widok jako duplikat",
  "TicketViewPage.toolbar.modal.areYouSure": "Czy na pewno?",
  "TicketViewPage.toolbar.modal.yes": "Tak",
  "TicketViewPage.toolbar.modal.no": "Nie",
  "ticketViewPage.childTickets": "Zgłoszenia Potomne",
  "ticketViewPage.parent": "Nadrzędne",
  "ticketViewPage.ticketSummary": "Podsumowanie zgłoszenia",
  "ticketViewPage.ticketNumber": "Numer zgłoszenia",
  "TicketViewPage.toolbar.label.filters": "Filtry",
  "TicketViewPage.toolbar.label.addContact": "Dodaj kontakt",
  "ticketViewPage.addNote": "Dodaj notatkę",
  "ticketViewPage.ticketUpdate": "Aktualizacja zgłoszenia",
  "ticketViewPage.infoRequest": "Prośba o informacje",
  "ticketViewPage.approval": "Zatwierdzenie",
  "ticketViewPage.requestForAction": "Prośba o działanie",
  "ticketViewPage.resolution": "Rozwiązanie",
  "ticketviewPage.tab.overview": "Przegląd",
  "ticketviewPage.tab.timeline": "Oś czasu",
  "ticketviewPage.tab.formSummary": "Podsumowanie formularza",
  "ticketviewPage.tab.notes": "Notatki",
  "ticketviewPage.tab.tasks": "Zadania",
  "ticketviewPage.tab.activity": "Aktywność",
  "ticketViewPage.createdAt": "Utworzono",
  "ticketViewPage.lastUpdate": "Ostatnia aktualizacja",
  "ticketViewPage.priority": "Priorytet",
  "ticketViewPage.ticketSLA": "SLA zgłoszenia",
  "ticketViewPage.requester": "Wnioskodawca",
  "ticketViewPage.ticketOwner": "Właściciel zgłoszenia",
  "ticketViewPage.ticketStatus": "Status zgłoszenia:",
  "ticketViewPage.ticketSummary": "Podsumowanie zgłoszenia",
  "ticketViewPage.ticketNumber": "Numer zgłoszenia: #",
  "ticketViewPage.podAccess": "Szybki dostęp",
  "ticketViewPage.podAccessDesc": "Ten pod zapewni szybki dostęp do różnych sekcji zgłoszenia",
  "ticketViewPage.addContact": "Dodaj kontakt",
  "ticketViewPage.editType": "Edytuj typ",
  "ticketViewPage.assigneeOrRequester": "Zaktualizuj przypisane/wnioskodawcę",
  "ticketViewPage.contacts": "Kontakty",
  "ticketViewPage.contactDecs": "Ten pod wyświetli wszystkie kontakty związane z tym zgłoszeniem",
  "menu.manage.internalSection.forms.tabText": "Formularze",
  "menu.manage.internalSection.forms.menuText": "Formularze",
  "ticketViewPage.toolbar.label.quickEdit": "Szybka edycja",
  "ticketViewPage.toolbar.editTicket.submitButton": "Wyślij",
  "datatableViews.toolbar.currentView": "Bieżący widok",
  "datatableViews.toolbar.loading": "Ładowanie...",
  "ticketviewPage.tab.overview": "Przegląd",
  "ticketviewPage.tab.timeline": "Oś czasu",
  "ticketviewPage.tab.notes": "Notatki",
  "ticketviewPage.tab.tasks": "Zadania",
  "ticketviewPage.tab.formSummary": "Podsumowanie formularza",
  "ticketviewPage.tab.activity": "Aktywność",
  "ticketViewPage.timeline.ticketCreated": "Zgłoszenie utworzone",
  "ticketViewPage.timeline.ticketUpdated": "Zgłoszenie zaktualizowane",
  "ticketViewPage.timeline.messageAdded": "Dodano wiadomość",
  "ticketViewPage.timeline.titleChange": "Zmiana tytułu",
  "ticketViewPage.organisations": "Organizacje",
  "ticketViewPage.ticketDevices": "Urządzenia zgłoszenia",
  "ticketViewPage.ticketCC": "Zgłoszenie CC",
  "menu.manage.companyContact.tabText": "Firma",
  "menu.manage.companyContact.menuText": "Firma",
  "ticketViewPage.rateYourService": "Oceń swoją usługę",
  "ticketViewPage.relatedTickets.desc": "Ten pod wyświetli wszystkie powiązane zgłoszenia",
  "ticketViewPage.ticketCC.desc": "Ten pod wyświetli wszystkie powiązane kopie węglowe",
  "ticketViewPage.ticketDevices.desc": "Ten pod wyświetli wszystkie powiązane urządzenia",
  "ticketViewPage.ticketSLA.desc": "Ten pod wyświetli SLA tego zgłoszenia",
  "ticketViewPage.documents.desc": "Ten pod wyświetli wszystkie dokumenty związane z tym zgłoszeniem",
  "ticketViewPage.organisations.desc": "Ten pod wyświetli wszystkie organizacje związane z tym zgłoszeniem",
  "ticketViewPage.initialResponse": "Początkowa odpowiedź",
  "ticketViewPage.nextUpdate": "Następna aktualizacja",
  "ticketViewPage.estimatedResolution": "Szacowane rozwiązanie",
  "ticketViewPage.resolution": "Rozwiązanie",
  "ticketViewPage.relatedTickets": "Powiązane Zgłoszenia",
  "ticketViewPage.bundleTicket": "Połącz zgłoszenia",
  "ticketViewPage.shareTicket": "Udostępnij zgłoszenie",
  "ticketViewPage.contact": "Kontakt",
  "ticketViewPage.email": "E-mail",
  "ticketViewPage.rateYourServiceComment": "Możesz ocenić swoją usługę w dowolnym momencie podczas sesji z Six Star Global.",
  "ticketViewPage.responseHistory": "Historia Odpowiedzi",
  "ticketViewPage.initialComment": "Pierwszy Komentarz",
  "ticketViewPage.agentResponse": "Odpowiedź Agenta",
  "ticketVIewPage.notClosed": "Nie Zamknięte",
  "ticketViewPage.created": "Utworzono",
  "ticketViewPage.firstResponse": "Pierwsza Odpowiedź",
  "ticketViewPage.estimatedResolution": "Szacowane Rozwiązanie",
  "ticketViewPage.actualResolution": "Rzeczywiste Rozwiązanie",
  "createOrg.title": "Utwórz Organizację",
  "ticketViewPage.addManager": "Dodaj Kierownika Konta",
  "editManager.addNew": "Dodaj Nowy",
  "editManager.currentManagers": "Aktualni Menedżerowie",
  "companyContactPage.tabs.overview.region": "Region",
  "ticketViewPage.ratings": "Oceny Usług",
  "ticketViewPage.ratingsdesc": "Ten pod pokazuje wszelkie oceny pozostawione dla tego biletu.",
  "menu.manage.userSection.createOrganization.tabText": "Utwórz Organizację",
  "menu.manage.userSection.createOrganization.menuText": "Utwórz Organizację",
  "companyPage.info": "Informacje o Firmie",
  "companyPage.AccountManager": "Kierownik Konta",
  "companyPage.location": "Lokalizacja",
  "companyPage.relatedOrganizations": "Powiązane Organizacje",
  "companyPage.parentOrganization": "Organizacja Nadrzędna",
  "companyPage.childOrganizations": "Organizacje Podrzędne",
  "companyPage.noParentOrganization": "Brak Organizacji Nadrzędnej",
  "companyPage.noChildOrganizations": "Brak Organizacji Podrzędnych",
  "ticketViewPage.infoDesc": "Ten pod wyświetli wszystkie informacje związane z tym biletem",
  "ticketViewPage.ticketInfo": "Informacje o Bilecie",
  "ticketViewPage.timeline.priorityChange": "Zmiana Priorytetu",
  "ticketViewPage.timeline.statusChange": "Zmiana Statusu",
  "ticketViewPage.timeline.ticketResolved": "Bilet Rozwiązany",
  'ticketViewPage.infoDesc': 'Ten moduł wyświetli wszystkie informacje związane z tym zgłoszeniem',
  'ticketViewPage.ticketInfo': 'Informacje o Zgłoszeniu',
  'ticketViewPage.timeline.priorityChange': 'Zmiana Priorytetu',
  'ticketViewPage.timeline.statusChange': 'Zmiana Statusu',
  'ticketViewPage.timeline.ticketResolved': 'Zgłoszenie Rozwiązane',
  'ticketViewPage.addSummary': 'Dodaj Podsumowanie',
  'ticketViewPage.notResponded': 'Brak Odpowiedzi',
  'ticketViewPage.noInitialComment': 'Brak Początkowego Komentarza',
  'ticketViewPage.noAgentResponse': 'Brak Odpowiedzi Agenta',
  'ticketViewPage.makePrivate': 'Ustaw na Prywatne',
  'ticketViewPage.makePublic': 'Ustaw na Publiczne',
  'ticketViewPage.pod.team': 'Zespół',
  'ticketViewPage.team.desc': 'Ten moduł wyświetli wszystkich członków zespołu powiązanych z tym zgłoszeniem'

};

export default pages;
