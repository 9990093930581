const menu = {
  "menu.manage.dashboard.tabText": "Dashboard",
  "menu.manage.dashboard.menuText": "Dashboard",
  "menu.manage.supportSection.text": "Support",
  "menu.manage.supportSection.menuText": "Support",
  "menu.manage.supportSection.dashboard.tabText": "Dashboard",
  "menu.manage.supportSection.dashboard.menuText": "Dashboard",
  "menu.manage.supportSection.newRequest.tabText": "Neue Anfrage",
  "menu.manage.supportSection.newRequest.menuText": "Neue Anfrage",
  "menu.manage.supportSection.myTicketsOverview.tabText":
    "Meine Ticketübersicht",
  "menu.manage.supportSection.myTicketsOverview.menuText":
    "Meine Ticketübersicht",
  "menu.manage.supportSection.approvals.tabText": "Genehmigungen",
  "menu.manage.supportSection.approvals.menuText": "Genehmigungen",
  "menu.manage.sixstarMeet.tabText": "Six Star Meet",
  "menu.manage.sixstarMeet.menuText": "Six Star Meet",
  "menu.manage.manageSixStarConnect.tabText": "Six Star Connect",
  "menu.manage.manageSixStarConnect.menuText": "Six Star Connect",
  "menu.manage.manageManageSection.text": "Verwalten",
  "menu.manage.manageManageSection.menuText": "Verwalten",
  "menu.manage.manageManageSection.dashboard.tabText": "Dashboard",
  "menu.manage.manageManageSection.dashboard.menuText": "Dashboard",
  "menu.manage.manageSection.text": "Verwalten",
  "menu.manage.manageSection.menuText": "Verwalten",
  "menu.manage.manageSection.documents.tabText": "Dokumente",
  "menu.manage.manageSection.documents.menuText": "Dokumente",
  "menu.manage.manageSection.securityReports.tabText": "Sicherheitsberichte",
  "menu.manage.manageSection.securityReports.menuText": "Sicherheitsberichte",
  "menu.manage.manageSection.serviceReports.tabText": "Serviceberichte",
  "menu.manage.manageSection.serviceReports.menuText": "Serviceberichte",
  "menu.manage.manageSection.licenceManagement.tabText": "Lizenzverwaltung",
  "menu.manage.manageSection.licenceManagement.menuText": "Lizenzverwaltung",
  "menu.manage.manageSection.assetManagement.tabText": "Asset-Verwaltung",
  "menu.manage.manageSection.assetManagement.menuText": "Asset-Verwaltung",
  "menu.manage.manageSection.upcomingExpiries.tabText": "Bevorstehende Abläufe",
  "menu.manage.manageSection.upcomingExpiries.menuText":
    "Bevorstehende Abläufe",
  "menu.manage.manageSection.bookACallWithYourAccountManager.tabText":
    "Anruf mit Ihrem Account-Manager buchen",
  "menu.manage.manageSection.bookACallWithYourAccountManager.menuText":
    "Anruf mit Ihrem Account-Manager buchen",
  "menu.manage.financeSection.text": "Finanzen",
  "menu.manage.financeSection.menuText": "Finanzen",
  "menu.manage.financeSection.dashboard.tabText": "Dashboard",
  "menu.manage.financeSection.dashboard.menuText": "Dashboard",
  "menu.manage.financeSection.ordersQuotesInvoices.tabText":
    "Bestellungen/Angebote/Rechnungen",
  "menu.manage.financeSection.ordersQuotesInvoices.menuText":
    "Bestellungen/Angebote/Rechnungen",
  "menu.manage.financeSection.creditApplication.tabText": "Kreditantrag",
  "menu.manage.financeSection.creditApplication.menuText": "Kreditantrag",
  "menu.manage.helpSection.text": "Hilfe",
  "menu.manage.helpSection.menuText": "Hilfe",
  "menu.manage.helpSection.contactUs.tabText": "Kontaktieren Sie uns",
  "menu.manage.helpSection.contactUs.menuText": "Kontaktieren Sie uns",
  "menu.manage.helpSection.giveFeedback.tabText": "Feedback geben",
  "menu.manage.helpSection.giveFeedback.menuText": "Feedback geben",
  "menu.manage.helpSection.guides.tabText": "Leitfäden",
  "menu.manage.helpSection.guides.menuText": "Leitfäden",
  "menu.manage.helpSection.customerForms.tabText": "Kundenformulare",
  "menu.manage.helpSection.customerForms.menuText": "Kundenformulare",
  "menu.manage.engineerSection.text": "Ingenieur",
  "menu.manage.engineerSection.menuText": "Ingenieur",
  "menu.manage.engineerSection.myTasks.tabText": "Meine Aufgaben",
  "menu.manage.engineerSection.myTasks.menuText": "Meine Aufgaben",
  "menu.manage.engineerSection.ticketBoard.tabText": "Ticket-Board",
  "menu.manage.engineerSection.ticketBoard.menuText": "Ticket-Board",
  "menu.manage.engineerSection.triageTickets.tabText": "Triage-Tickets",
  "menu.manage.engineerSection.triageTickets.menuText": "Triage-Tickets",
  "menu.manage.engineerSection.priorityTickets.tabText": "Priorität-Tickets",
  "menu.manage.engineerSection.priorityTickets.menuText": "Priorität-Tickets",
  "menu.manage.engineerSection.escalations.tabText": "Eskalationen",
  "menu.manage.engineerSection.escalations.menuText": "Eskalationen",
  "menu.manage.internalSection.text": "Intern",
  "menu.manage.internalSection.menuText": "Intern",
  "menu.manage.internalSection.orderTracker.tabText": "Auftragsverfolgung",
  "menu.manage.internalSection.orderTracker.menuText": "Auftragsverfolgung",
  "menu.manage.internalSection.resourceTracker.tabText": "Ressourcenverfolgung",
  "menu.manage.internalSection.resourceTracker.menuText":
    "Ressourcenverfolgung",
  "menu.manage.internalSection.knowledgeBase.tabText": "Wissensbasis",
  "menu.manage.internalSection.knowledgeBase.menuText": "Wissensbasis",
  "menu.manage.internalSection.twoFactor.tabText": "Zwei Faktor",
  "menu.manage.internalSection.twoFactor.menuText": "Zwei Faktor",
  "menu.manage.internalSection.internal.tabText": "Intern",
  "menu.manage.internalSection.internal.menuText": "Intern",
  "menu.manage.internalSection.expenses.tabText": "Ausgaben",
  "menu.manage.internalSection.expenses.menuText": "Ausgaben",
  "menu.manage.userSection.text": "Benutzer",
  "menu.manage.userSection.menuText": "Benutzer",
  "menu.manage.userSection.createContact.tabText": "Kontakt erstellen",
  "menu.manage.userSection.createContact.menuText": "Kontakt erstellen",
  "menu.manage.userSection.contactSearch.tabText": "Kontaktsuche",
  "menu.manage.userSection.contactSearch.menuText": "Kontaktsuche",
  "menu.manage.userSection.zoomPresence.tabText": "Zoom Präsenz",
  "menu.manage.userSection.zoomPresence.menuText": "Zoom Präsenz",
  "menu.manage.userSection.zoomCallQueues.tabText": "Zoom Anrufwarteschlangen",
  "menu.manage.userSection.zoomCallQueues.menuText": "Zoom Anrufwarteschlangen",
  "menu.manage.userSection.mergeContacts.tabText": "Kontakte zusammenführen",
  "menu.manage.userSection.mergeContacts.menuText": "Kontakte zusammenführen",
  "menu.manage.adminSection.text": "Admin",
  "menu.manage.adminSection.menuText": "Admin",
  "menu.manage.adminSection.dashboard.tabText": "Dashboard",
  "menu.manage.adminSection.dashboard.menuText": "Dashboard",
  "menu.manage.adminSection.impersonate.tabText": "Imitieren",
  "menu.manage.adminSection.impersonate.menuText": "Imitieren",
  "menu.manage.supportSection.newRequestDashboard.tabText": "Neue Anfrage",
  "menu.manage.supportSection.newRequestDashboard.menuText": "Neue Anfrage",
  "menu.manage.supportSection.newRequestForm.tabText.supportRequest":
    "Supportanfrage",
  "menu.manage.supportSection.newRequestForm.tabText.serviceRequest":
    "Dienstanfrage",
  "menu.manage.supportSection.newRequestForm.tabText.procurementRequest":
    "Beschaffungsanfrage",
  "menu.manage.supportSection.newRequestForm.tabText.internalRequest":
    "Interne Anfrage",
  "menu.manage.supportSection.newRequestForm.tabText.passwordReset":
    "Passwort zurücksetzen",
  "menu.manage.supportSection.newRequestForm.tabText.newStarter":
    "Neueinstellung / Rollenwechsel",
  "menu.manage.supportSection.newRequestForm.tabText.leaverRequest":
    "Austrittsanfrage",
  "menu.manage.supportSection.newRequestForm.tabText.engineerVisit":
    "Technikerbesuch",
  "menu.manage.supportSection.newRequestForm.tabText.developmentRequest":
    "Entwicklungsanfrage",
  "menu.manage.engineerSection.ticketViewPage.tabText": "Ticket# {id}",
  "menu.manage.engineerSection.ticketViewPage.menuText": "Ticket {id}",
  "menu.manage.engineerSection.ticketsBoard.tabText": "Ticket-Board",
  "menu.manage.engineerSection.ticketsBoard.menuText": "Ticket-Board",
  "menu.manage.supportSection.newRequestForm.tabText": "{id}",
  "menu.manage.supportSection.newRequestForm.menuText": "{id}",
  "menu.manage.engineerSection.drive.tabText": "Six Star Drive",
  "menu.manage.engineerSection.drive.menuText": "Six Star Drive",
  "menu.manage.engineerSection.reports.tabText": "Berichte",
  "menu.manage.engineerSection.reports.menuText": "Berichte",
  "menu.manage.engineerSection.companyProfile.tabText": "Meine Organisation",
  "menu.manage.engineerSection.companyProfile.menuText": "Meine Organisation",
  "menu.manage.engineerSection.chatGPT.tabText": "Chat GPT",
  "menu.manage.engineerSection.chatGPT.menuText": "Chat GPT",
  "menu.manage.financeSection.Invoices.tabText": "Rechnungen",
  "menu.manage.financeSection.Invoices.menuText": "Rechnungen",
  "menu.manage.financeSection.quotesProposals.tabText": "Angebote",
  "menu.manage.financeSection.quotesProposals.menuText": "Angebote",
  "menu.manage.financeSection.creditTerms.tabText": "Kreditbedingungen",
  "menu.manage.financeSection.creditTerms.menuText": "Kreditbedingungen",
  "menu.manage.internalSection.impersonate.tabText": "Imitieren",
  "menu.manage.internalSection.impersonate.menuText": "Imitieren",
  "menu.manage.managedServicesSection.text": "Gemanagte Dienstleistungen",
  "menu.manage.managedServicesSection.menuText": "Gemanagte Dienstleistungen",
  "menu.manage.managedServicesSection.dashboard.tabText": "Dashboard",
  "menu.manage.managedServicesSection.dashboard.menuText": "Dashboard",
  "menu.manage.managedServicesSection.serviceOverview.tabText":
    "Serviceübersicht",
  "menu.manage.managedServicesSection.serviceOverview.menuText":
    "Serviceübersicht",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.tabText":
    "Endpoint-Sicherheit",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.menuText":
    "Endpoint-Sicherheit",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.tabText":
    "Backup",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.menuText":
    "Backup",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.tabText":
    "Sichere Formulare",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.menuText":
    "Sichere Formulare",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.tabText":
    "Geplante Vor-Ort-Besuche",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.menuText":
    "Geplante Vor-Ort-Besuche",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.tabText":
    "Elektroschrott-Entsorgung",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.menuText":
    "Elektroschrott-Entsorgung",
  "menu.manage.sixStarSecureSection.text": "Six Star Secure",
  "menu.manage.sixStarSecureSection.menuText": "Six Star Secure",
  "menu.manage.sixStarSecureSection.dashboard.tabText": "Armaturenbrett",
  "menu.manage.sixStarSecureSection.dashboard.menuText": "Armaturenbrett",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.tabText":
    "Endpoint-Schutz (Antivirus & Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.menuText":
    "Endpoint-Schutz (Antivirus & Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.tabText":
    "Endpoint-Erkennung & Reaktion",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.menuText":
    "Endpoint-Erkennung & Reaktion",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.tabText":
    "Firewall-Management",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.menuText":
    "Firewall-Management",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.tabText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.menuText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.tabText":
    "Geräteverschlüsselung",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.menuText":
    "Geräteverschlüsselung",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.tabText":
    "Office 365/Azure Management",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.menuText":
    "Office 365/Azure Management",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.tabText":
    "Backup & Katastrophenwiederherstellung",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.menuText":
    "Backup & Katastrophenwiederherstellung",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.tabText":
    "Sicherheitsberichte",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.menuText":
    "Sicherheitsberichte",
  "menu.manage.softwareLicenceManagementSection.text":
    "Softwarelizenzmanagement",
  "menu.manage.softwareLicenceManagementSection.menuText":
    "Softwarelizenzmanagement",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.tabText":
    "Autorisierter Softwarekatalog",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.menuText":
    "Autorisierter Softwarekatalog",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.tabText":
    "Lizenzmanagement",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.menuText":
    "Lizenzmanagement",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.tabText":
    "Softwaremanagement",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.menuText":
    "Softwaremanagement",
  "menu.manage.assetManagementSection.text": "Anlagenverwaltung",
  "menu.manage.assetManagementSection.menuText": "Anlagenverwaltung",
  "menu.manage.assetManagementSection.assetManagementDashboard.tabText":
    "Armaturenbrett",
  "menu.manage.assetManagementSection.assetManagementDashboard.menuText":
    "Armaturenbrett",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.tabText":
    "Autorisierter Hardwarekatalog",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.menuText":
    "Autorisierter Hardwarekatalog",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.tabText":
    "Gerätemanagement",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.menuText":
    "Gerätemanagement",
  "menu.manage.sixStarAdditionsSection.text": "Six Star-Zusätze",
  "menu.manage.sixStarAdditionsSection.menuText": "Six Star-Zusätze",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.tabText":
    "Gästegeräte-Support",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.menuText":
    "Gästegeräte-Support",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.tabText":
    "Six Star: Stimme",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.menuText":
    "Six Star: Stimme",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.tabText":
    "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.menuText":
    "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.tabText":
    "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.menuText":
    "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.tabText":
    "Six Star: Treffen",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.menuText":
    "Six Star: Treffen",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.tabText":
    "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.menuText":
    "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.tabText":
    "Six Star: Cast",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.menuText":
    "Six Star: Cast",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.tabText":
    "Six Star: Zahlen",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.menuText":
    "Six Star: Zahlen",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.tabText":
    "Six Star: Anzeige",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.menuText":
    "Six Star: Anzeige",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.tabText":
    "Six Star: Park",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.menuText":
    "Six Star: Park",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.tabText":
    "Six Star: Schwarz",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.menuText":
    "Six Star: Schwarz",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.tabText":
    "Six Star: Druck",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.menuText":
    "Six Star: Druck",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.tabText":
    "Six Star: Internet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.menuText":
    "Six Star: Internet",
    "menu.manage.userSection.editContact.tabText": "Kontakt bearbeiten",
"menu.manage.userSection.editContact.menuText": "Kontakt bearbeiten",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.tabText": "Lohnabrechnungen",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.menuText": "Lohnabrechnungen",
};
export default menu;
