const formLogicChecker = ({ conditions, formValues }) => {
  if (!conditions) return true
  if (!conditions.conditions) return true

  let conditionsMet = 0

  conditions.conditions.map((condition) => {
    const { name, value, operator } = condition

    let conditionTestValue = formValues[name] ?? undefined

    if (typeof formValues[name] === 'object') {
      if (operator === 'equals') {
        if (conditionTestValue.value === value) {
          conditionsMet++
        }
      }
      if (operator === 'notequals') {
        if (conditionTestValue.value !== value) {
          conditionsMet++
        }
      }
    } else {
      if (operator === 'equals') {
        if (conditionTestValue === value) {
          conditionsMet++
        }
      }
      if (operator === 'notequals') {
        if (conditionTestValue !== value) {
          conditionsMet++
        }
      }
    }
  })

  if (conditions.action === 'show') {
    if (conditions.conditional === 'any') {
      if (conditionsMet > 0) {
        return true
      }
      else {
        return false
      }
    }
    else {
      if (conditionsMet === conditions.conditions.length) {
        return true
      }
      else {
        return false
      }
    }
  }

  if (conditions.action === 'hide') {
    if (conditions.conditional === 'any') {
      if (conditionsMet > 0) {
        return false
      }
      else {
        return true
      }
    } else {
      if (conditionsMet === conditions.conditions.length) {
        return false
      }
      else {
        return true
      }
    }
  }
}

export { formLogicChecker }