const misc = {
  'tooltip.disabled': 'Disabilitato',
  'languageSelector.english': 'Inglese',
  'languageSelector.dutch': 'Olandese',
  'languageSelector.german': 'Tedesco',
  'languageSelector.french': 'Francese',
  'languageSelector.spanish': 'Spagnolo',
  'languageSelector.polish': 'Polacco',
  'languageSelector.italian': 'Italiano',
  'languageSelector.thai': 'Tailandese',
  'datatable.toolbar.quickFilterPlaceholder': 'Cerca...',
  'datatable.toolbar.columns': 'Colonne',
  'datatable.toolbar.density': 'Densità',
  'datatable.toolbar.densityComfortable': 'Confortevole',
  'datatable.toolbar.densityCompact': 'Compatto',
  'datatable.toolbar.densityStandard': 'Standard',
  'datatable.toolbar.export': 'Esporta',
  'datatable.toolbar.filters': 'Filtri',
  'socket.connected': 'Connesso',
  'socket.disconnected': 'Disconnesso',
  'profile.menu.viewProfile': 'Visualizza Profilo',
  'profile.menu.editProfile': 'Modifica Profilo',
  'profile.menu.help': 'Aiuto',
  'profile.menu.signOut': 'Esci',
  'addon.add': 'Aggiungi',
  'addon.modal.save': 'Salva',
  'addon.edit': 'Modifica',
  'notFoundPage.pageDevelopment': 'Pagina in Sviluppo',
  'notFoundPage.pageDevelopmentMessage':
    'Grazie per aver esplorato il nostro sito web. Questa pagina particolare è ancora in fase di lavorazione poiché siamo attualmente nella nostra fase beta. Apprezziamo la vostra pazienza e comprensione.',
  'notFoundPage.pageDevelopmentMessage2':
    'Si prega di notare che il nostro sito è in fase di aggiornamenti e miglioramenti regolari per offrirvi la migliore esperienza possibile. Torna presto per nuovi contenuti e funzionalità!',
  general: 'Generale',
  'ticket.role.first_responder': 'Primo Interventista',
  'ticket.role.first_responder.tooltip':
    'Primo Interventista: Punto iniziale di contatto per problemi IT ad alta priorità, responsabile della rapida risoluzione e del minimo tempo di inattività.',
  'ticket.role.lead_engineer': 'Ingegnere Capo',
  'ticket.role.lead_engineer.tooltip':
    'Ingegnere Capo: Responsabile della direzione tecnica complessiva del progetto e per garantire che il progetto sia completato in tempo e nel rispetto del budget.',
  'ticket.status_id': 'Stato',
  'ticket.priority_id': 'Priorità',
  'ticket.closed_at': 'Chiuso il',
  'ticket.subject': 'Oggetto',
  'team.team': 'Squadra',
  'ticket.created_at': 'Creato il',
  'ticket.updated_at': 'Aggiornato il',
  'ticket.requester_id': 'Richiedente',
  'ticket.organization_id': 'Organizzazione',
  'ticket.assignee_id': 'Assegnatario',
  'ticket.author_id': 'Autore',
  'ticket.public': 'Pubblico',
  'ticket.alias': 'ID',
  'ticket.status': 'Stato',
  'ticket.priority': 'Priorità',
  'ticket.team_id': 'Squadra',
  'ticket.assignee': 'Assegnatario',
  'ticket.requester': 'Richiedente',
  'ticket.has_incidents': 'Ha Incidenti',
  'ticket.updated_by_id': 'Aggiornato Da',
  'ticket.author': 'Autore',
  'person.assignee': 'Assegnatario',
  'person.requester': 'Richiedente',
  'ticket_status.status': 'Stato',
  'ticket_priority.priority': 'Priorità',
  'organization.organization': 'Azienda',
  'ticket_priority.priority.normal': 'Normale',
  'ticket_priority.priority.high': 'Alto',
  'ticket_priority.priority.low': 'Basso',
  'ticket_status.status.new': 'Nuovo',
  'ticket_status.status.pending': 'In Attesa',
  'ticket_status.status.hold': 'In Sospeso',
  'ticket_status.status.open': 'Aperto',
  'ticket_status.status.resolved': 'Risolto',
  'person.name': 'Nome',
  'person.avatar': 'Avatar',
  'person.email_address': 'Email',
  'person.active': 'Attivo',
  'person.alias': 'ID',
  'organization.name': 'Nome',
  'organization.active': 'Attivo',
  'organization.alias': 'ID',
  'board.board': 'Tabellone',

  // new translations
  'dateTime.utc': 'UTC',
  'ticket.devices.RMM.install': 'Installa Six Star RMM',
  'ticket.devices.RMM.login': 'Accedi a Six Star RMM',
  'ticket.devices.RMM.title': 'Per visualizzare le ultime informazioni',

  // Filtri della tabella dati
  'dataTable.filters.reset': 'Reimposta',
  'dataTable.filters.loading': 'Caricamento...',
  'dataTable.filters.header': 'Filtri',

  // Anteprima della tabella dati
  'dataTable.preview.viewTicket': 'Visualizza ticket',
  'dataTable.preview.quickNotes': 'Note rapide',
  'dataTable.preview.contacts': 'Contatti',
  'dataTable.preview.followers': 'Seguaci',
  'dataTable.preview.relatedTickets': 'Ticket correlati',
  'dataTable.preview.updateIn': 'Aggiornamento in',
  'dataTable.preview.devices': 'Dispositivi',
  'dataTable.preview.engineer': 'Ingegnere',
  'dataTable.preview.ticketCreated': 'Ticket creato:',

  // ChatGPT
  'chatgpt.somethingWentWrong': 'Qualcosa è andato storto, riprova',
  'chatgpt.history': 'Cronologia',

  // Pulsante del gruppo personalizzato
  'customGroupButton.one.expandAll': 'Espandi tutto',
  'customGroupButton.two.collapseAll': 'Comprimi tutto',

  // Numeri di bandiera
  'flagNumber.button.callUs': 'Chiamaci',

  'HelpOptions.Title': 'Come possiamo aiutarti?',
  'FloatingBeta.ReportIssue': 'Segnala un problema',
  'FloatingBeta.Feedback': 'Invia feedback',
  'dateTime.utc': 'UTC',
  'ticket.devices.RMM.install': 'Installa Six Star RMM',
  'ticket.devices.RMM.login': 'Accedi a Six Star RMM',
  'ticket.devices.RMM.title': 'Per visualizzare le ultime informazioni',
  'dataTable.filters.reset': 'Reimposta',
  'dataTable.filters.loading': 'Caricamento...',
  'dataTable.filters.header': 'Filtri',
  'dataTable.preview.viewTicket': 'Visualizza il ticket',
  'dataTable.preview.quickNotes': 'Note rapide',
  'dataTable.preview.contacts': 'Contatti',
  'dataTable.preview.followers': 'Seguaci',
  'dataTable.preview.relatedTickets': 'Ticket correlati',
  'dataTable.preview.updateIn': 'Aggiorna in',
  'dataTable.preview.devices': 'Dispositivi',
  'dataTable.preview.engineer': 'Ingegnere',
  'dataTable.preview.ticketCreated': 'Ticket creato: ',
  'chatgpt.somethingWentWrong': 'Qualcosa è andato storto. Per favore, riprova.',
  'chatgpt.history': 'Cronologia',
  'customGroupButton.one.expandAll': 'Espandi tutto',
  'customGroupButton.two.collapseAll': 'Comprimi tutto',
  'flagNumber.button.callUs': 'Chiamaci',
  'profile.menu.viewCompany': "Visualizza l'azienda",
  'contactUsModals.addWidgetModal.title': 'Come aggiungere un widget al tuo cruscotto',
  'contactUsModals.addWidgetModal.step1': '1. Vai al tuo cruscotto',
  'contactUsModals.addWidgetModal.step2': '2. Trova il nastro viola in alto sullo schermo',
  'contactUsModals.addWidgetModal.step3': '3. Fai clic sul pulsante Aggiungi widget',
  'contactUsModals.addWidgetModal.step4': '4. Seleziona un widget dalla lista',
  'contactUsModals.addWidgetModal.step5': '5. Fai clic sul pulsante Aggiungi',
  'contactUsModals.signOutModal.title': 'Come disconnettersi',
  'contactUsModals.signOutModal.step1': '1. Trova il tuo avatar in alto a destra dello schermo',
  'contactUsModals.signOutModal.step2': '2. Fai clic sul tuo avatar',
  'contactUsModals.signOutModal.step3': '3. Trova il pulsante di disconnessione nel menu a discesa',
  'contactUsModals.signOutModal.step4': '4. Fai clic sul pulsante di disconnessione',
  'ticketViewPage.toolbar.label': 'Cerca biglietti',
  'ticketViewPage.toolbar.view': 'Vista',
  'ticketViewPage.toolbar.saveView': 'Salva vista',
  'ticketViewPage.toolbar.saveViewPrompt': 'Inserisci il nome con cui desideri salvare questa vista.',
  'ticketViewPage.toolbar.label.export': 'Esporta',
  'ticketViewPage.toolbar.label.filters': 'Filtri',
  'formLoader.submissionSuccess': "Grazie per l'invio del modulo",
  'formLoader.submissionError': "Si è verificato un errore durante l'invio del modulo",
  'reportIssue.submit': 'Invia',
  'reportIssue.issue': 'Problema...',
  'ticketViewPage.modal.button.bunle': 'Raggruppa',
  'ticketViewPage.modal.button.back': 'Indietro',
  'flagNumber.button.callUs': 'Chiamaci',
  'ticketDocuments.notFound': 'Nessun documento trovato',
  'ticketViewPage.childTicketsNone': 'Nessun ticket figlio',
  'ticketViewPage.parentNone': 'Nessun ticket principale',
  'ticketViewPage.restricted': 'Ristretto',
};
export default misc;
