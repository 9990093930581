import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeCheckLoginState } from "slices/user";

export function LogoutListener() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("storage", myFunction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myFunction = () => {
    dispatch(makeCheckLoginState());
  };

  return <></>;
}

export default LogoutListener;
