import React, { useEffect, useState, useContext } from 'react';
import {
    GridFilterInputValue, GridFilterInputSingleSelect, GridFilterInputMultipleSingleSelect
} from '@mui/x-data-grid-pro';
import * as demo from '@mui/x-data-grid-pro';
import { TextField, FormControl, InputLabel, MenuItem, Select, Checkbox, ListItemText, Typography, LinearProgress, ListSubheader, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateTime } from 'luxon';
import { useQuery } from '@apollo/client';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { VariableSizeList } from 'react-window';
import Chip from '@mui/material/Chip';
import { SiteData } from 'context/siteData';
import convertTeams from './utils/convertTeams';
import { gql } from '@apollo/client';
import { People } from 'context/people';
import { useSelector } from 'react-redux';
import user from 'slices/user';
import { Org } from 'context/orgs';

const DataWrapper = ({ query, queryName, grouped, children }) => {
    const { data } = useQuery(query);

    if (data === undefined) {
        return (
            <>

                <TextField
                    value={'Loading...'}
                    variant='standard'
                    sx={{
                        marginTop: '16px'
                    }}
                    disabled
                />
                <LinearProgress
                    sx={{
                        position: 'relative',
                        height: '1px',
                        marginTop: '-1px'
                    }}
                />
            </>
        )
    }


    let returnData = [];

    if (grouped) {
        // group by organization
        const groupedData = data[queryName].reduce((acc, item) => {
            const organization = item.organization.name;
            if (!acc[organization]) {
                acc[organization] = [];
            }
            acc[organization].push(item);
            return acc;
        }, {});

        returnData = Object.keys(groupedData).map((key) => {
            return {
                label: key,
                children: groupedData[key].map((item) => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            }
        })
    } else {
        returnData = data[queryName].map((item) => {
            return {
                label: item.name,
                value: item.id
            }
        })
    }

    return children(returnData);
}
const LISTBOX_PADDING = 8

function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Tooltip title={dataSet[1]?.email_address ?? ''}>
            <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
                {dataSet[1].name}
            </Typography>
        </Tooltip>
    );
}

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {

    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    // const theme = useTheme();
    // const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    //     noSsr: true,
    // });
    const itemCount = itemData.length;
    // const itemSize = smUp ? 36 : 48;
    const itemSize = 36

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };


    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    variant="standard"
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const AutocompleteVirtualizedFilterInputComponent = React.memo(({ item, applyValue, data }) => {
    const [value, setValue] = useState([]);

    return (
        <Autocomplete
            value={value}
            size='small'
            options={data}
            renderInput={(params) => <TextField {...params} label="" variant="standard" sx={{ marginTop: '19px' }} />}
            renderOption={(props, option, state) => [props, option, state.index]}
            disableListWrap
            ListboxComponent={ListboxComponent}
            renderGroup={(params) => params}
            groupBy={(option) => option?.organization_name ?? 'NO NAME'}
            getOptionLabel={option => option.name + ' - ' + option.organization_name + ' - ' + option.email_address}
            renderValue={(selected) => selected.name}
            renderTags={(value, getTagProps) => value.map((option, index) => (
                <Tooltip title={option.email_address} key={index}>
                    <Chip label={option.name} size={'small'} />
                </Tooltip>
            ))}
            // Change the return value to just an array of the ids
            onChange={(event, value) => {
                setValue(value);
                let ids = value.map(item => item.id.toString());
                applyValue({ ...item, value: ids })
            }}
            multiple={true}
        />

    )
});

function TimeRangeFilterInputComponent({ item, applyValue }) {
    const [value, setValue] = useState([null, null]);
    const handleChange = (event) => {
        // console.log(event)
        if (event[0] !== null && event[1] !== null) {
            applyValue({ ...item, value: [event[0].toISO(), event[1].toISO()] });
        }
    }

    React.useEffect(() => {
        if (Array.isArray(item?.value) && item?.value.length === 2) {
            setValue([DateTime.fromISO(item?.value[0]) ?? null, DateTime.fromISO(item?.value[1]) ?? null]);
        }
    }, [item?.value]);

    // if (Array.isArray(item?.value) && item?.value.length === 2) {
    //     value = [DateTime.fromISO(item?.value[0]) ?? null, DateTime.fromISO(item?.value[1]) ?? null]
    // }

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocal='en-uk'>
            <DateRangePicker
                variant="standard"
                value={value}
                onChange={(date) => handleChange(date)}
                label="Date Range"
                fullWidth
                size='small'
                slotProps={{ textField: { size: 'small', variant: 'standard', sx: { margin: 0 } } }}
                sx={{
                    marginTop: '3px',
                    gap: '1px',
                    '& .MuiTypography-root': {
                        margin: '10px'
                    },
                    '& .MuiFormControl-root': {
                        margin: 0
                    },
                    '& .MuiInputBase-input': {
                        textAlign: 'center'
                    }

                }}
            />
        </LocalizationProvider>
    );
}

function TimeFilterInputComponent({ item, applyValue }) {
    const handleChange = (event) => {
        applyValue({ ...item, value: event.toISO() });
    }

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocal='en-uk'>
            <DateTimePicker
                value={DateTime.fromISO(item?.value) ?? ''}
                onChange={(date) => handleChange(date)}
                label="Date Time"
                fullWidth
                size='small'
                variant="standard"
                slotProps={{ textField: { size: 'small', variant: 'standard' } }}
                sx={{
                    marginTop: '3px !important',
                    marginRight: '10px'
                }}
            />
        </LocalizationProvider>
    );
}
// Numeric Filter Input Component
function NumericFilterInputComponent({ item, applyValue }) {
    const handleChange = (event) => {
        const value = event.target.value;
        if (value === '' || !isNaN(Number(value))) {
            applyValue({ ...item, value: value !== '' ? Number(value) : '' });
        }
    };

    return (
        <TextField
            type="number"
            value={item.value || ''}
            onChange={handleChange}
            variant="standard"
            label="Number"
            fullWidth
            size='small'
            sx={{
                marginTop: '3px'
            }}
            InputProps={{ inputProps: { min: 0 } }}
        />
    );
}

// String Filter Input Component for _ilike
function StringFilterInputComponent({ item, applyValue }) {
    const handleChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };

    return (
        <TextField
            value={item.value || ''}
            onChange={handleChange}
            variant="standard"
            fullWidth
            size='small'
            sx={{
                marginTop: '19px'
            }}
        />
    );
}

function ArrayFilterInputQueryComponent({ item, applyValue }) {
    return (
        <TextField />
    )
}

// Array Filter Input Component for _in
function ArrayFilterInputComponent({ item, applyValue, query }) {
    const [value, setValue] = useState(item.value || '');

    const handleChange = (event) => {
        const val = event.target.value;
        setValue(val);
        applyValue({ ...item, value: val.split(',').map(v => v.trim()) });
    };

    return (
        <TextField
            value={value}
            onChange={handleChange}
            variant="standard"
            label="In"
            fullWidth
            size='small'
            helperText="Comma-separated values"
        />
    );
}

// Array Filter Input Component for _in
const ArrayFilterDropDownComponent = React.memo(({ item, applyValue, options, grouped }) => {

    useEffect(() => {
        console.log(item, applyValue, options, grouped)
    }, [])

    const [checkedItems, setCheckedItems] = useState(item.value || []);
    const [open, setOpen] = useState(false);

    const handleChange = (event) => {
        const value = event.target.value;
        setCheckedItems(value);
        applyValue({ ...item, value });
    };

    const renderValue = (selected) => {
        if (!selected.length) {
            return 'Select';
        }
        return selected.map((value) => {
            const option = options.flatMap(group => group.children || [group]).find((opt) => opt.value === value);
            return option ? option?.label?.charAt(0)?.toUpperCase() + option?.label?.slice(1) ?? '' : value;
        }).join(', ');
    };

    const handleGroupClick = (group) => {
        const groupValues = group.children.map(child => child.value);
        const allChecked = groupValues.every(value => checkedItems.includes(value));

        const newCheckedItems = allChecked
            ? checkedItems.filter(value => !groupValues.includes(value))
            : [...new Set([...checkedItems, ...groupValues])];

        setCheckedItems(newCheckedItems);
        applyValue({ ...item, value: newCheckedItems });
    };

    const handleItemClick = (value) => {
        const newCheckedItems = checkedItems.includes(value)
            ? checkedItems.filter(item => item !== value)
            : [...checkedItems, value];

        setCheckedItems(newCheckedItems);
        applyValue({ ...item, value: newCheckedItems });
    };

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="select-label">Select</InputLabel>
            <Select
                labelId="select-label"
                multiple
                value={checkedItems}
                onChange={handleChange}
                renderValue={renderValue}
                label="Select"
                sx={{
                    maxHeight: 300,
                }}
                open={open}
                onOpen={() => setOpen(true)}
            >
                {!grouped && options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <ListItemText primary={option?.label?.charAt(0)?.toUpperCase() + option?.label?.slice(1) ?? ''} />
                        <Checkbox
                            checked={checkedItems.includes(option.value)}
                            size='small'
                            sx={{
                                padding: 0,
                                marginRight: 1,
                                border: 'none'
                            }}
                        />
                    </MenuItem>
                ))}
                {grouped && options.map((group) => (
                    <div key={group.label}>
                        <MenuItem onClick={() => handleGroupClick(group)}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#2a203b'
                                }}
                            >
                                {group.label}
                            </Typography>
                        </MenuItem>
                        {group.children.map((option) => (
                            <MenuItem key={option.value} value={option.value} onClick={() => handleItemClick(option.value)}>
                                <ListItemText primary={option?.label?.charAt(0)?.toUpperCase() + option?.label?.slice(1) ?? ''} />
                                <Checkbox
                                    checked={checkedItems.includes(option.value)}
                                    size='small'
                                    sx={{
                                        padding: 0,
                                        marginRight: 1,
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </div>
                ))}
                {!options.length && <MenuItem disabled>No options</MenuItem>}
            </Select >
        </FormControl>
    );
});

function IsNullFilterInputComponent({ item, applyValue }) {
    const handleChange = (event) => {
        const value = event.target.value;
        applyValue({ ...item, value: value === 'true' });
    };

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel>Is Null</InputLabel>
            <Select
                value={item.value === true ? 'true' : 'false'}
                onChange={handleChange}
                label="Is Null"
            >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
            </Select>
        </FormControl>
    );
}

const _eq = [
    {
        label: 'Equals',
        value: '_eq',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: GridFilterInputValue,
    },
];

const _eq_number = [
    {
        label: 'Equals Number',
        value: '_eq',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === Number(filterItem.value);
            };
        },
        InputComponent: NumericFilterInputComponent,
    },
];

const _in = [
    {
        label: 'In',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value || !Array.isArray(filterItem.value)) {
                return null;
            }
            return ({ value }) => {
                return filterItem.value.includes(value);
            };
        },
        InputComponent: ArrayFilterInputComponent,
    },
];

const _in_dropdown = (options, grouped = false) => [
    {
        label: 'In',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value) {
                return null;
            }
            return ({ value }) => {
                return filterItem.value.includes(value);
            };
        },
        InputComponent: React.memo(({ item, applyValue }) => <ArrayFilterDropDownComponent item={item} applyValue={applyValue} options={options} grouped={grouped} />),
    },
];

const _ilike = [
    {
        label: 'Contains',
        value: '_ilike',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value) {
                return null;
            }
            const filterValue = filterItem.value.toLowerCase();
            return ({ value }) => {
                return value.toLowerCase().includes(filterValue);
            };
        },
        InputComponent: StringFilterInputComponent,
    },
];

const _is_null = [
    {
        label: 'Is Null',
        value: '_is_null',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return filterItem.value ? value == null : value != null;
            };
        },
        InputComponent: IsNullFilterInputComponent,
    },
];

const _iregex = [
    {
        label: 'Contains',
        value: '_iregex',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value) {
                return null;
            }
            const filterValue = new RegExp(filterItem.value, 'i');
            return ({ value }) => {
                return filterValue.test(value);
            };
        },
        InputComponent: StringFilterInputComponent,
    },
];

const _niregex = [
    {
        label: 'Does Not Contain',
        value: '_niregex',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value) {
                return null;
            }
            const filterValue = new RegExp(filterItem.value, 'i');
            return ({ value }) => {
                return !filterValue.test(value);
            };
        },
        InputComponent: StringFilterInputComponent,
    },
];

const _gte = [
    {
        label: 'Greater Than or Equal',
        value: '_gte',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return filterItem.value;
            };
        },
        InputComponent: TimeFilterInputComponent,
    },
];

const _lte = [
    {
        label: 'Less Than or Equal',
        value: '_lte',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return filterItem.value;
            };
        },
        InputComponent: TimeFilterInputComponent,
    },
];

const _gte_lte = [
    {
        label: 'In Range',
        value: {
            logicalOperator: null,
            0: '_gte',
            1: '_lte',
            fallback: '_lte',
        },
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return filterItem.value;
            };
        },
        InputComponent: TimeRangeFilterInputComponent,
    },
];

// const _in_query = ({ query, queryName, grouped = false }) => [
//     {
//         label: 'In Query',
//         value: '_in',
//         getApplyFilterFn: (filterItem) => {
//             if (!filterItem.columnField || !filterItem.value || !Array.isArray(filterItem.value)) {
//                 return null;
//             }
//             return ({ value }) => {
//                 return filterItem.value.includes(value);
//             };
//         },
//         InputComponent: ({ item, applyValue }) => <DataWrapper query={query} queryName={queryName} grouped={grouped}>{(data) => <ArrayFilterDropDownComponent item={item} applyValue={applyValue} options={data || []} grouped={grouped} />}</DataWrapper>,
//     },
// ];

const _in_autocomplete = ({ data, grouped = false, identifier, group_identifier }) => [
    {
        label: 'In',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value || !Array.isArray(filterItem.value)) {
                return null;
            }
            return ({ value }) => {
                return filterItem.value.includes(value);
            };
        },
        InputComponent: ({ item, applyValue }) => <AutocompleteVirtualizedFilterInputComponent item={item} applyValue={applyValue} data={data} />,
    },
];

const _In_siteData = ({ item, applyValue, valueGetter, siteDateGetter, grouped = false }) => {
    const { data: siteData } = useContext(SiteData);
    const options = siteData[siteDateGetter] || [];

    const callback = (value) => {
        applyValue(value);
    }

    return (
        <MultiSelect
            valueGetter={valueGetter}
            item={item}
            options={options}
            callback={applyValue}
            grouped={grouped}
        />

    );
}

const _In_siteData_teams = ({ item, applyValue, valueGetter, siteDateGetter, grouped = false }) => {
    const { data: siteData } = useContext(SiteData);
    const { teams } = useSelector(state => state?.user?.user?.permissions);
    const options = convertTeams(siteData[siteDateGetter], teams) || [];

    const callback = (value) => {
        applyValue(value);
    }

    return (
        <MultiSelect
            valueGetter={valueGetter}
            item={item}
            options={options}
            callback={callback}
            grouped={grouped}
        />

    );
}

const _In_person = ({ item, applyValue, valueGetter, grouped = false }) => {
    const people = useContext(People)
    // console.log(people)
    const options = people?.people ?? [];

    const callback = (value) => {
        applyValue(value);
    }

    return (
        <AutocompleteVirtualizedFilterInputComponent item={item} applyValue={applyValue} data={options} />
    )
}

const _In_Org = ({ item, applyValue, valueGetter, grouped = false }) => {
    const orgs = useContext(Org)
    const options = orgs?.orgs ?? [];

    return (
        <AutocompleteVirtualizedFilterInputComponent item={item} applyValue={applyValue} data={options} />
    )
}

const _in_query = ({ item, applyValue, valueGetter, query, grouped = false, siteDateGetter }) => {

    const { data: options, error: error, loading: loading } = useQuery(query)

    const callback = (value) => {
        applyValue(value);
    }

    // console.log(options)

    if (loading) {
        return <TextField
            value={'Loading...'}
            variant='standard'
            sx={{
                marginTop: '16px'
            }}
            disabled
        /> // or some loading indicator
    }

    if (error) {
        return <TextField
            value={'Loading...'}
            variant='standard'
            sx={{
                marginTop: '16px'
            }}
            disabled
        />// or some error message
    }

    if (options === undefined) {
        return <TextField
            value={'Loading...'}
            variant='standard'
            sx={{
                marginTop: '16px'
            }}
            disabled
        />// or some loading indicator
    }

    return (
        <MultiSelect
            valueGetter={valueGetter}
            item={item}
            options={options[siteDateGetter]}
            callback={applyValue}
            grouped={grouped}
        />
    );
}

const MultiSelect = ({ valueGetter, item, options, callback, grouped }) => {
    const [value, setValue] = useState(item.value || []);

    useEffect(() => {
        callback({ ...item, value });
    }, [value]);

    const handleChange = (event) => {
        setValue(event.target.value)
    };

    const renderValue = (selected) => {
        if (!selected.length) {
            return 'Select';
        }
        return selected.map((value) => {
            const option = options.flatMap(group => group.children || [group]).find((opt) => opt.id === value);
            return option ? option[valueGetter] ?? '' : value;
        }).join(', ');
    };

    const handleGroupClick = (group) => {
        const groupValues = group.children.map(child => child.id);
        const allValues = groupValues.every(groupValue => value.includes(groupValue));
        const newValue = allValues
            ? value.filter(valueA => !groupValues.includes(valueA))
            : [...new Set([...value, ...groupValues])];

        setValue(newValue);
        callback({ ...item, value: newValue });
    };

    const handleItemClick = (single) => {
        const newValue = value.includes(single)
            ? value.filter(checkedItem => checkedItem !== single)
            : [...value, single];

        setValue(newValue);
        callback({ ...item, value: newValue });
    };
    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="select-label">Select</InputLabel>
            <Select
                labelId="select-label"
                multiple
                value={value}
                onChange={handleChange}
                renderValue={renderValue}
                label="Select"
                sx={{
                    maxHeight: 300,
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 400,
                        }
                    },
                }}
            >
                {grouped && options?.map((group) => (
                    <div key={group[valueGetter]}>
                        <MenuItem onClick={() => handleGroupClick(group)}>
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#2a203b'
                                }}
                            >
                                {group[valueGetter]}
                            </Typography>
                        </MenuItem>
                        {group.children.map((option) => (
                            <MenuItem key={option.id} value={option.id} onClick={() => handleItemClick(option.id)}>
                                <ListItemText primary={option[valueGetter]?.charAt(0)?.toUpperCase() + option[valueGetter]?.slice(1) ?? ''} />
                                <Checkbox
                                    checked={value.includes(option.id)}
                                    size='small'
                                    sx={{
                                        padding: 0,
                                        marginRight: 1,
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </div>
                ))}
                {!grouped && options?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={option[valueGetter] ?? ''} />
                        <Checkbox
                            checked={value.includes(option.id)}
                            size='small'
                            sx={{
                                padding: 0,
                                marginRight: 1,
                                border: 'none'
                            }}
                        />
                    </MenuItem>
                ))}
                {!options.length && <MenuItem disabled>No options</MenuItem>}
            </Select >
        </FormControl>
    )
}

const _in_query_redux = ({ item, applyValue, valueGetter, grouped = false, queryType, siteDateGetter, redux }) => {
    const state = useSelector(state => state.user)
    let stateData = []
    let query = null;
    switch (queryType) {
        case 'org':
            stateData = state?.user?.permissions?.organizations.map(org => `"${org.organization.id}"`) ?? []
            if (stateData?.length !== 0) {
                query = gql`
                    query GetOrgs {
                        organization(
                            where: {
                                active: {
                                    _eq: true
                                },
                                id: {
                                    _in: [${stateData}]
                                },
                                tickets_aggregate: {
                                    count: {
                                        predicate: {
                                            _gt: 0
                                        }
                                    }
                                }
                            },
                            order_by: {
                                name: asc
                            }
                        ) {
                            name
                            id
                        }
                    }
                `;
            }
            break;
        case 'person':
            stateData = state?.user?.permissions?.organizations.map(org => `"${org.organization.id}"`) ?? []
            if (stateData?.length !== 0) {
                query = gql`
                query GET_AGENTS {
                    person(
                    where: {
                        active: {
                            _eq: true
                        }, 
                        organization_id: {
                            _in: [${stateData}]
                        }
                    },
                    order_by: {name: asc}
                    ) 
                    {
                        name
                        last_name
                        id
                    }
                }
                `
            }
            break;
        default:
            break;
    }
    if (query === null || stateData?.length === 0) return (
        <TextField
            value={'Loading...'}
            variant='standard'
            sx={{
                marginTop: '16px'
            }}
            disabled
        />)

    return (
        <_in_query item={item} applyValue={applyValue} valueGetter={valueGetter} query={query} grouped={grouped} siteDateGetter={siteDateGetter} />
    )
}

const _status_in = [
    {
        label: 'Any Of',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_In_siteData item={item} applyValue={applyValue} valueGetter={'tableName'} siteDateGetter={'status'} />,
    },
];

const _status_nin = [
    {
        label: 'Not Any Of',
        value: '_nin',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };

        },
        InputComponent: ({ item, applyValue }) => <_In_siteData item={item} applyValue={applyValue} valueGetter={'tableName'} siteDateGetter={'status'} />,
    },
];

const _priority_in = [
    {
        label: 'Any Of',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_In_siteData item={item} applyValue={applyValue} valueGetter={'name'} siteDateGetter={'priority'} />,
    },
];

const _priority_nin = [
    {
        label: 'Not Any Of',
        value: '_nin',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_In_siteData item={item} applyValue={applyValue} valueGetter={'name'} siteDateGetter={'priority'} />,
    },
];


const _teams_in = [
    {
        label: 'Any Of',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_In_siteData_teams item={item} applyValue={applyValue} valueGetter={'tableName'} siteDateGetter={'teams'} grouped={true} />,
    },
];

const _teams_nin = [
    {
        label: 'Not Any Of',
        value: '_nin',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_In_siteData_teams item={item} applyValue={applyValue} valueGetter={'tableName'} siteDateGetter={'teams'} grouped={true} />,
    },
];

const _requester_in = [
    {
        label: 'Any Of',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_In_person item={item} applyValue={applyValue} grouped={false} valueGetter={"name"} />,
    },
];

const _org_in = [
    {
        label: 'Any Of',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_In_Org item={item} applyValue={applyValue} grouped={false} valueGetter={"name"} />,
    },
];

const _org_nin = [
    {
        label: 'Not Any Of',
        value: '_nin',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_In_Org item={item} applyValue={applyValue} grouped={false} valueGetter={"name"} />,
    },
];

const _requester_nin = [
    {
        label: 'Not Any Of',
        value: '_nin',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_In_person item={item} applyValue={applyValue} grouped={false} valueGetter={"name"} />,
    },
];

const _assignee_in = [
    {
        label: 'Any Of',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_in_query item={item} applyValue={applyValue} valueGetter={'name'} siteDateGetter={'person'} query={gql`
            query GET_AGENTS {
                person(where: {active: {_eq: true}, organization_id: {_eq: "be4c7483-78e6-482e-ba4b-630e5bba3ff1"}}, order_by: {name: asc}) {
                name
                last_name
                id
                }
            }
            `} />,
    },
];

const _assignee_nin = [
    {
        label: 'Not Any Of',
        value: '_nin',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_in_query item={item} applyValue={applyValue} valueGetter={'name'} siteDateGetter={'person'} query={gql`
            query GET_AGENTS {
                person(where: {active: {_eq: true}, organization_id: {_eq: "be4c7483-78e6-482e-ba4b-630e5bba3ff1"}}, order_by: {name: asc}) {
                name
                last_name
                id
                }
            }
            `} />,
    },
];

const _organization_in = [
    {
        label: 'Any Of',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_in_query item={item} applyValue={applyValue} valueGetter={'name'} siteDateGetter={'organization'} query={gql`
            query GetOrgs {
                organization(where: {active: {_eq: true}, tickets_aggregate: {count: {predicate: {_gt: 0}}}}, order_by: {name: asc}) {
                name
                id
                }
            }
            `} />,
    },
];

const _organization_nin = [
    {
        label: 'Not Any Of',
        value: '_nin',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_in_query item={item} applyValue={applyValue} valueGetter={'name'} siteDateGetter={'organization'} query={gql`
            query GetOrgs {
                organization(where: {active: {_eq: true}, tickets_aggregate: {count: {predicate: {_gt: 0}}}}, order_by: {name: asc}) {
                name
                id
                }
            }
            `} />,
    },
];
const _organization_in_gm = [
    {
        label: 'Any Of',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_in_query_redux item={item} applyValue={applyValue} valueGetter={'name'} queryType={'org'} siteDateGetter={'organization'} />,
    },
];

const _organization_nin_gm = [
    {
        label: 'Not Any Of',
        value: '_nin',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_in_query_redux item={item} applyValue={applyValue} valueGetter={'name'} queryType={'org'} siteDateGetter={'organization'} />,
    },
];

const _requester_in_gm = [
    {
        label: 'Any Of',
        value: '_in',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_in_query_redux item={item} applyValue={applyValue} grouped={false} valueGetter={"name"} queryType={'person'} siteDateGetter={'person'} />,
    },
];

const _requester_nin_gm = [
    {
        label: 'Not Any Of',
        value: '_nin',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || filterItem.value == null) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: ({ item, applyValue }) => <_in_query_redux item={item} applyValue={applyValue} grouped={false} valueGetter={"name"} queryType={'person'} siteDateGetter={'person'} />,
    },
];


const filters = {
    _eq,
    _eq_number,
    _in,
    _ilike,
    _in_dropdown,
    _is_null,
    _iregex,
    _niregex,
    _gte,
    _lte,
    _gte_lte,
    _in_query,
    _in_autocomplete,
    _status_in,
    _status_nin,
    _priority_in,
    _priority_nin,
    _teams_in,
    _teams_nin,
    _requester_in,
    _requester_nin,
    _requester_in_gm,
    _requester_nin_gm,
    _assignee_in,
    _assignee_nin,
    _organization_in,
    _organization_nin,
    _organization_in_gm,
    _organization_nin_gm,
    _org_in,
    _org_nin
};

export default filters;

