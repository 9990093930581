import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import PageBase from "components/PageBase";
import styles from "./styles";
import { Grid, Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormStackLoader from "components/Global/FormStackLoader";
import FormLoader from "components/FormLoader";
import { useSelector } from "react-redux";

const Feedback = (props) => {
  const { theme } = props;
  const [loading, setLoading] = useState(true);

  const user = useSelector((state) => state?.user.user);
  useEffect(() => {
    let loadingTimer = setTimeout(() => setLoading(false), 0);
    return () => {
      clearTimeout(loadingTimer);
    };
  }, []);

  const handleCallback = (data) => {
    // if (data === true) {
    //   console.log("Form loaded");
    // } else {
    //   console.log("Form error");
    // }
  };

  return (
    <PageBase minHeight={500} loading={false}>
      {!loading && (
        <>
          <FormLoader formID={"5435091"} userID={user.id} />
        </>
      )}
    </PageBase>
  );
};
export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(Feedback);
