import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridToolbarExport,
} from '@mui/x-data-grid-pro';
import GridToolbarViewSelector from './GridToolbarViewSelector';
import QuickCloseTicketToolBar from './QuickCloseTicketToolBar';
import { Button, Box, Tooltip } from '@mui/material';
import { Clear } from '@mui/icons-material';
import Translation from 'utils/translation';
import React, { useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';

const customToolTip = () => {
    return (
        <Tooltip title={"Hello"}>
            Hello
        </Tooltip>
    )
}

const CustomToolbar = ({
    avaliableViews,
    currentView,
    setCurrentView,
    saveNewView,
    deleteView,
    apiRef,
    columns,
    selectedCheckboxRows,
    setSelectedCheckboxRows,
    handleQuickSearch
}) => {

    return (
        <GridToolbarContainer
            sx={{
                minHeight: 68,
                display: 'flex',
                flexWrap: 'nowrap',
            }}
        >
            {selectedCheckboxRows.length > 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <Button
                        size='small'
                        onClick={() => setSelectedCheckboxRows([])}
                        startIcon={<Clear />}
                        color="error"
                    >
                        {Translation('ticketViewPage.toolbar.label.removeMultiSelect')}
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <QuickCloseTicketToolBar
                            apiRef={apiRef}
                            selectedCheckboxRows={selectedCheckboxRows}
                            columns={columns}
                        />
                    </Box>
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            flexGrow: 1,
                            position: 'relative'
                        }}
                    >
                        <GridToolbarQuickFilter
                            onBlur={(event) => {
                                handleQuickSearch()
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleQuickSearch()
                                }
                            }}
                            sx={{
                                flexGrow: '1 !important',
                                width: '100%',
                                '& .MuiInput-input': {
                                    paddingRight: '100px',
                                    height: '44px'
                                },
                                '& .MuiIconButton-root': {
                                    position: 'absolute !important',
                                    right: '66px !important',
                                    top: 5,
                                    height: 44
                                }

                            }}
                            debounceMs={0}
                        />
                        <Button
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 5,
                                height: 44
                            }}
                            onClick={() => handleQuickSearch()}
                        >
                            Search
                        </Button>
                    </Box>
                    <GridToolbarViewSelector
                        avaliableViews={avaliableViews}
                        currentView={currentView}
                        setCurrentView={setCurrentView}
                        saveNewView={saveNewView}
                        deleteView={deleteView}
                    />
                    <GridToolbarExport
                        printOptions={{
                            disableToolbarButton: true,
                        }}
                        sx={{
                            display: { xs: 'none', sm: 'flex' }
                        }}
                    />
                    <GridToolbarColumnsButton

                    />
                    <GridToolbarDensitySelector
                        sx={{
                            display: { xs: 'none', sm: 'flex' }
                        }}
                    />
                    <GridToolbarFilterButton
                        sx={{
                            display: { xs: 'none', sm: 'flex' }
                        }}
                        disableHoverListener={true}
                    />
                </>
            )}
        </GridToolbarContainer>
    );
}

export default CustomToolbar;