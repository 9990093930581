/* eslint-disable array-callback-return */
const fr = () => {
  let returnData = {};
  const req = require.context("./", true, /.js$/);
  const modules = req.keys().map(req);
  modules.map((module) => {
    returnData = {
      ...returnData,
      ...module.default,
    };
  });
  return returnData;
};

export default fr;
