export const OPEN_VIEW = "app/OPEN_VIEW";
export const OPEN_DYNAMIC_VIEW = "app/OPEN_DYNAMIC_VIEW";
export const OPENED_VIEW = "app/OPENED_VIEW";
export const OPENED_DYNAMIC_VIEW = "app/OPENED_DYNAMIC_VIEW";
export const CLOSE_VIEW = "app/CLOSE_VIEW";
export const CLOSED_VIEW = "app/CLOSED_VIEW";
export const SELECT_MENU_ITEM = "app/SELECT_MENU_ITEM";
export const SELECTED_MENU_ITEM = "app/SELECTED_MENU_ITEM";
export const CHANGE_MENU = "app/CHANGE_MENU";
export const OPEN_DYNAMIC_PARENT_VIEW = "app/OPEN_DYNAMIC_PARENT_VIEW";
export const OPEN_SETTING_DRAWER = "app/OPEN_SETTING_DRAWER";
export const CLOSE_SETTING_DRAWER = "app/CLOSE_SETTING_DRAWER";

export const SHOW_HIDE_OPEN_VIEWS = "app/SHOW_HIDE_OPEN_VIEWS";
export const SHOW_HIDE_HEADER_TABS = "app/SHOW_HIDE_HEADER_TABS";

export const SHOW_SEARCH = "app/SHOW_SEARCH";
