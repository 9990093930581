import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getInitials } from "utils/helperFunctions";
import { signOut } from "slices/user";
import styles from "./styles";
import TranslationComponent from "components/Global/Translation";
import CompanyContact from "containers/Pages/CompanyContact";
import { Navigate } from "react-router-dom"
import MenuNavigationItem from "components/Global/MenuNavigationItem";
import { useSelector } from "react-redux";


class Profile extends React.Component {
  state = {
    anchorEl: null,
    msssoProfile: false,
    msssoProfilePicture: null,
  };

  

  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  signOutHandler = () => {
    this.setState({
      anchorEl: null,
    });
   // console.log("starting signout");
    this.props.dispatch(signOut());
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, drawerIsOpen, user, socketStatus } = this.props;
    const open = Boolean(anchorEl);

    //translate "help" and "sign out" with Translation component
    const userID = user?.id;
    const orgID = user?.organization?.id;
    // console.log(user)
    //  console.log('user id from menu section',orgID)
    // console.log('user from menu section',user)

  

    return (
      <div>
        <IconButton
          aria-owns={drawerIsOpen ? "menu-appbar" : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
          size="large"
        >
          {this.props.user.avatar ? (
            <Avatar
              className={classNames(
                classes.avatar,
                socketStatus
                  ? classes.avatarConnected
                  : classes.avatarDisconnected
              )}
              src={this.props.user.avatar}
            ></Avatar>
          ) : (
            <>
              {this.state.msssoProfile ? (
                <Avatar
                  className={classNames(
                    classes.avatar,
                    socketStatus
                      ? classes.avatarConnected
                      : classes.avatarDisconnected
                  )}
                  src={this.state.msssoProfilePicture}
                ></Avatar>
              ) : (
                <Avatar
                  className={classNames(
                    classes.avatar,
                    socketStatus
                      ? classes.avatarConnected
                      : classes.avatarDisconnected
                  )}
                >
                  {getInitials(user.name)}
                </Avatar>
              )}
            </>
          )}
        </IconButton>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            marginTop: "33px",
            "& .MuiPaper-root": {
              borderRadius: "3px",
            },
          }}
          open={open}
          onClose={this.handleClose}
        >
          {/* passing the user id to the end of the profile url */}
          <MenuNavigationItem tag="profile.menu.viewProfile" to={`/admin/profile/${userID}`} />
          <MenuNavigationItem tag="profile.menu.viewCompany" to={`/admin/company/${orgID}`} />
          <MenuNavigationItem tag="profile.menu.help" to='/help/contact-us' />
          <MenuNavigationItem tag="menu.manage.helpSection.releaseNotes.tabText" to='/help/release-notes' />
          <Divider />
          <MenuItem onClick={this.signOutHandler}>
            <TranslationComponent tag="profile.menu.signOut" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerIsOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    socketStatus: state.socket.connected,
    mssso: state.user.mssso,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles((theme) => styles(theme), {
    withTheme: true,
  })(Profile)
);
