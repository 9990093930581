import React, { useEffect, useState, useRef } from 'react';
import withStyles from '@mui/styles/withStyles';
import layoutStyles from 'containers/Layout/styles';
import styles from './styles';
import Button from '@mui/material/Button';
import { useQuery, useMutation, gql } from '@apollo/client';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Translation from 'utils/translation';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import PageBase from 'components/PageBase';
import { graphQLQueries } from 'graphql/organization';
import { useLazyQuery } from "@apollo/client";
import organization from 'graphql/organization';
import { useNavigate } from 'react-router-dom';




function CreateContact(props) {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const [selectedOrganization, setOrganization] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { GET_ORGANIZATIONS } = organization();
  const [
    getOrganizations,
    { loading: loadingOrganizations, error: errorOrganizations, data: dataOrganizations },
  ] = useLazyQuery(GET_ORGANIZATIONS);
  const navigate = useNavigate();


  useEffect(() => {
    getOrganizations();
  }, []);

  let orgNames = [];
  for (let i = 0; i < dataOrganizations?.organization.length; i++) {

    orgNames.push(dataOrganizations?.organization[i].name);
  }

  //  console.log(orgNames);


  let createPersonMutaion = gql`
    mutation MyMutation(
      $permission_group_id: uuid = "", 
      $organization_id: uuid = "", 
      $last_name: String = "", 
      $first_name: String = "", 
      $email_address: String = "", 
      $account_id: uuid = "", 
      $time_zone_id: uuid = "",
      $orgPermissionId: uuid = "") {
      insert_person(
        objects: {
          account_id: 
          $account_id, 
          active: true, 
          email_address: 
          $email_address, 
          deleted: false, 
          organization_id: 
          $organization_id, 
          last_name: $last_name, 
          first_name: $first_name, 
          first_time_setup: true, 
          time_zone_id: $time_zone_id, 
          permissions: {
            data: {
              groups: {
                data: {
                  permission_group_id: $permission_group_id
                }
              }
              organizations: {
                data: {
                  permission_organization_id: $orgPermissionId
                }                
              }
            }
          }
        }
        ) {
        affected_rows
      }
    }`

  const [createPerson, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(createPersonMutaion);

  useEffect(() => {
    setErrorMessage(mutationError?.message);
  }, [mutationError]);

  const handleSubmit = () => {
    setErrorMessage("");
    let orgID = dataOrganizations?.organization.find(o => o.name === selectedOrganization)?.id;
    let orgPermissionId = dataOrganizations?.organization.find(o => o.name === selectedOrganization)?.permissions?.id;

    let variables = {
      first_name: firstNameRef.current.value,
      last_name: lastNameRef.current.value,
      email_address: emailRef.current.value,
      organization_id: orgID,
      account_id: "92cf2939-e08a-4a03-908a-f4d136b6f1e0",
      time_zone_id: "0185e8a3-364c-746c-a95f-89ff9caf1fff",
      permission_group_id: "f56dc335-b4a0-45e9-836c-14075d8618ad",
      orgPermissionId: orgPermissionId
    }

    if (!variables.first_name || !variables.last_name || !variables.email_address || !variables.organization_id) {
      setErrorMessage("Please fill out all fields");
      return;
    }

    let emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(variables.email_address)) {
      setErrorMessage("Please enter a valid email address");
      return;
    }

    createPerson({ variables: variables })
  }

  return (
    <>
      <PageBase>
        <Card sx={{}}>
          {mutationData?.insert_person?.affected_rows >= 1 ? (
            <CardContent>
              <Typography variant="h5" component="div">
                Contact Created!
              </Typography>

              {/* <Button variant="contained" onClick={() => navigate('/')}>Dashboard</Button> */}
            </CardContent>

          ) : (
            <CardContent>
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ alignItems: 'center', textAlign: 'center' }}>
                {Translation("dashboard.modal.addContact")}
              </Typography>
              {errorMessage && (
                <Typography variant="h4" color="error" sx={{ alignItems: 'center', textAlign: 'center' }}>{errorMessage}</Typography>
              )}
              <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column' }}>
                <TextField label='First Name' inputRef={firstNameRef} sx={{ width: '100%' }} />

                <TextField label='Last Name' inputRef={lastNameRef} sx={{ marginTop: 2, width: '100%' }} />

                <TextField label='Email' inputRef={emailRef} sx={{ marginTop: 2, width: '100%' }} />

                <Autocomplete
                  disablePortal
                  id="organization"
                  options={orgNames}
                  multiple={false}
                  onChange={(event, value) => {

                    setOrganization(value);
                  }}

                  sx={{ width: '100%', marginTop: 2 }}
                  renderInput={(params) => <TextField {...params} label="Organization" />}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 2, left: '50%' }}>

                <Button disabled={mutationLoading} variant='contained' sx={{ left: '37%', width: '25%' }}
                  onClick={() => handleSubmit()}
                >{Translation("dashboard.modal.button.Add")}

                </Button>
              </Box>
            </CardContent>
          )}
        </Card>
      </PageBase>
    </>
  )
}

export default CreateContact;