import React, { useEffect, useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { Box, flexbox } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, TextField } from "@mui/material";
import Button from "components/MuiOverride/Button";
import DropdownSelect from "components/Global/DropDownSelect";
import DataTableFilterLoop from "./DataTableFilterLoop";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import Translation from "utils/translation";
function DataTableFilters(props) {
  const {
    filterOptions,
    valueChanged,
    showFilters,
    setShowFilters,
    theme,
    classes,
    handleSaveView,
    activeView,
    filterView,
    setCurrentView,
  } = props;
  const [value, setValue] = useState("");
  const [debounced] = useDebouncedValue(value, 400);
  const [currentValues, setCurrentValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [mainFilterOptions, setMainFilterOptions] = useState(filterOptions);

  // useEffect(() => {
  //   // console.log(filterOptions);
  // }, [filterOptions]);
  // useEffect(() => {
  //   // console.log({ filterOptions: filterOptions });
  //   console.log(activeView?.global);
  // }, []);
  // useEffect(() => {
  //   console.log(activeView?.global);
  // }, [activeView]);
  useEffect(() => {
    if (filterView?.length > 0 && filterView !== null) {
      setCurrentValues(filterView);
    }
  }, [])

  useEffect(() => {
    // if (filterOptions?.length > 0) {
    //   let tempCurrentValues = {};
    //   filterOptions.forEach((filter, index) => {
    //     tempCurrentValues[filter.field_parent] = filter.value;
    //   });

    //   // console.log(tempCurrentValues);

    //   // console.log(Object.keys(tempCurrentValues).length);
    //   setCurrentValues(tempCurrentValues);
    // }
    // setLoading(false);
    // setMainFilterOptions(filterOptions);
    setLoading(false);
  }, [filterOptions]);

  useEffect(() => {
          // valueChanged(debounced);
    if (Object.keys(debounced)?.length > 0) {
      // console.log({ debounced: debounced });
      // console.log(value);
      valueChanged(debounced);
    }
  }, [debounced]);

  const handleChange = (event, value) => {
    // console.log(event);
    // console.log(value);
    // console.log("handleChange");
  };

  const resetHandler = () => {
    setCurrentView(0)
      setShowFilters(false)
  };

  useEffect(() => {
    // console.log({ currentValues: currentValues });
  }, [currentValues]);

  return (
    <>
    <Box sx={{ overflowY: 'scroll',  maxHeight: '600px', border: '1px solid #ccc' }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          overflowY: "auto"
        }}
      >
        <Button
          sx={{
            backgroundColor: theme.palette.grey[200],
            textAlign: "center",
            padding: theme.spacing(1.5, 1),
            cursor: "pointer",
            zIndex: 10,
            // width: "50%",
            width: "100%",
            // position: "relative",
            borderRadius: "0px",
          }}
          onClick={() => setShowFilters()}
        >
          <FontAwesomeIcon icon={["fas", "chevron-right"]} fixedWidth />
        </Button>
        {/* <Button
          disabled={activeView?.global}
          sx={{
            backgroundColor: theme.palette.grey[200],
            textAlign: "center",
            padding: theme.spacing(1.5, 1),
            cursor: "pointer",
            zIndex: 10,
            // position: "relative",
            width: "50%",
            borderRadius: "0px",
          }}
          onClick={() => handleSaveView()}
        >
          <FontAwesomeIcon icon={["fas", "save"]} fixedWidth />
        </Button> */}
      </Box>

      <Box display="flex" sx={{ placeContent: "center", marginTop: "1rem" }}>
        <Typography variant="h6">{Translation("dataTable.filters.header")}</Typography>
      </Box>
      <Box padding={theme.spacing(3)} 
      sx={{overflowY: 'scroll'}}>
        {loading ? (
          <div>{Translation("dataTable.filters.loading")}</div>
        ) : (
          <Box
          sx={{}}>
            <DataTableFilterLoop
              handleChange={handleChange}
              theme={theme}
              classes={classes}
              currentValues={currentValues}
              filterOptions={filterOptions}
              setCurrentValues={setCurrentValues}
              setValue={(value,queryField) => {
                setValue({
                  value: value,
                  queryField: queryField
                })
              }}
              defaultValues={filterView}
            />
          </Box>
        )}
        <Box display="flex" sx={{ placeContent: "center" , }}>
          <Button
            onClick={resetHandler}
            variant="contained"
            size="large"
            color="secondary"
            disabled={true}
          >
            <Box>{Translation("dataTable.filters.reset")}</Box>
          </Button>
        </Box>
      </Box>
      </Box>
    </>
  );
}

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(DataTableFilters);
