import React, { useEffect } from 'react';
import useTableData from 'containers/Pages/TableTest/hooks/useTableData';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Button, Box, Tooltip, LinearProgress } from '@mui/material';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridToolbarExport,
    GridPagination
} from '@mui/x-data-grid-pro';
import { Clear } from '@mui/icons-material';
import Translation from 'utils/translation';
import MuiPagination from '@mui/material/Pagination';

const Pagination = (props) => {
    const { page, onPageChange, className, rowsPerPage, count } = props;

    return (
        <MuiPagination
            size='small'
            color='primary'
            className={className}
            count={Math.ceil(count / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
        />
    );
};

const CustomPagination = (props) => {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const CustomToolbar = ({
    avaliableViews,
    currentView,
    setCurrentView,
    saveNewView,
    deleteView,
    apiRef,
    columns,
    selectedCheckboxRows,
    setSelectedCheckboxRows,
    handleQuickSearch
}) => {

    return (
        <GridToolbarContainer
            sx={{
                minHeight: 68,
                display: 'flex',
                flexWrap: 'nowrap',
            }}
        >
            {selectedCheckboxRows?.length > 0 ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <Button
                        size='small'
                        onClick={() => setSelectedCheckboxRows([])}
                        startIcon={<Clear />}
                        color="error"
                    >
                        {Translation('ticketViewPage.toolbar.label.removeMultiSelect')}
                    </Button>
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            flexGrow: 1,
                            position: 'relative'
                        }}
                    >
                        <GridToolbarQuickFilter
                            onBlur={(event) => {
                                handleQuickSearch()
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    handleQuickSearch()
                                }
                            }}
                            sx={{
                                flexGrow: '1 !important',
                                width: '100%',
                                '& .MuiInput-input': {
                                    paddingRight: '100px',
                                    height: '44px'
                                },
                                '& .MuiIconButton-root': {
                                    position: 'absolute !important',
                                    right: '66px !important',
                                    top: 5,
                                    height: 44
                                }

                            }}
                            debounceMs={0}
                        />
                        <Button
                            sx={{
                                position: 'absolute',
                                right: 0,
                                top: 5,
                                height: 44
                            }}
                            onClick={() => handleQuickSearch()}
                        >
                            Search
                        </Button>
                    </Box>
                    {/* <GridToolbarExport
                        printOptions={{
                            disableToolbarButton: true,
                        }}
                        sx={{
                            display: { xs: 'none', sm: 'flex' }
                        }}
                    /> */}
                    <GridToolbarColumnsButton

                    />
                    <GridToolbarDensitySelector
                        sx={{
                            display: { xs: 'none', sm: 'flex' }
                        }}
                    />
                    {/* <GridToolbarFilterButton
                        sx={{
                            display: { xs: 'none', sm: 'flex' }
                        }}
                        disableHoverListener={true}
                    /> */}
                </>
            )}
        </GridToolbarContainer>
    );
}

const TableGeneric = ({ columns = [], query, searchQuery, tableName = "-", onRowClick, style, personID }) => {
    const [tempFilters, setTempFilters] = React.useState({})

    const {
        apiRef,
        rows,
        tableLoading,
        setPage,
        pageSize,
        filtersHasChanged,
        rowCount,
        error,
        pageSizeOptions,
        density,
        setDensity,
        handlePaginationModelChange,
        subConnected,
        sx,
        sortOptionsHaveChanged
    } = useTableData({ query, searchQuery, tableName, rowClick: onRowClick, style, personID, defaultPageSize: 10 });


    useEffect(() => {
        console.log('rows', rows)
    }, [rows])

    const handleQuickSearch = () => {
        filtersHasChanged(tempFilters)
    }

    return (
        <DataGridPro
            rows={rows}
            columns={columns}
            apiRef={apiRef}
            pagination
            paginationMode='server'
            filterMode='server'
            sortingMode='server'
            disableSelectionOnClick
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            density={density}
            loading={tableLoading}
            rowCount={rowCount}
            sx={sx}
            onRowClick={onRowClick}
            onStateChange={(params) => {
                if (params.density.value !== density) setDensity(params.density.value)
            }}
            onFilterModelChange={(params) => {
                setTempFilters(params)
                if (params?.items?.length === 0 && params?.quickFilterValues?.length === 0) {
                    filtersHasChanged(params)
                    setTempFilters({})
                }
            }}
            onPaginationModelChange={
                (params) => {
                    handlePaginationModelChange(params)
                }
            }
            onSortModelChange={(params) => {
                sortOptionsHaveChanged(params)
            }}
            slots={{
                toolbar: CustomToolbar,
                loadingOverlay: LinearProgress,
                pagination: CustomPagination,
            }}
            slotProps={{
                toolbar: {
                    columns,
                    apiRef,
                    filtersHasChanged,
                    handleQuickSearch
                },
                pagination: {
                    rowsPerPage: pageSize,
                }
            }}
        />
    )
}

export default TableGeneric;