import React, { useContext, useEffect } from 'react';
import { DateTime } from 'luxon';
import { EnumType } from 'json-to-graphql-query';
import { SiteData } from 'context/siteData';
import TableTest from './TableTest';
import convertTeams from './utils/convertTeams';
import Filters from './filters';
import PriorityLabel from './components/PriorityLabel';
import StatusLabel from './components/StatusLabel';
import UserAvatar from './components/UserAvatar';
import useTableData from './hooks/useTableData';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { width } from '@mui/system';
import { People } from 'context/people';

const PERSON_QUERY = gql`
query PERSON_QUERY($_eq: String = "", $_eq1: String = "") {
    person(limit: 40 , order_by: {name: asc}) {
      id
      email_address
      name
      organization {
        name
      }
      middle_name
      last_name
      first_name
    }
  }`;

const ORGANIZATION_QUERY = gql`
query GetOrgs {
    organization(where: {active: {_eq: true}, tickets_aggregate: {count: {predicate: {_gt: 0}}}}, order_by: {name: asc}) {
      name
      id
    }
  }
`;

const AGENT_QUERY = gql`
query GET_AGENTS {
    person(where: {active: {_eq: true}, organization_id: {_eq: "be4c7483-78e6-482e-ba4b-630e5bba3ff1"}}, order_by: {name: asc}) {
      name
      last_name
      id
    }
  }
`;

const tableName = 'ticket_activeorg';
const dataTableIdentifier = 'primary_ticket_table';
let query = {
    query: {
        [tableName]: {
            __args: {
                limit: 25,
                offset: 0,
                order_by: {
                    updated_at: new EnumType('desc')
                },
                where: {
                    _and: [

                    ]
                }
            },
            id: true,
            alias: true,
            priority_id: true,
            status_id: true,
            subject: true,
            updated_at: true,
            created_at: true,
            requester: {
                id: true,
                name: true,
                avatar: true
            },
            assignee: {
                id: true,
                name: true,
                avatar: true
            },
            team_id: true,
            organization: {
                name: true
            }
        }
    }
};

const searchQuery = {
    default: [
        { key: 'subject', operator: '_ilike' },
        { key: 'assignee.name', operator: '_ilike' },
        { key: 'requester.name', operator: '_ilike' },
        { key: 'organization.name', operator: '_ilike' },
        { key: 'organization.parent_organization.name', operator: '_ilike' },
        { key: 'team.name', operator: '_ilike' },
        { key: 'status.name', operator: '_ilike' },
        { key: 'priority.name', operator: '_ilike' },
    ],
    numeric: [
        { key: 'alias', operator: '_eq' },
        { key: 'subject', operator: '_ilike' }
    ]
}

const TableLoader = () => {
    const navigate = useNavigate();
    const people = useContext(People);
    const { user } = useSelector(state => state.user);
    const { roles } = user ?? {};
    const is_agent = roles.includes('agent') ?? false;
    const is_gm = roles.includes('group_gm') ?? false;
    const { data: siteData, functions } = useContext(SiteData);
    const { priority: priorityData, status: statusData, teams: teamsData } = siteData;

    const MainFilters = Filters

    const rowClick = (event) => {
        if (event?.alias) {
            navigate('/support/tickets/' + event.alias);
        } else if (event?.row?.alias) {
            navigate('/support/tickets/' + event.row.alias);
        }
    };

    useEffect(() => {
        return () => {
            functions.refreshData();
        };
    }, []);


    const {
        apiRef,
        rows,
        tableLoading,
        setPage,
        pageSize,
        filtersHasChanged,
        rowCount,
        error,
        pageSizeOptions,
        density,
        setDensity,
        handlePaginationModelChange,
        subConnected,
        sx,
        sortOptionsHaveChanged
    } = useTableData({ query, searchQuery, tableName, rowClick });

    useEffect(() => {
        if (siteData) {
            const views = siteData?.views?.filter(view => view.datatable === dataTableIdentifier);
            const USER_DEFAULT_VIEW = views?.filter(view => view.datatable === dataTableIdentifier && view.default_flag === true)[0];
            const USER_RECENTLY_UPDATED = views?.reduce((latest, current) => {
                const latestUpdatedTime = new Date(latest.updated_at).getTime();
                const currentUpdatedTime = new Date(current.updated_at).getTime();
                return currentUpdatedTime > latestUpdatedTime ? current : latest;
            }, views[0]);
            const currentTime = new Date().getTime();
            const sixtyMinutesAgo = currentTime - (60 * 60 * 1000);
            const isRecentlyUpdated = USER_RECENTLY_UPDATED && new Date(USER_RECENTLY_UPDATED.updated_at).getTime() > sixtyMinutesAgo;
            const firstLoadView = USER_RECENTLY_UPDATED ?? USER_DEFAULT_VIEW ?? null;
            console.log({ firstLoadView })
            if (firstLoadView) {
                query = {
                    query: {
                        [tableName]: {
                            __args: {
                                limit: firstLoadView?.state?.pagination?.paginationModel?.pageSize ?? 25,
                                offset: 0,
                                order_by: {
                                    updated_at: new EnumType('desc')
                                },
                                where: {
                                    _and: [

                                    ]
                                }
                            },
                            id: true,
                            alias: true,
                            priority_id: true,
                            status_id: true,
                            subject: true,
                            updated_at: true,
                            created_at: true,
                            requester: {
                                id: true,
                                name: true,
                                avatar: true
                            },
                            assignee: {
                                id: true,
                                name: true,
                                avatar: true
                            },
                            team_id: true,
                            organization: {
                                name: true
                            }
                        }
                    }
                };
            }

        }
    }, [siteData])

    const agentFields = [

    ]

    // How To Do Multi Filters
    // filterOperators: [
    //     Filters._in_dropdown(priorityData.map((priority) => ({ label: priority.name, value: priority.id })))[0],
    //     Filters._is_null[0]
    // ]



    const agentColumns = [
        {
            field: 'alias',
            headerName: 'ID',
            width: 70,
            resizable: false,
            filterOperators: MainFilters._eq_number,
        },
        {
            field: 'priority_id',
            headerName: 'Priority',
            width: 100,
            resizable: false,
            valueGetter: (params) => priorityData?.find((priority) => priority.id === params?.row?.priority_id)?.name ?? '',
            renderCell: (cellValues) => <PriorityLabel value={cellValues.value} density={density} />,
            filterOperators: [MainFilters._priority_in[0], MainFilters._priority_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'status_id',
            headerName: 'Status',
            width: 90,
            resizable: false,
            valueGetter: (params) => statusData?.find((status) => status.id === params?.row?.status_id)?.tableName ?? '',
            valueOptions: statusData?.map((status) => ({ label: status.tableName, value: status.id })),
            renderCell: (cellValues) => <StatusLabel value={cellValues.value} density={density} />,
            filterOperators: [MainFilters._status_in[0], MainFilters._status_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'subject',
            headerName: 'Subject',
            width: 400,
            filterOperators: MainFilters._iregex,
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 110,
            resizable: false,
            valueFormatter: (params) => DateTime.fromISO(params?.value).toRelative(),
            filterOperators: [MainFilters._gte_lte[0], Filters._lte[0], Filters._gte[0]]
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 110,
            resizable: false,
            valueFormatter: (params) => DateTime.fromISO(params?.value).toRelative(),
            filterOperators: [MainFilters._gte_lte[0], Filters._lte[0], Filters._gte[0]]
        },
        {
            field: 'requester_id',
            headerName: 'Requester',
            width: 140,
            valueGetter: (params) => params?.row?.requester ?? '',
            renderCell: (cellValues) => <UserAvatar user={cellValues.value} density={density} />,
            filterOperators: [MainFilters._requester_in[0], MainFilters._requester_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'assignee_id',
            headerName: 'Assignee',
            width: 140,
            valueGetter: (params) => params?.row?.assignee ?? '',
            renderCell: (cellValues) => <UserAvatar user={cellValues.value} density={density} />,
            filterOperators: [MainFilters._assignee_in[0], MainFilters._assignee_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'team_id',
            headerName: 'Team',
            width: 130,
            valueGetter: (params) => teamsData?.find((team) => team.id === params?.row?.team_id)?.tableName ?? '',
            filterOperators: [MainFilters._teams_in[0], MainFilters._teams_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'organization_id',
            headerName: 'Organization',
            width: 270,
            valueGetter: (params) => params?.row?.organization?.name ?? '',
            filterOperators: [MainFilters._org_in[0], MainFilters._org_nin[0], MainFilters._is_null[0]]
        },
    ];

    const userColumns = [
        {
            field: 'alias',
            headerName: 'Alias',
            width: 70,
            resizable: false,
            filterOperators: MainFilters._eq_number,
        },
        {
            field: 'priority_id',
            headerName: 'Priority',
            width: 100,
            resizable: false,
            valueGetter: (params) => priorityData?.find((priority) => priority.id === params?.row?.priority_id)?.name ?? '',
            renderCell: (cellValues) => <PriorityLabel value={cellValues.value} density={density} />,
            filterOperators: [MainFilters._priority_in[0], MainFilters._priority_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'status_id',
            headerName: 'Status',
            width: 90,
            resizable: false,
            valueGetter: (params) => statusData?.find((status) => status.id === params?.row?.status_id)?.tableName ?? '',
            valueOptions: statusData?.map((status) => ({ label: status.tableName, value: status.id })),
            renderCell: (cellValues) => <StatusLabel value={cellValues.value} density={density} />,
            filterOperators: [MainFilters._status_in[0], MainFilters._status_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'subject',
            headerName: 'Subject',
            width: 400,
            filterOperators: MainFilters._iregex,
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 110,
            resizable: false,
            valueFormatter: (params) => DateTime.fromISO(params?.value).toRelative(),
            filterOperators: [MainFilters._gte_lte[0], Filters._lte[0], Filters._gte[0]]
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 110,
            resizable: false,
            valueFormatter: (params) => DateTime.fromISO(params?.value).toRelative(),
            filterOperators: [MainFilters._gte_lte[0], Filters._lte[0], Filters._gte[0]]
        },
        {
            field: 'requester_id',
            headerName: 'Requester',
            width: 140,
            valueGetter: (params) => params?.row?.requester ?? '',
            renderCell: (cellValues) => <UserAvatar user={cellValues.value} density={density} />,
            filterOperators: [MainFilters._is_null[0]]
        },
        {
            field: 'assignee_id',
            headerName: 'Assignee',
            width: 140,
            valueGetter: (params) => params?.row?.assignee ?? '',
            renderCell: (cellValues) => <UserAvatar user={cellValues.value} density={density} />,
            filterOperators: [MainFilters._is_null[0]]
        },
        {
            field: 'team_id',
            headerName: 'Team',
            width: 130,
            valueGetter: (params) => teamsData?.find((team) => team.id === params?.row?.team_id)?.tableName ?? '',
            filterOperators: [MainFilters._teams_in[0], MainFilters._teams_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'organization_id',
            headerName: 'Organization',
            width: 270,
            valueGetter: (params) => params?.row?.organization?.name ?? '',
            filterOperators: [MainFilters._is_null[0]]
        },
    ];

    const gmColumns = [
        {
            field: 'alias',
            headerName: 'Alias',
            width: 70,
            resizable: false,
            filterOperators: MainFilters._eq_number,
        },
        {
            field: 'priority_id',
            headerName: 'Priority',
            width: 100,
            resizable: false,
            valueGetter: (params) => priorityData?.find((priority) => priority.id === params?.row?.priority_id)?.name ?? '',
            renderCell: (cellValues) => <PriorityLabel value={cellValues.value} density={density} />,
            filterOperators: [MainFilters._priority_in[0], MainFilters._priority_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'status_id',
            headerName: 'Status',
            width: 90,
            resizable: false,
            valueGetter: (params) => statusData?.find((status) => status.id === params?.row?.status_id)?.tableName ?? '',
            valueOptions: statusData?.map((status) => ({ label: status.tableName, value: status.id })),
            renderCell: (cellValues) => <StatusLabel value={cellValues.value} density={density} />,
            filterOperators: [MainFilters._status_in[0], MainFilters._status_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'subject',
            headerName: 'Subject',
            width: 400,
            filterOperators: MainFilters._iregex,
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 110,
            resizable: false,
            valueFormatter: (params) => DateTime.fromISO(params?.value).toRelative(),
            filterOperators: [MainFilters._gte_lte[0], Filters._lte[0], Filters._gte[0]]
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 110,
            resizable: false,
            valueFormatter: (params) => DateTime.fromISO(params?.value).toRelative(),
            filterOperators: [MainFilters._gte_lte[0], Filters._lte[0], Filters._gte[0]]
        },
        {
            field: 'requester_id',
            headerName: 'Requester',
            width: 140,
            valueGetter: (params) => params?.row?.requester ?? '',
            renderCell: (cellValues) => <UserAvatar user={cellValues.value} density={density} />,
            filterOperators: [MainFilters._requester_in_gm[0], MainFilters._requester_nin_gm[0], MainFilters._is_null[0]]
        },
        {
            field: 'assignee_id',
            headerName: 'Assignee',
            width: 140,
            valueGetter: (params) => params?.row?.assignee ?? '',
            renderCell: (cellValues) => <UserAvatar user={cellValues.value} density={density} />,
            filterOperators: [MainFilters._is_null[0]]
        },
        {
            field: 'team_id',
            headerName: 'Team',
            width: 130,
            valueGetter: (params) => teamsData?.find((team) => team.id === params?.row?.team_id)?.tableName ?? '',
            filterOperators: [MainFilters._teams_in[0], MainFilters._teams_nin[0], MainFilters._is_null[0]]
        },
        {
            field: 'organization_id',
            headerName: 'Organization',
            width: 270,
            valueGetter: (params) => params?.row?.organization?.name ?? '',
            filterOperators: [MainFilters._organization_in_gm[0], MainFilters._organization_nin_gm[0], MainFilters._is_null[0]]
        },
    ];

    let columns;
    if (is_agent) {
        columns = agentColumns;
    } else if (is_gm) {
        columns = gmColumns;
    } else {
        columns = userColumns;
    }


    return (
        <>
            <TableTest
                columns={[...(is_agent ? agentFields : []), ...columns,]}
                sx={sx}
                siteData={{
                    apiRef,
                    rows,
                    tableLoading,
                    setPage,
                    pageSize,
                    filtersHasChanged,
                    rowCount,
                    error,
                    pageSizeOptions,
                    density,
                    setDensity,
                    handlePaginationModelChange,
                    tableName,
                    dataTableIdentifier,
                    subConnected,
                    sortOptionsHaveChanged
                }}
            />
        </>
    );
}

export default TableLoader;