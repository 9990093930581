import React, { useEffect, useState } from "react";
import {
  SidePanelCard,
  SidePanelCardContent,
  SidePanelCardIcon,
  SidePanelCardTitle,
  SidePanelCardTitlePopOut,
} from "containers/Layout/ThreePanelView/components/Styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "@mui/styles/withStyles";
import { Box, Button } from "@mui/material";
import { Popper as PopperUnstyled } from "@mui/base/Popper";
import { useHover } from "@mantine/hooks";
import TicketModal from "containers/Pages/TicketViewPage/components/TicketModal";
import Tooltip from "@mui/material/Tooltip";
import Translation from "utils/translation";
const SidePanelItem = (props) => {
  const {
    tabValue,
    handleTabs,
    title,
    panelCollapsed,
    icon,
    children,
    right = false,
    handleCollapse,
    modalContent,
    count = 0,
    icon2,
    theme,
    permission = false,
    widthOverride
  } = props;
  const [showPanel, setShowPanel] = useState(true);
  const [open, setOpen] = React.useState(false);
  const { hovered, ref } = useHover();
  const { hovered: popOutHovered, ref: popOut } = useHover();
  const [openModal, setOpenModal] = React.useState(false);
  // console.log('SidePanelItem',tabValue)
  useEffect(() => {
    if (typeof handleTabs === "function") {
      handleTabs({ title, icon, children });
    }
  }, []);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  useEffect(() => {
    if (hovered && !popOutHovered) {
      setOpen(true);
    }
    if (hovered && popOutHovered) {
      setOpen(true);
    }
    if (!hovered && !popOutHovered) {
      setOpen(false);
    }
  }, [hovered]);

  const handleClickIcon = () => {
    setOpen(false);
    handleCollapse(right ? "right" : "left");
  };
  return (
    <>
      <TicketModal
        open={openModal}
        handleClose={handleCloseModal}
        content={modalContent}
        widthOverride={widthOverride || undefined}
      />
      {tabValue?.title === title && (
        <SidePanelCard>
          <Box sx={{ height: theme.spacing(1) }} />

          <SidePanelCardContent>
            {permission && (
              <Button
                display={"inline-block"}
                variant={"outlined"}
                color={"primary"}
                sx={{ marginTop: theme.spacing(2), width: "100%" }}
                onClick={handleOpenModal}
              >
                {" "}
                <FontAwesomeIcon icon={["fas", "plus"]} fixedWidth />
                Add {title}
              </Button>
            )}

            {children}
          </SidePanelCardContent>
        </SidePanelCard>
      )}
      {!tabValue && (
        <SidePanelCard ref={ref}>
          {!panelCollapsed && (
            <SidePanelCardIcon onClick={handleClickIcon}>
              <FontAwesomeIcon icon={icon} fixedWidth />
            </SidePanelCardIcon>
          )}

          {panelCollapsed && (
            <SidePanelCardTitle $showPanel={showPanel}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Box onClick={() => setShowPanel(!showPanel)} flexGrow={1}>
                  <FontAwesomeIcon
                    icon={[
                      "fas",
                      `${showPanel ? "chevron-down" : "chevron-right"}`,
                    ]}
                    fixedWidth
                    style={{ paddingRight: 10 }}
                  />
                  <FontAwesomeIcon
                    icon={icon}
                    style={{ paddingRight: 10 }}
                    fixedWidth
                  />

                  {title} {count > 0 && `(${count})`}


                </Box>
                {modalContent && (
                  <Tooltip title={Translation("tooltip.edit")}>
                    <FontAwesomeIcon
                      icon={["fas", "pen-to-square"]}
                      fixedWidth
                      onClick={handleOpenModal}
                    />
                  </Tooltip>
                )}
              </Box>
            </SidePanelCardTitle>
          )}
          {showPanel && panelCollapsed && (
            <SidePanelCardContent>{children}</SidePanelCardContent>
          )}
          {!panelCollapsed && (
            <PopperUnstyled
              id={title}
              open={open}
              anchorEl={ref.current}
              style={{
                zIndex: 1000,
              }}
              placement={"right-start"}
            >
              {" "}
              <Box
                ref={popOut}
                sx={{
                  "& div:nth-of-type(2)": {
                    margin: "0px !important",
                  },
                  borderRadius: props.theme.borderRadius,
                  position: "relative",
                  top: -1,
                  left: !right ? 20 : -15,

                  backgroundColor: props.theme.palette.text.light,
                  boxShadow: props.theme.shadows[4],

                  padding: props.theme.spacing(3, 3, 3, 3),
                }}
              >
                <SidePanelCardTitlePopOut>
                  {title} {count > 0 && `(${count})`}
                </SidePanelCardTitlePopOut>

                {children}
              </Box>
            </PopperUnstyled>
          )}
        </SidePanelCard>
      )}
    </>
  );
};

export default withStyles((theme) => ({}), { withTheme: true })(SidePanelItem);
