import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql";
import { refreshToken as refreshTokenSocket } from "containers/App/websocket/refreshToken";
import { addTokens } from "slices/user";
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split, } from "@apollo/client";
import { getMainDefinition, Observable } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { TokenRefreshLink } from 'apollo-link-token-refresh';

const apolloCreate = ({ store }) => {
  // Returns access token if operation is not a refresh token request
  //
  let token = store.getState().user?.tokens?.token || null;

  const authHeaders = {
    authorization: token ? `Bearer ${token}` : "",
  };

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_SERVER,
  });
  const authLink = setContext((operation, { headers }) => {
    return {
      headers: {
        ...headers,
        ...authHeaders,
      },
    };
  });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_API_SERVER_WS,
      connectionParams: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
      lazy: true,
      // on: {
      //   closed: (e) => {
      //     console.log(e)
      //   },
      //   connected: (e) => {
      //     console.log(e)
      //   },
      //   error: (e) => {
      //     console.log(e)
      //   }
      // }
    })
  );
  // TODO: add error handling for refresh token

  // const errorLink = onError(
  //   ({ graphQLErrors, networkError, operation, forward }) => {
  //     if (graphQLErrors) {
  //       // console.log('graphQLErrors', graphQLErrors);
  //       for (let err of graphQLErrors) {
  //
  //         switch (err.extensions.code) {
  //           case "access-denied":
  //             // console.log("Access denied");
  //             // console.log(err);
  //             // ignore 401 error for a refresh request
  //             // console.log(operation.operationName);
  //             if (operation.operationName === "refreshToken") return;
  //
  //             return new Observable((observer) => {
  //               // used an anonymous function for using an async function
  //               (async () => {
  //                 try {
  //                   // console.log("refreshing token");
  //                   const accessToken = await refreshTokenInternal();
  //
  //                   if (!accessToken) {
  //                     throw new GraphQLError("Empty AccessToken");
  //                   }
  //                   // console.log(
  //                   //   "retry failed request with new token",
  //                   //   accessToken
  //                   // );
  //                   // Retry the failed request
  //                   const subscriber = {
  //                     next: observer.next.bind(observer),
  //                     error: observer.error.bind(observer),
  //                     complete: observer.complete.bind(observer),
  //                   };
  //
  //                   forward(operation).subscribe(subscriber);
  //                 } catch (err) {
  //                   console.log("err in refresh catch", err);
  //                   observer.error(err);
  //                 }
  //               })();
  //             });
  //           default:
  //             return null;
  //         }
  //       }
  //     }
  //
  //     // if (networkError) console.log(`[Network error]: ${networkError}`);
  //   }
  // );

  const link = split(
    //only create the split in the browser
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    httpLink
  );

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
  };
  const refreshLink = new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: async () => {
      let token = store.getState().user?.tokens.token;
      let refreshToken = store.getState().user?.tokens?.refreshToken;
      let expiresAt = store.getState().user?.tokens?.expiresAt;

      if (!token) return false;
      if (!refreshToken) return false;
      const now = new Date();
      const expiresOn = new Date(expiresAt);
      const diff = expiresOn - now;
      const minutes = Math.floor(diff / 1000 / 60);
      // console.log("minutes", minutes,minutes >= 5);
      return minutes >= 5;


      // const accessToken = accessTokenSelector(getState());
      // validate access token and return true/false
    },
    fetchAccessToken: async () => {
      // console.log("fetching access token");
      return await refreshTokenInternal();

    },
    handleFetch: accessToken => {
      // console.log("handle fetch", accessToken)

    },
    handleResponse: (operation, accessTokenField) => response => {
      // console.log("handle response", response)
      // here you can parse response, handle errors, prepare returned token to
      // further operations

      // returned object should be like this:
      // {
      //    access_token: 'token string here'
      // }
    },
    handleError: err => {
      // full control over handling token fetch Error
      console.warn('Your refresh token is invalid. Try to relogin');
      console.error(err);

      // When the browser is offline and an error occurs we don’t want the user to be logged out of course.
      // We also don’t want to delete a JWT token from the `localStorage` in this case of course.

    }
  })
  const client = new ApolloClient({
    link: ApolloLink.from([refreshLink, authLink, link]),
    // link: ApolloLink.from([errorLink, authLink, link]),
    cache: new InMemoryCache(),
    defaultHttpLink: false,
    defaultOptions: defaultOptions,
  });

  // Request a refresh token to then stores and returns the accessToken.
  const refreshTokenInternal = async () => {
    try {
      // let payload = await  (store.dispatch({type: REFRESH_TOKEN, payload: store.getState().user?.tokens?.refreshToken}))

      // console.log("starting refresh token");
      let refreshToken = store.getState().user?.tokens?.refreshToken;
      let [payload] = await Promise.all([
        store.dispatch(refreshTokenSocket(refreshToken)),
      ]);
      // console.log("got payload", payload);
      if (payload.result.error) {
        console.log("error", payload.result.error);
        return null;
      }
      if (!payload.result.error) {
        // console.log("got payload", payload);
        let [tokenPayload] = await Promise.all([
          store.dispatch(addTokens(payload.result.data)),
        ]);

        return tokenPayload.payload.token;
      }
      return null;
    } catch (err) {
      console.log("err - maybe log out", err);
      throw err;
    }
  };

  return client;
};

export default apolloCreate;
