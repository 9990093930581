import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
const TextInput = ({ name, value, label, callBack, variant, backgroundColor, color, id, required, multiline, rows, inputRef, defaultValue }) => {
  return (
    <TextField
      inputRef={inputRef}
      name={name}
      required={required}
      variant={variant}
      id={id}
      multiline={multiline}
      rows={rows}
      value={value}
      label={label}
      size='small'
      margin='dense'
      onChange={callBack}
      defaultValue={defaultValue}
      fullWidth
      sx={{
        '& .MuiInputBase-root.MuiInputBase-colorPrimary': {
          backgroundColor: backgroundColor
        },
        '& .MuiInputBase-adornedEnd': {
          backgroundColor: backgroundColor
        },
        '& .MuiInputBase-inputTypeSearch': {
          padding: '4px'
        },
        '& .MuiInputLabel-inputTypeSearch': {
          color: color,
          fontWeight: '500',
          transform: 'translate(7px, 4px)'
        },
        '& .css-1v55oxs-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': {
          color: color,
        },
        '& .css-16b0e1j-MuiInputBase-root-MuiOutlinedInput-root': {
          borderRadius: '0px'
        },
      }}
    />
  )
}

TextInput.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  callBack: PropTypes.func,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.string,
  ref: PropTypes.object,
  inputRef: PropTypes.object
}

TextInput.defaultProps = {
  name: '',
  id: '',
  label: '',
  callBack: () => { },
  backgroundColor: 'white',
  color: 'black',
  variant: 'outlined',
  required: false,
  multiline: false,
  rows: 1,
  value: null,
  ref: null,
  inputRef: null
}

export default TextInput