export const SIGN_IN = "app/SIGN_IN";
export const TWO_FACTOR = "app/TWO_FACTOR";
export const SIGN_OUT = "app/SIGN_OUT";
export const LOGGING_IN = "app/LOGGING_IN";
export const CLEAR_LOGGING_IN = "app/CLEAR_LOGGING_IN";
export const AUTHENTICATED = "app/AUTHENTICATED";
export const TWO_FACTOR_CHECK = "app/TWO_FACTOR_CHECK";
export const CLEAR_TWO_FACTOR = "app/CLEAR_TWO_FACTOR";
export const AUTHENTICATION_FAILED = "app/AUTHENTICATION_FAILED";
export const CLEAR_AUTHENTICATION_MESSAGE = "app/CLEAR_AUTHENTICATION_MESSAGE";
export const REGISTER = "app/REGISTER";
export const REGISTRATION_FAILED = "app/REGISTRATION_FAILED";
export const RESET_PASSWORD = "app/RESET_PASSWORD";
export const RESET_PASSWORD_FAILED = "app/RESET_PASSWORD_FAILED";
export const ADD_TOKENS = "app/ADD_TOKENS";
export const CHECK_LOGIN_STATE = "app/CHECK_LOGIN_STATE";
export const SET_ONLINE_USERS = "app/SET_ONLINE_USERS";
export const SIGN_IN_SSO = "app/SIGN_IN_SSO";
export const UPDATE_MSSSO_TOKENS = "app/UPDATE_MSSSO_TOKENS";
export const UPDATE_TOKEN = "app/UPDATE_TOKEN";
export const SET_TWO_FACTOR = "app/SET_TWO_FACTOR";
