import { useState, createContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { gql, useLazyQuery } from '@apollo/client'

const Org = createContext()


const query = gql`
    query GetOrgs {
        organization(
            where: {
                active: {
                    _eq: true
                },
            },
            order_by: {
                name: asc
            }
        ) {
            name
            id
        }
    }
`;

const OrgProvider = ({ children, socketClient }) => {
    // const socket = socketClient
    const { user } = useSelector(state => state.user);
    const { roles } = user ?? {};
    const isAgent = roles?.includes("admin") || roles?.includes("agent");
    const [orgs, setOrgs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [getOrgs, { data: orgData, loading: orgLoaded, error: orgError }] = useLazyQuery(query);

    useEffect(() => {
        if (isAgent) {
            getOrgs()
        } else {
            setLoading(false)
        }
    }, [user]);

    useEffect(() => {
        if (orgData) {
            setOrgs(orgData.organization)
            setLoading(false)
        }
    }, [orgData]);

    useEffect(() => {
        if (orgError) {
            setError(orgError)
            setLoading(true)
        }
    }, [orgError]);


    return (
        <Org.Provider value={{ orgs, loading, error }}>
            {children}
        </Org.Provider>
    )
}

export { Org, OrgProvider };