import { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import changeFilterValue from '../utils/ChangeFilterValue';
import updatePaginationQuery from '../utils/updatePaginationQuery';
import createAggregateQuery from '../utils/createAggregateQuery';
import { useSelector } from 'react-redux';

const useTableData = ({ query, searchQuery, tableName, fieldTranslations = [], rowClick = null, style, personID, defaultPageSize = 25 }) => {

    const updateTicketTableID = useSelector(state => state.app.updateTicketTableID);
    const aggregateQuery = createAggregateQuery(query, tableName);
    const apiRef = useGridApiRef();
    const [inititalTicketTableID, setInititalTicketTableID] = useState(updateTicketTableID)
    const [mainQuery, setMainQuery] = useState(gql`
        ${jsonToGraphQLQuery(query)}
    `);
    const [countQuery, setCountQuery] = useState(gql`
        ${jsonToGraphQLQuery(aggregateQuery)}
    `);
    const [jsonQuery, setJsonQuery] = useState(query);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [pageSizeOptions, setPageSizeOptions] = useState([5, 10, 25, 50, 100]);
    const [density, setDensity] = useState('compact');

    const [currentFilters, setCurrentFilters] = useState({});

    const [sx, setSX] = useState({
        ...style,
        borderRadius: '0px !important'
    })

    const [getTable, { loading: tableLoading, error, data: table, refetch: tableRefresh }] = useLazyQuery(mainQuery);
    const [getCount, { data: countData, refetch: countRefetch }] = useLazyQuery(countQuery);

    useEffect(() => {
        if (rowClick !== null) {
            setSX({ ...sx, '& .MuiDataGrid-row': { cursor: 'pointer' } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (updateTicketTableID !== inititalTicketTableID) {
            // setInititalTicketTableID(updateTicketTableID)
            // console.log('Preforming table refresh', new Date())
            // tableRefresh()
            // countRefetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateTicketTableID])

    //useEffect to refetch the table and count when the personID changes
    useEffect(() => {

        if (personID) {
            console.log('refetching for', personID)
            // calling filterHasChanged with an empty object will trigger a refetch
            filtersHasChanged({})
        }
    }, [personID])


    // if the mainQuery changes, refetch the table and count
    useEffect(() => {
        if (mainQuery) {
            if (table) {
                getTable()
                getCount()
            } else {
                tableRefresh()
                countRefetch()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainQuery])

    // if the page or pageSize changes, update the query
    useEffect(() => {
        const newMainQuery = updatePaginationQuery(jsonQuery, tableName, page, pageSize);
        setMainQuery(gql`
            ${jsonToGraphQLQuery(newMainQuery)}
        `);
        setJsonQuery(newMainQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize]);


    // if the filters change, update the query
    const filtersHasChanged = (filters) => {
        // console.log('Filters have changed', filters)
        const newMainQuery = changeFilterValue(query, searchQuery, filters, tableName);
        const newCountQuery = changeFilterValue(aggregateQuery, searchQuery, filters, `${tableName}_aggregate`,);
        setCurrentFilters(filters);
        setMainQuery(gql`
            ${jsonToGraphQLQuery(newMainQuery)}
        `);
        setJsonQuery(newMainQuery);
        setCountQuery(gql`
            ${jsonToGraphQLQuery(newCountQuery)}
        `);
    };

    const sortOptionsHaveChanged = (sortModel) => {
        const newMainQuery = changeFilterValue(query, searchQuery, currentFilters, tableName, sortModel);
        setMainQuery(gql`
            ${jsonToGraphQLQuery(newMainQuery)}
        `);
        setJsonQuery(newMainQuery);
    }

    useEffect(() => {
        const handleRowClick = (params) => {
            if (rowClick !== null) {
                rowClick(params);
            }
        };

        const rowClickSubscription = apiRef.current.subscribeEvent('rowClick', handleRowClick);

        // Cleanup function to unsubscribe from both events
        return () => {
            rowClickSubscription();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiRef]);

    const handlePaginationModelChange = (params) => {
        if (params.pageSize !== pageSize) setPageSize(params.pageSize)
        if (params.page !== page) setPage(params.page)
    }

    const handleSetMainQuery = (newMainQuery) => {
        setMainQuery(gql`
            ${jsonToGraphQLQuery(newMainQuery)}
        `);
        setJsonQuery(newMainQuery)
    }

    return {
        apiRef,
        rows: table?.[tableName] ?? [],
        tableLoading,
        setMainQuery: handleSetMainQuery,
        tableRefresh,
        page,
        pageSize,
        filtersHasChanged,
        rowCount: countData?.[`${tableName}_aggregate`]?.aggregate?.count ?? 0,
        error,
        pageSizeOptions,
        setPageSizeOptions,
        density,
        setDensity,
        handlePaginationModelChange,
        sx,
        sortOptionsHaveChanged
    }

}

export default useTableData;

