import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import PageBase from "components/PageBase";
import styles from "./styles";
import flagNumbers from "containers/Pages/ContactUs/flagNumbers";
import FlagNumber from "components/Global/FlagNumber";
import ContactUsTop from "components/ContactUsTop";
import Typography from "@mui/material/Typography";
import Translation from "utils/translation";

function ContactUsPage(props) {
  const { classes } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let loadingTimer = setTimeout(() => setLoading(false), 300);
    return () => {
      clearTimeout(loadingTimer);
    };
  });

  return (
    <PageBase minHeight={500} loading={loading} noPadding={true}>
      <ContactUsTop />
      <div className={classes.bottomContainer}>
        <Typography
          variant="h3"
          component="h3"
          className={classes.headingTextSecond}
        >
          {Translation("contactUsPage.title.second")}
        </Typography>
        {!loading && (
          <div className={classes.FlagNumberGroupContainer}>
            {flagNumbers.map((flagNumber, index) => {
              const { url, number } = flagNumber;
              return <FlagNumber url={url} number={number} key={index} />;
            })}
          </div>
        )}
      </div>

    </PageBase>
  );
}

ContactUsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  flagNumbers: PropTypes.array.isRequired,
};

ContactUsPage.defaultProps = {
  flagNumbers: [],
};

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(ContactUsPage);
