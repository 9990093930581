import React from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import PageBase from "components/PageBase";
import PersonTabs from "containers/Pages/PersonContact/Tabs";
import ThreePanelView from "containers/Layout/ThreePanelView";
import {useSelector } from "react-redux";
import SidePanel from "containers/Pages/CompanyContact/Tabs/OverviewTab/SidePanel"
import { useLazyQuery, useSubscription, gql } from "@apollo/client";
import { useEffect } from "react";
import graphQLQueries from "graphql/person";
import { useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Header from "./Components/Header";
// import { useNavigate } from "react-router-dom";
import NotAuthorizedPage from "../ErrorPages/NotAuthorizedPage";

function PersonContact(props) {

const user = useSelector((state) => state?.user?.user);
const {roles} = user; 
const isAgent = roles.includes('agent');
const { id } = useParams();
const location = useLocation();
const { state } = location;
const Edituser = state ? state.Edituser : null;
  const { GET_USER_PROFILE_DETAILS } = graphQLQueries();
  // const navigate = useNavigate();

// calling my subscription to get the user details
const {  data: UserDetails } = useSubscription(GET_USER_PROFILE_DETAILS, {
  variables: { person_id: id },
});

const GET_COMMENTS = gql`
query MyQuery($_eq: uuid = "") {
  ticket_comment(where: {author_id: {_eq: $_eq}}, order_by: {created_at: desc}, limit: 1) {
    alias
    created_at
    public
    ticket {
      alias
    }
    source {
      body
      subject
      body_preview
    }
      id
    person {
      name
    }
  }
}

`;

const [getComments, { data: Comments }] = useLazyQuery(GET_COMMENTS);

useEffect(() => {
  if (id) {
    getComments({ variables: { _eq: id } });
  }
}, [id]);

// useEffect(() => {
//   if (!isAgent) {
//   if (id !== user?.id) {
//     // navigate(`/404`);
//     // return a different component
//     return (
//     <NotAuthorizedPage />
//     )
// } 
// } 
// }, []);

if (!isAgent && id !== user?.id) {
  return <NotAuthorizedPage />;
}

  return (
    <PageBase minHeight={500} noPadding={true} style={{}}>
      <ThreePanelView
        topPanelHeight={75}
        panelWidth={350}
        collapseHeight={75}
        pageHeaderHeight={100}
        singlePanel={true}
        topSection={
          //Insert Top Section Here
          <>
         <Header
         user={UserDetails}
         Edituser = {Edituser}
         userID = {id}
         />

          </>
        }
        leftSection={
          //Ignore Left Section
          <></>
        }
        centerSection={
          //Add center content here
          <>
          <PersonTabs
          user={UserDetails}
          Edituser = {Edituser}
          userID = {id}
          comments = {Comments}
          />
          </>
        }
        rightSection={
          //Add Right content here
          <SidePanel
          user={UserDetails}
          Edituser = {Edituser}
          userID = {id}
          />
        }
      />
</PageBase>
  )
}


export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(PersonContact);