import React from "react";
import styles from "./styles";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import { Box } from "@mui/system";

const LanguageOption = (props) => {
  const { flag, flagSize, language, classes } = props;
  if (!flag || !flagSize || !language) {
    return null;
  }

  // console.log(props);

  return (
    <Box className={classes.container}>
      <img
        src={flag}
        width={flagSize}
        height={flagSize}
        alt={`${language} Flag`}
      />
      <span className={classes.text}>{language}</span>
    </Box>
  );
};

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(LanguageOption);
