import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  config: {},
  header: {},
  sla: {
    reply_time: 120,
    periodic_update_time: 120,
    resolution_time: 1440,
  },
  quickCreate: false,
  updateTicketTableID: 0,
};

export const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = { ...action.payload };
    },
    setHeader: (state, action) => {
      state.header = { ...action.payload };
    },
    setQuickCreate: (state, action) => {
      state.quickCreate = !state.quickCreate;
    },
    setUpdateTicketTableID: (state, action) => {
      state.updateTicketTableID = action.payload;
    }
  },
});

export const { setConfig, setHeader, setQuickCreate, setUpdateTicketTableID } = app.actions;

export default app.reducer;
