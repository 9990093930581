import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import Translation from 'utils/translation';
import { useSelector, useDispatch } from 'react-redux';
import { setQuickCreate } from 'slices/app'
import Virtualize from "components/MuiOverride/Autocomplete";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import { graphQLMutations } from "graphql/ticket";
import { useNavigate } from 'react-router-dom';
import {
  ModalContainer,
  ModalHeaderContainer,
  ModalHeaderTitle,
  HeaderCloseIcon,
  ModalBodyContainer,
  ModalTextInput,
  ModelLoadingIndicatorContainer,
  ModelLoadingIndicator,
  QuickCreateModelDisclaimer,
  QuickCreateFooter,
  QuickCreateButton,
  QuickCreateError,
  QuickCreateSuccessTitle,
  LeftGroup,
  RightGroup,
  DropdownGroups,
  GroupHeader
} from './muiOverrides';

const organizationQuery = gql`
query MyQuery {
  organization(order_by: { name: asc }) {
    id
    name
  }
}`

const contactQuery = gql`
query MyQuery($orgID: uuid! = "") {
  person(order_by: {name: asc}, where: {deleted: {_eq: false}, active: {_eq: true}, permissions: {organizations: {organization_permission: {organization_id: {_eq: $orgID}}}}}) {
    id
    name
  }
}`

const GET_TICKET_ALIAS = gql`
  query getTicketAlias($id: uuid!) {
    ticket(where: { id: { _eq: $id } }) {
      alias
    }
  }`;

const priorityQuery = gql`
query MyQuery {
  ticket_priority(where: {active: {_eq: true}, deleted: {_eq: false}}, order_by: {sort: asc}) {
    id
    name
  }
}`

const StatusQuery = gql`
query MyQuery {
  ticket_status(where: {deleted: {_eq: false}, active: {_eq: true}, id: {_nin: ["115dc3ec-c6ca-4377-8d6b-d24045c345c8", "9736681b-a657-45d2-9415-f4befe48aece"]}}, order_by: {sort: asc}) {
    name
    id
  }
}`

function QuickCreateTicket() {
  const open = useSelector((state) => state.app.quickCreate);
  const user = useSelector((state) => state?.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { INSERT_TICKET } = graphQLMutations();
  const [insertTicket, { data: insertTicketData, error: insertTicketError }] = useMutation(INSERT_TICKET);
  const [getTicketAlias, { data: getTicketAliasData, error: getTicketAliasError }] = useLazyQuery(GET_TICKET_ALIAS);
  const [organization, setOrganization] = useState(null);
  const [contact, setContact] = useState(null);
  const [priority, setPriority] = useState(null);
  const [status, setStatus] = useState(null);
  const [description, setDescription] = useState(null);
  const [summary, setSummary] = useState(null);
  const [title, setTitle] = useState(null);
  const [error, setError] = useState(null);
  const [state, setState] = useState(0)
  const [ticketID, setTicketID] = useState(null)


  useEffect(() => {
    if (getTicketAliasData) {
      if (getTicketAliasData?.ticket?.length > 0) {
        setTicketID(getTicketAliasData?.ticket[0]?.alias)
      } else {
        setError("Error getting new ticket number ticket, ticket may of still been created. Please check ticket table.")
      }
      setState(2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTicketAliasData]);

  useEffect(() => {
    if (getTicketAliasError) {
      setError("Error getting new ticket number ticket, ticket may of still been created. Please check ticket table.")
      setState(2)
    }
  }, [getTicketAliasError]);

  useEffect(() => {
    if (insertTicketData) {
      if (insertTicketData?.insert_ticket?.returning.length > 0) {
        getTicketAlias({ variables: { id: insertTicketData?.insert_ticket?.returning[0].id } })
      } else {
        setError("Error inserting ticket. Please try again.")
        setState(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertTicketData]);

  useEffect(() => {
    if (insertTicketError) {
      setError(`Error creating ticket : ${insertTicketError.message}`)
      setState(0)
    }
  }, [insertTicketError]);

  const clearStates = () => {
    setOrganization(null);
    setContact(null);
    setDescription(null);
    setSummary(null);
    setTitle(null);
    setError(null);
    setState(0)
    setTicketID(null)
  }

  const handleClose = () => {
    clearStates();
    dispatch(setQuickCreate(false));
  }

  const goToNewTicket = () => {
    navigate(`support/tickets/${ticketID}`);
    clearStates();
    dispatch(setQuickCreate(false));
  }

  const blankCheck = (endpoint) => {
    if (endpoint === null || endpoint === undefined || endpoint === "") {
      return true
    } else {
      return false
    }
  }

  const lengthCheck = (value, length) => {
    if (value?.length < length) {
      return false
    } else {
      return true
    }
  }

  const validate = (checkValues) => {
    try {
      let count = 0
      let message = "Please complete the following fields: "

      checkValues.forEach((item) => {
        if (blankCheck(item.value)) {
          message += `${item.name}, `
          count++
          return
        }
        if (item.length) {
          if (!lengthCheck(item.value, item.length)) {
            message += `${item.name} to short, `
            count++
            return
          }
        }
      })

      if (count === 0) {
        return {
          status: true,
        }
      }
      else {
        message = message.slice(0, -2)
        return {
          status: false,
          message: message
        }
      }
    } catch (err) {
      console.log(err)
      return {
        status: false,
        message: "Error validating fields"
      }
    }
  }

  const handleInsert = () => {
    let variables = {
      objects: {
        has_incidents: false,
        updated_at: "now()",
        account_id: user?.account_id,
        assignee_id: user?.id,
        requester_id: contact,
        subject: title,
        priority_id: priority ?? "e447041f-d5d6-4c41-b22d-2e0157d23c19",
        status_id: status ?? "115dc3ec-c6ca-4377-8d6b-d24045c345c8",
        organization_id: organization,
        board_id: "78f57115-43d8-402d-9219-2b843a1890ad",
        summary: summary,
        comments: {
          data: {
            account_id: user?.account_id,
            public: true,
            agent: false,
            created_at: "now()",
            updated_at: "now()",
            author_id: user?.id,
            source: {
              data: {
                recipient: {
                  manage_uuid: contact,
                },
                conversation_id: null,
                internet_message_id: null,
                sender: {
                  manage_uuid: contact,
                },
                subject: "Initial Description",
                body: description,
                body_preview: description.substring(0, 250),
                type_id: "8ecb7ab1-c27f-48a6-9503-b1dbf6497fa1",

              }
            },
            deleted: false,
          }
        }
      }
    }
    setState(1)
    insertTicket({ variables: variables });
  }

  const onSubmit = () => {
    setState(1)
    setError(null)
    const { status: validateStatus, message } = validate([
      {
        name: "Organization",
        value: organization
      },
      {
        name: "Contact",
        value: contact
      },
      {
        name: "Title",
        value: title,
        length: 5
      },
      {
        name: "Description",
        value: description,
        length: 15
      },
      {
        name: "Summary",
        value: summary,
        length: 15
      }
    ])
    if (validateStatus) {
      handleInsert()
    } else {
      setError(message)
      setState(0)
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="quick-create-ticket-modal"
      aria-describedby="quick-create-ticket-modal"
    >
      <ModalContainer>
        <ModalHeaderContainer>
          <ModalHeaderTitle>{Translation("quickCreateTicketModal.title")}</ModalHeaderTitle>
          <HeaderCloseIcon
            icon="xmark"
            onClick={handleClose}
          />
        </ModalHeaderContainer>
        <ModalBodyContainer>
          {state === 0 && (
            <>
              <DropdownGroups>
                <LeftGroup>
                  <GroupHeader>
                    {Translation("quickCreateTicketModal.groupHeader.requiredFields")}
                  </GroupHeader>
                  <Virtualize
                    query={organizationQuery}
                    field={"name"}
                    returnField={"id"}
                    endpoint={"organization"}
                    type={"autocomplete"}
                    label={"Organization"}
                    callback={(value) => {
                      setOrganization(value)
                    }}
                    group={""}
                    multiple={false}
                  />
                  <Virtualize
                    query={contactQuery}
                    variables={{ orgID: organization }}
                    field={"name"}
                    returnField={"id"}
                    endpoint={"person"}
                    type={"autocomplete"}
                    label={"Contact"}
                    callback={(value) => {
                      setContact(value)
                    }}
                    group={""}
                    multiple={false}
                  />
                </LeftGroup>
                <RightGroup>
                  <GroupHeader align='right'>
                    {Translation("quickCreateTicketModal.groupHeader.optionalFields")}
                  </GroupHeader>
                  <Virtualize
                    query={priorityQuery}
                    field={"name"}
                    returnField={"id"}
                    endpoint={"ticket_priority"}
                    type={"autocomplete"}
                    label={"Priority"}
                    callback={(value) => {
                      setPriority(value)
                    }}
                    group={""}
                    multiple={false}
                  />
                  <Virtualize
                    query={StatusQuery}
                    field={"name"}
                    returnField={"id"}
                    endpoint={"ticket_status"}
                    type={"autocomplete"}
                    label={"Status"}
                    callback={(value) => {
                      setStatus(value)
                    }}
                    group={""}
                    multiple={false}
                  />
                </RightGroup>
              </DropdownGroups>
              <ModalTextInput
                id='quickCreateTicketTitle'
                label="Title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <ModalTextInput
                id='quickCreateTicketDescription'
                multiline
                rows={4}
                label="Detailed Description"
                onChange={(e) => setDescription(e.target.value)}
              />
              <ModalTextInput
                id='quickCreateTicketSummary'
                label="Summary"
                multiline
                rows={4}
                onChange={(e) => setSummary(e.target.value)}
              />
              <QuickCreateFooter>
                <QuickCreateModelDisclaimer>
                  {Translation("quickCreateTicketModal.disclaimer")}
                </QuickCreateModelDisclaimer>
                <QuickCreateButton
                  variant="contained"
                  onClick={onSubmit}
                >
                  {Translation("quickCreateTicketModal.button.submit")}
                </QuickCreateButton>
              </QuickCreateFooter>
            </>
          )}
          {state === 1 && (
            <ModelLoadingIndicatorContainer>
              <ModelLoadingIndicator />
            </ModelLoadingIndicatorContainer>
          )}
          {state === 2 && (
            <>
              <QuickCreateSuccessTitle>
                {Translation("quickCreateTicketModal.success")}
              </QuickCreateSuccessTitle>
              <QuickCreateFooter>
                <QuickCreateButton
                  variant="contained"
                  onClick={clearStates}
                  fullWidth
                >
                  {Translation("quickCreateTicketModal.button.createAnother")}
                </QuickCreateButton>
                <QuickCreateButton
                  onClick={handleClose}
                  fullWidth
                >
                  {Translation("quickCreateTicketModal.button.close")}
                </QuickCreateButton>
                <QuickCreateButton
                  variant="contained"
                  onClick={goToNewTicket}
                  fullWidth
                  disabled={ticketID === null}
                >
                  {Translation("ticketViewPage.masterDetail.viewTicket")}
                </QuickCreateButton>
              </QuickCreateFooter>

            </>
          )}
          {error && (
            <QuickCreateError>
              {error}
            </QuickCreateError>
          )}
        </ModalBodyContainer>

      </ModalContainer>
    </Modal>
  );
}

export default QuickCreateTicket