import PropTypes from 'prop-types';
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  Checkbox,
} from '@mui/material';

const SSGMatrix = ({ field, handleChange, currentValue, formFieldsValues }) => {
  const { label, name, required, options, option_other, } = field;

  // Define column headers based on the field name
  const columnHeaders = {
    performance_matrix: [
      { label: '8GB RAM (Standard)', value: '8GB RAM (Standard)' },
      { label: '16GB RAM', value: '16GB RAM (High)' },
      { label: '32GB RAM', value: '32GB RAM (Ultra)' },
    ],
    style_matrix: [
      { label: 'Non-Touchscreen Laptop', value: 'Non-Touchscreen Laptop' },
      { label: 'Touchscreen Laptop', value: 'Touchscreen Laptop' },
      { label: '2-in-1 Convertible Laptop/Tablet', value: '2-in-1 Convertible Laptop/Tablet' },
      { label: 'Tablet', value: 'Tablet' },
    ],
    use_case_matrix: [
      { label: 'Not Used', value: 'Not Used' },
      { label: 'Single Window at A time', value: 'Single Window at A time' },
      { label: 'Multiple Windows at A time', value: 'Multiple Windows at A time' },
    ],
    usage_environment_matrix: [
      { label: 'At a Desk', value: 'At a Desk' },
      { label: 'On Lap', value: 'On Lap' },
      { label: 'On the Go', value: 'On the Go' },
    ],
  }[name] || [];

  // Check type and format currentValue
  const currentValuesArray = Array.isArray(currentValue)
    ? currentValue
    : (typeof currentValue === 'string' ? currentValue.split(', ').map(val => val.trim()) : []);

  return (
    <div>
      {currentValue !== undefined && (
        <>
          <label>{label || 'Default'}{required && '*'}</label>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {columnHeaders.map((header, index) => (
                    <TableCell key={index}>{header.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {options.map((option, index) => (
                  <TableRow key={index}>
                    <TableCell>{option.label}</TableCell>
                    {columnHeaders.map((header, index) => (
                      <TableCell key={index}>
                        {name === 'use_case_matrix' ? (
                          <Radio
                            name={`${name}-${header.value}-${option.value}`}
                            value={header.value}
                            onChange={(e) => handleChange(header.value, option.value, e.target.checked)}
                          />
                        ) : (
                          <Checkbox
                            checked={currentValuesArray.includes(`${header.value}-${option.value}`)}
                            onChange={(e) => handleChange(header.value, option.value, e.target.checked)}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

SSGMatrix.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
    option_other: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default SSGMatrix;
