function convertTeams(input, teams, access = true) {

    const result = [];

    const teamsMap = input?.reduce((acc, { id, name }) => {
        const [parentName, childName] = name.split(':::');

        const hasAccess = teams?.find(team => team.id === id);

        if (access && !hasAccess) {
            return acc;
        }

        if (!acc[parentName]) {
            acc[parentName] = {
                tableName: parentName,
                children: []
            };
        }

        if (childName) {
            acc[parentName].children.push({
                tableName: childName,
                id: id
            });
        } else {
            acc[parentName].children.push({
                tableName: parentName,
                id: id
            });
        }

        return acc;
    }, {});

    for (const key in teamsMap) {
        result.push(teamsMap[key]);
    }

    result.sort((a, b) => a.tableName.localeCompare(b.tableName));

    result.forEach(parent => {
        parent.children.sort((a, b) => a.tableName.localeCompare(b.tableName));
    });

    return result;
}

export default convertTeams;