import { formLogicChecker } from 'utils/formLogicChecker'

const SSGFormCondition = ({ field, children, formValues }) => {
  // let shouldFieldShow = false

  // if (field.name === 'and_what_exactly_is_the_issue_with_the_system_or_service') {
  //   // console.log({ name: field.name })
  //   let formLogicResult = formLogicChecker({ conditions: field?.condition, formValues })
  //   // console.log({ conditions: field?.condition, formValues })
  //   field?.condition.conditions.map((condition) => {
  //     console.log({ condition: condition.value, formValues: formValues[condition.name] })
  //   })
  //   // console.log({ formLogicResult })
  // }

  // let formLogicResult = formLogicChecker({ conditions: field?.condition, formValues })



  // if (formLogicResult === true && field.hidden === false) {
  //   shouldFieldShow = true
  // }

  return (
    // <>
    //   {shouldFieldShow ? children : null}
    // </>
    <>
      {children}
    </>
  )
}

export default SSGFormCondition