const styles = (theme) => ({
    // helpOptionsContainer: {
    //     width: "100%",
    //     display: "flex",
    //     alignItems: "center",
    //     flexDirection: "column",
    //     gap: "40px",
    //     backgroundColor: '#DAD9EC',
    //     padding: theme.spacing(3),
    // },
});

export default styles;