const misc = {
  'tooltip.disabled': 'Disabled',
  'languageSelector.english': 'English',
  'languageSelector.dutch': 'Dutch',
  'languageSelector.german': 'German',
  'languageSelector.french': 'French',
  'languageSelector.spanish': 'Spanish',
  'languageSelector.polish': 'Polish',
  'languageSelector.italian': 'Italian',
  'languageSelector.thai': 'Thai',
  'datatable.toolbar.quickFilterPlaceholder': 'Search...',
  'datatable.toolbar.columns': 'Columns',
  'datatable.toolbar.density': 'Density',
  'datatable.toolbar.densityComfortable': 'Comfortable',
  'datatable.toolbar.densityCompact': 'Compact',
  'datatable.toolbar.densityStandard': 'Standard',
  'datatable.toolbar.export': 'Export',
  'datatable.toolbar.filters': 'Filters',
  'socket.connected': 'Connected',
  'socket.disconnected': 'Disconnected',
  'profile.menu.viewProfile': 'View Profile',
  'profile.menu.editProfile': 'Edit Profile',
  'profile.menu.help': 'Help',
  'profile.menu.signOut': 'Sign Out',
  'addon.add': 'Add',
  'addon.modal.save': 'Save',
  'addon.edit': 'Edit',
  'notFoundPage.pageDevelopment': 'Page Under Development',
  'notFoundPage.pageDevelopmentMessage':
    ' Thank you for exploring our website. This particular page is still in the works as we are currently in our beta phase. We appreciate your patience and understanding.',
  'notFoundPage.pageDevelopmentMessage2':
    'Please note that our site is undergoing regular updates and enhancements to provide you with the best experience possible. Check back soon for new content and features!',
  general: 'General',
  'ticket.role.first_responder': 'First Responder',
  'ticket.role.first_responder.tooltip':
    'First Responder: Initial point of contact for high priority IT issues, responsible for quick resolution and minimal downtime.',
  'ticket.role.lead_engineer': 'Lead Engineer',
  'ticket.role.lead_engineer.tooltip':
    'Lead Engineer: Responsible for the overall technical direction of the project, and for ensuring that the project is completed on time and within budget.',
  'ticket.status_id': 'Status',
  'ticket.priority_id': 'Priority',
  'ticket.closed_at': 'Closed At',
  'ticket.subject': 'Subject',
  'team.team': 'Team',
  'ticket.identifier': 'UUID',
  'ticket.created_at': 'Created At',
  'ticket.updated_at': 'Updated At',
  'ticket.requester_id': 'Requester',
  'ticket.organization_id': 'Organization',
  'ticket.assignee_id': 'Assignee',
  'ticket.author_id': 'Author',
  'ticket.public': 'Public',
  'ticket.alias': 'Ticket ID',
  'ticket.status': 'Status',
  'ticket.priority': 'Priority',
  'ticket.team_id': 'Team',
  'ticket.team': 'Team',
  'ticket.parent': 'Parent',
  'ticket.parent_id': 'Parent ID',
  'ticket.children': 'Children',
  'ticket.tags': 'Tags',
  'ticket.active': 'Active',
  'ticket.assignee': 'Assignee',
  'ticket.requester': 'Requester',
  'ticket.has_incidents': 'Has Incidents',
  'ticket.updated_by_id': 'Updated By',
  'ticket.author': 'Author',
  'person.assignee': 'Assignee',
  'person.requester': 'Requester',
  'ticket_status.status': 'Status',
  'ticket_priority.priority': 'Priority',
  'organization.organization': 'Company',
  'ticket_priority.priority.normal': 'Normal',
  'ticket_priority.priority.high': 'High',
  'ticket_priority.priority.low': 'Low',
  'ticket_status.status.new': 'New',
  'ticket_status.status.pending': 'Pending',
  'ticket_status.status.hold': 'Hold',
  'ticket_status.status.open': 'Open',
  'ticket_status.status.resolved': 'Resolved',
  'person.name': 'Name',
  'person.avatar': 'Avatar',
  'person.email_address': 'Email',
  'person.active': 'Active',
  'person.alias': 'ID',
  'organization.name': 'Name',
  'organization.active': 'Active',
  'organization.alias': 'ID',
  'board.board': 'Board',

  'HelpOptions.Title': 'How may we help you?',
  'FloatingBeta.ReportIssue': 'Report an Issue',
  'FloatingBeta.Feedback': 'Send Feedback',
  // new translations
  'dateTime.utc': 'UTC',
  'ticket.devices.RMM.install': 'Install Six Star RMM',
  'ticket.devices.RMM.login': 'Login to Six Star RMM',
  'ticket.devices.RMM.title': 'To view the latest information',

  //Data table filters
  'dataTable.filters.reset': 'Reset',
  'dataTable.filters.loading': 'Loading...',
  'dataTable.filters.header': 'Filters',

  //data table preview
  'dataTable.preview.viewTicket': 'View Ticket',
  'dataTable.preview.quickNotes': 'Quick Notes',
  'dataTable.preview.contacts': 'Contacts',
  'dataTable.preview.followers': 'Followers',
  'dataTable.preview.relatedTickets': 'Related Tickets',
  'dataTable.preview.updateIn': 'Update In',
  'dataTable.preview.devices': 'Devices',
  'dataTable.preview.engineer': 'Engineer',
  'dataTable.preview.ticketCreated': 'Ticket Created: ',

  // chatgpt
  'chatgpt.somethingWentWrong': 'Something went wrong please try again',
  'chatgpt.history': 'History',

  //custom group button
  'customGroupButton.one.expandAll': 'Expand All',
  'customGroupButton.two.collapseAll': 'Collapse All',

  // flag numbers
  'flagNumber.button.callUs': 'Call Us',

  'profile.menu.viewCompany': 'View Company',

  //new translations
  'ticketDocuments.notFound': 'No Documents Found',

  //contact us modals
  'contactUsModals.addWidgetModal.title': 'How To Add A Widget To Your Dashboard',
  'contactUsModals.addWidgetModal.step1': '1. Navigate to your dashboard',
  'contactUsModals.addWidgetModal.step2': '2. Locate the purple ribbon on the top of your screen',
  'contactUsModals.addWidgetModal.step3': '3. Click on the add widget button',
  'contactUsModals.addWidgetModal.step4': '4. Select a widget from the list',
  'contactUsModals.addWidgetModal.step5': '5. Click on the add button',

  'contactUsModals.signOutModal.title': 'How To Sign Out',
  'contactUsModals.signOutModal.step1': '1. Find your avatar on the top right of the screen',
  'contactUsModals.signOutModal.step2': '2. Click on your avatar',
  'contactUsModals.signOutModal.step3': '3. Find the sign out button in the drop down menu',
  'contactUsModals.signOutModal.step4': '4. Click on the sign out button',

  'ticketViewPage.toolbar.label': 'Search Tickets',
  'ticketViewPage.toolbar.view': 'View',
  'ticketViewPage.toolbar.saveView': 'Save View',
  'ticketViewPage.toolbar.deleteView': 'Delete Current View',
  'ticketViewPage.toolbar.saveViewPrompt': 'Please enter the name you would like to save this view as.',
  'ticketViewPage.toolbar.deleteViewPrompt': 'Are you sure you want to delete the current view.',
  'ticketViewPage.toolbar.label.export': 'Export',
  'ticketViewPage.toolbar.label.filters': 'Filters',
  'ticketViewPage.toolbar.label.removeMultiSelect': 'Clear All',
  'ticketViewPage.toolbar.label.openViews': 'Views',

  'formLoader.submissionSuccess': 'Thank you for submitting the form',
  'formLoader.submissionError': 'There was an error submitting the form',

  'reportIssue.submit': 'Submit',
  'reportIssue.issue': 'Issue...',

  'ticketViewPage.modal.button.bundle': 'Bundle',
  'ticketViewPage.modal.button.back': 'Back',
  'ticketViewPage.childTicketsNone': 'No child tickets',
  'ticketViewPage.parentNone': 'No parent ticket',
  'ticketViewPage.restricted': 'Restricted',

  //new transltions below
  'ticketViewPage.modal.button.close': 'Close',
  'tooltip.edit': 'Edit',
  'tooltip.notifications': 'Notifications',
  'tooltip.chooseLanguage': 'Choose Language',
  'tooltip.logTicket': 'Log Ticket',
  'tooltip.contactUs': 'Contact Us',
  'tooltip.hideTray': 'Hide Tray',
  'ticketTablePage.dateTime.secondsAgo': 'Just Now',

  //new translations below
};
export default misc;
