import React from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import PropTypes from "prop-types";
import { TextField as MuiTextField } from "@mui/material";
import { useAuditAction } from "containers/App/Hooks/useAuditAction";

function TextField(props) {
    let {
        autoComplete,
        autoFocus,
        classes,
        color,
        defaultValue,
        disabled,
        error,
        FormHelperTextProps,
        fullWidth,
        helperText,
        id,
        InputLabelProps,
        inputProps,
        InputProps,
        inputRef,
        label,
        margin,
        maxRows,
        metadata,
        minRows,
        multiline,
        name,
        onChange,
        placeholder,
        required,
        rows,
        select,
        SelectProps,
        size,
        sx,
        type,
        value,
        variant
    } = props

    const { handleInsertAuditLog } = useAuditAction();

    return (
        <MuiTextField
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            // classes={classes}
            color={color}
            defaultValue={defaultValue}
            disabled={disabled}
            error={error}
            FormHelperTextProps={FormHelperTextProps}
            fullWidth={fullWidth}
            helperText={helperText}
            id={id}
            InputLabelProps={InputLabelProps}
            inputProps={inputProps}
            InputProps={InputProps}
            inputRef={inputRef}
            label={label}
            margin={margin}
            maxRows={maxRows}
            minRows={minRows}
            multiline={multiline}
            name={name}
            onChange={
                (event) => {
                    if (onChange) {
                        handleInsertAuditLog("mui_textfield_changed", metadata)
                        onChange(event);
                    }
                }
            }
            placeholder={placeholder}
            required={required}
            rows={rows}
            select={select}
            SelectProps={SelectProps}
            size={size}
            sx={sx}
            type={type}
            value={value}
            variant={variant}
        />
    )
}

TextField.propTypes = {
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    classes: PropTypes.object,
    color: PropTypes.oneOf(["primary", "secondary"]),
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    FormHelperTextProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.node,
    id: PropTypes.string,
    InputLabelProps: PropTypes.object,
    inputProps: PropTypes.object,
    InputProps: PropTypes.object,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ]),
    label: PropTypes.node,
    margin: PropTypes.oneOf(["dense", "none", "normal"]),
    maxRows: PropTypes.number,
    metadata: PropTypes.string,
    minRows: PropTypes.number,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rows: PropTypes.number,
    select: PropTypes.bool,
    SelectProps: PropTypes.object,
    size: PropTypes.oneOf(["medium", "small"]),
    sx: PropTypes.object,
    type: PropTypes.string,
    value: PropTypes.any,
    variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
}

TextField.defaultProps = {
    autoComplete: undefined,
    autoFocus: false,
    classes: {},
    color: "primary",
    defaultValue: undefined,
    disabled: false,
    error: false,
    FormHelperTextProps: undefined,
    fullWidth: false,
    helperText: undefined,
    id: undefined,
    InputLabelProps: undefined,
    inputProps: undefined,
    InputProps: undefined,
    inputRef: undefined,
    label: undefined,
    margin: "dense",
    maxRows: undefined,
    metadata: "undefined",
    minRows: undefined,
    multiline: false,
    name: undefined,
    onChange: undefined,
    placeholder: undefined,
    required: false,
    rows: undefined,
    select: false,
    SelectProps: undefined,
    size: "medium",
    sx: undefined,
    type: undefined,
    value: undefined,
    variant: "standard",
}

export default withStyles(
    (theme) => ({
        ...layoutStyles(theme),
        ...styles(theme),
    }),
    { withTheme: true }
)(TextField); 