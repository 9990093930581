import React from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import MuiSwitch from "@mui/material/Switch";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";

/*
 *   Creates a MUI Switch.
 *   @Params
 *
 *   @Return Html & Javascript component
 *   @Author Llew
 */

function UserAvatar(props) {
  let { theme, user, size, sx } = props;
  const { users } = useSelector((state) => state.onlineUsers);
  let online = false;
  if (user?.id) {
    online = !!users?.find((userToFind) => userToFind?.id === user?.id);
  }
  if (user?.email_address) {
    online = !!users?.find(
      (userToFind) => userToFind?.email_address === user?.email_address
    );
  }
  const style = {
    width: `${size}px!important`,
    height: `${size}px!important`,
    backgroundColor: theme.palette.tabs.secondary,
    borderColor: online ? theme.palette.success.main : theme.palette.tabs.main,
    borderStyle: "solid",
    borderWidth: `${size * 0.1}px!important`,
    fontSize: `${size * 0.4}px!important`,
    ...sx,
  };

  //get the first and last initial of any words in the name and capitalise them only use the first and last
  const initials = user?.name
    ?.split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase()
    .substring(0, 2);

  return !user ? (
    <Avatar sx={{ ...style }} />
  ) : user.avatar ? (
    <Avatar src={user.avatar} sx={{ ...style }} />
  ) : (
    <Avatar sx={{ ...style }}> {initials} </Avatar>
  );
}

export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(UserAvatar);
