import PropTypes from 'prop-types'
import SSGFormCondition from 'components/SSGForm/SSGFormCondition'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AutoComplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const SSgFormDropDown = ({ field, handleChange, formValues, defaultValue }) => {
  const { name = '', options = [], value = '', placeholder, label, required = false } = field
  // console.log(defaultValue)
  // console.log(formValues)
  // console.log({ 'value': value, 'default': defaultValue, 'id': field.identifier })

  let currentValue = ''

  if (value !== '') {
    currentValue = value
  }
  else if (defaultValue !== '') {
    currentValue = defaultValue
  }


  if (currentValue === '-') {
    currentValue = null
  }

  return (
    <>
      {/* <Select
        name={name}
        variant="standard"
        // value={formValues[name] ?? value ?? defaultValue}
        onChange={(e) => handleChange(e.target.value)}
        fullWidth
        sx={{ color: 'white' }}
        required={required}
      >
        {
          options.length > 0 &&
          options.map((option, index) => {
            return (
              <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
            )
          })
        }
      </Select > */}
      <AutoComplete
        // disablePortal
        name={name}
        defaultValue={currentValue}
        onChange={(e, value) => {
          // console.log('onChange', value)
         // handleChange(field.identifier, value.value)

         const selectedValue = value ? value.value : null;
         handleChange(field.identifier, selectedValue);
          
        }}
        options={options}
        fullWidth
        sx={{ color: 'white' ,  marginBottom:1,  }}
        renderInput={(params) => <TextField {...params} 
        label={`${label}${field.required ? ' *' : ''}`} //  
        />}
        required={required}
        
      />
    </>
  )
}

// RenderObjects.propTypes = {
//   field: PropTypes.object,
//   handleChange: PropTypes.func,
//   formValues: PropTypes.object
// }

// RenderObjects.defaultProps = {
//   field: {
//     name: '',
//     value: '',
//     options: []
//   },
//   handleChange: () => { },
//   formValues: {}
// }

// const SSgFormDropDown = ({ field, handleChange, formValues }) => {
//   return (
//     <SSGFormCondition field={field} formValues={formValues}>
//       <RenderObjects field={field} handleChange={handleChange} formValues={formValues} />
//     </SSGFormCondition>
//   )
// }

// SSgFormDropDown.propTypes = {
//   field: PropTypes.object,
//   handleChange: PropTypes.func,
//   formValues: PropTypes.object
// }

// SSgFormDropDown.defaultProps = {
//   field: {
//     name: '',
//     value: '',
//     options: []
//   },
// handleChange: () => { },
//   formValues: { }
// }

export default SSgFormDropDown