/**
 * i18n.js
 */
import en from "./translations/en";
import fr from "./translations/fr";
import pr from "./translations/pr";
import bl from "./translations/bl";
import nl from "./translations/nl";
import de from "./translations/de";
import it from "./translations/it";
import es from "./translations/es";
import pl from "./translations/pl";
import th from "./translations/th";
export const enTranslationMessages = en();
export const frTranslationMessages = fr();
export const prTranslationMessages = pr();
export const blTranslationMessages = bl();
export const nlTranslationMessages = nl();
export const deTranslationMessages = de();
export const itTranslationMessages = it();
export const esTranslationMessages = es();
export const plTranslationMessages = pl();
export const thTranslationMessages = th();

export const DEFAULT_LOCALE = "en";

// prettier-ignore
export const appLocales = [
  'en',
  'fr',
  'pr',
  'bl',
  'nl',
  'de',
  'it',
  'es',
  'pl',
  'th',

];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(
          DEFAULT_LOCALE,
          enTranslationMessages,
          frTranslationMessages,
          prTranslationMessages,
          blTranslationMessages,
          nlTranslationMessages,
          deTranslationMessages,
          itTranslationMessages,
          esTranslationMessages,
          plTranslationMessages,
          thTranslationMessages,
        )
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages("en", enTranslationMessages),
  fr: formatTranslationMessages("fr", frTranslationMessages),
  pr: formatTranslationMessages("pr", prTranslationMessages),
  bl: formatTranslationMessages("bl", blTranslationMessages),
  nl: formatTranslationMessages("nl", nlTranslationMessages),
  de: formatTranslationMessages("de", deTranslationMessages),
  it: formatTranslationMessages("it", itTranslationMessages),
  es: formatTranslationMessages("es", esTranslationMessages),
  pl: formatTranslationMessages("pl", plTranslationMessages),
  th: formatTranslationMessages("th", thTranslationMessages),
};
