import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTheme: "manage",
};

export const theme = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      state.currentTheme = action.payload;
    },
  },
});

export const { changeTheme } = theme.actions;

export default theme.reducer;
