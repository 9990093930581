import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import useTranslation from "containers/App/Hooks/useTranslation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/MuiOverride/Button";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Menu, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import Translation from "utils/translation";
import { useDebouncedValue } from '@mantine/hooks'
import { current } from "immer";
import { validateMessage } from "graphql-ws";
import { set, findIndex } from "lodash";
import { _ } from "core-js";
import View from 'components/DataTable/components/View';
import { DataGridPro, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';
import { useMutation, gql, resetApolloContext, resetCaches } from "@apollo/client"
import { useSelector } from "react-redux";
import CreateContact from "containers/Pages/CreateContact";
import organization from 'graphql/organization';
import { useLazyQuery } from "@apollo/client";
import { Card, CardContent } from "@mui/material";

function Toolbar(props) {
  const {
    classes,
    viewArray,
    handleAddView,
    currentView,
    setCurrentView,
    setShowFilters,
    toolbar,
    currentSearch,
    handleSearch,
    handleDeleteView,
    apiRef,
    handleRenameView,
    handleUpdateView,
    selectedCheckboxRows
  } = props;

  const { translate } = useTranslation();
  const [searchValue, setSearchValue] = useState(currentSearch);
  const [debounced] = useDebouncedValue(searchValue, 400);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (handleSearch) {
      handleSearch(debounced);
    }
  }, [debounced])

  // Button props
  const exportButtonProps = {
    color: "primary",
    size: "small",
    startIcon: <FontAwesomeIcon icon={["fas", "file-export"]} fixedWidth />,
  };
  const filterButtonProps = {
    color: "primary",
    size: "small",
    startIcon: <FontAwesomeIcon icon={["fas", "filter"]} fixedWidth />,
  };
  const saveButtonProps = {
    color: "primary",
    size: "small",
    startIcon: <FontAwesomeIcon icon={["fas", "floppy-disk"]} fixedWidth />,
  };


  const [quickClose, setQuickClose] = useState(false)
  const [quickCloseReason, setQuickCloseReason] = useState('')
  const [addContact, setAddContact] = useState(false)
  const openAddContact = () => setAddContact(true);
  const closeAddContact = () => setAddContact(false);

  const gqlMutation = gql`
  mutation MyMutation($comment: [ticket_comment_insert_input!]!, $ticket: ticket_set_input = {}, $where: ticket_bool_exp = {}) {
    insert_ticket_comment(objects: $comment) {
      affected_rows
    }
    update_ticket(_set: $ticket, where: $where) {
      affected_rows
    }
  }`

  const [mutation, { data: quickCloseData, loading: quickCloseLoading, error: quickCloseError }] = useMutation(gqlMutation, {
    variables: {
      that: 'this'
    },
  });

  useEffect(() => {
    setQuickCloseReason('')
    // reset the mutation
    resetApolloContext()
    resetCaches()
  }, [quickClose])

  const handleCloseQuickClose = () => {
    setQuickClose(false)
    setQuickCloseReason('')
    resetApolloContext()
    resetCaches()
  }

  const handleQuickCloseSubmit = () => {
    let ticketIdsArray = []
    let newComments = []
    selectedCheckboxRows.forEach((ticket) => {
      ticketIdsArray.push(ticket.id)
      let comment = {
        ticket_id: ticket.id,
        account_id: user?.account_id,
        agent: true,
        public: false,
        author_id: user?.id,
        created_at: 'now()',
        updated_at: 'now()',
        source: {
          data: {
            subject: 'Quick Resolve',
            body_preview: quickCloseReason,
            body: `<h3>This ticket has been resolved by the quick resolve feature</h3><h4>Quick Resolve Reason :- </h4><p>${quickCloseReason}</p>`,
            type_id: '2d67d487-aaa7-4d3e-a610-95eac278687f',
          }
        }
      }
      newComments.push(comment)
    })
    let valuables = {
      comment: [
        ...newComments
      ],
      ticket: {
        status_id: 'c7dc5b89-e3cd-41ec-baf1-d7d43d079725',
      },
      where: {
        id: {
          _in: ticketIdsArray
        }
      }
    }

    mutation({ variables: valuables })
  }



  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  // getting the list of organizations for the autocomplete
  const { GET_ORGANIZATIONS } = organization();
  const [
    getOrganizations,
    { loading: loadingOrganizations, error: errorOrganizations, data: dataOrganizations },
  ] = useLazyQuery(GET_ORGANIZATIONS);


  useEffect(() => {
    getOrganizations();
  }, []);

  let orgNames = [];
  for (let i = 0; i < dataOrganizations?.organization.length; i++) {

    orgNames.push(dataOrganizations?.organization[i]?.name);
  }


  return (
    <>
      {toolbar && Object.keys(toolbar).length > 0 && (
        <Box
          className={classes.toolbar}
        >
          {toolbar?.search === true && (
            <TextField style={{ width: '30%' }} label={Translation("TicketViewPage.toolbar.label")} variant="outlined" InputProps={{
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value),
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon
                    icon={["fas", "magnifying-glass"]} />
                </InputAdornment>
              ),
            }} />

          )}

          {toolbar?.views === true && viewArray.length > 0 && (
            <View
              viewArray={viewArray}
              currentView={currentView}
              setCurrentView={setCurrentView}
              handleAddView={handleAddView}
              handleRenameView={handleRenameView}
              handleDeleteView={handleDeleteView}
              handleUpdateView={handleUpdateView}
            />


          )}
          <Box sx={{ marginLeft: 'auto' }} />

          {selectedCheckboxRows.length > 0 && (
            <>
              <Modal
                open={quickClose}
                onClose={() => setQuickClose(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
              >
                <>





                  <Box
                    sx={{
                      ...style,
                      width: `80%`,
                    }}
                  >
                    <h1>{Translation("TicketViewPage.toolbar.quickClose.model.title")}</h1>
                    {!quickCloseLoading && !quickCloseError && quickCloseData && (
                      <>
                        <h2>{Translation("TicketViewPage.toolbar.quickClose.model.finished")}</h2>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: `row`,
                            marginTop: `16px`,
                            gap: '20px'
                          }}
                        >
                          <Button
                            sx={{
                              width: `35%`,
                            }}
                            onClick={() => {
                              handleCloseQuickClose()
                            }}
                          >{Translation("TicketViewPage.toolbar.quickClose.closeButton")}</Button>
                        </Box>
                      </>
                    )}
                    {quickCloseLoading && (
                      <h2>{Translation("TicketViewPage.toolbar.quickClose.model.updating")}</h2>
                    )}
                    {quickCloseError && !quickCloseLoading && (
                      <>
                        <h2>{Translation("TicketViewPage.toolbar.quickClose.model.error")}</h2>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: `row`,
                            marginTop: `16px`,
                            gap: '20px'
                          }}
                        >
                          <Button
                            sx={{
                              width: `35%`,
                            }}
                            onClick={() => {
                              handleCloseQuickClose()
                            }}
                          >{Translation("TicketViewPage.toolbar.quickClose.closeButton")}</Button>
                        </Box>
                      </>
                    )}
                    {!quickCloseLoading && !quickCloseError && !quickCloseData && (
                      <>
                        <h2>{Translation("TicketViewPage.toolbar.quickClose.model.subTitle", { count: selectedCheckboxRows?.length ?? 0 })}</h2>
                        <DataGridPro
                          rows={selectedCheckboxRows}
                          columns={[
                            { field: 'alias', headerName: 'Alias', width: 150 },
                            { field: 'subject', headerName: 'Subject', flex: 1 },
                            { field: 'requester', headerName: 'Requester', width: 150 },
                            { field: 'status', headerName: 'Status', width: 150 },
                          ]}
                          density="compact"
                          // hideFooter={true}
                          sx={{
                            maxHeight: '250px',
                          }}
                        />
                        <h3>{Translation("TicketViewPage.toolbar.quickClose.model.reason")}</h3>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          variant="outlined"
                          onChange={(e) => {
                            setQuickCloseReason(e.target.value)
                          }}
                          placeholder={Translation("TicketViewPage.toolbar.quickClose.model.reasonPlaceholder")}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flexDirection: `row`,
                            marginTop: `16px`,
                            gap: '20px'
                          }}
                        >


                          <Button
                            sx={{
                              width: `35%`,
                            }}
                            onClick={() => {
                              handleCloseQuickClose()
                            }}
                          >{Translation("TicketViewPage.toolbar.quickClose.cancelButton")}</Button>
                          <Button
                            sx={{
                              width: `35%`,
                            }}
                            color="primary"
                            variant="contained"
                            disabled={quickCloseReason.length > 3 ? false : true}
                            onClick={() => {
                              handleQuickCloseSubmit()
                            }}
                          >{Translation("TicketViewPage.toolbar.quickClose.submitButton")}</Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              </Modal>
              <Tooltip title={Translation("TicketViewPage.toolbar.label.quickClose")}>
                <FontAwesomeIcon
                  style={{ marginLeft: '16px' }}
                  cursor="pointer"
                  onClick={() => {
                    // console.log(selectedCheckboxRows)
                    setQuickClose(true)
                  }}
                  size="xl"
                  icon={["fas", "circle-xmark"]} fixedWidth />
              </Tooltip>
            </>
          )}
          {toolbar?.filters === true && (
            <Tooltip title={Translation("TicketViewPage.toolbar.label.filters")}>
              <FontAwesomeIcon
                style={{ marginRight: '16px' }}
                cursor="pointer"
                onClick={() =>
                  setShowFilters()
                }
                icon={["fas", "filter"]} fixedWidth
                size="xl" />
            </Tooltip>
          )}
          {toolbar?.newUser === true && (
            <Tooltip title={Translation("TicketViewPage.toolbar.label.addContact")}>
              <FontAwesomeIcon
                style={{ marginLeft: '16px' }}
                cursor="pointer"
                onClick={() =>
                  openAddContact()
                }
                icon={["fas", "user-plus"]} fixedWidth
                size="xl"
              />
            </Tooltip>
          )}
          <Modal
            open={addContact}
            onClose={closeAddContact}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
            style={{
              top: '15%', width: '70%', left: '15%'
            }}>

            <CreateContact />

          </Modal>
          {toolbar?.export === true && (
            <GridToolbarExport
              apiRef={apiRef}
              printOptions={{
                disableToolbarButton: true,
              }}
            />
          )}
          <Box
            sx={{
              marginLeft: '16px'
            }}>
            <GridToolbarDensitySelector
              apiRef={apiRef}
            />
          </Box>
        </Box >
      )
      }
    </>
  );
};


export default withStyles(
  (theme) => ({
    ...styles(theme),
  }),
  { withTheme: true }
)(Toolbar);
