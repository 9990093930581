import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SSGFormCondition from 'components/SSGForm/SSGFormCondition'
import TextInput from 'components/Global/TextInput'
import { TextField } from '@mui/material'
import { useDebouncedValue } from '@mantine/hooks'
import { faMicrophoneLines } from '@fortawesome/pro-regular-svg-icons'

const SSGFormTextInput = ({ field, handleChange, currentValue, multiline = false, minlines = 1 }) => {
  const { name, label, placeholder, required, rows, section_text, value } = field

  // console.log(field.value)

  // console.log(ref)

  const [inputValue, setInputValue] = React.useState(currentValue ?? value)
  const [inputValueTemp, setInputValueTemp] = React.useState(currentValue ?? value)
  const [debounced] = useDebouncedValue(inputValueTemp, 500)

  useEffect(() => {
    if (debounced) {
      setInputValue(debounced)
    }
  }, [debounced])

  useEffect(() => {
    if (inputValue !== currentValue ?? value) {
      handleChange(inputValue)
    }
  }, [inputValue])

  if (name) {
    return (
      <TextField
        // inputRef={ref}
        label={label ?? ''}
        name={name}
        defaultValue={currentValue ?? value}
        required={required}
        placeholder={placeholder}
        onChange={(e) => {
          if (e.target.value === '') {
            handleChange("")
          }
          setInputValueTemp(e.target.value)
        }}
        multiline={multiline}
        minRows={minlines}
        rows={rows}
        sx={{ width: '100%', margin: '5px', background: 'white', borderRadius: 3 }}
      />
    )
  } else {
    // console.log(field)
    let textBoxText
    if (label !== "") {
      textBoxText = label
    } else if (section_text !== "") {
      textBoxText = section_text
    } else {
      textBoxText = 'Not Found'
    }

    return (
      <div dangerouslySetInnerHTML={{ __html: textBoxText }}></div>
    )
  }
}

// RenderObjects.propTypes = {
//   field: PropTypes.object,
//   handleChange: PropTypes.func,
//   formValues: PropTypes.object
// }

// RenderObjects.defaultProps = {
//   field: {
//     name: '',
//     label: '',
//     placeholder: '',
//     required: false,
//     multiline: false,
//     rows: 1
//   },
//   handleChange: () => { },
//   formValues: {}
// }


// SSGFormTextInput.propTypes = {
//   field: PropTypes.object,
//   handleChange: PropTypes.func,
//   formValues: PropTypes.object
// }

// SSGFormTextInput.defaultProps = {
//   field: {
//     name: '',
//     label: '',
//     placeholder: '',
//     required: false,
//     multiline: false,
//     rows: 1
//   },
//   handleChange: () => { },
//   formValues: {}
// }

export default SSGFormTextInput