import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Translation from 'utils/translation';


const Summary = ({ company }) => {

    return (

<Box
sx={{
  backgroundColor: 'white !important',
  padding: 2,
  borderRadius: 2,
  boxShadow: 2,
  height: 'auto',
}}
>
<Grid container spacing={2}>
  <Grid item xs={3}>
    <Typography variant='h6' align='center'>
      {Translation('companyContactPage.tabs.overview.region')}
    </Typography>
    <Typography padding={1} align='center'>
     {company?.company?.organization?.[0]?.locations?.[0]?.country?.name || "Not Set"}
    </Typography>
  </Grid>
  <Grid item xs={3}>
    <Typography variant='h6' align='center'>
      {Translation('companyContactPage.tabs.overview.customerStatus')}
    </Typography>
    <Typography padding={1} align='center'>
     {company?.company?.organization?.[0]?.active ? 'Active' : 'Inactive'}
    </Typography>
  </Grid>
  <Grid item xs={3}>
    <Typography variant='h6' align='center'>
      {Translation('ticketViewPage.createdAt')}
    </Typography>
    <Typography padding={1} align='center'>
     {company?.company?.organization?.[0]?.account?.created_at.split('T')[0]}
    </Typography>
  </Grid>
  <Grid item xs={3}>
    <Typography variant='h6' align='center'>
      {Translation('companyContactPage.tabs.overview.company')}
    </Typography>
    <Typography padding={1} align='center' sx={{ color: '#3F76B6' }}>
      {company?.company?.organization?.[0]?.name}
    </Typography>
  </Grid>
</Grid>
</Box>
    )
}

export default Summary;