import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { Box, Button, Stack, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import Translation from "utils/translation";
import {
    SidePanelBoldTitle,
    SidePanelItemCard,
    SidePanelParagraph,
} from "containers/Pages/TicketViewPage/components/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import useWarningNotification from "utils/useWarningNotification";
import { useNavigate } from 'react-router-dom';



function ContactsModal(props) {

    const { Edituser, user } = props;
    const navigate = useNavigate();


    const userEmail = user?.person?.[0]?.email_address;
    // const nameToDisplay = Edituser?.name || user?.person?.name;
    const orgID = user?.person?.[0]?.organization?.id;

    const [showWarningNotification] = useWarningNotification();
    // function to copy email to clipboard
    const copyToClipboard = (userEmail) => {
        navigator.clipboard.writeText(userEmail);
        showWarningNotification({
            message: `Copied ${userEmail} to clipboard`,
            title: "Copied to clipboard",
        });
    };

    const handleNavigate = () => {
        navigate(`/admin/company/${orgID}`);
    }

    const fullPhoneNumber = user?.person?.[0]?.phone_numbers?.[0]?.phone_number?.country?.dialing_prefix + ' ' + user?.person?.[0]?.phone_numbers?.[0]?.phone_number?.number;
    return (
        <>
            <SidePanelItemCard>
                <Box sx={{ width: '100%', }}>
                    <Typography variant="h6"
                        onClick={() => { }}
                        style={{ color: '#3F76B6', cursor: 'pointer' }}

                    >{Edituser?.name || user?.person?.[0].name}</Typography>
                    <Typography variant="h6" style={{ paddingTop: 1 }} >{user?.person?.[0]?.organization?.name}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 5 }} />
                        <Typography style={{ color: '#6196C7', paddingTop: 1, paddingLeft: 1, cursor: "pointer" }} onClick={() => copyToClipboard(userEmail)} >{userEmail}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faPhone} style={{ marginRight: 5 }} />
                        {user?.person?.[0]?.phone_numbers?.length > 0 ? (
                            <>  <Typography
                                onClick={() => copyToClipboard(fullPhoneNumber)}
                                sx={{ cursor: "pointer" }}
                            >
                                {fullPhoneNumber}
                            </Typography >
                            </>
                        ) : (
                            <Typography style={{ paddingTop: 1, paddingLeft: 1 }}>
                                No phone number set
                            </Typography>
                        )}

                    </Box>
                </Box>
            </SidePanelItemCard>

            <Typography color="#838383"  >{Translation("companyContactPage.tabs.sidePanel.company")}</Typography>


            <SidePanelItemCard>
                <Box sx={{ width: '100%' }}>
                    <Typography
                        style={{ color: '#3F76B6', fontWeight: 'bold', cursor: "pointer" }}
                        onClick={handleNavigate}
                    >
                        {user?.person?.[0]?.organization?.name}
                    </Typography>

                    {user?.organization_child?.map((child, index) => (
                        <Typography key={index} style={{ fontWeight: 'bold' }}>{child.name}</Typography>
                    ))}
                </Box>
            </SidePanelItemCard>







        </>
    );
}

export default ContactsModal;