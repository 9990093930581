import React from 'react';
import { withStyles } from "@mui/styles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import { Box, Button, Card, Checkbox, Grid, Stack } from "@mui/material";
import TicketCommentContainer from "containers/Pages/TicketViewPage/containers/TicketCommentContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from '@mui/material/useMediaQuery';
import Translation from 'utils/translation';
import { useNavigate } from 'react-router-dom';


function MasterDetailTitle(props) {
    const {
        subject = "Unknown Subject",
        organization = "Unknown Organization",
        created_at = new Date(),
        width,
        data
    } = props
    return (
        <Box
            sx={{
                width: width ?? "100%",
                backgroundColor: "#103F65",
                color: "#FFFF",
                padding: 2,
            }}
        >
            <Tooltip title={subject}>
                <Typography variant="h5" noWrap>
                    {subject}
                </Typography>
            </Tooltip>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="subtitle1">
                    {data.row.requester && data.row.requester.organization && data.row.requester.organization.name !== null && data.row.requester.organization.name !== undefined ? (
                        data.row.requester.organization.name
                    ) : (
                        "Organization not found"
                    )}
                </Typography>
                <Typography variant="subtitle1">
                    {Translation("ticketViewPage.masterDetail.createdAt")}{` `}
                    <Moment date={created_at} fromNow />
                </Typography>
            </Box>
        </Box>
    )
}

function MasterDetailEnginnerDevices(props) {
    const { classes, assignee = "Unassigned", created_at = new Date(), contacts, data } = props
   // console.log('***********', data)
    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" fontWeight="500">
                    <FontAwesomeIcon icon="user-gear" color="#193154" />
                </Typography>
                <Typography variant="h6" fontWeight="500" sx={{ marginLeft: 2 }}>
                    {Translation("ticketViewPage.masterDetail.assignedMember")}
                </Typography>
            </Box>
            <Box sx={{ marginTop: 1 }}>
                <Typography variant="subtitle1" onClick={() => { }}
                    style={{ color: '#3F76B6', cursor: 'pointer' }}>
                    {assignee}
                </Typography>
            </Box>

            <Box sx={{ marginTop: 6 }}>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" fontWeight="500">
                        <FontAwesomeIcon icon="users" color="#193154" />
                    </Typography>
                    <Typography variant="h6" fontWeight="500" sx={{ marginLeft: 2 }}>
                        {Translation("ticketViewPage.masterDetail.ticketContacts")}
                    </Typography>
                </Box>

                <Typography variant="subtitle1" onClick={() => { }}
                    style={{ color: '#3F76B6', cursor: 'pointer' }}>
                    {/* insert contacts here */}

                    {data.row.requester && data.row.requester.name !== null && data.row.requester.name !== undefined ? (
                        data.row.requester.name
                    ) : (
                        "Contact not found"
                    )}
                </Typography>
            </Box>

            <Box sx={{ marginTop: 6, display: 'flex' }}>
                <Typography variant="h6" fontWeight="500">
                    <FontAwesomeIcon icon="calendar" color="#193154" />
                </Typography>
                <Typography variant="h6" fontWeight="500" sx={{ marginLeft: 3 }}>
                    {Translation("ticketViewPage.masterDetail.ticketCreatedAt")}
                </Typography>
            </Box>
            <Typography variant="subtitle1">

                {data.row.created_at}
            </Typography>

        </Box>
    )
}

function MasterDetailSLA(props) {
    const { classes } = props
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box

                sx={{
                    backgroundColor: "#4FCF83", width: 100, height: 100, borderRadius: 2, marginLeft: 2, color: 'white'
                }}
            >
                <Typography variant="h6" align="center">
                    Update In
                </Typography>
                <Typography variant="h6" align="center">
                    2h
                </Typography>
            </Box>
            <Box

                sx={{
                    width: 100,
                    height: 100,
                    backgroundColor: "#3F76B6",
                    borderRadius: 2,
                    marginLeft: 4,
                    color: 'white'
                }}
            >
                <Typography variant="h6" align="center">
                    Related Tickets
                </Typography>
                <Typography variant="h6" align="center">
                    2
                </Typography>
            </Box>
        </Box>
    )
}

function MasterDetailComments(props) {
    const { classes, data } = props

    const htmlString = data?.row?.comments[data?.row?.comments.length - 1]?.source?.body ?? null;

    
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const strippedString = doc.body.textContent || '';
    
   // console.log(strippedString);
    return (
        <>
           {data?.row?.comments?.length > 0 ? (
  <>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon="clock" color="#193154" />
      <Typography variant="h6" fontWeight="500" sx={{ marginLeft: 2 }}>
        {Translation("ticketViewPage.masterDetail.mostRecentActivity")}
      </Typography>
    </Box>

    <Box sx={{ marginTop: 5, border: '1px solid', padding: 2, width: '80%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon icon="exclamation" color="#193154" />
        <Typography variant="h6" fontWeight="300" sx={{ marginLeft: 2, color: '#103F65' }}>
          {Translation("ticketViewPage.masterDetail.ticketUpdatedBy")}
        </Typography>
        <Typography
          variant="h6"
          fontWeight="500"
          onClick={() => { }}
          sx={{ marginLeft: 0.5, color: '#103F65', cursor: 'pointer' }}
        >
         {data?.row?.comments[data?.row?.comments.length - 1]?.source?.sender?.name ?? null}
        </Typography>
        <Box sx={{ ml: 'auto' }}>
          <Typography fontWeight="300" sx={{ color: '#3C3C3CD9' }}>
          {data?.row?.comments[data?.row?.comments.length - 1]?.created_at ?? null}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography fontWeight="300" sx={{ marginLeft: 2, color: '#103F65' }}>
        {/* {data?.row?.comments[data?.row?.comments.length - 1]?.source?.body ?? null} */}
        {strippedString}
        </Typography>
      </Box>
    </Box>
  </>
) : (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <FontAwesomeIcon icon="clock" color="#193154" />
    <Typography variant="h6" fontWeight="500" sx={{ marginLeft: 2 }}>
      {Translation("ticketViewPage.masterDetail.noRecentActivity")}
    </Typography>
  </Box>
)}



        </>

    )
}

function MasterDetailButtons(props) {
    const { classes, data, } = props
   // console.log('******ticket number*****', data.row.alias)
    const navigate = useNavigate();

    // const ticketUrl = data.row.alias;

    const ticketUrl = `/support/tickets/${data.row.alias}`;

    const handleViewTicketClick = () => {

        navigate(ticketUrl);

    }
    return (
        <>
            <Box position="absolute" bottom={90} right={150} textAlign="center" p={2}>
                <Stack spacing={2}>
                    <Button
                        sx={{
                            color: 'white', border: "1px solid", borderRadius: 2, backgroundColor: "#2A203B", display: 'flex', alignItems: 'center', padding: 2, '& span': {
                                color: 'white',
                            }, '&:hover': {
                                backgroundColor: "#2A203B",
                                textDecoration: 'none',
                            },
                        }}
                        onClick={handleViewTicketClick}>


                        <span style={{ color: 'white' }}>{Translation("ticketViewPage.masterDetail.viewTicket")}</span>
                        <FontAwesomeIcon icon={faArrowCircleRight} style={{ color: '#6196C7', marginLeft: 4, }} />
                    </Button>
                    <Button
                        sx={{ border: "1px solid", borderRadius: 2, display: 'flex', alignItems: 'center', padding: 2, }}
                        onClick={() => { }}

                    >{Translation("ticketViewPage.masterDetail.quickNote")}
                        <FontAwesomeIcon icon={faArrowCircleRight} style={{ color: '#6196C7', marginLeft: 4, }} />
                    </Button>
                </Stack>
            </Box>
        </>

    )
}

function MobileView(props) {
    const { row, classes, width = '99%' } = props
    return (
        <>
            <Box sx={{ width: '30%', p: 2 }}>
                <Box sx={{}}>
                    <MasterDetailTitle
                        subject={row?.subject}
                        organization={row?.organization}
                        created_at={row?.created_at}
                        data={props}
                    />
                </Box>

                <Box sx={{ marginTop: 4, }}>
                    <MasterDetailSLA />
                </Box>

                <Box sx={{ marginTop: 4, }}>
                    <MasterDetailEnginnerDevices
                        classes={classes}
                        assignee={row?.assignee?.name ?? null}
                        data={props}
                    />
                </Box>

                <Box sx={{ marginTop: 4, width: '100%' }}>
                    <MasterDetailComments
                        data={props} />
                </Box>

                <Box sx={{ marginTop: 4, ml: 'auto', display: 'flex', }}>
                    <Button
                        sx={{
                            color: 'white', border: "1px solid", borderRadius: 2, backgroundColor: "#3F76B6", display: 'flex', alignItems: 'center', padding: 2, '& span': {
                                color: 'white',
                            }, '&:hover': {
                                backgroundColor: "#3F76B6",
                                textDecoration: 'none',
                            },
                        }}
                        onClick={() => { }}>


                        <span style={{ color: 'white' }}>{Translation("ticketViewPage.masterDetail.viewTicket")}</span>
                        <FontAwesomeIcon icon={faArrowCircleRight} style={{ color: '#6196C7', marginLeft: 4, }} />
                    </Button>
                    <Button
                        sx={{
                            color: 'white', border: "1px solid", borderRadius: 2, display: 'flex', alignItems: 'center', padding: 2, marginLeft: 3, backgroundColor: "#3F76B6", '& span': {
                                color: 'white',
                            }, '&:hover': {
                                backgroundColor: "#3F76B6",
                                textDecoration: 'none',
                            }
                        }}
                        onClick={() => { }}

                    ><span style={{ color: 'white' }}>{Translation("ticketViewPage.masterDetail.quickNote")}</span>
                        <FontAwesomeIcon icon={faArrowCircleRight} style={{ color: '#6196C7', marginLeft: 4, }} />
                    </Button>
                </Box>
            </Box>
        </>
    );
}


function MasterDetail(props) {
    const { row, classes, width = '99%' } = props
    const isPhoneSize = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    // temp until we get the real view ticket function
    const viewTicket = (data) => {
      //  console.log(data)
    }

    return (
        <>
            {isPhoneSize ? (
                // show phone view
                <MobileView />
            ) : (
                <Box sx={{ width: '100%' }}>
                    {/* Top Title bar containing Subject, Organization and Created at Time */}
                    <MasterDetailTitle
                        subject={row?.subject}
                        organization={row?.organization}
                        created_at={row?.created_at}
                        data={props}
                    />
                    {/* Top Section containing Engineer, Devices and SLA */}
                    <Box sx={{ marginTop: 4, marginLeft: 4 }}>

                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={6} md={4} >
                                <MasterDetailEnginnerDevices
                                    classes={classes}
                                    assignee={row?.assignee?.name ?? null}
                                    data={props}
                                />

                            </Grid>

                            <Grid item xs={12} sm={6} md={6} >

                                <MasterDetailComments
                                    data={props} />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} >
                                <MasterDetailButtons data={props} />
                            </Grid>

                        </Grid>

                    </Box>

                </Box>
            )}
        </>
    );

}

export default withStyles(
    (theme) => ({
        ...layoutStyles(theme),
        ...styles(theme),
    }),
    { withTheme: true }
)(MasterDetail);
