const styles = (theme) => ({
    infoBoxContainer: {
        display: "flex",
        flexDirection: "row",
        padding: 4,
        color: "#FFFF",
        // alignSelf: "right",
        width: "50%",
        justifyContent: "space-evenly",
    },
    infoBox: {
        height: 120,
        width: 120,
        borderRadius: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 2,
    },
    ticketDetailsContainer: {
        width: "50%",
        padding: 4,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    ticketDetails: {
        color: "#000000",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: 2,
        alignItems: "center",
    },
    ticketDetailTitle: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
    },
});

export default styles;
