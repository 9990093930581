import { cloneDeep, findIndex, get, pullAt, set } from 'lodash';
import { gql } from '@apollo/client';
import { EnumType, jsonToGraphQLQuery } from 'json-to-graphql-query';

const changeFilterValue = (queryToChange, searchQuery, filters, tableName, sortOptions = null) => {
    // Create a deep copy of the query
    const newQuery = cloneDeep(queryToChange);

    // Ensure the necessary objects and arrays exist
    if (!newQuery.query) {
        newQuery.query = {};
    }
    if (!newQuery.query[tableName]) {
        newQuery.query[tableName] = {};
    }
    if (!newQuery.query[tableName].__args) {
        newQuery.query[tableName].__args = {};
    }
    if (!newQuery.query[tableName].__args.where) {
        newQuery.query[tableName].__args.where = {};
    }
    if (!newQuery.query[tableName].__args.where._and) {
        newQuery.query[tableName].__args.where._and = [];
    }

    // Iterate over each filter and update the query
    let filterObject = [];

    if (filters?.items?.length > 0) {
        filters?.items?.forEach((filter) => {
            filterObject.push({
                [filter.field]: {
                    [filter.operator]: filter.value
                }
            });
        });
    }
    // Add the search query to the filters.
    // Concatenate all quickFilterValues with a space in between
    if (filters?.quickFilterValues?.length > 0) {
        const concatenatedValue = filters.quickFilterValues.join(' ').toLowerCase();
        let numericValue = parseFloat(concatenatedValue);
        let isNumeric = !isNaN(numericValue) && isFinite(numericValue);


        if (isNumeric) {
            console.log('isNumeric', isNumeric);
            // If the concatenated value is numeric, handle accordingly
            filterObject.push({
                _or: searchQuery?.numeric.map((query) => {
                    let filter = {};
                    switch (query.operator) {
                        case '_eq':
                            filter[query.key] = {
                                _eq: parseFloat(concatenatedValue)
                            };
                            break;
                        case '_ilike':
                            filter[query.key] = {
                                _ilike: `%${concatenatedValue}%`
                            };
                            break;
                        default:
                            break;
                    }
                    return filter;
                })
            });
        } else {
            filterObject.push({
                _or: searchQuery?.default.map((field) => {
                    let filter = {};
                    let keys = field?.key?.split('.');
                    let current = filter;

                    keys?.forEach((key, index) => {
                        if (index === keys.length - 1) {
                            // Insert concatenatedValue at the last key
                            switch (field.operator) {
                                case '_eq':
                                    current[key] = { _eq: parseFloat(concatenatedValue) };
                                    break;
                                case '_ilike':
                                    current[key] = { _ilike: `%${concatenatedValue}%` };
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            // Navigate or create intermediate objects
                            current[key] = current[key] || {};
                            current = current[key];
                        }
                    });
                    return filter;
                })
            });

        }
    }
    newQuery.query[tableName].__args.where._and = filterObject;


    // Iterate over each sort option and update the query
    if (sortOptions?.length > 0) {
        let sortObject = {
            [sortOptions[0].field]: new EnumType(sortOptions[0].sort)
        };
        newQuery.query[tableName].__args.order_by = sortObject;
    }

    return newQuery;
};

export default changeFilterValue;
