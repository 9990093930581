import { gql } from "@apollo/client";

const graphQLQueries = () => {
  const DELETE_NOTIFICATION = gql`
  mutation MyMutation($id: uuid = "") {
    update_notification_by_pk(pk_columns: {id: $id}, _set: {read: true, read_at: "now()"}) {
      read
      read_at
    }
  }`
  const GET_UNREAD_COUNT = gql`
    subscription GetNotifications($user: uuid, $organizations: [uuid!]) {
      notification_aggregate(
        where: {
          _or: [
            {
              _and: [
                { person_id: { _eq: $user } }
                { organization_id: { _is_null: true } }
              ]
            }
            {
              _and: [
                { person_id: { _is_null: true } }
                { organization_id: { _in: $organizations } }
              ]
            }
          ]
          read: { _eq: false }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
  const GET_NOTIFICATIONS = gql`
    subscription GetNotifications(
      $user: uuid
      $limit: Int = 100
      $organizations: [uuid!]
    ) {
      notification(
        where: {
          _or: [
            {
              _and: [
                { person_id: { _eq: $user } }
                { organization_id: { _is_null: true } }
              ]
            }
            {
              _and: [
                { person_id: { _is_null: true } }
                { organization_id: { _in: $organizations } }
              ]
            }
          ]
          read: { _eq: false }
        }
        order_by: { created_at: desc }

        limit: $limit
      ) {
        id
        body
        metadata
        title
        read
        read_at
        url
        priority {
          name
        }
        person {
          name
        }
        organization {
          name
        }
        icon
        created_at
        category {
          name
        }
        type {
          icon
          name
        }
      }
    }
  `;

  return {
    GET_NOTIFICATIONS,
    GET_UNREAD_COUNT,
    DELETE_NOTIFICATION
  };
};

export const graphQLMutations = () => {
  const INSERT_NOTIFICATION = gql`
      mutation INSERT_NOTIFICATION($notificationBody: String!, $notificationTitle: String!, $recipientID: uuid!, $notificationURL: String!) {
      insert_notification_one(
        object: {
          account_id: "92cf2939-e08a-4a03-908a-f4d136b6f1e0"
          body: $notificationBody
          priority_id: "f2b7cbf1-fd95-4811-a333-be8a77d91fb6"
          category_id: "06ebe260-8e38-4ce5-8f69-b55b17d3f7c4"
          created_at: "now"
          icon: "fa bell"
          person_id: $recipientID
          title: $notificationTitle
          type_id: "ca6a74fc-326d-46a3-b5ee-8182f74fea83"
          url: $notificationURL
        }
      ) {
        id
      }
}`;
  return {
    INSERT_NOTIFICATION
  }
}
export default graphQLQueries;
