import { useState, createContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

const People = createContext()

const PeopleProvider = ({ children, socketClient }) => {
    const { user } = useSelector(state => state.user);
    const { roles } = user ?? {};
    const isAgent = roles?.includes("admin") || roles?.includes("agent");
    const socket = socketClient
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isAgent) {
            socket.emit('websocket:GET_PEOPLE')
        } else {
            setLoading(false)
        }
    }, [user]);


    socket.on('websocket:GET_PEOPLE', (data) => {
        setPeople(data)
        setLoading(false)
    })

    return (
        <People.Provider value={{ people, loading, error }}>
            {children}
        </People.Provider>
    )
}

export { People, PeopleProvider };