import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
const defaultNotification = {
  notificationUnreadCount: 0,
  notifications: [],
};
const initialState = {
  user: {},
  userIsLoggingIn: false,
  userIsAuthenticated: false,
  authenticationErrorMessage: '',
  registrationErrorMessage: '',
  twoFactor: false,
  tokens: {
    token: null,
    refreshToken: null,
  },
  mssso: {},
  email: {
    inbox: [],
  },
  language: 'en',
  notifications: defaultNotification,
};



export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateMsssoError: (state, action) => {
      state.userIsLoggingIn = false;
      state.userIsAuthenticated = false;
      state.authenticationErrorMessage = action.payload.message;
    },
    setRedirectTo: (state, action) => {
      state.mssso.redirectTo = action.payload;
    },
    updateLanguage: (state, action) => {
      state.language = action.payload;
      // console.log(action);
    },
    updateFirstTimeSetup: (state, action) => {
      state.user.first_time_setup = false;
    },
    setTwoFactor: (state, action) => {
      state.twoFactor = action.payload;
    },
    updateMsssoTokens: (state, action) => {
      state.mssso.accessToken = action.payload.access_token;
      // state.mssso.idToken = action.payload.idToken
      state.mssso.refreshToken = action.payload.refresh_token;
      // state.mssso.extExpiresOn = action.payload.extExpiresOn
      state.mssso.expiresOn = action.payload.expires_on;
    },
    makeCheckLoginState: (state, action) => {
      // console.log("not set up yet");
    },
    setOnlineUsers: (state, action) => {
      state.onlineUsers = action.onlineUsers;
    },
    addTokens: (state, action) => {
      // console.log('adding tokens', action.payload);
      state.tokens = action.payload;
      let date = new Date();
      date.setHours(date.getHours() + 1);
      state.tokens.expiresAt = date;
    },
    debugJWT: (state, action) => {
      state.tokens.token = action.payload;
    },
    updateToken: (state, action) => {
      state.tokens.token = action.payload;
      let date = new Date();
      date.setHours(date.getHours() + 1);
      state.tokens.expiresAt = date;
    },
    signIn: (state, action) => {
      state.tokens.token = action.payload.token;
      let date = new Date();
      date.setHours(date.getHours() + 1);
      state.tokens.expiresAt = date;

      state.tokens.refreshToken = action.payload.refreshToken;
      state.userIsLoggingIn = true;
      state.authenticationErrorMessage = '';
    },
    signInSSO: (state, action) => {
      console.log(action.payload);
      state.tokens.token = action.payload.token;
      state.tokens.refreshToken = action.payload.refreshToken;
      let date = new Date();
      date.setHours(date.getHours() + 1);
      state.tokens.expiresAt = date;
      state.userIsLoggingIn = true;
      state.authenticationErrorMessage = '';
      state.mssso = action.payload.mssso ?? {};
      state.mssso.redirectTo = action.payload.redirectTo ?? '/';
    },
    userLoaded: (state, action) => {
      state.user = action.payload;
      state.userIsAuthenticated = true;
    },
    signInError: (state, action) => {
      state.authenticationErrorMessage = action.payload;
    },
    signOut: (state, action) => {
      // console.log(initialState)
      // console.log('in user')
      // console.log('signing out',socketClient)
      state.userIsAuthenticated = false;
      state.userIsLoggingIn = false;
      state.twoFactor = false;
      state.user = {};
      state.tokens = {
        token: null,
        refreshToken: null,
      };
      state.authenticationErrorMessage = '';
      state.mssso = {};
      state.notifications = defaultNotification;
      // console.log('before')
      window.localStorage.removeItem('persist:manage');
      window.localStorage.removeItem('persist:menu');

      // delete indexDB
      // console.log('deleting indexDB');




      //refresh the window after 5s
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      state.mssso.redirectTo = action.payload;
    },
    register: (state, action) => {
      // console.log("not set up yet");
    },
    resetPassword: (state, action) => {
      // console.log("not set up yet");
    },
    clearAuthenticationMessage: (state, action) => {
      state.authenticationErrorMessage = '';
    },
    clearTwoFactor: (state, action) => {
      state.twoFactor = false;
    },
    clearLoggingIn: (state, action) => {
      state.userIsLoggingIn = false;
    },
    setEmailInbox: (state, action) => {
      state.email.inbox = action.payload;
    },
    setAvatar: (state, action) => {
      state.user.avatar = action.payload;
    },
    updateNotificationUnreadCount: (state, action) => {
      state.notifications.notificationUnreadCount = action.payload;
    },
    setCurrentDataTableView: (state, action) => { },
    updateDatatableViews: (state, action) => {
      // const { table, view } = action.payload;
      // const updatedTable = state.user.datatable_views[table] || [];
      // const updatedViews = [...updatedTable, view];
      // state.user.datatable_views = {
      //   ...state.user.datatable_views,
      //   [table]: updatedViews,
      // };
    },
    updateSavedDataTableData: (state, action) => {
      const { identifier, exportState } = action.payload;
      // console.log('updateSavedDataTableData', identifier, exportState);

      // Ensure `state.user.datatable_views` is defined and is an array
      if (!state.user) {
        state.user = {};
      }
      if (!Array.isArray(state.user.datatable_views)) {
        state.user.datatable_views = [];
      }

      const tableIndex = findIndex(state.user.datatable_views, {
        identifier: identifier,
      });
      if (tableIndex === -1) {
        //if not add it
        state.user.datatable_views.push({
          identifier: identifier,
          exportState: exportState,
        });
      } else {
        //if it does update it
        state.user.datatable_views[tableIndex].exportState = exportState;
      }
      // console.log("tableIndex", tableIndex);
    },
  },
});

export const {
  updateMsssoError,
  debugJWT,
  setTwoFactor,
  updateMsssoTokens,
  makeCheckLoginState,
  setOnlineUsers,
  addTokens,
  signIn,
  updateToken,
  signInSSO,
  signInError,
  signOut,
  register,
  resetPassword,
  clearAuthenticationMessage,
  clearTwoFactor,
  clearLoggingIn,
  userLoaded,
  setEmailInbox,
  setAvatar,
  updateNotificationUnreadCount,
  updateFirstTimeSetup,
  updateDatatableViews,
  setCurrentDataTableView,
  updateLanguage,
  setRedirectTo,
  updateSavedDataTableData,
} = user.actions;

export default user.reducer;
