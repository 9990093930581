import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TicketPageMessageContainer,
  TicketPageMessageContainerInner,
  TicketPageMessageContainerInnerLeft,
  TicketPageMessageContainerInnerRight,
  TicketPageMessageUsersName,
  TicketPageMessageContainerDateStatus,
  TicketPageMessageContainerInnerRightMessage,
  TicketPageMessageContainerInnerRightMessageTitle,
  TicketPageMessageContainerInnerRightMessageText,
  TicketPageMessageLockContainer,
  CommentEllipsis,
} from 'containers/Pages/TicketViewPage/components/styled';
import UserAvatar from 'components/Global/UserAvatar';
import { Typography } from '@mui/material';
import { sanitize } from 'dompurify';
import TicketCommentTextEditor from 'containers/Pages/TicketViewPage/components/TicketCommentTextEditor';
import { useSelector } from 'react-redux';
import usePermissions from 'containers/App/Hooks/usePermissions';
import useCommentDelete from 'containers/App/Hooks/useCommentDelete';
import styles from 'components/PageBase/styles';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useMutation, gql } from '@apollo/client';
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';



const TicketMessage = ({ message, title, ticket, theme, commentID }) => {
  const { created_at = {}, person = {}, source = {}, public: is_public = false, id = null } = message ?? {};
  const { readable = '' } = created_at;
  const { name: fullName = '' } = person;
  const { id: person_id = '' } = person;
  const { body_preview = '', subject = '', lexical = null, body = '' } = source;

  // console.log(message)

  // console.log(lexical)
  const [expandMessage, setExpandMessage] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const noteIndicatorColor = is_public ? 'success' : 'error';
  const lockColor = is_public ? 'success' : 'error';
  const lockIcon = is_public ? 'lock-open' : 'lock';
  const user = useSelector((state) => state.user);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log(ticket)

  const UPDATE_TICKET_COMMENT_PUBLIC = gql`
    mutation MyMutation($_eq: uuid = "", $_eq1: uuid = "", $public: Boolean = false) {
  update_ticket_comment(where: {id: {_eq: $_eq}, ticket_id: {_eq: $_eq1}}, _set: {public: $public}) {
    affected_rows
  }
}
    `;

  const [submitChangeCommentPublic, { data, loading, error }] = useMutation(UPDATE_TICKET_COMMENT_PUBLIC);

  const handlePublicUpdate = () => {
    const variables = {
      _eq: commentID,
      _eq1: ticket?.id,
      public: !is_public,
    };
    submitChangeCommentPublic({ variables });

  };

  const { handle: handleDelete, loading: deleteLoading } = useCommentDelete({
    commentID: commentID,
    ticketID: ticket?.id,
  });

  // const { handle: handlePublicUpdate, loading: publicUpdateLoading } = useCommentPublicUpdate({
  //   commentID: ticket?.comments?.allComments?.id,
  //   ticketID: ticket?.id,
  // });

  const permission = usePermissions({
    access: {
      type: 'one',
      priority: 1,
      permissions: [
        {
          key: 'CAN_EDIT_TICKET',
          value: true,
        },
      ],
    },
  });

  const handlePersonNavigate = () => {
    navigate(`/admin/profile/${person_id}`);
  }

  let messagePreview = body_preview?.replace(/<[^>]+>(?=\S)/g, ' ')?.replace(/<[^>]+>/g, '') ?? '';

  return (
    <TicketPageMessageContainer type={noteIndicatorColor}>
      {title && (
        <Typography variant='h6' fontWeight={600}>
          {title}
        </Typography>
      )}
      <CommentEllipsis>
        <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} onClick={handleClick} />
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handlePublicUpdate}>{is_public ? "Make Internal" : "Make Public"}</MenuItem>
          <MenuItem onClick={handleDelete} style={{ color: 'red' }} >Delete</MenuItem>
        </Menu>
      </CommentEllipsis>
      <TicketPageMessageContainerInner>
        <TicketPageMessageContainerInnerLeft>
          <UserAvatar user={person} />
          <TicketPageMessageUsersName
            onClick={handlePersonNavigate}
          >{fullName}</TicketPageMessageUsersName>
          <TicketPageMessageLockContainer type={lockColor}>
            <FontAwesomeIcon icon={['fas', lockIcon]} />
          </TicketPageMessageLockContainer>
        </TicketPageMessageContainerInnerLeft>
        <TicketPageMessageContainerInnerRight>
          <TicketPageMessageContainerInnerRightMessage>
            <TicketPageMessageContainerInnerRightMessageTitle>
              <FontAwesomeIcon icon={['fas', expandMessage ? 'chevron-down' : 'chevron-right']} onClick={() => setExpandMessage(!expandMessage)} />
              <Typography variant='h6'>{subject}</Typography>
            </TicketPageMessageContainerInnerRightMessageTitle>

            <TicketPageMessageContainerInnerRightMessageText>
              {expandMessage ? (
                <>
                  {lexical ? (
                    <TicketCommentTextEditor id={id} readOnly={true} value={lexical} namespace={`ticketCommentTextEditor${id}`} />
                  ) : (
                    <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: sanitize(body) }}></div>
                  )}
                </>
              ) : (
                <div style={{ width: '100%' }} >
                  {messagePreview}
                </div>
              )}
            </TicketPageMessageContainerInnerRightMessageText>
          </TicketPageMessageContainerInnerRightMessage>
          <TicketPageMessageContainerDateStatus>
            <FontAwesomeIcon icon={['fas', 'globe']} />
            <Typography>{readable}</Typography>
          </TicketPageMessageContainerDateStatus>
        </TicketPageMessageContainerInnerRight>
      </TicketPageMessageContainerInner>
    </TicketPageMessageContainer>
  );
};

export default withStyles(styles)(TicketMessage);