import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Chip, Stack, Tooltip, Typography } from '@mui/material';
import TicketModal from '../../TicketModal';
import { TicketChipList, TicketChipListItem } from '../../styled';
import personJson from './personJson';
import Translation from 'utils/translation';
import DataTablePro from 'components/DataTablePro';
import { gql } from '@apollo/client';
import { Checkbox, FormControlLabel } from '@mui/material';
import { GET_ALL_ACTIVE_PERSONS } from 'graphql/person';
import TableGeneric from 'components/TableGeneric';
import { EnumType } from 'json-to-graphql-query';

//write a function to loop through an object and its children and return every object where the key is mentionName

function ToolBarContactsPlugin(props) {
  const {
    classes,
    theme,
    ticket,
    editorState,
    commentValues,
    addResources,
    setFormValues,
    emailListOpen,
    handleCloseEmails,
    setEmailListOpen,
    toArray,
    setToArray,
    emailOptions,
    setEmailOptions,
  } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const [ccArray, setCcArray] = useState([]);
  const [mentionArray, setMentionArray] = useState([]);
  const [mergedCcArray, setMergedCcArray] = useState([]);
  const [immutableCcArray, setImmutableCcArray] = useState([]);
  // const [emailOptions, setEmailOptions] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [showMore, setShowMore] = useState(false);
  //  console.log('item', ticket)

  //  console.log('toArray', toArray)
  useEffect(() => {
    if (addResources) {
      let resourceArray = [];
      ticket.resources.forEach((resource) => {
        if (!toArray.find((item) => item.id === resource.person.id) && !ccArray.find((item) => item.id === resource.person.id)) {
          resourceArray.push(resource.person);
        }
      });
      let assignee = ticket.assignee;
      if (!toArray.find((item) => item.id === assignee.id) && !ccArray.find((item) => item.id === assignee.id) && !mentionArray.find((item) => item.id === assignee.id)) {
        resourceArray.push(assignee);
      }
      setCcArray((prevState) => [...prevState, ...resourceArray]);
    }
  }, [addResources, toArray, ccArray, mentionArray]);
  useEffect(() => {
    const mentions = getMentionName(editorState, 'mentionName');
    setMentionArray(mentions);

    //if mentions is not empty, loop through and if mentions.id is not in the toArray or ccArray, add it to cleanArray

    let mentionsNotInToOrCc = [];
    mentions.forEach((mention) => {
      if (!toArray.find((item) => item.id === mention.id) && !ccArray.find((item) => item.id === mention.id)) {
        mentionsNotInToOrCc.push(mention);
      }
    });
    setImmutableCcArray(mentionsNotInToOrCc);
  }, [editorState, toArray, ccArray]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {
    if (ticket?.contacts[0]?.roles[0]?.contacts[0]?.name) {
      //add if not already in array
      if (!toArray.find((item) => item.id === ticket.requester.id)) {
        setToArray([...toArray]);
      }
    }
  }, [ticket]);

  // useEffect(() => {
  //   console.log('to array', toArray)
  // }, [toArray])

  useEffect(() => {
    // console.log(mentionArray)
    //add mentionName to ccArray if not already in array and not in the toArray

    let mentionsNotInToOrCc = [];
    mentionArray.forEach((mention) => {
      if (!toArray.find((item) => item.id === mention.id) && !ccArray.find((item) => item.id === mention.id)) {
        mentionsNotInToOrCc.push(mention);
      }
    });
    setImmutableCcArray(mentionsNotInToOrCc);
    setMergedCcArray([...ccArray, ...mentionsNotInToOrCc]);

    setFormValues((prevState) => ({
      ...prevState,
      cc: ccArray,
      to: toArray,
      mentions: mentionArray,
    }));
  }, [mentionArray, ccArray, toArray]);
  const handleCallbackTo = (data) => {
    // console.log("data", data);
    setToArray(data);
  };
  const handleCallbackCC = (data) => {
    setCcArray(data);
  };

  useEffect(() => {
    if (modalContent) {
      handleOpenModal();
    }
  }, [modalContent]);
  // console.log('toArray', toArray)
  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      let email = emailOptions.find((item) => item.email_address === event.target.value);
      setToArray([...toArray, email]);
    } else {
      let email = emailOptions.find((item) => item.email_address === event.target.value);
      let newArray = toArray.filter((item) => item.email_address !== email.email_address);
      setToArray(newArray);
    }
  };

  const handleShowMore = () => {
    setEmailListOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '20%',
          // border: "1px solid",
          backgroundColor: 'white!important',
        }}
      >
        <Box
          sx={{
            // marginTop: "10px",
            backgroundColor: 'white!important',
          }}
        >
          <Typography variant={'h6'} align={'center'} sx={{}}>
            {' '}
            Choose Recipients{' '}
          </Typography>
        </Box>

        <Box
          sx={{
            // width: "100%",
            // height: "20%",
            // border: "1px solid",
            display: 'flex',
            flexDirection: 'row',
            padding: '10px',
            overflowY: 'auto',
            borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
          }}
        >
          {emailOptions
            .filter((item, index, self) => index === self.findIndex((t) => t.email_address === item.email_address))
            .slice(0, showMore ? emailOptions.length : 5)
            .map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  control={<Checkbox id={item.email_address} name={item.email_address} value={item.email_address} onChange={handleCheckboxChange} />}
                  label={item.email_address}
                />
              </Box>
            ))}
          {emailOptions.length > 5 && !showMore && (
            <Button
              onClick={handleShowMore}
              variant='contained'
              sx={{
                height: '30px',
                marginTop: '5px',
              }}
            >
              Show More
            </Button>
          )}
        </Box>
      </Box>
      <Box className={`${classes['toolbar']} ${classes['contact-toolbar']}`} sx={{}}>
        <TicketModal open={openModal} handleClose={handleCloseModal} content={modalContent} />

        <Box className={'toolbar-item'} style={{ borderRadius: 2, cursor: 'pointer' }}>
          <Box
            minHeight={'48px'}
            display={'flex'}
            alignItems={'center'}
            gap={1}
            paddingX={1}
            paddingY={1}
            onClick={() => {
              setModalContent(<AddContact callback={handleCallbackTo} contactsArray={toArray} ticket={ticket} />);
            }}
          >
            <FontAwesomeIcon icon={['fas', 'user-plus']} fixedWidth className={'fa-icon'} />
            <div>To{toArray?.length > 0 && <> :</>}</div>

            <Box display={'flex'} alignItems={'center'}>
              <TicketChipList>
                {toArray?.length > 0 && (
                  <>
                    <TicketChipListItem
                      disableGutters
                      sx={{
                        marginBottom: 0,
                        display: 'inline-block',
                      }}
                    >
                      <Chip
                        sx={{
                          backgroundColor: '#B0B0B0'[200],
                          boxShadow: 'none',
                          cursor: 'pointer',
                        }}
                        label={toArray[0].name + ' - ' + toArray[0].email_address}

                      // onDelete={ ()=>handleDeleteToArray(item)}
                      />
                    </TicketChipListItem>
                  </>
                )}
              </TicketChipList>
              {toArray.length > 1 && (
                // <Tooltip
                //   title={
                //     <div>
                //       {toArray.slice(1).map((item, index) => {
                //         return (
                //           <div key={index}>
                //             testign
                //             {item} - {item.email_address}
                //           </div>
                //         );
                //       })}
                //     </div>
                //   }
                //   disableInteractive
                //   placement={"top"}
                // >
                <Typography
                  sx={{
                    fontSize: 14,
                  }}
                >
                  +{toArray.length - 1} more
                </Typography>
                // </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
        <div className='divider'></div>
        {/* <Box
        className={"toolbar-item"}
        style={{ borderRadius: theme.borderRadius, cursor: "pointer" }}
      >
        <Box
          minHeight={"48px"}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          paddingX={1}
          paddingY={1}
          onClick={() => {
            setModalContent(
              <AddContact
                callback={handleCallbackCC}
                contactsArray={ccArray}
                immutableContactsArray={immutableCcArray}
                ticket={ticket}
              />
            );
          }}
        >
          <FontAwesomeIcon
            icon={["fas", "users-medical"]}
            fixedWidth
            className={"fa-icon"}
          />
          <div>CC{ccArray?.length > 0 && <> :</>}</div>

          <Box display={"flex"} alignItems={"center"}>
            <TicketChipList>
              {mergedCcArray?.length > 0 && (
                <>
                  <TicketChipListItem
                    disableGutters
                    sx={{
                      marginBottom: 0,
                      display: "inline-block",
                    }}
                  >
                    <Chip
                      sx={{
                        backgroundColor: theme.palette.grey[200],
                        boxShadow: "none",
                        cursor: "pointer",
                      }}
                      label={
                        mergedCcArray[0]?.name +
                        " - " +
                        mergedCcArray[0]?.email_address
                      }
                      // onDelete={ ()=>handleDeleteToArray(item)}
                    />
                  </TicketChipListItem>
                </>
              )}
            </TicketChipList>
            {mergedCcArray.length > 1 && (
              <Tooltip
                title={
                  <div>
                    {mergedCcArray.slice(1).map((item, index) => {
                      return (
                        <div key={index}>
                          {item.name} - {item.email_address}
                        </div>
                      );
                    })}
                  </div>
                }
                disableInteractive
                placement={"top"}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                  }}
                >
                  +{mergedCcArray.length - 1} more
                </Typography>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box> */}
      </Box>
    </>
  );
}

function AddContact(props) {
  const { handleClose, callback, contactsArray, theme, immutableContactsArray = [], ticket } = props;
  const [contacts, setContacts] = useState([...contactsArray]);

  const incomingColumns = [
    // { field: 'id', headerName: 'uuid', minWidth: 100, visability: false },
    { field: 'name', headerName: 'Name', minWidth: 200 },
    { field: 'email_address', headerName: 'Email', minWidth: 200 },
    { field: 'organization_name', headerName: 'Organization', minWidth: 200 },
  ];

  const handleRemove = (value) => {
    let newArray = contacts.filter((item) => item.id !== value.id);
    setContacts(newArray);
    callback(newArray);
  };
  const filters = [
    {
      queryField: 'name',
      endpoint: 'person',
      query: gql`
        query MyQuery {
          person(order_by: { organization: { name: asc } }) {
            id
            name
            organization {
              name
            }
          }
        }
      `,
      label: 'Name',
      group: 'organization.name',
      type: 'autocomplete',
    },
    {
      queryField: 'email_address',
      endpoint: 'person',
      query: gql`
        query MyQuery {
          person(order_by: { email_address: asc }) {
            id
            email_address
          }
        }
      `,
      label: 'Email',
      field: 'email_address',
      type: 'autocomplete',
    },
    {
      queryField: 'organization.name',
      endpoint: 'organization',
      query: gql`
        query MyQuery {
          organization(order_by: { name: asc }) {
            id
            name
          }
        }
      `,
      label: 'Organization',
      type: 'autocomplete',
    },
  ];
  // const filters = {
  //   filters: [
  //     {
  //       table: "organization",
  //       value: `${
  //         ticket?.organization?.name ? ticket?.organization?.name : ""
  //       }`,
  //       field: "name",
  //       field_parent: "organization",
  //     },
  //   ],
  //   density: "compact",
  //   columnVisibilityModel: {},
  //   sort: "{name: asc}",
  //   rowsPerPage: 10,
  // };
  const handleAdd = (itemObj) => {
    // let itemObj = {
    //   email_address: "bob.saget@tv.show",
    //   id: "fdb89d67-faa9-4843-8157-834484dbeb81",
    //   name: "Bob Saget",
    // };

    if (!contacts.find((item) => item.id === itemObj.id)) {
      setContacts([...contacts, itemObj]);
      callback([...contacts, itemObj]);
    }
  };
  const handleSelectionChange = (value) => {
    //console.log(value.row);
    handleAdd({
      email_address: value.row.email_address,
      id: value.row.id,
      name: value.row.name,
    });
  };
  let width = window.innerWidth * 0.75;
  //round no decimal
  width = Math.round(width);
  //if width is odd number, make it even
  width = width % 2 === 0 ? width : width - 1;


  //https://www.npmjs.com/package/json-to-graphql-query
  const query = {
    query: {
      person: {
        __args: {
          order_by: {
            organization_name: new EnumType('asc')
          },
          where: {
            active: {
              _eq: true
            }
          }
        },
        id: true,
        name: true,
        organization_name: true,
        email_address: true
      }
    }
  }
  //   const searchQuery = {
  //     default: [
  //         { key: 'person.name', operator: '_ilike' },
  //         { key: 'person.email_address', operator: '_ilike' },
  //     ],
  //     numerator: [
  //         { key: 'ticket.alias', operator: '_ilike' },
  //     ]
  // }

  const searchQuery = {
    default: [
      { key: 'name', operator: '_ilike' },
      { key: 'email_address', operator: '_ilike' },
      { key: 'organization_name', operator: '_ilike' },
    ]
  }

  return (
    <Box sx={{ width: width }}>
      <Typography variant={'h4'} align={'center'} sx={{ marginBottom: 3 }}>
        {Translation('ticketViewPage.addContact')}
      </Typography>
      <Box
        sx={{
          // backgroundColor: theme.palette.grey[100],
          padding: 2,
          borderRadius: 2,
          marginBottom: 3,
          border: '1px solid' + '#B0B0B0'[300],
          minHeight: '66px',
        }}
      >
        <Stack direction='row' spacing={2} useFlexGap flexWrap='wrap'>
          {immutableContactsArray.map((item, index) => {
            return (
              <Chip
                key={index}
                // label={item.name + " - " + item.email_address}
                label={item.email_address}
                sx={{
                  backgroundColor: '#B0B0B0'[100],
                }}
              />
            );
          })}
          {contacts.map((item, index) => {
            //  console.log('item', item)
            return (
              <Chip
                key={index}
                // label={item.name + " - " + item.email_address}
                label={item.email_address}
                onDelete={() => handleRemove(item)}
                sx={{
                  backgroundColor: '#B0B0B0'[200],
                  boxShadow: 'none',
                }}
              />
            );
          })}
        </Stack>
      </Box>
      <Box>
        <TableGeneric
          columns={incomingColumns}
          tableName='person'
          query={query}
          onRowClick={handleSelectionChange}
          searchQuery={searchQuery}
        />
      </Box>


      <Button fullWidth sx={{ marginTop: 3 }} onClick={handleClose}>
        {Translation('ticketViewPage.button.close')}
      </Button>
    </Box>
  );
}

function getMentionName(obj, key) {
  let result = [];
  for (let i in obj) {
    if (i === key) {
      result.push(obj[i]);
    } else if (obj[i] !== null && typeof obj[i] === 'object') {
      result = result.concat(getMentionName(obj[i], key));
    }
  }
  return result;
}

export default ToolBarContactsPlugin;
