import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { TextField } from '@mui/material';
import Translation from 'utils/translation';
import withStyles from "@mui/styles/withStyles";
import { styled } from "@mui/system";
import React, { useEffect, useRef } from "react";
import { useElementSize } from "@mantine/hooks";
import PersonOverview from './personOverview';
import NotFoundPage from "containers/Pages/ErrorPages/NotFoundPage/Loadable";

function TabPanel(props) {
  const { children, value, index, sx, theme,disabled   } = props;

  return (
    <Box
      sx={{
        ...sx,
        opacity: disabled ? 0.5 : 1,
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Box
        sx={{
          margin: 3,
          marginTop: 0,
          pointerEvents: disabled ? 'none' : 'auto',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {children}
      </Box>
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  sx: PropTypes.object,
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};



export const StyledTab = styled(Tab)(({ theme, tab }) => ({
  padding: theme.spacing(0),
  fontWeight: 600,
  // borderRadius: `0 0 150px 150px `,
  backgroundColor: theme.palette.grey[200],

  "&.Mui-selected": {
    overflow: "hidden",
    // borderRadius: ` ${tab === 0 ? ` 0 ${theme.borderRadius} 0 0` : tab < 3 ? `${theme.borderRadius}  ${theme.borderRadius}  0 0` : `${theme.borderRadius} 0 0 0`} `,
    borderRadius: `${theme.borderRadius} ${theme.borderRadius} 0 0`,
    // border:`1px solid ${theme.palette.grey[200]}`,
    borderBottom: 0,
    textDecoration: "underline",

    backgroundColor: theme.palette.background.dark,
    textDecorationThickness: "3px",
    // textDecorationStyle:'wavy',
    textUnderlineOffset: "5px",
  },
}));

const DisabledTab = styled(Tab)(({ theme }) => ({
  pointerEvents: 'none',
  opacity: 0.5,
  padding: theme.spacing(0),
  fontWeight: 600,
  backgroundColor: theme.palette.grey[200],
}));

function PersonTabs(props) {
  const {
    theme, 
    height,
    user,
    Edituser,
    userID, 
    comments
  } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [tabValue, setTabValue] = React.useState(0);

  const updateTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const { ref, width, height: tabHeaderHeight } = useElementSize();
  const { ref: searchRef, height: searchHeight } = useElementSize();
  const scrollWindowRef = useRef(null);
  const listRef = useRef(null);

  return (
    <>
    <Tabs
    variant="scrollable"
    scrollButtons
    allowScrollButtonsMobile
    value={tabValue}
    onChange={(e, v) => updateTab(e, v)}
    sx={{
      flex: 1,
      top: "2px",
      zIndex: 1,

      backgroundColor: theme.palette.grey[200],
      // position: 'relative',
      "& .MuiTabs-flexContainer": {
        height: "48px",
      },
      ".MuiTabs-indicator": {
        height: "0!important",
      },
    }}
  >
    {" "}
    <StyledTab
      tab={tabValue}
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(0, 3),
          }}
        >
          <Box>{"Overview"}</Box>
        </Box>
      }
      disableRipple
    />
    <DisabledTab
      tab={tabValue}
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(0, 3),
          }}
        >
          <Box>{"Activity"}</Box>
        </Box>
      }
      disableRipple
    />

   <DisabledTab

      tab={tabValue}
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(0, 3),
          }}
        >
          <Box>{"Tasks"}</Box>
        </Box>
      }
      disableRipple
    />
  </Tabs>

      <Box
      ref={scrollWindowRef}
      sx={{
          backgroundColor: theme.palette.background.dark,
        //  height: `calc(${height - tabHeaderHeight}px )`,
          // transition: "height  0.05s ease-in-out",
          // transitionDelay: '3s',
          overflowY: "scroll",
          //position: "relative",
          maxHeight: "calc(100vh - 230px)",
        }}
        >
<TabPanel value={tabValue} index={0} theme={theme}>
  <PersonOverview
  user={user}
  Edituser = {Edituser}
  userID = {userID}
  comments = {comments}
  />
</TabPanel>
<TabPanel value={tabValue} index={1} theme={theme} disabled={true}>
  <NotFoundPage/>
</TabPanel>
<TabPanel value={tabValue} index={2} theme={theme} disabled={true} >
  <NotFoundPage/>
</TabPanel>
</Box>




</>
 
  );
}


export default withStyles((theme) => ({}), { withTheme: true })(
  PersonTabs
);