import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import graphQLQueries from 'graphql/person';
import { Autocomplete, TextField } from '@mui/material';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import {
  ModalCancelButton,
  ModalAddButton,
} from 'containers/Pages/TicketViewPage/components/styled';
import { useParams } from 'react-router-dom';
import Button from 'components/MuiOverride/Button';


const EditLocation = (props) => {
  const { handleClose, company } = props;
  const [errorMessage, setErrorMessage] = useState();
  const {id} = useParams();
  const orgName = company?.organization?.[0]?.name;

  const addressLineOneRef = useRef();
  const addressLineTwoRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const zipCodeRef = useRef();

  const ADD_ORG_LOCATION = gql`
mutation MyMutation($address_line_one: String = "", $address_line_two: String = "", $city: String = "", $created_at: timestamptz = "", $time_zone_id: uuid = "", $zip_code: String = "", $organization_id: uuid = "", $country_id: uuid = "", $name1: String = "", $updated_at: timestamptz = "") {
  insert_organization_location(objects: {address_line_one: $address_line_one, address_line_two: $address_line_two, city: $city, created_at: $created_at, time_zone_id: $time_zone_id, zip_code: $zip_code, organization_id: $organization_id, country_id: $country_id, name: $name1, updated_at: $updated_at}) {
    affected_rows
  }
}



  `;
  const [addLocation] = useMutation(ADD_ORG_LOCATION);

  const handleAddLocation = () => {
    setErrorMessage('');
    if (!addressLineOneRef.current.value || !addressLineTwoRef.current.value || !cityRef.current.value ||  !zipCodeRef.current.value) {
      setErrorMessage('Please make sure all fields have been filled out.');
      return;
    }
    addLocation({
      variables: {
        address_line_one: addressLineOneRef.current.value,
        address_line_two: addressLineTwoRef.current.value,
        city: cityRef.current.value,
        zip_code: zipCodeRef.current.value,
        organization_id: id,
        created_at: new Date().toISOString(),
        time_zone_id: '0185e8a3-364c-746c-a95f-89ff9caf1fff',
        country_id: countryRef.current,
        name1: orgName,
        updated_at: new Date().toISOString(),
      //  name: 'United Kingdom',
      },
    });
    handleClose();
  };

  const EDIT_ORG_LOCATION = gql`
  mutation MyMutation($orgID: uuid = "", $address_line_one: String = "", $address_line_two: String = "", $city: String = "", $zip_code: String = "", $updated_at: timestamptz = "", $country_id: uuid = "") {
  update_organization_location(where: {organization_id: {_eq: $orgID}}, _set: {address_line_one: $address_line_one, address_line_two: $address_line_two, city: $city, zip_code: $zip_code, updated_at: $updated_at, country_id: $country_id}) {
    affected_rows
  }
}
  `;

  const [editLocation] = useMutation(EDIT_ORG_LOCATION);

  const handleEditLocation = () => {
    setErrorMessage('');
    if (!addressLineOneRef.current.value || !addressLineTwoRef.current.value || !cityRef.current.value ||  !zipCodeRef.current.value) {
      setErrorMessage('Please make sure all fields have been filled out.');
      return;
    }
    editLocation({
      variables: {
        orgID: id,
        address_line_one: addressLineOneRef.current.value,
        address_line_two: addressLineTwoRef.current.value,
        city: cityRef.current.value,
        zip_code: zipCodeRef.current.value,
        updated_at: new Date().toISOString(),
        country_id: countryRef.current,
      },
    });
    handleClose();
  }

  const GET_COUNTRIES = gql`
  query MyQuery {
  country {
    name
    id
  }
}
  `;

  const [getCountries, { loading: loadingCountries, error: errorCountries, data: dataCountries }] = useLazyQuery(GET_COUNTRIES);

  useEffect(() => {
    getCountries();
  }
  , []);

  const locations = company?.organization?.[0]?.locations || [];
  console.log('Locations:', company?.organization?.[0]?.locations);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {errorMessage && (
          <Typography color='error' sx={{ alignItems: 'center', textAlign: 'center' }}>
            {errorMessage}
          </Typography>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <TextField 
          label='Address Line 1' 
          variant='outlined' 
          fullWidth 
          required 
          inputRef={addressLineOneRef} 
          defaultValue={company?.organization?.[0]?.locations?.[0]?.address_line_one}
          />
          <TextField 
          label='Address Line 2' 
          variant='outlined' 
          fullWidth 
          required 
          inputRef={addressLineTwoRef} 
          defaultValue={company?.organization?.[0]?.locations?.[0]?.address_line_two}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <TextField 
          label='City' 
          variant='outlined' 
          sx={{ width: 430 }} 
          required 
          inputRef={cityRef} 
          defaultValue={company?.organization?.[0]?.locations?.[0]?.city}
          />
         
          <Autocomplete
            loading={loadingCountries}
            id='combo-box-demo'
            options={dataCountries?.country ? dataCountries?.country : []}
            onChange={(event, value) => {
              countryRef.current = value.id;
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label='Country' />}
            getOptionLabel={(option) => option.name}
            defaultValue={
              company?.organization?.[0]?.locations?.[0]?.country
                ? {
                    id: company.organization[0].locations[0].country.id,
                    name: company.organization[0].locations[0].country.name,
                  }
                : null
            }
          />

          <TextField 
          label='Zip Code' 
          variant='outlined' 
          sx={{ width: 120 }} 
          required 
          inputRef={zipCodeRef} 
          defaultValue={company?.organization?.[0]?.locations?.[0]?.zip_code}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            // width: '95%',
            marginBottom: 2,
            // marginLeft: theme.spacing(2),
            marginTop: 4,
          }}
        >
          <ModalCancelButton variant='contained' onClick={handleClose}>
            Cancel
          </ModalCancelButton>
          {locations?.length >= 1 ? (
               <ModalAddButton
               variant='contained'
               onClick={handleEditLocation}
             >
               Save
             </ModalAddButton>
          ) : (
              <ModalAddButton
              variant='contained'
                 onClick={handleAddLocation}
            >
              Add
            </ModalAddButton>
          )
          }
         
        </Box>
      </Box>
  
    </>
  );
};

export default EditLocation;
