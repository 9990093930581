import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Info from "@mui/icons-material/Info";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import PageBase from "components/PageBase";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';



function InternalForm(props) {
    const [iframeSrc, setIframeSrc] = useState("https://www.sixstar.online/formsindex");
    const iframeRef = useRef(null);

    const formsList =[
        {
            title: "Global Operations",
            forms: [
                {
                    title: "Work Initiation Form",
                    url: "https://sixstar.formstack.com/forms/work_initiation_document ",
                    description: "Use this to kick off/initiate any piece of work that is not already being managed as part of an existing project or initiative, whether or not there is already a ticket for it."
                }
            ]
        },
        {
            title: "Account Management",
            forms: [
                {
                    title: "Accounts and Company Information Request",
                    url: "https://sixstar.formstack.com/forms/customer_information_form_fid59627",
                    description: "FID59627"
                },
                {
                    title: "New MSS Service Form [Client Facing] ",
                    url: "https://sixstar.formstack.com/forms/new_mss_service_fid27120",
                    description: "FID27120"
                },
                {
                    title: "Request For Proposal [Internal]",
                    url: "https://sixstar.formstack.com/forms/request_for_proposal_fid19840",
                    description: "FID19840"
                },
                {
                    title: "Six Star:ARK Sales/Account Management System Onboarding",
                    url: "https://sixstar.formstack.com/forms/start_ark",
                    description: "FID3883218"
                },
                
            ]
        },
        {
            title: "Global Finance",
            forms: [
                {
                    title: "Credit Application Form",
                    url: "https://sixstar.formstack.com/forms/credit_application_form",
                    description: "FID63865"
                },
                {
                    title: "Invoice Order",
                    url: "https://sixstar.formstack.com/forms/invoice_order_fid63718",
                    description: "FID63718"
                },
                {
                    title: "Payment Request",
                    url: "https://sixstar.formstack.com/workflows/payment_request_fid18654",
                    description: "FID18654"
                },
                {
                    title: "Purchase Request",
                    url: "https://sixstar.formstack.com/forms/purchase_request_fid23649",
                    description: "FID23649"
                },
            ]
        },
        {
            title: "Procurement",
            forms:[
                {
                    title: "New Vendor Form [Vendor Facing]",
                    url: "https://sixstar.formstack.com/forms/new_vendor_form_fid51524",
                    description: "FID51524"
                },
                // {
                //     title: "New Vendor Form [Internal]",
                //     url: "https://manage.sixstar.global/sqs/vendor-form",
                //     description: ""
                // },
            ]
        },
        {
          title: "Global Professional Services" ,
            forms: [
                {
                    title: "New Client Customer Basic Information Form [Client Facing]",
                    url: "https://sixstar.formstack.com/forms/new_client_quote_information_request",
                    description: "For Basic CRM & Quoting Information"
                },
                {
                    title: "New Service Form [Internal]",
                    url: "https://sixstar.formstack.com/forms/new_service_form",
                    description: "FID13047"
                },
                {
                    title: "Project Introduction Email Form [Internal]",
                    url: "https://sixstar.formstack.com/forms/projectintroduction",
                    description: ""
                },
                // {
                //     title: "Hilton WAN Form [Client Facing] ",
                //     url: "https://sixstar.formstack.com/forms/hilton_wan_form",
                //     description: "FID42447"
                // },
                {
                    title: "Client Property Project Sign-off [Client Facing]",
                    url: "https://sixstar.formstack.com/forms/client_property_project_signoff_fid24802",
                    description: "FID24802"
                },
                // {
                //     title: "Client Group Project Overall Sign-off [Client Facing]",
                //     url: "https://sixstar.formstack.com/forms/client_group_project_overall_signoff_fid25845",
                //     description: "FID25845"
                // },
                {
                    title:"Resourcing Request Form [Internal]",
                    url: "https://forms.office.com/pages/responsepage.aspx?id=8oZAhxivEkydreu3yJy2erCC1Zz29KZJr_gw9_xQNvhUNkJYNU9PSklTOUhORFVFVDdITzQ0WktIQS4u",
                    description: ""
                }
            ]
        },
        {
            title: "Global Marketing & Design",
            forms: [
                {
                    title: "Request ID Card ",
                    url: "https://sixstar.formstack.com/forms/request_id_card_fid32699",
                    description: "FID32699"
                },
                {
                    title: "Internal Team Page",
                    url: "https://coda.io/d/_dIexrStbgij/Team_suzvF#_luor_",
                    description: "FID22967"
                },
                {
                    title: "Business Cards Request",
                    url: "https://sixstar.formstack.com/forms/request_business_card",
                    description: ""
                },  
                {
                    title: "Uniform  Request",
                    url: "https://sixstar.formstack.com/forms/six_star_uniforms ",
                    description: ""
                }, 
            ]
                },
                {
                    title: "Global Engineering",
                    forms: [
                        {
                            title: "Site Visit Completion",
                            url: "https://sixstar.formstack.com/forms/site_visit_completion_fid21153",
                            description: "FID21153"
                        },
                        // {
                        //     title: "Pre-Site Visit Self Certification",
                        //     url: "https://sixstar.formstack.com/forms/employee_selfcertification",
                        // }
                    ]
                },
                {
                    title: "Internal Systems & Services",
                    forms: [
                        // {
                        //     title: "Request for Six Star Drive Storage",
                        //     url: "https://sixstar.formstack.com/forms/request_for_drive_storage_fid60246",
                        //     description: "FID60246"
                        // },
                        {
                            title: "Development Request Form (Bugs/Feedback/Requests etc) [Internal]",
                            url: "https://manage.sixstar.support/help/give-feedback",
                            description: ""
                        }
                    ]
                },
                {
                    title: "Travel & Expense",
                    forms: [
                        {
                            title: "Expense Card Request",
                            url: "https://sixstar.formstack.com/workflows/expense_card_request",
                            description: "FID18653"
                        },
                        {
                            title: "Expense Submission",
                            url: "https://app.factorialhr.com/my-expenses/list/expenses",
                            description: "FID39354"
                        },
                        {
                            title: "Travel Preferences Form",
                            url: "https://sixstar.formstack.com/forms/travel_preferences_fid60246",
                            description: "FID68850"
                        },
                        {
                            title: "Travel Request Form",
                            url: "https://sixstar.formstack.com/forms/travel_request_form_fid42862",
                            description: "FID42862"
                        }
                    ]
                },
                {
                    title: "Global Service Delivery",
                    forms: [
                        {
                            title: "New Computer Setup Sign-Off [Client Facing]",
                            url: "https://sixstar.formstack.com/forms/new_computer_setup_signoff",
                            description: "FID21077"
                        }
                    ]
                },
                {
                    title: "Human Resources",
                    forms: [
                        {
                            title: "Manager Feedback",
                            url: "https://sixstar.formstack.com/forms/manager_feedback",
                            description: "(Only visible to CEO in first instance)"
                        },
                        {
                            title: "Colleague Feedback ",
                            url: "https://sixstar.formstack.com/forms/colleague_feedback",
                            description: "(Only visible to Executive Team in first instance)"
                        },
                        {
                            title: "Employee Onboarding",
                            url: "https://manage.sixstar.support/support/new-request/new-starter",
                            description: "FID18650"
                        },
                        {
                            title: "1 to 1",
                            url: "https://app.factorialhr.com/performance/reviews",
                            description: ""
                        },
                        {
                            title: "Employee Information Update",
                            url: "https://app.factorialhr.com/profile",
                            description: ""
                        },
                        // {
                        //     title: "Training Feedback",
                        //     url: "https://www.sixstar.online/training-meeting-feedback",
                        //     description: ""
                        // },
                        {
                            title: "Notify Team of Unplanned Absence ",
                            url: "https://sixstar.formstack.com/forms/notify_of_leave",
                            description: ""
                        },
                        {
                            title: "Short-Term Leave Request",
                            url: "https://app.factorialhr.com/time-off",
                            description: ""
                        },
                        {
                            title: "Request an Offer Letter ",
                            url: "https://sixstar.formstack.com/forms/new_starter_onboarding_to_be_used_by_line_managers_ ",
                            description: ""
                        },
                        {
                            title: "DISC Personality Test",
                            url: "https://teams.microsoft.com/l/entity/0e5a38f0-71f9-45e5-952f-8df55bf56a4b/_djb2_msteams_prefix_3028935652?context=%7B%22channelId%22%3A%2219%3Ada9548bcac5f4c6fa94b9496b454ac0b%40thread.tacv2%22%7D&tenantId=874086f2-af18-4c12-9dad-ebb7c89cb67a",
                            description: ""
                        },
                      
    ]
}
    ]
            

    const navigate = useNavigate(); // useNavigate hook for navigation

    const handleTitleClick = (url) => {
        window.open(url, '_blank'); // Open the URL in a new tab
      };
  

    return (
        <>
            <PageBase minHeight={500} noPadding={false} style={{}}>

                <Card sx={{alignItems: 'center'}}>
                    <Typography variant="h6" align="center" fontWeight="bold">Six Star Global Internal Forms</Typography>
        {/* creating a box to hold a list of forms. each box must take the main title as the heading */}

        <List>
      {formsList?.map((category, categoryIndex) => (
        <React.Fragment key={categoryIndex}>
          <Box sx={{ mb: 2}}>
          <Typography variant="h6" fontWeight={600} >{category.title}</Typography>
            <List sx={{ pl: 4 }}>
              {category?.forms?.map((form, formIndex) => (
                <ListItem key={formIndex} disablePadding>
              <ListItemText
                primary={<span style={{ fontWeight: 400, textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleTitleClick(form.url)}>{form.title}</span>}
                secondary={form.description}
              />
            </ListItem>
              ))}
            </List>
          </Box>
          {categoryIndex < formsList.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
                    {/* <iframe
                        ref={iframeRef}
                        src={iframeSrc}
                        title="SSG Internal Forms"
                        width="100%"
                        height="700"
                    /> */}

                </Card>
            </PageBase>
        </>
    );
}

export default InternalForm;