const styles = (theme) => ({
    container: {
        backgroundColor: "white",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "15px",
        padding: "20px",
        width: "100%",
    },
    textContainer: {
        textAlign: "center",
        width: "100%",
        height: "60px",
        alignItems: "center",
        jutsifyContent: "center",
        margin: "20px 0px",
        fontSize: "20px",

    },
});

export default styles;
