import TicketTablePage from "containers/Pages/TicketTablePage/Loadable";
import DashboardPage from "containers/Pages/DashboardPage/Loadable";
import NotFoundPage from "containers/Pages/ErrorPages/NotFoundPage/Loadable";
// import ApolloDemo from "containers/Pages/ApolloDemo/Loadable";
import NewRequestPage from "containers/Pages/NewRequestPage/Loadable";
import LicensePage from "containers/Pages/LicensePage/Loadable";
import Form from "containers/Pages/Form/Loadable";
import AssetPage from "containers/Pages/AssetPage/Loadable";
import ResourceTrackerPage from "containers/Pages/ResourceTrackerPage/Loadable";
import Drive from "containers/Pages/Drive/Loadable";
import ElasticDemo from "containers/Pages/ElasticDemo/Loadable";
import OrganisationProfile from "containers/Pages/OrganisationProfile/Loadable";
import TicketViewPage from "containers/Pages/TicketViewPage/Loadable";
import Email from "containers/Pages/Email/Loadable";
import ChatGPTPage from "containers/Pages/ChatGPTPage/Loadable";
import SixStarMeetPage from "containers/Pages/SixStarMeetPage/Loadable";
import ContactUs from "containers/Pages/ContactUs";
import Feedback from "containers/Pages/Feedback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompanyContact from "containers/Pages/CompanyContact";
import PersonContact from "containers/Pages/PersonContact";
import SixStarTVPage from "containers/Pages/SixStarTVPage";
import DataTablePro from "components/DataTablePro";
import SixStarVoicePage from "containers/Pages/SixStarVoicePage";
import SixStarInternetPage from "containers/Pages/SixStarInternet";
import SixStarVIPPage from "containers/Pages/SixStarVIPPage";
import SixStarBlackPage from "containers/Pages/SixStarBlackPage";
import SixStarCastPage from "containers/Pages/SixStarCastPage";
import SixStarParkPage from "containers/Pages/SixStarPark";
import SixStarGuestServicesPage from "containers/Pages/SixStarGuestServices";
import SixStarPrintPage from "containers/Pages/SixStarPrint";
import SixStarDisplayPage from "containers/Pages/SIxStarDisplayPage";
import SixStarPayPage from "containers/Pages/SixStarPayPage";
import CreateContact from "containers/Pages/CreateContact";
import Payslips from "containers/Pages/Payslips";
import InternalForm from "containers/Pages/InternalForm";
import TableTest from "containers/Pages/TableTest";
import AuditLog from "containers/Pages/AuditLog";
import AdminPanel from "containers/Pages/AdminPanel";
import AuditLogGraph from "containers/Pages/AuditLogGraph";
import ReleaseNotes from "containers/Pages/ReleaseNotes";
import ContactSearch from "containers/Pages/ContactSearch";
import CreateOrg from "containers/Pages/CreateOrg";


const manage = [
  {
    id: "dashboard",
    text: "menu.manage.dashboard.tabText",
    menuText: "menu.manage.dashboard.menuText",
    url: "/",
    roles: ["user", "agent", "admin", "group_gm"],
    component: <DashboardPage />,
    icon: ["fad", "home"],
  },
  {
    id: "manageSupportSection",
    text: "menu.manage.supportSection.text",
    menuText: "menu.manage.supportSection.menuText",
    url: "/support",
    icon: ["fad", "headset"],
    roles: ["user", "agent", "admin", "group_gm"],
    isDynamic: false,
    children: [
      {
        id: "manageEngineerTicketBoard",
        text: "menu.manage.engineerSection.ticketsBoard.tabText",
        menuText: "menu.manage.engineerSection.ticketsBoard.menuText",
        isDynamic: true,
        roles: ["user", "agent", "admin", "group_gm"],
        children: [
          {
            id: "manageEngineerTicket",
            text: "menu.manage.engineerSection.ticketsBoard.tabText",
            menuText: "menu.manage.engineerSection.ticketsBoard.menuText",
            icon: "fad faw fa-triangle-person-digging",
            url: "/support/tickets",
            component: <TableTest />,
            roles: ["user", "agent", "admin", "group_gm"],
          },
          {
            id: "manageEngineerTicket-:id",
            text: "menu.manage.engineerSection.ticketViewPage.tabText",
            menuText: "menu.manage.engineerSection.ticketViewPage.menuText",
            icon: "fad faw fa-triangle-person-digging",
            url: "/support/tickets/:id",
            component: <TicketViewPage />,
            roles: ["user", "agent", "admin", "group_gm"],
          },
        ],
      },
      {
        id: "manageEngineerReports",
        text: "menu.manage.engineerSection.reports.tabText",
        menuText: "menu.manage.engineerSection.reports.menuText",
        url: "/support/reports",
        component: <ElasticDemo />,
        roles: ["user", "agent", "admin", "group_gm"],
        disabled: true,
      },
      {
        id: "manageSupportNewRequest",
        text: "menu.manage.supportSection.newRequest.tabText",
        menuText: "menu.manage.supportSection.newRequest.menuText",
        isDynamic: true,
        roles: ["user", "agent", "admin", "group_gm"],
        children: [
          {
            id: "manageSupportNewRequestDashboard",
            text: "menu.manage.supportSection.newRequestDashboard.tabText",
            menuText: "menu.manage.supportSection.newRequestDashboard.menuText",
            url: "/support/new-request",
            component: <NewRequestPage />,
            roles: ["user", "agent", "admin", "group_gm"],
          },
          {
            id: "manageSupportNewRequestDashboard-:id",
            text: "menu.manage.supportSection.newRequestForm.tabText",
            menuText: "menu.manage.supportSection.newRequestForm.menuText",
            url: "/support/new-request/:id",
            component: <Form />,
            roles: ["user", "agent", "admin", "group_gm"],
          },
        ],
      },
    ],
  },
  {
    id: "managedServices",
    text: "menu.manage.managedServicesSection.text",
    menuText: "menu.manage.managedServicesSection.menuText",
    url: "/managed-services",
    roles: ["user", "agent", "admin", "group_gm"],
    component: <NotFoundPage />,
    icon: ["fad", "database"],
    children: [
      {
        id: "manageManagedServicesDashboard",
        text: "menu.manage.managedServicesSection.dashboard.tabText",
        menuText: "menu.manage.managedServicesSection.dashboard.menuText",
        url: "/managed-services/dashboard",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "manageManagedServicesServiceOverview",
        text: "menu.manage.managedServicesSection.serviceOverview.tabText",
        menuText: "menu.manage.managedServicesSection.serviceOverview.menuText",
        url: "/managed-services/service-overview",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "manageManagedServicesEndpointSecurity",
        text: "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.tabText",
        menuText:
          "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.menuText",
        url: "/managed-services/endpoint-security",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "manageManagedServicesBackup",
        text: "menu.manage.managedServicesSection.manageManagedServicesBackup.tabText",
        menuText:
          "menu.manage.managedServicesSection.manageManagedServicesBackup.menuText",
        url: "/managed-services/backups",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "manageManagedServicesSecureForms",
        text: "menu.manage.managedServicesSection.manageManagedServicesSecureForms.tabText",
        menuText:
          "menu.manage.managedServicesSection.manageManagedServicesSecureForms.menuText",
        url: "/managed-services/secure-forms",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "manageManagedServicesScheduledSiteVisits",
        text: "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.tabText",
        menuText:
          "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.menuText",
        url: "/managed-services/scheduled-site-visits",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "manageManagedServicesEWasteDisposals",
        text: "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.tabText",
        menuText:
          "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.menuText",
        url: "/managed-services/e-waste-disposals",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "manageManagedServicesPayslips",
        text: "menu.manage.managedServicesSection.manageManagedServicesPayslips.tabText",
        menuText:
          "menu.manage.managedServicesSection.manageManagedServicesPayslips.menuText",
        url: "/managed-services/payslips",
        roles: ["connect_payslips"],
        component: <Payslips />,
      },
    ],
  },
  {
    id: "sixStarSecureSection",
    text: "menu.manage.sixStarSecureSection.text",
    menuText: "menu.manage.sixStarSecureSection.menuText",
    url: "/six-star-secure",
    roles: ["user", "agent", "admin", "group_gm"],
    component: <NotFoundPage />,
    icon: ["fad", "shield-halved"],
    children: [
      {
        id: "sixStarSecureSectionDashboard",
        text: "menu.manage.sixStarSecureSection.dashboard.tabText",
        menuText: "menu.manage.sixStarSecureSection.dashboard.menuText",
        url: "/six-star-secure/dashboard",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "sixStarSecurEndpointProtection",
        text: "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.tabText",
        menuText:
          "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.menuText",
        url: "/six-star-secure/endpoint-protection",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "sixStarSecurEndpointDetection",
        text: "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.tabText",
        menuText:
          "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.menuText",
        url: "/six-star-secure/endpoint-detection",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "sixStarSecurFirewallManagement",
        text: "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.tabText",
        menuText:
          "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.menuText",
        url: "/six-star-secure/firewall-management",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "sixStarSecurVpn",
        text: "menu.manage.sixStarSecureSection.sixStarSecurVpn.tabText",
        menuText: "menu.manage.sixStarSecureSection.sixStarSecurVpn.menuText",
        url: "/six-star-secure/vpn",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "sixStarSecurDeviceEncryption",
        text: "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.tabText",
        menuText:
          "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.menuText",
        url: "/six-star-secure/device-encryption",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "sixStarSecurOffice365AzureManagement",
        text: "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.tabText",
        menuText:
          "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.menuText",
        url: "/six-star-secure/office365-azure",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <LicensePage />,
        disabled: true
      },
      {
        id: "sixStarSecurBackupDisasterRecovery",
        text: "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.tabText",
        menuText:
          "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.menuText",
        url: "/six-star-secure/backup-disaster-recovery",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "sixStarSecurSecurityReports",
        text: "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.tabText",
        menuText:
          "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.menuText",
        url: "/six-star-secure/security-reports",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
    ],
  },
  {
    id: "assetManagementSection",
    text: "menu.manage.assetManagementSection.text",
    menuText: "menu.manage.assetManagementSection.menuText",
    url: "/asset-management",
    roles: ["user", "agent", "admin", "group_gm"],
    component: <NotFoundPage />,
    icon: ["fad", "tablet"],
    children: [
      {
        id: "assetManagementDashboard",
        text: "menu.manage.assetManagementSection.assetManagementDashboard.tabText",
        menuText:
          "menu.manage.assetManagementSection.assetManagementDashboard.menuText",
        url: "/asset-management/dashboard",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "assetManagementAuthorisedHardwareCatalogue",
        text: "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.tabText",
        menuText:
          "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.menuText",
        url: "/asset-management/authorised-hardware-catalogue",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "assetManagementDeviceManagement",
        text: "menu.manage.assetManagementSection.assetManagementDeviceManagement.tabText",
        menuText:
          "menu.manage.assetManagementSection.assetManagementDeviceManagement.menuText",
        url: "/asset-management/device-management",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <AssetPage />,
        disabled: true
        // children: [
        //   {
        //     id: "cmdb",
        //     text: "Configuration Management Database (CMDB)",
        //     menuText: "Configuration Management Database (CMDB)",
        //     url: "/asset-management/device-management/cmdb",
        //     roles: ["user", "agent", "admin", "group_gm"],
        //     component: <NotFoundPage />,
        //   },
        //   {
        //     id: "osExtendHardwareSupportLicensing",
        //     text: "OS/Extend Hardware Support Licensing",
        //     menuText: "OS/Extend Hardware Support Licensing",
        //     url: "/asset-management/device-management/os-extend-licensing",
        //     roles: ["user", "agent", "admin", "group_gm"],
        //     component: <NotFoundPage />,
        //   },
        //   {
        //     id: "mobileDeviceManagement",
        //     text: "Mobile Device Management",
        //     menuText: "Mobile Device Management",
        //     url: "/asset-management/device-management/mobile-device-management",
        //     roles: ["user", "agent", "admin", "group_gm"],
        //     component: <NotFoundPage />,
        //   },
        // ],
      },
      // {
      //   id: "softwareLicenceManagementSection",
      //   text: "menu.manage.softwareLicenceManagementSection.text",
      //   menuText: "menu.manage.softwareLicenceManagementSection.menuText",
      //   url: "/asset-management/software-licence-management",
      //   roles: ["user", "agent", "admin", "group_gm"],
      //   component: <NotFoundPage />,
      //   children: [
      {
        id: "softwareLicenceManagementAuthorisedSoftwareCatalogue",
        text: "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.tabText",
        menuText:
          "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.menuText",
        url: "/asset-management/software-licence-management/authorised-software-catalogue",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "softwareLicenceManagementLicenceManagement",
        text: "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.tabText",
        menuText:
          "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.menuText",
        url: "/asset-management/software-licence-management/licence-management",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <LicensePage />,
        disabled: true
      },
      {
        id: "softwareLicenceManagementSoftwareManagement",
        text: "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.tabText",
        menuText:
          "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.menuText",
        url: "/asset-management/software-licence-management/software-management",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      //   ],
      // },
    ],
  },
  {
    id: "sixStarAdditionsSection",
    text: "menu.manage.sixStarAdditionsSection.text",
    menuText: "menu.manage.sixStarAdditionsSection.menuText",
    url: "/six-star-additions",
    roles: ["user", "agent", "admin", "group_gm"],
    component: <NotFoundPage />,
    icon: ["fad", "list-check"],
    children: [
      {
        id: "sixStarAdditionsGuestDeviceSupport",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.menuText",
        url: "/six-star-additions/guest-device-support",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarGuestServicesPage />,
        //disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarVoice",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.menuText",
        url: "/six-star-additions/six-star-voice",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarVoicePage />,
        //disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarVIP",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.menuText",
        url: "/six-star-additions/six-star-vip",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarVIPPage />,
        // disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarWiFi",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.menuText",
        url: "/six-star-additions/six-star-wifi",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarMeet",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.menuText",
        url: "/six-star-additions/six-star-meet",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarMeetPage />,
      },
      {
        id: "sixStarAdditionsSixStarTV",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.menuText",
        url: "/six-star-additions/six-star-tv",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarTVPage />,
        // disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarCast",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.menuText",
        url: "/six-star-additions/six-star-cast",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarCastPage />,
        //disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarPay",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.menuText",
        url: "/six-star-additions/six-star-pay",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarPayPage />,
        //disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarDisplay",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.menuText",
        url: "/six-star-additions/six-star-display",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarDisplayPage />,
        // disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarPark",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.menuText",
        url: "/six-star-additions/six-star-park",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarParkPage />,
        //disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarBlack",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.menuText",
        url: "/six-star-additions/six-star-black",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarBlackPage />,
        //disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarPrint",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.menuText",
        url: "/six-star-additions/six-star-print",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarPrintPage />,
        // disabled: true,
      },
      {
        id: "sixStarAdditionsSixStarInternet",
        text: "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.tabText",
        menuText:
          "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.menuText",
        url: "/six-star-additions/six-star-internet",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <SixStarInternetPage />,
        //disabled: true,
      },
    ],
  },
  {
    id: "manageFinanceSection",
    text: "menu.manage.financeSection.text",
    menuText: "menu.manage.financeSection.menuText",
    icon: ["fad", "money-bill"],
    roles: ["user", "agent", "admin", "group_gm"],
    component: <NotFoundPage />,
    icon: ["fad", "money-bill"],
    children: [
      {
        id: "manageFinanceDashboard",
        text: "menu.manage.financeSection.dashboard.tabText",
        menuText: "menu.manage.financeSection.dashboard.menuText",
        url: "/finance/dashboard",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "manageFinanceInvoices",
        text: "menu.manage.financeSection.Invoices.tabText",
        menuText: "menu.manage.financeSection.Invoices.menuText",
        url: "/finance/invoices",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "manageFinancequotesProposals",
        text: "menu.manage.financeSection.quotesProposals.tabText",
        menuText: "menu.manage.financeSection.quotesProposals.menuText",
        url: "/finance/quotes-proposals",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
      {
        id: "manageFinancecreditTerms",
        text: "menu.manage.financeSection.creditTerms.tabText",
        menuText: "menu.manage.financeSection.creditTerms.menuText",
        url: "/finance/credit-terms",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
    ],
  },
  {
    id: "manageinternalSection",
    text: "menu.manage.internalSection.text",
    menuText: "menu.manage.internalSection.menuText",
    roles: ["agent", "admin"],
    component: <NotFoundPage />,
    icon: ["fad", "network-wired"],
    children: [
      {
        id: "manageInternalResourceTracker",
        text: "menu.manage.internalSection.resourceTracker.tabText",
        menuText: "menu.manage.internalSection.resourceTracker.menuText",
        url: "/internal/resource-tracker",
        component: <ResourceTrackerPage />,
        roles: ["agent", "admin"],
      },
      {
        id: "manageInternalForms",
        text: "menu.manage.internalSection.forms.tabText",
        menuText: "menu.manage.internalSection.forms.menuText",
        url: "/internal/forms",
        component: <InternalForm />,
        roles: ["agent", "admin", "Engineer"],
      },
      {
        id: "manageInternalImpersonate",
        text: "menu.manage.internalSection.impersonate.tabText",
        menuText: "menu.manage.internalSection.impersonate.menuText",
        url: "/internal/impersonate",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <NotFoundPage />,
        disabled: true,
      },
    ],
  },
  {
    id: "manageAdminSection",
    text: "menu.manage.adminSection.text",
    menuText: "menu.manage.adminSection.menuText",
    roles: ["agent", "admin"],
    component: <NotFoundPage />,
    icon: ["fad", "user-crown"],
    url: "/admin",
    children: [
      {
        id: "manageUserCreateContact",
        text: "menu.manage.userSection.createContact.tabText",
        menuText: "menu.manage.userSection.createContact.menuText",
        url: "/user/create-contact",
        component: <CreateContact />,
        roles: ["agent", "admin"],
        disabled: false,
      },
      {
        id: "manageCreateOrganization",
        text: "menu.manage.userSection.createOrganization.tabText",
        menuText: "menu.manage.userSection.createOrganization.menuText",
        url: "/admin/create-organization",
        component: <CreateOrg />,
        roles: ["agent", "admin"],
        disabled: false,
      },
      {
        id: "manageUserContactSearch",
        text: "menu.manage.userSection.contactSearch.tabText",
        menuText: "menu.manage.userSection.contactSearch.menuText",
        url: "/admin/contact-search",
        component: <ContactSearch />,
        roles: ["agent", "admin"],
        disabled: false,
      },
      {
        id: "manageEngineerTicketBoard",
        text: "menu.manage.personContact.tabText",
        menuText: "menu.manage.personContact.menuText",
        roles: ["user", "agent", "admin", "group_gm"],
        url: "/admin/profile",
        isDynamic: true,
        component: <NotFoundPage />,
        children: [
          {
            id: "personProfile-:id",

            text: "menu.manage.personContact.tabText",
            menuText: "menu.manage.personContact.menuText",
            url: "/admin/profile/:id",
            roles: ["user", "agent", "admin", "group_gm"],
            component: <PersonContact />,
          },
        ],
      },
      // {
      //   id: "personProfile-:id",
      //   text: "menu.manage.personContact.tabText",
      //   menuText: "menu.manage.personContact.menuText",
      //   isDynamic: true,
      //   url: "/admin/:id",
      //   roles: ["user", "agent", "admin", "group_gm"],
      //   component: <PersonContact />,
      // },
      {
        id: "companyContact",
        text: "menu.manage.companyContact.tabText",
        menuText: "menu.manage.companyContact.menuText",
        url: "/admin/company/:id",
        roles: ["user", "agent", "admin", "group_gm"],
        component: <CompanyContact />,
      },
      {
        id: "manageUserMergeContacts",
        text: "menu.manage.userSection.mergeContacts.tabText",
        menuText: "menu.manage.userSection.mergeContacts.menuText",
        url: "/user/merge-contacts",
        component: <NotFoundPage />,
        roles: ["agent", "admin"],
        disabled: true,
      },
      {
        id: "manageAdminPanel",
        text: "menu.manage.userSection.adminPanel.tabText",
        menuText: "menu.manage.userSection.adminPanel.menuText",
        url: "/admin/admin-panel",
        component: <AdminPanel />,
        roles: ["admin_panel"],
      },
      // {
      //   id: "manageAuditLog",
      //   text: "audit log",
      //   menuText: "audit log",
      //   url: "/admin/audit-log",
      //   component: <AuditLog />,
      //   roles: ["agent"],
      // },
      // {
      //   id: "manageAuditLogGraph",
      //   text: "audit log graph",
      //   menuText: "audit log graph",
      //   url: "/admin/audit-log-graph",
      //   component: <AuditLogGraph />,
      //   roles: ["agent"],
      // },
    ],
  },
  // {
  //   id: "manageOrganisationSection",
  //   text: "menu.manage.organisationSection.text",
  //   menuText: "menu.manage.organisationSection.menuText",
  //   icon: ["fad", "building"],
  //   roles: ["agent", "admin", "user"],
  //   children: [
  //     {
  //       id: "companyContact",
  //       text: "menu.manage.companyContact.tabText",
  //       menuText: "menu.manage.companyContact.menuText",
  //       url: "/company/:id",
  //       roles: ["user", "agent", "admin", "group_gm"],
  //       component: <CompanyContact />,
  //     },
  //   ]
  // },
  {
    id: "manageHelpSection",
    text: "menu.manage.helpSection.text",
    menuText: "menu.manage.helpSection.menuText",
    icon: ["fad", "question-circle"],
    roles: ["user", "agent", "admin", "group_gm"],
    children: [
      {
        id: "manageHelpReleaseNotes",
        text: "menu.manage.helpSection.releaseNotes.tabText",
        menuText: "menu.manage.helpSection.releaseNotes.menuText",
        url: "/help/release-notes",
        component: <ReleaseNotes />,
        roles: ["agent", "admin", "user", "group_gm"],
      },
      {
        id: "manageHelpContactUs",
        text: "menu.manage.helpSection.contactUs.tabText",
        menuText: "menu.manage.helpSection.contactUs.menuText",
        url: "/help/contact-us",
        component: <ContactUs />,
        roles: ["user", "agent", "admin", "group_gm"],
      },
      {
        id: "manageHelpGiveFeedback",
        text: "menu.manage.helpSection.giveFeedback.tabText",
        menuText: "menu.manage.helpSection.giveFeedback.menuText",
        url: "/help/give-feedback",
        component: <Feedback />,
        roles: ["user", "agent", "admin", "group_gm"],
      },
    ],
  },
];

export default manage;
