const login = {
  "login.title": "SIX STAR GLOBAL",
  "login.headline": "Het toonaangevende IT Managed Services bedrijf ter wereld.",
  "login.signInText": "Sign Into Six Star: Manage",
  "login.topCallToActionTitle": "Geen onderdeel van Six Star?",
  "login.topCallToActionLink": "Laten we chatten",
  "loginPage.input.email": "E-mail",
  "loginPage.input.password": "Wachtwoord",
  "loginPage.input.mfa": "MFA-code",
  "loginPage.rememberMe": "Onthoud mij",
  "loginPage.button.login": "Inloggen",
  "loginPage.button.forgotPassword": "Wachtwoord vergeten?",
  "loginPage.apiMfaInProgress": "Voer uw MFA-code in",
  "login.forgotPasswordText": "Wachtwoord vergeten",
  "loginPage.apiMultipleUsers": "Gebruikersaccountfout, neem contact op met Six Star",
  "loginPage.apiNoPassword": "Voer wachtwoord in",
  "loginPage.apiNoUser": "Voer e-mailadres in",
  "loginPage.apiInvalidTOTP": "Ongeldige MFA-code",
  "loginPage.apiInvalidPassword": "Ongeldig wachtwoord",
  "login.sso.starting": "Single Sign-On starten",
  "loginPage.button.normalSignIn": "Inloggen met gebruikersnaam/wachtwoord",
  "loginPage.text.msssoLoading": "Laden...",
  "loginPage.label.msssoSignIn": "Inloggen met Microsoft",
  "loginPage.label.msssoSignInEmail": "Microsoft e-mailadres",
  "loginPage.text.msssoError": "Fout bij het inloggen",

  "loginPage.emailPrompt": "Voer hieronder uw e-mailadres in om uw wachtwoord opnieuw in te stellen.",
  "loginPage.ForgotPassword.GoBack": "Ga terug",
  "loginPage.ForgotPassword.Submit": "Verzenden",
  "loginPage.label.email": "E-mail",
  "loginPage.helpIcon": "Help",
  "loginPage.label.fullName": "Volledige naam",
  "loginPage.label.password": "Wachtwoord",
  "loginPage.label.confirmPassword": "Bevestig wachtwoord",
  "loginPage.register.button.goback": "Ga terug",
  "loginPage.register.button.register": "Registreren",
  "loginPage.register.button.signUp": "Aanmelden",

};
export default login;
