import React, { useEffect, useState } from "react";
// import { socket } from 'containers/App/Sagas/websocketSaga';
import { Slide } from "@mui/material";
import { useSnackbar } from "notistack";
import notificationWAV from "../sounds/notifications/messageNotification.wav";
import connectWav from "../sounds/notifications/siteConnected.wav";
import { useDispatch, useSelector } from "react-redux";
import { joinPage } from "containers/App/websocket/navigation";
import { registerSession } from "containers/App/websocket/registerSession";
import withStyles from "@mui/styles/withStyles";
import useTranslation from "containers/App/Hooks/useTranslation";

function isElectron() {
  if (
    typeof window !== "undefined" &&
    typeof window.process === "object" &&
    window.process.type === "renderer"
  ) {
    return true;
  }
  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!process.versions.electron
  ) {
    return true;
  }
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  ) {
    return true;
  }
  return false;
}

function OnlineStatus(props) {
  const { translate } = useTranslation();
  let socket = props.socket.socket;
  const socketStatus = useSelector((state) => state.socket.connected);
  // const { sounds } = useSelector((state) => state.user)
  const [online, setOnline] = useState();
  const [offline, setOffline] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [notificationSound] = useState(new Audio(notificationWAV));
  const [connectSound] = useState(new Audio(connectWav));
  const [playing, setPlaying] = useState(false);
  const [playingConnected, setPlayingConnected] = useState(false);

  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { userIsAuthenticated, user, mssso } = globalState.user;

  // const [soundsStatus, setSoundsStatus] = useState({})
  // const playNotificationSound = () => {
  //   //console.log(soundsStatus)
  //   //console.log({ 'start': soundsStatus.notification })
  //   if (soundsStatus.notification === true) {
  //     //console.log({ 'if': soundsStatus.notification })
  //     setPlaying(!playing);
  //   } else {
  //     //console.log({ 'else': soundsStatus.notification })
  //   }
  // }
  // const playConnectSound = () => setPlayingConnected(!playingConnected);

  //console.log(sounds)

  useEffect(
    (props) => {
      if (socketStatus === true) {
        if (userIsAuthenticated) {
          // console.log(user)
          dispatch(registerSession({ ...user, mssso: mssso }));
          let path = window.location.pathname;
          path = path.replace(/^\/+/, "");
          if (path === "") {
            path = "/";
          }
          dispatch(joinPage({ pathname: path, user: user }));
        }
      }
      // eslint-disable-next-line
    },
    [socketStatus]
  );

  useEffect(
    () => {
      playingConnected ? connectSound.play() : connectSound.pause();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playingConnected]
  );
  useEffect(
    () => {
      playing ? notificationSound.play() : notificationSound.pause();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playing]
  );
  useEffect(() => {
    notificationSound.addEventListener("ended", () => setPlaying(false));
    return () => {
      notificationSound.removeEventListener("ended", () => setPlaying(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    connectSound.addEventListener("ended", () => setPlayingConnected(false));
    return () => {
      connectSound.removeEventListener("ended", () =>
        setPlayingConnected(false)
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!socketStatus) {
      // if (isElectron()) {
      //     new Notification('Six Star Manage - Connection Status', { body: 'Offline' })
      // }
      // else {
      closeSnackbar(offline);
      const newOffline = enqueueSnackbar(translate("socket.disconnected"), {
        variant: "error",
        TransitionComponent: Slide,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        persist: true,
      });
      setOnline(newOffline);
      // }
    } else {
      // if (isElectron()) {
      //     new Notification('Six Star Manage - Connection Status', { body: 'Connected' })
      // }
      // else {
      closeSnackbar(online);
      // playConnectSound()
      const newOnline = enqueueSnackbar(translate("socket.connected"), {
        variant: "success",
        autoHideDuration: 3000,
        TransitionComponent: Slide,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        className: props.classes.snackbar,
      });
      setOffline(newOnline);
      // }
    }
    // eslint-disable-next-line
  }, [socketStatus]);
  useEffect(() => {
    //TODO:SOCKET

    // dispatch(incomingMessage()).then(data => {
    //   console.log({'onlineststau':data})
    // })

    socket.on("incomingMessage", (payload) => {
      let options = {
        variant: "warning",
        autoHideDuration: 3000,
        TransitionComponent: Slide,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        className: props.classes.snackbar,
      };
      if (isElectron()) {
        let desktopOptions = {
          body: payload,
          icon: "https://react.dave.domains/desktopNotificationIcon.png",
        };
        new Notification("", desktopOptions);
      } else {
        // playNotificationSound()
        enqueueSnackbar(payload.message, options);
      }
    });
    // eslint-disable-next-line
  }, []);
  return <></>;
}

export default withStyles(
  (theme) => ({
    snackbar: {
      borderRadius: `${theme.borderRadius}!important`,
    },
  }),
  { withTheme: true }
)(OnlineStatus);
