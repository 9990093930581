import React, { useEffect, useState, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Hidden from "@mui/material/Hidden";
import Header from "containers/Layout/Header";
import Auth from "containers/Auth";
import { useDocumentTitle, useDocumentVisibility } from "@mantine/hooks";
import { findMenuItem, isMobile, updateMenuDimensions } from "utils/menuHelper";
import {
  closeView,
  openView,
  selectMenuItem,
} from "containers/App/Redux/menu/actions";
import layoutStyles from "containers/Layout/styles";
import LeftSideBar from "containers/Layout/LeftSideBar";
import MainContent from "components/Layout/MainContent";
import GlobalStyle from "global-styles";
import { CombinedRoutes } from "./Redux/menu/menu";
import { WebsocketChecker } from "utils/WebsocketChecker";
import OnlineStatus from "utils/OnlineStatus";
import { LogoutListener } from "utils/LogoutListener";
import withMaterialUI from "../Layout";
import Translation from "utils/translation";
import { joinPage } from "containers/App/websocket/navigation";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas as fasFree } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import apolloCreate from "utils/apolloCreate";
import { ApolloProvider } from "@apollo/client";
import NotificationSubscriber from "utils/NotificationSubscriber";
import { useLazyQuery } from "@apollo/client";
import getConfig from "utils/getConfig";
import { setConfig } from "slices/app";
import NotificationDrawer from "containers/Layout/NotificationDrawer";
import FirstTimeSetup from "components/FirstTimeSetup";
import FloatingBeta from "components/FloatingBeta";
import { refreshToken as refreshTokenSocket } from "containers/App/websocket/refreshToken";
import { addTokens, setRedirectTo } from "slices/user";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import QuickCreateTicket from "Modals/QuickCreateTicket";
import { SiteDataProvider, SiteData } from 'context/siteData';
import LoadingIndicator from "components/LoadingIndicator";
import { PeopleProvider } from "context/people";
import { OrgProvider } from "context/orgs";

function App(props) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE)
  const globalState = useSelector((state) => state);

  const location = useSelector((state) => state.router.location);
  const menuState = useSelector((state) => state.menu);
  const { user, tokens } = globalState.user;

  const dispatch = useDispatch();
  const { GET_CONFIG } = getConfig();
  const [getConfigCall, { loading, error, data }] = useLazyQuery(GET_CONFIG);
  const documentState = useDocumentVisibility();
  const isDataTable = window.location.href.includes('/support/tickets');


  var currentURL = window.location.href;
  var pattern = /\/ticket\/\d+/;
  if (pattern.test(currentURL)) {
    console.log("The URL includes a ticket with ID");
    var ticketId = currentURL.split("/").pop();
    window.location.href = `https://manage.sixstar.global/ticket/${ticketId}`;

  }

  const refreshTokenInternal = async () => {
    try {
      // let payload = await  (store.dispatch({type: REFRESH_TOKEN, payload: store.getState().user?.tokens?.refreshToken}))

      // console.log("starting refresh token");

      let [payload] = await Promise.all([
        dispatch(refreshTokenSocket(tokens.refreshToken)),
      ]);
      // console.log("got payload", payload);
      if (payload.result.error) {
        return null;
      }
      if (!payload.result.error) {
        let [tokenPayload] = await Promise.all([
          dispatch(addTokens(payload.result.data)),
        ]);

        return tokenPayload.payload.token;
      }
      return null;
    } catch (err) {
      console.log("err - maybe log out", err);
      throw err;
    }
  };
  useEffect(() => {
    //if mssso.expiresOn exists and has less than 10 minutes console.log refresh
    if (
      tokens?.expiresAt &&
      documentState === "visible" &&
      userIsAuthenticated
    ) {
      const now = new Date();
      const expiresOn = new Date(tokens.expiresAt);
      const diff = expiresOn - now;
      const minutes = Math.floor(diff / 1000 / 60);
      // console.log("minutes", minutes);
      // if (minutes <= 5) {
      //   refreshTokenInternal().then((r) => {});
      // }
    }
  }, [documentState]);
  useEffect(() => {
    if (globalState?.user?.mssso?.accessToken) {
      // if (!globalState?.user?.user?.avatar) {
      //   console.log('getting avatar')
      //   dispatch(msssoCall('/me/photo/$value', globalState.user.mssso.accessToken))
      //     .then(res => {
      //       if (res.type === 'microsoft:TEST/SUCCESS') {
      //         dispatch(setAvatar(URL.createObjectURL(res.result)))
      //       }
      //     })
      //     .catch(err => {
      //       console.log(err)
      //     })
      // }
      //
      // if (globalState?.user?.email?.inbox?.length < 1) {
      //   console.log('getting email inbox')
      //   dispatch(msssoCall('/me/mailFolders/inbox/messages?$top=100', globalState.user.mssso.accessToken))
      //     .then(res => {
      //       if (res.type === 'microsoft:TEST/SUCCESS') {
      //         dispatch(setEmailInbox(res.result.value))
      //       }
      //     })
      //     .catch(err => {
      //       console.log(err)
      //     })
      // }
      //
      // dispatch(msssoCall('/subscriptions', globalState.user.mssso.accessToken))
      //   .then(res => {
      //     if (res.type === 'microsoft:TEST/SUCCESS') {
      //       dispatch(setEmailInbox(res.result.value))
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    }
  }, [globalState?.user?.user?.avatar, globalState?.user?.email]);

  useEffect(() => {
    // console.log("getting config", user);
    if (user.id && !data) {
      // console.log('Running Get Config', data, user)
      getConfigCall();
    }
    if (data && user.id) {
      // console.log(data)
      // console.log('Setting Config', data, user)
      dispatch(setConfig(data));
    }
  }, [data, user]);

  const menu = menuState.menu;
  const currentMenu = menuState.currentMenu;
  const openViews = menuState.openViews[menuState.currentMenu];
  const selectedMenuItem = menuState.selectedMenuItem[menuState.currentMenu];
  const showHeaderTabs = menuState.showHeaderTabs;
  const userIsAuthenticated = globalState.user.userIsAuthenticated;
  const mssso = globalState.user.mssso;
  const [open, setOpen] = useState(true);
  const [prevUrl, setPrevUrl] = useState(location.pathname);
  const title = selectedMenuItem.dynamic
    ? Translation(selectedMenuItem.text, { id: selectedMenuItem.itemId })
    : Translation(selectedMenuItem.text);

  library.add(fal, fasFree, far, fas, fad, fab);
  useEffect(() => {
    window.addEventListener("resize", updateMenuDimensions);
    updateMenuDimensions();
    setInitialSelectedMenuItem();

    return window.removeEventListener("resize", updateMenuDimensions);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setTimeout(() => {
      updateMenuDimensions();
    }, 0);
  }, [props]);

  useEffect(() => {
    const url = location.pathname;
    if (url !== prevUrl) {
      if (selectedMenuItem?.text) {
        document.title = title + " - Six Star Manage";
      }

      let result = findMenuItem(menu, "url", url);
      const { foundMenuItem, foundParentMenuItem } = result;

      let resultTwo = findMenuItem(openViews, "url", url);
      const foundOpenViewItem = resultTwo.foundMenuItem;

      if (foundMenuItem) {
        if (!foundOpenViewItem) {
          const icon = foundParentMenuItem
            ? foundParentMenuItem.icon
            : foundMenuItem.icon;
          dispatch(openView(foundMenuItem, icon, openViews)); // Add new openView item
        } else {
          dispatch(selectMenuItem(foundMenuItem, foundOpenViewItem, openViews));
        }
      }
      if (isMobile()) {
        setOpen(false);
      }
      setPrevUrl(url);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
    };
    // eslint-disable-next-line
  }, []);

  //check for  redirect
  useEffect(() => {

    if (userIsAuthenticated) {


      if (mssso.redirectTo) {

        dispatch(setRedirectTo(null))
        props.history.push(mssso.redirectTo)
      }
    }
  }, [userIsAuthenticated, mssso]);
  const onFocus = () => {
    let path = window.location.pathname;
    path = path.replace(/^\/+/, "");
    if (path === "") {
      path = "/";
    }
    dispatch(joinPage({ pathname: path, user: user }));
  };

  const setInitialSelectedMenuItem = () => {
    const { pathname } = location;

    if (selectedMenuItem && selectedMenuItem.url !== pathname) {
      const { foundMenuItem, foundParentMenuItem } = findMenuItem(
        menu,
        "url",
        pathname
      );
      if (foundMenuItem) {
        const icon = foundParentMenuItem
          ? foundParentMenuItem.icon
          : foundMenuItem.icon;

        dispatch(openView(foundMenuItem, icon, openViews)); // Add new openView item
      }
    }
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleCloseView = (menuItem) => {
    const openedView = openViews.find((item) => item.id === menuItem.id);
    let indexToBeRemoved = openViews.indexOf(openedView);

    const isParentMenuItem = menuItem.children ?? false;
    let isChildMenuItem = false;

    if (menuItem.parent) {
      isChildMenuItem = true;
    }

    if (indexToBeRemoved > 0) {
      if (isParentMenuItem) {
        const parentMenuFirstChild = openViews.find(
          (item) => item.id === menuItem.children[0].id
        );
        indexToBeRemoved = openViews.indexOf(parentMenuFirstChild);
      }

      // find previous menu item
      const previousItem = openViews[indexToBeRemoved - 1];
      let result = findMenuItem(menu, "id", previousItem.id);
      let { foundMenuItem } = result;

      // find previous open view
      result = findMenuItem(openViews, "id", previousItem.id);
      let foundOpenViewItem = result.foundMenuItem;

      if (foundMenuItem) {
        // create array of ids to be removed
        let idsToBeRemoved = [menuItem.id];
        if (isParentMenuItem) {
          idsToBeRemoved = menuItem.children.map((child) => child.id);
        }

        if (isChildMenuItem) {
          openViews.map((item) => {
            if (
              item?.parent?.parentId ===
              openViews[indexToBeRemoved].parent.parentId &&
              item?.id !== openViews[indexToBeRemoved].id
            ) {
              foundOpenViewItem = item;
              foundMenuItem = item;
            }
            return null;
          });
        }
        dispatch(closeView(foundMenuItem, foundOpenViewItem, idsToBeRemoved));
      }
    }
  };

  const renderHeader = () => {
    return (
      <Header
        drawerIsOpen={open}
        handleDrawerToggle={handleDrawerToggle}
        handleCloseView={handleCloseView}
      />
    );
  };

  const renderLeftSideBar = (type) => {
    return (
      <LeftSideBar
        type={type}
        drawerIsOpen={open}
        closeView={() => { }}
        handleDrawerToggle={handleDrawerToggle}
        handleCloseView={handleCloseView}
      />
    );
  };

  const renderMainContent = () => {
    return (
      <MainContent
        sidebarIsOpen={open}
        selectedMenuItem={selectedMenuItem}
        showHeaderTabs={showHeaderTabs}
      >
        {CombinedRoutes(location, menu, currentMenu)}
      </MainContent>
    );
  };

  return (
    <div id="app-wrapper">
      {!userIsAuthenticated ? (
        <Auth />
      ) : (
        <div className="app-frame">
          <WebsocketChecker />
          <OnlineStatus socket={props.socketClient} />
          <NotificationSubscriber />
          {/* <FirstTimeSetup /> */}
          {/* Header */}
          {renderHeader()}
          {/* Left Bar */}
          <Hidden mdUp>{renderLeftSideBar("temporary")}</Hidden>
          <Hidden mdDown implementation="css">
            {renderLeftSideBar("permanent")}
          </Hidden>
          {/* Main Content */}
          {renderMainContent()}
          {/* Right Bar / Settings */}
          <NotificationDrawer location={location} />
          {!isDataTable && (
            <FloatingBeta />
          )}
          <QuickCreateTicket />
        </div>
      )}
      <GlobalStyle />
      <LogoutListener />
    </div>
  );
}

function Apollo(props) {
  const { socketClient, history, store } = props;
  const tokens = useSelector((state) => state.user.tokens)

  const dispatch = useDispatch();
  return (
    <ApolloProvider
      client={apolloCreate({ store })}
    >
      <SiteDataProvider>
        <PeopleProvider socketClient={socketClient}>
          <OrgProvider socketClient={socketClient}>
            <FinalAppLoader {...props} socketClient={socketClient} history={history} />
          </OrgProvider>
        </PeopleProvider>
      </SiteDataProvider>
    </ApolloProvider>
  );
}

function FinalAppLoader(props) {
  const { DBReady } = useContext(SiteData);

  if (!DBReady) return (
    <div class="spinner-container">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  )
  return (
    <App socketClient={props.socketClient} history={props.history} />
  )
}

export default withMaterialUI(
  withStyles((theme) => layoutStyles(theme), {
    withTheme: true,
  })(Apollo)
);
