import { gql } from "@apollo/client";

const graphQLQueries = () => {
  const GET_COMMENT_BODY = gql`
    query MyQuery($id: uuid = "") {
      ticket_comment_by_pk(id: $id) {
        source {
          body
        }
      }
    }
  `;
  return {
    GET_COMMENT_BODY,
  };
};

export default graphQLQueries;
