const styles = (theme) => ({
  FlagNumberGroupContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});

export default styles;
