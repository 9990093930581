import { set, cloneDeep } from 'lodash';
import { gql } from '@apollo/client';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';

const updatePaginationQuery = (query, tableName, page, pageSize) => {
    const newQuery = cloneDeep(query);

    newQuery.query[tableName].__args = {
        ...newQuery.query[tableName].__args,
        limit: pageSize,
        offset: page * pageSize
    };

    return newQuery;
};

export default updatePaginationQuery;