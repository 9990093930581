import React, { useState, useEffect, useRef } from "react";
import { useScrollIntoView } from "@mantine/hooks";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import PageBase from "components/PageBase";
import { Box } from "@mui/system";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import useTranslation from "containers/App/Hooks/useTranslation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles";
import { useTheme } from "@mui/system";
import TVHeader from "images/tv/TVHeader.png";
import plus from "images/tv/plus.png";
import QuickCreateTicket from "Modals/QuickCreateTicket";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from '@mui/material/Button';
import HotelRoom from "images/tv/HotelRoom.png";
import TVStill2 from "images/tv/TVStill2.png";


const SixStarTVPage = ({ classes }) => {
    const theme = useTheme();
    const { translate } = useTranslation();
    const [loading, setLoading] = useState(true);
    const isPhoneSize = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        let loadingTimer = setTimeout(() => setLoading(false), 300);
        return () => {
            clearTimeout(loadingTimer);
        };
    });


    return (
        <>
            <PageBase minHeight={500} loading={loading} noPadding={true}>
                {/* <QuickCreateTicket /> */}
                <Box sx={{ background: '#8777E4' }}>
                    <img

                        src={TVHeader}
                        alt={"Six Star TV"}
                        style={{
                            width: "67%",
                            margin: "auto",
                            display: "block",
                        }}
                    />

                    <Box sx={{ background: "white!important", height: 'auto', }}>
                        <Typography
                            variant={"h3"}
                            align={"center"}
                            sx={{
                                fontWeight: 500,
                                display: "block",
                                paddingTop: theme.spacing(6)

                            }}
                        >
                            {translate("sixStarTVPage.header")}
                        </Typography>
                        <Box
                            align={"center"}
                            sx={{
                                paddingTop: theme.spacing(4),
                                display: "block",
                                paddingX: 4
                            }}>
                            <Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit </Typography>
                        </Box>

                        <Box spacing={5} style={{ alignItems: 'center', justifyContent: 'center', paddingTop: theme.spacing(4), height: 'auto', display: 'flex', flexDirection: isPhoneSize ? "column" : "row" }}>
                            <Box sx={{ padding: 2, width: isPhoneSize ? 'auto': '30%' }}>

                                <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "link"]}
                                    color="white"
                                    style={{
                                        fontSize: "5rem",
                                        backgroundColor: "#4FCF83",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" fontWeight={500}>{translate("sixStarTVPage.integration")}</Typography>
                                <Typography align="center" style={{ whiteSpace: "normal" }}>{translate("sixStarTVPage.p3")}</Typography>
                            </Box>
                            <Box sx={{ padding: 2, width: isPhoneSize ? 'auto': '30%' }}>
                                <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "browser"]}
                                    color="white"
                                    style={{
                                        fontSize: "5rem",
                                        backgroundColor: "#87CAEE",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" fontWeight={500}>{translate("sixStarTVPage.webBasedManagement")}</Typography>
                                <Typography align="center" style={{ whiteSpace: "normal" }}>{translate("sixStarTVPage.p4")}</Typography>
                            </Box>

                            <Box sx={{ padding: 2, width: isPhoneSize ? 'auto': '30%' }}>
                                <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "gamepad-modern"]}
                                    color="white"
                                    style={{
                                        fontSize: "7rem",
                                        backgroundColor: "#DAD9EC",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" fontWeight={500}>{translate("sixStarTVPage.gaming")}</Typography>

                                <Typography align="center" style={{ whiteSpace: "normal" }}>{translate("sixStarTVPage.p5")}</Typography>
                            </Box>



                        </Box>

                        <Box
                            sx={{ background: "#8777E4", marginTop: theme.spacing(4), height: 'auto', paddingBottom: 8 }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ marginTop: 8 }}>
                                        <img

                                            src={TVStill2}
                                            alt={"Six Star TV"}
                                            style={{
                                                width: "90%",
                                                margin: "auto",
                                                display: "block",
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ width: '100%', align: 'center', paddingX: 8, marginBottom: 2 }}>
                                        <Typography align={"center"} variant="h4" sx={{ color: "white!important", marginTop: 16, fontWeight: 500, paddingBottom: 6 }}>{translate("sixStarTVPage.fullyCustomisable")}</Typography>
                                        <Typography align={"center"} sx={{ color: "white!important" }}>{translate("sixStarTVPage.fullyCustomizable1080p")}</Typography>

                                    </Box>
                                    <Box sx={{ width: '100%', align: 'center', justifyContent: 'center', display: 'flex', }}>
                                        <Button align ="center" variant="contained" style={{ backgroundColor: '#87CAEE', width: '45%', }}>
                                            <Typography align={"center"} sx={{ color: 'white' }}>{translate("sixStarTV.design")}</Typography>
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>



                        </Box>

                        <Box
                            sx={{ background: "white", marginTop: theme.spacing(4), height: 'auto', paddingBottom: 8 }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={isPhoneSize ? 12 : 6}>



                                    <Box sx={{ width: '100%', align: 'center', paddingX: 8 }}>
                                        <Typography align={"center"} variant="h4" sx={{ marginTop: 16, fontWeight: 500, paddingBottom: 6 }}>{translate("sixStarTVPage.externalTVInputs")}</Typography>
                                        <Typography align={"center"} sx={{}}>{translate("sixStarTVPage.p2")}</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ marginTop: 8 }}>
                                        <img

                                            src={HotelRoom}
                                            alt={"Six Star TV"}
                                            style={{
                                                width: "90%",
                                                margin: "auto",
                                                display: "block",
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>



                        </Box>

                    </Box>

                    <Box sx={{ background: "#8777E4", height: 'auto', paddingBottom: 8 }}>
                        <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Typography align={"center"} variant="h4" sx={{ color: "white!important", fontWeight: 500, paddingTop: theme.spacing(6) }}>{translate("sixStarTVPage.extraFeatures")}</Typography>
                            <Typography align={"center"} sx={{ color: "white!important", }}>{translate("sixStarTVPage.plus")}</Typography>
                        </Box>
                        <Box>



                            <Grid container spacing={3} sx={{ paddingY: theme.spacing(4), display: 'flex', flexDirection: isPhoneSize ? 'column' : 'row' }}>
                                <Grid item xs={isPhoneSize ? 12 : 3}>
                                    <Box sx={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: 3 }}>
                                        <img

                                            src={plus}
                                            alt={"Six Star TV Plus"}
                                            style={{
                                                width: "67%",
                                                margin: "auto",
                                                display: "block",
                                            }}
                                        />
                                        {/* <FontAwesomeIcon
                                        icon={["fas", "plus"]}
                                        color="white"
                                        style={{
                                            fontSize: "7rem",
                                            backgroundColor: "#4FCF83",
                                            borderRadius: "50%",
                                            padding: "2rem",
                                            width: "1rem",
                                            height: "1rem",
                                            margin: "auto",
                                            marginBottom: theme.spacing(1),
                                            display: "block",
                                        }}
                                    /> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {/* room service icon */}
                                        <Box >
                                            <FontAwesomeIcon
                                                size="2x"
                                                icon={["fas", "pot-food"]}
                                                color="white"
                                                style={{
                                                    fontSize: "7rem",
                                                    backgroundColor: "#87CAEE",
                                                    borderRadius: "100%",
                                                    padding: "2rem",
                                                    width: "2rem",
                                                    height: "2rem",
                                                    margin: "auto",
                                                    marginBottom: theme.spacing(1),
                                                    display: "block",
                                                }}
                                            />

                                            <Typography align="center" style={{ color: 'white' }}>{translate("sixStarTVPage.roomService")}</Typography>
                                        </Box>

                                        {/* wake up icon */}
                                        <Box>
                                            <FontAwesomeIcon
                                                size="2x"
                                                icon={["fas", "clock-desk"]}
                                                color="white"
                                                style={{
                                                    fontSize: "7rem",
                                                    backgroundColor: "#87CAEE",
                                                    borderRadius: "100%",
                                                    padding: "2rem",
                                                    width: "2rem",
                                                    height: "2rem",
                                                    margin: "auto",
                                                    marginBottom: theme.spacing(1),
                                                    display: "block",
                                                }}
                                            />

                                            <Typography align="center" style={{ color: 'white' }}>{translate("sixStarTVPage.wakeUp")}</Typography>
                                        </Box>

                                        {/* international viewing */}
                                        <Box>
                                            <FontAwesomeIcon
                                                size="2x"
                                                icon={["fas", "earth"]}
                                                color="white"
                                                style={{
                                                    fontSize: "7rem",
                                                    backgroundColor: "#87CAEE",
                                                    borderRadius: "100%",
                                                    padding: "2rem",
                                                    width: "2rem",
                                                    height: "2rem",
                                                    margin: "auto",
                                                    marginBottom: theme.spacing(1),
                                                    display: "block",
                                                }}
                                            />

                                            <Typography align="center" style={{ color: 'white' }}>{translate("sixStarTVPage.internationalViewing")}</Typography>

                                        </Box>

                                    </Box>
                                </Grid>
                                <Grid item xs={isPhoneSize ? 12 : 3}>
                                    <Box sx={{ alignItems: isPhoneSize ? 'center' : 'flex-end', justifyContent: 'center', paddingRight: theme.spacing(2) }}>
                                        <Typography align={"center"} sx={{ color: "white!important", }}>{translate("sixStarTVPage.offer")}</Typography>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>

                        <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>

                            <Button align="center" variant="contained" style={{ backgroundColor: 'white', width: '20%', mx: 'auto' }}>
                                <Typography align={"center"} sx={{ color: 'black' }}>{translate("sixStarPlus.getInTouch")}</Typography>
                            </Button>


                        </Box>
                    </Box>

                </Box>


            </PageBase>

        </>
    );

}

export default SixStarTVPage;