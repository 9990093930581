import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

const SSGFormOption = ({ field, handleChange, currentValue }) => {
  const { label, name, required, options, option_other } = field
  const [otherSelected, setOtherSelected] = React.useState(currentValue === 'Other');
  const [otherValue, setOtherValue] = React.useState('');

  const handleRadioChange = (event) => {
    handleChange(event);
    setOtherSelected(event.target.value === 'Other');
  };

  const handleOtherChange = (event) => {
    setOtherValue(event.target.value);
    // Assuming handleChange takes an event-like object with target.name and target.value
    handleChange({ target: { name, value: event.target.value } });
  };

  return (
    <div>
      {currentValue !== undefined && (
        <>
          <label>{label}{required && '*'}</label>
          {options.length > 0 &&
            <RadioGroup
              name={name}
              required={required}
              onChange={handleRadioChange}
              defaultValue={currentValue}
            >
              {options.map((option, index) => {
                let checked = false
                if (option.value === currentValue) {
                  checked = true
                } else {
                  checked = false
                }
                return (
                  <FormControlLabel aria-checked={checked} defaultChecked={checked} key={index} value={option.value} control={<Radio />} label={option.label} />
                )
              })}
              {option_other && <FormControlLabel value="Other" control={<Radio />} label="Other" />}
            </RadioGroup>
          }
          {otherSelected && <TextField label="Other" value={otherValue} onChange={handleOtherChange} />}
        </>
      )}
    </div>
  )
}

export default SSGFormOption