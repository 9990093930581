import { createIntl, createIntlCache } from 'react-intl'
import * as Locale from "../i18n";

export const Translation = (tag, variables = null) => {

  // get local storage
  const persistedState = localStorage.getItem('persist:manage') ? JSON.parse(localStorage.getItem('persist:manage')) : {}
  let incomingLocal = 'en'
  if (persistedState?.language) {
    incomingLocal = JSON.parse(persistedState.language)?.locale ?? 'en'
  }
  const locale = incomingLocal;
  const messages = Locale.translationMessages[locale];
  const cache = createIntlCache();
  const intl = createIntl({ locale, messages }, cache)
  // const intl = useIntl();
  // console.log(intl)
  let message = "";

  try {
    if (
      variables !== null &&
      !variables?.id.match(/\d+/g) &&
      variables?.id.includes("-")
    ) {
      // Remove the dash in variables.id and make it camelcase
      variables.id = variables.id.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });

      tag = tag + "." + variables.id;
      // console.log(tag)
      variables = null;
    }
  } catch (error) {
    // console.log(error);
  }

  try {
    if (variables !== null) {
      // console.log(tag, variables);
      message = intl.formatMessage(
        {
          id: tag,
          defaultMessage: tag + " Not Defined",
          description: "Translation for " + tag,
        },
        { ...variables }
      );
    } else {
      message = intl.formatMessage({
        id: tag,
        defaultMessage: tag + " Not Defined",
        description: "Translation for " + tag,
      });
    }
  } catch (error) {
    console.log(error);
  }

  return message;
};

export default Translation;