import React, { useState, useEffect } from 'react';
import withStyles from "@mui/styles/withStyles";
import { Box, FormControl, InputLabel, Select, TextField, Typography, Button, Menu, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Translation from "utils/translation";
import styles from './styles';



function View(props) {
    const {
        classes,
        viewArray,
        currentView,
        setCurrentView,
        handleDeleteView,
        handleRenameView,
        handleUpdateView,
        handleAddView,
    } = props;

    const [viewOpen, setViewOpen] = useState(false)
    const [anchorDelete, setAnchorDelete] = useState(null)
    const [anchorSave, setAnchorSave] = useState(null)
    const [edittingView, setEdittingView] = useState(null)
    const [newViewTextField, setNewViewTextField] = useState('')
    const [newViewDuplicate, setNewViewDuplicate] = useState(false)

    const handleEditKeyDown = async (e) => {
        if (e.key === 'Enter') {
            if (edittingView !== null) {
                handleRenameView(edittingView, e.target.value)
                setEdittingView(null)
            }
        }
    };

    const handleNewViewKeyDown = async (e) => {
        if (e.key === 'Enter') {
            if (_.findIndex(viewArray, { name: e.target.value }) === -1) {
                handleAddView(e.target.value)
                setNewViewTextField('')
            }
        }
    };

    const handleViewClose = (e, v) => {
        if (e.target.innerText !== undefined) {
            if (anchorDelete === null) {
                if (edittingView === null) {
                    setViewOpen(false)
                }
            }
        }
    };

    const handleViewOpen = () => {
        setViewOpen(true)
    }

    useEffect(() => {
        if (newViewTextField !== '') {
            if (_.findIndex(viewArray, { name: newViewTextField }) === -1) {
                setNewViewDuplicate(false)
            } else {
                setNewViewDuplicate(true)
            }
        }
    }, [newViewTextField])

    return (
        <>
            <FormControl
                style={{ marginLeft: '16px', width: '10%' }}
            >
                <InputLabel id="select-view-label">{Translation("TicketViewPage.toolbar.view")}</InputLabel>
                <Select
                    labelId="select-view-label"
                    label={Translation("TicketViewPage.toolbar.view")}
                    open={viewOpen}
                    onOpen={handleViewOpen}
                    onClose={(e, v) => handleViewClose(e, v)}
                    value={currentView}
                    onChange={(value) => {
                        if (edittingView === null) {
                            setCurrentView(value?.target?.value)
                        }
                    }}
                    renderValue={(index) => (
                        <Box
                        >
                            {viewArray[index]?.name}
                        </Box>
                    )}
                >
                    {viewArray.map((view, index) => {
                        return (
                            <Box
                                key={index}
                                value={index}
                                sx={{
                                    display: 'flex',
                                    marginLeft: 'auto',
                                    padding: edittingView === index ? '8px 16px 8px 14px' : '8px 16px',
                                }}
                            >
                                {edittingView === index ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexGrow: 1,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <TextField
                                            defaultValue={view?.name}
                                            variant="standard"
                                            onKeyDown={handleEditKeyDown}
                                            autoFocus={true}
                                            size="small"
                                            sx={{
                                                height: '24px',
                                                padding: '0',
                                                margin: '0',
                                            }}
                                        />
                                    </Box>
                                ) : (
                                    <Box
                                        onClick={() => {
                                            setCurrentView(index)
                                            setViewOpen(false)

                                        }}
                                        sx={{
                                            display: 'flex',
                                            flexGrow: 1,
                                            cursor: 'pointer',
                                            height: '24px',
                                            padding: '0',
                                            margin: '0',
                                        }}
                                    >
                                        {'\u200B' + view?.name}
                                    </Box>
                                )}
                                {view?.global ? (

                                    <FontAwesomeIcon
                                        style={{ padding: '2px' }}
                                        icon={["fa-light", "pencil-slash"]}
                                        fixedWidth
                                        size="m"
                                        disabled={true}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        onClick={() => {
                                            if (edittingView === index) {
                                                setEdittingView(null)
                                            } else {
                                                setEdittingView(index)
                                            }
                                        }}
                                        style={{ marginLeft: '16px', padding: '2px' }}
                                        cursor="pointer"
                                        icon={["fas", "pencil"]} fixedWidth
                                        size="m"
                                    />
                                )}
                                {view?.default_flag || view?.global ? (
                                    <FontAwesomeIcon
                                        style={{ padding: '2px' }}
                                        icon={["fa-light", "trash-can-slash"]}
                                        fixedWidth
                                        size="m"
                                        disabled={true}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        onClick={(event) => setAnchorDelete({ target: event.currentTarget, index: index })}
                                        style={{ padding: '2px' }}
                                        cursor="pointer"
                                        icon={["fas", "trash-can"]}
                                        fixedWidth
                                        size="m"
                                    />
                                )}
                                <Menu
                                    anchorEl={anchorDelete?.target}
                                    open={Boolean(anchorDelete)}
                                    onClose={() => setAnchorDelete(null)}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            marginLeft: 'auto',
                                            padding: '8px 16px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography id="modal-modal-title" variant="h6" component="h2">{Translation("TicketViewPage.toolbar.modal.areYouSure")}</Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}>
                                            <Button
                                                onClick={() => {
                                                    handleDeleteView(anchorDelete?.index)
                                                    setAnchorDelete(null)
                                                }}
                                            >{Translation("TicketViewPage.toolbar.modal.yes")}</Button>
                                            <Button
                                                onClick={() => setAnchorDelete(null)}
                                            >{Translation("TicketViewPage.toolbar.modal.no")}</Button>
                                        </Box>
                                    </Box>
                                </Menu>
                            </Box>
                        )
                    })}
                    <Box
                        sx={{
                            display: 'flex',
                            marginLeft: 'auto',
                            padding: '8px 16px',
                        }}
                    >
                        <TextField
                            placeholder={Translation("TicketViewPage.toolbar.saveViewAs")}
                            variant="standard"
                            onKeyDown={handleNewViewKeyDown}
                            size="small"
                            value={newViewTextField}
                            onChange={(e) => setNewViewTextField(e.target.value)}
                            error={newViewDuplicate}
                            helperText={newViewDuplicate ? Translation("TicketViewPage.toolbar.saveViewAsDuplicate") : null}
                            sx={{
                                minHeight: '24px',
                                padding: '0',
                                margin: '0',
                                flexGrow: 1,
                                display: 'flex',
                            }}
                        />
                    </Box>
                </Select>
            </FormControl>
            <FontAwesomeIcon
                style={{ marginLeft: '16px' }}
                cursor="pointer"
                onClick={(event) => {
                    setAnchorSave(event.currentTarget)
                }}
                icon={["fas", "floppy-disk"]} fixedWidth
                size="xl"
            />
            <Menu
                anchorEl={anchorSave}
                open={Boolean(anchorSave)}
                onClose={() => setAnchorSave(null)}
            >
                <MenuItem
                    onClick={(e) => {
                        handleUpdateView(currentView)
                        setAnchorSave(null)
                    }}>
                    {Translation("TicketViewPage.toolbar.saveView")}
                </MenuItem>
                <Box
                    sx={{
                        display: 'flex',
                        marginLeft: 'auto',
                        padding: '8px 16px',
                    }}>
                    <TextField
                        placeholder={Translation("TicketViewPage.toolbar.saveViewAs")}
                        variant="standard"
                        onKeyDown={handleNewViewKeyDown}
                        size="small"
                        value={newViewTextField}
                        onChange={(e) => setNewViewTextField(e.target.value)}
                        error={newViewDuplicate}
                        helperText={newViewDuplicate ? Translation("TicketViewPage.toolbar.saveViewAsDuplicate") : null}
                        sx={{
                            minHeight: '24px',
                            padding: '0',
                            margin: '0',
                            flexGrow: 1,
                            display: 'flex',
                        }}
                    />
                </Box>
            </Menu>

        </>
    )
}

export default withStyles(
    (theme) => ({
        ...styles(theme),
    }),
    { withTheme: true }
)(View);
