import { combineReducers } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import userReducer from "slices/user";
import languageProviderReducer from "slices/language";
import menuReducer from "containers/App/Redux/menu/reducer";
import widgetsReducer from "slices/widgets";
import socketReducer from "slices/socket";
import themeReducer from "slices/theme";
import settingsReducer from "slices/settings";
import clipboardReducer from "slices/clipboard";
import onlineUsersReducer from "slices/onlineUsers";
import tickets from "slices/tickets";
import app from "slices/app";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist"; // defaults to localStorage for web

const { routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});
export default function createReducer(injectedReducers = {}) {
  const menuPersistConfig = {
    key: "menu",
    storage: storage,

  };
  const userPersistConfig = {
    key: "user",
    storage: storage,
    blacklist: ["notifications"],
  };
  // Check permission 
  // console.log("userPersistConfig", userPersistConfig)
  // console.log("userReducer", userReducer)
  return combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    menu: persistReducer(menuPersistConfig, menuReducer),
    // menu: menuReducer,
    widgets: widgetsReducer,
    socket: socketReducer,
    theme: themeReducer,
    clipboard: clipboardReducer,
    settings: settingsReducer,
    language: languageProviderReducer,
    router: routerReducer,
    onlineUsers: onlineUsersReducer,
    tickets: tickets,
    app: app,
    ...injectedReducers,
  });
}
