import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connected: false,
  error: {
    message: null,
  },
  SSOCode: {
    code: null,
    expires: null,
  },
  SSOLoginURL: null,
  socket: null,
};

export const socket = createSlice({
  name: "socket",
  initialState,
  reducers: {
    socketStatus: (state, action) => {
      state.connected = action.payload;
      if (!action.payload) {
        state.socket = null;
      }
    },
    setSSOCode: (state, action) => {
      state.SSOCode = {
        code: action.payload.code,
        expires: action.payload.expires,
      };
    },
    setSSOLoginURL: (state, action) => {
      state.SSOLoginURL = action.payload;
    },
    clearSSOCode: (state) => {
      state.SSOLoginURL = null;
      state.SSOCode = {
        code: null,
        expires: null,
      };
    },
    setSessionCode: (state, action) => {
      state.sessionCode = {
        code: action.payload.code,
        expires: new Date(new Date().getTime() + 300 * 1000).toISOString(),
      };
    },
  },
});

export const { socketStatus, setSSOCode, setSSOLoginURL, clearSSOCode } =
  socket.actions;

export default socket.reducer;
