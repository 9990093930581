import { useEffect, useState, useRef } from 'react';

const useIndexedDB = (dbName, storeNames, version) => {
    const [db, setDb] = useState(null);
    const [error, setError] = useState(null);
    const isInitialized = useRef(false);

    useEffect(() => {
        if (isInitialized.current) return;

        const openRequest = indexedDB.open(dbName, version);

        openRequest.onupgradeneeded = (event) => {
            const db = event.target.result;
            storeNames.forEach(storeName => {
                if (!db.objectStoreNames.contains(storeName)) {
                    console.log(`Creating store: ${storeName}`);
                    db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
                }
            });
        };

        openRequest.onsuccess = (event) => {
            setDb(event.target.result);
            isInitialized.current = true;
        };

        openRequest.onerror = (event) => {
            setError(event.target.error);
        };

        openRequest.onblocked = () => {
            console.warn("Database open request blocked");
        };
    }, [dbName, storeNames, version]);

    const addData = (storeName, data) => {
        return new Promise((resolve, reject) => {
            if (db) {
                const transaction = db.transaction([storeName], 'readwrite');
                const store = transaction.objectStore(storeName);
                const request = store.add(data);

                request.onsuccess = () => {
                    resolve(request.result);
                };

                request.onerror = (event) => {
                    reject(event.target.error);
                };
            } else {
                reject(new Error('Database is not initialized'));
            }
        });
    };

    const getData = (storeName, id) => {
        return new Promise((resolve, reject) => {
            if (db) {
                const transaction = db.transaction([storeName]);
                const store = transaction.objectStore(storeName);
                const request = store.get(id);

                request.onsuccess = () => {
                    resolve(request.result);
                };

                request.onerror = (event) => {
                    reject(event.target.error);
                };
            } else {
                reject(new Error('Database is not initialized'));
            }
        });
    };

    const updateData = (storeName, data) => {
        return new Promise((resolve, reject) => {
            if (db) {
                const transaction = db.transaction([storeName], 'readwrite');
                const store = transaction.objectStore(storeName);

                if (!data) {
                    reject(new Error('Data is required'));
                }
                if (!data.id) {
                    reject(new Error('Data must have an id'));
                }
                const getRequest = store.get(data.id);
                getRequest.onsuccess = () => {
                    const existingData = getRequest.result;
                    if (existingData) {
                        if (JSON.stringify(existingData) !== JSON.stringify(data)) {
                            const updatedData = { ...existingData, ...data };
                            const putRequest = store.put(updatedData);
                            putRequest.onsuccess = () => {
                                resolve(putRequest.result);
                            };
                            putRequest.onerror = (event) => {
                                reject(event.target.error);
                            };
                        }
                    } else {
                        addData(storeName, data).then(resolve()).catch(reject());
                    }
                };
                getRequest.onerror = (event) => {
                    reject(event.target.error);
                };
            } else {
                console.log('Database is not initialized')
            }
        });
    };

    const deleteData = (storeName, id) => {
        return new Promise((resolve, reject) => {
            if (db) {
                const transaction = db.transaction([storeName], 'readwrite');
                const store = transaction.objectStore(storeName);
                const request = store.delete(id);

                request.onsuccess = () => {
                    resolve();
                };

                request.onerror = (event) => {
                    reject(event.target.error);
                };
            } else {
                reject(new Error('Database is not initialized'));
            }
        });
    };

    const getTableAllData = (storeName) => {
        return new Promise((resolve, reject) => {
            if (db) {
                const transaction = db.transaction([storeName]);
                const store = transaction.objectStore(storeName);
                const request = store.getAll();

                request.onsuccess = () => {
                    resolve(request.result);
                };

                request.onerror = (event) => {
                    reject(event.target.error);
                };
            } else {
                reject(new Error('Database is not initialized'));
            }
        });
    };

    const getAllData = () => {
        return new Promise((resolve, reject) => {
            if (db) {
                const transaction = db.transaction(storeNames);
                const allData = {};

                storeNames.forEach(storeName => {
                    const store = transaction.objectStore(storeName);
                    const request = store.getAll();

                    request.onsuccess = () => {
                        allData[storeName] = request.result;

                        if (Object.keys(allData).length === storeNames.length) {
                            resolve(allData);
                        }
                    };

                    request.onerror = (event) => {
                        reject(event.target.error);
                    };
                });
            } else {
                reject(new Error('Database is not initialized'));
            }
        });
    }


    const deleteDatabase = () => {
        return new Promise((resolve, reject) => {
            if (db) {
                storeNames.forEach(storeName => {
                    const transaction = db.transaction([storeName], 'readwrite');
                    const store = transaction.objectStore(storeName);
                    store.clear();
                })
            }
        });
    };

    const dbIsInitialized = () => {
        return db !== null;
    };


    return { db, error, getData, updateData, deleteData, getTableAllData, dbIsInitialized, getAllData, deleteDatabase };
};

export default useIndexedDB;
