import { gql } from "@apollo/client";

const graphQLQueries = () => {
  const GET_PRIORITY1S = gql`
    query Priority1s($person_id: uuid!) {
      ticket_aggregate(
        where: {
          status: { open: { _eq: true } }
          priority: { name: { _eq: "high" } }
          parent_tickets_aggregate: {count: {predicate: {_eq: 0}}}
          _and: [
            {
              _or: [
                { assignee_id: { _eq: $person_id } }
                { requester_id: { _eq: $person_id } }
                { followers: { person_id: { _in: [$person_id] } } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
  const GET_PRIORITY2S = gql`
    query Priority2s($person_id: uuid!) {
      ticket_aggregate(
        where: {
          parent_tickets_aggregate: {count: {predicate: {_eq: 0}}}
          status: { open: { _eq: true } }
          priority: { name: { _eq: "normal" } }
          _and: [
            {
              _or: [
                { assignee_id: { _eq: $person_id } }
                { requester_id: { _eq: $person_id } }
                { followers: { person_id: { _in: [$person_id] } } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `;

  const GET_OPENS = gql`
    query Opens($person_id: uuid!) {
      ticket_aggregate(
        where: {
          parent_tickets_aggregate: {count: {predicate: {_eq: 0}}}
          status: { open: { _eq: true } }
          _and: [
            {
              _or: [
                { assignee_id: { _eq: $person_id } }
                { requester_id: { _eq: $person_id } }
                { followers: { person_id: { _in: [$person_id] } } }
              ]
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `;

  const GET_USER_DETAILS = gql`
    query UserDetails($person_id: uuid! ) {
      person(where: {id: {_eq: $person_id}}) {
        email_address
        organization {
          name
        }
        team {
          team {
            name
          }
        }
      }
    }
  `;

  const GET_ENGINEER_ALL_ID = gql`
    query EngineerAllId {
      person(where: {permissions: {groups: {permission_group_id: {_eq: "0185d422-d968-7f36-8067-d4c1b3a2dfd1"}}}}) {
        id
      }
    }
  `;

  const GET_ENGINEER_ALL_NAME = gql`
  query EngineerAllId {
    person(where: {permissions: {groups: {permission_group_id: {_eq: "0185d422-d968-7f36-8067-d4c1b3a2dfd1"}}}}) {
      id
      name
    }
  }
`;

  const GET_USER_PROFILE_DETAILS = gql`
  subscription UserProfileDetails($person_id: uuid!) {
    person(where: {id: {_eq: $person_id}}) {
      email_address
      organization {
        name
        id
        legal_name
        tickets(limit: 1, where: {comments: {author_id: {_eq: $person_id}}}) {
          alias
          comments(limit: 1, order_by: {created_at: desc}) {
            source {
              body
            }
            created_at
          }
        }
        permissions {
          id
        }
      }
      name
      account {
        created_at
        active
      }
      avatar
      first_name
      last_name
      permissions {
        groups {
          group_permission {
            key
            name
          }
        }
      }
      phone_numbers {
       phone_number {
        number
        country {
          dialing_prefix
          id
          name
        }
        id
      }
      }
    }
  }  
`;
  const GET_PERSON = gql`
  query Person($organizationID: uuid!) {
    person(where: { active: { _eq: true }, organization_id: { _eq: $organizationID } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;

  const GET_ALL_ACTIVE_PERSON = gql`
query MyQuery {
  person(where: {active: {_eq: true}}) {
    id
    name
    organization_name
  }
}

`;

  return {
    GET_PRIORITY1S,
    GET_PRIORITY2S,
    GET_OPENS,
    GET_USER_DETAILS,
    GET_USER_PROFILE_DETAILS,
    GET_ENGINEER_ALL_ID,
    GET_ENGINEER_ALL_NAME,
    GET_PERSON,
    GET_ALL_ACTIVE_PERSON
  };
};

export default graphQLQueries;
