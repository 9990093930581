import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import layoutStyles from 'containers/Layout/styles';
import styles from './styles';
import SSGFormTextInput from 'components/SSGForm/SSGFormTextInput'
import SSGFormOption from 'components/SSGForm/SSGFormOption'
import SSGFormDropDown from 'components/SSGForm/SSGFormDropDown';
import SSGFormCheckBoxOptions from 'components/SSGForm/SSGFormCheckBoxOptions';
import SSGAddress from 'components/SSGForm/SSGAddress';
import SSGMatrix from 'components/SSGForm/SSGMatrix';
import Button from '@mui/material/Button';
import LoadingIndicator from 'components/LoadingIndicator';
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import { useMediaQuery, useTheme } from '@mui/material';
import Translation from 'utils/translation';
import 'dayjs/locale/en-gb';

const logicChecker = (fieldLogic, formFieldsValues, override) => {
    // console.log({fieldLogic})
    // console.log({formFieldsValues})
    // console.log({override})
    // return new Promise((resolve, reject) => {
    const { action, checks, conditional } = fieldLogic

    let shouldBeShown = action === 'show' ? false : true

    let checksMet = 0

    checks.forEach((check, key) => {
        const { field, option, condition } = check
        let currentValue = formFieldsValues[field] ?? null
        if (override) {
            const { value, identifier } = override
            currentValue = value ?? formFieldsValues[identifier] ?? null
        }

        switch (condition) {
            case 'equals':
                if (currentValue) {
                    if (currentValue === option) {
                        checksMet++
                    }
                    if (currentValue.includes(',')) {
                        if (currentValue.includes(option)) {
                            checksMet++
                        }
                    }
                }
                break;
            case 'notequals':
                if (currentValue !== option) {
                    checksMet++
                }
                if (currentValue.includes(',')) {
                    if (!currentValue.includes(option)) {
                        checksMet++
                    }
                }
                break;
            default:
                // console.log('default')
                break;
        }
    })

    if (conditional === 'all') {
        if (checksMet === checks.length) {
            shouldBeShown = !shouldBeShown
        }
    } else {
        if (checksMet > 0) {
            shouldBeShown = !shouldBeShown
        }
    }
    return shouldBeShown
    // resolve(shouldBeShown)
    // })
}

const nestFieldsInSections = (formData) => {
    return new Promise((resolve, reject) => {
        const sections = [];
        formData?.fields?.forEach((field) => {
            if (field.type === 'section') {
                let newField = {
                    ...field,
                    fields: []
                }
                sections.push(newField);
            } else {
                sections[sections.length - 1]?.fields.push(field);
            }
        });
        resolve(sections)
    })
}

const sectionLogicChecker = (formSections) => {
    return new Promise((resolve, reject) => {
        const sectionsLogic = []
        formSections.forEach((section) => {
            if (section.logic) {
                sectionsLogic[section.identifier] = section.logic
            }
        })
        resolve(sectionsLogic)
    })
}

const sectionEnabledChecker = (formSections) => {
    return new Promise((resolve, reject) => {
        const sectionsEnabled = []
        formSections.forEach((section) => {
            if (section.logic) {
                sectionsEnabled[section.identifier] = false
            } else {
                let sectionEnabled = false
                section.fields.forEach((field) => {
                    if (field.hidden === false) {
                        // end loop and set to true
                        sectionEnabled = true
                    }
                })
                sectionsEnabled[section.identifier] = sectionEnabled
            }
        })
        resolve(sectionsEnabled)
    })
}

const fieldsInititalValues = (formSections, user) => {
    return new Promise((resolve, reject) => {
        const fieldsValues = []
        formSections.forEach((section) => {
            section.fields.forEach((field) => {
                switch (field.name) {
                    case 'your_email_address':
                        fieldsValues[field.identifier] = user?.email_address ?? field.value ?? null
                        break;
                    case 'your_phone_number':
                        fieldsValues[field.identifier] = user?.phone_numbers[0]?.phone_number?.number ?? field.value ?? null
                        break;
                    case 'your_name':
                        fieldsValues[field.identifier] = user?.name ?? field.value ?? null
                        break;
                    case 'first_name':
                        fieldsValues[field.identifier] = user.first_name ?? field.value ?? null
                        break;
                    case 'last_name':
                        fieldsValues[field.identifier] = user.last_name ?? field.value ?? null
                        break;
                    default:
                        fieldsValues[field.identifier] = field.value ?? null
                        break;
                }
            })
        })
        resolve(fieldsValues)
    })
}

const fieldsLogicChecker = (formSections) => {
    // console.log({formSections})
    return new Promise((resolve, reject) => {
        const fieldsLogic = []
        formSections.forEach((section) => {
            section.fields.forEach((field) => {
                if (field.logic) {
                    fieldsLogic[field.identifier] = field.logic
                }
            })
        })
        resolve(fieldsLogic)
    })
}

const fieldsEnabledChecker = (formSections, formFieldsValues) => {
    return new Promise((resolve, reject) => {
        const fieldsEnabled = []
        formSections.forEach((section) => {
            section.fields.forEach((field) => {
                if (field.identifier === 74619040) {
                    // console.log(field)
                }
                if (field.hidden) {
                    fieldsEnabled[field.identifier] = false
                    if (field.identifier === 74619040) {
                        // console.log(`fieldsEnabled[${field.identifier}] is set to false`)
                    }
                } else if (field.logic) {
                    fieldsEnabled[field.identifier] = logicChecker(field.logic, formFieldsValues)
                    if (field.identifier === 74619040) {
                        // console.log(`fieldsEnabled[${field.identifier}] is run though checker`)
                        // console.log(logicChecker(field.logic))
                    }
                }
                else {
                    fieldsEnabled[field.identifier] = true
                    if (field.identifier === 74619040) {
                        // console.log(`fieldsEnabled[${field.identifier}] is set to true`)
                    }
                }
            }
            )
        })
        resolve(fieldsEnabled)
    })
}

const effectLogicLookupGenerator = (formSections) => {
    return new Promise((resolve, reject) => {
        let effectLogicLookup = []
        formSections.forEach((section) => {

            if (section.logic) {
                section.logic.checks.forEach((check) => {
                    if (effectLogicLookup[check.field]) {
                        effectLogicLookup[check.field].push({
                            identifier: section.identifier,
                            type: 'section'
                        })
                    } else {
                        effectLogicLookup[check.field] = [{
                            identifier: section.identifier,
                            type: 'section'
                        }]
                    }
                })
            }


            section.fields.forEach((field) => {
                if (field.logic) {
                    field.logic.checks.forEach((check) => {
                        if (effectLogicLookup[check.field]) {
                            effectLogicLookup[check.field].push({
                                identifier: field.identifier,
                                type: 'field'
                            })
                        } else {
                            effectLogicLookup[check.field] = [{
                                identifier: field.identifier,
                                type: 'field'
                            }]
                        }
                    })
                }
            })
        })

        resolve(effectLogicLookup)
    })
}

/**
 * Represents a form component for SSG (Static Site Generation).
 * @param {Object} props - The props for the SSGForm component.
 * @returns {JSX.Element} The rendered SSGForm component.
 */
function SSGFormLoader(props) {

    let debugOn = false

    const roles = useSelector((state) => state.user.user.permissions.roles)

    const navigate = useNavigate()
    const [formDetails, setFormDetails] = useState({
        formName: '',
        formId: ''
    })


    const { formID, userID } = props



    const [formSections, setFormSections] = useState([])
    const [formSectionsLogic, setFormSectionsLogic] = useState([])
    const [formSectionEnabled, setFormSectionEnabled] = useState([])

    const [formFieldsValues, setFormFieldsValues] = useState([])
    const [formFieldsLogic, setFormFieldsLogic] = useState([])
    const [formFieldsEnabled, setFormFieldsEnabled] = useState([])

    const [formFieldsEffectLogic, setFormFieldsEffectLogic] = useState([])

    const [currentSection, setCurrentSection] = useState(0)
    const [canGoBack, setCanGoBack] = useState(false)
    const [canGoForward, setCanGoForward] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const [showSubmit, setShowSubmit] = useState(false)
    const [previousSection, setPreviousSection] = useState(0)
    const [nextSection, setNextSection] = useState(0)
    const [formData, setFormData] = useState(null)
    const [submitedSucessfully, setSubmitedSucessfully] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [subjectFieldIdentifier, setSubjectFieldIdentifier] = useState(null)

    const [loading, setLoading] = useState(true)

    const [user, setUser] = useState(null)
    const isPhoneSize = useMediaQuery(theme => theme.breakpoints.down('sm'));


    useEffect(() => {
        // console.log(canGoForward)
    }, [canGoForward])



    useEffect(() => {
        isCurrentSectionComplete()
        // console.log("************************Section has beeen checked************************")
    }, [formFieldsValues])



    const query = gql`
    query MyQuery {
        person(where: {id: {_eq: "${userID}"}}) {
        email_address
        first_name
        last_name
        middle_name
        name
        organization_id
        account_id
        phone_numbers {
        phone_number {
            main
            number
        }
        }
    }
    formstack_form(where: {identifier: {_eq: ${formID}}}) {
      active
      identifier
      id
      name
      fields(order_by: {sort: asc}) {
        date_format
        deleted
        description
        form_id
        hidden
        hide_label
        id
        identifier
        label
        logic
        name
        option_checkall
        option_layout
        option_other
        option_show_values
        option_store
        options
        readonly
        required
        section_break
        section_heading
        section_text
        show_initial
        show_prefix
        show_suffix
        sort
        time_format
        type
        uniq
        value
        colspan
        add_na
      }
    }
  }`

    // create a mutation to update the formstack form
    // const mutation = gql`
    // mutation MyMutation($formID: String!, $data: jsonb = {}) {
    //     formstack_submit_form(formBody: {formID: $formID, data: $data}) {
    //         id
    //         redirect_url
    //         timestamp
    //         form
    //         latitude
    //         longitude
    //         payment_status
    //         remote_addr
    //         user_agent
    //     }
    // }`

    const mutationNoTicket = gql`
    mutation MyMutation($data: [formstack_submission_data_insert_input!] = {}, $form_id: uuid!, $person_id: uuid!, $ticket: ticket_obj_rel_insert_input = {data: {}}) {
        insert_formstack_submission(objects: {created_at: "now()", form_id: $form_id, person_id: $person_id, data: {data: $data}, tickets: {data: {ticket: $ticket}}}) {
            affected_rows
            returning {
                tickets {
                    ticket {
                        alias
                    }
                }
            }
        }
    }`

    const [updateFormNoTicket, { loading: mutationLoadingNT, error: mutationErrorNT }] = useMutation(mutationNoTicket);



    const mutationWithTicket = gql`
    mutation MyMutation($data: [formstack_submission_data_insert_input!] = {}, $form_id: uuid!, $person_id: uuid!, $ticket_id: uuid) {
        insert_formstack_submission(objects: {created_at: "now()", form_id: $form_id, person_id: $person_id, data: {data: $data}, tickets: {data: {ticket_id: $ticket_id}}}) {
            affected_rows
        }
    }`

    const [updateFormWithTicket, { loading: mutationLoadingWT, error: mutationErrorWT }] = useMutation(mutationWithTicket);

    // const [firstTimeUser, { loading, error, data }] = useMutation(FIRST_USER, {
    //     variables: { userID: user.id },
    // });

    useEffect(() => {

        if (mutationErrorWT || mutationErrorNT) {
            // console.log('mutationError')
            // console.log(mutationErrorWT)
            // console.log(mutationErrorNT?.message)
        }
    }, [mutationErrorWT, mutationErrorNT])


    const { data, loading: queryLoading, error } = useQuery(query)

    // STAGE 0
    useEffect(() => {
        if (data) {
            setFormData(data.formstack_form[0])
            //  console.log(data.formstack_form[0])
            setUser(data.person[0])
        }
    }, [data])

    useEffect(() => {
        if (error) {
            console.log(error.message)

        }
    }, [error])

    // STAGE 1
    // set initial sections from the form data
    useEffect(async () => {
        if (formData !== null && formData !== undefined) {
            // console.log('Stage 1')
            // console.log({ formData })
            setFormDetails({
                formName: formData.name,
                formId: formData.id
            })
            // sort the fields into sections
            setFormSections(await nestFieldsInSections(formData))

        }
    }, [formData])
    // console.log(formSections)
    useEffect(() => {
        // console.log('currentSection', currentSection)
        avaliableOptions()
    }, [currentSection])

    useEffect(async () => {
        // STAGE 2
        // console.log('Stage 2')
        // console.log('formSections', formSections) // this can be removed in production
        setFormSectionsLogic(await sectionLogicChecker(formSections))
        // STAGE 3
        // console.log('Stage 3')
        let tempFieldValues = await fieldsInititalValues(formSections, user)
        setFormFieldsValues(tempFieldValues)
        // STAGE 4
        // console.log('Stage 4')
        setFormFieldsLogic(await fieldsLogicChecker(formSections))
        // STAGE 5
        // console.log('Stage 5')
        setFormFieldsEnabled(await fieldsEnabledChecker(formSections, tempFieldValues))
        // STAGE 6
        // console.log('Stage 6')
        setFormSectionEnabled(await sectionEnabledChecker(formSections))
        // STAGE 7
        // console.log('Stage 7')
        setFormFieldsEffectLogic(await effectLogicLookupGenerator(formSections))
        // console.log('formSectionEnabled', formSectionEnabled)
        // Finished Initialising
    }, [formSections])

    useEffect(() => {
        avaliableOptions()
    }, [formFieldsValues])

    useEffect(() => {
        // console.log({formSectionEnabled})
    }, [formSectionEnabled])

    useEffect(() => {
        if (formSections.length > 0) {
            let currectSectionEnabled = formSectionEnabled[formSections[currentSection].identifier]
            if (currectSectionEnabled === false) {
                for (let i = currentSection; i <= formSections.length; i++) {
                    if (formSectionEnabled[formSections[i].identifier] === true) {
                        // console.log('formSections[i].identifier', formSections[i].identifier)
                        setCurrentSection(i)
                        break;
                    }
                }
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }


    }, [formSectionEnabled])

    useEffect(() => {
        // console.log('formFieldsEffectLogic', formFieldsEffectLogic)
    }, [formFieldsEffectLogic])

    // useEffect(() => {
    //     formFieldsLogic.map((fieldLogic, key) => {
    //         // console.log('fieldLogic', fieldLogic)
    //         // console.log('key', key)
    //         if (key === 74619019) {
    //             let logicReturn = logicChecker(fieldLogic, formFieldsValues)
    //         }
    //     })
    // }, [formFieldsValues])


    // Current issues
    // 1. Tracking previous changes to fields i.e. i make a change to a field and then change something else, sometimes it losees that there is a value in the other one
    // 2. Delay, there is a delay after the fields are filled out and activating the forward button
    // 3. Need to add checking if the value is suitable for the field i.e. if the field is a number and the value is not a number then it should not be allowed
    // 4. This is triggering many times on page load, need to find a way to stop this
    const isCurrentSectionComplete = () => {
        if (formSections.length > 0) {
            // console.log('isCurrentSectionComplete')
            let currentProgress = true
            // console.log('formSections[currentSection]', formSections[currentSection])
            // console.log('formSections[currentSection].fields', formSections[currentSection].fields)
            // console.log('formFieldsValues', formFieldsValues)
            formSections[currentSection].fields.forEach((field) => {
                if (field.hidden === false) {
                    if (field.required) {
                        // console.log('field', field)
                        // console.log(formFieldsEnabled)
                        // console.log('formFieldsEnabled[field.identifier]', formFieldsEnabled[field.identifier])
                        if (formFieldsEnabled[field.identifier] === true) {
                            // console.log('formFieldsValues' + [field.identifier], formFieldsValues[field.identifier])
                            if (formFieldsValues[field.identifier] === null || formFieldsValues[field.identifier] === undefined || formFieldsValues[field.identifier] === '' || formFieldsValues[field.identifier] === ',') {
                                setCanGoForward(false)
                                // break out of the loop
                                // console.log('found a false field, exiting loop')
                                currentProgress = false
                                return false
                            }
                        }
                    }
                }
            })
            if (formFieldsEnabled.length === 0) {
                currentProgress = false
            }
            // console.log('currentProgress', currentProgress)
            // console.log('canGoForward', true)
            // Check if any section after current is true then can go forward otherwise if all are flase set can submit to true
            let nextSection = currentSection + 1;
            while (nextSection < formSections.length && nextSection >= 0) {
                // console.log('nextSection', nextSection)
                // console.log('formSectionEnabled[formSections[nextSection].identifier]', formSectionEnabled[formSections[nextSection].identifier])
                if (formSectionEnabled[formSections[nextSection]?.identifier] === false) {
                    if (nextSection === formSections.length - 1) {
                        setCanGoForward(false)
                        setShowSubmit(true)
                        setCanSubmit(currentProgress)
                        break;
                    }
                    nextSection++;
                } else {
                    // console.log('nextSection', nextSection)
                    setNextSection(nextSection);
                    // console.log('canGoForward', currentProgress)
                    setCanGoForward(currentProgress)
                    setShowSubmit(false)
                    setCanSubmit(false)
                    break;
                }
            }

            // if the section is the last one show the submit button
            if (currentSection === formSections.length - 1) {
                setShowSubmit(true)
                setCanSubmit(currentProgress)
            }

            // if (!nextSection < formSections.length && nextSection >= 0) {
            //     console.log('enable submit button')
            //     setCanGoForward(false)
            //     setShowSubmit(true)
            //     setCanSubmit(currentProgress)
            // }
        }

    }

    const avaliableOptions = () => {
        let previousSection = currentSection - 1;
        while (previousSection < formSections.length && previousSection >= 0) {
            if (formSectionEnabled[formSections[previousSection].identifier] === false) {
                if (previousSection === 0) {
                    // console.log('canGoBack', false)
                    setCanGoBack(false)
                    break;
                }
                previousSection--;
            } else {
                // console.log('previousSection', previousSection)
                // console.log('canGoBack', true)
                setPreviousSection(previousSection);
                setCanGoBack(true)
                break;
            }
        }

        isCurrentSectionComplete()
        // while (nextSection < formSections.length-1 && nextSection >= 0) {
        //     console.log('nextSection', nextSection)
        //     if (formSectionEnabled[formSections[nextSection].identifier] === false) {
        //         if (currentSection === formSections.length - 1) {
        //             // console.log('canGoForward', false)
        //             console.log('at end')
        //             setCanGoForward(false)
        //             break;
        //         }
        //     } else {
        //         console.log('maybe')
        //         // Check for required fields
        //         isCurrentSectionComplete()
        //         // console.log('nextSection', nextSection)
        //         // console.log('canGoForward', sectionProgress)
        //         setNextSection(nextSection);
        //         // setCanGoForward(sectionProgress)
        //         break;
        //     }
        //     nextSection++;
        // }
    }

    const handleChangedValue = (identifier, value) => {
        // console.log('handleChangedValue', identifier, value)
        // console.log('ID to be changed: ', identifier)
        //Can Finish

        // isCurrentSectionComplete()

        // console.log('handleChangedValue', identifier, value)
        // Find if the idenfifier is in the form field effect logic
        let temp = formFieldsEffectLogic[identifier]

        if (value && temp) {
            setFormFieldsValues((prev) => {
                return { ...prev, [identifier]: value }
            })
        }
        if (temp) {
            temp.forEach((effect, index) => {
                // console.log(effect)
                let logicToCheck
                if (effect.type === 'section') {
                    logicToCheck = formSectionsLogic[effect.identifier]
                } else {
                    logicToCheck = formFieldsLogic[effect.identifier]
                }
                // console.log(logicToCheck)
                // let shouldBeShown = logicChecker({
                //     newValue: value,
                //     condition: logicToCheck.checks[0].condition,
                //     expectedValue: logicToCheck.checks[0].option,
                //     conditional: logicToCheck.conditional,
                //     action: logicToCheck.action
                // })
                let shouldBeShown = logicChecker(logicToCheck, formFieldsValues, { value, identifier })
                // console.log(shouldBeShown)
                // console.log('effect.type', effect.type)
                if (effect.type === 'section') {
                    setFormSectionEnabled((prev) => {
                        return { ...prev, [effect.identifier]: shouldBeShown }
                    })
                } else {
                    // console.log('setFormFieldsEnabled')
                    setFormFieldsEnabled((prev) => {
                        return { ...prev, [effect.identifier]: shouldBeShown }
                    })
                    // setFormFieldsValues((prev) => {
                    //     return { ...prev, [effect.identifier]: value }
                    // })
                }
            })
        } else {
            setFormFieldsValues((prev) => {
                return { ...prev, [identifier]: value }
            })
        }
        // setFormFieldsValues((prev) => {
        //     return { ...prev, [identifier]: value }
        // })

        // setFormFieldsEnabled((prev) => {
        //     return { ...prev, [identifier]: false }
        // })
    }


    const handleSubmit = () => {
        setCanSubmit(false)
        // let body = {}

        // formSections.forEach((section) => {
        //     section.fields.forEach((field) => {
        //         console.log(field.name)
        //         if (field.hidden === false) {
        //             if (formFieldsValues[field.identifier] !== "") {
        //                 if (field.name === 'your_name') {
        //                     // split the name into first and last
        //                     let name = formFieldsValues[field.identifier].split(' ')
        //                     body[`field_${field.identifier}`] = {
        //                         first: name[0] ?? '',
        //                         last: name[1] ?? ''
        //                     }
        //                 }
        //                 else if (field.type === 'checkbox') {
        //                     if (formFieldsValues[field.identifier]) {
        //                         body[`field_${field.identifier}`] = formFieldsValues[field.identifier].split(',')
        //                     }
        //                 } else {
        //                     body[`field_${field.identifier}`] = formFieldsValues[field.identifier]
        //                 }
        //             }
        //         }
        //     })
        // })

        // // console.log(body)
        // updateForm({ variables: { formID, data: body } })
        //     .then(response => {
        //         // console.log(response);
        //         // handle success
        //         // naviagate('/form-submitted')
        //         setSubmitted(true)
        //         setSubmitedSucessfully(true)
        //     })
        //     .catch(error => {
        //         setSubmitted(true)
        //         setSubmitedSucessfully(false)
        //         console.error(error);
        //         // handle error
        //     });

        // console.log(user)


        let data = []
        let form_id = formDetails.formId
        let person_id = userID
        let organization_id = user.organization_id
        let account_id = user.account_id
        let status_id = "6b888b99-cd8d-4cf5-a255-1cca328effa2" //e4829c81-314e-4634-81bc-903976e0ea99, 63ffbdc8-b443-42c6-8bc2-6e58a4011f93 c7dc5b89-e3cd-41ec-baf1-d7d43d079725, 115dc3ec-c6ca-4377-8d6b-d24045c345c8
        let priority_id = "e447041f-d5d6-4c41-b22d-2e0157d23c19" //0820adea-a913-41ea-bca2-23a5836dcacd, 629698ef-a3f6-4523-9d80-700a5ff1a491
        let board_id = "0185e376-6548-762e-bb48-ef790d8edef4" //78f57115-43d8-402d-9219-2b843a1890ad, 0185e9a8-6aee-721c-aca0-de4a992acf6f
        let subject = formDetails.formName
        if (subjectFieldIdentifier) {
            if (formFieldsValues[subjectFieldIdentifier].length > 5) {
                subject = formFieldsValues[subjectFieldIdentifier]
            }
        }

        let ticket_id = null

        let ticket = {
            data: {
                account_id: account_id,
                organization_id: organization_id,
                requester_id: userID,
                status_id: status_id, //e4829c81-314e-4634-81bc-903976e0ea99, 63ffbdc8-b443-42c6-8bc2-6e58a4011f93 c7dc5b89-e3cd-41ec-baf1-d7d43d079725, 115dc3ec-c6ca-4377-8d6b-d24045c345c8
                priority_id: priority_id, //0820adea-a913-41ea-bca2-23a5836dcacd, 629698ef-a3f6-4523-9d80-700a5ff1a491
                board_id: board_id, //78f57115-43d8-402d-9219-2b843a1890ad, 0185e9a8-6aee-721c-aca0-de4a992acf6f
                subject: subject,
                has_incidents: false,
                updated_at: "now()",
            }
        }

        // {
        //     "data": [
        //         {
        //           "field_id": "8066bc8a-b2b6-40fa-a5be-c543e8f336aa",
        //         "value": "test value"
        //       },
        //       {
        //           "field_id": "1dbc19cc-d19f-44a5-95d1-d60e0fc80de9",
        //         "value": "value 2"
        //         }
        //     ]
        //   }

        formSections.forEach((section) => {
            section.fields.forEach((field) => {
                if (field.hidden === false) {
                    const fieldValue = formFieldsValues[field.identifier];

                    // Check if the value is not an object and is not an empty string
                    if (typeof fieldValue === 'object' && fieldValue !== "") {
                        data.push({
                            field_id: field.id,
                            value: JSON.stringify(fieldValue) // Convert object to string if needed
                        });
                    } else if (fieldValue !== "") {
                        // Handle non-empty string values
                        data.push({
                            field_id: field.id,
                            value: fieldValue
                        });
                    }
                }
            });
        });



        if (ticket_id) {
            //  console.log({ form_id, person_id, ticket_id, data })
            updateFormWithTicket({ variables: { form_id, person_id, ticket_id, data } })
        } else {
            updateFormNoTicket({ variables: { form_id, person_id, data, ticket } }).then((response) => {
                //    console.log(response)

                if (response?.data?.insert_formstack_submission?.returning[0]?.tickets[0]?.ticket?.alias) {
                    navigate('/support/tickets/' + response?.data?.insert_formstack_submission?.returning[0]?.tickets[0]?.ticket?.alias)
                } else {
                    setSubmitted(true)
                    setSubmitedSucessfully(true)
                    setCanSubmit(true)
                }
            }).catch((error) => {
                //    console.log(error)
                setCanSubmit(true)
                // setSubmitted(true)
                // setSubmitedSucessfully(false)
            })
        }




        // updateForm({ variables: { formID, data: body } })
        //     .then(response => {
        //         // console.log(response);
        //         // handle success
        //         // naviagate('/form-submitted')
        //         setSubmitted(true)
        //         setSubmitedSucessfully(true)
        //     })
        //     .catch(error => {
        //         setSubmitted(true)
        //         setSubmitedSucessfully(false)
        //         console.error(error);
        //         // handle error
        //     });




    }


    useEffect(() => {
        console.log('showSubmit', showSubmit)
    }, [showSubmit])




    const DebugButtons = () => {
        if (debugOn) {
            return (
                <>
                    <div style={{ margin: '10px' }}>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => console.log('formDetails', formDetails)}>formDetails</Button>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => console.log('formSections', formSections)}>formSections</Button>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => console.log('formSectionsLogic', formSectionsLogic)}>formSectionsLogic</Button>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => console.log('formSectionEnabled', formSectionEnabled)}>formSectionEnabled</Button>
                    </div>
                    <div style={{ margin: '10px' }}>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => console.log('formFieldsValues', formFieldsValues)}>formFieldsValues</Button>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => console.log('formFieldsLogic', formFieldsLogic)}>formFieldsLogic</Button>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => console.log('formFieldsEnabled', formFieldsEnabled)}>formFieldsEnabled</Button>
                    </div>
                    <div>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => console.log('formFieldsEffectLogic', formFieldsEffectLogic)}>formFieldsEffectLogic</Button>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => avaliableOptions()}>avaliableOptions</Button>

                    </div>
                    <div>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => setCurrentSection(currentSection - 1)}>Previous Section</Button>
                        <Button style={{ margin: '10px' }} variant="contained" onClick={() => setCurrentSection(currentSection + 1)}>Next Section</Button>

                    </div>
                    <div style={{ margin: '10px' }}>
                        <Button
                            style={{ margin: '10px' }}
                            variant="contained"
                            onClick={() => setFormFieldsValues((prev) => {
                                return { ...prev, 74619019: 'Yes' }
                            })}>Change 74619019 to Yes</Button>
                        <Button
                            style={{ margin: '10px' }}
                            variant="contained"
                            onClick={() => setFormFieldsValues((prev) => {
                                return { ...prev, 74619019: 'Yes' }
                            })}>Change 74619019 to No</Button>
                        <Button variant="contained" onClick={() => handleSubmit()} disabled={!canSubmit}>Submit</Button>
                        <Button variant="contained" onClick={() => setCanSubmit(!canSubmit)}>Enable/Disable Submitting</Button>
                    </div>

                </>
            )
        } else {
            return (<></>)
        }
    }


    return (
        <>
            <Card variant="outlined">
                {loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80vh', flexGrow: 1 }}><LoadingIndicator /></div>}
                {!loading && (
                    <>

                        <DebugButtons />
                        <h1 style={{ marginLeft: 47, marginBottom: -20, borderBottom: '1px solid', width: '90%', marginInlineEnd: '10%' }} >{formDetails?.formName ?? 'No Form Name Set'}</h1>
                        {!submitted ? (
                            <>
                                {formSections.map((section, index) => {
                                    let sectionIdentifier = section?.identifier ?? ''
                                    let SectionHeader = section?.section_heading ?? ''
                                    let sectionText = section?.section_text ?? ''
                                    if (SectionHeader === "") section.section_heading = ""



                                    const standardStyles = {
                                        color: 'black',
                                        width: '100%',
                                        padding: '20px'
                                    }
                                    const showStyle = {
                                        ...standardStyles,
                                        // backgroundColor: 'green',
                                    }
                                    const hideStyle = {
                                        ...standardStyles,
                                        // backgroundColor: 'red',
                                        display: 'none'

                                    }

                                    const style = section.identifier == formSections[currentSection].identifier ? showStyle : hideStyle


                                    return (
                                        <div style={{ marginLeft: 15, marginTop: "" }} key={index}>
                                            <div style={style}>
                                                <h2 style={{ marginLeft: 15 }}>{SectionHeader}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: sectionText }}></div>
                                            </div>
                                            <div style={{ ...style, marginBottom: 3, marginLeft: 10, width: "95%", }}>
                                                {section.fields.map((field, index) => {
                                                    switch (field.type) {
                                                        case 'embed':
                                                            break;
                                                        case 'text':
                                                        case 'richtext':
                                                        case 'email':
                                                        case 'number':
                                                        case 'phone':
                                                        case 'name':


                                                            if (field.hidden) return null;
                                                            if (field.name === 'please_provide_a_brief_summary') {
                                                                if (subjectFieldIdentifier === null) {

                                                                    setSubjectFieldIdentifier(field.identifier)
                                                                }
                                                            }
                                                            if (formFieldsEnabled[field.identifier]) {
                                                                const style = { color: 'black', marginBotom: 3, };
                                                                return (
                                                                    <div key={index} style={style}>
                                                                        <SSGFormTextInput
                                                                            key={index}
                                                                            field={field}
                                                                            currentValue={formFieldsValues[field.identifier]}
                                                                            handleChange={(v) => {
                                                                                handleChangedValue(parseInt(field.identifier), v);
                                                                            }}

                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                            break;
                                                        case 'textarea':
                                                            if (field.hidden) return null;
                                                            if (field.name === 'please_provide_a_brief_summary') {
                                                                if (subjectFieldIdentifier === null) {

                                                                    setSubjectFieldIdentifier(field.identifier)
                                                                }
                                                            }
                                                            if (formFieldsEnabled[field.identifier]) {
                                                                const style = { color: 'black', marginBotom: 3, };
                                                                return (
                                                                    <div key={index} style={style}>
                                                                        <SSGFormTextInput
                                                                            key={index}
                                                                            field={field}
                                                                            currentValue={formFieldsValues[field.identifier]}
                                                                            handleChange={(v) => {
                                                                                handleChangedValue(parseInt(field.identifier), v);
                                                                            }}
                                                                            multiline={true}
                                                                            minlines={3}

                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                            break;
                                                        case 'radio':
                                                            if (field.hidden) return null;
                                                            if (formFieldsEnabled[field.identifier]) {
                                                                const style = { color: 'black', marginLeft: 8, marginBotom: 5 };
                                                                // console.log('field', field)
                                                                // if (field?.option_other === true) {
                                                                //     field.options.push({ label: 'Other', value: 'other' })
                                                                // }
                                                                return (
                                                                    <div key={index} style={style}>
                                                                        <SSGFormOption
                                                                            key={index}
                                                                            field={field}
                                                                            currentValue={formFieldsValues[field.identifier]}
                                                                            handleChange={(v) => {
                                                                                handleChangedValue(parseInt(field.identifier), v.target.value);
                                                                            }}

                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                            break;
                                                        case 'select':
                                                            if (field.hidden) return null;
                                                            if (formFieldsEnabled[field.identifier]) {
                                                                const style = { color: 'black', marginLeft: 7.5, marginTop: 7, width: '100%', backgroundColor: 'white', borderRadius: 8, };
                                                                return (
                                                                    <div key={index} style={style}>
                                                                        <SSGFormDropDown
                                                                            key={index}
                                                                            field={field}
                                                                            defaultValue={formFieldsValues[field.identifier]}
                                                                            handleChange={(id, v) => {
                                                                                handleChangedValue(parseInt(id), v);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                            break;
                                                        case 'checkbox':
                                                            if (field.hidden) return null;
                                                            if (formFieldsEnabled[field.identifier]) {
                                                                const style = { color: 'black', marginLeft: 10, marginBotom: 5, };
                                                                return (
                                                                    <div key={index} style={style}>
                                                                        <SSGFormCheckBoxOptions
                                                                            key={index}
                                                                            field={field}
                                                                            handleChange={(v, state) => {
                                                                                let oldValue = formFieldsValues[field.identifier];
                                                                                let newValue = v;

                                                                                if (state) {
                                                                                    if (oldValue === null) {
                                                                                        // formFieldsValues[field.identifier] = newValue;
                                                                                    } else {
                                                                                        newValue = oldValue + ',' + v;
                                                                                    }
                                                                                } else {
                                                                                    if (oldValue === null) {
                                                                                        // do nothing
                                                                                    } else {
                                                                                        newValue = oldValue.replace(v, '');
                                                                                        let arrayValue = newValue.split(',');
                                                                                        arrayValue = arrayValue.map(item => item.replace(/^[^a-zA-Z0-9]+/, ''));
                                                                                        newValue = arrayValue.join(',');
                                                                                        // newValue = newValue.replace(/(?<=[^a-zA-Z0-9]),/g, '');
                                                                                    }
                                                                                }
                                                                                let arrayValue = newValue.split(',');
                                                                                arrayValue = arrayValue.map(item => item.replace(/^[^a-zA-Z0-9]+/, ''));
                                                                                newValue = arrayValue.join(',');
                                                                                // newValue = newValue.replace(/(?<=[^a-zA-Z0-9]),/g, '');

                                                                                if (newValue === null) {
                                                                                    newValue = '';
                                                                                }

                                                                                handleChangedValue(parseInt(field.identifier), newValue);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                            break;
                                                        case 'datetime':
                                                            // field.time_format
                                                            // field.date_format
                                                            if (field.hidden) return null;
                                                            if (formFieldsEnabled[field.identifier]) {
                                                                return (
                                                                    <div key={index} style={{}}>
                                                                        <LocalizationProvider
                                                                            dateAdapter={AdapterDayjs}
                                                                            adapterLocale='en-gb'
                                                                        >
                                                                            {field.date_format && (
                                                                                <DatePicker
                                                                                    label={`${field.label} ${field.required ? ' *' : ''}`}
                                                                                    name="test"
                                                                                    required={field.required}
                                                                                    value={formFieldsValues[field.identifier]}
                                                                                    onChange={(v) => {
                                                                                        handleChangedValue(parseInt(field.identifier), v.$d);
                                                                                    }}
                                                                                    sx={{ width: isPhoneSize ? '100%' : '30%', marginLeft: 0.7, marginBottom: 1, marginTop: 1, backgroundColor: 'white', borderRadius: 8, }}
                                                                                />
                                                                            )}
                                                                            {field.time_format && (
                                                                                <TimePicker
                                                                                    label={`${field.label} ${field.required ? ' *' : ''}`}
                                                                                    name="test"
                                                                                    value={formFieldsValues[field.identifier] === "" ? null : formFieldsValues[field.identifier]}
                                                                                    onChange={(v) => {
                                                                                        let returnValue = v.$H + ':' + v.$m
                                                                                        // console.log(returnValue)
                                                                                        handleChangedValue(parseInt(field.identifier), returnValue);
                                                                                    }} required={field.required}
                                                                                    sx={{ width: isPhoneSize ? '100%' : '30%', marginLeft: 0.7, marginBottom: 1, backgroundColor: 'white', borderRadius: 8, }}
                                                                                />
                                                                            )}
                                                                        </LocalizationProvider>
                                                                    </div>
                                                                )
                                                            }
                                                            break;
                                                        case 'address':
                                                            if (field.hidden) return null;
                                                            if (formFieldsEnabled[field.identifier]) {
                                                                return (
                                                                    <SSGAddress
                                                                        address={field}
                                                                    />
                                                                )
                                                            }
                                                            break;
                                                        case 'matrix':
                                                            if (field.hidden) return null;
                                                            if (formFieldsEnabled[field.identifier]) {

                                                                return (
                                                                    <>
                                                                        <SSGMatrix
                                                                            field={field}
                                                                            handleChange={(header, rowSelection, isChecked) => {
                                                                                // console.log('Header:', header);
                                                                                // console.log('Row Selection:', rowSelection);
                                                                                // console.log('Is Checked:', isChecked);

                                                                                // Handle different field types
                                                                                if (field.name !== 'use_case_matrix') {
                                                                                    let currentValues = formFieldsValues[field.identifier];

                                                                                    // Ensure currentValues is an array
                                                                                    if (!Array.isArray(currentValues)) {
                                                                                        currentValues = [];
                                                                                    }

                                                                                    // Combine header and row selection with a hyphen
                                                                                    const combinedValue = `${header}-${rowSelection}`;

                                                                                    let updatedValues;

                                                                                    if (isChecked) {
                                                                                        // Add the combined value to the array if it's not already present
                                                                                        if (!currentValues.includes(combinedValue)) {
                                                                                            updatedValues = [...currentValues, combinedValue];


                                                                                        } else {
                                                                                            updatedValues = [...currentValues]; // No change if already included
                                                                                        }
                                                                                    } else {
                                                                                        // Remove the combined value from the array
                                                                                        updatedValues = currentValues.filter(value => value !== combinedValue);
                                                                                    }

                                                                                    // Ensure we pass an array to form field values
                                                                                    handleChangedValue(parseInt(field.identifier), updatedValues);
                                                                                } else {
                                                                                    let currentValues = formFieldsValues[field.identifier] || {};

                                                                                    const formattedValue = ` - ${header} `;

                                                                                    const updatedValues = { ...currentValues, [rowSelection]: formattedValue };



                                                                                    handleChangedValue(parseInt(field.identifier), updatedValues);
                                                                                }
                                                                            }}
                                                                            currentValue={formFieldsValues[field.identifier]}
                                                                            formFieldsValues={formFieldsValues}
                                                                        />
                                                                    </>
                                                                )
                                                            }

                                                            break;
                                                        default:
                                                            if (debugOn) console.log(`******Field ${field.type} Not Found******`)
                                                            break
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                                <div>
                                    {
                                        // <div onClick={() => setChangeSection('back')}>back</div>
                                        <Button variant="contained" sx={{
                                            width: '10%', position: 'absolute', left: isPhoneSize ? '39%' : '30%',
                                            transform: 'translateX(-50%)', marginTop: -2
                                        }} onClick={() => setCurrentSection(previousSection)} disabled={!canGoBack}>{Translation("formLoader.BackButton")}</Button>
                                    }
                                    {!showSubmit &&
                                        // <div onClick={() => setChangeSection('forward')}>forward</div>
                                        <Button variant="contained" sx={{
                                            width: '10%', width: '10%', position: 'absolute', right: isPhoneSize ? '15%' : '30%',
                                            transform: 'translateX(-50%)', marginTop: -2
                                        }} onClick={() => setCurrentSection(nextSection)} disabled={!canGoForward}>{Translation("formoader.forwardButton")}</Button>
                                    }
                                    {showSubmit && !submitted &&
                                        // <div onClick={() => setChangeSection('forward')}>forward</div>
                                        <Button variant="contained" sx={{
                                            width: '10%', width: '10%', position: 'absolute', right: '30%',
                                            transform: 'translateX(-50%)', marginTop: -2
                                        }} onClick={() => handleSubmit()} disabled={!canSubmit}>{Translation("formLoader.submitButton")}</Button>
                                    }
                                    {showSubmit && submitted &&
                                        // <div onClick={() => setChangeSection('forward')}>forward</div>
                                        <Button variant="contained" sx={{
                                            width: '10%', width: '10%', position: 'absolute', right: '30%',
                                            transform: 'translateX(-50%)', marginTop: -2
                                        }} onClick={() => handleSubmit()} disabled={!canSubmit}>{Translation("formLoader.reSubmitButton")}</Button>
                                    }
                                </div>
                            </>
                        ) : (
                            <div>
                                {submitedSucessfully ? (
                                    <div>
                                        <h1>{Translation("formLoader.submissionSuccess")}</h1>
                                    </div>
                                ) : (
                                    <div>
                                        <h1>{Translation("formLoader.submissionError")}</h1>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </Card>
        </>
    )
}

export default withStyles(
    theme => ({
        ...layoutStyles(theme),
        ...styles(theme),
    }),
    { withTheme: true },
)(SSGFormLoader);




