import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import styles from "./styles";
import PageBase from "components/PageBase";
import ContactTabs from "containers/Pages/CompanyContact/Tabs";
import ThreePanelView from "containers/Layout/ThreePanelView";
import HeaderComponent from "./Components/Header";
import graphQLQueries from "graphql/organization";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import { useLazyQuery, useSubscription } from "@apollo/client";
import CompanySidePanel from "./Components/CompanySidePanel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotAuthorizedPage from "../ErrorPages/NotAuthorizedPage";



function CompanyContact(props) {


  const { GET_ORGANIZATION_PROFILE } = graphQLQueries();
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);
  const isAgent = user?.roles?.includes('agent');
  const navigate = useNavigate();

  const { loading: organizationLoading, error: organizationError, data: organizationDetails } = useSubscription(GET_ORGANIZATION_PROFILE, {
    variables: { organizationID: id },
  });


 
    if ( !isAgent && id !== user?.organization?.id) {
      return <NotAuthorizedPage />;
    }



   // console.log('organizationDetails',organizationDetails)
  return (
    <PageBase minHeight={500} noPadding={true} style={{}}>
      <ThreePanelView
        topPanelHeight={75}
        panelWidth={350}
        collapseHeight={75}
        pageHeaderHeight={100}
        singlePanel={true}
        topSection={
          //Insert Top Section Here
          <>
         <HeaderComponent
         company = {organizationDetails}
         />
          </>
        }
        leftSection={
          //Ignore Left Section
          <></>
        }
        centerSection={
          //Add center content here
          <>
          <ContactTabs
          company = {organizationDetails}/>
          </>
        }
        rightSection={
          //Add Right content here
          <CompanySidePanel
          company = {organizationDetails}
          id = {id}
          />
         
        }
      />
</PageBase>
  )
}


export default withStyles(
  (theme) => ({
    ...layoutStyles(theme),
    ...styles(theme),
  }),
  { withTheme: true }
)(CompanyContact);