import { gql } from "@apollo/client";

const getConfig = () => {
  const GET_CONFIG = gql`
    query GET_CONFIG {
      ticket_role(where: { deleted: { _eq: false } }) {
        id
        name
      }
      ticket_priority(
        order_by: { sort: asc }
        where: { active: { _eq: true }, deleted: { _eq: false } }
      ) {
        id
        name
      }
      ticket_status(
        order_by: { sort: asc }
        where: { active: { _eq: true }, deleted: { _eq: false } }
      ) {
        id
        name
        resolved
        open
        new
        hold
        pending
      }
      team {
        main
        name
        id
        people(
          where: { person: { active: { _eq: true }, deleted: { _eq: false } } }
        ) {
          person {
            name
            id
          }
        }
      }
      board(where: { active: { _eq: true }, deleted: { _eq: false } }) {
        id
        name
        teams(where: {}) {
          team {
            id
            name
            people {
              person {
                name
                id
              }
            }
          }
        }
      }
      planner_progress {
        icon
        id
        name
      }
      planner_priority {
        icon
        id
        name
      }
    }
  `;

  return {
    GET_CONFIG,
  };
};

export default getConfig;
