const styles = (theme) => ({
  flagNumberContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "250px",
    marginBottom: 20,
    gap: "10px",
  },
  flagNumberText: {
    flexGrow: 1,
    textAlign: "center",
  },
  callUsButton: {
    paddingLeft: 20,
    paddingRight: 20,
  }
});

export default styles;
