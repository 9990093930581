const component = {
    "components.search": "ค้นหา",

    // new translations
    "components.leftSideBarMenuList.TitleA": "SIX STAR",
    "components.leftSideBarMenuList.TitleB": "MANAGE",
    
    //password verification
    "components.passwordVerifyComponent.mustContain": "รหัสผ่านต้องประกอบด้วย: ",
    "components.passwordVerifyComponent.upperCase": "ตัวอักษรพิมพ์ใหญ่",
    "components.passwordVerifyComponent.lowerCase": "ตัวอักษรพิมพ์เล็ก",
    "components.passwordVerifyComponent.number": "ตัวเลข",
    "components.passwordVerifyComponent.min": "อย่างน้อย 8 ตัวอักษร",
    "components.passwordVerifyComponent.special": "ตัวอักษรพิเศษ",
    "components.passwordVerifyComponent.match": "รหัสผ่านต้องตรงกัน",
    "components.passwordVerifyComponent.enterNew": "กรุณาใส่รหัสผ่านใหม่ของคุณ",
    "components.passwordVerifyComponent.confirm": "ยืนยันรหัสผ่านใหม่ของคุณ",
    "components.passwordVerifyComponent.random": "รหัสผ่านสุ่มของคุณคือ:",
    "components.passwordVerifyComponent.copiedToClipboard": "คัดลอกลงคลิปบอร์ดแล้ว",
    "components.passwordVerifyComponent.makeNote": "โปรดทำบันทึกเก็บไว้",
    
    //password reset
    "components.passwordReset.error": "ขออภัย ไม่สามารถรีเซ็ตรหัสผ่านของคุณได้ในขณะนี้",
    "components.passwordReset.submitRequest": "โปรดส่งคำขอการสนับสนุนเพื่อให้เราช่วยคุณรีเซ็ตรหัสผ่าน",
    "components.passwordReset.thankYou": "ขอบคุณสำหรับความเข้าใจของคุณ",
    "components.passwprdReset.success.pleaseWait": "โปรดรอสักครู่ กำลังรีเซ็ตรหัสผ่าน",
    
};

export default component;