import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { joinPage } from "containers/App/websocket/navigation";

export function WebsocketChecker() {
  const dispatch = useDispatch();
  const location = useLocation();
  const globalState = useSelector((state) => state);
  const { user } = globalState.user;

  useEffect(() => {
    let path = window.location.pathname;
    path = path.replace(/^\/+/, "");
    if (path === "") {
      path = "/";
    }
    dispatch(joinPage({ pathname: path, user: user }));
    // eslint-disable-next-line
  }, [location]);

  return <></>;
}

export default WebsocketChecker;
