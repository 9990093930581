import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Box, Button, Stack, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import Translation from 'utils/translation';
import { SidePanelBoldTitle, SidePanelItemCard, SidePanelParagraph } from 'containers/Pages/TicketViewPage/components/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import useWarningNotification from 'utils/useWarningNotification';
import { useNavigate } from 'react-router-dom';

const Info = (props) => {
    const { company } = props;
  return (
    <>
      <SidePanelItemCard>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h6' onClick={() => {}} style={{ color: '#3F76B6', cursor: 'pointer' }}>
            {company?.organization?.[0]?.name}
          </Typography>
        </Box>
      </SidePanelItemCard>
    </>
  );
};

export default Info;
