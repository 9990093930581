import { get } from 'lodash';

const createAggregateQuery = (query, tableName) => {
    // Safely get the where clause from the query
    const whereClause = get(query, `${tableName}.__args.where`, {});

    // Create the aggregate query
    const aggregateQuery = {
        query: {
            [`${tableName}_aggregate`]: {
                __args: {
                    where: { ...whereClause }
                },
                aggregate: {
                    count: true
                }
            }
        }
    };

    return aggregateQuery;
};

export default createAggregateQuery;