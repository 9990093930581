const menu = {
  "menu.manage.dashboard.tabText": "Ship's Log",
  "menu.manage.dashboard.menuText": "Ship's Log",
  "menu.manage.supportSection.text": "Mateys",
  "menu.manage.supportSection.menuText": "Mateys",
  "menu.manage.supportSection.dashboard.tabText": "Ship's Log",
  "menu.manage.supportSection.dashboard.menuText": "Ship's Log",
  "menu.manage.supportSection.newRequest.tabText": "New Plea",
  "menu.manage.supportSection.newRequest.menuText": "New Plea",
  "menu.manage.supportSection.myTicketsOverview.tabText": "Me Letters Overview",
  "menu.manage.supportSection.myTicketsOverview.menuText":
    "Me Letters Overview",
  "menu.manage.supportSection.approvals.tabText": "Approvals, Arrr",
  "menu.manage.supportSection.approvals.menuText": "Approvals, Arrr",
  "menu.manage.sixstarMeet.tabText": "Six Star Parley",
  "menu.manage.sixstarMeet.menuText": "Six Star Parley",
  "menu.manage.manageSixStarConnect.tabText": "Six Star Connect, Yarrr",
  "menu.manage.manageSixStarConnect.menuText": "Six Star Connect, Yarrr",
  "menu.manage.manageManageSection.text": "Command",
  "menu.manage.manageManageSection.menuText": "Command",
  "menu.manage.manageManageSection.dashboard.tabText": "Ship's Log",
  "menu.manage.manageManageSection.dashboard.menuText": "Ship's Log",
  "menu.manage.manageSection.text": "Command",
  "menu.manage.manageSection.menuText": "Command",
  "menu.manage.manageSection.documents.tabText": "Papers",
  "menu.manage.manageSection.documents.menuText": "Papers",
  "menu.manage.manageSection.securityReports.tabText": "Lookout Reports",
  "menu.manage.manageSection.securityReports.menuText": "Lookout Reports",
  "menu.manage.manageSection.serviceReports.tabText": "Service Reports, Yarrr",
  "menu.manage.manageSection.serviceReports.menuText": "Service Reports, Yarrr",
  "menu.manage.manageSection.licenceManagement.tabText": "Licence Command",
  "menu.manage.manageSection.licenceManagement.menuText": "Licence Command",
  "menu.manage.manageSection.assetManagement.tabText": "Treasure Command",
  "menu.manage.manageSection.assetManagement.menuText": "Treasure Command",
  "menu.manage.manageSection.upcomingExpiries.tabText":
    "Upcomin' Expiries, Arrr",
  "menu.manage.manageSection.upcomingExpiries.menuText":
    "Upcomin' Expiries, Arrr",
  "menu.manage.manageSection.bookACallWithYourAccountManager.tabText":
    "Parley with Ye Account Keeper",
  "menu.manage.manageSection.bookACallWithYourAccountManager.menuText":
    "Parley with Ye Account Keeper",
  "menu.manage.financeSection.text": "Treasure",
  "menu.manage.financeSection.menuText": "Treasure",
  "menu.manage.financeSection.dashboard.tabText": "Ship's Log",
  "menu.manage.financeSection.dashboard.menuText": "Ship's Log",
  "menu.manage.financeSection.ordersQuotesInvoices.tabText":
    "Orders/Quotes/Bills of Sale",
  "menu.manage.financeSection.ordersQuotesInvoices.menuText":
    "Orders/Quotes/Bills of Sale",
  "menu.manage.financeSection.creditApplication.tabText": "Credit Plea",
  "menu.manage.financeSection.creditApplication.menuText": "Credit Plea",
  "menu.manage.helpSection.text": "Aid",
  "menu.manage.helpSection.menuText": "Aid",
  "menu.manage.helpSection.contactUs.tabText": "Send a Bottle Message",
  "menu.manage.helpSection.contactUs.menuText": "Send a Bottle Message",
  "menu.manage.helpSection.giveFeedback.tabText": "Give Yer Thoughts",
  "menu.manage.helpSection.giveFeedback.menuText": "Give Yer Thoughts",
  "menu.manage.helpSection.guides.tabText": "Sea Charts",
  "menu.manage.helpSection.guides.menuText": "Sea Charts",
  "menu.manage.helpSection.customerForms.tabText": "Sailor Forms",
  "menu.manage.helpSection.customerForms.menuText": "Sailor Forms",
  "menu.manage.engineerSection.text": "Engineer Matey",
  "menu.manage.engineerSection.menuText": "Engineer Matey",
  "menu.manage.engineerSection.myTasks.tabText": "Me Duties",
  "menu.manage.engineerSection.myTasks.menuText": "Me Duties",
  "menu.manage.engineerSection.ticketBoard.tabText": "Ticket Plank",
  "menu.manage.engineerSection.ticketBoard.menuText": "Ticket Plank",
  "menu.manage.engineerSection.triageTickets.tabText": "Triage Letters",
  "menu.manage.engineerSection.triageTickets.menuText": "Triage Letters",
  "menu.manage.engineerSection.priorityTickets.tabText": "Priority Letters",
  "menu.manage.engineerSection.priorityTickets.menuText": "Priority Letters",
  "menu.manage.engineerSection.escalations.tabText": "Escalations, Arrr",
  "menu.manage.engineerSection.escalations.menuText": "Escalations, Arrr",
  "menu.manage.internalSection.text": "Ship's Crew",
  "menu.manage.internalSection.menuText": "Ship's Crew",
  "menu.manage.internalSection.orderTracker.tabText": "Order Tracker, Yarrr",
  "menu.manage.internalSection.orderTracker.menuText": "Order Tracker, Yarrr",
  "menu.manage.internalSection.resourceTracker.tabText":
    "Resource Tracker, Yarrr",
  "menu.manage.internalSection.resourceTracker.menuText":
    "Resource Tracker, Yarrr",
  "menu.manage.internalSection.knowledgeBase.tabText": "Knowledge Chest",
  "menu.manage.internalSection.knowledgeBase.menuText": "Knowledge Chest",
  "menu.manage.internalSection.twoFactor.tabText": "Two-Factor, Arrr",
  "menu.manage.internalSection.twoFactor.menuText": "Two-Factor, Arrr",
  "menu.manage.internalSection.internal.tabText": "Ship's Crew",
  "menu.manage.internalSection.internal.menuText": "Ship's Crew",
  "menu.manage.internalSection.expenses.tabText": "Expenses, Yarrr",
  "menu.manage.internalSection.expenses.menuText": "Expenses, Yarrr",
  "menu.manage.userSection.text": "Sailor",
  "menu.manage.userSection.menuText": "Sailor",
  "menu.manage.userSection.createContact.tabText": "Create Matey",
  "menu.manage.userSection.createContact.menuText": "Create Matey",
  "menu.manage.userSection.contactSearch.tabText": "Matey Search",
  "menu.manage.userSection.contactSearch.menuText": "Matey Search",
  "menu.manage.userSection.zoomPresence.tabText": "Zoom Presence, Yarrr",
  "menu.manage.userSection.zoomPresence.menuText": "Zoom Presence, Yarrr",
  "menu.manage.userSection.zoomCallQueues.tabText": "Zoom Call Queues, Yarrr",
  "menu.manage.userSection.zoomCallQueues.menuText": "Zoom Call Queues, Yarrr",
  "menu.manage.userSection.mergeContacts.tabText": "Merge Mateys",
  "menu.manage.userSection.mergeContacts.menuText": "Merge Mateys",
  "menu.manage.adminSection.text": "Captain",
  "menu.manage.adminSection.menuText": "Captain",
  "menu.manage.adminSection.dashboard.tabText": "Ship's Log",
  "menu.manage.adminSection.dashboard.menuText": "Ship's Log",
  "menu.manage.adminSection.impersonate.tabText": "Impersonate, Arrr",
  "menu.manage.adminSection.impersonate.menuText": "Impersonate, Arrr",
  "menu.manage.supportSection.newRequestDashboard.tabText": "New Plea",
  "menu.manage.supportSection.newRequestDashboard.menuText": "New Plea",
  "menu.manage.supportSection.newRequestForm.tabText.supportRequest":
    "Support Plea",
  "menu.manage.supportSection.newRequestForm.tabText.serviceRequest":
    "Service Plea",
  "menu.manage.supportSection.newRequestForm.tabText.procurementRequest":
    "Procurement Plea",
  "menu.manage.supportSection.newRequestForm.tabText.internalRequest":
    "Internal Plea",
  "menu.manage.supportSection.newRequestForm.tabText.passwordReset":
    "Password Reset, Arrr",
  "menu.manage.supportSection.newRequestForm.tabText.newStarter":
    "New Starter / Role Change, Yarrr",
  "menu.manage.supportSection.newRequestForm.tabText.leaverRequest":
    "Leaver Plea",
  "menu.manage.supportSection.newRequestForm.tabText.engineerVisit":
    "Engineer Visit, Yarrr",
  "menu.manage.supportSection.newRequestForm.tabText.developmentRequest":
    "Development Plea",
  "menu.manage.engineerSection.ticketViewPage.tabText": "Letter# {id}",
  "menu.manage.engineerSection.ticketViewPage.menuText": "Letter {id}",
  "menu.manage.engineerSection.ticketsBoard.tabText": "Letters Plank",
  "menu.manage.engineerSection.ticketsBoard.menuText": "Letters Plank",
  "menu.manage.supportSection.newRequestForm.tabText": "{id}",
  "menu.manage.supportSection.newRequestForm.menuText": "{id}",
  "menu.manage.engineerSection.drive.tabText": "Six Star Drive, Yarrr",
  "menu.manage.engineerSection.drive.menuText": "Six Star Drive, Yarrr",
  "menu.manage.engineerSection.reports.tabText": "Reports, Yarrr",
  "menu.manage.engineerSection.reports.menuText": "Reports, Yarrr",
  "menu.manage.engineerSection.companyProfile.tabText": "Me Crew",
  "menu.manage.engineerSection.companyProfile.menuText": "Me Crew",
  "menu.manage.engineerSection.chatGPT.tabText": "Chat GPT, Yarrr",
  "menu.manage.engineerSection.chatGPT.menuText": "Chat GPT, Yarrr",
};
export default menu;
