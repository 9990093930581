import { updateMsssoTokens } from "slices/user";
import { REFRESH_SSO } from "containers/App/websocket/constants";

function microsoftMiddleware(client, socket) {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === "function") {
        return action(dispatch, getState);
      }

      const { promise, type, types, ...rest } = action;

      if (type !== "microsoft" || !promise) {
        return next(action);
      }

      // if (type === 'microsoft') {
      //     console.log('microsoft middleware')
      // }

      const [REQUEST, SUCCESS, FAILURE] = types;

      if (REQUEST) {
        // console.log('starting process')
        return getRefreshToken(getState, socket, dispatch).then((token) => {
          // console.log('stage 1 done')
          return checkClientConnected(client, token, client).then(() => {
            // console.log('Making The Call')
            return makeCall(client, SUCCESS, FAILURE, rest, promise, next);
          });
        });
      }
    };
}

const makeCall = (client, SUCCESS, FAILURE, rest, promise, next) => {
  return promise(client)
    .then((result) => {
      // console.log('call complete 1')
      return next({ ...rest, result, type: SUCCESS });
    })
    .catch((error) => {
      // console.log('call complete 2')
      return next({ ...rest, error, type: FAILURE });
    });
};

const getRefreshToken = (getState, socket, dispatch, client) => {
  return new Promise(async (resolve, reject) => {
    let mssso = getState().user.mssso;
    if (
      Math.floor(new Date(mssso?.expiresOn)) <
      Math.floor(new Date(new Date().getTime() + 30 * 60000))
    ) {
      // console.log({ 'getting refresh token': mssso?.refreshToken })
      await socket
        .emit(REFRESH_SSO, {
          refreshToken: mssso?.refreshToken,
          tennentID: mssso?.account?.tenantId,
        })
        .then((response) => {
          dispatch(updateMsssoTokens(response.data));
          resolve(response.data.access_token);
          // console.log("refresh token complete");
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    } else {
      // console.log('dont need new token')
      resolve(mssso.accessToken);
    }
  });
};

const checkClientConnected = (client, token) => {
  return new Promise((resolve, reject) => {
    if (client?.config) {
      client
        .connect(token)
        .then(() => {
          // console.log('client connected')
          resolve();
        })
        .catch((err) => {
          // console.log('client not connected')
          // console.log(err)
          reject(err);
        });
    } else {
      // console.log("client is not null")
      // console.log(client)
      resolve();
    }
  });
};

export default microsoftMiddleware;
