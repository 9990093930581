import { forEach, isObject, includes } from "lodash";

const getInitials = (name) => {
  try {
    if (name == null) {
      name = "Six Star";
    }
    var str = name;
    var matches = str.match(/\b(\w)/g);
    return matches.join("");
  } catch (e) {
    return "SS";
  }
};

const fullnameGenerator = (data, default_value) => {
  let firstName = data?.first_name ?? default_value;
  let lastName = data?.last_name ?? default_value;

  let fullName = firstName + " " + lastName;
  if (fullName === `${default_value} ${default_value}`)
    fullName = default_value;

  return fullName;
};

const capitalize = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const getMainPhoneNumber = (data, format = false) => {
  let returnItem = null;
  data?.forEach((item) => {
    if (item?.phone_number.main === true) {
      returnItem = item?.phone_number;
    }
  });
  if (format) returnItem = formatPhoneNumber(returnItem);
  return returnItem;
};

const formatPhoneNumber = (data) => {
  if (data === null) return null;
  // remove all non numeric characters and whitespace from data?.number
  let number = data?.number.replace(/\D/g, "");
  let returnData = `+${data?.country?.dialing_prefix ?? ""}${number}`;
  try {
    let formattedReturn = returnData;
    formattedReturn.split("").forEach((item, index) => {
      if (index === 0) {
        formattedReturn = `(${item}`;
      } else if (index === 2) {
        formattedReturn = `${formattedReturn}${item}) `;
      } else if (index === 5) {
        formattedReturn = `${formattedReturn}${item} `;
      } else if (index === 8) {
        formattedReturn = `${formattedReturn}${item} `;
      } else {
        formattedReturn = `${formattedReturn}${item}`;
      }
    });
    return formattedReturn;
  } catch (e) {
    console.log("error");
    return returnData;
  }
};
const findObjectsByKey = (obj, objectKeys, foundObjects) => {
  forEach(obj, (value, key) => {
    if (isObject(value)) {
      findObjectsByKey(value, objectKeys, foundObjects);
    }
    if (includes(objectKeys, key)) {
      //if value.id is not in foundObjects, add the value object to foundObjects
      if (!foundObjects.some((obj) => obj?.id === value?.id)) {
        foundObjects.push(value);
      }
    }
  });
};
export {
  getInitials,
  fullnameGenerator,
  capitalize,
  getMainPhoneNumber,
  formatPhoneNumber,
  findObjectsByKey,
};
