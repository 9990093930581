import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Icon from "@mui/material/Icon";
import Tab from "@mui/material/Tab";
import { MenuSubTabs as Tabs } from "../Styled";
import Translation from "utils/translation";
import layoutStyles from "containers/Layout/styles";
import { gql, useLazyQuery } from "@apollo/client";

const GET_PERSON_NAME = gql`
  query getPersonName($id: uuid = "") {
    person(where: { id: { _eq: $id } }) {
      id
      name
    }
  }
`;

const SubTabs = ({
  classes,
  handleCloseView,
  handleTabChange,
  openViews,
  selectedMenuItem,
}) => {

  const [personName, setPersonName] = useState([]);
  const [getPerson, { data, loading, error }] = useLazyQuery(GET_PERSON_NAME)




  useEffect(() => {
    if (data) {
      setPersonName(prevState => {
        return [...prevState, { id: data.person[0].id, name: data.person[0].name }];
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      // console.log('Error getting person name', error);
    }
  }, [error]);

  useEffect(() => {
    if (loading) {
      // console.log('loading')
    }
  }, [loading]);

  useEffect(() => {
    const menuItem = openViews.find(item => item.id.includes('personProfile'));


    openViews.forEach((item, index) => {
      if (item.id.includes('personProfile')) {
        if (!personName.find(item => item.id === item.itemId)) {
          getPerson({ variables: { id: item.itemId } });
        }
      }
    })
  }, [openViews, getPerson]);

  return (
    <Tabs
      className={classNames("tabs", classes.tabs)}
      value={selectedMenuItem.index}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
    >
      {openViews &&
        openViews.map((menuItem) => (
          <Tab
            key={`id-${menuItem.id}`}
            value={menuItem.index}
            label={
              menuItem.id.includes('personProfile') ? personName.find(item => item.id === menuItem.itemId)?.name ?? menuItem.itemId :
                menuItem.dynamic
                  ? Translation(menuItem.text, { id: menuItem.itemId })
                  : Translation(menuItem.text)
            }
            className={classNames(
              `${menuItem.index > 0 ? "close-tab" : ""}`,
              classes.tab
            )}
            icon={
              menuItem.index ? (
                <Icon
                  onClick={() => handleCloseView(menuItem)}
                  className="material-icons"
                >
                  cancel
                </Icon>
              ) : null
            }
          />
        ))}
    </Tabs>
  );
};

SubTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCloseView: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  openViews: PropTypes.any.isRequired,
  selectedMenuItem: PropTypes.object.isRequired,
};

export default withStyles((theme) => layoutStyles(theme), {
  withTheme: true,
})(SubTabs);
