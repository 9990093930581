const widgets = {
  "widget.barChart.name": "Widget Wykresu Słupkowego",
  "widget.barChart.description":
      "To jest bardzo fajny widget testowy, jest importowany i można go używać w innych dodatkach",
  "widget.organizationTickets.name": "Widget Biletów Organizacji",
  "widget.organizationTickets.description":
      "To jest widget wyświetlający liczbę otwartych priorytetów 1.",
  "widget.outlookInbox.name": "Widget Skrzynki Odbiorczej Outlook",
  "widget.outlookInbox.description":
      "To jest widget wyświetlający liczbę e-maili w skrzynce odbiorczej.",
  "widget.personTickets.name": "Osobisty Widget Biletów",
  "widget.personTickets.description":
      "To jest widget wyświetlający liczbę otwartych priorytetów 1.",
  "widget.pieChart.name": "Widget Wykresu Kołowego",
  "widget.pieChart.description":
      "To jest bardzo fajny widget testowy, jest importowany i można go używać w innych dodatkach",
  "widget.quickLinks.name": "Widget Szybkich Linków",
  "widget.quickLinks.description":
      "To jest widget wyświetlający listę linków do innych stron na stronie.",
  "widget.social.name": "Widget Kanału Społecznościowego",
  "widget.social.description":
      "To jest widget kanału społecznościowego połączony z kontami społecznościowymi Six Star.",
  "widget.ticketTable.name": "Widget Tabeli Biletów",
  "widget.ticketTable.description":
      "To jest bardzo fajny widget testowy, jest importowany i można go używać w innych dodatkach",
  "widget.social.feedType": "Facebook / Twitter",
  "widget.quickLinks.newRequest": "Nowe Zapytanie",
  "widget.quickLinks.licenceManagement": "Zarządzanie Licencjami",
  "widget.quickLinks.assetManagement": "Zarządzanie Aktywami",
  "widget.quickLinks.sixStarDrive": "Six Star Drive",
  "widget.quickLinks.sixStarGlobal": "Six Star Global",

  "widget.quickLinks.button.newRequest": "Nowe Zapytanie",
"widget.quickLinks.button.licenceManagement": "Zarządzanie Licencjami",
"widget.quickLinks.button.assetManagement": "Zarządzanie Zasobami",
"widget.quickLinks.button.sixStarDrive": "Six Star Drive",

"widgets.org.tickets.p1": "Bilety Org P1",
"widgets.org.tickets.p2": "Bilety Org P2",
"widgets.org.tickets.open": "Otwarte bilety Org",

"widgets.personal.tickets.p1": "Osobiste P1",
"widgets.personal.tickets.p2": "Osobiste P2",
"widgets.personal.tickets.open": "Otwarte osobiste",
"widgets.personal.tickets.p1": "Osobiste P1",
"widgets.personal.tickets.p2": "Osobiste P2",
"widgets.personal.tickets.open": "Otwarte Osobiste",
"widget.Guage.name": "Widget Wykresu Rozwiązanych",
"widget.Guage.description": "To jest widget, który wyświetla liczbę rozwiązanych zgłoszeń z dzisiejszej kolejki.",
"widget.LineGraph.name": "Widget Wykresu Liniowego",
"widget.LineGraph.description": "To jest widget, który wyświetla liczbę utworzonych zgłoszeń w ciągu ostatnich 30 dni.",
"widget.muiBarChart.name": "Widget Wykresu Słupkowego",
"widget.muiBarChart.description": "To jest widget, który wyświetla liczbę zgłoszeń, według priorytetu, utworzonych w ciągu ostatnich 30 dni.",
"widget.ReleaseNotes.name": "Widget Notatek Wydania",
"widget.ReleaseNotes.description": "To jest widget, który wyświetla najnowsze notatki wydania.",
"widget.DailyUpdates.name": "Widget Codziennych Aktualizacji",
"widget.DailyUpdates.description": "To jest widget, który wyświetla codzienne aktualizacje.",
"widget.TicketPieChart.name": "Widget Wykresu Kołowego Zgłoszeń",
"widget.TicketPieChart.description": "To jest widget, który wyświetla liczbę zgłoszeń według statusu.",
"widget.barchart.title" : "Zgłoszenia według Priorytetu",
"widget.linechart.title" : "Zgłoszenia Utworzone w Ciągu Ostatnich 30 Dni",
"widget.piechart.title" : "Zgłoszenia według Statusu",
"widget.guage.title" : "Rozwiązane Zgłoszenia z Dzisiejszej Kolejki",

};
export default widgets;
