import React from "react";

import withStyles from "@mui/styles/withStyles";

import layoutStyles from "containers/Layout/styles";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import useTranslation from "containers/App/Hooks/useTranslation";
import { useSelector } from "react-redux";

const HeaderBanner = ({
  classes,
  selectedMenuItem,
  selectedSubMenuItem,
  drawerIsOpen,
  showHeaderTabs,
}) => {
  const header = useSelector((state) => state.app.header);
  const location = useSelector((state) => state?.router?.location);
  // console.log(showHeaderTabs)
  const { translate } = useTranslation();
  let bannerText = translate(selectedMenuItem.text);
  if (selectedSubMenuItem) {
    if (selectedSubMenuItem.dynamic) {
      bannerText = translate(selectedSubMenuItem.text, {
        id: selectedSubMenuItem.itemId,
      });
    } else {
      bannerText = translate(selectedSubMenuItem.text);
    }
  }

  // get last word of text
  let lastWord = bannerText.split(" ").pop();
  //remove last word from text
  bannerText = bannerText.replace(lastWord, "");
  // if only one word, remove last word
  if (bannerText === "") {
    bannerText = lastWord;
    lastWord = "";
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#103F65",
          height: "60px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          zIndex: 1000,
          marginTop: "48px",
          "@media (max-width: 600px)": {
            marginTop: "44px",
            height: "40px",
          },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: drawerIsOpen
              ? "calc(100% - 240px + 1px)"
              : "calc(100% - 60px + 1px)",
            transition:
              "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            justifyContent: "start",
            marginLeft: drawerIsOpen ? "240px" : "60px",
            "@media (max-width: 600px)": {
              width: drawerIsOpen
                ? "calc(100% - 240px + 1px)"
                : "calc(100% + 1px)",
              marginLeft: drawerIsOpen ? "240px" : "0px",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              top: `${
                showHeaderTabs && selectedMenuItem.children ? "6px" : "2px"
              }`,
              "@media (max-width: 600px)": {
                top: 0,
              },
            }}
          >
            <Typography
              variant={"h4"}
              sx={{
                color: "white!important",
                fontWeight: "400",
                marginLeft: "52px",
                lineHeight: "60px",
                display: "inline-block",
                "@media (max-width: 600px)": {
                  fontSize: "1.5rem",
                  lineHeight: "40px",
                },
                // marginTop:"28px"
              }}
            >
              {" "}
              {bannerText}
            </Typography>
            {lastWord && (
              <Typography
                variant={"h4"}
                sx={{
                  color: "white!important",
                  fontWeight: "600",
                  marginLeft: 1,
                  lineHeight: "60px",
                  display: "inline-block",
                  "@media (max-width: 600px)": {
                    fontSize: "1.5rem",
                    lineHeight: "40px",
                  },
                }}
              >
                {" "}
                {lastWord}
              </Typography>
            )}
          </Box>
          {header.RightSide &&
            location?.pathname === header.location?.pathname && (
              <>{header.RightSide}</>
            )}
        </Box>
      </Box>
    </>
  );
};

export default withStyles((theme) => layoutStyles(theme), {
  withTheme: true,
})(HeaderBanner);
