const login = {
  "login.title": "SIX STAR GLOBAL",
  "login.headline": "The Worlds Leading IT Managed Services Company.",
  "login.signInText": "Sign Into Six Star: Manage",
  "login.topCallToActionTitle": "Not Part of Six Star?",
  "login.topCallToActionLink": "Let's Chat",
  "loginPage.input.email": "E-mail",
  "loginPage.input.password": "Password",
  "loginPage.input.mfa": "MFA Code",
  "loginPage.rememberMe": "Remember Me",
  "loginPage.button.login": "Sign In",
  "loginPage.button.forgotPassword": "Forgot Password?",
  "loginPage.apiMfaInProgress": "Please enter your MFA code",
  "login.forgotPasswordText": "Forgot Password",
  "loginPage.apiMultipleUsers": "User Account Error, Contact Six Star",
  "loginPage.apiNoPassword": "Please enter password",
  "loginPage.apiNoUser": "Please enter email address",
  "loginPage.apiInvalidTOTP": "Invalid MFA Code",
  "loginPage.apiInvalidPassword": "Invalid Password",
  "login.sso.starting": "Starting Single Sign On",
  "loginPage.button.normalSignIn": "Username/Password Sign In",
  "loginPage.text.msssoLoading": "Loading...",
  "loginPage.label.msssoSignIn": "Sign In With Microsoft",
  "loginPage.label.googleSignIn": "Sign In With Google",
  "loginPage.label.msssoSignInEmail": "Microsoft Email Address",
  "loginPage.text.msssoError": "Error Signing In",


  "loginPage.emailPrompt": "Enter your e-mail address below to reset your password.",
  "loginPage.ForgotPassword.GoBack": "Go Back",
  "loginPage.ForgotPassword.Submit": "Submit",
  "loginPage.label.email": "E-mail",
  "loginPage.helpIcon": "Help",
  "loginPage.label.fullName": "Full Name",
  "loginPage.label.password": "Password",
  "loginPage.label.confirmPassword": "Confirm Password",
  "loginPage.register.button.goback": "Go Back",
  "loginPage.register.button.register": "Register",
  "loginPage.register.button.signUp": "Sign Up",

  // new translations 
};
export default login;
