import { Filter } from "interweave";

export default class StylesFilter extends Filter {
  attribute(name, value) {
    if (name === "style") {
      return { ...value, width: "100%", height: "auto" };
    }
    if (name === "href") {
      return encodeURIComponent(value);
    }

    return value;
  }

  node(name, node) {
    // console.log(node.innerHTML)
    if (node.innerHTML === "&nbsp;") {
      return null;
    }
    // node.innerText = node.innerText.replace(/\n/g, '');

    if (name === "table") {
      node.setAttribute("width", "100%");
    }
    if (name === "img") {
      node.setAttribute("width", "100%");
      node.setAttribute("height", "auto");
    }
    if (name === "a") {
      //todo allow only certain links
      return null;
    }
    // node.setAttribute('style', '');

    return node;
  }
}
