import React, { useState } from 'react';

import withStyles from '@mui/styles/withStyles';
import { DialogTitle, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
function GlobalModal(props) {
  const { cancelLabel = 'cancel', submitLabel = 'ok', width = '400px', theme, open, handleClose, handleCallback = () => {}, content, title } = props;
  let childWithProps;

  const [buttonClicked, setButtonClicked] = useState(false);
  const [hideActionButton, setHideActionButton] = useState(false);
  const [disableActionButton, setDisableActionButton] = useState(false);
  const handleActionButtonPress = () => {
    setButtonClicked(true);
    // Reset the state if necessary, for example after a short delay, to allow the child to react to subsequent clicks
    setTimeout(() => setButtonClicked(false), 100); // Reset after 100ms
  };
  if (content) {
    childWithProps = React.cloneElement(content, {
      handleClose,
      handleCallback,
      theme,
      onActionButtonPressed: buttonClicked,
      disableActionButton: setDisableActionButton,
      hideActionButton: setHideActionButton,
      ...content.props,
    });
  }

  const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '0%',
    // transform: `translateX(-50%) translateY(calc(-50% - .5px))`,
    '.MuiDialog-paper': {
      maxWidth: width,
    },
  };
  return (
    <Dialog open={open} onClose={handleClose} sx={style} fullWidth>
      {title && (
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          {title}
        </DialogTitle>
      )}

      <DialogContent>{childWithProps}</DialogContent>
      {!hideActionButton && (
        <DialogActions>
          <Button
            sx={{
              width: '50%',
            }}
            autoFocus
            onClick={handleClose}
          >
            {cancelLabel}
          </Button>
          <Button
            disabled={disableActionButton}
            sx={{
              width: '50%',
            }}
            onClick={handleActionButtonPress}
          >
            {submitLabel}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default withStyles((theme) => ({}), { withTheme: true })(GlobalModal);
