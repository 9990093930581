import { grey, red } from "@mui/material/colors";

const styles = () => ({
  checkRemember: {
    maxWidth: 180,
  },
  checkRememberContainer: {
    display: "inline-flex",
    marginTop: 15,
    display: "inline-flex",
    marginTop: 15,
  },
  loginContainer: {
    height: "100vh",
    width: "100%",
    display: "flex",
    minWidth: "427px",
    height: "100vh",
    width: "100%",
    display: "flex",
    minWidth: "427px",
  },
  loginLeftSide: {
    background: "#2A203B",
    width: "70vw",
    maxWidth: "70px",
    minWidth: "70px",
    background: "#2A203B",
    width: "70vw",
    maxWidth: "70px",
    minWidth: "70px",
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `center`,
    alignItems: `center`,
  },
  verticalSpacer: {
    flexGrow: 1,
    flexGrow: 1,
  },
  horizontalSpacer: {
    flexGrow: 1,
    flexGrow: 1,
  },
  leftSideBarBottomIcon: {
    marginBottom: 20,
    fontSize: "1.2em",
    fontSize: "1.2em",
  },
  topSideBarIcon: {
    fontSize: "1.2em",
  },
  loginRightSide: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "100%",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "100%",
    flexGrow: 1,
  },
  boxContainer: {
    minWidth: "427px",
    minWidth: "427px",
  },
  boxWrapper: {
    margin: 30,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "15px",
  },
  buttonsDiv: {
    textAlign: "right",
    textAlign: "right",
    paddingTop: 10,
  },
  errorMessage: {
    color: "red",
    marginTop: 20,
    textAlign: "center",
  },
  errorMessageGreen: {
    color: "green",
    marginTop: 20,
    textAlign: "center",
    textAlign: "center",
  },
  flatButton: {
    color: "#532C6D",
    fontWeight: "bold",
    color: "#532C6D",
    fontWeight: "bold",
  },
  instructions: {
    textAlign: "center",
    textAlign: "center",
    color: grey[500],
  },
  logoContainer: {
    textAlign: "center",
    textAlign: "center",
    maxWidth: 360,
    paddingTop: 20,
  },
  logoSmallContainer: {
    position: "absolute",
    position: "absolute",
    right: 20,
    top: 20,
  },
  paper: {
    padding: 40,
    overflow: "auto",
    overflow: "auto",
  },
  mobileContainer: {
    top: "15%",
    top: "15%",
  },
  mobileContainerRegister: {
    top: "10%",
    top: "10%",
  },
  title: {
    fontSize: 22,
    height: 60,
  },
  leftSideLogo: {
    marginBottom: "30px",
    marginTop: "20px",
    maxWidth: "100%",
    marginBottom: "30px",
    marginTop: "20px",
    maxWidth: "100%",
  },
  topSideLogo: {
    maxWidth: "100%",
    maxWidth: "100%",
  },
  loginCallToAction: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    top: "20px",
    right: "20px",
    justifyContent: "space-between",
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    top: "20px",
    right: "20px",
    justifyContent: "space-between",
  },
  LoginBoxHeader: {
    borderBottomStyle: "solid",
    borderBottomColor: "#4292CD",
    borderBottomWidth: "4px",
    paddingBottom: "8px",
    marginBottom: "8px",
    borderBottomStyle: "solid",
    borderBottomColor: "#4292CD",
    borderBottomWidth: "4px",
    paddingBottom: "8px",
    marginBottom: "8px",
  },
  loginButton: {
    maxWidth: "120px",
    maxWidth: "120px",
  },
  loginButtonSso: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  forgotPasswordGoBack: {
    maxWidth: "120px",
    maxWidth: "120px",
  },
  forgotPasswordButton: {
    maxWidth: "120px",
    maxWidth: "120px",
  },
  forgotPasswordButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "20px",
  },
  horizontalTopBar: {
    minWidth: "100%",
    justifyContent: "center",
    background: "#2A203B",
    height: "70px",
    alignItems: "center",
    minWidth: "100%",
    justifyContent: "center",
    background: "#2A203B",
    height: "70px",
    alignItems: "center",
  },
});

export default styles;
