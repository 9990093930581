import React from 'react';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ViewSelectorIcon = ({ disabled, icon, onClick }) => (
    <IconButton
        sx={{
            height: '34px',
            width: '34px',
            margin: 0,
            padding: 0,
        }}
        size={'small'}
        disabled={disabled}
        onClick={onClick
        }
    >
        <FontAwesomeIcon icon={icon} fixedWidth size={'sm'} />
    </IconButton>
)

export default ViewSelectorIcon;