const widgets = {
  "widget.barChart.name": "Balkendiagramm-Widget",
  "widget.barChart.description":
    "Dies ist ein sehr cooles Test-Widget, es ist importiert und kann in anderen Addons verwendet werden",
  "widget.organizationTickets.name": "Organisations-Tickets-Widget",
  "widget.organizationTickets.description":
    "Dies ist ein Widget, das die Anzahl der offenen Priorität 1 anzeigt.",
  "widget.outlookInbox.name": "Outlook Posteingang-Widget",
  "widget.outlookInbox.description":
    "Dies ist ein Widget, das die Anzahl der E-Mails in Ihrem Posteingang anzeigt.",
  "widget.personTickets.name": "Persönliche Tickets-Widget",
  "widget.personTickets.description":
    "Dies ist ein Widget, das die Anzahl der offenen Priorität 1 anzeigt.",
  "widget.pieChart.name": "Kreisdiagramm-Widget",
  "widget.pieChart.description":
    "Dies ist ein sehr cooles Test-Widget, es ist importiert und kann in anderen Addons verwendet werden",
  "widget.quickLinks.name": "Schnellverknüpfungen-Widget",
  "widget.quickLinks.description":
    "Dies ist ein Widget, das eine Liste von Links zu anderen Seiten auf der Website anzeigt.",
  "widget.social.name": "Soziales Feed-Widget",
  "widget.social.description":
    "Dies ist ein soziales Feed-Widget, das mit den Social-Media-Konten von Six Star verbunden ist.",
  "widget.ticketTable.name": "Ticket-Tabelle-Widget",
  "widget.ticketTable.description":
    "Dies ist ein sehr cooles Test-Widget, es ist importiert und kann in anderen Addons verwendet werden",
  "widget.social.feedType": "Facebook / Twitter",
  "widget.quickLinks.newRequest": "Neue Anfrage",
  "widget.quickLinks.licenceManagement": "Lizenzverwaltung",
  "widget.quickLinks.assetManagement": "Asset-Management",
  "widget.quickLinks.sixStarDrive": "Six Star Drive",
  "widget.quickLinks.sixStarGlobal": "Six Star Global",

  "widget.quickLinks.button.newRequest": "Neue Anfrage",
"widget.quickLinks.button.licenceManagement": "Lizenzverwaltung",
"widget.quickLinks.button.assetManagement": "Asset-Management",
"widget.quickLinks.button.sixStarDrive": "Six Star Drive",

"widgets.org.tickets.p1": "Org P1 Tickets",
"widgets.org.tickets.p2": "Org P2 Tickets",
"widgets.org.tickets.open": "Org Offene Tickets",

"widgets.personal.tickets.p1": "Persönliche P1",
"widgets.personal.tickets.p2": "Persönliche P2",
"widgets.personal.tickets.open": "Persönliche Offen",

"widgets.personal.tickets.p1": "Persönliche P1s",
"widgets.personal.tickets.p2": "Persönliche P2s",
"widgets.personal.tickets.open": "Persönlich Offen",
"widget.Guage.name": "Gelöster Messgerät-Widget",
"widget.Guage.description": "Dies ist ein Widget, das die Anzahl der gelösten Tickets aus der Warteschlange von heute anzeigt.",
"widget.LineGraph.name": "Linien-Diagramm-Widget",
"widget.LineGraph.description": "Dies ist ein Widget, das die Anzahl der erstellten Tickets in den letzten 30 Tagen anzeigt.",
"widget.muiBarChart.name": "Balkendiagramm-Widget",
"widget.muiBarChart.description": "Dies ist ein Widget, das die Anzahl der Tickets nach Priorität anzeigt, die in den letzten 30 Tagen erstellt wurden.",
"widget.ReleaseNotes.name": "Versionshinweise-Widget",
"widget.ReleaseNotes.description": "Dies ist ein Widget, das die neuesten Versionshinweise anzeigt.",
"widget.DailyUpdates.name": "Tägliche Updates-Widget",
"widget.DailyUpdates.description": "Dies ist ein Widget, das die täglichen Updates anzeigt.",
"widget.TicketPieChart.name": "Ticket-Kreisdiagramm-Widget",
"widget.TicketPieChart.description": "Dies ist ein Widget, das die Anzahl der Tickets nach Status anzeigt.",
"widget.barchart.title" : "Tickets nach Priorität",
"widget.linechart.title" : "Tickets, die in den letzten 30 Tagen erstellt wurden",
"widget.piechart.title" : "Tickets nach Status",
"widget.guage.title" : "Gelöste Tickets aus der Warteschlange von heute",

};
export default widgets;
