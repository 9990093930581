import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { Box, Button, Stack, Card } from "@mui/material";
import Typography from "@mui/material/Typography";
import Translation from "utils/translation";
import {
    SidePanelBoldTitle,
    SidePanelItemCard,
    SidePanelParagraph,
} from 'containers/Pages/TicketViewPage/components/styled';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import useWarningNotification from "utils/useWarningNotification";
import { useNavigate } from 'react-router-dom';
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {faBuilding} from "@fortawesome/free-solid-svg-icons";

const AccountManagers = (props) => {
    const { company } = props;
    const navigate = useNavigate();

    const CopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        useWarningNotification('Email Copied to Clipboard');
    }


    const handleNameNavigate = (id) => {
        navigate(`/admin/profile/${id}`);
    }

    const handleOrganizationNavigate = (id) => {
        navigate(`/admin/company/${id}`);
    }

    return(
        <>
        {company?.organization?.[0]?.account_owners?.map((account_manager, index) => (
            <SidePanelItemCard key={index}>
                  <SidePanelBoldTitle>{Translation('companyPage.AccountManager')}</SidePanelBoldTitle>

                <Box sx={{ display: 'flex', marginRight: 5, alignItems: 'center', flexDirection: 'row', gap: 1 }}>
                    <FontAwesomeIcon icon={faUser}  />
                    <Typography  
                        onClick={() => handleNameNavigate(account_manager?.person?.id)}
                        style={{ color: '#3F76B6', cursor: 'pointer' }}
                    >
                        {account_manager?.person?.name}
                    </Typography>
                </Box>
                <Box sx={{ width: '100%' }}>
                  
               
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 5 }} />
                        <Typography 
                            style={{ color: '#6196C7', paddingTop: 1, paddingLeft: 1, cursor: 'pointer' }} 
                             onClick={() => CopyToClipboard(account_manager?.person?.email_address)}
                        >
                            {account_manager?.person?.email_address}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', marginRight: 5, alignItems: 'center', flexDirection: 'row', gap: 1 }}>
                    <FontAwesomeIcon icon={faBuilding}  />
                    <Typography  
                        onClick={() => handleOrganizationNavigate(account_manager?.person?.organization?.id)}
                        style={{ color: '#3F76B6', cursor: 'pointer' }}
                    >
                        {account_manager?.person?.organization?.name}
                    </Typography>
                </Box>
                </Box>
            </SidePanelItemCard>
        ))}
    </>
        
    )
}

export default AccountManagers;