import {
  LOGIN_SSO,
  LOGIN_SSO_SUCCESS,
  LOGIN_SSO_FAIL,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SOCKET_SERVER,
  LOGIN_CODE,
  LOGIN_CODE_SUCCESS,
  LOGIN_CODE_FAIL,
} from "../constants";

export function loginSSO(content) {
  // console.log(content)
  return {
    type: "socket",
    types: [LOGIN_SSO, LOGIN_SSO_SUCCESS, LOGIN_SSO_FAIL],
    promise: (socket) => {
      return socket.emit(LOGIN_SSO, content).then((result) => {
        let resultData = JSON.parse(result);
        return resultData;
      });
    },
  };
}
export function logOutSocketServer(content) {
  return {
    type: "socket",
    types: [LOGOUT_SOCKET_SERVER],
    promise: (socket) => {
      return socket.emit(LOGOUT_SOCKET_SERVER, content).then((result) => {
        let resultData = JSON.parse(result);
        return resultData;
      });
    },
  };
}
export function loginUsername(content) {
  return {
    type: "socket",
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: (socket) => {
      return socket.emit(LOGIN, content).then((result) => {
        let resultData = JSON.parse(result);
        return resultData;
      });
    },
  };
}

export function msssoLoginCode(code) {
  return {
    type: "socket",
    types: [LOGIN_CODE, LOGIN_CODE_SUCCESS, LOGIN_CODE_FAIL],
    promise: (socket) => {
      return socket.emit(LOGIN_CODE, code).then((result) => {
        let resultData = JSON.parse(result);
        return resultData;
      });
    },
  };
}
