import React, { useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import { Box } from "@mui/system";
import {
    Autocomplete,
    Button,
    Chip,
    MenuItem,
    Select,
    TextField,
    Typography,
    Tabs, Tab,
} from "@mui/material";
import Translation from "utils/translation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import organization from 'graphql/organization';
import { useLazyQuery } from '@apollo/client';
import person from 'graphql/person';
import { useMutation, gql } from '@apollo/client';

// adding updated vars to the mutation to dynamically update the fields
const UPDATE_CONTACT_INFO = gql`
mutation UpdatePersonMutation(
  $personID: uuid!,
  $setPersonVars: person_set_input = {}
) {
  update_person_by_pk(
    pk_columns: { id: $personID },
    _set: $setPersonVars
  ) {
    id
    first_name
    last_name
    email_address
  }
}
`;

const GET_DIAL_CODES = gql`
query MyQuery {
  country {
    dialing_prefix
    id
    name
  }
}
`;


const GET_ORGANIZATIONS = gql`
query getOrganizations  {
  organization (where: {active: {_eq: true}}){
    id
    name
    permissions {
      id
    }
  }
}
`;

const ADD_PHONE_NUMBER = gql`
mutation MyMutation($country_id: uuid = "", $number: String = "") {
  insert_phone_number(objects: {account_id: "92cf2939-e08a-4a03-908a-f4d136b6f1e0", country_id: $country_id, number: $number, type_id: "c771cc78-3339-447b-86bf-8e968a676c8a", main: true}) {
    returning {
      id
    }
  }
}
`;

const INSERT_PERSON_PHONE_NUMBER = gql`
mutation MyMutation( $person_id: uuid = "", $phone_number_id: uuid = "") {
  insert_person_phone_number(objects: {person_id: $person_id, phone_number_id: $phone_number_id}){
  affected_rows
  }
}
`;

const UPDATE_PHONE_NUMBER = gql`
mutation MyMutation($_eq: uuid = "", $country_id: uuid = "", $number: String = "") {
  update_phone_number(where: {id: {_eq: $_eq}}, _set: {country_id: $country_id, number: $number}){
  affected_rows
  }
}
`;

function CustomTab({ children, value, index, isPhone, setIsPhone, ...other }) {
    switch (value) {
        case 0:
            setIsPhone(false);
            break;
        case 1:
            setIsPhone(true);
            break;
        default:
            setIsPhone(false);
    }
    return (
        <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>


            <Box
                sx={{
                    display: 'flex',
                }}
            >  {value === index && <Box sx={{ p: 3 }}>{children}</Box>}</Box>
        </div>
    );
}

function validatePhoneNumber(phone) {

    const cleanedPhone = phone.replace(/\D/g, ''); // remoing non digits 
    const isValid = cleanedPhone.length >= 10 && cleanedPhone.length <= 15;  // room for international numbers

    return isValid;
}

const EditContactInfo = (props) => {
    const { Edituser, user, userID, handleClose } = props;
    const [name, setName] = React.useState(user?.person[0].first_name);
    const [lastName, setLastName] = React.useState(user?.person[0].last_name);
    const [email, setEmail] = React.useState(user?.person[0].email_address);
    const [organization_id, setOrganization_id] = React.useState(user?.person[0].organization?.id);
    const [orgName, setOrgName] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [updatePerson] = useMutation(UPDATE_CONTACT_INFO);
    const [getOrganizations, { data: organizationsData }] = useLazyQuery(GET_ORGANIZATIONS);
    const [updatePhoneNumber] = useMutation(UPDATE_PHONE_NUMBER);
    const phoneNumber = user?.person[0]?.phone_numbers?.[0]?.phone_number;
    const [getDialcodes, { data: dialCodes }] = useLazyQuery(GET_DIAL_CODES);
    const [phone, setPhone] = React.useState(phoneNumber?.number);
    const [dialCode, setDialCode] = React.useState(phoneNumber?.country?.dialing_prefix);
    const [tabValue, setTabValue] = React.useState(0);
    const [isPhone, setIsPhone] = React.useState(false);
    const [phoneNumberID, setPhoneNumberID] = React.useState(phoneNumber?.id);
    const [addPhoneNumber] = useMutation(ADD_PHONE_NUMBER);
    const [addPersonPhoneNumber] = useMutation(INSERT_PERSON_PHONE_NUMBER);
    const [currentCountry, setCurrentCountry] = React.useState(dialCodes?.country?.[0]);

    useEffect(() => {
        getOrganizations();
    }, []);

    useEffect(() => {
        getDialcodes();
    }, []);




    useEffect(() => {
    }, [organizationsData, organization_id]);
    const handleClick = () => {
        //   console.log("clicked");

        const updatedFields = {};

        if (name !== user.person[0].first_name) updatedFields.first_name = name;
        if (lastName !== user.person[0].last_name) updatedFields.last_name = lastName;
        if (email !== user.person[0].email_address) updatedFields.email_address = email;
        if (organization_id !== user.person[0].organization?.id) updatedFields.organization_id = organization_id;


        const removeNullValues = (obj) => {
            return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
        };

        if (Object.keys(updatedFields).length > 0) {
            const validFields = removeNullValues(updatedFields);

            updatePerson({
                variables: {
                    personID: userID,
                    setPersonVars: {
                        ...validFields,

                    },
                }

            }).then(response => {
                // console.log('Update successful', response);
                setErrorMessage('');
                handleClose();
            }).catch(error => {
                console.error('Update error', error);
                setErrorMessage('Error updating user');
            });


        }

    };
    let orgNames = [];
    for (let i = 0; i < organizationsData?.organization.length; i++) {
        orgNames.push({
            name: organizationsData.organization[i].name,
            id: organizationsData.organization[i].id
        });
    }
    useEffect(() => {
        //  console.log('orgNames', orgNames);
    }, [orgNames]);

    const handleOrganizationChange = (event, value) => {
        setOrgName(value);
        for (let i = 0; i < orgNames.length; i++) {
            if (orgNames[i].name === value) {
                setOrganization_id(orgNames[i].id);
            }
        }
    };

    const orgNamesOptions = orgNames.map(org => org.name);

    const handleAddPhone = () => {
        if (phone === '' || dialCode === '') {
            setErrorMessage('Please fill in all fields');
            return;
        }
        if (!validatePhoneNumber(phone)) {
            setErrorMessage('Please enter a valid phone number');
            return;
        }

        if (phoneNumberID) {
            updatePhoneNumber({
                variables: {
                    _eq: phoneNumberID,
                    number: phone,
                    country_id: dialCode
                }
            }).then(response => {
                console.log('Phone number added', response);
                setErrorMessage('');
                handleClose();
            }).catch(error => {
                console.error('Phone number error', error);
                setErrorMessage('Error adding phone number');
            });
        } else {
            addPhoneNumber({
                variables: {
                    number: phone,
                    country_id: dialCode
                }
            }).then(response => {
                console.log('Phone number added', response);
                setErrorMessage('');
                const newPhoneNumberID = response.data.insert_phone_number.returning[0].id;
                addNewNumber(newPhoneNumberID);
                // handleClose();
            }).catch(error => {
                console.error('Phone number error', error);
                setErrorMessage('Error adding phone number');
            });
        }
    }

    const addNewNumber = (newPhoneNumberID) => {
        addPersonPhoneNumber({
            variables: {
                person_id: userID,
                phone_number_id: newPhoneNumberID
            }
        }).then(response => {
            // console.log('Person phone number added', response);
            setErrorMessage('');
            handleClose();
        }).catch(error => {
            console.error('Person phone number error', error);
            setErrorMessage('Error adding person phone number');
        });
    }


    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                width: '100%',
            }}>
                <Tabs value={tabValue} aria-label="basic tabs example"
                    onChange={(event, newValue) => {
                        setTabValue(newValue);
                    }}

                >

                    <Tab label="Contact Info" />
                    <Tab label="Phone Numbers" />
                </Tabs>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                </Box>
                <CustomTab value={tabValue} index={0} isPhone={isPhone} setIsPhone={setIsPhone}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                        }}>
                            <TextField
                                sx={{
                                    width: '50%',
                                    // marginRight: '20px',
                                }}
                                label="First Name"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                sx={{
                                    width: '50%',
                                    // marginRight: '20px',
                                }}
                                label="Last Name"
                                variant="outlined"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                        }}>

                            <TextField
                                sx={{
                                    width: '100%',
                                }}
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                        </Box>

                        <Box>
                            <Autocomplete
                                id="tags-standard"
                                options={orgNamesOptions || []}
                                getOptionLabel={(option) => option}
                                defaultValue={[]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label={user?.person[0].organization?.name}
                                    />
                                )}
                                onChange={handleOrganizationChange}
                            />
                        </Box>
                    </Box>
                </CustomTab>

                <CustomTab value={tabValue} index={1} isPhone={isPhone} setIsPhone={setIsPhone} >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                    }}>
                        <Typography variant="subtitle1">
                            *Use the country names to select the dialing prefix
                        </Typography>
                        <Autocomplete
                            id="tags-standard"
                            options={dialCodes?.country || []}
                            getOptionLabel={(option) => option.name}
                            defaultValue={dialCodes?.country?.[0]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={currentCountry?.dialing_prefix}
                                />
                            )}
                            onChange={(event, value) => {
                                // setDialCode(value.id);
                                setCurrentCountry(value);
                                setDialCode(value.id);
                            }}
                            sx={{
                                width: '100%',
                            }}
                        />
                        <TextField
                            sx={{
                                width: '100%',
                            }}
                            label="Phone Number"
                            variant="outlined"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Box>
                </CustomTab>



            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>

                <Button
                    variant="contained"
                    color="error"
                    sx={{
                        width: '30%',
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                {isPhone ? (
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            width: '30%',
                        }}
                        onClick={handleAddPhone}
                    >
                        Add
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            width: '30%',
                        }}
                        onClick={handleClick}
                    >
                        Save
                    </Button>
                )
                }


            </Box>
        </>
    )

}

export default EditContactInfo;
