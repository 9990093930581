const components = {
  "components.search": "Szukaj",

  // Nowe tłumaczenia
  "components.leftSideBarMenuList.TitleA": "SIX STAR",
  "components.leftSideBarMenuList.TitleB": "ZARZĄDZAJ",
  
  // Weryfikacja hasła
  "components.passwordVerifyComponent.mustContain": "Hasło musi zawierać następujące elementy: ",
  "components.passwordVerifyComponent.upperCase": "Wielką literę",
  "components.passwordVerifyComponent.lowerCase": "Małą literę",
  "components.passwordVerifyComponent.number": "Cyfrę",
  "components.passwordVerifyComponent.min": "Minimum 8 znaków",
  "components.passwordVerifyComponent.special": "Znak specjalny",
  "components.passwordVerifyComponent.match": "Hasła muszą być identyczne",
  "components.passwordVerifyComponent.enterNew": "Wprowadź nowe hasło",
  "components.passwordVerifyComponent.confirm": "Potwierdź nowe hasło",
  "components.passwordVerifyComponent.random": "Twoje losowe hasło to:",
  "components.passwordVerifyComponent.copiedToClipboard": "Skopiowane do schowka",
  "components.passwordVerifyComponent.makeNote": "Proszę zapamiętać to.",
  
  // Resetowanie hasła
  "components.passwordReset.error": "Przepraszamy, ale obecnie nie możemy zresetować Twojego hasła.",
  "components.passwordReset.submitRequest": "Prosimy o przesłanie zgłoszenia wsparcia, abyśmy mogli pomóc Ci zresetować hasło.",
  "components.passwordReset.thankYou": "Dziękujemy za zrozumienie.",
  "components.passwprdReset.success.pleaseWait": "Proszę czekać, trwa resetowanie hasła",

};
export default components;
