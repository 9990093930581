import React, { useState, useEffect, useRef } from "react";
import { useScrollIntoView } from "@mantine/hooks";
import withStyles from "@mui/styles/withStyles";
import layoutStyles from "containers/Layout/styles";
import PageBase from "components/PageBase";
import { Box } from "@mui/system";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import useTranslation from "containers/App/Hooks/useTranslation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles";
import { useTheme } from "@mui/system";
import VIPHeader from "images/VIP/VIPHeader.png";
import VIPPlus from "images/VIP/VIPPlus.png";
import { Button } from "@mui/material";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";

const SixStarVIPPage = ({ classes }) => {
    const theme = useTheme();
    const { translate } = useTranslation();
    const [loading, setLoading] = useState(true);
    const isPhoneSize = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        let loadingTimer = setTimeout(() => setLoading(false), 300);
        return () => {
            clearTimeout(loadingTimer);
        };
    });

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <>
            <PageBase minHeight={500} loading={loading} noPadding={true}>
                <Box sx={{ background: '#833477' }}>
                    <img

                        src={VIPHeader}
                        alt={"Six Star Voice"}
                        style={{
                            width: "67%",
                            margin: "auto",
                            display: "block",
                        }}
                    />

                    <Box sx={{ background: "white!important", height: 'auto', }}>
                        <Typography
                            variant={"h3"}
                            align={"center"}
                            sx={{
                                fontWeight: 500,
                                display: "block",
                                paddingTop: theme.spacing(6)

                            }}
                        >
                            {translate("sixStarVIPPage.header")}
                        </Typography>
                        <Box
                            align={"center"}
                            sx={{
                                paddingTop: theme.spacing(4),
                                display: "block",
                                paddingX: 4
                            }}>
                            <Typography>{translate("sixStarVIPPage.subHeader")}</Typography>
                        </Box>

                        <Box  spacing={5} style={{ alignItems: 'center',justifyContent: 'center' ,paddingTop: theme.spacing(4), height: 'auto', display: 'flex', flexDirection: isPhoneSize ? "column" : "row"  }}>
                            <Box sx={{padding: 2, width: isPhoneSize ? 'auto': '30%'}}>

                                <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "user-headset"]}
                                    color="white"
                                    style={{
                                        fontSize: "5rem",
                                        backgroundColor: "#182C48",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" fontWeight={500}>{translate("sixStarVIPPage.directToSeniorSupport")}</Typography>
                                <Typography align="center" style={{ whiteSpace: "normal" }}>{translate("sixStarVIPPage.whenYouCall")}</Typography>
                            </Box>
                            <Box sx={{padding: 2, width: isPhoneSize ? 'auto': '30%'}}>
                                <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "id-card"]}
                                    color="white"
                                    style={{
                                        fontSize: "5rem",
                                        backgroundColor: "#3F76B6",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" fontWeight={500}>{translate("sixStarVIPPage.contactCards")}</Typography>
                                <Typography align="center" style={{ whiteSpace: "normal" }}>{translate("sixStarVIPPage.onTheGo")}</Typography>
                            </Box>

                            <Box sx={{padding: 2, width: isPhoneSize ? 'auto': '30%'}}>
                                <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "arrow-down"]}
                                    color="white"
                                    style={{
                                        fontSize: "7rem",
                                        backgroundColor: "#2A203B",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "5rem",
                                        height: "5rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" fontWeight={500}>{translate("sixStarVIPPage.reducedSLA")}</Typography>

                                <Typography align="center" style={{ whiteSpace: "normal" }}>{translate("sixStarVIPPage.bigOrSmall")}</Typography>
                            </Box>



                        </Box>

                        <Box
                            sx={{ background: "#833477", marginTop: theme.spacing(4), height: 'auto', paddingBottom: 8 }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ marginTop: 8 }}>
                                        {/* <img

                                            src={MeetTwo}
                                            alt={"Six Star TV"}
                                            style={{
                                                width: "90%",
                                                margin: "auto",
                                                display: "block",
                                            }}
                                        /> */}
                                    </Box>
                                </Grid>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ width: '100%', align: 'center', paddingX: 8 }}>
                                        <Typography align={"center"} variant="h4" sx={{ color: "white!important", marginTop: 16, fontWeight: 500, paddingBottom: 6  }}>{translate("sixStarVIPPage.contactCardsForGM")}</Typography>
                                        <Typography align={"center"} variant="h6" sx={{ color: "white!important" }}>L, consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit </Typography>
                                    </Box>
                                </Grid>
                            </Grid>



                        </Box>

                        

                    </Box>

                    <Box sx={{ background: "#8777E4", height: 'auto', paddingBottom: 8 }}>
                        <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Typography align={"center"} variant="h4" sx={{ color: "white!important", fontWeight: 500, paddingTop: theme.spacing(6) }}>{translate("sixStarTVPage.extraFeatures")}</Typography>
                            <Typography align={"center"} sx={{ color: "white!important", }}>{translate("sixStarVIPPage.withPlus")}</Typography>
                        </Box>
                        <Box>



                            <Grid container spacing={3} sx={{ paddingY: theme.spacing(4), display: 'flex', flexDirection: isPhoneSize ? 'column' : 'row'  }}>
                                <Grid item xs={isPhoneSize ? 12 : 3}>
                                    <Box sx={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: 3 }}>
                                        <img

                                            src={VIPPlus}
                                            alt={"Six Star TV Plus"}
                                            style={{
                                                width: "67%",
                                                margin: "auto",
                                                display: "block",
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={isPhoneSize ? 12 : 6}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {/* room service icon */}
                                         <Box >
                                         <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "user-headset"]}
                                    color="white"
                                    style={{
                                        fontSize: "7rem",
                                        backgroundColor: "#3F76B6",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "2rem",
                                        height: "2rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" style={{color:'white'}}>{translate("sixStarVIPPage.instant")}</Typography>
                                        </Box>   

                                            {/* wake up icon */}
                                        <Box>
                                        <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "mobile"]}
                                    color="white"
                                    style={{
                                        fontSize: "7rem",
                                        backgroundColor: "#3F76B6",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "2rem",
                                        height: "2rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" style={{color:'white'}}>{translate("sixStarVIPPage.dedicated")}</Typography>
                                        </Box>

                                        {/* international viewing */}
                                        <Box>
                                        <FontAwesomeIcon
                                    size="2x"
                                    icon={["fas", "earth"]}
                                    color="white"
                                    style={{
                                        fontSize: "7rem",
                                        backgroundColor: "#3F76B6",
                                        borderRadius: "100%",
                                        padding: "2rem",
                                        width: "2rem",
                                        height: "2rem",
                                        margin: "auto",
                                        marginBottom: theme.spacing(1),
                                        display: "block",
                                    }}
                                />

                                <Typography align="center" style={{color:'white'}}>{translate("sixStarVIPPage.priority")}</Typography>

                                        </Box>

                                    </Box>
                                </Grid>
                                <Grid item xs={isPhoneSize ? 12 : 3}>
                                    <Box sx={{ alignItems: isPhoneSize ? 'center' : 'flex-end', justifyContent: 'center', paddingRight: theme.spacing(2)}}>
                                        <Typography align={"center"} sx={{ color: "white!important", }}>{translate("sixStarVIPPage.plus" )}</Typography>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>

                        <Box sx={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>

                            <Button align="center" variant="contained" style={{backgroundColor: 'white', width: '20%',mx: 'auto'}}>
                                <Typography align={"center"} sx={{color: 'black'}}>{translate("sixStarPlus.getInTouch")}</Typography>
                            </Button>

                        
                        </Box>
                    </Box>

                </Box>


            </PageBase>

        </>
    );

}

export default SixStarVIPPage;