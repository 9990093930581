import withStyles from "@mui/styles/withStyles";
import React, {useState} from "react";
import styles from "./styles";
import Typography from "@mui/material/Typography";
import Translation from "utils/translation";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";

const card = {
}

function HelpOptions(props) {

    const { classes, onClose } = props;

    return (
        <Box  className={classes.helpOptionsContainer}
        
        sx={{ position: 'fixed', width: 500, alignItems: 'center', justifyContent: 'center', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}
        >
       <Card
         > 
       <CardContent>
        <Typography>{Translation("HelpOptions.Title")}</Typography>
        <Button>Give Feedback</Button>
        <Button>Report a Bug</Button>

       </CardContent>
       
       
       </Card>
       </Box>
    );

}

export default withStyles(
    (theme) => ({
      ...styles(theme),
    }),
    {
      withTheme: true,
    }
  )(HelpOptions);