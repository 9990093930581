const widgets = {
  "widget.barChart.name": "Bar Chart Widget",
  "widget.barChart.description":
    "Bar Chart Widget that displays the number of open priority tickets.",
  "widget.organizationTickets.name": "Organization Tickets Widget",
  "widget.organizationTickets.description":
    "This is a widget that displays the number of open priority 1s.",
  "widget.outlookInbox.name": "Outlook Inbox Widget",
  "widget.outlookInbox.description":
    "This is a widget that displays the number of emails in your inbox.",
  "widget.personTickets.name": "Personal Tickets Widget",
  "widget.personTickets.description":
    "This is a widget that displays the number of open priority 1s.",
  "widget.pieChart.name": "Pie Chart Widget",
  "widget.pieChart.description":
    "Pie Chart Widget that displays the number of open tickets a contact has.",
  "widget.quickLinks.name": "Quick Links Widget",
  "widget.quickLinks.description":
    "This is a widget that displays a list of links to other pages on the site.",
  "widget.social.name": "Social Feed Widget",
  "widget.social.description":
    "This is a social feed widget connected to Six Star social media accounts.",
  "widget.ticketTable.name": "Ticket Table Widget",
  "widget.ticketTable.description":
    "Widget that displays a table of the tickets.",
  "widget.social.feedType": "Facebook / Twitter",
  "widget.quickLinks.newRequest": "New Request",
  "widget.quickLinks.licenceManagement": "Licence Management",
  "widget.quickLinks.assetManagement": "Asset Management",
  "widget.quickLinks.sixStarDrive": "Six Star Drive",
  "widget.quickLinks.sixStarGlobal": "Six Star Global",

  // new translations 
  "widget.quickLinks.button.newRequest": "New Request",
  "widget.quickLinks.button.licenceManagement": "Licence Management",
  "widget.quickLinks.button.assetManagement": "Asset Management",
  "widget.quickLinks.button.sixStarDrive": "Six Star Drive",

  "widgets.org.tickets.p1": "Org P1 Tickets",
  "widgets.org.tickets.p2": "Org P2 Tickets",
  "widgets.org.tickets.open": "Org Open Tickets",

  "widgets.personal.tickets.p1": "Personal P1s",
  "widgets.personal.tickets.p2": "Personal P2s",
  "widgets.personal.tickets.open": "Personal Open",
  "widget.Guage.name": "Resolved Guage Widget",
  "widget.Guage.description": "This is a widget that displays the number of resolved tickets from today's queue.",
  "widget.LineGraph.name": "Line Graph Widget",
  "widget.LineGraph.description": "This is a widget that displays the number of created tickets in the last 30 days.",
  "widget.muiBarChart.name": "Bar Chart Widget",
  "widget.muiBarChart.description": "This is a widget that displays the number tickets, by priority, created in the last 30 days.",
  "widget.ReleaseNotes.name": "Release Notes Widget",
  "widget.ReleaseNotes.description": "This is a widget that displays the latest release notes.",
  "widget.DailyUpdates.name": "Daily Updates Widget",
  "widget.DailyUpdates.description": "This is a widget that displays the daily updates.",
  "widget.TicketPieChart.name": "Ticket Pie Chart Widget",
  "widget.TicketPieChart.description": "This is a widget that displays the number of tickets by status.",
  "widget.barchart.title" : "Tickets By Priority",
  "widget.linechart.title" : "Tickets Created In The Last 30 Days",
  "widget.piechart.title" : "Tickets By Status",
  "widget.guage.title" : "Resolved Tickets From Today's Queue",
  
};
export default widgets;
