const menu = {
  "menu.manage.dashboard.tabText": "Panel",
  "menu.manage.dashboard.menuText": "Panel",
  "menu.manage.supportSection.text": "Wsparcie",
  "menu.manage.supportSection.menuText": "Wsparcie",
  "menu.manage.supportSection.dashboard.tabText": "Panel",
  "menu.manage.supportSection.dashboard.menuText": "Panel",
  "menu.manage.supportSection.newRequest.tabText": "Nowe Zapytanie",
  "menu.manage.supportSection.newRequest.menuText": "Nowe Zapytanie",
  "menu.manage.supportSection.myTicketsOverview.tabText": "Przegląd Moich Biletów",
  "menu.manage.supportSection.myTicketsOverview.menuText": "Przegląd Moich Biletów",
  "menu.manage.supportSection.approvals.tabText": "Zatwierdzenia",
  "menu.manage.supportSection.approvals.menuText": "Zatwierdzenia",
  "menu.manage.sixstarMeet.tabText": "Spotkanie Six Star",
  "menu.manage.sixstarMeet.menuText": "Spotkanie Six Star",
  "menu.manage.manageSixStarConnect.tabText": "Six Star Connect",
  "menu.manage.manageSixStarConnect.menuText": "Six Star Connect",
  "menu.manage.manageManageSection.text": "Zarządzaj",
  "menu.manage.manageManageSection.menuText": "Zarządzaj",
  "menu.manage.manageManageSection.dashboard.tabText": "Panel",
  "menu.manage.manageManageSection.dashboard.menuText": "Panel",
  "menu.manage.manageSection.text": "Zarządzaj",
  "menu.manage.manageSection.menuText": "Zarządzaj",
  "menu.manage.manageSection.documents.tabText": "Dokumenty",
  "menu.manage.manageSection.documents.menuText": "Dokumenty",
  "menu.manage.manageSection.securityReports.tabText": "Raporty Bezpieczeństwa",
  "menu.manage.manageSection.securityReports.menuText": "Raporty Bezpieczeństwa",
  "menu.manage.manageSection.serviceReports.tabText": "Raporty z Usług",
  "menu.manage.manageSection.serviceReports.menuText": "Raporty z Usług",
  "menu.manage.manageSection.licenceManagement.tabText": "Zarządzanie Licencjami",
  "menu.manage.manageSection.licenceManagement.menuText": "Zarządzanie Licencjami",
  "menu.manage.manageSection.assetManagement.tabText": "Zarządzanie Aktywami",
  "menu.manage.manageSection.assetManagement.menuText": "Zarządzanie Aktywami",
  "menu.manage.manageSection.upcomingExpiries.tabText": "Nadchodzące Wygaśnięcia",
  "menu.manage.manageSection.upcomingExpiries.menuText": "Nadchodzące Wygaśnięcia",
  "menu.manage.manageSection.bookACallWithYourAccountManager.tabText": "Umów się na Rozmowę z Twoim Kierownikiem Konta",
  "menu.manage.manageSection.bookACallWithYourAccountManager.menuText": "Umów się na Rozmowę z Twoim Kierownikiem Konta",
  "menu.manage.financeSection.text": "Finanse",
  "menu.manage.financeSection.menuText": "Finanse",
  "menu.manage.financeSection.dashboard.tabText": "Panel",
  "menu.manage.financeSection.dashboard.menuText": "Panel",
  "menu.manage.financeSection.ordersQuotesInvoices.tabText": "Zamówienia/Oferty/Faktury",
  "menu.manage.financeSection.ordersQuotesInvoices.menuText": "Zamówienia/Oferty/Faktury",
  "menu.manage.financeSection.creditApplication.tabText": "Wniosek Kredytowy",
  "menu.manage.financeSection.creditApplication.menuText": "Wniosek Kredytowy",
  "menu.manage.helpSection.text": "Pomoc",
  "menu.manage.helpSection.menuText": "Pomoc",
  "menu.manage.helpSection.contactUs.tabText": "Skontaktuj się z Nami",
  "menu.manage.helpSection.contactUs.menuText": "Skontaktuj się z Nami",
  "menu.manage.helpSection.giveFeedback.tabText": "Podziel się Opinią",
  "menu.manage.helpSection.giveFeedback.menuText": "Podziel się Opinią",
  "menu.manage.helpSection.guides.tabText": "Poradniki",
  "menu.manage.helpSection.guides.menuText": "Poradniki",
  "menu.manage.helpSection.customerForms.tabText": "Formularze dla Klientów",
  "menu.manage.helpSection.customerForms.menuText": "Formularze dla Klientów",
  "menu.manage.engineerSection.text": "Inżynier",
  "menu.manage.engineerSection.menuText": "Inżynier",
  "menu.manage.engineerSection.myTasks.tabText": "Moje Zadania",
  "menu.manage.engineerSection.myTasks.menuText": "Moje Zadania",
  "menu.manage.engineerSection.ticketBoard.tabText": "Bilety",
  "menu.manage.engineerSection.ticketBoard.menuText": "Bilety",
  "menu.manage.engineerSection.triageTickets.tabText": "Bilety do Triage",
  "menu.manage.engineerSection.triageTickets.menuText": "Bilety do Triage",
  "menu.manage.engineerSection.priorityTickets.tabText": "Priorytetowe Bilety",
  "menu.manage.engineerSection.priorityTickets.menuText": "Priorytetowe Bilety",
  "menu.manage.engineerSection.escalations.tabText": "Eskalacje",
  "menu.manage.engineerSection.escalations.menuText": "Eskalacje",
  "menu.manage.internalSection.text": "Wewnętrzne",
  "menu.manage.internalSection.menuText": "Wewnętrzne",
  "menu.manage.internalSection.orderTracker.tabText": "Śledzenie Zamówień",
  "menu.manage.internalSection.orderTracker.menuText": "Śledzenie Zamówień",
  "menu.manage.internalSection.resourceTracker.tabText": "Śledzenie Zasobów",
  "menu.manage.internalSection.resourceTracker.menuText": "Śledzenie Zasobów",
  "menu.manage.internalSection.knowledgeBase.tabText": "Baza Wiedzy",
  "menu.manage.internalSection.knowledgeBase.menuText": "Baza Wiedzy",
  "menu.manage.internalSection.twoFactor.tabText": "Dwuetapowe uwierzytelnienie",
  "menu.manage.internalSection.twoFactor.menuText": "Dwuetapowe uwierzytelnienie",
  "menu.manage.internalSection.internal.tabText": "Wewnętrzne",
  "menu.manage.internalSection.internal.menuText": "Wewnętrzne",
  "menu.manage.internalSection.expenses.tabText": "Wydatki",
  "menu.manage.internalSection.expenses.menuText": "Wydatki",
  "menu.manage.userSection.text": "Użytkownik",
  "menu.manage.userSection.menuText": "Użytkownik",
  "menu.manage.userSection.createContact.tabText": "Utwórz Kontakt",
  "menu.manage.userSection.createContact.menuText": "Utwórz Kontakt",
  "menu.manage.userSection.contactSearch.tabText": "Wyszukaj Kontakt",
  "menu.manage.userSection.contactSearch.menuText": "Wyszukaj Kontakt",
  "menu.manage.userSection.zoomPresence.tabText": "Obecność na Zoom",
  "menu.manage.userSection.zoomPresence.menuText": "Obecność na Zoom",
  "menu.manage.userSection.zoomCallQueues.tabText": "Kolejki połączeń Zoom",
  "menu.manage.userSection.zoomCallQueues.menuText": "Kolejki połączeń Zoom",
  "menu.manage.userSection.mergeContacts.tabText": "Połącz Kontakty",
  "menu.manage.userSection.mergeContacts.menuText": "Połącz Kontakty",
  "menu.manage.adminSection.text": "Admin",
  "menu.manage.adminSection.menuText": "Admin",
  "menu.manage.adminSection.dashboard.tabText": "Pulpit",
  "menu.manage.adminSection.dashboard.menuText": "Pulpit",
  "menu.manage.adminSection.impersonate.tabText": "Personifikacja",
  "menu.manage.adminSection.impersonate.menuText": "Personifikacja",
  "menu.manage.supportSection.newRequestDashboard.tabText": "Nowe Zgłoszenie",
  "menu.manage.supportSection.newRequestDashboard.menuText": "Nowe Zgłoszenie",
  "menu.manage.supportSection.newRequestForm.tabText.supportRequest": "Wsparcie Techniczne",
  "menu.manage.supportSection.newRequestForm.tabText.serviceRequest": "Zgłoszenie Serwisowe",
  "menu.manage.supportSection.newRequestForm.tabText.procurementRequest": "Zapotrzebowanie",
  "menu.manage.supportSection.newRequestForm.tabText.internalRequest": "Wewnętrzne Zgłoszenie",
  "menu.manage.supportSection.newRequestForm.tabText.passwordReset": "Resetowanie Hasła",
  "menu.manage.supportSection.newRequestForm.tabText.newStarter": "Nowy Start / Zmiana Roli",
  "menu.manage.supportSection.newRequestForm.tabText.leaverRequest": "Zgłoszenie Wypowiedzenia",
  "menu.manage.supportSection.newRequestForm.tabText.engineerVisit": "Wizyta Inżyniera",
  "menu.manage.supportSection.newRequestForm.tabText.developmentRequest": "Zgłoszenie Rozwojowe",
  "menu.manage.engineerSection.ticketViewPage.tabText": "Zgłoszenie# {id}",
  "menu.manage.engineerSection.ticketViewPage.menuText": "Zgłoszenie {id}",
  "menu.manage.engineerSection.ticketsBoard.tabText": "Zgłoszenia",
  "menu.manage.engineerSection.ticketsBoard.menuText": "Zgłoszenia",
  "menu.manage.supportSection.newRequestForm.tabText": "{id}",
  "menu.manage.supportSection.newRequestForm.menuText": "{id}",
  "menu.manage.engineerSection.drive.tabText": "Six Star Drive",
  "menu.manage.engineerSection.drive.menuText": "Six Star Drive",
  "menu.manage.engineerSection.reports.tabText": "Raporty",
  "menu.manage.engineerSection.reports.menuText": "Raporty",
  "menu.manage.engineerSection.companyProfile.tabText": "Moja Organizacja",
  "menu.manage.engineerSection.companyProfile.menuText": "Moja Organizacja",
  "menu.manage.engineerSection.chatGPT.tabText": "Chat GPT",
  "menu.manage.engineerSection.chatGPT.menuText": "Chat GPT",
  "menu.manage.financeSection.Invoices.tabText": "Faktury",
  "menu.manage.financeSection.Invoices.menuText": "Faktury",
  "menu.manage.financeSection.quotesProposals.tabText": "Oferty",
  "menu.manage.financeSection.quotesProposals.menuText": "Oferty",
  "menu.manage.financeSection.creditTerms.tabText": "Warunki Kredytowe",
  "menu.manage.financeSection.creditTerms.menuText": "Warunki Kredytowe",
  "menu.manage.internalSection.impersonate.tabText": "Personifikacja",
  "menu.manage.internalSection.impersonate.menuText": "Personifikacja",
  "menu.manage.managedServicesSection.text": "Usługi Zarządzane",
  "menu.manage.managedServicesSection.menuText": "Usługi Zarządzane",
  "menu.manage.managedServicesSection.dashboard.tabText": "Pulpit",
  "menu.manage.managedServicesSection.dashboard.menuText": "Pulpit",
  "menu.manage.managedServicesSection.serviceOverview.tabText": "Przegląd usług",
  "menu.manage.managedServicesSection.serviceOverview.menuText": "Przegląd usług",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.tabText": "Zabezpieczenia końcówek",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.menuText": "Zabezpieczenia końcówek",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.tabText": "Kopia zapasowa",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.menuText": "Kopia zapasowa",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.tabText": "Bezpieczne formularze",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.menuText": "Bezpieczne formularze",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.tabText": "Zaplanowane wizyty na miejscu",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.menuText": "Zaplanowane wizyty na miejscu",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.tabText": "Usuwanie elektrośmieci",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.menuText": "Usuwanie elektrośmieci",
  "menu.manage.sixStarSecureSection.text": "Six Star Secure",
  "menu.manage.sixStarSecureSection.menuText": "Six Star Secure",
  "menu.manage.sixStarSecureSection.dashboard.tabText": "Pulpit nawigacyjny",
  "menu.manage.sixStarSecureSection.dashboard.menuText": "Pulpit nawigacyjny",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.tabText": "Ochrona końcówek (Antywirus i Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.menuText": "Ochrona końcówek (Antywirus i Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.tabText": "Wykrywanie i reagowanie na końcówkach",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.menuText": "Wykrywanie i reagowanie na końcówkach",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.tabText": "Zarządzanie zaporą sieciową",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.menuText": "Zarządzanie zaporą sieciową",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.tabText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.menuText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.tabText": "Szyfrowanie urządzeń",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.menuText": "Szyfrowanie urządzeń",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.tabText": "Zarządzanie Office 365/Azure",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.menuText": "Zarządzanie Office 365/Azure",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.tabText": "Kopia zapasowa i odzyskiwanie po awariach",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.menuText": "Kopia zapasowa i odzyskiwanie po awariach",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.tabText": "Raporty zabezpieczeń",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.menuText": "Raporty zabezpieczeń",
  "menu.manage.softwareLicenceManagementSection.text": "Zarządzanie licencjami oprogramowania",
  "menu.manage.softwareLicenceManagementSection.menuText": "Zarządzanie licencjami oprogramowania",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.tabText": "Katalog autoryzowanego oprogramowania",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.menuText": "Katalog autoryzowanego oprogramowania",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.tabText": "Zarządzanie licencjami",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.menuText": "Zarządzanie licencjami",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.tabText": "Zarządzanie oprogramowaniem",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.menuText": "Zarządzanie oprogramowaniem",
  "menu.manage.assetManagementSection.text": "Zarządzanie aktywami",
  "menu.manage.assetManagementSection.menuText": "Zarządzanie aktywami",
  "menu.manage.assetManagementSection.assetManagementDashboard.tabText": "Panel sterowania",
  "menu.manage.assetManagementSection.assetManagementDashboard.menuText": "Panel sterowania",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.tabText": "Katalog autoryzowanego sprzętu",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.menuText": "Katalog autoryzowanego sprzętu",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.tabText": "Zarządzanie urządzeniami",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.menuText": "Zarządzanie urządzeniami",
  "menu.manage.sixStarAdditionsSection.text": "Dodatki Six Star",
  "menu.manage.sixStarAdditionsSection.menuText": "Dodatki Six Star",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.tabText": "Wsparcie dla urządzeń gości",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.menuText": "Wsparcie dla urządzeń gości",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.tabText": "Six Star: Głos",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.menuText": "Six Star: Głos",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.tabText": "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.menuText": "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.tabText": "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.menuText": "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.tabText": "Six Star: Meet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.menuText": "Six Star: Meet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.tabText": "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.menuText": "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.tabText": "Six Star: Cast",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.menuText": "Six Star: Cast",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.tabText": "Six Star: Pay",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.menuText": "Six Star: Pay",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.tabText": "Six Star: Display",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.menuText": "Six Star: Display",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.tabText": "Six Star: Park",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.menuText": "Six Star: Park",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.tabText": "Six Star: Black",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.menuText": "Six Star: Black",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.tabText": "Six Star: Druk",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.menuText": "Six Star: Druk",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.tabText": "Six Star: Internet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.menuText": "Six Star: Internet",
  "menu.manage.userSection.editContact.tabText": "Edytuj kontakt",
"menu.manage.userSection.editContact.menuText": "Edytuj kontakt",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.tabText": "Wypłaty",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.menuText": "Wypłaty",

};
export default menu;
