const menu = {
  "menu.manage.dashboard.tabText": "Cruscotto",
  "menu.manage.dashboard.menuText": "Cruscotto",
  "menu.manage.supportSection.text": "Supporto",
  "menu.manage.supportSection.menuText": "Supporto",
  "menu.manage.supportSection.dashboard.tabText": "Cruscotto",
  "menu.manage.supportSection.dashboard.menuText": "Cruscotto",
  "menu.manage.supportSection.newRequest.tabText": "Nuova Richiesta",
  "menu.manage.supportSection.newRequest.menuText": "Nuova Richiesta",
  "menu.manage.supportSection.myTicketsOverview.tabText":
    "Panoramica dei Miei Ticket",
  "menu.manage.supportSection.myTicketsOverview.menuText":
    "Panoramica dei Miei Ticket",
  "menu.manage.supportSection.approvals.tabText": "Approvazioni",
  "menu.manage.supportSection.approvals.menuText": "Approvazioni",
  "menu.manage.sixstarMeet.tabText": "Six Star Incontro",
  "menu.manage.sixstarMeet.menuText": "Six Star Incontro",
  "menu.manage.manageSixStarConnect.tabText": "Six Star Connect",
  "menu.manage.manageSixStarConnect.menuText": "Six Star Connect",
  "menu.manage.manageManageSection.text": "Gestisci",
  "menu.manage.manageManageSection.menuText": "Gestisci",
  "menu.manage.manageManageSection.dashboard.tabText": "Cruscotto",
  "menu.manage.manageManageSection.dashboard.menuText": "Cruscotto",
  "menu.manage.manageSection.text": "Gestisci",
  "menu.manage.manageSection.menuText": "Gestisci",
  "menu.manage.manageSection.documents.tabText": "Documenti",
  "menu.manage.manageSection.documents.menuText": "Documenti",
  "menu.manage.manageSection.securityReports.tabText": "Rapporti di Sicurezza",
  "menu.manage.manageSection.securityReports.menuText": "Rapporti di Sicurezza",
  "menu.manage.manageSection.serviceReports.tabText": "Rapporti di Servizio",
  "menu.manage.manageSection.serviceReports.menuText": "Rapporti di Servizio",
  "menu.manage.manageSection.licenceManagement.tabText":
    "Gestione delle Licenze",
  "menu.manage.manageSection.licenceManagement.menuText":
    "Gestione delle Licenze",
  "menu.manage.manageSection.assetManagement.tabText": "Gestione degli Asset",
  "menu.manage.manageSection.assetManagement.menuText": "Gestione degli Asset",
  "menu.manage.manageSection.upcomingExpiries.tabText": "Scadenze Imminenti",
  "menu.manage.manageSection.upcomingExpiries.menuText": "Scadenze Imminenti",
  "menu.manage.manageSection.bookACallWithYourAccountManager.tabText":
    "Prenota una Chiamata con il Tuo Responsabile Account",
  "menu.manage.manageSection.bookACallWithYourAccountManager.menuText":
    "Prenota una Chiamata con il Tuo Responsabile Account",
  "menu.manage.financeSection.text": "Finanza",
  "menu.manage.financeSection.menuText": "Finanza",
  "menu.manage.financeSection.dashboard.tabText": "Cruscotto",
  "menu.manage.financeSection.dashboard.menuText": "Cruscotto",
  "menu.manage.financeSection.ordersQuotesInvoices.tabText":
    "Ordini/Preventivi/Fatture",
  "menu.manage.financeSection.ordersQuotesInvoices.menuText":
    "Ordini/Preventivi/Fatture",
  "menu.manage.financeSection.creditApplication.tabText":
    "Richiesta di Credito",
  "menu.manage.financeSection.creditApplication.menuText":
    "Richiesta di Credito",
  "menu.manage.helpSection.text": "Aiuto",
  "menu.manage.helpSection.menuText": "Aiuto",
  "menu.manage.helpSection.contactUs.tabText": "Contattaci",
  "menu.manage.helpSection.contactUs.menuText": "Contattaci",
  "menu.manage.helpSection.giveFeedback.tabText": "Lascia un Feedback",
  "menu.manage.helpSection.giveFeedback.menuText": "Lascia un Feedback",
  "menu.manage.helpSection.guides.tabText": "Guide",
  "menu.manage.helpSection.guides.menuText": "Guide",
  "menu.manage.helpSection.customerForms.tabText": "Moduli per Clienti",
  "menu.manage.helpSection.customerForms.menuText": "Moduli per Clienti",
  "menu.manage.engineerSection.text": "Ingegnere",
  "menu.manage.engineerSection.menuText": "Ingegnere",
  "menu.manage.engineerSection.myTasks.tabText": "I Miei Compiti",
  "menu.manage.engineerSection.myTasks.menuText": "I Miei Compiti",
  "menu.manage.engineerSection.ticketBoard.tabText": "Ticket",
  "menu.manage.engineerSection.ticketBoard.menuText": "Ticket",
  "menu.manage.engineerSection.triageTickets.tabText": "Ticket di Triage",
  "menu.manage.engineerSection.triageTickets.menuText": "Ticket di Triage",
  "menu.manage.engineerSection.priorityTickets.tabText": "Ticket Prioritari",
  "menu.manage.engineerSection.priorityTickets.menuText": "Ticket Prioritari",
  "menu.manage.engineerSection.escalations.tabText": "Escalation",
  "menu.manage.engineerSection.escalations.menuText": "Escalation",
  "menu.manage.internalSection.text": "Interno",
  "menu.manage.internalSection.menuText": "Interno",
  "menu.manage.internalSection.orderTracker.tabText": "Monitoraggio Ordini",
  "menu.manage.internalSection.orderTracker.menuText": "Monitoraggio Ordini",
  "menu.manage.internalSection.resourceTracker.tabText": "Monitoraggio Risorse",
  "menu.manage.internalSection.resourceTracker.menuText":
    "Monitoraggio Risorse",
  "menu.manage.internalSection.knowledgeBase.tabText": "Base di Conoscenza",
  "menu.manage.internalSection.knowledgeBase.menuText": "Base di Conoscenza",
  "menu.manage.internalSection.twoFactor.tabText":
    "Autenticazione a Due Fattori",
  "menu.manage.internalSection.twoFactor.menuText":
    "Autenticazione a Due Fattori",
  "menu.manage.internalSection.internal.tabText": "Interno",
  "menu.manage.internalSection.internal.menuText": "Interno",
  "menu.manage.internalSection.expenses.tabText": "Spese",
  "menu.manage.internalSection.expenses.menuText": "Spese",
  "menu.manage.userSection.text": "Utente",
  "menu.manage.userSection.menuText": "Utente",
  "menu.manage.userSection.createContact.tabText": "Crea Contatto",
  "menu.manage.userSection.createContact.menuText": "Crea Contatto",
  "menu.manage.userSection.contactSearch.tabText": "Ricerca Contatti",
  "menu.manage.userSection.contactSearch.menuText": "Ricerca Contatti",
  "menu.manage.userSection.zoomPresence.tabText": "Presenza Zoom",
  "menu.manage.userSection.zoomPresence.menuText": "Presenza Zoom",
  "menu.manage.userSection.zoomCallQueues.tabText": "Code di Chiamata Zoom",
  "menu.manage.userSection.zoomCallQueues.menuText": "Code di Chiamata Zoom",
  "menu.manage.userSection.mergeContacts.tabText": "Unisci Contatti",
  "menu.manage.userSection.mergeContacts.menuText": "Unisci Contatti",
  "menu.manage.adminSection.text": "Amministratore",
  "menu.manage.adminSection.menuText": "Amministratore",
  "menu.manage.adminSection.dashboard.tabText": "Cruscotto",
  "menu.manage.adminSection.dashboard.menuText": "Cruscotto",
  "menu.manage.adminSection.impersonate.tabText": "Impersona",
  "menu.manage.adminSection.impersonate.menuText": "Impersona",
  "menu.manage.supportSection.newRequestDashboard.tabText": "Nuova Richiesta",
  "menu.manage.supportSection.newRequestDashboard.menuText": "Nuova Richiesta",
  "menu.manage.supportSection.newRequestForm.tabText.supportRequest":
    "Richiesta di Supporto",
  "menu.manage.supportSection.newRequestForm.tabText.serviceRequest":
    "Richiesta di Servizio",
  "menu.manage.supportSection.newRequestForm.tabText.procurementRequest":
    "Richiesta di Acquisto",
  "menu.manage.supportSection.newRequestForm.tabText.internalRequest":
    "Richiesta Interna",
  "menu.manage.supportSection.newRequestForm.tabText.passwordReset":
    "Reimpostazione Password",
  "menu.manage.supportSection.newRequestForm.tabText.newStarter":
    "Nuovo Ingresso / Cambio Ruolo",
  "menu.manage.supportSection.newRequestForm.tabText.leaverRequest":
    "Richiesta di Uscita",
  "menu.manage.supportSection.newRequestForm.tabText.engineerVisit":
    "Visita dell'Ingegnere",
  "menu.manage.supportSection.newRequestForm.tabText.developmentRequest":
    "Richiesta di Sviluppo",
  "menu.manage.engineerSection.ticketViewPage.tabText": "Ticket# {id}",
  "menu.manage.engineerSection.ticketViewPage.menuText": "Ticket {id}",
  "menu.manage.engineerSection.ticketsBoard.tabText": "Ticket",
  "menu.manage.engineerSection.ticketsBoard.menuText": "Ticket",
  "menu.manage.supportSection.newRequestForm.tabText": "{id}",
  "menu.manage.supportSection.newRequestForm.menuText": "{id}",
  "menu.manage.engineerSection.drive.tabText": "Six Star Drive",
  "menu.manage.engineerSection.drive.menuText": "Six Star Drive",
  "menu.manage.engineerSection.reports.tabText": "Rapporti",
  "menu.manage.engineerSection.reports.menuText": "Rapporti",
  "menu.manage.engineerSection.companyProfile.tabText": "La Mia Organizzazione",
  "menu.manage.engineerSection.companyProfile.menuText":
    "La Mia Organizzazione",
  "menu.manage.engineerSection.chatGPT.tabText": "Chat GPT",
  "menu.manage.engineerSection.chatGPT.menuText": "Chat GPT",
  "menu.manage.financeSection.Invoices.tabText": "Fatture",
  "menu.manage.financeSection.Invoices.menuText": "Fatture",
  "menu.manage.financeSection.quotesProposals.tabText": "Preventivi",
  "menu.manage.financeSection.quotesProposals.menuText": "Preventivi",
  "menu.manage.financeSection.creditTerms.tabText": "Condizioni di Credito",
  "menu.manage.financeSection.creditTerms.menuText": "Condizioni di Credito",
  "menu.manage.internalSection.impersonate.tabText": "Impersona",
  "menu.manage.internalSection.impersonate.menuText": "Impersona",
  "menu.manage.managedServicesSection.text": "Servizi Gestiti",
  "menu.manage.managedServicesSection.menuText": "Servizi Gestiti",
  "menu.manage.managedServicesSection.dashboard.tabText": "Cruscotto",
  "menu.manage.managedServicesSection.dashboard.menuText": "Cruscotto",
  "menu.manage.managedServicesSection.serviceOverview.tabText":
    "Panoramica dei Servizi",
  "menu.manage.managedServicesSection.serviceOverview.menuText":
    "Panoramica dei Servizi",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.tabText":
    "Sicurezza degli Endpoint",
  "menu.manage.managedServicesSection.manageManagedServicesEndpointSecurity.menuText":
    "Sicurezza degli Endpoint",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.tabText":
    "Backup",
  "menu.manage.managedServicesSection.manageManagedServicesBackup.menuText":
    "Backup",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.tabText":
    "Moduli Sicuri",
  "menu.manage.managedServicesSection.manageManagedServicesSecureForms.menuText":
    "Moduli Sicuri",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.tabText":
    "Visite Programmate",
  "menu.manage.managedServicesSection.manageManagedServicesScheduledSiteVisits.menuText":
    "Visite Programmate",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.tabText":
    "Smaltimento E-Waste",
  "menu.manage.managedServicesSection.manageManagedServicesEWasteDisposals.menuText":
    "Smaltimento E-Waste",
  "menu.manage.sixStarSecureSection.text": "Six Star Sicuro",
  "menu.manage.sixStarSecureSection.menuText": "Six Star Sicuro",
  "menu.manage.sixStarSecureSection.dashboard.tabText": "Cruscotto",
  "menu.manage.sixStarSecureSection.dashboard.menuText": "Cruscotto",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.tabText":
    "Protezione degli Endpoint (Antivirus & Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointProtection.menuText":
    "Protezione degli Endpoint (Antivirus & Malware)",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.tabText":
    "Rilevamento e Risposta degli Endpoint",
  "menu.manage.sixStarSecureSection.sixStarSecurEndpointDetection.menuText":
    "Rilevamento e Risposta degli Endpoint",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.tabText":
    "Gestione del Firewall",
  "menu.manage.sixStarSecureSection.sixStarSecurFirewallManagement.menuText":
    "Gestione del Firewall",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.tabText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurVpn.menuText": "VPN",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.tabText":
    "Crittografia dei Dispositivi",
  "menu.manage.sixStarSecureSection.sixStarSecurDeviceEncryption.menuText":
    "Crittografia dei Dispositivi",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.tabText":
    "Gestione Office 365/Azure",
  "menu.manage.sixStarSecureSection.sixStarSecurOffice365AzureManagement.menuText":
    "Gestione Office 365/Azure",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.tabText":
    "Backup e Recupero da Disastro",
  "menu.manage.sixStarSecureSection.sixStarSecurBackupDisasterRecovery.menuText":
    "Backup e Recupero da Disastro",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.tabText":
    "Rapporti sulla Sicurezza",
  "menu.manage.sixStarSecureSection.sixStarSecurSecurityReports.menuText":
    "Rapporti sulla Sicurezza",
  "menu.manage.softwareLicenceManagementSection.text":
    "Gestione delle Licenze Software",
  "menu.manage.softwareLicenceManagementSection.menuText":
    "Gestione delle Licenze Software",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.tabText":
    "Catalogo Software Autorizzato",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementAuthorisedSoftwareCatalogue.menuText":
    "Catalogo Software Autorizzato",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.tabText":
    "Gestione delle Licenze",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementLicenceManagement.menuText":
    "Gestione delle Licenze",
  "menu.manage.softwareLicenceManagementSection.softwareLicenceManagementSoftwareManagement.tabText":
    "Gestione del Software",
  "menu.manage.assetManagementSection.text": "Gestione degli Asset",
  "menu.manage.assetManagementSection.menuText": "Gestione degli Asset",
  "menu.manage.assetManagementSection.assetManagementDashboard.tabText":
    "Cruscotto",
  "menu.manage.assetManagementSection.assetManagementDashboard.menuText":
    "Cruscotto",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.tabText":
    "Catalogo Hardware Autorizzato",
  "menu.manage.assetManagementSection.assetManagementAuthorisedHardwareCatalogue.menuText":
    "Catalogo Hardware Autorizzato",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.tabText":
    "Gestione dei Dispositivi",
  "menu.manage.assetManagementSection.assetManagementDeviceManagement.menuText":
    "Gestione dei Dispositivi",
  "menu.manage.sixStarAdditionsSection.text": "Aggiunte di Six Star",
  "menu.manage.sixStarAdditionsSection.menuText": "Aggiunte di Six Star",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.tabText":
    "Supporto Dispositivi degli Ospiti",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsGuestDeviceSupport.menuText":
    "Supporto Dispositivi degli Ospiti",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.tabText":
    "Six Star: Voce",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVoice.menuText":
    "Six Star: Voce",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.tabText":
    "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarVIP.menuText":
    "Six Star: VIP",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.tabText":
    "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarWiFi.menuText":
    "Six Star: WiFi",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.tabText":
    "Six Star: Meet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarMeet.menuText":
    "Six Star: Meet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.tabText":
    "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarTV.menuText":
    "Six Star: TV",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.tabText":
    "Six Star: Cast",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarCast.menuText":
    "Six Star: Cast",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.tabText":
    "Six Star: Paga",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPay.menuText":
    "Six Star: Paga",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.tabText":
    "Six Star: Display",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarDisplay.menuText":
    "Six Star: Display",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.tabText":
    "Six Star: Parco",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPark.menuText":
    "Six Star: Parco",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.tabText":
    "Six Star: Nero",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarBlack.menuText":
    "Six Star: Nero",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.tabText":
    "Six Star: Stampa",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarPrint.menuText":
    "Six Star: Stampa",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.tabText":
    "Six Star: Internet",
  "menu.manage.sixStarAdditionsSection.sixStarAdditionsSixStarInternet.menuText":
    "Six Star: Internet",
    "menu.manage.userSection.editContact.tabText": "Modifica contatto",
"menu.manage.userSection.editContact.menuText": "Modifica contatto",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.tabText": "Schede paga",
"menu.manage.managedServicesSection.manageManagedServicesPayslips.menuText": "Schede paga",
};
export default menu;
