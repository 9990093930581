const misc = {
  General: "General, Arrr",
  "ticket.role.first_responder": "First Mate",
  "ticket.role.first_responder.tooltip":
    "First Mate: Initial point of contact for high priority IT issues, responsible for quick resolution and minimal downtime, Yarrr.",
  "ticket.role.lead_engineer": "Lead Engineer, Yarrr",
  "ticket.role.lead_engineer.tooltip":
    "Lead Engineer: Responsible for the overall technical direction of the project, and for ensuring that the project is completed on time and within budget, Arrr.",
  "ticket.status_id": "Status, Yarrr",
  "ticket.priority_id": "Importance",
  "ticket.closed_at": "Closed At, Yarrr",
  "ticket.subject": "Subject, Arrr",
  "team.team": "Crew",
  "ticket.created_at": "Created At, Yarrr",
  "ticket.updated_at": "Updated At, Arrr",
  "ticket.requester_id": "Requester, Yarrr",
  "ticket.organization_id": "Crew",
  "ticket.assignee_id": "Assigned Matey",
  "ticket.author_id": "Author, Yarrr",
  "ticket.public": "Public, Arrr",
  "ticket.alias": "ID, Yarrr",
  "ticket.status": "Status, Arrr",
  "ticket.priority": "Importance",
  "ticket.team_id": "Crew",
  "ticket.assignee": "Assigned Matey",
  "ticket.requester": "Requester, Arrr",
  "ticket.has_incidents": "Has Incidents, Yarrr",
  "ticket.updated_by_id": "Updated By, Yarrr",
  "ticket.author": "Author, Arrr",
  "person.assignee": "Assigned Matey",
  "person.requester": "Requester, Yarrr",
  "ticket_status.status": "Status, Yarrr",
  "ticket_priority.priority": "Importance",
  "organization.organization": "Company, Yarrr",
  "ticket_priority.priority.normal": "Normal, Yarrr",
  "ticket_priority.priority.high": "High, Arrr",
  "ticket_priority.priority.low": "Low, Yarrr",
  "ticket_status.status.new": "New, Arrr",
  "ticket_status.status.pending": "Pending, Yarrr",
  "ticket_status.status.hold": "Hold, Arrr",
  "ticket_status.status.open": "Open, Yarrr",
  "ticket_status.status.resolved": "Resolved, Arrr",
  "person.name": "Name, Yarrr",
  "person.avatar": "Avatar, Arrr",
  "person.email_address": "Pigeon Post Address",
  "person.active": "Active, Yarrr",
  "person.alias": "ID, Yarrr",
  "organization.name": "Name, Arrr",
  "organization.active": "Active, Yarrr",
  "organization.alias": "ID, Arrr",
  "board.board": "Plank",

  // new translations 
  "dateTime.utc": "UTC",
  "ticket.devices.RMM.install": "Install Six Star RMM, me heartie!",
  "ticket.devices.RMM.login": "Set sail for Six Star RMM, ye scallywag!",
  "ticket.devices.RMM.title": "To spy on the latest treasures, arrr!",
  "ticketViewPage.childTicketsNone": "No child tickets",
  "ticketViewPage.parentNone": "No parent",
  "ticketViewPage.restricted": "Restricted",

};
export default misc;
