const styles = (theme) => ({
    wrapper: {
        background:
          "linear-gradient(180deg, rgba(45,118,187,1) 0%, rgba(45,118,187,1) 45%, rgba(255,255,255,1) 100%)",
        padding: theme.spacing(0, 6, 6, 6),
      },
      meetHeader: {
        width: "67%",
        margin: "auto",
        display: "block",
      },
      videoWrapper: {
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(10),
        border: "8px solid #fff",
        borderRadius: theme.borderRadius,
        cursor: "pointer",
      },
      downArrowIcon: {
        fontSize: "3rem",
        margin: "auto",
        display: "block",
        cursor: "pointer",
      },
      handIcon: {
        fontSize: "5rem",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "100%",
        padding: "2rem",
        width: "5rem",
        height: "5rem",
        margin: "auto",
        marginBottom: theme.spacing(1),
        display: "block",
      },
      iconWrapper: {},
    });

export default styles;