import * as React from "react";
import { styled } from "@mui/system";
import {
  Tab,
  Card,
  Box,
  CardContent,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export const TopPanel = styled(Grid)(({ theme, height }) => ({
  height: `${height}px!important`,
  backgroundColor: "white",
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

export const SidePanel = styled(Grid)(({ theme, width, height, panel }) => ({
  position: "relative",
  borderLeft:
    panel === "right" ? `1px solid ${theme.palette.grey[300]}` : "none",
  borderRight:
    panel === "left" ? `1px solid ${theme.palette.grey[300]}` : "none",
  minWidth: width + "px!important",
  maxWidth: width + "px!important",
  width: width + "px!important",

  backgroundColor: theme.palette.common.white,
  overflowY: "hidden",
  height: `calc(100vh - ${height}px)`,
  // paddingTop: theme.spacing(5),
  ["@media (max-width: " + theme.breakpoints.values.lg + "px)"]: {
    minWidth: "100%!important",
    maxWidth: "100%!important",
    height: "auto",
  },

  "div:nth-of-type(1)": {
    // borderBottom: "none",
  },
}));

export const CenterPanel = styled(Grid)(({ theme, width }) => ({
  minWidth: width + "px!important",
  maxWidth: width + "px!important",
  width: width + "px!important",
  transition: "width  0.05s ease-in-out",

  backgroundColor: theme.palette.background.dark,
  ["@media (max-width: " + theme.breakpoints.values.lg + "px)"]: {
    minWidth: "100%!important",
    maxWidth: "100%!important",
  },
}));
export const SidePanelScrollWrapper = styled(Box)(
  ({ paddingBottom, theme }) => ({
    height: "100%",
    paddingBottom: paddingBottom + "px",
    overflowY: "scroll",
  })
);

export const SidePanelCollapse = styled(Grid)(({ theme, left, right }) => ({
  // position: "absolute",
  backgroundColor: theme.palette.grey[200],

  cursor: "pointer",
  // top: 0,
  // right:`${right === true ? '0':'auto'}`,
  // left: `${left === true ? '0':'auto'}`,
  padding: theme.spacing(1.5, 1),

  textAlign: `${right === true ? "center" : "center"}`,
}));

export const SidePanelCard = styled(Box)(({ theme }) => ({
  // padding: theme.spacing(2,2,2,3),
  backgroundColor: theme.palette.common.white,
  borderBottom: "1px solid #e0e0e0",
  position: "relative",
}));

export const SidePanelCardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 2, 3),
}));

export const SidePanelCardIcon = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2, 3),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
}));
export const SidePanelCardTitle = styled("div")(({ theme, $showPanel }) => ({
  padding: theme.spacing(2, 2, $showPanel ? 0 : 2, 3),
  color: theme.palette.text.primary,
  display: "inline-block",
  margin: 0,
  // padding:  theme.spacing(0,0),
  fontWeight: 800,
  width: "100%",
  cursor: "pointer",
}));
export const SidePanelCardTitlePopOut = styled(SidePanelCardTitle)(
  ({ theme }) => ({
    padding: theme.spacing(0, 0, 2, 0),
  })
);
