import {
  REGISTER_SESSION,
  REGISTER_SESSION_SUCCESS,
  REGISTER_SESSION_FAIL,
} from "../constants";

export function registerSession(content) {
  return {
    type: "socket",
    types: [REGISTER_SESSION, REGISTER_SESSION_SUCCESS, REGISTER_SESSION_FAIL],
    promise: (socket) => {
      return socket.emit(REGISTER_SESSION, content).then((result) => {
        let resultData = JSON.parse(result);
        return resultData;
      });
    },
  };
}
