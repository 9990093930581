import React from 'react';
import { GridPagination } from '@mui/x-data-grid-pro';
import MuiPagination from '@mui/material/Pagination';
const Pagination = (props) => {
    const { page, onPageChange, className, rowsPerPage, count } = props;

    return (
        <MuiPagination
            size='small'
            color='primary'
            className={className}
            count={Math.ceil(count / rowsPerPage)}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
        />
    );
};
const CustomPagination = (props) => {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export default CustomPagination;