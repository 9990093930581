import graphQLMutations from "graphql/audit";
import { useMutation } from "@apollo/client";
// import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export const useAuditAction = (props) => {
    const { INSERT_AUDIT_LOG } = graphQLMutations();
    const [insertAuditLog, {
        loading: auditLogLoading,
        error: auditLogError,
        data: auditLogData }
    ] = useMutation(INSERT_AUDIT_LOG);

    const user = useSelector((state) => state);

    // useEffect(() => {
    //     console.log(auditLogLoading)
    // }, [auditLogLoading])

    // useEffect(() => {
    //     console.log(auditLogError)
    // }, [auditLogError])

    // useEffect(() => {
    //     console.log(auditLogData)
    // }, [auditLogData])

    // const handleInsertAuditLog = async (action, metadata) => {
    //     try {
    //         console.log(user.id);
    //         await insertAuditLog({
    //             variables: {
    //                 action: action ?? 'UNTAGGED_ACTION',
    //                 person_id: `${user?.user?.user?.id}` ?? null,
    //                 metadata: {
    //                     ...metadata,
    //                     pathname: `${router?.location?.pathname}` ?? null,
    //                 },
    //             },
    //         });
    //     } catch (error) {
    //         console.log('handleInsertAuditLog error', error);
    //     }
    // }

    const handleInsertAuditLog = async (action, metadata) => {}

    return {
        handleInsertAuditLog
    };
};

export default useAuditAction;