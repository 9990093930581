import produce from "immer";
import { findMenuItem, getNextIndex } from "utils/menuHelper";
import { getMenu } from "./menu";
import { cloneDeep } from "lodash";
import {
  CHANGE_MENU,
  CLOSE_SETTING_DRAWER,
  CLOSED_VIEW,
  OPEN_DYNAMIC_PARENT_VIEW,
  OPEN_SETTING_DRAWER,
  OPENED_DYNAMIC_VIEW,
  OPENED_VIEW,
  SELECTED_MENU_ITEM,
  SHOW_HIDE_HEADER_TABS,
  SHOW_HIDE_OPEN_VIEWS,
  SHOW_SEARCH,
} from "./constants";

// Taken out from above
// OPENED_DYNAMIC_VIEW,

const Menu = getMenu();
const selectedMenuItem = Menu[0];
const openedViews = [];
openedViews.push(selectedMenuItem);

// The initial state of the App
export const initialState = {
  menu: Menu,
  currentMenu: "manage",
  openViews: { manage: openedViews },
  selectedMenuItem: { manage: selectedMenuItem },
  selectedOpenedMenuItem: { manage: selectedMenuItem },
  activeSubMenuItem: { manage: {} },
  showOpenViews: false,
  showHeaderTabs: true,
  showSearch: false,
  openSettingDrawer: false,
};

/* eslint-disable default-case, no-param-reassign */
const menuReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CHANGE_MENU: {
        // console.log("changeMenu", action.menu);
        const currentMenu = action.menu;
        const Menu = getMenu(currentMenu);
        draft.menu = Menu;
        draft.currentMenu = currentMenu;
        const selectedMenuItem = Menu[0];
        const openedViews = [];
        openedViews.push(selectedMenuItem);
        if (state.openViews[currentMenu] === undefined) {
          draft.openViews[currentMenu] = openedViews;
          draft.selectedMenuItem[currentMenu] = selectedMenuItem;
        }

        break;
      }
      case OPENED_VIEW: {
        // console.log("OPENED_VIEW");
        const { menuItem, icon } = action.payload || action;

        const currentMenu = state.currentMenu;
        const openViews = Object.assign([], draft.openViews[currentMenu]);
        const openView = Object.assign({}, menuItem);

        openView.icon = icon;

        openViews.push(openView);

        draft.openViews[currentMenu] = openViews;
        break;
      }
      case OPENED_DYNAMIC_VIEW: {
        // console.log("OPENED_DYNAMIC_VIEW");
        const { dynamicItem, parentItem } = action;

        const menu = Object.assign([], draft.menu);
        const currentMenu = state.currentMenu;

        const openViews = Object.assign([], draft.openViews[currentMenu]);
        const { foundMenuItem } = findMenuItem(menu, "id", parentItem.id);
        let firstChild = parentItem.children[0];
        if (firstChild.children && firstChild.children.length > 0) {
          firstChild = firstChild.children[0];
        }

        dynamicItem.index = getNextIndex(menu);
        foundMenuItem.children.push(dynamicItem);

        const firstChildInOpenViews = openViews.find(
          (item) => item.id === firstChild.id
        );
        const openView = Object.assign({}, dynamicItem);
        openView.icon = parentItem.icon;

        //
        if (!firstChildInOpenViews) {
          openViews.push(firstChild);
        }

        openViews.push(openView);

        draft.openViews[currentMenu] = openViews;
        break;
      }
      case OPEN_DYNAMIC_PARENT_VIEW: {
        const { dynamicItem, parentItem } = action;
        // console.log("OPEN_DYNAMIC_PARENT_VIEW");

        const currentMenu = state.currentMenu;
        //
        const openViews = Object.assign([], draft.openViews[currentMenu]);

        const { foundMenuItem, foundParentMenuItem } = findMenuItem(
          parentItem,
          "id",
          dynamicItem.id
        );

        let firstChild = foundParentMenuItem.children[0];
        const firstChildInOpenViews = openViews.find(
          (item) => item.id === firstChild.id
        );
        if (foundMenuItem.id !== firstChild.id) {
          if (!firstChildInOpenViews) {
            openViews.push(firstChild);
          }
        }
        draft.openViews[currentMenu] = openViews;
        break;
      }
      case CLOSED_VIEW: {
        const { idsToBeRemoved } = action;
        const currentMenu = state.currentMenu;
        const openViews = Object.assign([], draft.openViews[currentMenu]);

        idsToBeRemoved.forEach((id) => {
          const itemToBeRemoved = openViews.find((item) => item.id === id);
          const index = openViews.indexOf(itemToBeRemoved);
          openViews.splice(index, 1);
        });

        draft.openViews[currentMenu] = openViews;
        break;
      }
      case SELECTED_MENU_ITEM: {
        const currentMenu = state.currentMenu;
        draft.selectedMenuItem[currentMenu] = action.foundMenuItem;
        draft.selectedOpenedMenuItem[currentMenu] = action.foundOpenViewItem;
        if (action.foundMenuItem?.parent?.parentId) {
          const parent = action.foundMenuItem.parent.parentId;
          draft.activeSubMenuItem[currentMenu][parent] = action.foundMenuItem;
        }
        break;
      }

      case OPEN_SETTING_DRAWER: {
        draft.openSettingDrawer = true;
        break;
      }
      case CLOSE_SETTING_DRAWER: {
        draft.openSettingDrawer = false;
        break;
      }
      case SHOW_HIDE_OPEN_VIEWS: {
        draft.showOpenViews = action.showHide;
        break;
      }
      case SHOW_HIDE_HEADER_TABS: {
        draft.showHeaderTabs = action.showHide;
        break;
      }
      case SHOW_SEARCH: {
        const { value } = action;
        draft.showSearch = value;
        break;
      }
    }
  });

export default menuReducer;
